import PutStepService from "@/service/PutStepService";
import PutStepHistoryService from '@/service/PutStepHistoryService'
import PutSleepHistoryService from "@/service/PutSleepHistoryService";
import PutWeightHistoryService, {WeightDataByTime} from "@/service/PutWeightHistoryService";
import PutHeartRateHistoryService from "@/service/PutHeartRateHistoryService";
import PutFloorHistoryService from "@/service/PutFloorHistoryService";
import PutExerciseHistoryService from "@/service/PutExerciseHistoryService";
import UserMissionService from "@/service/UserMissionService";
import {PutMeasureType} from "@/common/Constant";
import {ICWeightData} from "@/service/rothynative/RNISmartScale";
import InterfaceUtil from "@/common/Interface";

export const putMeasure = (type: PutMeasureType, data?: ICWeightData): Promise<void> => {
    return new Promise((resolve, reject) => {
        console.log("■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■")
        console.log("■ PUT MEASURE START")
        const promises = new Array<Promise<void>>()

        // 걸음
        if (type === PutMeasureType.ALL || type === PutMeasureType.STEP) {
            console.log("■ \tadd step")
            promises.push(PutStepService.putStep())

            console.log("■ \tadd step history")
            promises.push(PutStepHistoryService.putStepHistory())
        }

        // 수면
        if (type === PutMeasureType.ALL || type === PutMeasureType.SLEEP) {
            console.log("■ \tadd sleep")
            promises.push(PutSleepHistoryService.putSleepHistory())
        }

        // 체질량
        if (type === PutMeasureType.ALL || type === PutMeasureType.WEIGHT) {
            console.log("■ \tadd weight")
            promises.push(PutWeightHistoryService.putWeightHistory(data))
        }

        if (promises.length > 0) {
            Promise
                .all(promises)
                .catch(e => console.log(e))
                .finally(() => {
                    if (InterfaceUtil.isAppRunning()) {
                        console.log("■ PUT MEASURE END")
                        console.log("■ CHECK MISSION START")
                        UserMissionService
                            .asyncCheckMission()
                            .catch(e => reject(e))
                            .finally(() => {
                                console.log("■ CHECK MISSION END")
                                console.log("■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■")
                                resolve()
                            })
                    } else {
                        console.log("■ PUT MEASURE PASS")
                        console.log("■ CHECK MISSION START")
                        console.log("■ CHECK MISSION PASS")
                        console.log("■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■")

                        resolve()
                    }
                })
        } else {
            resolve()
        }
    })
}

export const putMeasureMq = (): Promise<void> => {
    return new Promise((resolve) => {
        const promises = new Array<Promise<void>>()

        // 심박수
        promises.push(PutHeartRateHistoryService.putHistory())

        // 계단오름
        promises.push(PutFloorHistoryService.putHistory())

        // 운동량
        promises.push(PutExerciseHistoryService.putHistory())

        Promise
            .all(promises)
            .catch(e => console.log(e))
            .finally(() => {
                resolve()
            })
    })
}
