import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Icon from "@/view/common/component/Icon";
import BoardService, {Response} from "@/service/BoardService";
import InterfaceUtil from "@/common/Interface";
import {HEALTH_AGREE, OS_TYPE} from "@/common/Constant";
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import {RHParamAuthorization} from "@/service/rothynative/RNIHealth";
import StorageUtil from "@/common/StorageUtil";

interface HealthConnectProps {
    signupDt: string,
    onReturn: () => void
}

const HealthConnect = (props:HealthConnectProps) => {
    const [approve, setApprove] = useState(StorageUtil.getLocalStorage(HEALTH_AGREE) === 'Y' ? true : false)
    const [req, setReq] = useState('')

    const {refetch: patchSamsungHealthSetting } = useQuery<Response, AxiosError>(
        ['patchSamsungHealthSetting'],
        ()=> BoardService.patchSamsungHealthSetting(req)
    )

    const updateAgree = (agreeYn :string) => {
        setReq(agreeYn)
        onClickRequestHealthAuthorization()
    }

    useEffect(()=> {
        if(req !== ''){
            patchSamsungHealthSetting()
                .then(() => {
                    return Promise<void>
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
    }, [approve])

    const onClickRequestHealthAuthorization = () => {

        const param = new RHParamAuthorization()
        param.shareDataTypes = []
        param.readDataTypes = []

        const config = new RNIMessageConfig<RHParamAuthorization, boolean>()
        config.action = RNIActions.REQUEST_HEALTH_AUTHORIZATION
        config.data = param

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: boolean) => {
                setApprove(response)
                if(response){
                    StorageUtil.setLocalStorage(HEALTH_AGREE,'Y')
                } else {
                    StorageUtil.setLocalStorage(HEALTH_AGREE,'N')
                    props.onReturn()
                }
            })
            .catch(healthError)
    }

    const healthError = (error: RNIError) => {
        if (error.code === '-9005') {
            // 삼성헬스앱/애플헬스앱 미설치
            openStoreApp()
        } else if (error.code === '-9006') {
            // 삼성헬스앱 미활성화
            openSamsungHealthApp()
        } else {
            console.log(`${error.code || ''}::${error.message || ''}`)
        }
    }

    const openStoreApp = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_URL
        if (InterfaceUtil.getOs() == OS_TYPE.ANDROID) {
            config.data = "https://play.google.com/store/apps/details?id=com.sec.android.app.shealth"
        }

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open url success")
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const openSamsungHealthApp = () => {
        const config = new RNIMessageConfig<string, void>()
        if (InterfaceUtil.getOs() == OS_TYPE.ANDROID) {
            config.action = RNIActions.OPEN_APP
            config.data = "com.sec.android.app.shealth"
        }

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open app success")
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    return (
        <>
            {
                InterfaceUtil.getOs() == OS_TYPE.ANDROID ?
                    <div key='android' className='mrml-24px ptpb-22px'>
                        <div className='flex justify-between align-center'>
                            <span className='b2_r'>삼성 헬스와 동기화</span>
                            {
                                approve ?
                                    <div onClick={() => updateAgree('N')}>
                                        <Icon iconClass='toggle-on w-44px h-26px' />
                                    </div>
                                    :
                                    <div onClick={() => updateAgree('Y')}>
                                        <Icon iconClass='toggle-off w-44px h-26px' />
                                    </div>
                            }
                        </div>
                        <span className='ft-cl-hint c1_r'>미션 수행 시 모든 권한 접근 설정이 필요합니다</span>
                    </div>
                    :
                    <div key='ios' className='mrml-24px ptpb-22px'>
                        <div className='flex justify-between align-center'>
                            <span className='b2_r'>Apple Health와 연결</span>
                            <span className='cl-gray04 c1_r'>{props.signupDt}</span>
                        </div>
                        <span className='ft-cl-hint c1_r'>기록을 위해 건강 데이터 '모두켜기'를 허용해주세요</span>
                    </div>
            }
        </>
    )
}

export default HealthConnect;