import {rothyClient} from "@/service/RothyClient";

export interface Notification {
    data: {
        objectList: NotificationListData[]
    }
}
export interface NotificationListData{
    pushTrxId: number,
    type: string,
    pushTitle: string,
    sendDt: string,
    recvYn: string,
}

export interface NoticeList {
    data: {
        objectList: NoticeListData[]
    }
}
export interface NoticeListData{
    noticeId: number,
    title: string,
    contents: string,
    regDt: string
}

export interface QuestionList {
    data : {
        objectList: QuestionData[]
    }
}

export interface QuestionData{
    questionNo: number,
    questionTitle: string,
    questionCn: string,
    answerStatus: string,
    answerStatusNm: string,
    questionType: string,
    questionTypeNm: string,
    questionDate: string
}

export interface FaqList {
    data: {
        objectList: FaqListData[]
    }
}
export interface FaqListData{
    faqId: number,
    title: string,
    contents: string,
}

export interface Response{
    result: boolean,
    code: string,
    message: string,
    errors: string[]
}

export interface ResponseApi{
    result: boolean,
    code: string,
    message: string,
    errors: string[],
    data?: object,
    data2?: object,
}

export interface SingleDataResponse<T>{
    result: boolean,
    code: string,
    message: string,
    errors: string[],
    data?: T,
    data2?: T,
}

export interface RegistQuestionReq{
    wrtrUserNo: number,
    wrtrNickNm: string,
    wrtrWpno: string,
    compCd: string,
    questionTitle: string,
    questionCn: string,
    questionType: string
}

export interface AnswerList {
    data: {
        objectList: AnswerData[]
    }
}
export interface AnswerData{
    frstRegDtm: string,
    answerStatus: string,
    answerCn: string
}

export interface TermsList {
    data: {
        objectList: TermsListData[]
    }
}
export interface TermsListData{
    termsId: number,
    termsNm: string,
    termsContents: string,
}

export interface NotificationSettingReq{
    pushAgree: string,
}

export interface TermsAgreeData{
    termsAgrYn: string,
    infoUseAgrDtm: string
}

const getNotificationUnreadCount = async () =>
    rothyClient.get<SingleDataResponse<number>>('/svcUsers/notification-unread-count').then(res => res.data)

const selectNotification = async () =>
    rothyClient.get<Notification>('/svcUsers/notificationlist').then(res => res.data)

const updateNotification = async (pushTrxId : number) =>
    await rothyClient.patch<ResponseApi>('/svcUsers/notification/'+pushTrxId.toString()).then(res => res.data)

const selectNotice = async () =>
    rothyClient.get<NoticeList>("/noticelist").then(res => res.data)

const selectQuestion = async () =>
    rothyClient.get<QuestionList>('/questionlist').then(res => res.data)

const selectFaq = async () =>
    rothyClient.get<FaqList>('/faqlist').then(res => res.data)

const postQuestion = async (question: RegistQuestionReq | undefined) =>
    await rothyClient.post<Response>('/question', {...question}).then(res => res.data)

const getAnswer = async (questionNo:number) =>
    rothyClient.get<AnswerList>('/answerlist/'+ questionNo.toString()).then(res => res.data)

const selectTerms = async () =>
    rothyClient.get<TermsList>('/termslist',{params: {viewYnInfo: 'Y'}}).then(res => res.data)

const selectTermsByTermsTp = async (termsTp:string) =>
    rothyClient.get<TermsList>('/termslist',{params: {termsTp: termsTp}}).then(res => res.data)

const selectNotificationSetting = async () =>
    rothyClient.get<SingleDataResponse<string>>('/svcUsers/notification-setting').then(res => res.data)

const postNotificationSetting = async (data: NotificationSettingReq | undefined) =>
    await rothyClient.post<number>('/svcUsers/notification-setting', {...data}).then(res => res.data)

const getTermsAgree = async () =>
    rothyClient.get<SingleDataResponse<TermsAgreeData>>('/svcUser/terms/ADVR_INFO').then(res => res.data)

const patchTermsAgree = async (termsArgYn: string) =>
    await rothyClient.patch<Response>('/svcUser/terms/3', {'termsArgYn': termsArgYn}).then(res => res.data)

const patchSamsungHealthSetting = async (agreeYn: string) =>
    await rothyClient.patch<Response>('/svcUser/terms/4', {'termsArgYn': agreeYn}).then(res => res.data)

const BoardService = {
    getNotificationUnreadCount,
    selectNotification,
    updateNotification,
    selectNotice,
    selectQuestion,
    selectFaq,
    postQuestion,
    getAnswer,
    selectTerms,
    selectNotificationSetting,
    postNotificationSetting,
    getTermsAgree,
    patchTermsAgree,
    selectTermsByTermsTp,
    patchSamsungHealthSetting
}

export default BoardService;
