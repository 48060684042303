
type ButtonProps = {
    title: string,
    notification?: JSX.Element,
    onClick?: () => Promise<void> | void,
    className?: string,
    disabled: boolean,
    wrapperClassName?:string
};

const BottomButton = (props: ButtonProps) => {
    return(
        <>
            <div  className={`${props.wrapperClassName? props.wrapperClassName :'button-wrapper'} `} >
                {props.notification && <div className={'mb-20px'}> {props.notification} </div>}
                <button className={`${props.className? props.className :'primary'} bottomButton`}
                        onClick={()=>props.onClick ? props.onClick() : null}
                        disabled={props.disabled}>
                    {props.title}
                </button>
            </div>
        </>
    )
}

export default BottomButton;
