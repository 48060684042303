import {rothyClient} from "@/service/RothyClient";
import StringUtil from "@/common/StringUtil";

export interface StepData {
    data: WalkData
}

export interface WalkData {
    compCd:string,
    currentStepDate:Step,
    last4DaysStepData:Step[],
    stepMessageBubble:string[]
}

export interface Step{
    cnptCalr: number,
    stepCondVl: number,
    moveSpeed: number,
    todaySpeed: number,
    moveDist: number,
    totalDist: number,
    stepCondCd: string,
    stepCnt: number,
    userId: number,
    msreDtm: string
}

export interface WalkCardReq{
    fromDate: string,
    limit: number,
    start: number,
    userId: number
}

export interface WalkCardRes{
    data: WalkCardData[]
}

export interface WalkCardData{
    msreDtm: string,
    moveDist: number,
    distMsg: string,
    stepCnt: number,
    stepMsg: string,
    moveSpeed: number,
    speedMsg: string,
    burnedCalorie: number,
    calorieMsg: string,
    goalStepCnt: number,
    achievementRate: number,
    changeGoalPossibleYn: string,
    limitExceedYn: string,
    limitDay: number,
    remainLimitDay: number
}

export interface PeriodReq{
    periodType: string,
    userId: number
}

export interface WalkDistanceData{
    data: {
        recordComment: string,
        recordImageUrl: string,
        startPoint: string,
        endPoint: string,
        pointCount: number,
        distance: number,
        step: number,
        calorie: number,
        showYn: string
    }
}

export interface WalkScoreData{
    data: {
        comment: string,
        score: number,
        abilityType: string,
        abilityInfo: string,
        imageUrl: string,
        showYn: string,
        stepTypeNo: number
    }
}

export interface GraphReq{
    [key:string]:string | undefined | null,
    periodType:string,
    selectType?:string,
    userId:string,
    fromDate?: string,
    toDate?:string,
    month?: string,
    year?:string
}

export interface WalkGraphRes{
    data: WalkGraphData
}

export interface WalkGraphData{
    pastData: GraphData[],
    presentData: GraphData[],
    recommendComment: string,
    recordComment: string,
    recommendValue: number,
    distComment: string,
    distImageUrl: string,
    totalDist: number
}

export interface GraphData{
    horizontalValue:string,
    verticalValue:number,
    imageUrl:string,
    comment:string,
    minVerticalValue:number,
    maxVerticalValue:number
}

export interface CalorieBurnedRes{
    data: CalorieBurnedData
}

export interface CalorieBurnedData{
    bubbleComment: string,
    bubbleImageUrl: string,
    burnedCalorie: number,
    foodImageUrl: string,
    pastData: GraphData[],
    presentData: GraphData[],
    recommendValue: number,
    recordComment: string,
}

export interface StepGoalReq{
    stepGoal: number,
    userId: number
}

const getUserStepData = async () =>
    rothyClient.get<StepData>("/userMission/userSteptData").then(res => res.data)

const getWalkCardData = async (props: WalkCardReq) =>
    rothyClient.get<WalkCardRes>("/step",{params:props}).then(res => res.data)

const getWalkDistance = async (props: PeriodReq) =>
    rothyClient.get<WalkDistanceData>("/step/dist", {params:props}).then(res => res.data)

const getWalkScore = async (props: PeriodReq) =>
    rothyClient.get<WalkScoreData>("/step/score", {params:props}).then(res => res.data)

const getWalkRecordDist = async (props: GraphReq) =>
    rothyClient.get<WalkGraphRes>("/step/record/dist", {params: removeUndefinedValue(props)}).then(res => res.data)

const getWalkRecordCalorie = async (props: GraphReq) =>
    rothyClient.get<CalorieBurnedRes>("/step/record/calorie", {params: removeUndefinedValue(props)}).then(res => res.data)

const patchStepGoal = async (props: StepGoalReq) =>
    rothyClient.patch("/step/goal", {...props})

const getWalkTime = async (props: GraphReq) =>
    rothyClient.get<WalkGraphRes>("/step/record/time", {params:props}).then(res => res.data)

const getWalkSpeed = async (props: GraphReq) =>
    rothyClient.get<WalkGraphRes>("/step/record/speed", {params:props}).then(res => res.data)

const StepService = {
    getUserStepData,
    getWalkCardData,
    getWalkDistance,
    getWalkScore,
    getWalkRecordDist,
    getWalkRecordCalorie,
    patchStepGoal,
    getWalkTime,
    getWalkSpeed
}


const removeUndefinedValue = (obj:GraphReq) =>{
    const newObj:GraphReq = {periodType: "", selectType: "", userId: ""}
    if(obj !== null && obj !== undefined) {
        Object.keys(obj).forEach(key => {
            if(StringUtil.isNotEmpty(obj[key])){
                newObj[key] = obj[key]
            }
        })
    }

    return newObj
}
export default StepService;