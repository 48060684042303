import React, { useEffect, useState } from 'react';
import RothyNativeService, {
    RNIActions, RNIError, RNIMessageConfig,
} from "@/service/rothynative/RothyNativeService";

import * as GoogleSignIn from "@/service/rothynative/RNIGoogleSignIn"


const GoogleSignInSample: React.FC = (): JSX.Element => {
    const [idToken, setIdToken] = useState<string | undefined>(undefined)
    const [uId, setUid] = useState<string | undefined>(undefined)
    const [profile, setProfile] = useState<GoogleSignIn.User | undefined>(undefined)

    useEffect(() => {
        if (idToken) {
            googleSignInWithCredential(idToken)
        }
    }, [idToken])

    const onClickGoogleSignIn = () => {
        const config = new RNIMessageConfig<void, GoogleSignIn.User>()
        config.action = RNIActions.GOOGLE_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: GoogleSignIn.User) => {
                if (response?.idToken) {
                    setIdToken(response?.idToken)
                } else {
                    setIdToken(undefined)
                    setUid(undefined)
                }
            })
            .catch((error: RNIError) => {
                if (error.code == GoogleSignIn.RNI_GSI_STATUS_CODES.SIGN_IN_CANCELLED) {
                    // 사용자가 취소 버튼 터치
                } else {
                    alert(`${error.code || ''}::${error.message || ''}`)
                }
            })
    }
    const googleSignInWithCredential = (idToken: string) => {
        if (!idToken) {
            alert("idToken is null")
            return
        }

        const config = new RNIMessageConfig<string, GoogleSignIn.UserCredential>()
        config.action = RNIActions.GOOGLE_SIGNIN_WITH_CREDENTIAL
        config.data = idToken

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response) => {
                const uid = response?.user.uid
                if (uid) {
                    setUid(uid)
                }
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }
    const onClickGoogleSignOut = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.GOOGLE_SIGN_OUT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setIdToken(undefined)
                setUid(undefined)
                setProfile(undefined)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }
    const onClickGoogleProfile = () => {
        const config = new RNIMessageConfig<void, GoogleSignIn.User>()
        config.action = RNIActions.GOOGLE_PROFILE

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: GoogleSignIn.User) => {
                setProfile(response)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }
    
    return <>
        <button onClick={onClickGoogleSignIn}>구글 로그인</button>
        <br />
        <button onClick={onClickGoogleSignOut}>구글 로그아웃</button>
        <br />
        <button onClick={onClickGoogleProfile}>구글 계정정보 조회</button>
        <ul>
            <li><b>ID Token</b></li>
            <li>{idToken ? idToken : ''}</li>
            <li><b>UID</b></li>
            <li>{uId ? uId : ''}</li>
            <li><b>계정 정보</b></li>
            {profile && (
                <>
                    <li>프로필 이미지: {`${profile?.user.photo || ''}`}</li>
                    <li>email: {profile?.user.email || ''}</li>
                    <li>성명: {`${profile?.user.familyName || ''} ${profile?.user.givenName || ''}`}</li>
                </>
            )}
        </ul>
    </>
}

export default GoogleSignInSample;