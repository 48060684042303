import React, {useState} from 'react'
import {Skeleton} from '@mui/material'
import Icon from '@/view/common/component/Icon'
import Popup from "@/view/common/popup/Popup";
import WalkScoreDescription from "@/view/walk/component/WalkScoreDescription";

export interface WalkScoreParam{
    comment:string,
    score:number,
    abilityType: string,
    abilityInfo: string,
    imageUrl: string,
    showYn: string,
    stepTypeNo: number
}

const WalkScore = (props: WalkScoreParam) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
        {props.showYn === 'Y'
            ?
                <div className={'mt-88px walk-score'}>
                    <div className={'flex justify-between align-center'}>
                        <div className={'flex-column justify-center'}><p className={'s_title_1_m'}>걷기 능력점수</p></div>
                        <div className={'flex align-center'} onClick={() => setIsOpen(true)}><p className={'c1_r'}>걷기능력점수란?&nbsp;</p><Icon iconClass={'next w-7px h-13px'}/></div>
                    </div>
                    {props.comment === 'LOADING'
                        ?
                        <Skeleton variant='rounded' animation='wave' height={300}/>
                        : <>
                            <div className={'mt-4px gray-bar h-1px'}></div>
                            <p className={'b2_l mt-18px mb-20px'}>{props.comment}</p>
                            <div className={'flex align-center justify-center mt-14px w-100p'}>
                                <img width={'120px'} height={'120px'} src={props.imageUrl}/>
                                <div className={'flex'}>
                                    <p className={'h2_m cl-primary-cyan01 ml-18px'}>{props.score}</p><p className={'h2_l cl-primary-cyan01'}>점</p>
                                </div>
                            </div>
                            <p className={'c1_r cl-primary-cyan01 bubble text-center pt-7px pb-7px'}>{props.abilityType}</p>
                            <p className={'mt-19px cl-primary-cyan01 text-center b2_r'}>{props.abilityInfo}</p>
                        </>
                    }
                </div>
            :
                null
        }
        <Popup className='bg-cl-white' content={<WalkScoreDescription imageUrl={props.imageUrl} abilityType={props.abilityType}/>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
        </>
    )
}

export default WalkScore