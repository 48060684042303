import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Dialog, Slide, SlideProps} from '@mui/material';
import Icon from '../common/component/Icon'
import MenuView from "@/view/menu/MenuView";

type HeaderParam = {
    title ?: string,
    leftIcon ?: string,
    rightIcon ?: string,
    rightLink ?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const Header = (props:HeaderParam) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        navigate('/main');
        setOpen(false);
    };

    return (
        <>
            <div className='header'>
                {props.leftIcon &&
                    <>
                        <div className='left svg' onClick={handleClickOpen}>
                            <Icon iconClass={props.leftIcon}/>
                        </div>
                        <span className='b1_r'>{props.title}</span>
                    </>}
                {props.rightLink && props.rightIcon &&
                    <div className='right svg' onClick={props.rightLink}>
                        <Icon iconClass={props.rightIcon}/>
                    </div>
                }

            </div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <MenuView onClose={handleClose}/>
            </Dialog>
        </>
    )
}

export default Header