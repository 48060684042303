import StringUtil from "@/common/StringUtil";

type ButtonProps = {
    titleCancle: string,
    titleOk:string,
    notification?: JSX.Element,
    onClickCancle?: () => Promise<void> | void,
    onClickOk?: () => Promise<void> | void,
    classNameCancle?: string,
    classNameOk?:string
    disabledCancle?: boolean,
    disabledOk?: boolean
};

const TwoBottomButton = (props: ButtonProps) => {
    console.debug(StringUtil.stringify(props))
    return(
        <>
            <div  className={'button-wrapper flex justify-space-between'}>
                {
                    props.notification
                }
                <button className={`${props.classNameCancle? props.classNameCancle :'primary'} bottomButton-35p`}
                        onClick={()=>props.onClickCancle ? props.onClickCancle() : null}
                        disabled={props.disabledCancle}>
                    {props.titleCancle}
                </button>
                <button className={`${props.classNameOk? props.classNameOk :'primary'} bottomButton-63p`}
                        onClick={()=>props.onClickOk ? props.onClickOk() : null}
                        disabled={props.disabledOk}>
                    {props.titleOk}
                </button>
            </div>
        </>
    )
}

export default TwoBottomButton;
