import {rothyClient} from "@/service/RothyClient";

export class VitaIndexReq{
    fromDate: string
    toDate: string
    userId: number
    constructor(fromDate: string, toDate: string, userId: number,) {
        this.fromDate= fromDate
        this.toDate= toDate
        this.userId= userId
    }
}

export interface VitaIndexContents{
    data: string
    message: string
    result: boolean
}

export interface VitaIndexMainData{
    todayScore: number,
    todayTopRate: number,
    scoreVariance: number,
    dayPercent: number,
    dayCount: number
}

export interface VitaIndexDescContentsData{
    result?: boolean
    vitaIndexDetail: VitaIndexDetailData
    scoreFeature: ScoreFeatureData
    warningHabit: WarningHabitData
    scoreTipList: ScoreTipListData[]
}

export interface ScoreTipListData{
    comment: string
    lifelogType: string
    tipId: string
    title: string
    score: number
}

export interface  WarningHabitData{
    lifelogType: string
    title: string
    comment: string
}

export interface ScoreFeatureData {
    total: ScoreFeatureDetail
    walking: ScoreFeatureDetail
    sleep: ScoreFeatureDetail
    body: ScoreFeatureDetail

}

export interface ScoreFeatureDetail{
    firstCard: ScoreFeatureDetailFirstCard
    secondCard: ScoreFeatureDetailSecondCard
    thirdCard: ScoreFeatureDetailThirdCard
}

export interface ScoreFeatureDetailFirstCard{
    cardId: string
    title: string
    comment: string
}

export interface ScoreFeatureDetailSecondCard{
    lastWeekScoreList: number[]
    meanScore: number
    title: string
    comment: string
}

export interface ScoreFeatureDetailThirdCard{
    ageMeanScore: number
    ageGroup: number
    title: string
    comment: string
}

export interface VitaIndexDetailData {
    todayTotalScore: number
    todayWalkingScore: number
    todaySleepScore: number
    todayBodyScore: number
    totalScoreVariance: number
    walkingScoreVariance: number
    sleepScoreVariance: number
    bodyScoreVariance: number
    dailyWalkingPercent: number
    dailySleepPercent: number
    dailyBodyPercent: number
    improvedWalkingPercent: number
    improvedBodyPercent: number
    improvedSleepPercent: number
}

export interface ScoreHistory {
    scoreHistory: ScoreHistoryData[]
    result?: boolean
}

export interface ScoreHistoryData {
    date: string
    totalScore: number
    walkingScore: number
    sleepScore: number
    bodyScore: number
    commentList: CommentData[]
}

export interface CommentData {
    commentName: string
    value: string
}

export interface resPastVitaIndex {
    data: number
}

const getPastVitaIndex = async (userId: number) =>
    rothyClient.post<resPastVitaIndex>(`/vita-index/${userId}/past`).then(res => res.data)

const getVitaIndexCollect = async (userId: number) =>
    rothyClient.post<VitaIndexContents>(`/vita-index/collect/${userId}`).then(res => res.data)

const getVitaIndexData = async (props: VitaIndexReq|undefined) =>
    rothyClient.get<VitaIndexContents>("/vita-index/"+String(props?.userId)).then(res => res.data)

const getVitaIndexListData = async (props: VitaIndexReq|undefined) =>
    rothyClient.get<VitaIndexContents>("/vita-index/list",{params:props}).then(res => res.data)

const VitaIndexService = {
    getPastVitaIndex,
    getVitaIndexCollect,
    getVitaIndexData,
    getVitaIndexListData
 }

export default VitaIndexService;