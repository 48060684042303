import React, {forwardRef, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import {Skeleton} from "@mui/material"
import {FocusItem} from "@/service/UserService"
import WeightService, {
    WeightHealthyTypeRes,
    WeightHealthyRes,
    WeightRecordReq
} from "@/service/WeightService"
import StringUtil from "@/common/StringUtil"
import StorageUtil from "@/common/StorageUtil"
import {FOCUS_ITEM, LINK_DVIC} from "@/common/Constant"
import WeightHealthyYearChart, {WeightHealthyYearData} from "@/view/weight/component/WeightHealthyYearChart"
import WeightHealthyCalendar, {WeightHealthyCalendarData} from "@/view/weight/component/WeightHealthyCalendar"
import dateUtil from "@/common/DateUtil"

interface weightHealthyParam{
    reqParam: WeightRecordReq
}

const WeightHealthy = forwardRef((props:weightHealthyParam, ref) => {
    const [clickedIndex, setClickedIndex] = useState(0)
    const [itemList,setItemList] = useState<FocusItem[]>([])
    const [healthyReq,setHealthyReq] = useState<WeightRecordReq>(new WeightRecordReq(0,'','','','','',''))
    const [healthyComment,setHealthyComment] = useState('')
    const [chartData,setChartData] = useState<WeightHealthyCalendarData>()
    const [data,setData] = useState<WeightHealthyCalendarData>()
    const [yearChartData,setYearChartData] = useState<WeightHealthyYearData>()
    const [yearData,setYearData] = useState<WeightHealthyYearData>()
    const [_currentDateYn,setCurrentDateYn] = useState(true)

    const focusItem:FocusItem = StringUtil.jsonString2ObjectWithMap<FocusItem>(StorageUtil.getLocalStorage(FOCUS_ITEM) as unknown as '')
    let focusCd = focusItem? focusItem.focusCd : 'BDFAT_RATE'
    const [_focusUnit,setFocusUnit] = useState(focusItem? focusItem.focusUnit : '%')
    const linkDvic = StringUtil.jsonString2ObjectWithMap<string>(StorageUtil.getLocalStorage(LINK_DVIC) as unknown as "")

    const [calendarHeight, setCalendarHeight] = useState('250px')

    useEffect(() => {
        setCd()
        getHealthyType()
            .then(() =>{return Promise<void>})
            .catch(err => console.log(err))
    },[])

    const setCd = () =>{
        if(focusCd !== 'WT' && focusCd !== 'BMI'){
            focusCd = 'BDFAT_RATE'
        }
        
    }

    const {refetch: getHealthyType} = useQuery<WeightHealthyTypeRes, AxiosError>(
        ['getHealthyType'],
        ()=> WeightService.getWeightHealthyType(linkDvic? linkDvic : 'DEFAULT'),
        {
            onSuccess: (v: WeightHealthyTypeRes) => typeSuccess(v.data)
        }
    )

    const typeSuccess = (v:FocusItem[]) => {
        if(v.length > 1 ) setItemList(v)
    }

    const {refetch: getHealthyData} = useQuery<WeightHealthyRes, AxiosError>(
        ['getHealthyData'],
        ()=> WeightService.getWeightHealthy(healthyReq),
        {
            onSuccess: (v: WeightHealthyRes) => dataSuccess(v)
        }
    )

    const dataSuccess = (v:WeightHealthyRes) => {
        setHealthyComment(v.data.comment)
        if(props.reqParam.periodType === 'YEAR'){
            setYearData({currentList: v.data.currentList, lastList: v.data.lastList, lastYn: v.data.lastYn, periodType: props.reqParam.periodType})
        }else if(props.reqParam.periodType === 'MONTH'){
            setCalendarHeight('300px')
            console.log(v.data.dailyList)
            setData({dailyList: v.data.dailyList, periodType: props.reqParam.periodType, month: props.reqParam.month, focusUnit: _focusUnit, currentDateYn: _currentDateYn})
        }else {
            setCalendarHeight('130px')
            setData({dailyList: v.data.dailyList, periodType: props.reqParam.periodType, focusUnit: _focusUnit, currentDateYn: _currentDateYn})
        }
    }

    useEffect(() => {
        setYearChartData(yearData)
    },[yearData])

    useEffect(() => {
        setChartData(data)
    }, [data])

    const selectButton = (index:number, data:FocusItem) =>{
        setClickedIndex(index)
        setHealthyReq((prevState) => ({
            userId:prevState.userId, periodType:prevState.periodType, fromDate:prevState.fromDate,
            toDate:prevState.toDate, month:prevState.month, year:prevState.year, selectType:data.focusCd
        }))
        setFocusUnit(data.focusUnit)
    }

    useEffect(() => {
        setCd()
        if(props && itemList.length > 0){
            const _weightRecordReq = new WeightRecordReq (
                props.reqParam.userId,
                props.reqParam.periodType,
                props.reqParam.fromDate,
                props.reqParam.toDate,
                props.reqParam.month,
                props.reqParam.year,
                focusCd
            )
            setHealthyReq(_weightRecordReq)
        }

        itemList.map((data,index) => {
            if(data.focusCd === focusCd) {
                setClickedIndex(index)
                setFocusUnit(data.focusUnit)
            }
        })
    },[props, itemList])

    useEffect(() => {
        if(healthyReq && StringUtil.isNotEmpty(healthyReq.periodType)){
            setCd()
            if(props.reqParam.periodType === 'MONTH'){
                setCurrentDateYn(dateUtil.checkCurrentMonthYn(healthyReq.month))
            }else {
                setCurrentDateYn(dateUtil.checkCurrentWeekYn(healthyReq.fromDate,healthyReq.toDate))
            }

            getHealthyData()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[healthyReq])

    return (
        <div id="WeightHealthy">
            <div className={'weight-record-title pt-19px'}>더 건강해진 날은 <br/>며칠일까? </div>
            <div className={'flex mt-12px mb-12px'}>
                {itemList.map((data, index)=>{
                    return <div key={index}> <button className={`${clickedIndex === index? 'on' : ''}`} onClick={() => selectButton(index,data)}>{data.focusNm}</button> </div>
                })}
            </div>
            <div className={'b2_l'}>{healthyComment}</div>
            <div className={'report mt-40px'} style={{height: calendarHeight, width: '100%'}}>
                {
                    props.reqParam.periodType === 'YEAR'?
                        (
                            yearChartData
                            ?
                            <WeightHealthyYearChart {...yearChartData}/>
                            :
                            <Skeleton height={calendarHeight} animation={'wave'}/>
                        )
                        :
                        (
                            chartData
                                ?
                                <WeightHealthyCalendar {...chartData}/>
                                :
                                <Skeleton height={calendarHeight} animation={'wave'}/>
                        )
                }
            </div>
        </div>
    )
})

export default WeightHealthy