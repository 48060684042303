import React, {useCallback, useRef, useState} from 'react'
import Flicking, {ViewportSlot} from "@egjs/react-flicking";
import {Pagination} from "@egjs/flicking-plugins";
import {StepPopUpData} from "@/service/PopUpService";
import NumberUtil from "@/common/NumberUtil";
import StepService from "@/service/StepService";
import LottieAnimation from "@/view/common/component/LottieAnimation";
import ClapLottie from "@/assets/lottie/onboarding_new_01_clap.json";


export interface RecommendGoalParam{
    data: StepPopUpData,
    onClose: () => void,
    refresh: () => void,
    selfChange: () => void,
    userId: number
}

const RecommendGoal = (props: RecommendGoalParam) => {
    const flickingRef = useRef<Flicking>(null)
    const [_plugins] = useState([new Pagination({ type: 'bullet' })])
    const [isMoving, setMoving] = useState(false)
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    const moveToNext = ()=>{
        if(flickingRef.current !== null && !isMoving) {
            const index: number = flickingRef.current.index
            if (index < 2) {
                setMoving(true)

                flickingRef.current.next(300)
                    .then(() => {
                        setMoving(false)
                    })
                    .catch(() => {
                            setMoving(false)
                        },
                    )
            }
        }
    }

    const changeStepGoal = (goal:number) => {
        StepService.patchStepGoal({userId: props.userId, stepGoal: goal})
            .then(() => {props.refresh(); handleOverlayClick()})
            .catch(reason=>{console.log(reason)})
    }

    if(props) {
        switch (props.data.popupType) {
            case 'FIRST': //최초가입자 팝업
                return (
                    <div className={'goal-recommend mrml-24px'}>
                        <Flicking bounce={1}
                                  plugins={_plugins}
                                  circular={false}
                                  hanger={'0'}
                                  align={'prev'}
                                  panelsPerView={1}
                                  moveType={['strict', { count: 1 }]}
                                  changeOnHold={false}
                                  ref={flickingRef}
                        >
                            <div key={0} className={'flex-column flex-start'}>
                                <div className={'mt-24px'}>
                                    <p className={'h5_m'}>ROTHY가 당신을 위한<br/> 걸음 목표를 준비했어요!</p>
                                    <p className={'mt-6px c1_r cl-gray04'}>{props.data.nickNm}님에게 딱 맞는 걸음 목표를 설정하고<br/>걸음수를 조금씩 늘려가 보세요.</p>
                                </div>
                                <div className={'w-100p h-200px'}/>
                                <button className={'mt-24px b2_m recommend-popup-button'} onClick={moveToNext}>다음</button>
                            </div>
                            <div key={1} className={'flex-column flex-start'}>
                                <div className={'mt-24px'}>
                                    <p className={'h5_m'}>목표는 4,000보부터<br/>가볍게 시작해보세요!</p>
                                    <p className={'mt-6px c1_r cl-gray04'}>앞으로 ROTHY가 {props.data.nickNm}님의 걸음 실력에<br/>맞춰 걸음 목표를 추천해 드립니다</p>
                                    <button className={'mt-40px custom-button'} onClick={() => props.selfChange()}> 목표 직접 설정하기</button>
                                </div>
                                <div className={'w-100p h-200px'}/>
                                <button className={'mt-24px b2_m recommend-popup-button'} onClick={() => changeStepGoal(4000)}>이 목표로 도전하기!</button>
                            </div>
                            <ViewportSlot>
                                <div className='flicking-pagination'></div>
                            </ViewportSlot>
                        </Flicking>
                    </div>
                )
            case 'INIT': //기존사용자 최초팝업
                return (
                    <div className={'goal-recommend mrml-24px'}>
                        <Flicking bounce={1}
                                  plugins={_plugins}
                                  circular={false}
                                  hanger={'0'}
                                  align={'prev'}
                                  panelsPerView={1}
                                  moveType={['strict', { count: 1 }]}
                                  changeOnHold={false}
                                  ref={flickingRef}
                        >
                            <div key={0} className={'flex-column flex-start'}>
                                <div className={'mt-24px'}>
                                    <p className={'h5_m'}>더 똑똑해진 ROTHY가<br/>당신을 위한 걸음 목표를<br/>준비했어요!</p>
                                    <p className={'mt-6px c1_r cl-gray04'}>{props.data.nickNm}님에게 딱 맞는 걸음 목표를 설정하고<br/>걸음수를 조금씩 늘려가 보세요.</p>
                                </div>
                                <div className={'w-100p h-200px'}/>
                                <button className={'mt-24px b2_m recommend-popup-button'} onClick={moveToNext}>다음</button>
                            </div>
                            <div key={1} className={'flex-column flex-start'}>
                                <div className={'mt-24px'}>
                                    {props.data.stepDataYn === 'Y'
                                        ?   <>
                                            <p className={'h5_m'}>{props.data.nickNm}님의 적정목표<br/>걸음수는 {NumberUtil.numberWithCommas(props.data.recommendGoal)}보입니다</p>
                                            <p className={'mt-6px c1_r cl-gray04'}>ROTHY가 {props.data.nickNm}님의 최근 30일 걸음수 <br/>데이터를 분석해 제안하는 목표 걸음수 입니다</p>
                                            </>
                                        :   <>
                                            <p className={'h5_m'}>목표는 4,000보부터<br/>가볍게 시작해보세요!</p>
                                            <p className={'mt-6px c1_r cl-gray04'}>{props.data.nickNm}님의 최근 30일 걸음 수 데이터가 없어<br/>4,000보를 기본목표로 설정합니다</p>
                                            </>
                                    }

                                    <button className={'mt-40px custom-button'} onClick={() => props.selfChange()}> 목표 직접 설정하기</button>
                                </div>
                                <div className={'w-100p h-200px'}/>
                                <button className={'mt-24px b2_m recommend-popup-button'} onClick={() => changeStepGoal(props.data.recommendGoal)}>이 목표로 도전하기!</button>
                            </div>
                            <ViewportSlot>
                                <div className='flicking-pagination'></div>
                            </ViewportSlot>
                        </Flicking>
                    </div>
                )
            case 'MORE': //걸음수 증가권장
                return (
                    <div className={'goal-recommend flex-column flex-start mrml-24px'}>
                        <LottieAnimation animationData={ClapLottie}
                                         lottieStyle={{width: '130px', height: '146px'}}/>
                        <p className={'mt-39px h5_m'}>와우! {props.data.nickNm}님의<br/>걸음 실력이 늘었어요!<br/>다음은 {NumberUtil.numberWithCommas(props.data.recommendGoal)}보에 도전해 볼까요?</p>
                        <p className={'mt-6px c1_r cl-gray04'}>{props.data.nickNm}님의 걸음 실력이 좋아지고 있어<br/>{NumberUtil.numberWithCommas(props.data.goalDiff)}보를 추가했어요! 목표를 변경하고 싶다면<br/>직접 입력 버튼을 탭해주세요</p>
                        <button className={'mt-40px custom-button'} onClick={() => props.selfChange()}> 목표 직접 설정하기</button>
                        <div className={'w-100p h-150px'}/>
                        <div className={'w-100p flex popup-buttons'}>
                            <button className={'stay-button'} onClick={() => changeStepGoal(props.data.stepGoal)}>기존 목표로 유지</button>
                            <button className={'change-button'} onClick={() => changeStepGoal(props.data.recommendGoal)}>이 목표로 도전하기!</button>
                        </div>
                    </div>
                )
            case 'NONE': // 그대로 도전권장
                return (
                    <div className={'goal-recommend flex-column flex-start mrml-24px'}>
                        <p className={'mt-39px h5_m'}>잘하고 계세요!<br/>계속 {NumberUtil.numberWithCommas(props.data.stepGoal)}보에 도전해 보세요</p>
                        <p className={'mt-6px c1_r cl-gray04'}>지금도 잘 걷고 계시지만 조금만 더 걸어서<br/>걸음 목표를 높여보세요. 목표를 변경하고<br/>싶다면 직접 입력 버튼을 탭해주세요</p>
                        <button className={'mt-40px custom-button'} onClick={() => props.selfChange()}> 목표 직접 설정하기</button>
                        <div className={'w-100p h-150px'}/>
                        <div className={'w-100p flex popup-buttons'}>
                            <button className={'stay-button'} onClick={() => changeStepGoal(props.data.stepGoal)}>기존 목표로 유지</button>
                            <button className={'change-button'} onClick={() => changeStepGoal(props.data.recommendGoal)}>이 목표로 도전하기!</button>
                        </div>
                    </div>
                )
            case 'LESS': //걸음수 감소권장
                return (
                    <div className={'goal-recommend flex-column flex-start mrml-24px'}>
                        <p className={'mt-39px h5_m'}>이번엔 목표를 {NumberUtil.numberWithCommas(props.data.recommendGoal)}보로 조금<br/>낮출까요? 현재 컨디션에<br/>맞춰 걸어보세요</p>
                        <p className={'mt-6px c1_r cl-gray04'}>최근 컨디션에 맞춰 달성할 수 있을만한<br/>목표를 설정해보세요. 목표를 변경하고 싶다면<br/>직접 입력 버튼을 탭해주세요</p>
                        <button className={'mt-40px custom-button'} onClick={() => props.selfChange()}> 목표 직접 설정하기</button>
                        <div className={'w-100p h-150px'}/>
                        <div className={'w-100p flex popup-buttons'}>
                            <button className={'stay-button'} onClick={() => changeStepGoal(props.data.stepGoal)}>기존 목표로 유지</button>
                            <button className={'change-button'} onClick={() => changeStepGoal(props.data.recommendGoal)}>이 목표로 도전하기!</button>
                        </div>
                    </div>
                )
            default:
                return (
                    <></>
                )
        }
    }else{
        return (<></>)
    }
}

export default RecommendGoal