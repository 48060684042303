export enum Actions {
    RABBIT_MQ_PUBLISH = "RABBIT_MQ_PUBLISH",
}

export declare interface RabbitMqPublish<T> {
    host?: string,
    port?: number,
    userName?: string,
    password?: string,
    qn: string,
    ex: string,
    rk: string,
    quantityType: string,
    data: T,
}
