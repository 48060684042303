import React from 'react'
import Icon from '@/view/common/component/Icon'

export interface PropsInterface{
    onClick: (value:string) => void,
}

const UpdateTypeSelect = (props:PropsInterface) => {
    return (
            <div className={'custom-select'}>
                <div className={`light`}>
                    <div className={'select-items flex'} style={{justifyContent:'normal'}} onClick={() => props.onClick('update')}>
                        <Icon iconClass={'edit w-24px h-24px'}/>
                        <p className={'b2_r'}>이름 변경</p>
                    </div>
                    <div className={'select-items flex mb-20px'} style={{justifyContent:'normal'}} onClick={() => props.onClick('disconnect')}>
                        <Icon iconClass={'delete w-24px h-24px'}/>
                        <p className={'b2_r'}>연결 해제</p>
                    </div>
                </div>
            </div>
    )
}

export default UpdateTypeSelect