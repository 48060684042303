export interface config{
    className?: string,
}

const HeaderSpacer = (param: config) => {
    return (
        <div className={`header-spacer ${param.className? param.className: ''}`}></div>
    )
}

export default HeaderSpacer