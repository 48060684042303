import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {AxiosError} from 'axios'
import {useQuery} from '@tanstack/react-query'
import TodayMission, {TodayMissionParam} from '@/view/mission/component/TodayMission'
import TodayRecord, {TodayRecordParam} from '@/view/mission/component/TodayRecord'
import MissionUserInfo from '@/view/mission/component/MissionUserInfo'
import UserMissionService, {TodayMissionData} from '@/service/UserMissionService'
import StorageUtil from '@/common/StorageUtil'
import {USER_ID} from '@/common/Constant'
import VitaIndexMain from "@/view/vitaindex/component/VitaIndexMain";
import HealthRecord from "@/view/health/component/HealthRecord";
import Toast from "@/view/common/popup/Toast";
import InterfaceUtil from "@/common/Interface";

export interface MissionProps{
    vitaIndexFlag: boolean
}

const Mission = forwardRef((props: MissionProps, ref) => {
    const userId = Number(StorageUtil.getLocalStorage(USER_ID)) as unknown as number
    const [todayMission, setTodayMission] = useState<TodayMissionParam>({missionStatCd:'', missionType:'', successCnt:0, missionDay:0, totalMissionDay:0, monthMissionDay:0, dDay:0, stepGoal:'',
    todayStep:'', todaySleep:'', todayWeight:'', comment:'', missionList:''})
    const [todayRecord, setTodayRecord] = useState<TodayRecordParam>({    step:'0', stepComparison:'', sleep:'', sleepComparison: '', weight: '', weightComparison: ''})
    const [toastOpen, setToastOpen] = useState(false)
    const [message, setMessage] = useState("")

    const {refetch: getTodayMissionData} = useQuery<TodayMissionData, AxiosError>(
        ['getTodayMissionData'],
        ()=> UserMissionService.getTodayMissionData(userId),
        {
            onSuccess: (v: TodayMissionData) => onSuccess(v)
        }
    )

    const onSuccess = (v: TodayMissionData) => {
        setTodayMission({missionStatCd: v.data.missionStatusCd, missionType: v.data.missionType
                            , successCnt: v.data.successCnt, missionDay: v.data.missionDay
                            , totalMissionDay: v.data.totalMissionDay, monthMissionDay: v.data.monthMissionDay
                            , dDay: v.data.dday, stepGoal: v.data.stepGoal
                            , todayStep: v.data.todayStep, todaySleep: v.data.todaySleep, todayWeight: v.data.todayWeight, comment: v.data.comment
                            , missionList: v.data.missionList})
        setTodayRecord({step: v.data.todayStep, stepComparison: v.data.stepComparison, sleep: v.data.todaySleep
                            , sleepComparison: v.data.sleepComparison, weight: v.data.todayWeight, weightComparison: v.data.weightComparison})
    }

    const initData = () => {
        getTodayMissionData()
            .then(r => r.data)
            .catch(reason => {console.log(reason)})
    }

    useEffect(()=>{
        initData()
    }, [])

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            initData()
        }
    }))

    const onToastOpen = (message:string) => {
        setMessage(message)
        setToastOpen(true)
    }

    return (
        <>
            <div className={'mission-content mrml-24px'}>
                <MissionUserInfo/>
                <VitaIndexMain userId={userId} vitaIndexFlag={props.vitaIndexFlag} />
                <TodayMission  {...todayMission}/>
                <TodayRecord {...todayRecord}/>
                <HealthRecord onToast={(message:string) => onToastOpen(message)}/>
                {
                    InterfaceUtil.getOs() === 'ios' &&  <div className={'mt-34px'}/>
                }
            </div>
            <Toast content={<div className={'white-space-wrap'}>{message}</div>} isOpen={toastOpen} onClose={ () => setToastOpen(false) }></Toast>
        </>
    )
})

export default Mission