import React, {useCallback, useEffect, useState} from 'react'
import {Dialog, Slide, SlideProps} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import DialogHeader from "@/view/layout/DialogHeader";
import Input from "@/view/common/component/Input";
import Icon from "@/view/common/component/Icon";
import ValidateUtil from "@/common/ValidateUtil";
import AgreeDetail from "@/view/signup/component/AgreeDetail";
import BoardService, {TermsList, TermsListData} from "@/service/BoardService";
import TermsDetail from "@/view/signup/component/TermsDetail";
import StringUtil from "@/common/StringUtil";
import {authType, defaultAuthType} from "@/common/Constant";
import HealthAuthConfirm from "@/view/health/component/HealthAuthConfirm";
import HealthService, {TilkoAuthData, TilkoAuthRes} from "@/service/HealthService";
import Alert from "@/view/common/popup/Alert";
import InterfaceUtil from "@/common/Interface";
import BottomButton from "@/view/common/component/button/BottomButton";

export interface HealthCertificationParam{
    onClose: () => void,
    onReturn?: () => void
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
})

const HealthCertification = (props: HealthCertificationParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const defaultTheme = 'light'
    const [name, setName] = useState({value:'', errMsg : ''})
    const [birthday, setBirthday] = useState({value:'', errMsg : ''})
    const [phoneNumber, setPhoneNumber] = useState({value:'', errMsg : ''})
    const [allAgreeFlag, setAllAgreeFlag] = useState(false)
    const [termsAgree1, setTermsAgree1] = useState('N')
    const [termsAgree2, setTermsAgree2] = useState('N')
    const [termsAgree3, setTermsAgree3] = useState('N')
    const [termDetail, setTermDetail] = useState<TermsListData | undefined>(undefined)
    const [termsList, setTermsList] = useState<TermsListData[]>([])
    const [termsAgreeOpen1, setOpenTermsAgree1] = useState(false)
    const [termsAgreeOpen2, setOpenTermsAgree2] = useState(false)
    const [termsAgreeOpen3, setOpenTermsAgree3] = useState(false)
    const [selectedAuthType, setSelectedAuthType] = useState<number| undefined>()
    const [canGoNext, setCanGoNext] = useState({input: false, terms: false})
    const [openAuthConfirm, setOpenAuthConfirm] = useState(false)
    const [authErrOpen, setAuthErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [authResult, setAuthResult] = useState<TilkoAuthData>()
    const [selectedAuth, setSelectedAuth] = useState<authType>()


    useEffect(()=>{
        if(selectedAuthType !== undefined){
            setSelectedAuth(defaultAuthType[selectedAuthType])
        }
    }, [selectedAuthType])

    const {data:result} = useQuery<TermsList, AxiosError>(
        ['termsList'],
        ()=> BoardService.selectTermsByTermsTp('HEALTH_AGREE'),
        {
            enabled: true,
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 60,
        }
    )

    useEffect(() => {
        if(result){
            updateTermsList(result.data.objectList)
        }
    }, [result])

    const updateTermsList = (data: TermsListData[]) => {
        const dataList : TermsListData[] =[]
        data.map( d => dataList.push({ termsId: d.termsId, termsNm: d.termsNm, termsContents: d.termsContents }))
        setTermsList(dataList)
    }

    const validateBirthday = (value:string) =>{
        const result = ValidateUtil.checkValidBirthday(value)
        setBirthday({value: result.value, errMsg: result.errMsg})
    }

    const validatePhoneNumber = (value: string) =>{
        setPhoneNumber(prevState => ({value: value.replace(/[^0-9]/g,''), errMsg: prevState.errMsg}))
    }

    const openTermsAgree = (termsAgreeType:number) => {
        setTermDetail(termsList[termsAgreeType-1])
        if(termsAgreeType === 1) setOpenTermsAgree1(true)
        if(termsAgreeType === 2) setOpenTermsAgree2(true)
        if(termsAgreeType === 3) setOpenTermsAgree3(true)
    }

    const closeDialog = (type: number) => {
        if(type === 1) setOpenTermsAgree1(false)
        if(type === 2) setOpenTermsAgree2(false)
        if(type === 3) setOpenTermsAgree3(false)
    }

    const changeAllAgree = (state:string) => {
        if(state === 'Y'){
            setTermsAgree1('Y')
            setTermsAgree2('Y')
            setTermsAgree3('Y')
        }else{
            setTermsAgree1('N')
            setTermsAgree2('N')
            setTermsAgree3('N')
        }
    }

    useEffect(() =>{
        if(termsAgree1 === 'Y' && termsAgree2 === 'Y' && termsAgree3 === 'Y'){
            setAllAgreeFlag(true)
            setCanGoNext(prevState => ({input: prevState.input, terms: true}))
        }else{
            setAllAgreeFlag(false)
            setCanGoNext(prevState => ({input: prevState.input, terms: false}))
        }
    }, [termsAgree1, termsAgree2, termsAgree3])

    useEffect(()=>{
        if(selectedAuthType !== undefined && StringUtil.isNotEmpty(name.value) && StringUtil.isNotEmpty(phoneNumber.value)
            && name.errMsg === '' && birthday.errMsg === '' && phoneNumber.errMsg === ''){
            setCanGoNext(prevState => ({input: true, terms: prevState.terms}))
        }else{
            setCanGoNext(prevState => ({input: false, terms: prevState.terms}))
        }
    }, [selectedAuthType, name, birthday, phoneNumber])

    const goToNext = () =>{
        getTilkoAuth().then(r=>r).catch(reason=>console.log(reason))

    }

    const {refetch:getTilkoAuth} = useQuery<TilkoAuthRes, AxiosError>(
        ['tilkoAuth'],
        ()=> HealthService.getTilkoAuth({
            userCellphoneNumber: phoneNumber.value,
            birthDate: birthday.value.replaceAll('.',''),
            userName: name.value,
            privateAuthType: selectedAuth? selectedAuth.privateAuthType : ''
        }),
        {
            onSuccess: (v: TilkoAuthRes) => checkResult(v),
        }
    )

    const checkResult = (result: TilkoAuthRes) => {
        if(result.message !== '성공') {
            setErrMsg('인증 요청 중 오류가 발생했어요\n잠시 후 다시 시도해 주세요')
            setAuthErrOpen(true)
        }else {
            if (result.data.Status === 'OK' && StringUtil.isNotEmpty(result.data.ResultData.CxId)) {
                setAuthResult(result.data.ResultData)
                setOpenAuthConfirm(true)
            } else {
                setErrMsg('입력하신 정보가\n이동통신사에 등록된 정보와 달라요\n확인하신 뒤 다시 시도해 주세요')
                setAuthErrOpen(true)
            }
        }
    }

    return (
        <div className={'health-certification'}>
            <DialogHeader title='' leftIcon={'none'} rightIcon={'close-small'} rightOnClick={handleOverlayClick}/>
            <div className={'auth-contents'}>
                <div className={'mt-40px mrml-24px flex-column'}>
                    <p className={'ft-cl-black h5_m'}>검진 기록 확인을 위해<br/>아래 정보를 입력해 주세요</p>
                    <div className={'mt-32px flex justify-space-between'}>
                        {defaultAuthType.map((data, index)=>{
                                return (
                                    <div key={index} className={`certification-type ${selectedAuthType === index? 'certification-type-selected' :''}`} onClick={() => setSelectedAuthType(index)}>
                                        <img width={'34px'} height={'34px'} src={data.logo}/>
                                        <p className={'s_title_2_m'}>{data.title}</p>
                                    </div>
                                )
                        })
                        }
                    </div>
                    <div className={'mt-32px mb-12px b1_r cl-gray02'}>
                        <Input theme={defaultTheme}
                               label={'이름'}
                               name={'name'}
                               value={name.value}
                               required={true}
                               onChange={(ev) => setName(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                               errorMsg={name.errMsg}
                               placeholder={'이름'}
                        />
                    </div>
                    <div className={'mb-12px b1_r cl-gray02'}>
                        <Input theme={defaultTheme}
                               label={'생년월일'}
                               name={'birthday'}
                               value={birthday.value}
                               required={true}
                               onChange={(ev) => setBirthday(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                               errorMsg={birthday.errMsg}
                               maxLength={8}
                               onBlur={() => validateBirthday(birthday.value)}
                               onKeyUp={() => validateBirthday(birthday.value)}
                               placeholder={'YYYYMMDD'}
                        />
                    </div>
                    <div className={'mb-12px b1_r cl-gray02'}>
                        <Input theme={defaultTheme}
                               label={'휴대전화 번호'}
                               name={'phone'}
                               value={phoneNumber.value}
                               required={true}
                               onChange={(ev) => setPhoneNumber(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                               onKeyUp={() => validatePhoneNumber(phoneNumber.value)}
                               errorMsg={phoneNumber.errMsg}
                               maxLength={11}
                               placeholder={'휴대전화 번호 (-제외)'}
                        />
                    </div>
                </div>
                <div className={'health-terms'}>
                    <div className={'terms-all-agree'}>
                        {
                            allAgreeFlag === true?
                                <div className={'all-agree-item all-agree-checkbox'} onClick={() => (changeAllAgree('N'))}>
                                    <Icon iconClass='check-all-on w-20px h-20px' />
                                </div>
                                :
                                <div className={'all-agree-item all-agree-checkbox'} onClick={() => (changeAllAgree('Y'))}>
                                    <Icon iconClass='check-all-off w-20px h-20px' />
                                </div>
                        }
                        <div className={'all-agree-item'}>
                            <span className={'b1_m all-agree-items ml-8px'}>약관 전체 동의</span>
                        </div>
                    </div>
                    <AgreeDetail type={termsAgree1} setType={setTermsAgree1} title={termsList.length > 0? '[필수] ' + termsList[0].termsNm :'[필수] 서비스 이용약관 동의'} openSignupDetail={() => openTermsAgree(1)}/>
                    <AgreeDetail type={termsAgree2} setType={setTermsAgree2} title={termsList.length > 1? '[필수] ' + termsList[1].termsNm :'[필수] 민감정보 수집 및 이용동의'} openSignupDetail={() => openTermsAgree(2)}/>
                    <AgreeDetail type={termsAgree3} setType={setTermsAgree3} title={termsList.length > 2? '[필수] ' + termsList[2].termsNm :'[필수] 고유식별번호 처리 동의'} openSignupDetail={() => openTermsAgree(3)}/>
                </div>
            </div>
            {canGoNext.input && canGoNext.terms
                ?
                <>
                    <BottomButton title={'다음'} disabled={false} onClick={() => goToNext()}/>
                    {
                        InterfaceUtil.getOs() === 'ios' &&  <div className={'w-100p h-34px'}/>
                    }
                </>
                :
                <>
                    <BottomButton title={'다음'} disabled={true}/>
                    {
                        InterfaceUtil.getOs() === 'ios' &&  <div className={'w-100p h-34px'}/>
                    }
                </>
            }
            <Dialog fullScreen open={termsAgreeOpen1} TransitionComponent={Transition}>
                <TermsDetail onClose={() => closeDialog(1)} termDetail={termDetail} type={termsAgree1} setType={setTermsAgree1} themeClass={'bg-cl-white'}/>
            </Dialog>
            <Dialog fullScreen open={termsAgreeOpen2} TransitionComponent={Transition}>
                <TermsDetail onClose={() => closeDialog(2)} termDetail={termDetail} type={termsAgree2} setType={setTermsAgree2} themeClass={'bg-cl-white'}/>
            </Dialog>
            <Dialog fullScreen open={termsAgreeOpen3} TransitionComponent={Transition}>
                <TermsDetail onClose={() => closeDialog(3)} termDetail={termDetail} type={termsAgree3} setType={setTermsAgree3} themeClass={'bg-cl-white'}/>
            </Dialog>
            <Dialog fullScreen open={openAuthConfirm} TransitionComponent={Transition}>
                <HealthAuthConfirm onClose={() => setOpenAuthConfirm(false)} onReturn={props.onReturn} authType={selectedAuthType} name={name.value} birthday={birthday.value} phoneNumber={phoneNumber.value} authResult={authResult}/>
            </Dialog>

            <Alert content={<div className={'text-center b2_r'}><p className={'white-space-wrap'}>{errMsg}</p></div>} title={undefined} btnContent={'확인'} isOpen={authErrOpen} onClose={ () => setAuthErrOpen(false)}/>
        </div>
    )
}

export default HealthCertification