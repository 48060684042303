import React, {useCallback, useEffect, useState} from "react"
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import DialogHeader from "@/view/layout/DialogHeader"
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import BoardListItem from "@/view/common/component/BoardListItem";
import CodeService, {DetailCode} from "@/service/CodeService";
import Icon from "@/view/common/component/Icon";

interface QuestionAddViewParam {
    onClose: () => void,
    onReturn: () => void
}

const QuestionAddView = (props:QuestionAddViewParam) => {
    const handleOverlayClick = useCallback(() => {props.onClose()}, [props.onClose])
    const handleReturnClick = useCallback(() => {props.onClose(); props.onReturn();}, [props.onClose])
    const [questionTypeList, setQuestionTypeList] = useState<DetailCode[]>([])

    const {refetch:selectQuestion, data:typeResult} = useQuery<DetailCode[], AxiosError>(
        ['getQuestionType'],
        ()=> CodeService.selectDetailCode('Q_TP'),
        {
            enabled: true,
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 60,
            onSuccess: (v: DetailCode[]) => updateQuestionTypeList(v),
        }
    )

    const updateQuestionTypeList = (data:DetailCode[]) => {
        const dataList:DetailCode[] = []
        data.map(d => {
            const questionType = {
                dtlCd: d.dtlCd,
                dtlCdNm: d.dtlCdNm,
                grpCd: d.grpCd,
                status: d.status
            }
            dataList.push(questionType)
        })
        setQuestionTypeList(dataList)
    }

    useEffect(() => {
        if(typeResult){
            updateQuestionTypeList(typeResult)
        }
    }, [])

    return (
        <>
            <DialogHeader title='문의등록' onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <div className='h5_m mt-24px mb-24px'>문의하고 싶은 항목을<br/>선택해 주세요</div>
                <div>
                    {questionTypeList.map((data, index)=>{
                        if(data.dtlCd !== 'ADMIN') {
                            return <div key={index}>
                                        <BoardListItem type={'questionAdd'}
                                                   index={index}
                                                   title={data.dtlCdNm}
                                                   contents={data.dtlCd}
                                                   heightClass={'h-61px'}
                                                   rightContent={<Icon iconClass={'next'}/>}
                                                   onReturn={handleReturnClick}/>
                                    </div>
                        }
                    })}
                </div>
            </div>
        </>
    )
}

export default QuestionAddView