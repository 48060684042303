import React, {useState} from 'react'
import {YearItem} from "@/view/sleep/component/Oxygen";

export interface OxygenYearDetailConfig{
    itemList : YearItem[] | undefined
    onClose: () => void,
}

const OxygenYearDetail = (props:OxygenYearDetailConfig) => {
    const [itemList] = useState<YearItem[]>(props.itemList !== undefined? props.itemList : [])

    return (
        <>
            <div className={'mrml-24px'} style={{marginBottom: "60px"}}>
                <div className={'h6_m mb-16px'}>
                    수면 중 혈중 산소<br/>
                    며칠 동안 내려갔지?
                </div>
                <div className={'b2_r mb-24px'}>
                    월별 수면 중 혈중 산소 90% 아래로 내려간 누적 일수
                </div>
                <div>
                    {itemList.map((data, index)=>{
                        return(
                            data.totalOxygenLowCount>0 &&
                                <div key={index} className={'flex justify-between bg-cl-gray-9 mb-8px'} style={{height: "59px", borderRadius: "8px", alignItems:"center"}}>
                                    <p className={'b1_r'} style={{marginLeft:"24px"}}>{data.date}</p>
                                    <p className={'h5_m'} style={{marginRight:"24px"}}>{data.totalOxygenLowCount}일</p>
                                </div>
                        )
                    })}
                </div>
                <div style={{width:"100px", height:"60px"}}></div>


            </div>
        </>
    )
}

export default OxygenYearDetail