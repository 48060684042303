import React from 'react'
import moment from "moment";

export interface AnswerContentParam{
    answerStatus: string | undefined,
    answerDate: string | undefined,
    answerCn: string | undefined
}

const AnswerContent = (props:AnswerContentParam) => {
    const answerYet = props.answerStatus === undefined || props.answerStatus !== 'END'
    return <div className={'w-100p'}>
        <div className={`c1_m mt-36px ${answerYet?'cl-gray04': 'cl-primary-cyan01'}`}><p>{answerYet? '답변 준비중': '답변 완료'}</p></div>
        {answerYet? null : <div className={'c1_r cl-gray04 mt-16px'}><p>{moment(props.answerDate).format('YYYY.MM.DD')}</p></div>}
        {answerYet
            ? <div className={'c1_r cl-gray04 mt-13px'}><p>문의 주신 내용 확인 후 답변을 준비 중이에요<br/>최대한 빠르게 답변 드릴게요</p></div>
            : <div className={'b2_r mt-32px'}><p>{props.answerCn}</p></div>}
    </div>
}

export default AnswerContent