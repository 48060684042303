import React, {useEffect, useRef} from 'react'
import lottie from 'lottie-web'

export interface LottieAnimationConfig {
    animationData: any,
    lottieStyle: React.CSSProperties,
    loop?:boolean
}

const LottieAnimation = (props: LottieAnimationConfig) => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(container !== null) {
            lottie.loadAnimation({
                container: container.current? container.current: new HTMLElement(),
                renderer: 'svg',
                loop: props.loop==undefined?true:props.loop,
                autoplay: true,
                animationData: props.animationData as JSON
            })
        }
    }, [])

    return (
        <>
            <div ref={container} style={props.lottieStyle}/>
        </>
    )
}

export default LottieAnimation