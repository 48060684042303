import React from "react";

export interface answerStatusParam{
    answerStatus: string,
    answerStatusNm: string
}

const AnswerStatus = (props: answerStatusParam) => {
    return <div className={`${props.answerStatus === 'END'? 'cl-primary-cyan01 ml-18px c1_r': 'cl-gray05 ml-18px c1_m'}`}>{props.answerStatusNm}</div>
}

export default AnswerStatus