import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import Icon from "@/view/common/component/Icon";
import Popup from "@/view/common/popup/Popup";
import ScoreChart from "@/view/vitaindex/component/ScoreChart";
import AccuracyChart from "@/view/vitaindex/component/AccuracyChart";
import VitaIndexService, {
    resPastVitaIndex,
    VitaIndexContents,
    VitaIndexMainData
} from "@/service/VitaIndexService";
import StringUtil from "@/common/StringUtil";

export interface PropsConfig{
    userId: number,
    vitaIndexFlag: boolean
}

const VitaIndexMain = (props: PropsConfig) => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [chartFlag, setChartFlag] = useState(false)
    const [countFlag, setCountFlag] = useState(true)
    const [circularValue, setCircularValue] = useState(0)
    const [mainData, setMainData] = useState<VitaIndexMainData>({todayScore: 0, todayTopRate: 0, scoreVariance: 0, dayPercent: 0, dayCount: 0})

    const handleNext = () => {
        navigate("/vitaIndexDetail" )
    }

    const {data} = useQuery<resPastVitaIndex, AxiosError>(
        ['getPastVitaIndex'],
        () => VitaIndexService.getPastVitaIndex(props.userId),
        {
            enabled: true
        }
    )

    useEffect(()=>{
        if(data){
            console.log('past 끝남')
            setMainData({...mainData, todayScore: data.data})
            setChartFlag(true)
            setCircularValue(data.data / 10)
            setCountFlag(true)
        }
    },[data])

    useEffect(() => {
        if(props.vitaIndexFlag){
            getVitaIndexCollect()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[props.vitaIndexFlag])

    const {refetch: getVitaIndexCollect} = useQuery<VitaIndexContents, AxiosError>(
        ['getVitaIndexCollect'],
        () => VitaIndexService.getVitaIndexCollect(props.userId),
        {
            onSuccess: (v: VitaIndexContents) => success(v)
        }
    )

    const success = (v:VitaIndexContents) => {
        if(v.result) {
            const _mainData:VitaIndexMainData = StringUtil.jsonString2ObjectWithMap(v.data)
            setMainData(_mainData)
            setChartFlag(true)
            setCircularValue(_mainData.todayScore / 10)
            if(_mainData.dayPercent > 100) {
                setCountFlag(true)
            } else {
                setCountFlag(false)
            }
        }
    }

    const vitaIndexDescription = () => {
        const result = []

        result.push(<div key='vitaIndexDescription' className={'flex-column'} style={{margin: '24px'}}>
                    <div className={'h6_b mb-16px'}>건강 습관 점수란?</div>
                    <div className={'s_title_2_r mb-16px'}>ROTHY와 함께 쌓아온 건강 기록을 바탕으로 더 건강해지기 위한 습관 점수를 알려드려요. 만점인 1,000점을 향해 꾸준히 올려봐요!</div>
                    <div className={'s_title_2_r cl-gray04'}>수면과 체성분 항목을 측정할 수 있는 웨어러블 디바이스는 오른쪽 위의 ROTHY 스토어에서 확인하실 수 있어요.</div>
                    <div className={'h-61px'}></div>
                </div>)

         return result
    }

    const todayScoreSpan = () => {
        if(!chartFlag) {
            return
        }

        const _scoreVariance = mainData.scoreVariance
        const result = []

        if(_scoreVariance > 0){
            result.push(<div key='todayScore_1' className={'flex justify-center'}>
                            <span className={'b2_r mr-4px cl-gray03'}>어제보다</span>
                            <span className={'b2_m cl-point03'}>+{_scoreVariance.toFixed(2)}점</span>
                        </div>)
        } else if(_scoreVariance < 0) {
            result.push(<div key='todayScore_2' className={'flex justify-center'}>
                            <span className={'b2_r mr-4px cl-gray03'}>어제보다</span>
                            <span className={'b2_m cl-primary-cyan01'}>{_scoreVariance.toFixed(2)}점</span>
                        </div>)
        } else {
            result.push(<div key='todayScore_3' className={'flex justify-center'}>
                            <span className={'b2_r mr-4px cl-gray03'}>어제와 같음</span>
                        </div>)
        }

        return result
    }

    return (
        <>
        <div className={'card-content mt-24px mb-12px ptpb-24px prpl-24px'}>
            <div className={'flex justify-between align-center'}>
                <div className={'flex'} onClick={() => setIsOpen(true)}>
                    <p className={'s_title_2_m pr-2px'}>내 건강 습관 점수</p>
                    <Icon iconClass={'inform w-20px h-20px'}/>
                </div>
                <div className={'b2_r pl-4px'} onClick={handleNext}>
                    <Icon iconClass={'thin-next w-6px h-12px'}/>
                </div>
            </div>
            <ScoreChart chartFlag={chartFlag} countFlag={countFlag} circularValue={circularValue}/>
            {
                chartFlag ?
                    <>
                        <div className={'flex justify-center'}>
                            <span className={'h4_b'}> {mainData.todayScore} </span>
                            <span className={'h4_r'}>점</span>
                        </div>
                        { todayScoreSpan() }
                    </>
                :
                    <div className={'flex justify-center'}>
                        <span className={'b2_r cl-gray03'}>점수 계산 중...</span>
                    </div>
            }
            {
                chartFlag && countFlag && mainData.todayTopRate != 0 && mainData.todayTopRate < 80 ?
                    <>
                        <div className={'flex justify-center pt-11px'} >
                            <div style={{backgroundColor:'#EAF5F4', borderRadius:'20px', width:'66px', height:'22px'}}>
                                <div className={'c1_m flex justify-center'} style={{ color:'#24CBAA', lineHeight:'1.8'}}>상위 {mainData.todayTopRate}%</div>
                            </div>
                        </div>
                    </>
                    :
                    <></>
            }
            {
                chartFlag && !countFlag ?
                    <>
                        <div>
                            <div className={'border-top-gray mt-24px'}></div>
                        </div>
                        <AccuracyChart dayPercent={mainData.dayPercent} dayCount={mainData.dayCount}/>
                    </>
                :
                <></>
            }
        </div>
        <Popup className='bg-cl-white' content={<div>{vitaIndexDescription()}</div>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
        </>
)
}

export default VitaIndexMain