import React, {useEffect, useState} from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import {WalkCardData} from "@/service/StepService";
import NumberUtil from "@/common/NumberUtil";


const WalkCard = (props:WalkCardData) => {
    const isToday = props.msreDtm === moment().format('YY.MM.DD')
    const [data, setData] = useState(props)

    useEffect(() => {
        setData(props)
    }, [props])

    return <div className='daily-card-content'>
                <div className={'flex align-center'}>
                    <p className='b2_m'>{isToday? 'TODAY' : data.msreDtm}</p>
                    {/*<div className={'svg pr-22px ml-auto'}><Icon iconClass={'share'}/></div> 공유하기 Native 인터페이스 구현될때까지 숨김*/}
                </div>
                <p className='c1_r pt-19px'>{!isToday && data.moveDist === 0 ? '괜찮으신거죠?' : '걸음 수'}</p>
                <h1 className={`h1_r pt-2px ${data.achievementRate > 100 ? 'cl-primary-cyan01 h1_m': ''}`}>{!isToday && data.stepCnt === 0 ? '휴식' : NumberUtil.numberWithCommas(data.stepCnt)}</h1>
                <div className='border-top-gray mb-22px'>
                    <div className='step-count mt-22px flex align-center'>
                        <p className='c2_r cl-gray03'>이동<br/>거리</p>
                        <p className={`s_title_1_r pl-17px ${data.distMsg !== 'Hang in!'? 'cl-primary-cyan01': ''}`}>{!isToday && data.moveDist === 0? '-' : NumberUtil.numberWithCommas(data.moveDist)}</p>
                        <p className='b1_l pl-2px cl-gray03'>km</p>
                        <div className='ml-auto'>
                            <p className={`c1_m ${data.distMsg !== 'Hang in!'? 'cl-primary-cyan01': 'cl-gray04'}`}>{!isToday && data.moveDist === 0? '' : data.distMsg}</p>
                        </div>
                    </div>
                    <div className='step-speed mt-16px flex align-center'>
                        <p className='c2_r cl-gray03'>평균<br/>속도</p>
                        <p className={`s_title_1_r pl-17px ${data.speedMsg !== 'Hang in!'? 'cl-primary-cyan01': ''}`}>{!isToday && data.moveSpeed === 0? '-' : data.moveSpeed.toFixed(1)}</p>
                        <p className='b1_l pl-2px cl-gray03'>km/h</p>
                        <div className='ml-auto'>
                            <p className={`c1_m ${data.speedMsg !== 'Hang in!'? 'cl-primary-cyan01': 'cl-gray04'}`}>{!isToday && data.moveSpeed === 0? '' : data.speedMsg}</p>
                        </div>
                    </div>
                    <div className='burned-calorie flex align-center'>
                        <p className='c2_r mt-14px cl-gray03'>소모<br/>칼로리</p>
                        <p className={`s_title_1_r pl-8px mt-16px ${data.calorieMsg != 'Hang in!'? 'cl-primary-cyan01': ''}`}>{!isToday && data.burnedCalorie === 0 ? '-' : NumberUtil.numberWithCommas(Math.round(data.burnedCalorie))}</p>
                        <p className='b1_l pl-2px mt-16px cl-gray03'>kcal</p>
                        <div className='ml-auto'>
                            <p className={`c1_m mt-18px ${data.calorieMsg !== 'Hang in!'? 'cl-primary-cyan01': 'cl-gray04'}`}>{!isToday && data.burnedCalorie === 0 ? '' : data.calorieMsg}</p>
                        </div>
                    </div>
                </div>
                <div className={'border-top-gray cl-gray04 pb-24px'}>
                    <div className='pt-19px flex align-center'>
                        <p className='c1_r'>{!isToday && data.moveDist ===0 ? '목표' : NumberUtil.numberWithCommas(data.stepCnt)+ '/' + NumberUtil.numberWithCommas(data.goalStepCnt)}</p>
                        <p className='c1_r ml-auto'>{!isToday && data.moveDist === 0 ? '이 날은 쉬었어요' : Math.round(data.achievementRate).toString() + '% 달성'}</p>
                    </div>
                    <div className='progress-bar pt-3px'>
                        <LinearProgress className='walk-progress' color="primary" variant="determinate" value={data.achievementRate>100 ? 100 : data.achievementRate} />
                    </div>
                </div>
            </div>
}

export default WalkCard