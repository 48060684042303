import React, {useCallback} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import NoticeDetail from "@/view/notice/component/NoticeDetail";

export interface NoticeDetailParam {
    title:string,
    subTitle?:string,
    contents?:string,
    onClose: () => void
}

const NoticeDetailView: React.FC<NoticeDetailParam> = (props:NoticeDetailParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    return (
        <>
            <DialogHeader onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <NoticeDetail title={props.title} subTitle={props.subTitle} contents={props.contents}/>
            </div>
        </>
    )
}

export default NoticeDetailView;