import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import WeightDetail from './component/WeightDetail'
import WeightRecord from './component/WeightRecord'
import Popup from "@/view/common/popup/Popup"
import WeightService from "@/service/WeightService"
import {FocusItem} from "@/service/UserService"
import {SingleDataResponse} from "@/service/BoardService"
import StorageUtil from "@/common/StorageUtil"
import {USER_ID} from "@/common/Constant"
import FocusItemSelectParam from "@/view/common/component/FocusItemSelect"


const WeightView = forwardRef((props, ref) => {
    const weightDetailRef = useRef<{refresh: () => void}>(null)
    const weightRecordRef = useRef<{refresh: () => void}>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [focusItemList, setFocusItemList] = useState<FocusItem[]>()

    useQuery<SingleDataResponse<FocusItem[]>, AxiosError>(
        ['getFocusItem'],
        ()=> WeightService.getFocusItem(Number(StorageUtil.getLocalStorage(USER_ID))),
        {
            enabled: true,
            onSuccess: (v: SingleDataResponse<FocusItem[]>) => getFocusItemList(v.data)
        }
    )

    const getFocusItemList = (data: FocusItem[] | undefined) => {
        const dataList : FocusItem[] =[]
        if(data !== undefined){
            data.map( d => dataList.push({ focusCd: d.focusCd, focusNm: d.focusNm, focusUnit: d.focusUnit }))
        }
        setFocusItemList(dataList)
    }

    const refreshWeightDetail = () => {
        if(weightDetailRef.current !== null){
            weightDetailRef.current.refresh()
        }
    }

    const refreshWeightRecord = () => {
        if(weightRecordRef.current !== null){
            weightRecordRef.current.refresh()
        }
    }

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            refreshWeightDetail()
            refreshWeightRecord()
        }
    }))

    const handleClickOpen = () => {
        setIsOpen(true)
    }

    return (
        <>
            <WeightDetail onOpen={handleClickOpen} ref={weightDetailRef}/>
            <div className={'gray-bar'}></div>
            <WeightRecord focusItemList={focusItemList} ref={weightRecordRef}/>
            <div className={'mt-100px'}></div>
            <Popup className='bg-cl-white' title='집중 관리 항목 선택' content={<FocusItemSelectParam focusItemList={focusItemList} onClose={() => setIsOpen(!isOpen)}/>} isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}/>
        </>
    )
})

export default WeightView;