import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import moment from "moment/moment";
import HistoryDaily from "@/view/vitaindex/component/HistoryDaily";
import DateUtil from "@/common/DateUtil";
import Icon from "@/view/common/component/Icon";
import VitaIndexService, {
    ScoreHistory, ScoreHistoryData,
    VitaIndexContents,
    VitaIndexReq
} from "@/service/VitaIndexService";
import StorageUtil from "@/common/StorageUtil";
import {USER_ID} from "@/common/Constant";
import StringUtil from "@/common/StringUtil";

interface DdailyDataParam {
    x: string
    y: number
}

interface PropsConfig {
    dailyList?: DdailyDataParam[]
    month?:string;
    title?: string;
}

export interface DailyData {
    date?:string,
    status?:string,
}

const HistoryMonth= (props:PropsConfig ) => {
    const [vitaIndexReq, setVitaIndexReq] = useState<VitaIndexReq | undefined>( undefined)
    const [scoreHistory, setScoreHistory] = useState<ScoreHistoryData[] | undefined>(undefined)


    const[calendarData, setCalendarData] = useState<DailyData[]|undefined>(undefined)
    const[selectedDate, setSelectedDate] = useState<number|undefined>(undefined)
    const[dailyData, setDailyData] = useState<DailyData|undefined>(undefined)
    const[monthScoreAvg, setMonthScoreAvg] = useState<number|undefined>(undefined)

    const {refetch: getVitaIndexList} = useQuery<VitaIndexContents, AxiosError>(
        ['getVitaIndexList'],
        () => VitaIndexService.getVitaIndexListData(vitaIndexReq),
        {
            onSuccess: (v: VitaIndexContents) => success(v)
        }
    )

    const success = (v:VitaIndexContents) => {
        if(v.result){
            const data:ScoreHistory = StringUtil.jsonString2ObjectWithMap(v.data)
            if(data && data.result){
                setScoreHistory(data.scoreHistory)
            }else{
                setScoreHistory(undefined)
            }
        }
    }

    const drawMonthCalendar = () => {
        if(props.month){
            const _calendarData = DateUtil.getCalendarDataForMonth(props.month)
            _calendarData.forEach((data, index) => {
                if(data.status === 'today'){
                    onClickCalender(data, index)
                }
            })
            setCalendarData(_calendarData)
        }

        const _vitaIndexReq = new VitaIndexReq (
            moment(props.month).startOf('month').format('YYYY-MM-DD'),
            moment(props.month).endOf('month').format('YYYY-MM-DD'),
            Number(StorageUtil.getLocalStorage(USER_ID))
        )

        setVitaIndexReq(_vitaIndexReq)
    }

    const onClickCalender = (data:DailyData, index:number) => {
        setSelectedDate(index)
        setDailyData(data)
    }



    useEffect(() => {
        if(props.month){
            drawMonthCalendar()
            setSelectedDate(undefined)
        }
    },[props.month])

    useEffect(()=>{
        if(scoreHistory && scoreHistory.length > 0){
            let totalScore = 0
            scoreHistory.forEach((data) => {
                totalScore += data.totalScore
            })
            setMonthScoreAvg(Math.round(totalScore*100/scoreHistory.length)/100)
        }else {
            setMonthScoreAvg(0)
        }
    },[scoreHistory])

    useEffect(() => {
        if(vitaIndexReq && vitaIndexReq.fromDate && vitaIndexReq.toDate && vitaIndexReq.userId > 0){
            getVitaIndexList()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[vitaIndexReq])

    return (
        <div className='mt-26px'>
            <div className="calender-grid cl-gray03">
                <div className={'c1_r'}>일</div>
                <div className={'c1_r'}>월</div>
                <div className={'c1_r'}>화</div>
                <div className={'c1_r'}>수</div>
                <div className={'c1_r'}>목</div>
                <div className={'c1_r'}>금</div>
                <div className={'c1_r'}>토</div>
            </div>
            <div className={'grid-content-month'}>
            {
                calendarData &&
                    calendarData.map((data, index) => {
                            if(data.status === 'past'){
                                return <div key={'data'+index.toString()} className={'past'}></div>
                            }else if(data.status === 'today'){
                                return <div key={'data'+index.toString()} className={'today'} onClick={()=>onClickCalender(data, index)} >
                                            <div className={`today-inner c1_m cl-gray03' ${selectedDate === index ? 'selected': ''}`}>{moment(data.date).format('D')}</div>
                                       </div>
                            }else if(data.status === 'deactive-day'){
                                return <div key={'data'+index.toString()} className={'deactive-day'}>
                                           <div className={`active-day-inner c1_m cl-gray06 ${selectedDate === index ? 'selected': ''}`}>{moment(data.date).format('D')}</div>
                                       </div>
                            }else if(data.status === 'active-day'){
                                return <div key={'data'+index.toString()} className={'active-day'} onClick={()=>onClickCalender(data, index)} >
                                    <div className={`active-day-inner c1_m cl-gray03 ${selectedDate === index ? 'selected': ''}`}>{moment(data.date).format('D')}</div>
                                </div>
                            }

                        }
                    )
            }
            </div>
            <div className={'mt-36px flex ml-8px'}>
                <Icon iconClass='speech-bubble-icon w-27px h-19px' />
                <div className={'ml-5px b2_r cl-gray03'}>이번달 평균 종합 점수는 {monthScoreAvg}점</div>
            </div>
            <HistoryDaily dailyData={dailyData} scoreHistory={scoreHistory} month={props.month}/>
        </div>
    )
}

export default HistoryMonth