import React, {useEffect, useState} from 'react'
import moment from "moment/moment"
import Icon from '@/view/common/component/Icon'
import { WeightInfoData, SectionData } from '@/service/WeightService'

export interface WeightCardData{
    focusCd: string,
    focusNm: string,
    focusUnit: string,
    cardData: WeightInfoData,
}

const defaultSection = {
    section1: 0, section2: 0, section3: 0, section4: 0
}

const WeightCard = (props:WeightCardData) => {
    const [data, setData] = useState(props.cardData)
    const isToday = data.msreDt === moment().format('YY.MM.DD')
    const [msreData,setMsreData] = useState(0)
    const [section,setSection] = useState<SectionData>(data.weightStandard?data.weightStandard:defaultSection)

    useEffect(() => {
        if(props.cardData.msreCnt > 0){
            convertMsreData()
        }
        setData(props.cardData)
        convertMsreCountData()
    }, [props])

    useEffect(() => {
        setSection(data.weightStandard?data.weightStandard:defaultSection)
        setStandardGraph()
    }, [data])

    const convertMsreData = () => {
        if(props.focusCd === 'WT'){
            setMsreData(props.cardData.wt)
        } else if(props.focusCd === 'BMI'){
            setMsreData(props.cardData.bmi)
        } else if(props.focusCd === 'BDFAT_QTY'){
            setMsreData(props.cardData.bdfatQty)
        } else if(props.focusCd === 'BDWT_QTY'){
            setMsreData(props.cardData.bdwtQty)
        } else if(props.focusCd === 'SKLTN_WT'){
            setMsreData(props.cardData.skltnWt)
        } else{
            setMsreData(props.cardData.bdfatRate)
        }
    }

    const convertMsreCountData = () =>{
        const countList = props.cardData.countList? props.cardData.countList : []
        const result = []
        for(let i=0; i < countList.length; i++ ){
            const measureTime = countList[i].msreTm
            let hour = Number(measureTime.substring(0,2))
            const minute = Number(measureTime.substring(3,5))

            let convertTime = 0
            hour = hour===12 ? 0 : hour
            if(measureTime.substring(6,8) === 'AM'){
                convertTime = (60*hour + minute) / 1440 * 100
            } else {
                convertTime = (60*(hour+12) + (minute)) / 1440 * 100
            }
            if(i !== countList.length-1){
                result.push(<div key={i} className='weight-detail-count-content' style={{width:String(convertTime)+'%', borderRight: '4px solid rgba(36, 203, 170, 0.4)'}}></div>)
            } else {
                const widthTime = (measureTime.substring(6,8) === 'AM'? hour: hour+12) / 24 * 100
                result.push(<p key={'p' +i.toString()} className='c1_r weight-detail-count-text cl-gray03'
                               style={{width:String(widthTime+10)+'%'}}>{measureTime}</p>)
                result.push(<div key={i} className='weight-detail-count-content' style={{width:String(convertTime)+'%', borderRight: '4px solid rgba(36, 203, 170, 0.8)'}}></div>)
            }

        }
        return result
    }

    const setStandardGraph = () => {

        let standard: { firstBg: string; secondBg: string; indicateLocation: string; firstNm: string; first: string; second: string; secondNm: string }

        const result = [];
        if(props.focusCd === 'WT'){
            if(msreData >= section.section1 && msreData < section.section2){
                standard = {first: '0'+ props.focusUnit + '~', firstNm: '저체중'
                            , second: section.section2.toString()+props.focusUnit+'~', secondNm: '적정' , indicateLocation: 'ml-65px'
                            , firstBg: 'rgba(26, 26, 26, 0.5)' , secondBg: 'linear-gradient(90deg, #24CBAA 0%, rgba(36, 203, 170, 0) 100%)'}
            } else if (msreData >= section.section2 && msreData < section.section3){
                standard = {first: section.section2.toString()+props.focusUnit+'~', firstNm: '적정'
                            , second: section.section3.toString()+props.focusUnit+'~', secondNm: '과체중', indicateLocation: 'ml-65px'
                            , firstBg: '#24CBAA' , secondBg: 'linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0) 100%)'}
            } else if (msreData >= section.section3 && msreData < section.section4){
                standard = {first: section.section2.toString()+props.focusUnit+'~', firstNm: '적정'
                            , second: section.section3.toString()+props.focusUnit+'~', secondNm: '과체중', indicateLocation: 'ml-90px'
                            , firstBg: '#24CBAA' , secondBg: 'linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0) 100%)'}
            } else {
                standard = {first: section.section3.toString()+props.focusUnit+'~', firstNm: '과체중'
                            , second: section.section4.toString()+props.focusUnit+'~', secondNm: '비만', indicateLocation: 'ml-90px'
                            , firstBg: '#24CBAA' , secondBg: 'linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0) 100%)'}
            }
        } else if(props.focusCd === 'BMI' || props.focusCd === 'BDFAT_RATE'){
            if(msreData >= section.section1 && msreData < section.section2){
                standard = {first: '0'+ props.focusUnit + '~', firstNm: '낮음'
                            , second: section.section2.toString()+props.focusUnit+'~', secondNm: '적정' , indicateLocation: 'ml-65px'
                            , firstBg: 'rgba(26, 26, 26, 0.5)' , secondBg: 'linear-gradient(90deg, #24CBAA 0%, rgba(36, 203, 170, 0) 100%)'}
            } else if (msreData >= section.section2 && msreData < section.section3){
                standard = {first: section.section2.toString()+props.focusUnit+'~', firstNm: '적정'
                            , second: section.section3.toString()+props.focusUnit+'~', secondNm: '높음', indicateLocation: 'ml-65px'
                            , firstBg: '#24CBAA' , secondBg: 'linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0) 100%)'}
            } else {
                standard = {first: section.section2.toString()+props.focusUnit+'~', firstNm: '적정'
                            , second: section.section3.toString()+props.focusUnit+'~', secondNm: '높음', indicateLocation: 'ml-90px'
                            , firstBg: '#24CBAA' , secondBg: 'linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0) 100%)'}
            }
        } else {
            if (msreData >= section.section1 && msreData < section.section2) {
                standard = {first: '0'+ props.focusUnit + '~', firstNm: '적음'
                            , second: section.section2.toString() + props.focusUnit + '~', secondNm: '적정', indicateLocation: 'ml-65px'
                            , firstBg: 'rgba(26, 26, 26, 0.5)', secondBg: 'linear-gradient(90deg, #24CBAA 0%, rgba(36, 203, 170, 0) 100%)'
                }
            } else if (msreData >= section.section2 && msreData < section.section3) {
                standard = {first: section.section2.toString() + props.focusUnit + '~', firstNm: '적정'
                            , second: section.section3.toString() + props.focusUnit + '~', secondNm: '높음', indicateLocation: 'ml-65px'
                            , firstBg: '#24CBAA', secondBg: 'linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0) 100%)'
                }
            } else {
                standard = {first: section.section2.toString() + props.focusUnit + '~', firstNm: '적정'
                            , second: section.section3.toString() + props.focusUnit + '~', secondNm: '많음', indicateLocation: 'ml-90px'
                            , firstBg: '#24CBAA', secondBg: 'linear-gradient(90deg, rgba(26, 26, 26, 0.3) 0%, rgba(26, 26, 26, 0) 100%)'
                }
            }
        }

        result.push(<div key='standardKey'>
            <div className={'flex'}>
                <div className='c2_r cl-gray03'>{standard.firstNm}<br/>{standard.first}
                    <div style={{width: '82px', height: '2px', background: standard.firstBg}}></div>
                </div>
                <div className='c2_r ml-3px cl-gray03'>{standard.secondNm}<br/>{standard.second}
                    <div style={{
                        width: '40px',
                        height: '2px',
                        background: standard.secondBg
                    }}></div>
                </div>
            </div>
            <div>
                <Icon iconClass={`indicator w-8px h-8px mt-2px ${standard.indicateLocation}`}/>
            </div>
        </div>)

        return result
    }

    if(data.msreCnt === 0){
        return <div className='daily-card-content'>
                <p className='b2_m'>{isToday? 'TODAY' : data.msreDt}</p>
                <p className='c1_r pt-17px cl-gray03'>{props.focusNm}</p>
                <div className='flex-column h-141px'>
                    <h1 className={`h2_r pt-2px cl-gray03`}>{isToday? '대기 중' : '휴식'}</h1>
                    <p className='b1_l cl-gray05'>{isToday? '곧 잴 거야….' : '맞죠? 다시 재실 거죠…?'}</p>
                </div>
                <div className='border-top-gray mb-32px h-75px'>
                    <div className='flex justify-between align-center mt-25px'>
                        <p className='c1_r cl-gray03'>체성분 측정</p>
                        <p className='b2_r cl-gray03'>-</p>
                    </div>
                    <div className='weight-detail-count-bar'></div>
                </div>
                <div className={'border-top-gray pt-28px flex justify-between pb-24px'}>
                    <p className='c1_r cl-gray03'>목표! 체성분 측정하기</p>
                    <div className={'flex'}>
                        <Icon iconClass='mission-fail w-16px h-16px' />
                        <p className={'c1_r ml-7px cl-gray03'}>{isToday? '대기 중' : '휴식'}</p>
                    </div>
                </div>
            </div>
    } else {
        return <div className='daily-card-content'>
                <p className='b2_m'>{isToday? 'TODAY' : data.msreDt}</p>
                <p className='c1_r pt-17px cl-gray03'>{props.focusNm}</p>
                <div className='h-141px pb-16px flex-column justify-between'>
                    <div className='flex flex-end'>
                        <h1 className='h1_m pt-2px cl-primary-cyan01'>{msreData}</h1>
                        <p className='h6_r pl-2px mb-8px cl-gray03'>{props.focusUnit}</p>
                    </div>
                    <div className={'flex flex-end justify-between'}>
                        <div className='flex-column'>
                            {setStandardGraph()}
                        </div>

                        {data.dvicTp === 'WATCH'?
                            <div className='flex mb-2px'>
                                <Icon iconClass='watch w-8px h-16px mt-2px' />
                                <p className='c1_r ml-7px cl-gray04'>스마트 워치</p>
                            </div>
                            :
                            <div className='flex mb-2px'>
                                <Icon iconClass='weight w-16px h-16px' />
                                <p className='c1_r ml-7px cl-gray04'>체중계</p>
                            </div>}
                    </div>
                </div>
                <div className='border-top-gray mb-32px h-75px'>
                    <div className='flex justify-between align-center mt-25px'>
                        <p className='c1_r cl-gray03'>체성분 측정</p>
                        <p className='b2_r cl-gray03'>{data.msreCnt}회</p>
                    </div>
                    <div className='weight-detail-count-bar'>
                        {convertMsreCountData()}
                    </div>
                </div>
                <div className='border-top-gray pt-28px flex justify-between pb-24px'>
                    <p className='c1_r cl-gray03'>목표! 체성분 측정하기</p>
                    <div className='flex'>
                        <Icon iconClass='mission-success w-16px h-16px' />
                        <p className='c1_r ml-7px cl-gray03'>달성</p>
                    </div>
                </div>
            </div>
    }

}

export default WeightCard