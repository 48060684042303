import React, {useEffect, useState} from "react"
import {Dialog, Slide, SlideProps} from "@mui/material";
import {HealthReportData, ValueData} from "@/service/HealthService";
import Icon from "@/view/common/component/Icon";
import HealthItemDetail from "@/view/health/component/HealthItemDetail";
import HealthItemSummary from "@/view/health/component/HealthItemSummary";

export interface HealthReportItemParam{
    healthData: HealthReportData
}
type arrayData = {
    illness:string,
    value:ValueData[]
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
})

const HealthReportItem = (props: HealthReportItemParam) => {
    const [itemMap, setItemMap] = useState<Map<string, ValueData[]>>(new Map<string, ValueData[]>())
    const [warningArray, setWarningArray] = useState<arrayData[]>([])
    const [dangerArray, setDangerArray] = useState<arrayData[]>([])
    const [itemArray, setItemArray] = useState<arrayData[]>([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [detailParam, setDetailParam] = useState<{title:string, itemList:string[], history: boolean}>({title:'', itemList:[], history: false})

    useEffect(()=>{
        const tempMap = new Map<string,ValueData[]>()
        {props.healthData.resultDetailList.map(data=> tempMap.set(data.illnessName, data.valueList))}
        setItemMap(tempMap)
    }, [props.healthData])

    useEffect(()=>{
        if(itemMap.size > 0){
            const tempItemArray:arrayData[] = []
            const tempWarningArray:arrayData[] = []
            const tempDangerArray:arrayData[] = []
            itemMap.forEach(
                (value, key)=>{
                    tempItemArray.push({illness: key, value: value})
                    const tempWarningList:ValueData[] = []
                    const tempDangerList:ValueData[] = []
                    value.forEach((data)=>{
                        if(data.label === 'warning'){
                            tempWarningList.push(data)
                        }
                        if(data.label === 'danger'){
                            tempDangerList.push(data)
                        }
                    })
                    if(tempWarningList.length > 0) {
                        tempWarningArray.push({illness: key, value: tempWarningList})
                    }
                    if(tempDangerList.length > 0) {
                        tempDangerArray.push({illness: key, value: tempDangerList})
                    }
                }
            )
            setItemArray(tempItemArray)
            setWarningArray(tempWarningArray)
            setDangerArray(tempDangerArray)
        }
    }, [itemMap])

    const handleOpen = (title:string, itemList:string[], history:boolean) =>{
        setDetailParam({title: title, itemList:itemList, history: history})
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const getItemList = (array:arrayData[]):string[] => {
        const valueList = array.map((data)=>{return data.value})
        const tempItemList:string[] = []
        if(array.length > 0) {
            valueList.forEach((data) => {
                data.forEach((d)=>{tempItemList.push(d.labelType)})
            })
        }
        return tempItemList
    }

    return(
        <>
        <div className={'flex-column'}>
            {warningArray.length > 0
                ?
                <div className={'warning-space mt-18px'} onClick={() => handleOpen('주의 항목', getItemList(warningArray), false)}>
                    <div className={'flex-column flex-start'}>
                        <Icon iconClass={'summary-warning w-34px h-18px'}/>
                        <HealthItemSummary list={warningArray}/>
                    </div>
                </div>
                : null
            }
            {dangerArray.length > 0
                ?
                <div className={'danger-space mt-8px'} onClick={() => handleOpen('위험 항목', getItemList(dangerArray), false)}>
                    <div className={'flex-column flex-start'}>
                        <Icon iconClass={'summary-danger w-34px h-18px'}/>
                        <HealthItemSummary list={dangerArray}/>
                    </div>
                </div>
                : null
            }
            {
                itemArray.length > 0
                ?
                    itemArray.map(
                        (value, index) => {
                            return (
                                <div key={index}>
                                    {value.value.length > 0
                                        ?
                                            <div className={`flex-column mb-47px ${index === 0? 'mt-40px': ''}`}>
                                                <p className={'b1_m'}>{value.illness}</p>
                                                <div className={'border-top-gray mt-8px mb-24px'}/>
                                                {value.value.map((data, index) => {
                                                        return (
                                                            <div key={index}
                                                                 className={`flex align-center justify-between ${index > 0 ? 'mt-15px' : ''}`}
                                                                 onClick={() => handleOpen(data.itemName + ' 상세', [data.labelType], true)}>
                                                                <div className={'flex align-center'}>
                                                                    <Icon iconClass={`item-${data.label} w-30px h-18px`}/>
                                                                    <p className={'b2_r ml-6px mt-2px cl-gray03'}>{data.itemName} </p>
                                                                    <p className={'b2_r ml-4px mt-2px cl-gray03'}>|</p>
                                                                    <p className={'b2_r ml-4px mt-2px cl-gray03'}>{data.lawValue}</p>
                                                                </div>
                                                                <div className={'flex align-center'}>
                                                                    <p className={'c1_r cl-gray04 mr-8px mt-2px'}>{data.normalValue}</p>
                                                                    <Icon iconClass={'right-arrow-bold w-16px h-16px mt-2px'}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        : null
                                    }
                                </div>
                            )
                        }
                    )
                : null
            }
        </div>
            <Dialog fullScreen open={dialogOpen} onClose={handleClose} TransitionComponent={Transition}>
                {<HealthItemDetail onClose={handleClose} titleItem={detailParam.title} itemList={detailParam.itemList} history={detailParam.history}/>}
            </Dialog>
        </>
    )
}

export default HealthReportItem