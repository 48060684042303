import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import StepService, {WalkGraphData, WalkGraphRes} from "@/service/StepService";
import StringUtil from "@/common/StringUtil";
import WalkBarChart, {WalkBarChartParam} from "@/view/walk/component/WalkBarChart";

export interface WalkRecordParam{
    fromDate: string;
    month: string;
    periodType: string;
    toDate: string;
    userId: number;
    year: string;
}

const WalkHowFar = (props: WalkRecordParam) => {
    const [data, setData] = useState<WalkGraphData>()
    const [chartParam, setChartParam] = useState<WalkBarChartParam>()

    const {refetch:getWalkRecordTime} = useQuery<WalkGraphRes, AxiosError>(
        ['getWalkRecordTime'],
        ()=> StepService.getWalkTime({fromDate:props.fromDate, toDate:props.toDate
                                                            , month: props.month, year:props.year, userId:props.userId.toString()
                                                            , periodType:props.periodType}),
        {
            onSuccess: (v:WalkGraphRes) => updateGraphData(v.data)
        }
    )

    const updateGraphData = (data:WalkGraphData) => {
        setData({pastData:data.pastData, presentData:data.presentData, recordComment:data.recordComment
                        , recommendComment:data.recommendComment, recommendValue:data.recommendValue
                        , totalDist:data.totalDist, distImageUrl:data.distImageUrl, distComment:data.distComment})
    }

    useEffect(()=>{
        if(props.periodType !== undefined && StringUtil.isNotEmpty(props.periodType)){
            getWalkRecordTime()
                .then(r=>r)
                .catch(reason => console.log(reason))
        }
    },[props])

    useEffect(()=>{
        let data1:number[] = []
        let data2:object[] | number[] | undefined[] = []
        const data3:number[] = []
        let labels:string[] = []
        let maxValue = 5
        let recommendValue = 0
        if(data){
            data1 = data.presentData.map(d=>d.verticalValue)
            data2 = data.pastData.map(d=>d.verticalValue)
            maxValue = Math.ceil(Math.max.apply(null, data1.concat(data2)))
            data2.forEach((d, index)=> {if(Number(d) === 0) data2[index] = undefined})
            labels = data.presentData.map(d=> getLabelData(d.horizontalValue))
            data.presentData.forEach(() => data3.push(data.recommendValue))
            recommendValue = data.recommendValue
        }
        setChartParam({
            labelData1: data1,
            labelData2: data2,
            labelData3: data3,
            maxValue: maxValue,
            labels:labels,
            periodType: props.periodType,
            selectType: 'TIME',
            recommendValue: recommendValue
        })
    }, [data])

    const getLabelData = (label:string) => {
        let result = ''
        if(props.periodType === 'MONTH'){
            if(['7','14','21','28'].includes(label)){
                result = label +'일'
            }
        }else{
            result = label
        }
        return result
    }

    return (
        <div className={'mt-60px flex-column walk-time'}>
            <p className={'s_title_1_m'}>몇 분 걸었지?</p>
            <div className={'gray-bar-thin mt-7px'}></div>
            <p className={'mt-5px cl-gray03 c1_r'}>{data?.recommendComment}</p>
            <p className={'mt-11px b2_l'}>{data?.recordComment}</p>
            <div className={'mt-38px'}>
                <WalkBarChart {...chartParam}/>
            </div>
            {data?.presentData[0].comment?
                <div className={'mt-32px'}>
                    <p className={'b2_l cl-primary-cyan01 bubble prpl-24px text-center'}>{data.presentData[0].comment}</p>
                </div>
                : null
            }
        </div>
    )
}

export default WalkHowFar