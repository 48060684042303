import React, {useEffect, useRef, useState} from 'react'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip,
} from 'chart.js';
import {Chart} from 'react-chartjs-2'
import {Skeleton} from "@mui/material";
import Icon from "@/view/common/component/Icon";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Tooltip,
    Legend
)

export interface WalkBarChartParam{
    labelData1?: number[] | [number,number][] | undefined,
    labelData2?: number[] | object[] | undefined[] | undefined,
    labelData3?: number[] | undefined,
    maxValue?: number | undefined,
    labels?: string[] | undefined,
    periodType?: string | undefined,
    selectType?: string | undefined,
    recommendValue?: number | undefined
}

export type ChartDataSet = {
    type: any,
    label: string,
    backgroundColor?: string,
    borderColor?: string,
    borderWidth?: number,
    borderDash?: number[],
    fill?: boolean,
    data: number[] | object[] | undefined[] | undefined,
    pointRadius?: number,
    order: number,
    hidden?: boolean,
    barPercentage?:number,
    borderRadius?:number,
    borderSkipped?:boolean
}

const WalkBarChart = (props:WalkBarChartParam) => {
    const [data,setData] = useState<{labels:string[], datasets:ChartDataSet[]}>()
    const[options, setOptions] = useState({})
    const max = props.maxValue? props.maxValue : 0
    const chartDivRef = useRef<HTMLDivElement>(null)

    const getCeilMax = () =>{
        if(props.selectType === 'AMOUNT'){
            const temp = Math.ceil(max / 100) * 100
            if(temp <= 400){
                return 500
            }
            return temp
        }else if(props.selectType === 'SPEED'){
            return Math.ceil(max/5) * 5
        }else if(props.selectType === 'TIME'){
            const temp = Math.ceil(max / 100) * 100
            if(temp <= 100){
                return 200
            }
            return temp
        }else{
            return max > 1000? Math.ceil(max /10000) * 10000 : Math.ceil(max/10) * 10
        }
    }

    const ceilMax = getCeilMax()
    const recommendValue = props.recommendValue? props.recommendValue : 0
    const graphMax = ceilMax < recommendValue? recommendValue : ceilMax
    const reportHeight = 240
    const divWidth = chartDivRef.current ? chartDivRef.current.clientWidth : window.innerWidth - 48
    const reportWidth = divWidth * 0.75
    const realReportHeight = reportHeight-40
    const [pastUnitMsg, setPastUnitMsg] = useState<string | undefined>(undefined)
    const [recommendLabelStyle, setRecommendLabelStyle] = useState({})
    const recommendLabelMarginTop = String((1- recommendValue/graphMax)*realReportHeight - 8)+'px'
    const overRecommendHeight = String((1- recommendValue/graphMax)*realReportHeight + 5)+'px'
    const [overRecommendStyle, setOverRecommendStyle] = useState({})
    const [unitStyle, setUnitStyle] = useState({})
    const [unitInfoStyle, setUnitInfoStyle] = useState({})
    const [infos, setInfos] = useState({recommendLabel:'', unitLabel:'', unitInfo:''})


    useEffect(() => {
        initChart()
        updateOptions()
        updatePastUnitMsg()
        updateRecommendLabelStyle(recommendLabelMarginTop)
        updateOverRecommendStyle(overRecommendHeight)
        updateUnitLabel()
    },[props])

    useEffect(()=>{
        updateUnitStyle()
        updateUnitInfoStyle()
    }, [infos])

    const initChart = () => {
        const array:ChartDataSet[] = []
        array.push({
                type: 'bar' as const,
                label: '이번주',
                backgroundColor: '#24CBAA',
                data: props.labelData1,
                //borderColor: 'white',
                barPercentage: 0.4,
                borderRadius: props.selectType === 'SPEED'? 10 : 0,
                borderSkipped: false,
                order:2,
            },
            {
                type: 'line' as const,
                label: '지난주',
                backgroundColor: '#FFFFFF',
                borderColor: '#CDCDCD',
                borderWidth: 1,
                fill: false,
                data: props.labelData2,
                order:1,
            },
            {
                type: 'line' as const,
                label: '권장',
                borderColor: '#24CBAA',
                borderWidth: 1,
                fill: false,
                data: props.labelData3,
                pointRadius: 0,
                order:3,
                hidden:true
            },)

        setData({
            labels : props.labels? props.labels: [''],
            datasets: array,
        })
    }

    const updateOptions = () => {
        setOptions({
            responsive: false,
            animation: false,
            plugins: {
                datalabels: false,
                legend: {
                    display: false,
                    position: 'right',

                },
                tooltip: false,
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxRotation: 0,
                        autoSkip: false,
                        font: {
                            size: 12
                        }
                    },
                },
                y: {
                    grid: {
                        display: false
                    },
                    max: graphMax,
                    ticks: {
                        stepSize: props.selectType === 'SPEED'? 5 : Math.ceil(graphMax/2),
                        //stepSize: props.selectType === 'DISTANCE'? 2 : props.selectType ==='STEP'? 2000: 200,
                        callback: function (value: number) {
                            let result
                            if(value < 10){
                                result = '      ' + value.toString()
                            }else if(value < 1000){
                                result = '  ' + value.toString()
                            }else{
                                result = value.toString()
                                if(props.selectType === 'STEP'){
                                    result = (value / 1000).toString() + 'k'
                                }
                            }
                            return result
                        }
                    },
                },

            },
        })
    }

    const updatePastUnitMsg = () => {

        if(props.periodType === 'WEEK'){
            setPastUnitMsg('지난주')
        }else if(props.periodType === 'MONTH'){
            setPastUnitMsg('지난달')
        }else if(props.periodType === 'YEAR'){
            setPastUnitMsg('지난해')
        }else{
            setPastUnitMsg(undefined)
        }
    }

    const updateRecommendLabelStyle = (recommendLabelMarginTop:string) => {
        setRecommendLabelStyle({
            position:'absolute',
            width: String(reportWidth+30)+'px',
            height: '25px',
            left: '40px',
            borderBottom:'1px solid',
            borderColor:'#24CBAA',
            marginTop: recommendLabelMarginTop
        })
    }

    const updateOverRecommendStyle = (overRecommendHeight:string) => {
        setOverRecommendStyle({
            position:'absolute',
            backgroundColor: 'rgba(173, 230, 223, 0.3)',
            width: String(reportWidth-30)+'px',
            height: overRecommendHeight,
            left: '40px',
            marginTop: '10px',
        })
    }

    const updateUnitStyle = () => {
        setUnitStyle({
            position: 'absolute',
            bottom: 0,
            left: 0
        })
    }

    const updateUnitInfoStyle = () => {
        setUnitInfoStyle({
            position: 'absolute',
            bottom: 0,
            marginBottom: '-12px',
            left: '49px',
            display: infos.unitInfo === ''? 'none' : ''
        })
    }

    const updateUnitLabel = () => {
        switch (props.selectType){
            case 'DISTANCE':
                setInfos({unitLabel:'(km)', recommendLabel:'권장 거리', unitInfo: ''})
                break
            case 'STEP':
                setInfos({unitLabel:'(steps)', recommendLabel: '권장 걸음 수', unitInfo: 'k (kilo) = 1,000'})
                break
            case 'AMOUNT' :
                setInfos({unitLabel:'(kcal)', recommendLabel: '권장\n소모칼로리', unitInfo: ''})
                break
            case 'TIME' :
                setInfos({unitLabel:'(min)', recommendLabel: '권장 시간', unitInfo: ''})
                break
            case 'SPEED' :
                setInfos({unitLabel:'(km/h)', recommendLabel: '권장 속도', unitInfo: ''})
                break
        }
    }

    return (
        <div className={'flex w-100p'} style={{justifyContent: 'center', position:'relative', height: String(reportHeight)+'px'}} ref={chartDivRef}>
        {
            data ?
                <>
                    <div style={{width:String(reportWidth)+'px', position:'absolute', left: 0, zIndex:'2'}} >
                        <Chart type='bar' data={data} options={options} height={String(reportHeight)+'px'} width={String(reportWidth)+'px'}  />
                    </div>
                    <div className={'flex-column'} style={{position:'absolute', left: String(reportWidth + 20)+'px', bottom: 0}}>
                        <div className={'flex'} style={{justifyContent: 'flex-end'}}>
                            {
                                pastUnitMsg?
                                    <>
                                        <Icon iconClass={'report-legend-pointer-gray w-14px h-8px'} />
                                        <div className={'c1_r cl-gray04 pl-2px'}>{pastUnitMsg}</div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                    <div style={overRecommendStyle}/>
                    <div style={recommendLabelStyle}>
                        <div style={{justifyContent: 'flex-end'}} >
                            <div className={'b2_l cl-primary-cyan01'} style={{textAlign: 'right', whiteSpace: 'pre-wrap'}}>{infos.recommendLabel}</div>
                        </div>
                    </div>
                    <div style={unitStyle}>
                        <div className={'c2_r cl-gray04'}>{infos.unitLabel}</div>
                    </div>
                    <div style={unitInfoStyle}>
                        <div className={'c2_r cl-gray04'}>{infos.unitInfo}</div>
                    </div>
                </>
                :
                <Skeleton variant="rounded" height={String(reportHeight)+'px'} width={'100%'}/>
        }
    </div>
    )
}

export default WalkBarChart