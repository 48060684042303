export enum Actions {
    REQUEST_SELF_AUTH = "REQUEST_SELF_AUTH",
}

export declare interface SelfAuthItem {
    sCipherTime?: string//복호화한 시간
    sRequestNumber?: string//요청번호
    sResponseNumber?: string//NICE응답번호
    sAuthType?: string//인증수단
    sName?: string//성명
    sDupInfo?: string//중복가입확인값(DI)
    sConnInfo?: string//연계정보확인값(CI)
    sBirthDate?: string//생년월일(YYYYMMDD)
    sGender?: string//성별
    sNationalInfo?: string//내/외국인정보
    sMobileNo?: string//휴대폰번호
    sMobileCo?: string//통신사
}
