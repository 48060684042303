import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react'
import moment from 'moment'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import StorageUtil from '@/common/StorageUtil'
import {USER_INFO} from '@/common/Constant'
import UserService, {ProfileEditReq, SvcUserData} from '@/service/UserService'
import StringUtil from '@/common/StringUtil'
import Icon from '@/view/common/component/Icon'
import Input from '@/view/common/component/Input'
import Popup from '@/view/common/popup/Popup'
import BottomButton from '@/view/common/component/button/BottomButton'
import GenderSelect from '@/view/common/component/GenderSelect'
import ValidateUtil from '@/common/ValidateUtil'
import {Response} from "@/service/BoardService";

export interface ProfileEditParam{
    onSubmit?: () => void,
}

const ProfileEdit = forwardRef((props: ProfileEditParam, ref) => {
    const userInfo = StorageUtil.getLocalStorage(USER_INFO)
    const user:SvcUserData = StringUtil.jsonString2ObjectWithMap<SvcUserData>(userInfo? userInfo : '')
    const defaultTheme = 'light'
    const [nickName, setNickName] = useState({value:user.nickNm, errMsg : ''})
    const [gender, setGender] = useState({value: ValidateUtil.convertGenderName(user.gender), errMsg:''})
    const [birthday, setBirthday] = useState({value:moment(user.birthday).format('YYYY.MM.DD'), errMsg:''})
    const [tall, setTall] = useState({value:Number(user.tall).toFixed(1), errMsg:''})
    const [isOpen, setIsOpen] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [validateCheck, setValidateCheck] = useState({nickName: true, gender: true, birthday: true, tall: true})
    const [profileItem, setProfileItem] = useState<ProfileEditReq>()
    const submitHandler = useCallback(() => props.onSubmit && props.onSubmit(), [props.onSubmit])

    useImperativeHandle(ref, () => ({
        isChanged: () =>{
            return !buttonDisabled
        }
    }))

    const genderClicked = () => {
        setIsOpen(true)
    }

    const genderSelected = (value:string) => {
        setGender(prevState => ({value: ValidateUtil.convertGenderName(value), errMsg: prevState.errMsg}))
        setIsOpen(false)
    }

    const submitProfile = () => {
        setProfileItem({
            nickNm: nickName.value,
            gender: ValidateUtil.convertGenderValue(gender.value),
            birthday: birthday.value.replaceAll('.',''),
            tall: tall.value,
            pjtNo: 1,
            weight: user.weight? Number(user.weight): 60,
            userEmail: user.userEmail,
            userMobile: user.userMobile
        })
    }
    const {refetch:updateProfile} = useQuery<Response, AxiosError>(
        ['updateProfile'],
        ()=> UserService.updateProfile(profileItem),
        {
            onSuccess: (v: Response) => {reloadUserInfo()}
        }
    )

    const reloadUserInfo = () => {
        UserService.getUser()
            .then(r => {StorageUtil.setLocalStorage(USER_INFO,StringUtil.stringify(r.data)); submitHandler()})
            .catch(reason => console.log(reason))
    }

    useEffect(()=>{
        if(profileItem !== undefined){
            updateProfile()
                .then(res=> res.data)
                .catch(reason => console.log(reason))
        }

    }, [profileItem])

    const validateNickName = async (value: string) => {
        const result = ValidateUtil.checkValidNickName(value)
        let errMsg = result.errMsg
        if(StringUtil.isNotEmpty(result.value) && StringUtil.isEmpty(result.errMsg)){
            await ValidateUtil.checkDuplicate(result.value).then(res=> {
                if(!res){
                    errMsg =  '사용하실 수 없는 닉네임이에요.'
                }
            }).catch(err => {console.log('시스템 에러')})
        }
        setNickName(prevState => ({value: prevState.value, errMsg: errMsg}))
    }

    const clearNickName = () => {
        setNickName({value: '', errMsg: ''})
    }

    const validateBirthday = (value:string) =>{
        const result = ValidateUtil.checkValidBirthday(value)
        setBirthday({value: result.value, errMsg: result.errMsg})
    }

    const clearBirthday = () => {
        setBirthday({value:'', errMsg:''})
    }

    const validateTall = (value:string) => {
        const result = ValidateUtil.checkValidTall(value)
        setTall({value: result.value, errMsg: result.errMsg})
    }

    const clearTall = () => {
        setTall({value: '', errMsg: ''})
    }

    const buttonControl = (item:string, before:string, after:string, required: boolean, errMsg: string) => {
        let disabled = true
        if(canChange(before, after, required, errMsg)){
            disabled = false
        }
        switch (item){
            case 'nickName':
                setValidateCheck(prevState => ({nickName: disabled, gender: prevState.gender, birthday: prevState.birthday, tall: prevState.tall}))
                break
            case 'gender':
                setValidateCheck(prevState => ({nickName: prevState.nickName, gender: disabled, birthday: prevState.birthday, tall: prevState.tall}))
                break
            case 'birthday':
                setValidateCheck(prevState => ({nickName: prevState.nickName, gender: prevState.gender, birthday: disabled, tall: prevState.tall}))
                break
            case 'tall':
                setValidateCheck(prevState => ({nickName: prevState.nickName, gender: prevState.gender, birthday: prevState.birthday, tall: disabled}))
                break
            default:
                break
        }
    }

    useEffect(()=> {
        if(validateCheck.nickName && validateCheck.gender && validateCheck.birthday && validateCheck.tall){
            setButtonDisabled(true)
        }else if(StringUtil.isNotEmpty(nickName.errMsg) || StringUtil.isNotEmpty(gender.errMsg)
                || StringUtil.isNotEmpty(birthday.errMsg) || StringUtil.isNotEmpty(tall.errMsg)){
            setButtonDisabled(true)
        }else{
            setButtonDisabled(false)
        }
    }, [validateCheck])

    const canChange = (before:string, after:string, required: boolean, errMsg: string):boolean => {
        return ((required && !StringUtil.isEmpty(after)) && before !== after && StringUtil.isEmpty(errMsg))
                || (!required && before !== after && StringUtil.isEmpty(errMsg))
    }

    useEffect(()=>{
        if(StringUtil.isEmpty(nickName.value)){
            setNickName(prevState => ({value: prevState.value, errMsg: '닉네임을 입력해주세요'}))
        }else{
            setNickName(prevState => ({value: prevState.value, errMsg: ''}))
        }
        buttonControl('nickName', user.nickNm, nickName.value, true, nickName.errMsg)
    }, [nickName.value])

    useEffect(() => {
        buttonControl('nickName', user.nickNm, nickName.value, true, nickName.errMsg)
    }, [nickName.errMsg])

    useEffect(()=>{
        buttonControl('gender', ValidateUtil.convertGenderName(user.gender), gender.value, true, gender.errMsg)
    }, [gender.value])

    useEffect(() => {
        buttonControl('gender', ValidateUtil.convertGenderName(user.gender), gender.value, true, gender.errMsg)
    }, [gender.errMsg])

    useEffect(()=>{
        validateBirthday(birthday.value)
        buttonControl('birthday', user.birthday, birthday.value.replaceAll('.',''), true, birthday.errMsg)
    }, [birthday.value])

    useEffect(() => {
        buttonControl('birthday', user.birthday, birthday.value.replaceAll('.',''), true, birthday.errMsg)
    }, [birthday.errMsg])

    useEffect(()=>{
        validateTall(tall.value)
        buttonControl('tall', Number(user.tall).toFixed(1), Number(tall.value).toFixed(1), true, tall.errMsg)
    }, [tall.value])

    useEffect(() => {
        buttonControl('tall', Number(user.tall).toFixed(1), Number(tall.value).toFixed(1), true, tall.errMsg)
    }, [tall.errMsg])

    return <>
        <div className={'board profile-edit-list'}>
            <p className={'s_title_1_m cl-gray01 mt-50px'}>{user.userEmail}</p>
            <div className={'mt-40px mb-12px'}>
                <Input theme={defaultTheme}
                       label={'닉네임'}
                       name={'nickName'}
                       value={nickName.value}
                       rightContent={<div className={'click-area'} onClick={() => clearNickName()}><Icon iconClass={'input-delete-l'}/></div>}
                       onChange={(ev) => setNickName(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                       onKeyUp={() => validateNickName(nickName.value)}
                       required={true}
                       errorMsg={nickName.errMsg}
                />
            </div>
            <div className={'mb-12px b1_r cl-gray02'}>
                <Input theme={defaultTheme}
                       label={'성별'}
                       name={'gender'}
                       value={gender.value}
                       rightContent={<div onClick={()=> genderClicked()}><Icon iconClass={'expend-off'}/></div>}
                       required={true}
                       readOnly={true}
                       onClick={() =>genderClicked()}
                       onBlur={() => setGender(prevState => ({value:prevState.value, errMsg:prevState.errMsg}))}
                       errorMsg={gender.errMsg}
                />
            </div>
            <div className={'mb-12px b1_r cl-gray02'}>
                <Input theme={defaultTheme}
                       label={'생년월일'}
                       name={'birthday'}
                       value={birthday.value}
                       rightContent={<div className={'click-area'} onClick={() => clearBirthday()}><Icon iconClass={'input-delete-l'}/></div>}
                       required={true}
                       onChange={(ev) => setBirthday(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                       onBlur={() => validateBirthday(birthday.value)}
                       onKeyUp={() => validateBirthday(birthday.value)}
                       errorMsg={birthday.errMsg}
                       maxLength={8}
                />
            </div>
            <div className={'mb-24px b1_r cl-gray02'}>
                <Input theme={defaultTheme}
                       label={'키'}
                       name={'tall'}
                       value={tall.value}
                       rightContent={<div className={'click-area'} onClick={() => clearTall()}><Icon iconClass={'input-delete-l'}/></div>}
                       required={true}
                       onChange={(ev) => setTall(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                       maxLength={5}
                       errorMsg={tall.errMsg}
                       onBlur={() => validateTall(tall.value)}
                       onKeyUp={() => validateTall(tall.value)}
                />
            </div>
        </div>
        <BottomButton title={'완료'} disabled={buttonDisabled} onClick={submitProfile}/>
        <Popup className='bg-cl-white' title='성별 선택' content={<GenderSelect onClick={genderSelected} selected={gender.value} className={defaultTheme}/>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
    </>
})

export default ProfileEdit