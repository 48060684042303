import React from 'react'
import {HealthReportDetail, HealthReportDetailResult} from "@/service/HealthService";
import HealthItemLineChart from "@/view/health/component/HealthItemLineChart";

const HealthItemChartView = (props: HealthReportDetail) => {

    const getValue = (dataList:HealthReportDetailResult[], index: number):HealthReportDetailResult[] =>{
        const result = [...dataList]
        const seperatedValue = result.map((data)=>{
            if(props.labelType === 'UP'){
                let value = data.lawValue;
                if(data.lawValue === '약양성') value = '0'
                else if(data.lawValue === '음성') value = '-3'
                return {...data, lawValue: value}
            }
            return data.lawValue !== undefined ? {...data, lawValue: data.lawValue.split("/")[index]} : data
        })

        return seperatedValue.sort((a,b)=> {return (a.checkupDate > b.checkupDate) ? 1 : (a.checkupDate < b.checkupDate) ? -1 : 0})
    }

    return(
        <>
            {props.labelType === 'BP' || props.labelType === 'EYE'
            ?   <>
                <div className={'mt-20px'}>
                    <HealthItemLineChart title={props.labelType === 'BP'? '최고 혈압' : '왼쪽 시력'} labelType={props.labelType} annotationDataList={props.annotationList} data={getValue(props.resultList, 0)}/>
                </div>
                <div className={'h-32px'}/>
                <HealthItemLineChart title={props.labelType === 'BP'? '최저 혈압' : '오른쪽 시력'} labelType={props.labelType} annotationDataList={props.subAnnotationList} data={getValue(props.resultList, 1)}/>
                </>
            :   <div className={'mt-20px'}>
                    <HealthItemLineChart labelType={props.labelType} annotationDataList={props.annotationList} data={getValue(props.resultList, 0)}/>
                </div>
            }
        </>
    )
}

export default HealthItemChartView