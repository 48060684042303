import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import { styled } from '@mui/material/styles'
import {TabContext, TabPanel} from "@mui/lab"
import {Tabs} from "@mui/material"
import Tab from '@mui/material/Tab'
import WeightAmount from './WeightAmount'
import WeightHealthy from './WeightHealthy'
import WeightCount from './WeightCount'
import StringUtil from "@/common/StringUtil"
import StorageUtil from "@/common/StorageUtil"
import {USER_INFO} from "@/common/Constant"
import {FocusItem, SvcUserData} from "@/service/UserService"
import DateUtil, {WeekData, MonthData, YearData} from "@/common/DateUtil"
import Icon from "@/view/common/component/Icon"
import {WeightRecordReq} from "@/service/WeightService"

interface StyledTabProps {
    label: string;
    value: string;
}

interface StyledTabsProps {
    children?: React.ReactNode;
    value: string;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
}


interface weightDetailParam{
    focusItemList : FocusItem[] | undefined
}

const WeightRecord = forwardRef((props:weightDetailParam, ref) => {
    const [value, setValue] = React.useState('1')
    const [weekList, setWeekList] = useState<WeekData[] | undefined>(undefined)
    const [monthList, setMonthList] = useState<MonthData[] | undefined>(undefined)
    const [yearList, setYearList] = useState<YearData[] | undefined>(undefined)
    const [_reqParam, setReqParam] = useState<WeightRecordReq>(new WeightRecordReq(0,'','','','','',''))

    const [selectedIListIndex, setSelectedIListIndex] = useState<number | undefined>(undefined)
    const [selectedTitle, setSelectedTitle] = useState<string | undefined>('')

    const [leftIconDisable, setLeftIconDisable] = useState(false)
    const [rightIconDisable, setRightIconDisable] = useState(false)

    const userInfo: SvcUserData  = StringUtil.jsonString2ObjectWithMap<SvcUserData>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
    const userSignupDt = userInfo?.signupDt
    const userId = Number(userInfo?.userId)

    const [itemList,setItemList] = useState<FocusItem[]>(props.focusItemList !== undefined? props.focusItemList : [])

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedIListIndex(0)
        setValue(newValue)
    }

    const StyledTab = styled((props2: StyledTabProps) => (
        <Tab disableRipple {...props2} />
    ))(({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: '#1A1A1A',
        opacity: '0.3',
        '&.Mui-selected': {
            color: '#1A1A1A',
            opacity: '1',
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#1A1A1A',
        },
    }));

    const StyledTabs = styled((props3: StyledTabsProps) => (
        <Tabs
            {...props3}
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            width: '100%'
        },
        '& .MuiTabs-indicatorSpan': {
            maxWidth: 15,
            width: '100%',
            backgroundColor: '#1A1A1A',
        },
    });

    const handlePrev = () => {
        const prev = Number(selectedIListIndex)+1
        setSelectedIListIndex(prev)

    }

    const handleNext = () => {
        const next = Number(selectedIListIndex)-1
        setSelectedIListIndex(next)
    }

    const initData = () => {
        setLeftIconDisable(false)
        setRightIconDisable(false)
    }

    useEffect(()=>{
        if(!weekList && !monthList && !yearList){
            const _weekList:WeekData[] = DateUtil.getWeekList(userSignupDt)
            const _monthList:MonthData[] = DateUtil.getMonthList(userSignupDt)
            const _yearList:YearData[] = DateUtil.getYearList(userSignupDt)
            setWeekList(_weekList)
            setMonthList(_monthList)
            setYearList(_yearList)

            setSelectedIListIndex(0)
        }

    },[])

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            initData()
        }
    }))

    useEffect(()=>{
        initData()
        const index = selectedIListIndex?selectedIListIndex:0
        if(monthList && yearList && weekList){
            switch(value) {
                case '2': {
                    setSelectedTitle(monthList[index].text)
                    if(monthList.length === (index+1)){
                        setLeftIconDisable(true)
                    }
                    setReqParam({periodType:'MONTH', fromDate:'', toDate:'', month:monthList[index].month, year:'', userId:userId, selectType:''})
                    break;
                }
                case '3': {
                    setSelectedTitle(yearList[index].text)
                    if(yearList.length === (index+1)){
                        setLeftIconDisable(true)
                    }
                    setReqParam({periodType:'YEAR', fromDate:'', toDate:'', month:'', year:yearList[index].year, userId:userId, selectType:''})
                    break;
                }
                default: {
                    setSelectedTitle(weekList[index].text)
                    if(weekList.length === (index+1)){
                        setLeftIconDisable(true)
                    }
                    setReqParam({periodType:'WEEK', fromDate:weekList[index].fromDate, toDate:weekList[index].toDate, month:'', year:'', userId:userId, selectType:''})
                    break;
                }
            }
        }

        if(selectedIListIndex === 0){
            setRightIconDisable(true)
        }
    },[selectedIListIndex, value])

    useEffect(() => {
        setItemList(props.focusItemList !== undefined? props.focusItemList : [])
    }, [props.focusItemList])

    return (
               <div className='mrml-24px pt-60px'>
                   <h4 className='h4_l'>RECORD</h4>
                   <TabContext value={value}>
                       <div className={'record-tab-g'}>
                           <StyledTabs
                               value={value}
                               onChange={handleTabChange}
                           >
                               <StyledTab label="WEEK" value='1' />
                               <StyledTab label="MONTH" value='2' />
                               <StyledTab label="YEAR"  value='3'/>
                            </StyledTabs>
                       </div>
                       <div className={'record-search-wrapper mt-32px'}>
                           {
                               leftIconDisable?
                                   <div className='left svg w-20px h-20px' >
                                       <Icon iconClass={'prev-small-disable w-5px h-8px'}/>
                                   </div>
                               :
                                   <div className='left svg w-20px h-20px' onClick={handlePrev}>
                                       <Icon iconClass={'prev-small w-5px h-8px'}/>
                                   </div>
                           }
                           <span className='b2_r'>{selectedTitle}</span>
                           {
                               rightIconDisable?
                                   <div className='right svg w-20px h-20px'>
                                       <Icon iconClass={'next-small-disable w-5px h-8px'}/>
                                   </div>
                               :
                                   <div className='right svg w-20px h-20px' onClick={handleNext}>
                                       <Icon iconClass={'next-small w-5px h-8px'}/>
                                   </div>
                           }
                       </div>
                       <TabPanel value='1' className={'padding-0'}>
                           {
                               _reqParam.periodType==='WEEK'?
                                   <>
                                       <WeightAmount focusItemList={itemList} reqParam={_reqParam}/>
                                       <WeightHealthy reqParam={_reqParam}/>
                                       <WeightCount reqParam={_reqParam}/>
                                   </>
                                   :
                                   <></>
                           }
                       </TabPanel>
                       <TabPanel value='2' className={'padding-0'}>
                           {
                               _reqParam.periodType==='MONTH'?
                                   <>
                                       <WeightAmount focusItemList={itemList} reqParam={_reqParam}/>
                                       <WeightHealthy reqParam={_reqParam}/>
                                       <WeightCount reqParam={_reqParam}/>
                                   </>
                                   :
                                   <></>
                           }
                       </TabPanel>
                       <TabPanel value='3' className={'padding-0'}>
                           {
                               _reqParam.periodType==='YEAR'?
                                   <>
                                       <WeightAmount focusItemList={itemList} reqParam={_reqParam}/>
                                       <WeightHealthy reqParam={_reqParam}/>
                                       <WeightCount reqParam={_reqParam}/>
                                   </>
                                   :
                                   <></>
                           }
                       </TabPanel>
                   </TabContext>
               </div>
    )
})

export default WeightRecord