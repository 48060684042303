import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.scss';
import {FocusItem} from "@/service/UserService"
import StringUtil from "@/common/StringUtil"
import StorageUtil from "@/common/StorageUtil"
import {FOCUS_ITEM, USER_ID} from "@/common/Constant"
import WeightService from "@/service/WeightService"
import {ResponseApi} from "@/service/BoardService"

export interface focusItemSelectParam{
    focusItemList : FocusItem[] | undefined,
    onClose: () => void,
}

type focusItemSelect = {
    value: FocusItem,
    label: string
}

const FocusItemSelectParam = (props:focusItemSelectParam) => {
    const userId = Number(StorageUtil.getLocalStorage(USER_ID)) as unknown as number
    const focusItem:FocusItem = StringUtil.jsonString2ObjectWithMap<FocusItem>(StorageUtil.getLocalStorage(FOCUS_ITEM) as unknown as '')
    const focusNm = focusItem? focusItem.focusNm : '체지방률'
    const [itemList] = useState<FocusItem[]>(props.focusItemList !== undefined? props.focusItemList : [])
    const [selectItem, setSelectItem] = useState<FocusItem | undefined>(undefined)
    const [selectIndex, setSelectIndex] = useState<number | undefined>(undefined)

    useEffect(() => {
        const focusItems:focusItemSelect[] = []
        itemList.map((data,index) => {
                focusItems.push({value: data, label: data.focusNm})
            if(data.focusNm === focusNm) {
                setSelectItem(data)
                setSelectIndex(index)
            }
        })
    },[])

    const {refetch: patchFocusItem} = useQuery<ResponseApi, AxiosError>(
        ['patchFocusItem'],
        ()=> WeightService.updateFocusItem({userId: userId, selectType: selectItem?.focusCd || ''}),
        {
            onSuccess: () => updateStorageFocusItem()
        }
    )

    const onChangeFocusItem = () =>{
        patchFocusItem()
            .then(r => r.data)
            .catch(reason => {console.log(reason)})
    }

    const updateStorageFocusItem = () =>{
        StorageUtil.setLocalStorage(FOCUS_ITEM, StringUtil.stringify(selectItem))
        props.onClose()
    }

    const focusItemSelected = (index:number) => {
        setSelectItem(itemList[index])
    }

    return (
        <>
            <div className={'mrml-24px focus-select'}>
                <div className={'flex-column flex-start scroll-picker'}>
                    {selectIndex !== undefined
                        ?
                            <Swiper
                                direction={"vertical"}
                                centeredSlides={true}
                                slidesPerView={3}
                                initialSlide={selectIndex}
                                onSlideChange={(swiper) => {
                                    focusItemSelected(swiper.activeIndex)
                                }}
                            >
                                {itemList && itemList.map((value, index) => {
                                    return <SwiperSlide key={index}>{value.focusNm}</SwiperSlide>
                                })}
                            </Swiper>
                        : null
                    }
                    <div style={{pointerEvents: "none"}} className={'scroll-picker-selected'}/>
                    <div className={'popup-buttons w-100p mt-24px'}>
                        <button className={'confirm-button'}disabled={false} onClick={() => onChangeFocusItem()}>확인</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FocusItemSelectParam
