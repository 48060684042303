import {rothyClient} from "@/service/RothyClient";

export interface MissionPopupRes{
    data: MissionPopup[]
}

export interface MissionPopup{
    userMissionDtlNo: number,
    popUpTpCd: string,
    popUpUrl: string,
    verNo: number
}

export interface PointPopupRes{
    data: PointPopup
}

export interface PointPopup{
    popupYn: string,
    popupUrl: string,
    pointTitle: string,
    pointContent: string,
    lottie: string,
    bottomText: string
}

export interface UserPopupRes{
    data: UserPopup[]
}

export interface UserPopup{
    userId: number,
    popupId: number,
    popupTitle: string,
    popupCont: string,
    popupUrl: string,
    imgUrl: string,
    popupType: string
}

export interface StepPopUpRes{
    data: StepPopUpData
}

export interface StepPopUpData{
    goalDiff: number,
    nickNm: string,
    popupType: string,
    popupYn: string,
    recommendGoal: number,
    stepDataYn: string,
    stepGoal: number
}

const getMissionPopUp = async () =>
    rothyClient.get<MissionPopupRes>("/userMission/popup").then(res => res.data)

const readMissionPopup = async (userMissionDtlNo:number) =>
    rothyClient.patch("/userMission/popup/read/" + userMissionDtlNo.toString())

const getUserPopUp = async (userId:number) =>
    rothyClient.get<UserPopupRes>("/popup/user", {params: {userId: userId}}).then(res => res.data)

const getPersonalStepPopUp = async (userId:number) =>
    rothyClient.get<StepPopUpRes>("/popup/personal-mission/step", {params: {userId: userId}}).then(res => res.data)

const PopUpService = {
    getMissionPopUp,
    readMissionPopup,
    getUserPopUp,
    getPersonalStepPopUp
}

export default PopUpService