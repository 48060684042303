import React from 'react';
import Header from "@/view/layout/Header";
import Main from "@/view/main/component/Main";
import HeaderSpacer from "@/view/layout/HeaderSpacer";

const MainViewForAdmin: React.FC = (): JSX.Element => {
    return (
            <div className={'bg-cl-gray-7'}>
                <Header />
                <HeaderSpacer className='bg-cl-gray-7 header-tab-spacer'/>
                <Main/>
            </div>
    )
}

export default MainViewForAdmin;
