import moment from "moment-timezone";
import {DATE_FORMAT, ITEM_CD, OS_TYPE, USER_ID} from "@/common/Constant";
import InterfaceUtil from "@/common/Interface";
import UserMissionService, {LstMsreDateForMQ, MeasureListItem} from "@/service/UserMissionService";
import {RHParamHistory, RHSets} from "@/service/rothynative/RNIHealth";
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import StorageUtil from "@/common/StorageUtil";
import {RabbitMqPublish} from "@/service/rothynative/RabbitMqService";

const putHistory = () : Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!InterfaceUtil.isAppRunning()) {
            resolve()
            return
        }

        const userId = Number(StorageUtil.getLocalStorage(USER_ID))

        UserMissionService
            .getLstMsreDateForMQ(userId)
            .then(r => {
                if (InterfaceUtil.getOs() == OS_TYPE.ANDROID) {
                    return getHitoryDataAndroid(r)
                } {
                    return getHitoryDataIos(r)
                }
            })
            .then(r => {
                putMeasure(r)
                    .then(() => resolve())
                    .catch(err => reject(err))
            })
            .catch(err => reject(err))
    })
}

const getHitoryDataAndroid =  async (msreData : LstMsreDateForMQ) : Promise<Array<SHExercise>> => {
    return new Promise((resolve, reject) => {
        const weightConfig = new RNIMessageConfig<RHParamHistory, Array<SHExercise>>()
        weightConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        weightConfig.data = createParam(msreData)

        RothyNativeService.getInstance()
            .postMessage(weightConfig)
            .then((values) => {
                const userId = Number(StorageUtil.getLocalStorage(USER_ID))

                values.map((value, index) => {
                    value.userId = userId
                })

                resolve(values)
            })
            .catch((error: RNIError) => reject(error))
    })
}

const getHitoryDataIos =  async (msreData : LstMsreDateForMQ) : Promise<Array<SHExercise>> => {
    return new Promise((resolve, reject) => {
        resolve(new Array<SHExercise>())
    })
}

const createParam = (msreData : LstMsreDateForMQ): RHParamHistory => {
    const measureList: MeasureListItem[] = msreData.data.measureList
    const findIndex = measureList.findIndex(element => ITEM_CD.EXERCISE.match(element.measureType))
    const measureDatetime = Number(msreData.data.measureList[findIndex].measureDatetime)
    const startDate = moment(measureDatetime).utc().format(DATE_FORMAT.DATE_TIME_UTC)
    const endDate = moment(new Date()).utc().format(DATE_FORMAT.DATE_TIME_UTC)

    const param = new RHParamHistory()
    param.quantityType = RHSets.exercise.history.quantityType
    param.startDate = startDate
    param.endDate = endDate

    return param
}

const putMeasure = async  (meargeData: SHExercise[]) : Promise<void> => {
    return new Promise((resolve, reject) => {
        if (meargeData.length < 1) {
            resolve()
            return
        }

        const rabbitMqConfig = new RNIMessageConfig<RabbitMqPublish<SHExercise[]>, void>()
        rabbitMqConfig.action = RNIActions.RABBIT_MQ_PUBLISH
        rabbitMqConfig.data = {
            host: process.env.RABBIT_MQ_HOST,
            port: Number(process.env.RABBIT_MQ_PORT),
            userName: process.env.RABBIT_MQ_USERNAME,
            password: process.env.RABBIT_MQ_PASSWORD,
            qn: "q.exercise.givita",
            ex: "x.exercise.givita",
            rk: "exercise.user.*",
            quantityType: RHSets.exercise.history.quantityType,
            data: meargeData,
        }

        RothyNativeService.getInstance()
            .postMessage(rabbitMqConfig)
            .then((values) => {
                resolve()
            })
            .catch((error: RNIError) => reject(error))
    })
}

const PutExerciseHistoryService = {
    putHistory
}

export declare interface SHExercise {
    // 사용자ID
    userId: number,
    // 데이터 고유키
    datauuid: string,
    // 데이터 생성 TimeStamp
    createTime: number,
    // 데이터 변경 TimeStamp
    updateTime: number,
    // 패키지 이름
    pkgName: string,
    // 측정 시작 시 UTC 밀리초
    startTime: number,
    // 측정 종료 후 UTC 밀리초
    endTime: number,
    // 사전 정의된 운동 유형
    exerciseType: number,
    // 시간 오프셋(밀리초)
    timeOffset: number,
    // 소모된 칼로리
    calorie: number,
    // 운동 시간
    duration: number,
    // 맞춤 운동 유형
    exerciseCustomType?: string,
    // 거리
    distance?: number,
    // 활동 중 고도 증가
    altitudeGain?: number,
    // 활동 중 고도 감소
    altitudeLoss?: number,
    // 반복 동작 횟수
    count?: number,
    // 반복 동작 유형 30001-스트라이드 또는 스텝 케이던스 유형, 30002-스트로크 케이던스 유형, 30003-스윙 케이던스 유형, 30004-일반 반복 유형
    countType?: number,
    // 최대 속도
    maxSpeed?: number,
    // 평균 속도
    meanSpeed?: number,
    //	최대 소모 칼로리 비율
    maxCaloricburnRate?: number,
    //	평균 소모 칼로리 비율
    meanCaloricburnRate?: number,
    //	최대 케이던스 비율
    maxCadence?: number,
    //	평균 케이던스 비율
    meanCadence?: number,
    //	최대 심박수
    maxHeartRate?: number,
    //	평균 심박수
    meanHeartRate?: number,
    //	최소 심박수
    minHeartRate?: number,
    //	최대 고도
    maxAltitude?: number,
    //	최소 고도
    minAltitude?: number,
    //	오르막 거리
    inclineDistance?: number,
    //	내리막 거리
    declineDistance?: number,
    //	최대 전력
    maxPower?: number,
    //	평균 전력
    meanPower?: number,
    //	평균 rpm(분당 회전 수)
    meanRpm?: number,
    //	최대 rpm(분당 회전 수)
    maxRpm?: number,
    //	운동 중 실시간 데이터(예: 심박수, 속도, 전력 등)
    liveData?: ExcerciseLive[],
    //	운동 중 위치(궤적) 데이터
    locationData?: ExcerciseLocation[],
    //	운동 중 최대 산소 소비량.
    vo2Max?: number,
    //	논평
    comment?: string,
    //	운동의 세부 사항을 표현하기 위한 추가 정보
    additional?: string,
    // JSON 및 압축 데이터로 형식이 지정된 사용자 지정 정보
    custom?: string,
    deviceuuid: string,
    deviceGroup: number,
    dvicTp: number,
}

export declare interface ExcerciseLive {
    startTime?: number
    heartRate?: number
    cadence?: number
    count?: number
    power?: number
    speed?: number
    distance?: number
}

export declare interface ExcerciseLocation {
    startTime?: number
    latitude?: number
    longitude?: number
    altitude?: number
    accuracy?: number
}

export default PutExerciseHistoryService
