import React, {useCallback} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import Terms from "@/view/terms/component/Terms";

interface termsProps {
    onClose: () => void
}

const TermsView: React.FC<termsProps> = (props:termsProps) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    return (
        <>
            <DialogHeader title='약관 및 개인정보 처리동의' onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <Terms/>
            </div>
        </>
    )
}

export default TermsView;