import React from 'react';

export interface BubbleProps {
    message: string,
}

const Bubble = (props: BubbleProps) => {
    return (
        <div className='bubble-speach'>
            <span>{props.message}</span>
        </div>
    );
};

export default Bubble;