import React from 'react'
import StorageUtil from "@/common/StorageUtil";
import {USER_INFO} from "@/common/Constant";
import {SvcUserData} from "@/service/UserService";
import StringUtil from "@/common/StringUtil";

const MissionUserInfo = () => {
    const userInfo = StorageUtil.getLocalStorage(USER_INFO)
    const user:SvcUserData = StringUtil.jsonString2ObjectWithMap<SvcUserData>(userInfo? userInfo : '')
    return (
        <div className={'mt-24px'}>
            <div className={'flex text-left'}>
                <p className={'h6_m'}>안녕하세요&nbsp;</p>
                <p className={'h6_m cl-primary-cyan01'}>{user.nickNm}</p>
                <p className={'h6_m'}>님!</p>
            </div>
        </div>
    )
}

export default MissionUserInfo