import React, {useState} from 'react'
import {useSearchParams, useNavigate, createSearchParams} from "react-router-dom";
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import BottomButton from "@/view/common/component/button/BottomButton";
import LinkHeader from "@/view/layout/LinkHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import {LOGIN} from "@/common/Constant"


export const NoSignUpEmail = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const [_email] = useState(searchParam.get("email"))
    const goSignUp = () => {
        navigate({pathname: '/signup', search: createSearchParams(new URLSearchParams({email: _email === null? '': _email.toString()})).toString()})
    }

    return (
        <>
            <LinkHeader leftIcon={'prev-w'} leftLink={'/emailLogin'}/>
            <HeaderSpacer className='bg-cl-black'/>
            <div id='NoSignUpEmail' className={'bg-cl-black'}>
                <div className={'main-content text-left'}>
                    <div className={'h5_m title mt-39px'}>가입한 적 없는<br/>이메일이에요</div>
                    <div className={'b2_r title mt-16px ft-cl-hint'}>아직도 ROTHY를 사용하지 않고 계셨나요?<br/>
                        지금 바로 가입하고 시작해 보세요</div>
                    <div className={'terms-content'}>
                        <div className={'box'}>
                            <div className={'b1_r'}>{_email}</div>
                        </div>
                    </div>
                </div>
                <BottomButton title={'회원가입 하기'} disabled={false} onClick={() => goSignUp()} />
            </div>
        </>
    )

}

export default NoSignUpEmail