import React from 'react'
import IconVitaIndexDataNull from "@/assets/lottie/icon_vitaindex_data_null_221019.json"
import LottieAnimation from "@/view/common/component/LottieAnimation";

const CollectNotYet = () => {

    return (
        <div className='mrml-24px flex-column mt-210px' style={{alignItems: 'center'}}>
            <LottieAnimation animationData={IconVitaIndexDataNull} lottieStyle={{width: '312px', height:'130px', margin: '0 auto'}}/>
             <div className={'b2_m'}>데이터 가져오는 중</div>
            <div className={'b2_r cl-gray02 mt-4px'}>조금만 기다려주세요!</div>
        </div>
    )
}

export default CollectNotYet