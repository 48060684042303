import React, {useEffect, useState} from 'react'
import {Chart} from "chart.js"
import {Line} from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'
import datalabelsPlugin from 'chartjs-plugin-datalabels'
Chart.register(annotationPlugin, datalabelsPlugin)
import {GraphAnnotation, HealthReportDetailResult} from "@/service/HealthService";

type LineChartDataSet = {
    data: number[] | object[] | undefined[] | undefined | string,
    backgroundColor: string,
    borderColor: string,
    borderWidth: number
}
export interface HealthItemLineChartParam{
    title?: string,
    labelType: string,
    annotationDataList: GraphAnnotation[],
    data:  HealthReportDetailResult[]
}

type annotationType = {
    type: string,
    borderColor: string,
    borderDash: number[],
    borderWidth: number,
    scaleID: string,
    value: number | null
}

const HealthItemLineChart = (props: HealthItemLineChartParam) =>{
    const [data,setData] = useState<{labels:string[],datasets:LineChartDataSet[]}>({labels:[''],datasets:[]})
    const [options, setOptions] = useState({})
    const [annotationList, setAnnotationList] = useState<annotationType[]>()

    useEffect(() => {
        if(props.data.length > 0) {
            updateAnnotations()
        }
    },[props])

    useEffect(()=>{
        updateOptions()
        initChart()
    }, [annotationList])


    const updateAnnotations = () => {
        const tempList:annotationType[] = []
        props.annotationDataList.forEach((data) => {
            tempList.push({
                type: 'line',
                borderColor: data.color,
                borderDash: [2,2],
                borderWidth: 1.5,
                scaleID: 'y',
                value: Number(data.value),
            })
        })

        setAnnotationList(tempList)
    }

    const updateOptions = () => {
        setOptions({
            responsive: true,
            animation: false,
            plugins: {
                legend: false,
                title: props.title?
                    {   display: true,
                        text: props.title,
                        align: 'start',
                        color: '#616161',
                        font: { size: 14,
                                family: 'SpoqaHanSansNeo',
                                weight: '400',
                                lineHeight: '22px'}
                    }
                    : false,
                annotation:{
                    annotations: annotationList,
                },
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    color: '#616161',
                    formatter : function(value: number) {
                        if(props.labelType === 'UP' && value === -3) {
                            return '음성'
                        }
                        else if(props.labelType === 'UP' && value === 0){
                            return '약양성'
                        }
                        return value
                    }
                }
            },
            scales: {
                x: {
                    offset: true,
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: '12',
                            family: 'SpoqaHanSansNeo',
                            lineHeight: '18px',
                            weight: '400'
                        },
                        color: '#AAAAAA',
                        getLabelForValue(value: number): string {
                            return value as unknown as string
                        },
                        callback: function(value: number) {
                            return this.getLabelForValue(value)
                        }
                    },
                },
                y: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                }
            },

            elements: {
                point: {
                    pointStyle: 'circle',
                    radius: 4,
                    color: '#414141'
                }
            },

        })
    }

    const initChart = () => {
        const labelData : string[] = []
        const array = []

        props.data.forEach((result)=>{
            labelData.push( `'`+ result.checkupDate.substring(2,4))
            if(result.lawValue === '0' ) result.lawValue = undefined
        })

        const currentData = { data: props.data.map((d)=>{return Number(d.lawValue)}), borderColor: '#414141', backgroundColor: '#FFFFFF', borderWidth: 2}
        array.push(currentData)

        setData({
            labels : labelData,
            datasets: array,
        })
    }

    return <Line height={'200px'} options={options} data={data} />
}

export default HealthItemLineChart