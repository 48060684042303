import React from 'react'
import {Skeleton} from "@mui/material";
import Icon from "@/view/common/component/Icon";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import {USER_INFO} from "@/common/Constant";
import {UserInfo} from "@/view/menu/MenuView";

export interface WalkDistanceParam{
    showYn: string,
    step: number
    calorie:number,
    distance: number,
    pointCount: number,
    startPoint: string,
    endPoint: string,
    recordComment: string,
    recordImageUrl: string
}

const WalkDistance = (props: WalkDistanceParam) => {
    const userInfo: UserInfo  = StringUtil.jsonString2ObjectWithMap<UserInfo>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
    const userNickNm = userInfo?.nickNm
    return (
        <div className={'mt-32px walk-distance'}>
            <p className={'s_title_1_m'}>ROTHY와 함께한 거리</p>
            <div className={'mt-4px gray-bar h-1px'}></div>
            <p className={'b2_l mt-18px mb-20px record-comment'}>{props.showYn ==='Y' ? props.recordComment : 'ROTHY와 건강습관을 만들어 갈 ' +userNickNm +'님! 환영합니다.\n지금 당장 ROTHY와 함께 걸어볼까요?'}</p>
            {props.showYn === 'LOADING'
                ?
                    <Skeleton variant="rounded" animation="wave" height={300}/>
                :
                    props.showYn === 'N'
                    ?
                    <div className={'mt-24px'}>
                        <Icon iconClass={'no-distance w-100p h-150px'}/>
                    </div>
                    :
                    <div className={'reverse-mrml-24px distance-info'}>
                        <img width={'100%'} height={'fit-content'} src={props.recordImageUrl}/>
                        <div className={'distance-summary'}>
                            {props.pointCount > 0
                                ?
                                <>
                                <div className={'flex align-center'}>
                                    <p className={'bubble cl-primary-cyan01 c1_m pl-7px pr-7px'}>{props.startPoint}</p>
                                    {props.pointCount === 2
                                        ? <>
                                            <div className={'ml-3px mr-3px'}>
                                                <Icon iconClass={'right-arrow w-17px h-12px'}/>
                                            </div>
                                            <p className={'bubble cl-primary-cyan01 c1_m pl-7px pr-7px'}>{props.endPoint}</p>
                                        </>
                                        : null}
                                </div>
                                <div className={'flex align-center mt-14px mb-8px'}>
                                    <div className={'flex-column mr-25px'}>
                                        <p className={'cl-primary-cyan01 s_title_1_m'}>{StringUtil.addComma(Number(props.distance.toFixed(1)))}</p>
                                        <p className={'cl-primary-cyan01 c1_r'}>KM</p>
                                    </div>
                                    <div className={'flex-column mr-25px'}>
                                        <p className={'s_title_1_m'}>{StringUtil.addComma(props.step)}</p>
                                        <p className={'c1_r'}>STEPS</p>
                                    </div>
                                    <div className={'flex-column mr-25px'}>
                                        <p className={'s_title_1_m'}>{StringUtil.addComma(props.calorie)}</p>
                                        <p className={'c1_r'}>KCAL</p>
                                    </div>
                                </div>
                                </>
                                : null
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

export default WalkDistance
