import React, {useState} from 'react'
import {useSearchParams, useNavigate, createSearchParams} from "react-router-dom";
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import BottomButton from "@/view/common/component/button/BottomButton";
import LinkHeader from "@/view/layout/LinkHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";


export const EmailSignUpEmail = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const [_email] = useState(searchParam.get("email"))
    const goSignUp = () => {
        navigate({pathname: '/emailLogin', search: createSearchParams(new URLSearchParams({email: _email === null? '': _email.toString()})).toString()})
    }


    return (
        <>
            <LinkHeader leftIcon={'prev-w'} leftLink={'/login'}/>
            <HeaderSpacer className='bg-cl-black'/>
            <div id='SnsSignUpEmail' className={'bg-cl-black'}>
                <div className={'main-content text-left'}>
                    <div className={'h5_m title mt-39px'}>이메일로<br/>로그인 해주세요</div>
                    <div className={'b2_r title mt-16px ft-cl-hint'}>이메일 회원가입으로 진행한 계정이에요.
                        <br/>
                        이메일과 비밀번호로 로그인을 해주세요.</div>
                </div>
                <BottomButton title={'이메일 로그인 하기'} disabled={false} onClick={() => goSignUp()} />
            </div>
        </>
    )

}

export default EmailSignUpEmail