import React, {useEffect, useState} from 'react'
import {useQuery} from '@tanstack/react-query'
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom'
import {AxiosError} from "axios";
import { createBrowserHistory} from 'history'
import StorageUtil from "@/common/StorageUtil";
import {ACCESS_TOKEN, APP_VERSION_NO, DEVICE_MODEL, LOGIN_TYPE, USER_ID, USER_INFO, REFRESH_TOKEN} from '@/common/Constant'
import UserService, {SvcUser, SvcUserData} from "@/service/UserService";
import StringUtil from "@/common/StringUtil";
import Splash from "@/view/layout/Splash";
import RothyNativeService, {
    RNIActions, RNIAppInfo,
    RNIDeviceInfo,
    RNIError,
    RNIMessageConfig
} from "@/service/rothynative/RothyNativeService";
import interfaceUtil from "@/common/Interface"
import RothyFireBase from "@/service/FireBaseService";
type LogoutMsg = {
    type : string
}
const rotyFireBase : RothyFireBase = RothyFireBase.getInstance()

const AuthenticationView = () => {
    const [searchParam] = useSearchParams()
    const navigate = useNavigate()
    const history = createBrowserHistory();
    const location = history.location
    const state = location.state as LogoutMsg
    const type = state?.type
    const data: LogoutMsg = { type: LOGIN_TYPE.EXPIRED};
    const [isMsg,setIsMsg] = useState(false)


    const initAuthentication = () => {
        getrDeviceInfo()

        if(interfaceUtil.isAppRunning())
            getAppInfo()
        else
            movePage()
    }

    const movePage = () => {
        if(StringUtil.isNotEmpty(searchParam.get("type")) && searchParam.get("type") === 'popupUser'){
            const userInfoData: string | null = searchParam.get("userInfoData")
            const userInfoObj: SvcUserData = StringUtil.jsonString2ObjectWithMap(userInfoData?userInfoData:'')
            const userId = userInfoObj.userId
            const token = userInfoObj.token

            const refreshToken = userInfoObj.refreshToken
            StorageUtil.setLocalStorage(USER_INFO, userInfoData?userInfoData:'')
            StorageUtil.setLocalStorage(USER_ID, String(userId?userId:0))
            StorageUtil.setLocalStorage(ACCESS_TOKEN, token)
            StorageUtil.setLocalStorage(REFRESH_TOKEN, refreshToken)

            void getUser()

        }else if(type === LOGIN_TYPE.EXPIRED){
            navigate({pathname: '/login', search: createSearchParams(data).toString()})
        }else if(StringUtil.isEmpty(StorageUtil.getLocalStorage(USER_ID))
            || StringUtil.isEmpty(StorageUtil.getLocalStorage(USER_INFO))
            || StringUtil.isEmpty(StorageUtil.getLocalStorage(ACCESS_TOKEN))
        ) {
            goLogin()
        }else{
            void getUser()   // token 유효성 검사를 위해서 호출
        }
    }

    const goUpdate = () => {
        console.debug("App Update!!")
        alert('새로운 버젼을 설치 해 주세요')
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_URL
        config.data = "https://apps.apple.com/us/app/%EB%A1%9C%EB%94%94-rothy/id6444061298"
        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open url success")        }
            )
            .catch((error) => {console.log(error)})
    }

    const checkAppUpdate = (appVer:string)  => {


        console.debug("*************************version check")
        rotyFireBase.getRequireVersion(interfaceUtil.getOs()).then(r => {
                appVer = appVer.replaceAll(".","")
                console.debug("********my version ".concat(appVer))
                console.debug(r)
                if(appVer <  r){
                    console.debug(" firebase가 크다")
                    setIsMsg(true)
                    goUpdate()
                }else{
                    movePage()
                }
        }).catch(err => {console.log('version check err');console.log(err)})

    }

    const getrDeviceInfo = () => {
        const config = new RNIMessageConfig<void, RNIDeviceInfo>()
        config.action = RNIActions.GET_DEVICE_INFO

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIDeviceInfo) => {
                const deviceModel = response.model?response.model:''
                StorageUtil.setLocalStorage(DEVICE_MODEL, deviceModel)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const getAppInfo = () => {
        const config = new RNIMessageConfig<void, RNIAppInfo>()
        config.action = RNIActions.GET_APP_INFO

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIAppInfo) => {
                const appVersionNo = response.shortVersion?response.shortVersion:''
                StorageUtil.setLocalStorage(APP_VERSION_NO, appVersionNo)
                checkAppUpdate(appVersionNo)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const {refetch: getUser} = useQuery<SvcUser, AxiosError>(
        ['getUser'],
        ()=> UserService.getUser(),
        {
            onSuccess: () => onSuccess(),
            onError: () => onError()
        }
    )

    const onSuccess = () => {
        goMain()
    }

    const onError = () => {
        goLogin()
    }

    const goMain = () => {
        if(StringUtil.isNotEmpty(searchParam.get("type")) && searchParam.get("type") === 'popupUser') {
            navigate('/mainForAdmin')
        }else{
            navigate('/main')
        }
    }

    const goLogin = () => {
        navigate('/login')
    }

    useEffect(()=> {
        setTimeout(function() { // Code here
            initAuthentication()
        }, 500);
    },[])

    return(
        <>
            <div id="rootBg-white"></div>
            <Splash />
        </>
    )
}



export default AuthenticationView