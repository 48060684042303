import NaverLogo from "@/assets/image/naver_logo.png";
import NaverBigLogo from "@/assets/image/naver_big_logo.png";
import KakaoLogo from "@/assets/image/kakao_logo.png";
import KakaoBigLogo from "@/assets/image/kakao_big_logo.png";
import PassLogo from "@/assets/image/pass_logo.png";
import PassBigLogo from "@/assets/image/pass_big_logo.png";

export const ACCESS_TOKEN = 'accessToken'
export const USER_ID = 'userId'
export const USER_INFO = 'userInfo'
export const LINK_DVIC = 'linkDvic'
export const REFRESH_TOKEN = 'refreshToken'
export const DEVICE_MODEL = 'deviceModel'
export const APP_VERSION_NO = 'appVerNo'
export const COMP_CD = 'compCd'
export const MNBR_NTRY_SE_CD = 'mnbrNtrySeCd'
export const FOCUS_ITEM = 'focusItem'
export const POINT_POPUP = 'pointPopup'
export const SMART_STORE_URI = 'http://bit.ly/3J3nrpu'
export const HEALTH_REPORT_PLUS_INFO = 'healthReportPlusInfo'
export const HEALTH_AGREE = 'healthAgree'


export const MESSAGE = {
    'ERROR1': '일시적인 오류가 발생했어요.'
    ,'ERROR2': '로디 홈페이지(rothy.io) 채널톡으로'
    ,'ERROR3': '알려주시면 빠르게 도와드릴게요.'
    , 'LOGIN_EXPIRED': '로그인 정보가 만료되었습니다.'
    , 'AUTHENTICATION_FAIL': '인증에 실패하였습니다. 관리자에게 문의 하세요.'
    , 'LOGOUT': '로그아웃 되었습니다.'
    , 'DELETE_USER' : '계정 삭제가 완료되었습니다'
    , 'TERMS_AGREE_Y' : '\nROTHY의 선택정보 수집 및 이용동의가 철회되었습니다.'
    , 'TERMS_AGREE_N' : '\nROTHY의 선택정보 수집 및 이용에 동의하셨습니다.'
}

export const LOGIN_TYPE = {
    'LOGOUT': 'logout'
    , 'EXPIRED': 'expired'
    , 'DELETE_USER' : 'deleteUser'
}

export const CONFIRM = {
    'LOGOUT': {
        'LBTN':'로그아웃'
        , 'RBTN':'취소'
        , 'CONTENT':'로그아웃 하시겠습니까?'
    },
    'TERMS_AGREE': {
        'LBTN':'철회하기'
        , 'RBTN':'취소'
        , 'CONTENT':'선택정보 수집 및 이용약관을 동의하시면\n' +
            '맞춤 정보를 제공해드려요. \n' +
            '정말 철회하시겠어요?'
    },
}

export const DELETE_USER = {
    'BTN': '계정삭제',
    'NOTI_LINE1': '관계법령 및 내부방침에 의해 보존하도록 규정된 개인정보는',
    'NOTI_LINE2': '해당 기간까지만 보관되며 그 외의 개인정보는 바로 삭제됩니다',
    'CONTS_LINE1': '계정을 삭제하시면',
    'CONTS_LINE2_1': '일간 함께 쌓아온',
    'CONTS_LINE2_2': '지금까지 입력한',
    'CONTS_LINE3': '기록들이 사라져요'

}

export const ITEM_CD = {
    'STEP'  : 'STEP',
    'WEIGHT': 'WEIGHT',
    'SLEEP' : 'SLEEP',
    'HEART_RATE' : 'HEART_RATE',
    'FLOOR' : 'FLOOR',
    'EXERCISE' : 'EXERCISE',
}

export const LOGIN = {
    'TYPE': {
        'GOOGLE': 'G',
        'NAVER': 'N',
        'KAKAO': 'K',
        'EMAIL': 'E',
        'APPLE': 'A'
    },
    'HNAME': {
        'G': '구글',
        'K': '카카오',
        'N': '네이버',
        'E': '이메일',
        'A': '애플'
    },
    'TITLE': {
        'SNS': 'SNS로 시작',
        'EMAIL': '이메일로 시작하기'
    },
    'SKIP':'skip',
    'START_NOW':'바로 시작하기',
    'NEXT':'다음',
    'EMAIL_LOGIN':'이메일로 로그인',
    'SIGN_UP': {
        'MESSAGE1': '계정이 없으신가요?',
        'EMAIL': '이메일 회원가입'
    },

}

export const FIREBASE = {
    'ROOT_PATH': process.env.FIRE_ROOT as string,
    'PUT_MEASURE':'putMeasure/',
    'PUT_MEASURE_HISTORY':'putMeasureHistory/',
    'STEP':'step/',
    'SLEEP':'sleep/',
    'WEIGHT': 'weight/',
    'USER_ID' : 'userId_'
}

export const YN = {
    'Y':'Y',
    'N':'N'

}

export const DATE_FORMAT = {
    'DAY':'YYYY-MM-DD',
    'DAY_DOT':'YY.MM.DD',
    'DATE_TIME':'YYYY-MM-DD HH:mm:ss',
    'DATE_TIME_UTC':'YYYY-MM-DDTHH:mm:ss'

}

export const EVENT_TYPE = {
    'STEP_DAY':'STEP_DAY',
    'STEP_HISTORY':'STEP_HISTORY',
    'WALK_SET_LISTENER':'WALK_SET_LISTENER'
}

export const DEFAULT_DVIC_NM = {
    'SCALE':'내체중계'
}

export const DVIC_TYPE = {
    'SCALE':'SCALE'
}


export const GENDER = {
    'MALE':'M',
    'FEMALE': 'F'
}

export enum PutMeasureType {
    'ALL' = 'ALL',
    'STEP' = 'STEP',
    'SLEEP' = 'SLEEP',
    'WEIGHT' = 'WEIGHT',
    'HEART_RATE' = 'HEART_RATE',
    'FLOOR' = 'FLOOR',
    'EXERCISE' = 'EXERCISE',
}

export enum OS_TYPE {
    'IOS'='ios',
    'ANDROID'='android',
    'WEB'='web'
}


export type authType ={
    title: string,
    logo: string,
    bigLogo: string,
    info: string[],
    privateAuthType: string
}
export const defaultAuthType:authType[] = [
    {
        title: '네이버',
        logo: NaverLogo as string,
        bigLogo: NaverBigLogo as string,
        info: ['네이버 앱이 설치되어 있는지 확인해 주세요', '네이버 앱에서 종모양 아이콘을 누르고 \'네이버 인증서\'가 보낸 메세지를 눌러주세요'
                , '문제가 계속되면 네이버 앱 내의 도움말이나 네이버 고객센터 (1588-3820)로 문의해 주세요'],
        privateAuthType: '6'
    },
    {
        title: '카카오톡',
        logo: KakaoLogo as string,
        bigLogo: KakaoBigLogo as string,
        info: ['카카오톡 앱이 설치되어 있는지 확인해 주세요', '카카오 앱 내의 고객센터에서 해결 방법을 찾아보실 수 있어요'
                , '문제가 계속되면 [문의하기]나 [챗봇상담]을 통해 문의해 주세요'],
        privateAuthType: '0'
    },
    {
        title: 'PASS',
        logo: PassLogo as string,
        bigLogo: PassBigLogo as string,
        info: ['이전 화면으로 돌아가 이름, 생년월일, 휴대전화번호를 정확하게 입력했는지 확인해 주세요', 'PASS 앱이 설치되어 있는지 확인해 주세요'
                , 'PASS 앱에서 [인증서]를 누르면 인증 요청을 확인하실 수 있어요'
                , '알림 수신 동의가 되어있는지 확인해주세요', '문제가 계속되면 PASS 고객센터 (1800-4273)로 문의해 주세요'],
        privateAuthType: '4'
    }
]

export const exceptOrderList = ['HEM', 'HDL', 'GFR']

import scoreFeatureTotalWalking from "@/assets/image/vitaIndex/scoreFeatureTotalWalking.png"
import scoreFeatureTotalSleep from "@/assets/image/vitaIndex/scoreFeatureTotalSleep.png"
import scoreFeatureTotalBody from "@/assets/image/vitaIndex/scoreFeatureTotalBody.png"
import scoreFeatureTotalZeroScore from "@/assets/image/vitaIndex/scoreFeatureTotalZeroScore.png"
import scoreFeatureWalkingStepCount from "@/assets/image/vitaIndex/scoreFeatureWalkingStepCount.png"
import scoreFeatureWalkingStepSpeed from "@/assets/image/vitaIndex/scoreFeatureWalkingStepSpeed.png"
import scoreFeatureWalkingCadence from "@/assets/image/vitaIndex/scoreFeatureWalkingCadence.png"
import scoreFeatureWalkingZeroScore from "@/assets/image/vitaIndex/scoreFeatureWalkingZeroScore.png"
import scoreFeatureSleepSleepEfficiency from "@/assets/image/vitaIndex/scoreFeatureSleepSleepEfficiency.png"
import scoreFeatureSleepSleepMeasurement from "@/assets/image/vitaIndex/scoreFeatureSleepSleepMeasurement.png"
import scoreFeatureSleepSleepTime from "@/assets/image/vitaIndex/scoreFeatureSleepSleepTime.png"
import scoreFeatureSleepSleepRegularity from "@/assets/image/vitaIndex/scoreFeatureSleepSleepRegularity.png"
import scoreFeatureSleepZeroScore from "@/assets/image/vitaIndex/scoreFeatureSleepZeroScore.png"
import scoreFeatureBodyBodyBMI from "@/assets/image/vitaIndex/scoreFeatureBodyBodyBMI.png"
import scoreFeatureBodyBodyFMI from "@/assets/image/vitaIndex/scoreFeatureBodyBodyFMI.png"
import scoreFeatureBodyBodyBMR from "@/assets/image/vitaIndex/scoreFeatureBodyBodyBMR.png"
import scoreFeatureBodyBodyWeightAdjustedASM from "@/assets/image/vitaIndex/scoreFeatureBodyBodyWeightAdjustedASM.png"
import scoreFeatureBodyBodyHeightAdjustedASM from "@/assets/image/vitaIndex/scoreFeatureBodyBodyHeightAdjustedASM.png"
import scoreFeatureBodyBodyMeasurement from "@/assets/image/vitaIndex/scoreFeatureBodyBodyMeasurement.png"
import scoreFeatureBodyZeroScore from "@/assets/image/vitaIndex/scoreFeatureBodyZeroScore.png"

import warningHabitWalk from "@/assets/image/vitaIndex/warningHabitWalk.png"
import warningHabitSleep from "@/assets/image/vitaIndex/warningHabitSleep.png"
import warningHabitBody from "@/assets/image/vitaIndex/warningHabitBody.png"

import scoreTipListWalk from "@/assets/image/vitaIndex/scoreTipListWalk.png"
import scoreTipListSleep from "@/assets/image/vitaIndex/scoreTipListSleep.png"
import scoreTipListBody from "@/assets/image/vitaIndex/scoreTipListBody.png"

export const VitaIndexImage = {
    scoreCharacter:{
        'scoreFeature-total-walking': scoreFeatureTotalWalking as string,
        'scoreFeature-total-sleep': scoreFeatureTotalSleep as string,
        'scoreFeature-total-body': scoreFeatureTotalBody as string,
        'scoreFeature-total-zeroScore': scoreFeatureTotalZeroScore as string,
        'scoreFeature-walking-stepCount': scoreFeatureWalkingStepCount as string,
        'scoreFeature-walking-stepSpeed': scoreFeatureWalkingStepSpeed as string,
        'scoreFeature-walking-cadence': scoreFeatureWalkingCadence as string,
        'scoreFeature-walking-zeroScore': scoreFeatureWalkingZeroScore as string,
        'scoreFeature-sleep-sleepEfficiency': scoreFeatureSleepSleepEfficiency as string,
        'scoreFeature-sleep-sleepMeasurement': scoreFeatureSleepSleepMeasurement as string,
        'scoreFeature-sleep-sleepTime': scoreFeatureSleepSleepTime as string,
        'scoreFeature-sleep-sleepRegularity': scoreFeatureSleepSleepRegularity as string,
        'scoreFeature-sleep-zeroScore': scoreFeatureSleepZeroScore as string,
        'scoreFeature-body-bodyBMI': scoreFeatureBodyBodyBMI as string,
        'scoreFeature-body-bodyFMI': scoreFeatureBodyBodyFMI as string,
        'scoreFeature-body-bodyBMR': scoreFeatureBodyBodyBMR as string,
        'scoreFeature-body-bodyWeightAdjustedASM': scoreFeatureBodyBodyWeightAdjustedASM as string,
        'scoreFeature-body-bodyHeightAdjustedASM': scoreFeatureBodyBodyHeightAdjustedASM as string,
        'scoreFeature-body-bodyMeasurement': scoreFeatureBodyBodyMeasurement as string,
        'scoreFeature-body-zeroScore': scoreFeatureBodyZeroScore as string,
    },
    warningHabit: {
        'walk': warningHabitWalk as string,
        'sleep': warningHabitSleep as string,
        'body': warningHabitBody as string,
    },
    scoreTipList:{
        'walk': scoreTipListWalk as string,
        'sleep': scoreTipListSleep as string,
        'body': scoreTipListBody as string,
    }
}


