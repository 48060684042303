import React from 'react'
import Icon from '@/view/common/component/Icon'
import InterfaceUtil from "@/common/Interface";

export interface ListItem {
    name: string,
    value: string
}

export interface ListSelectParam{
    onClick: (value:string) => void,
    selected: string,
    className: string,
    itemList: ListItem[]
}

const ListSelect = (props:ListSelectParam) => {
    return (
        <>
            <div className={'custom-select'}>
                <div className={`${props.className}`}>
                    {props.itemList.map((data, index)=>{
                        return(<div key={index} className={`select-items ${index === props.itemList.length-1? 'mb-8px':''} ${data.name === props.selected?'item-selected' : ''}`} onClick={() => props.onClick(data.value)}>
                                    <p className={'b2_r'}>{data.name}</p>
                                    <div id ={'select-item-'+ index.toString()} style={{display: data.name === props.selected? '': 'none'}}><Icon iconClass={'select-box-selected'}/></div>
                               </div>)
                    })}
                </div>
            </div>
            {
                InterfaceUtil.getOs() === 'ios' &&  <div className={'w-100p h-34px'}></div>
            }
        </>
    )
}

export default ListSelect