import React from 'react'

const HealthRecordInfo = () =>{
    return(
        <div className={'flex-column mrml-24px'}>
            <p className={'h6_m'}>검진 기록은 무엇이 나오나요?</p>
            <p className={'mt-16px s_title_2_r'}>ROTHY는 국민건강보험공단에 등록된 검진 결과를 불러와서 보여드리고 있어요</p>
            <p className={'mt-16px s_title_2_r'}>만약 검진 기록을 불러왔는데 나오지 않는다면 국민건강보험공단 고객센터 1577-1000로 문의하여 검진 기록을 확인하신 뒤 이용해 주세요</p>
            <div className={'mt-60px'}/>
        </div>
    )
}

export default HealthRecordInfo