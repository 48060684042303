import React, {useCallback, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Dialog, Slide, SlideProps} from "@mui/material";
import moment from "moment/moment";
import Icon from "@/view/common/component/Icon";
import DialogHeader from "@/view/layout/DialogHeader";
import TermsDetail from "@/view/terms/component/TermsDetail";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import BottomButton from "@/view/common/component/button/BottomButton";
import BoardService, {Response, SingleDataResponse, TermsAgreeData} from '@/service/BoardService';
import Confirm from "@/view/common/popup/Confirm";
import {CONFIRM, DATE_FORMAT, MESSAGE} from "@/common/Constant";
import Alert from "@/view/common/popup/Alert";

export interface TermsAgreeParam {
    title:string,
    contents?:string,
    onClose: () => void
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const TermsAgree = (props:TermsAgreeParam) => {
    const [approve, setApprove] = useState('N')
    const [agrDtm, setAgrDtm] = useState('')
    const [open, setOpen] = React.useState(false)
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const [alertOpen, setAlertOpen] = React.useState(false)
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [req, setReq] = useState('')
    const today = moment(new Date()).format(DATE_FORMAT.DAY_DOT)

    const handleClose = () => {
        setOpen(false)
    }

    const handleButtonClick= () => {
        approve === 'Y' ?
            setConfirmOpen(true)
            : setReq('Y')
    }

    const confirmClick= () => {
        setConfirmOpen(false)
        setReq('N')
    }

    useQuery<SingleDataResponse<TermsAgreeData>, AxiosError>(
        ['getTermsAgree'],
        ()=> BoardService.getTermsAgree(),
        {
            enabled: true,
            onSuccess: (v: SingleDataResponse<TermsAgreeData>) => updateData(v.data)
        }
    )

    const updateData = (data: TermsAgreeData | undefined) => {
        if(data !== undefined){
            setApprove(data.termsAgrYn)
            setAgrDtm(moment(data.infoUseAgrDtm).format('YYYY.MM.DD'))
        }
    }

    const {refetch: patchTermsAgree } = useQuery<Response, AxiosError>(
        ['patchTermsAgree'],
        ()=> BoardService.patchTermsAgree(req.toString()),
        {
            onSuccess: () => setAlertOpen(true)
        }
    )

    useEffect(()=> {
        if(req !== ''){
            patchTermsAgree()
                .then(() => {
                    return Promise<void>
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
    }, [req])

    return (
        <>
            <DialogHeader title={props.title} onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <div className='content-main'>
                    {approve === 'Y'?
                        <div className='h4_r'>동의 철회를<br/>원하시나요?</div>
                        : <div className='h4_r'>다양한 정보를<br/>받아보시겠어요?</div>
                    }
                </div>
                <div className='pt-22px'>
                    {approve === 'Y'?
                        <span className='b2_l cl-gray02'>동의를 철회하시면 ROTHY의 이벤트, 혜택, 사은품 등에 대한 안내를 받으실 수 없어요</span>
                        : <span className='b2_l cl-gray02'>약관에 동의하시면 ROTHY의 이벤트, 혜택, 사은품 등에 대한 안내를 받으실 수 있어요</span>
                    }
                </div>
                <div className='flex pt-17px' onClick={() => setOpen(true)}>
                    <p className='b2_l cl-gray02'>약관 전문 보기</p>
                    <Icon iconClass='thin-next w-4px h-8px pl-8px'/>
                </div>
                {approve === 'Y'?
                    <div className={'terms-agree'} >
                        <span className='btn2_r cl-gray04'>동의일자 : {agrDtm}</span>
                    </div>
                    : ''
                }
            </div>
            <BottomButton title={approve === 'Y' ? '동의 철회하기' : '약관 동의하기' } disabled={false} onClick={handleButtonClick}/>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <TermsDetail contents={props.contents} onClose={handleClose}/>
            </Dialog>
            <Confirm content={<span>{CONFIRM.TERMS_AGREE.CONTENT}</span>}
                     leftBtnContent={CONFIRM.TERMS_AGREE.LBTN}
                     rightBtnContent={CONFIRM.TERMS_AGREE.RBTN}
                     isOpen={confirmOpen} onClick={confirmClick} onClose={ () => setConfirmOpen(false)}/>
            <Alert content={<span>{today} {approve === 'Y' ? MESSAGE.TERMS_AGREE_Y : MESSAGE.TERMS_AGREE_N }</span>}
                   title={undefined} btnContent={'확인'} isOpen={alertOpen} onClose={ () => { setAlertOpen(false); setApprove(approve === 'Y'? 'N' : 'Y') }}/>
        </>
    )
}

export default TermsAgree;