import React, {useCallback, useEffect, useState} from 'react'
import {Dialog, Slide, SlideProps} from "@mui/material";
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import UserDevice from "@/view/userDvic/component/UserDvic";
import BottomButton from "@/view/common/component/button/BottomButton";
import UserDvicAddView from "@/view/userDvic/UserDvicAddView";
import Popup from "@/view/common/popup/Popup";
import UserDvicAddUsedView from "@/view/userDvic/UserDvicAddUsedView";


const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface DviceProps {
    onClose: () => void
}

const UserDvicView: React.FC<DviceProps> = (props:DviceProps) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [isReload,setIsReload] = useState(false)
    const [isDvidReload,setIsDvicReload] = useState(false)
    const [open, setOpen] = React.useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setIsReload(!isReload)
        console.debug("UserDvicView reload")
    }

    useEffect(()=> {
        console.debug("UserDvicView 상태 변경 001")
        setIsDvicReload(!isDvidReload)
        console.debug("UserDvicView 상태 변경 002")
    },[isReload])

    return (
        <><DialogHeader title='내 기기 관리' onClose={handleOverlayClick}/>₩
            <div className={'board'}>
                <HeaderSpacer/>
            </div>
            <UserDevice updateList={isDvidReload}/>
            <BottomButton  title='체중계 연결하기' disabled={false}   onClick={handleClickOpen} />
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                {<UserDvicAddView onClose={handleClose} />}
            </Dialog>
        </>
    )
}

export default UserDvicView;