import moment from "moment-timezone";
import {DATE_FORMAT, ITEM_CD, USER_ID, OS_TYPE} from "@/common/Constant";
import InterfaceUtil from "@/common/Interface";
import UserMissionService, {LstMsreDateForMQ, MeasureListItem} from "@/service/UserMissionService";
import {RHParamHistory, RHQuantity, RHSets} from "@/service/rothynative/RNIHealth";
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import StorageUtil from "@/common/StorageUtil";
import {RabbitMqPublish} from "@/service/rothynative/RabbitMqService";

const putHistory = () : Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!InterfaceUtil.isAppRunning()) {
            resolve()
            return
        }

        const userId = Number(StorageUtil.getLocalStorage(USER_ID))

        UserMissionService
            .getLstMsreDateForMQ(userId)
            .then(r => {
                if (InterfaceUtil.getOs() == OS_TYPE.ANDROID) {
                    return getHitoryDataAndroid(r)
                } else {
                    return getHitoryDataIos(r)
                }
            })
            .then(r => {
                putMeasure(r)
                    .then(() => resolve())
                    .catch(err => reject(err))
            })
            .catch(err => reject(err))
    })
}

const getHitoryDataAndroid =  async (msreData : LstMsreDateForMQ) : Promise<Array<SHFloorsClimbed>> => {
    return new Promise((resolve, reject) => {
        const weightConfig = new RNIMessageConfig<RHParamHistory, Array<SHFloorsClimbed>>()
        weightConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        weightConfig.data = createParam(msreData)

        RothyNativeService.getInstance()
            .postMessage(weightConfig)
            .then((values) => {
                const userId = Number(StorageUtil.getLocalStorage(USER_ID))

                values.map((value, index) => {
                    value.userId = userId
                })

                resolve(values)
            })
            .catch((error: RNIError) => reject(error))
    })
}

const getHitoryDataIos =  async (msreData : LstMsreDateForMQ) : Promise<Array<SHFloorsClimbed>> => {
    return new Promise((resolve, reject) => {
        const weightConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        weightConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        weightConfig.data = createParam(msreData)

        RothyNativeService.getInstance()
            .postMessage(weightConfig)
            .then((values) => {
                const userId = Number(StorageUtil.getLocalStorage(USER_ID))

                const datas = new Array<SHFloorsClimbed>()

                values.map((value, index) => {
                    const data = new SHFloorsClimbed()
                    data.userId = userId
                    data.startTime = moment(`${value.startDate}Z`).tz("Asia/Seoul").valueOf()
                    data.endTime = moment(`${value.endDate}Z`).tz("Asia/Seoul").valueOf()
                    data.floor = value.quantity
                    if (value.deviceModel != undefined) {
                        data.dvicTp = value.deviceModel
                    }

                    datas.push(data)
                })

                resolve(datas)
            })
            .catch((error: RNIError) => reject(error))
    })
}

const createParam = (msreData : LstMsreDateForMQ): RHParamHistory => {
    const measureList: MeasureListItem[] = msreData.data.measureList
    const findIndex = measureList.findIndex(element => ITEM_CD.FLOOR.match(element.measureType))
    const measureDatetime = Number(msreData.data.measureList[findIndex].measureDatetime)
    const startDate = moment(measureDatetime).utc().format(DATE_FORMAT.DATE_TIME_UTC)
    const endDate = moment(new Date()).utc().format(DATE_FORMAT.DATE_TIME_UTC)

    const param = new RHParamHistory()
    param.quantityType = RHSets.floorsClimbed.history.quantityType
    param.startDate = startDate
    param.endDate = endDate

    return param
}

const putMeasure = async  (meargeData: SHFloorsClimbed[]) : Promise<void> => {
    return new Promise((resolve, reject) => {
        if (meargeData.length < 1) {
            resolve()
            return
        }

        const rabbitMqConfig = new RNIMessageConfig<RabbitMqPublish<SHFloorsClimbed[]>, void>()
        rabbitMqConfig.action = RNIActions.RABBIT_MQ_PUBLISH
        rabbitMqConfig.data = {
            host: process.env.RABBIT_MQ_HOST,
            port: Number(process.env.RABBIT_MQ_PORT),
            userName: process.env.RABBIT_MQ_USERNAME,
            password: process.env.RABBIT_MQ_PASSWORD,
            qn: "q.floor.givita",
            ex: "x.floor.givita",
            rk: "floor.user.*",
            quantityType: RHSets.floorsClimbed.history.quantityType,
            data: meargeData,
        }

        RothyNativeService.getInstance()
            .postMessage(rabbitMqConfig)
            .then((values) => {
                resolve()
            })
            .catch((error: RNIError) => reject(error))
    })
}

const PutFloorHistoryService = {
    putHistory
}

export class SHFloorsClimbed {
    // 사용자ID
    userId = -1
    // 데이터 고유키
    datauuid = ""
    // 데이터 생성 TimeStamp
    createTime = -1
    // 데이터 변경 TimeStamp
    updateTime = -1
    // 패키지 이름
    pkgName = ""
    // 측정 시작 시 UTC 밀리초
    startTime = -1
    // 측정 종료 후 UTC 밀리초
    endTime = -1
    // 시간 오프셋(밀리초)
    timeOffset = -1
    // 올라간 층수
    floor = 0
    // JSON 및 압축 데이터로 형식이 지정된 사용자 지정 정보
    custom?: string
    deviceuuid = ""
    deviceGroup = -1
    dvicTp = "0"
}

export default PutFloorHistoryService
