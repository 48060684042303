import axios from "axios"
import {createBrowserHistory} from 'history'
import {ACCESS_TOKEN, APP_VERSION_NO, DEVICE_MODEL, LOGIN_TYPE, REFRESH_TOKEN} from "@/common/Constant"

type Error = {
    message: string,
    response: {
        status: number,
        data: {
            message: string
        }
    }
}

type ResRefreshToken = {
    data: {
        result: boolean,
        message: string,
        data: string
    }
}

export type DefaultResult = {
    code: string,
    data: any|undefined,
    errors: string[],
    message: string,
    result: boolean
}

type LogoutMsg = {
    type : string
}

const data: LogoutMsg = { type: LOGIN_TYPE.EXPIRED};

export const history = createBrowserHistory();

export const rothyClient = axios.create({
    baseURL: process.env.GIVITA_BACKEND_URI,
})

export const refreshClient = axios.create({
    baseURL: process.env.GIVITA_BACKEND_URI,
})

export const loginClient = axios.create({
     baseURL: process.env.GIVITA_BACKEND_URI,
 })

loginClient.interceptors.request.use(
    function (config){
        if (config.headers) {
            config.headers["Content-Type"] = 'application/json'
            const deviceModel = localStorage.getItem(DEVICE_MODEL)
            config.headers["deviceModel"] = `${deviceModel === null? '': deviceModel.toString()}`
            const appVerNo = localStorage.getItem(APP_VERSION_NO)
            config.headers["appVerNo"] = `${appVerNo === null? '': appVerNo.toString()}`
        }
        return config;
    }
)

rothyClient.interceptors.request.use(
    function (config){
        if (config.headers) {
            config.headers["Content-Type"] = 'application/json'
            const accessToken = localStorage.getItem(ACCESS_TOKEN)
            config.headers["Authorization"] = `Bearer ${accessToken === null? '' : accessToken.toString()}`
        }
        return config;
    }
)

refreshClient.interceptors.request.use(
    function (config){
        if(config.headers) {
            config.headers["Content-Type"] = 'application/json'
            const refreshToken = localStorage.getItem(REFRESH_TOKEN)
            config.headers["Authorization"] = `Bearer ${refreshToken === null? '' : refreshToken.toString()}`
        }
        return config;
    }
)

loginClient.interceptors.response.use(
    response => {
        return response
    },
    function (error: Error) {
        return Promise.reject(error)
    }
);

rothyClient.interceptors.response.use(
    response => {
        return response
    },
    function (error: Error) {
        if (error.response.status == 401){ // 401 은 토큰이 없는 상태, 혹은 토큰 만료,
            void refreshToken().then(r => r).catch(err=> Promise.reject(err))
        }else if(error.response.status == 403) {  //403은 리프레시토큰도 만료되어 재로그인이 필요한 경우
            history.push({
                pathname: "/"
            }, data);
            location.reload()
        }

        //400 및 기타에러는 화면에서 처리(axios 요청 실패시, 여러번 호출 되기 때문에 interceptor에서 처리 하지 않음)
        return Promise.reject(error)
    }
);

const refreshToken = () =>
    refreshClient.post<ResRefreshToken>("/auth/tokenRefresh").then(res => res)


refreshClient.interceptors.response.use(
    response => {
        const r = response as unknown as ResRefreshToken
        if(r.data.result){
            // refresh 토큰이 유효하면 accesstoken 갱신하고 main 화면으로
            const token = r.data.data
            localStorage.setItem(ACCESS_TOKEN, token)
            history.push({
                pathname: "/"
            });
        }else{
            // refresh 토큰이 만료되면 로그인 화면으로
            history.push({
                pathname: "/"
            }, data);
        }
        location.reload()
        return
    },function (error: Error) {
        return Promise.reject(error)
    }
);

