function numberWithCommas(number: number): string{
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function calculateDisCountPercent(original: number, discount: number): number{
    return Math.round(((original - discount) / original)*100);
}

const replaceBiggerNumber = (a:number, b:number) => {
    if(a < b){
        return b
    }else{
        return a
    }
}

const replaceSmallerNumber = (a:number, b:number) => {
    if(a > b){
        return b
    }else{
        return a
    }
}


export default {
    numberWithCommas,
    replaceBiggerNumber,
    replaceSmallerNumber,
    calculateDisCountPercent,

}