import React from 'react';


const Typo: React.FC = (): JSX.Element => {
     return (
         <>
              <p>Headline0</p>
              <p className='h0_m'>건강한 루틴</p>
              <p className='h0_r'>건강한 루틴</p>
              <p className='h0_l'>건강한 루틴</p>
              <p>Headline1</p>
              <h1 className='h1_m'>누구나 할 수 있는 건강한 루틴</h1>
              <h1 className='h1_r'>누구나 할 수 있는 건강한 루틴</h1>
              <h1 className='h1_l'>누구나 할 수 있는 건강한 루틴</h1>
              <p>Headline2</p>
              <h2 className='h2_m'>누구나 할 수 있는 건강한 루틴</h2>
              <h2 className='h2_r'>누구나 할 수 있는 건강한 루틴</h2>
              <h2 className='h2_l'>누구나 할 수 있는 건강한 루틴</h2>
              <p>Headline3</p>
              <h3 className='h3_m'>누구나 할 수 있는 건강한 루틴</h3>
              <h3 className='h3_r'>누구나 할 수 있는 건강한 루틴</h3>
              <h3 className='h3_l'>누구나 할 수 있는 건강한 루틴</h3>
              <p>Headline4</p>
              <h4 className='h4_m'>누구나 할 수 있는 건강한 루틴</h4>
              <h4 className='h4_r'>누구나 할 수 있는 건강한 루틴</h4>
              <h4 className='h4_l'>누구나 할 수 있는 건강한 루틴</h4>
              <p>Headline5</p>
              <h5 className='h5_m'>누구나 할 수 있는 건강한 루틴</h5>
              <h5 className='h5_r'>누구나 할 수 있는 건강한 루틴</h5>
              <h5 className='h5_l'>누구나 할 수 있는 건강한 루틴</h5>
              <p>Headline6</p>
              <h6 className='h6_m'>누구나 할 수 있는 건강한 루틴</h6>
              <h6 className='h6_r'>누구나 할 수 있는 건강한 루틴</h6>
              <h6 className='h6_l'>누구나 할 수 있는 건강한 루틴</h6>
              <p>Subtitle1</p>
              <p className='s_title_1_m'>누구나 할 수 있는 건강한 루틴</p>
              <p className='s_title_1_r'>누구나 할 수 있는 건강한 루틴</p>
              <p className='s_title_1_l'>누구나 할 수 있는 건강한 루틴</p>
              <p>Subtitle2</p>
              <p className='s_title_2_m'>누구나 할 수 있는 건강한 루틴</p>
              <p className='s_title_2_r'>누구나 할 수 있는 건강한 루틴</p>
              <p className='s_title_2_l'>누구나 할 수 있는 건강한 루틴</p>
              <p>Body1</p>
              <p className='b1_m'>누구나 할 수 있는 건강한 루틴</p>
              <p className='b1_r'>누구나 할 수 있는 건강한 루틴</p>
              <p className='b1_l'>누구나 할 수 있는 건강한 루틴</p>
              <p>Body2</p>
              <p className='b2_m'>누구나 할 수 있는 건강한 루틴</p>
              <p className='b2_r'>누구나 할 수 있는 건강한 루틴</p>
              <p className='b2_l'>누구나 할 수 있는 건강한 루틴</p>
              <p>Button1</p>
              <p className='btn1_m'>누구나 할 수 있는 건강한 루틴</p>
              <p>Button2</p>
              <p className='btn2_m'>누구나 할 수 있는 건강한 루틴</p>
              <p>Caption1</p>
              <p className='c1_m'>누구나 할 수 있는 건강한 루틴</p>
              <p className='c1_r'>누구나 할 수 있는 건강한 루틴</p>
              <p className='c1_l'>누구나 할 수 있는 건강한 루틴</p>
              <p>Caption2</p>
              <p className='c2_m'>누구나 할 수 있는 건강한 루틴</p>
              <p className='c2_r'>누구나 할 수 있는 건강한 루틴</p>
              <p className='c2_l'>누구나 할 수 있는 건강한 루틴</p>
         </>
     )
}
export default Typo;