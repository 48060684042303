import {rothyClient} from "@/service/RothyClient";

export interface DetailCode{
    grpCd: string,
    dtlCd: string,
    dtlCdNm: string,
    status: string
}

const selectDetailCode = async (grpCd : string) =>
    rothyClient.get<DetailCode[]>("/code/selDetailCodeList/" +grpCd).then(res => res.data)

const CodeService = {
    selectDetailCode
}

export default CodeService
