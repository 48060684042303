import React from 'react'
import {DELETE_USER} from '@/common/Constant'

const Notification = () => {

    return (
        <div className={'c1_r notification ft-cl-hint'}>
            <div>{DELETE_USER.NOTI_LINE1}</div>
            <div>{DELETE_USER.NOTI_LINE2}</div>
        </div>
    )
}

export default Notification