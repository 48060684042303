import React from "react";
import {Dialog, Slide, SlideProps} from "@mui/material";
import {DELETE_USER} from '@/common/Constant'
import DeleteUser from "@/view/user/component/DeleteUser";


const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const DeleteUserView: React.FC = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className='mrml-24px ptpb-22px border-top-gray text-center button2-style cl-point06 c1_r' onClick={handleClickOpen}>
                {DELETE_USER.BTN}
            </div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DeleteUser onClose={handleClose}/>
            </Dialog>
        </>
    )
};

export default DeleteUserView;