import React from 'react'
import moment from "moment"
import {HealthyData} from '@/service/WeightService'
import Icon from "@/view/common/component/Icon"

export interface WeightHealthyCalendarData{
    dailyList: HealthyData[]
    periodType: string
    month?: string
    focusUnit: string
    currentDateYn: boolean
}

const WeightHealthyCalendar = (props:WeightHealthyCalendarData) => {

    const drawWeekCalendar = () => {
        const result = []
        if(props.currentDateYn){
            const now = moment().day()
            for(let i = 0; i <= now; i ++){
                const weekData = props.dailyList[i]
                if(weekData.y === 0){
                    result.push(<div key={'week_1_'+i.toString()} className={i === now? 'b2_l today' : 'b2_l'}>-</div>)
                } else {
                    if(weekData.highlightYn === 'Y'){
                        result.push(
                            <div key={'week_2'+i.toString()} className={'on'} style={{flexDirection: 'column'}}>
                                <div key={'week_3'+i.toString()} className={'b2_r'}>{weekData.y}{props.focusUnit}</div>
                                {
                                    weekData.diffValue === 0 ?
                                        <></>
                                        :
                                        <div key={'week_4'+i.toString()} className={'c2_r flex'}>
                                            <Icon iconClass='polygon5 w-6px h-6px mr-3px'/>
                                            {Math.abs(weekData.diffValue).toFixed(1)}
                                        </div>
                                }
                            </div>
                        )
                    } else {
                        result.push(<div key={'week_5'+i.toString()} className={'b2_l'}>{weekData.y}{props.focusUnit}</div>)
                    }
                }
            }
            for(let i = now +1 ; i <= 6; i ++){
                result.push(
                    <div key={'week_6_'+i.toString()} className={'b2_l future'}>-</div>
                )
            }
        } else {
            props.dailyList.map((data,index)=>{
                if(data.y === 0){
                    result.push(<div key={'week_1_'+index.toString()} className={'b2_l'}>-</div>)
                } else {
                    if(data.highlightYn === 'Y'){
                        result.push(
                            <div key={'week_2_'+index.toString()} className={'on'} style={{flexDirection: 'column'}}>
                                <div key={'week_3_'+index.toString()} className={'b2_r'}>{data.y}{props.focusUnit}</div>
                                {
                                    data.diffValue === 0 ?
                                        <></>
                                        :
                                        <div key={'week_4_'+index.toString()} className={'c2_r flex'}>
                                            <Icon iconClass='polygon5 w-6px h-6px mr-3px'/>
                                            {Math.abs(data.diffValue).toFixed(1)}
                                        </div>
                                }
                            </div>
                        )
                    } else {
                        result.push(<div key={'week_5_'+index.toString()} className={'b2_l'}>{data.y}{props.focusUnit}</div>)
                    }
                }
            })
        }
        return <div className={'grid-content-week'}>{result}</div>
    }

    const drawMonthCalendar = () => {
        const firstDay = moment(props.month).startOf('month').day()
        const result = []

        for(let i = 0 ; i < firstDay; i ++){
            result.push(<div key={'month_1_'+i.toString()} className={'past'}></div>)
        }

        props.dailyList.map((data,index)=>{
            if(data.y === 0){
                const todayChk = moment(moment().format('YYYY-MM-DD')).isSame(data.x)
                result.push(
                    <div key={'month_2_'+index.toString()} className={(props.currentDateYn && todayChk)? 'today' : ''}>
                        <div key={'month_3_'+index.toString()} className={'c2_r cl-gray04'}>{index + 1}</div>
                        <div key={'month_4_'+index.toString()} className={'c1_r cl-gray05'}>-</div>
                    </div>
                )
            } else {
                result.push(
                    <div key={'month_2_'+index.toString()} className={data.highlightYn === 'Y'? 'on' : ''}>
                        <div key={'month_3_'+index.toString()} className={'c2_r cl-gray04'}>{index + 1}</div>
                        <div key={'month_4_'+index.toString()} className={'c1_r cl-gray03'}>{data.y.toFixed(1)}{props.focusUnit}</div>
                    </div>
                )
            }
        })

        if(props.currentDateYn) {
            const daysCount = moment(props.month).daysInMonth()
            for(let j = props.dailyList.length; j < daysCount; j++){
                result.push(
                    <div key={'month_5_'+j.toString()} className={'future'}>
                        <div key={'month_6_'+j.toString()} className={'c2_r'}>{j+1}</div>
                        <div key={'month_7_'+j.toString()} className={'c1_r'}>-</div>
                    </div>
                )

            }
        }

        return <div className={'grid-content-month'}>{result}</div>
    }

    return <>
        <div className="weight-grid">
            <div className={'c1_r'}>일</div>
            <div className={'c1_r'}>월</div>
            <div className={'c1_r'}>화</div>
            <div className={'c1_r'}>수</div>
            <div className={'c1_r'}>목</div>
            <div className={'c1_r'}>금</div>
            <div className={'c1_r'}>토</div>
        </div>
        {
            props.periodType === 'WEEK'?
                drawWeekCalendar()
                :
                drawMonthCalendar()
        }
        {
            props.focusUnit === undefined || props.focusUnit === ''?
                <></>
                :
                <div className={'mt-8px c2_r cl-gray04'}>(단위: {props.focusUnit})</div>
        }
    </>
}

export default WeightHealthyCalendar