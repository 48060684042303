import React from 'react';
import EmailLogin from "@/view/login/component/EmailLogin";
import LinkHeader from "@/view/layout/LinkHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";


const EmailLoginView: React.FC = (): JSX.Element => {

    return(
        <>
            <LinkHeader leftIcon={'prev-w'} leftLink={'/login'}/>
            <HeaderSpacer className='bg-cl-black'/>
            <EmailLogin/>
        </>
    )

}

export default EmailLoginView;