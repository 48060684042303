import React, {useEffect, useState} from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import {AmountData} from '@/service/WeightService'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export interface WeightAmountChartData{
    minValue: number
    maxValue: number
    sizeValue: number
    currentList: AmountData[]
    currentYn: string
    lastList: AmountData[]
    lastYn: string
    unit: string
    children?: React.ReactNode
}

type LineChartDataSet = {
    data: number[] | object[] | undefined[] | undefined | string,
    backgroundColor: string,
    borderColor: string,
    borderWidth: number,
}

const WeightAmountChart = (props:WeightAmountChartData) => {
    const [data,setData] = useState<{labels:string[],datasets:LineChartDataSet[]}>({labels:[''],datasets:[]})
    const [options, setOptions] = useState({})
    let monthYn = props.currentList.length > 13 ? 'Y' : 'N'

    useEffect(() => {
        monthYn = props.currentList.length > 13 ? 'Y' : 'N'
        if(props.currentList.length > 0) {
            updateOptions()
            initChart()
        }
    },[props])

    const updateOptions = () => {
        setOptions({
            responsive: true,
            animation: false,
            plugins: {
                datalabels: false,
                legend: false,
                title: false
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: 12
                        },
                        getLabelForValue(value: number): string {
                            return value as unknown as string
                        },
                        callback: function(value: number) {
                            let result = this.getLabelForValue(value)
                            if(monthYn === 'Y'){
                                if(['7','14','21','28'].includes(result)){
                                    result =  result +'일'
                                } else {
                                    result = ''
                                }
                            }
                            return result
                        }
                    },
                },
                y: {
                    min : props.minValue,
                    max : props.maxValue,
                    ticks: {
                        stepSize: props.sizeValue,
                        callback: function(value: number) {
                            return ( Math.floor(value* 10) / 10 ).toString() + (props.unit === undefined? '' : props.unit)
                        }
                    },
                }
            },

            elements: {
                point: {
                    pointStyle: alternatePointStyles,
                    radius: 4,
                }
            }
        })
    }

    const initChart = () => {
        const labelData : string[] = []
        const array = []

        props.currentList.map((data)=>{
            labelData.push( data.x)
            if(data.y === 0 ) data.y = undefined
        })

        if(props.currentYn==='Y'){
            const currentData = { data: props.currentList, borderColor: '#24CBAA', backgroundColor: '#24CBAA', borderWidth: 2}
            array.push(currentData)
        }

        if(props.lastYn==='Y'){
            props.lastList.map((data)=>{
                if(data.y === 0 ) data.y = undefined
            })
            const lastData = { data: props.lastList, borderColor: '#CDCDCD', backgroundColor: '#CDCDCD', borderWidth: 2}
            array.push(lastData)
        }

        setData({
            labels : labelData,
            datasets: array,
        })
    }

    const alternatePointStyles  = (ctx: { raw: { dvicTp: string } | undefined }) => {
        if( ctx.raw !== undefined ) {
            return (ctx.raw.dvicTp !== '' && ctx.raw.dvicTp === 'SCALE') ? 'rect' : 'circle'
        }
    }

    return <Line height={'200px'} options={options} data={data} />
}

export default WeightAmountChart
