import React, {useEffect, useState} from 'react'
import moment from 'moment';
import Icon from "@/view/common/component/Icon";
import DateUtil from "@/common/DateUtil";

export interface SleepCardDataConfig{
    msreBeginDtm?: string,
    msreDt?: string,
    msreEndDtm?: string,
    scesYn?: string,
    sleepMaxTimeByAge?: number,
    sleepMinTimeByAge?: number,
    sleepOxygen?: string,
    sleepOxygenLowDuration?: number,
    sleepPatternDiff?: string,
    sleepPatternScoreOrMsg?: string,
    sleepTime?: number,
    sleepTimeMsg?: string,

    signupDate?: string,

}

const SleepCard = (props:SleepCardDataConfig) => {
    const [isToday] = useState(props.msreDt?props.msreDt === moment().format('YY.MM.DD'):true)
    const [data, setData] = useState(props)
    const [sleepTimeHour, setSleepTimeHour] = useState<number | undefined>(undefined)
    const [sleepTimeMin, setSleepTimeMin] = useState<number | undefined>(undefined)
    const [oxygenLow, setOxygenLow] = useState<string | undefined>(undefined)
    const [sleepMinMax, setSleepMinMax] = useState<string | undefined>(undefined)
    const [msreBeginEnd, setMsreBeginEnd] = useState<string | undefined>(undefined)
    const [signupDate] = useState(props.signupDate)
    const [isLastData] =  useState(signupDate?signupDate > moment().format('YY.MM.DD'):false)

    const [graphLeftStyle, setGraphLeftStyle] = useState({width:'100%'})
    const [graphRightStyle, setGraphRightStyle] = useState({width:'0%'})
    const [sleepMinMaStyle, setSleepMinMaxStyle] = useState({marginRight:'0px'})

    const hour8Sec = 28800

    const setSleepTime = (sleepTime: number) => {
        setSleepTimeHour(parseInt((sleepTime / 3600).toString()));
        setSleepTimeMin(parseInt(((sleepTime % 3600) / 60).toString()));
    }

    const setGraph = (sleepTime: number) => {
        const sleepPcntPer8Hour = parseInt(String((sleepTime / hour8Sec) * 100))
        if (sleepPcntPer8Hour > 100) {
            const overSecPer8Hour = sleepTime - hour8Sec
            const rightMargin = parseInt(String(overSecPer8Hour * 0.004))
            const _rightMargin = rightMargin > 85 ? 85 : rightMargin
            setSleepMinMaxStyle({marginRight: String(_rightMargin) + 'px'})
        } else {
            const _sleepPcntPer8Hour = sleepPcntPer8Hour > 100 ? 100 : sleepPcntPer8Hour
            const nonSleppPcrtPer8Hour = 100 - _sleepPcntPer8Hour
            setGraphLeftStyle({width: String(sleepPcntPer8Hour) + '%'})
            setGraphRightStyle({width: String(nonSleppPcrtPer8Hour) + '%'})
        }
    }

    const setOxygen = (sleepOxygenLowDuration: number) => {
        const hour = parseInt((sleepOxygenLowDuration/3600).toString())
        const min = parseInt(((sleepOxygenLowDuration%3600)/60).toString())
        const sec = parseInt((sleepOxygenLowDuration%60).toString())
        if(hour > 0 ){
            setOxygenLow(String(hour) +'시 ' + String(min) +'분 ' + String(sec) +'초')
        }else if(min > 0 ){
            setOxygenLow( String(min) +'분 ' + String(sec) +'초')
        }else if(sec > 0 ){
            setOxygenLow( String(sec) +'초')
        }else{
            setOxygenLow(undefined)
        }
    }

    useEffect(() => {
        if(props.sleepTime && props.sleepTime > 0){
            setSleepTime(props.sleepTime)
            setGraph(props.sleepTime)
         }

        if(props.sleepOxygenLowDuration && props.sleepOxygenLowDuration > 0){
            setOxygen(props.sleepOxygenLowDuration)

        }

        if(props.sleepMinTimeByAge && props.sleepMinTimeByAge > 0 && props.sleepMaxTimeByAge && props.sleepMaxTimeByAge > 0){
            setSleepMinMax(String(props.sleepMinTimeByAge) + 'H'+'~'+String(props.sleepMaxTimeByAge) + 'H')
        }

        if(props.msreBeginDtm && props.msreEndDtm ){
            const beginHour = Number(props.msreBeginDtm.substring(0,5).split(':')[0])
            const beginMin = Number(props.msreBeginDtm.substring(0,5).split(':')[1])
            const endHour = Number(props.msreEndDtm.substring(0,5).split(':')[0])
            const endMin = Number(props.msreEndDtm.substring(0,5).split(':')[1])

            setMsreBeginEnd(DateUtil.convertTimeTypeFrom24To12(beginHour, beginMin) + ' ~ ' + DateUtil.convertTimeTypeFrom24To12(endHour, endMin))
        }

        setData(props)

    }, [props])


    return <div className='daily-card-content'>
                 <div className={'flex align-center'}>
                {
                    isToday?
                        <p className='b2_m'>TODAY</p>
                    :
                        <p className='b2_m'>{data.msreDt}</p>
                }
                    {/*<div className={'svg pr-22px ml-auto'}><Icon iconClass={'share'}/></div> 공유하기 Native 인터페이스 구현될때까지 숨김*/}
                </div>
                <p className='c1_r pt-19px'>수면시간</p>
                <div className={'sleep-time align-center'}>
                    {
                        data.sleepTime && !isLastData ?
                            <>
                                <div className={'sleep-time-row'}>
                                    <div className={'sleep-time-row-item h1_m cl-primary-cyan01'}>{sleepTimeHour}</div>
                                    <div className={'sleep-time-row-item h6_r pl-2px mb-8px cl-gray03'}>h</div>
                                    <div className={'sleep-time-row-item h1_m cl-primary-cyan01'}>{sleepTimeMin}</div>
                                    <div className={'sleep-time-row-item h6_r pl-2px mb-8px cl-gray03'}>m</div>

                                </div>
                                <div className={'sleep-time-row flex-column w-100p'}>
                                    <div className={'sleep-time-graph-row'}>
                                        <div>
                                            <p className={'c1_r cl-gray04'}>{msreBeginEnd}</p>
                                        </div>
                                        <div className={'flex-column'} style={sleepMinMaStyle}>
                                            <p className={'c1_r sleepMinMax cl-gray04'} >{sleepMinMax}</p>
                                            <Icon iconClass='bridge w-32px h-5px' />
                                        </div>
                                    </div>
                                    <div className={'sleep-time-graph-row2'}>
                                        <div className={'sleep-time-graph1-left mt-3px'} style={graphLeftStyle}></div>
                                        <br/>
                                        <div className={'sleep-time-graph1-right mt-3px'} style={graphRightStyle}></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={'sleep-time-row pt-2px ft-50px'}>Zzz..Zzz..</div>
                                <div className={'sleep-time-row pt-2px b1_r'}>워치 차고 자는 습관을 들여봐요!</div>
                            </>
                    }
                </div>

                <div className={'sleep-score border-top-gray '}>
                    <div className='sleep-score-row'>
                        <p className={'c1_r cl-gray03'}>수면 규칙 점수</p>
                        <div className={'flex'}>
                            <p className={'c1_r ft-14px cl-gray03'}>{data.sleepPatternScoreOrMsg}</p>
                            <p className={'c1_r cl-primary-cyan01'}>{data.sleepPatternDiff}</p>
                        </div>
                    </div>
                    <div className='sleep-score-row'>
                        <p className={'c1_r cl-gray03'}>수면 중 혈중 산소</p>
                        <p className={'b2_r cl-gray03'}>{data.sleepOxygen}</p>
                    </div>
                    {
                        oxygenLow?
                            <div className='sleep-score-row'>
                                <div className={'flex'}>
                                    <Icon iconClass='point-red w-4px h-4px' />
                                    <p className={'c1_r ml-7px cl-gray03'}>90% 아래로 내려간 시간</p>
                                </div>
                                <div className={'flex'}>
                                    <p className={'c1_r cl-gray03'}>{oxygenLow}</p>
                                </div>
                            </div>
                            :
                            <></>

                    }
                </div>

                <div className={'sleep-goal border-top-gray'}>
                    <div className={'sleep-goal-row'}>
                        <p className={'c1_r cl-gray03'}>목표! 자기 전 워치 착용하기</p>
                        {
                            data.scesYn && data.scesYn === 'Y'?
                                <div className={'flex'}>
                                    <Icon iconClass='mission-success w-16px h-16px' />
                                    <p className={'c1_r ml-7px cl-gray03'}>달성</p>
                                </div>
                            :
                                <div className={'flex'}>
                                    <Icon iconClass='mission-fail w-16px h-16px' />
                                    <p className={'c1_r ml-7px cl-gray03'}>아맞다!</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
}

export default SleepCard