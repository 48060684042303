import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Chart} from "react-chartjs-2";
import {Skeleton} from "@mui/material";
import SleepService, {SleepReportReq, SleepStageRes} from "@/service/SleepService"
import StringUtil from "@/common/StringUtil";
import Icon from "@/view/common/component/Icon";
import NumberUtil from "@/common/NumberUtil";
import DateUtil from "@/common/DateUtil";
import sleepStageInfo from "@/assets/image/sleep-stage-info.png"
import {ChartDataSet} from "@/view/walk/component/WalkBarChart";
import {getLabelData, ReportPropsConfig} from "@/common/ReportUtil";

interface Item {
    itemNm: string,
    itemCd: string,
}

const HowLongByStage = forwardRef((props:ReportPropsConfig, ref) => {
    const itemList = [
        {'itemNm': '깨어남', 'itemCd': 'AWAKE'},
        {'itemNm': 'REM', 'itemCd': 'REM'},
        {'itemNm': '얕은 수면', 'itemCd': 'LIGHT'},
        {'itemNm': '깊은 수면', 'itemCd': 'DEEP'},

    ]

    const [data, setData] = useState<{labels:string[], datasets:ChartDataSet[]}>()
    const [resourceData, setResourceData] = useState<SleepStageRes | undefined>(undefined)
    const [options, setOptions] = useState({})
    const [standLabelStyle, setStandLabelStyle] = useState({})
    const [standAverageStyle, setStandAverageStyle] = useState({})
    const [standRangeStyle, setStandRangeStyle] = useState({})
    const [pastUnitMsg, setPastUnitMsg] = useState<string | undefined>(undefined)
    const [pastUnitMsgVisible, setPastUnitMsgVisible] = useState<boolean>(true)

    const [mentalInfoVisible, setMentalInfoVisible] = useState<boolean>(false)
    const [bodyInfoVisible, setBodyInfoVisible] = useState<boolean>(false)
    const [stageInfoVisible, setStageInfoVisible] = useState<boolean>(false)

    const [todayMentalRecoverTime, setTodayMentalRecoverTime] = useState<string | undefined>(undefined)
    const [todayBodyRecoverTime, setTodayBodyRecoverTime] = useState<string | undefined>(undefined)
    const [pastMentalRecoverTime, setPastMentalRecoverTime] = useState<string | undefined>(undefined)
    const [pastBodyRecoverTime, setPastBodyRecoverTime] = useState<string | undefined>(undefined)

    const [clickedIndex, setClickedIndex] = useState(0)
    const [selectedItem, setSelectedItem] = useState<Item| undefined>(itemList[0])
    const [sleepStageMsg, setSleepStageMsg] = useState('')
    const [sleepReportReq, setSleepReportReq] = useState<SleepReportReq>( new SleepReportReq ('','','','','',0,''))

    const chartDivRef = useRef<HTMLDivElement>(null)

    const reportHeight = 180
    const divWidth = chartDivRef.current ? chartDivRef.current.clientWidth : window.innerWidth - 48
    const reportWidth = divWidth * 0.75
    let deleteTodayBodyRecoverTime = 0 // 수집 장비 및 상태에 따라 얕은 수면이 100%인 경우 그래프, 데이터 테이블 에서 제외
    let deleteTodayMentalRecoverTime = 0 // 수집 장비 및 상태에 따라 얕은 수면이 100%인 경우 그래프, 데이터 테이블 에서 제외
    const clickStageInfo = () => {
        if(stageInfoVisible){
            setStageInfoVisible(false)
        }else{
            setStageInfoVisible(true)
        }
    }

    const clickMentalInfo = () => {
        if(mentalInfoVisible){
            setMentalInfoVisible(false)
        }else{
            setMentalInfoVisible(true)
            setBodyInfoVisible(false)
        }
    }

    const clickBodyInfo = () => {
        if(bodyInfoVisible){
            setBodyInfoVisible(false)
        }else{
            setBodyInfoVisible(true)
            setMentalInfoVisible(false)
        }
    }

    const selectButton = (index:number, item:Item) =>{
        setClickedIndex(index)
        setSelectedItem(item)
    }

    const {refetch: getSleepRecordStage} = useQuery<SleepStageRes, AxiosError>(
        ['getSleepRecordStage'],
        () => SleepService.getSleepRecordStage(sleepReportReq),
        {
            onSuccess: (v: SleepStageRes) => success(v)
        }
    )

    const success = (v:SleepStageRes) => {
        console.log(v)
        setResourceData(v)
    }

    useEffect(()=>{
        if(resourceData){
            createChart(resourceData)
        }
    },[resourceData, selectedItem])

    const createChart = (v:SleepStageRes) => {
        setSleepStageMsg(v.data.sleepStageMsg)

        const labels:string[] = []

        const labelData1:number[] = []
        const labelData2:any[] = []
        const labelData3:number[] = []

        let maxRatio = 0

        const selectedItemCd = selectedItem?.itemCd
        v.data.todaySleepStageList.forEach(d => {
            labels.push(getLabelData(d, props.periodType))
            if(d.LIGHT_STAGE_RATIO === 100){
                labelData1.push(0)
                deleteTodayBodyRecoverTime += d.SLEEP_TIME
            }else{
                switch (selectedItemCd) {
                    case 'AWAKE':
                        labelData1.push(d.AWAKE_STAGE_RATIO)
                        break
                    case 'REM':
                        labelData1.push(d.REM_STAGE_RATIO)
                        break
                    case 'LIGHT':
                        labelData1.push(d.LIGHT_STAGE_RATIO)
                        break
                    case 'DEEP':
                        labelData1.push(d.DEEP_STAGE_RATIO)
                        break
                }
            }

            v.data.sleepStageStandRangeList.forEach(f => {
                if(f.sleepStageNm === selectedItemCd){
                    labelData3.push(f.standToRange)
                }
            })
        })

        v.data.pastSleepStageList.forEach(g => {
            const _AWAKE_STAGE_RATIO = g.AWAKE_STAGE_RATIO===0?undefined:g.AWAKE_STAGE_RATIO
            const _REM_STAGE_RATIO = g.REM_STAGE_RATIO===0?undefined:g.REM_STAGE_RATIO
            const _LIGHT_STAGE_RATIO = g.LIGHT_STAGE_RATIO===0?undefined:g.LIGHT_STAGE_RATIO
            const _DEEP_STAGE_RATIO = g.DEEP_STAGE_RATIO===0?undefined:g.DEEP_STAGE_RATIO
            if(g.LIGHT_STAGE_RATIO === 100){
                labelData1.push(0)
                deleteTodayMentalRecoverTime+= g.SLEEP_TIME
            }else {
                switch (selectedItemCd) {
                    case 'AWAKE':
                        labelData2.push(_AWAKE_STAGE_RATIO)
                        break
                    case 'REM':
                        labelData2.push(_REM_STAGE_RATIO)
                        break
                    case 'LIGHT':
                        labelData2.push(_LIGHT_STAGE_RATIO)
                        break
                    case 'DEEP':
                        labelData2.push(_DEEP_STAGE_RATIO)
                        break
                }
            }
        })


        labelData1.forEach(h => {
            maxRatio = NumberUtil.replaceBiggerNumber(maxRatio, Math.ceil(h/10)*10)
        })

        labelData2.forEach(i => {
            let _i = 0
            if(i%10===0 && i !== 100){
                _i = Number(i)+5
            }else{
                _i = Math.ceil(i/10)*10
            }
            maxRatio = NumberUtil.replaceBiggerNumber(maxRatio, _i)
        })

        labelData3.forEach(j => {
            maxRatio = NumberUtil.replaceBiggerNumber(maxRatio, Math.ceil(j/10)*10)
  })

        let _standFromRange = 0
        let _standToRange = 0

        v.data.sleepStageStandRangeList.forEach(k => {
            if(k.sleepStageNm === selectedItemCd){
                _standFromRange = k.standFromRange
                _standToRange = k.standToRange
            }
        })

        const realReportHeight = reportHeight-10-25 // 여백과 tick을 제외한 실제 레포트 높이
        const averageMarginTop = String(((maxRatio-_standToRange)*realReportHeight/maxRatio)+10)+'px'
        const averageMarginTopForLabel = String(((maxRatio-_standToRange)*realReportHeight/maxRatio)+12)+'px'
        const averageHeight = String(((_standToRange-_standFromRange)*realReportHeight/maxRatio))+'px'
        updateStandLabelStyle(averageMarginTopForLabel)
        updateStandRangeStyle(averageHeight, averageMarginTop)
        updateStandAverageStyle(averageHeight, averageMarginTop)
        updateData(labels, labelData1, labelData2, labelData3)
        updateOptions(maxRatio)
        updatePastLabelVisible(v)
        updateRecoveryData(v)
    }

    const updateStandAverageStyle = (averageHeight:string, averageMarginTop:string) => {
        setStandAverageStyle({
            position:'absolute',
            width: String(reportWidth+15)+'px',
            marginLeft: '40px',
            borderTop:'1px solid',
            borderColor:'#24CBAA',
            height: averageHeight,
            marginTop: averageMarginTop,  //=(1-8/8)*(240-30)
            //=((y축ticks높이-standToRange)*(차트높이-x축ticks높이)+기본마진10)/y축ticks높이
        })
    }

    const updateStandRangeStyle = (averageHeight:string, averageMarginTop:string) => {
        setStandRangeStyle({
            backgroundColor: 'rgba(173, 230, 223, 0.3)',
            position:'absolute',
            width: String(reportWidth-45)+'px',
            marginLeft: '40px',
            height: averageHeight,
            marginTop: averageMarginTop,  //=(1-8/8)*(240-30)
            //=((y축ticks높이-standToRange)*(차트높이-x축ticks높이)+기본마진10)/y축ticks높이
        })
    }

    const updateStandLabelStyle = (averageMarginTopForLabel:string) => {
        setStandLabelStyle({
            position:'absolute',
            left: String(reportWidth+30)+'px',
            height: 0,
            marginTop: averageMarginTopForLabel,  //=(1-8/8)*(240-30)
            //=((y축ticks높이-standToRange)*(차트높이-x축ticks높이)+기본마진10)/y축ticks높이
        })
    }

    const updateData = (_labels:string[], labelData1:number[], labelData2:number[] | undefined[] | object[] | undefined, labelData3:number[]) => {
        setData({
            labels: _labels,
            datasets: [
                {
                    type: 'bar' as const,
                    label: '이번주',
                    backgroundColor: '#24CBAA',
                    data: labelData1,
                    //borderColor: 'white',
                    barPercentage: 0.4,
                    order:2,
                },
                {
                    type: 'line' as const,
                    label: '지난주',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#CDCDCD',
                    borderWidth: 1,
                    fill: false,
                    data: labelData2,
                    order:1,
                },
                {
                    type: 'line' as const,
                    label: '평균',
                    borderColor: '#24CBAA',
                    borderWidth: 0.5,
                    fill: false,
                    data: labelData3,
                    pointRadius: 0,
                    order:3,
                    hidden: true,
                },
            ],
        })
    }

    const updateOptions = (maxRatio:number) => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                datalabels: false,
                legend: {
                    display: false,
                    position: 'right',
                },
                tooltip: false,
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxRotation: 0,
                        autoSkip: false,
                        font: {
                            size: 12
                        }
                    },
                },
                y: {
                    grid: {
                        drawBorder: false,
                        display: false
                    },
                    max: maxRatio,
                    ticks: {
                        stepSize: Math.ceil(maxRatio/2),
                        callback: function (value: number) {
                            let result = ''
                            if(value < 10){
                                result = '  ' + value.toString() + '%'
                            }else{
                                result = value.toString() + '%'
                            }
                            return result
                        }
                    },

                },

            },
        })
    }

    const updateRecoveryData = (v:SleepStageRes) => {
        setPastMentalRecoverTime(v.data.pastMentalRecoverTime>0?DateUtil.convertTimeFromSecToHourMin(v.data.pastMentalRecoverTime):'-')
        setPastBodyRecoverTime(v.data.pastBodyRecoverTime-deleteTodayMentalRecoverTime>0?DateUtil.convertTimeFromSecToHourMin(v.data.pastBodyRecoverTime-deleteTodayMentalRecoverTime):'-')
        setTodayMentalRecoverTime(v.data.todayMentalRecoverTime>0?DateUtil.convertTimeFromSecToHourMin(v.data.todayMentalRecoverTime):'-')
        setTodayBodyRecoverTime(v.data.todayBodyRecoverTime-deleteTodayBodyRecoverTime>0?DateUtil.convertTimeFromSecToHourMin(v.data.todayBodyRecoverTime-deleteTodayBodyRecoverTime):'-')
    }

    const updatePastLabelVisible = (v:SleepStageRes) => {
        let sumPastSleepTime = 0
        v.data.pastSleepStageList.forEach(d => {
            sumPastSleepTime += d.SLEEP_TIME
        })

        if(sumPastSleepTime > 0){
            setPastUnitMsgVisible(true)
        }else{
            setPastUnitMsgVisible(false)
        }
    }

    const updatePastUnitMsg = (periodType:string) => {
        if(periodType === 'WEEK'){
            setPastUnitMsg('지난주')
        }else if(periodType === 'MONTH'){
            setPastUnitMsg('지난달')
        }else if(periodType === 'YEAR'){
            setPastUnitMsg('지난해')
        }
    }

    useEffect(() => {
        if(props){
            const _sleepReportReq = new SleepReportReq (
                props.fromDate,
                props.month,
                props.periodType,
                '',
                props.toDate,
                props.userId,
                props.year,
            )
            setSleepReportReq(_sleepReportReq)
            updatePastUnitMsg(props.periodType)

        }
    },[props])

    useEffect(() => {
        if(sleepReportReq && StringUtil.isNotEmpty(sleepReportReq.periodType)){
            getSleepRecordStage()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[sleepReportReq])

    useImperativeHandle(ref, () => ({
        stageFocusOut: () =>{
            focusOut()
        }
    }))

    const focusOut = () => {
        if(mentalInfoVisible){
            setMentalInfoVisible(false)
        }

        if(bodyInfoVisible){
            setBodyInfoVisible(false)
        }
    }
    return (
                <>
                    <div onClick={focusOut}>
                        <div className={'s_title_1_m mt-56px'} style={{lineHeight:'1.2'}}>수면 단계 별로는<br/>얼마나 잠들었지?</div>
                        <div className={'gray-bar-thin mt-8px'}></div>
                        <div className={'b2_l mt-12px'}>{sleepStageMsg}</div>
                        <div className={'flex mt-40px sleepStageBtn-wrapper'}>
                            {itemList.map((_item, index)=>{
                                return <div key={index}> <button className={`${clickedIndex === index? 'on' : ''}`} onClick={() => selectButton(index, _item)}>{_item.itemNm}</button> </div>
                            })}
                        </div>
                        <div className={'report mt-16px flex w-100p'} ref={chartDivRef}>
                            {
                                data ?
                                    <>
                                        <div style={{position: 'relative', zIndex: '2', width:String(reportWidth)+'px'}} >
                                            <Chart type='bar' data={data} options={options} height={String(reportHeight)+'px'} width={String(reportWidth)+'px'}/>
                                        </div>
                                        <div className={'flex-column'} style={{justifyContent: 'flex-end'}}>
                                            <div className={'flex'} style={{paddingBottom:'3px', justifyContent: 'flex-end', paddingLeft:'6px'}} >
                                            {
                                                pastUnitMsgVisible?
                                                    <>
                                                        <Icon iconClass={'report-legend-pointer-gray w-14px h-8px'} />
                                                        <div className={'c1_r cl-gray04 pl-2px'}>{pastUnitMsg}</div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            </div>
                                        </div>

                                        <div style={standLabelStyle} >
                                            <div style={{justifyContent: 'flex-end'}} >
                                                <div className={'flex-column'} style={{width:'49px'}}>
                                                    <div className={'s_title_2_l cl-primary-cyan01'} style={{textAlign: 'right'}}>표준</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={standRangeStyle} >
                                        </div>
                                        <div style={standAverageStyle} >
                                        </div>
                                    </>
                                    :
                                    <Skeleton variant="rounded" height={reportHeight} width={'100%'}/>
                            }
                        </div>
                        <div className={'grid-data mt-32px'} >
                            <div className={'row'} >
                                <div className={'row-item c1_r'} style={{width:'33%'}}></div>
                                <div className={'row-item c1_r'} style={{width:'34%'}}>{props.title}</div>
                                <div className={'row-item c1_r'} style={{width:'33%'}}>{pastUnitMsg}</div>
                            </div>
                            <div className={'row'} >
                                <div className={'row-item flex justify-center'} style={{width:'33%'}} onClick={clickMentalInfo} >
                                    <div className={'c1_r'}>마음 회복</div>
                                    <Icon iconClass={'inform w-16px h-16px'} />
                                    {mentalInfoVisible?
                                        <div style={{position:'absolute', marginTop:'15px', marginLeft:'106px'}}>
                                            <Icon iconClass={'inform-sleep-mental w-184px h-64px'} />
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className={'row-item b2_l'} style={{width:'34%'}}>{todayMentalRecoverTime}</div>
                                <div className={'row-item b2_l'} style={{width:'33%'}}>{pastMentalRecoverTime}</div>
                            </div>
                            <div className={'row'} >
                                <div className={'row-item flex justify-center'} style={{width:'33%'}} onClick={clickBodyInfo} >
                                    <div className={'c1_r'}>신체 회복</div>
                                    <Icon iconClass={'inform w-16px h-16px'} />
                                    {bodyInfoVisible?
                                        <div style={{position:'absolute', marginTop:'15px', marginLeft:'150px'}}>
                                            <Icon iconClass={'inform-sleep-body w-228px h-64px'} />
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className={'row-item b2_l'} style={{width:'34%'}}>{todayBodyRecoverTime}</div>
                                <div className={'row-item b2_l'} style={{width:'33%'}}>{pastBodyRecoverTime}</div>
                            </div>
                        </div>
                        <div className={'stage-inform mt-32px mb-60px'} onClick={clickStageInfo} >
                            <div className={'stage-inform-header flex justify-between align-center'}>
                                <div className={'b2_r pl-18px'}>수면 단계란?</div>
                                <div className={'b2_r pr-22px'}>
                                    {
                                        stageInfoVisible?
                                                <Icon iconClass={'arrow-top w-12px h-6px'} />
                                            :
                                                <Icon iconClass={'arrow-bottom w-12px h-6px'} />
                                    }
                                </div>
                            </div>
                            {
                                stageInfoVisible ?
                                    <>
                                        <div className={'flex'}>
                                            <div className={'w-18px'} style={{height:'0.5px'}}></div>
                                            <div className={'gray-bar-thin'}></div>
                                            <div className={'w-18px'} style={{height:'0.5px'}}></div>
                                        </div>
                                        <div className={'stage-inform-detail mt-20px prpl-18px pb-24px'}>
                                            <div className={'b2_l'}>
                                                잠이 들면 우리 몸은 약 90분 주기로 REM 수면과 NON-REM 수면 단계를 번갈아 갑니다. 보통 하룻밤에 4, 5회 주기를 반복하며, 이보다 적으면 뇌와 신체가 충분히 회복하지 못해 피로감을 느끼게 됩니다. 또한, 우리 몸의 체온과 맥박은 잠들어 있는 동안에도 수면 단계에 따라 변화를 보입니다. 만약 오랜 시간 잤음에도 피로가 풀리지 않는다면, 수면 중에 너무 많은 신체 변화가 일어났기 때문일 수 있습니다.
                                            </div>
                                            <div className={'mt-30px'}>
                                                <img src={sleepStageInfo as string} style={{ display:'block', margin:'0 auto', width: '100%', height:'146px' }}/>
                                            </div>
                                            <div className={'mt-30px flex align-center'}>
                                                <div className={'mr-3px'}><Icon iconClass={'sleep-info-awake w-12px h-12px'} /></div>
                                                <div className={'b2_l'}>깨어남</div>
                                            </div>
                                            <div className={'b2_l mt-4px'}>
                                                수면 단계에 들어가지 않은 상태입니다. 수면 중 뒤척거리는 시간도 포함되며, 잠에서 깨어났지만 기억 못하는 경우도 포함됩니다. 수면 중 깨어나는 것은 다시 잠들지 못하는 게 아니라면 자연스러운 현상입니다.
                                            </div>
                                            <div className={'mt-20px flex align-center'}>
                                                <div className={'mr-3px'}><Icon iconClass={'sleep-info-rem w-12px h-12px'} /></div>
                                                <div className={'b2_l'}>REM 수면</div>
                                            </div>
                                            <div className={'b2_l mt-4px'}>
                                                REM 수면 단계에 들어서면 몸의 근육이 이완되면서 움직이기 어려워집니다. 눈동자는 좌우로 빠르게 움직이고 대뇌가 활발하게 활동하면서 우리가 일어났을 때 기억하게 되는 꿈을 꿉니다. 이때 우리의 뇌는 잠들기 전에 일어났던 일들을 정리하며 정신적인 회복 과정을 거칩니다.
                                            </div>
                                            <div className={'mt-20px flex align-center'}>
                                                <div className={'mr-3px'}><Icon iconClass={'sleep-info-nonrem w-12px h-12px'} /></div>
                                                <div className={'b2_l'}>NON-REM 수면 (얕은 수면 + 깊은 수면)</div>
                                            </div>
                                            <div className={'b2_l mt-4px'}>
                                                NON-REM 수면 단계에 들어서면 대뇌의 활동은 거의 잦아들고, 호흡과 심박수도 줄어듭니다. 간혹 꿈을 꾸기도 하지만 이때 꾸는 꿈은 대부분 기억하지 못합니다. NON-REM 수면은 얕은 수면과 깊은 수면으로 나뉩니다. 수면 초반에는 깊은 수면 단계까지 접어들며 주기를 반복하다가 새벽이 가까워질수록 얕은 수면 단계까지만 반복하는 경향을 보입니다. 얕은 수면 단계에서는 자주 뒤척이게 되며 쉽게 잠에서 깰 수 있습니다. 깊은 수면 단계에 들어서면 호흡과 심박수가 가장 낮아지며 잠에서 쉽게 깨어나지 않습니다. 또한, 이때에는 성장 호르몬의 분비가 증가하며 세포가 회복됩니다.
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </>

    )
})

export default HowLongByStage