import React, {useCallback, useEffect, useState} from "react"
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import DialogHeader from "@/view/layout/DialogHeader"
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import QuestionDetail from "@/view/question/component/QuestionDetail";
import boardService, {AnswerData, AnswerList, QuestionData} from "@/service/BoardService";

interface QuestionDetailViewParam {
    onClose: () => void,
    question: QuestionData | undefined
}

const QuestionAddView = (props:QuestionDetailViewParam) => {
    const handleOverlayClick = useCallback(() => {props.onClose()}, [props.onClose])
    const [answer, setAnswer] = useState<AnswerData>()

    const {refetch:getAnswer} = useQuery<AnswerList, AxiosError>(
        ['getAnswer'],
        ()=> boardService.getAnswer(props.question? props.question.questionNo: 0),
        {
            onSuccess: (v: AnswerList) => updateAnswerItem(v.data.objectList),
        }
    )

    const updateAnswerItem = (data:AnswerData[]) => {
        const answerData:AnswerData = data[0]
        setAnswer(answerData)
    }

    useEffect(  () => {
        getAnswer()
            .then(r => r.data)
            .catch(reason => {console.log(reason)})
    },[])

    return (
        <>
            <DialogHeader onClose={handleOverlayClick} title={'문의내역'}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <QuestionDetail question={props.question} answer={answer}/>
            </div>
        </>
    )
}

export default QuestionAddView