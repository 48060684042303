import React, {useEffect, useState} from 'react'
import {HealthReportData} from "@/service/HealthService";
import Icon from "@/view/common/component/Icon";
import Popup from "@/view/common/popup/Popup";
import ListSelect, {ListItem} from "@/view/common/ListSelect";
import HealthReportItem from "@/view/health/component/HealthReportItem";

export interface HealthReportParam{
    reportList?: HealthReportData[]
}

const HealthReport = (props: HealthReportParam) => {
    const [healthData, setHealthData] = useState<HealthReportData | undefined>(undefined)
    const [itemList, setItemList] = useState<ListItem[]>([])
    const defaultTheme = 'light'
    const [isOpen, setIsOpen] = useState(false)

    useEffect(()=>{
        setHealthData(props.reportList && props.reportList.length > 0 ? props.reportList[0] : undefined)
        if(props.reportList) {
            setItemList(props.reportList.map((data, index) => {
                return {name: data.checkupDate, value: index.toString()}
            }))
        }
    }, [props.reportList])

    const itemSelected = (index:string) => {
        if(props.reportList && props.reportList.length > 0){
            setHealthData(props.reportList[Number(index)])
        }
        setIsOpen(false)
    }

    return(
        <>
            {healthData !== undefined
                ?
                <>
                <div className={'report-list'}>
                    <div className={'mt-12px flex-column'}>
                        <div className={'flex'}>
                            <p className={'s_title_1_m'} onClick={()=> setIsOpen(true)}>{healthData.checkupDate}</p>
                            <div className={'flex align-center ml-8px'} onClick={()=> setIsOpen(true)}>
                                <Icon iconClass={'down-arrow-bold w-16px h-16px'}/>
                            </div>
                        </div>
                        <p className={'s_title_1_m'}>검진 결과입니다</p>
                        <HealthReportItem healthData={healthData}/>
                        <div className={'mt-64px'}/>
                    </div>
                </div>
                <Popup className='bg-cl-white' title='검진 기록을 선택해 주세요' content={<ListSelect onClick={itemSelected} itemList={itemList} selected={healthData.checkupDate} className={defaultTheme}/>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
                </>
                :
                <div className={'no-list'}>
                    <div className={'flex-column align-center'}>
                        <Icon iconClass={'no-report-list w-130px h-130px'}/>
                        <p className={'c1_r cl-gray03 text-center'}>검진 기록을 불러오시면<br/>ROTHY만의 노하우로 관리해드릴게요</p>
                    </div>
                </div>
            }
        </>
    )
}

export default HealthReport