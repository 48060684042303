export enum Actions {
    APPLE_SIGN_IN = "APPLE_SIGN_IN",
}

export enum AppleError {
    UNKNOWN = '1000',
    CANCELED = '1001',
    INVALID_RESPONSE = '1002',
    NOT_HANDLED = '1003',
    FAILED = '1004',
}

export declare interface AppleRequestResponse {
    nonce: string
    user: string
    fullName: null | {
        namePrefix: string | null
        givenName: string | null
        middleName: string | null
        familyName: string | null
        nameSuffix: string | null
        nickname: string | null
    }
    identityToken: string | null
    email: string | null
    state: string | null
    authorizationCode: string | null
}
