import {USER_ID} from "@/common/Constant";
import StorageUtil from "@/common/StorageUtil";
import UserService from "@/service/UserService";
import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Icon from "@/view/common/component/Icon";
import BoardService, {NotificationSettingReq, SingleDataResponse} from "@/service/BoardService";

const NotificationSetting = () => {
    const userId = Number(StorageUtil.getLocalStorage(USER_ID)) as number

    const [approve, setApprove] = useState(false)
    const [req, setReq] = useState<NotificationSettingReq>()

    const [emailAgree, setEmailAgree] = useState(false)

    useQuery<SingleDataResponse<string>, AxiosError>(
        ['selectNotificationSetting'],
        ()=> BoardService.selectNotificationSetting(),
        {
            enabled: true,
            onSuccess: (v: SingleDataResponse<string>) => updateApprove(v.data),
        }
    )

    const {refetch: postNotificationSetting } = useQuery<number, AxiosError>(
        ['postNotificationSetting'],
        ()=> BoardService.postNotificationSetting(req),
        {
            onSuccess: () => setApprove(!approve),
        }
    )

    useQuery(
        ['selectUserEmailAgree'],
        () => UserService.selectUserEmailAgree(),
        {
            enabled: true,
            onSuccess: (response) => {
                setEmailAgree(response.data === "Y" ? true : false)
            },
        }
    )

    const updateApprove = (data: string | undefined) => {
        setApprove(data === 'Y' ? true : false)
    }

    const updateAgreeY = () => {
        setReq({pushAgree : 'Y'})
    }

    const updateAgreeN = () => {
        setReq({pushAgree : 'N'})
    }

    useEffect(()=> {
        if(req !== undefined){
            postNotificationSetting()
                .then(() => {
                    return Promise<void>
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
    }, [req])

    const updateUserEmailAgree = (isAgree: boolean) => {
        UserService.updateUserEmailAgree(userId, isAgree === true ? "Y" : "N")
            .then(() => {
                setEmailAgree(isAgree)
            })
    }

    return (
        <>
            <div className='content-main'>
                {approve === true?
                    <div className='h4_r'>ROTHY가 옆에서<br/>챙겨드릴게요</div>
                    : <div className='h4_r'>알림을 켜고<br/>ROTHY와 함께해요</div>
                }
            </div>

            <div className='ptpb-22px'>
                <div className='flex justify-between align-center'>
                    <span className='b1_r'>필요한 알림</span>
                    {
                        approve ?
                            <div onClick={updateAgreeN}>
                                <Icon iconClass='toggle-on w-44px h-26px' />
                            </div>
                            :
                            <div onClick={updateAgreeY}>
                                <Icon iconClass='toggle-off w-44px h-26px' />
                            </div>
                    }
                </div>
                <span className='ft-cl-hint b2_l'>건강과 관련된 꼭 필요한 내용만 보내드려요</span>
            </div>
            <div className='ptpb-22px'>
                <div className='flex justify-between align-center'>
                    <span className='b1_r'>이메일 마케팅 수신 동의</span>
                    {
                        emailAgree ?
                            <div onClick={() => { updateUserEmailAgree(false) }}>
                                <Icon iconClass='toggle-on w-44px h-26px' />
                            </div>
                            :
                            <div onClick={() => { updateUserEmailAgree(true) }}>
                                <Icon iconClass='toggle-off w-44px h-26px' />
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default NotificationSetting;
