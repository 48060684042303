import React, {useEffect, useRef, useState} from 'react'
import {Skeleton} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import { Chart } from 'react-chartjs-2';
import {AxiosError} from "axios";
import SleepService, {SleepReportReq, SleepTimeRes} from "@/service/SleepService"
import StringUtil from "@/common/StringUtil";
import DateUtil from "@/common/DateUtil"
import Icon from "@/view/common/component/Icon";
import NumberUtil from "@/common/NumberUtil";
import {ChartDataSet} from "@/view/walk/component/WalkBarChart";
import {getLabelDataByReportType, ReportPropsConfig, ReportTypeItem} from "@/common/ReportUtil";

const HowLongByTime = (props:ReportPropsConfig) => {

    const reportTypeItemList = [{name:'전체', value:'DATE'}, {name:'요일별', value:'YOIL'}]
    const [clickedReportTypeIndex, setClickedReportTypeIndex] = useState(0)
    const [selectedReportTypeItem, setSelectedReportTypeItem] = useState<ReportTypeItem| undefined>(reportTypeItemList[0])

    const [resourceData, setResourceData] = useState<SleepTimeRes | undefined>(undefined)
    const [sleepReportReq, setSleepReportReq] = useState<SleepReportReq>( new SleepReportReq ('','','','','',0,''))
    const [recommSleepTimeMsg, setRecommSleepTimeMsg] = useState('');
    const [sleepTimeMsg, setSleepTimeMsg] = useState('');
    const [data, setData] = useState<{labels:string[], datasets:ChartDataSet[]}>()
    const[options, setOptions] = useState({})

    const [recommendSleepStyle, setRecommendSleepStyle] = useState({})
    const [averageSleepVisible, setAverageSleepStyleVisible] = useState(false)
    const [averageSleepStyle, setAverageSleepStyle] = useState({})
    const [averageSleepLabelStyle, setAverageSleepLabelStyle] = useState({})
    const [averageSleepHourMin, setAverageSleepHourMin] = useState('')

    const [pastUnitMsg, setPastUnitMsg] = useState<string | undefined>(undefined)
    const [pastUnitMsgVisible, setPastUnitMsgVisible] = useState<boolean>(true)

    const chartDivRef = useRef<HTMLDivElement>(null)

    const reportHeight = 240
    const divWidth = chartDivRef.current ? chartDivRef.current.clientWidth : window.innerWidth - 48
    const reportWidth = divWidth * 0.75

    const {refetch: getSleepRecordTime} = useQuery<SleepTimeRes, AxiosError>(
        ['getSleepRecordTime'],
        () => SleepService.getSleepRecordTime(sleepReportReq),
        {
            onSuccess: (v: SleepTimeRes) => success(v)
        }
    )

    const selectReportTypeButton = (index:number, _data:ReportTypeItem) =>{
        setClickedReportTypeIndex(index)
        setSelectedReportTypeItem(_data)
    }

    const success = (v:SleepTimeRes) => {
        console.log(v)
        setResourceData(v)
    }

    useEffect(()=>{
        if(resourceData){
            createChart(resourceData)
        }
    },[resourceData])

    const createChart = (v:SleepTimeRes) => {
        setRecommSleepTimeMsg(v.data.recommSleepTimeMsg)
        setSleepTimeMsg(v.data.sleepTimeMsg)
        const labels:string[] = []

        const labelData1:number[] = []
        const labelData2:any[] = []
        const labelData3:number[] = []

        let maxHourRound = 0

        v.data.todaySleepList.forEach(d => {
            labels.push(getLabelDataByReportType(d, props.periodType, selectedReportTypeItem))
            const _data = DateUtil.convertTimeFromSecToHour(d.SLEEP_TIME)
            labelData1.push(_data)
            labelData3.push(DateUtil.convertTimeFromSecToHour(v.data.avgSleepTime))
            maxHourRound = NumberUtil.replaceBiggerNumber(maxHourRound, Math.ceil(_data))
        })

        if(v.data.pastSleepList){
            v.data.pastSleepList.forEach(d => {
                const _data = DateUtil.convertTimeFromSecToHour(d.SLEEP_TIME)
                maxHourRound = NumberUtil.replaceBiggerNumber(maxHourRound, Math.ceil(_data))
                labelData2.push(_data===0?undefined:_data)
            })
        }

        const maxTimeByAge = Math.ceil(v.data.sleepMaxTimeByAge)
        maxHourRound = NumberUtil.replaceBiggerNumber(maxHourRound, maxTimeByAge)

        const realReportHeight = reportHeight-40
        const recommendSleepHeight = String((v.data.sleepMaxTimeByAge/maxHourRound-v.data.sleepMinTimeByAge/maxHourRound)*realReportHeight)+'px'
        const recommendSleepMarginTop = String((1-v.data.sleepMaxTimeByAge/maxHourRound)*realReportHeight+10)+'px'
        const averageSleepLabelMarginTop = String((1-DateUtil.convertTimeFromSecToHour(v.data.avgSleepTime)/maxHourRound)*realReportHeight+12)+'px'
        const averageSleepMarginTop = String((1-DateUtil.convertTimeFromSecToHour(v.data.avgSleepTime)/maxHourRound)*realReportHeight+10)+'px'
        if(v.data.avgSleepTime > 0) {
            setAverageSleepStyleVisible(true)
        }else{
            setAverageSleepStyleVisible(false)
        }
        updateAverageSleepHourMin(v.data.avgSleepTime)
        updateRecommendSleepStyle(recommendSleepHeight, recommendSleepMarginTop)
        updateAverageSleepLabelStyle(averageSleepLabelMarginTop)
        updateAverageSleepStyle(averageSleepMarginTop)
        updateData(labels, labelData1, labelData2, labelData3)
        updateOptions(maxHourRound)
        updatePastLabelVisible(v)
    }

    const updateAverageSleepHourMin = (avgSleepTime:number) => {
        const hour = parseInt((avgSleepTime/3600).toString())
        const min = parseInt(((avgSleepTime%3600)/60).toString())
        if(hour > 0 ){
            setAverageSleepHourMin(String(hour) +'h ' + String(min) +'m')
        }else if(min > 0 ){
            setAverageSleepHourMin( String(min) +'m')
        }
    }

    const updateAverageSleepStyle = (averageSleepMarginTop:string) => {
        setAverageSleepStyle({
            position:'absolute',
            width: String(reportWidth+27)+'px',
            marginLeft: '28px',
            borderTop:'1px dashed',
            borderColor:'#24CBAA',
            marginTop: averageSleepMarginTop,})
    }
    const updateAverageSleepLabelStyle = (averageMarginTop:string) => {
        setAverageSleepLabelStyle({
            //backgroundColor: 'blue',
            position:'absolute',
            //width: '100%',
            left: String(reportWidth+30)+'px',
            height: String(reportHeight)+'px',
            marginTop: averageMarginTop,  //=(1-8/8)*(240-30)
            //=(1-min추천시간/최대시간반올림)*(차트높이-x축ticks높이)
        })
    }

    const updateRecommendSleepStyle = (recommendSleepHeight:string, recommendSleepMarginTop:string) => {
        setRecommendSleepStyle({
            position:'absolute',
            backgroundColor: 'rgba(173, 230, 223, 0.3)',
            width: String(reportWidth-30)+'px',
            height: recommendSleepHeight ,    // =(8/8-7/8)*(240-30) , 단위 px
            // =(max추천시간/최대시간반올림-mim추천시간/최대시간반올림)*(차트높이-x축ticks높이)
            marginLeft: '28px',
            marginTop: recommendSleepMarginTop,  //=(1-8/8)*(240-30)
            //=(1-max추천시간/최대시간반올림)*(차트높이-x축ticks높이)
        })
    }
    const updateData = (_labels:string[], labelData1:number[], labelData2: number[] | undefined[] | object[] | undefined, labelData3:number[]) => {
        setData({
            labels: _labels,
            datasets: [
                {
                    type: 'bar' as const,
                    label: '이번주',
                    backgroundColor: '#24CBAA',
                    data: labelData1,
                    //borderColor: 'white',
                    barPercentage: 0.4,
                    order:2,
                },
                {
                    type: 'line' as const,
                    label: '지난주',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#CDCDCD',
                    borderWidth: 1,
                    fill: false,
                    data: labelData2,
                    order:1,
                },
                {
                    type: 'line' as const,
                    label: '평균',
                    borderColor: '#24CBAA',
                    borderWidth: 1,
                    borderDash: [3, 3],
                    fill: false,
                    data: labelData3,
                    pointRadius: 0,
                    order:3,
                    hidden: true
                },
            ],
        })
    }

    const updateOptions = (maxHourRound: number) => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                datalabels: false,
                legend: {
                    display: false,
                    position: 'right',
                },
                tooltip: false,
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxRotation: 0,
                        autoSkip: false,
                        font: {
                            size: 12
                        }
                    },
                },
                y: {
                    grid: {
                        drawBorder: false,
                        display: false
                    },
                    max: maxHourRound,
                    ticks: {
                        stepSize: Math.round(maxHourRound/2),
                        callback: function (value: number) {
                            let result = ''
                            if(value < 10){
                                result = '  ' + value.toString()
                            }else{
                                result = value.toString()
                            }
                            return result
                        }
                    },
                },

            },
        })
    }

    const updatePastLabelVisible = (v:SleepTimeRes) => {
        let sumPastSleepTime = 0
        if(v.data.pastSleepList){
            v.data.pastSleepList.forEach(d => {
                sumPastSleepTime += d.SLEEP_TIME
            })
        }

        if(sumPastSleepTime > 0){
            setPastUnitMsgVisible(true)
        }else{
            setPastUnitMsgVisible(false)
        }
    }



    const updatePastUnitMsg = (periodType:string) => {
        if(periodType === 'WEEK'){
            setPastUnitMsg('지난주')
        }else if(periodType === 'MONTH'){
            setPastUnitMsg('지난달')
        }else if(periodType === 'YEAR'){
            setPastUnitMsg('지난해')
        }
    }

    useEffect(() => {
        let _selectType = 'DATE'
        if(selectedReportTypeItem){
            _selectType = selectedReportTypeItem.value
        }
        if(props){
            const _sleepReportReq = new SleepReportReq (
                props.fromDate,
                props.month,
                props.periodType,
                _selectType,
                props.toDate,
                props.userId,
                props.year,
            )
            setSleepReportReq(_sleepReportReq)
            updatePastUnitMsg(props.periodType)
        }
    },[props, selectedReportTypeItem])


    useEffect(() => {
        if(sleepReportReq && StringUtil.isNotEmpty(sleepReportReq.periodType)){
            getSleepRecordTime()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[sleepReportReq])

    return (
                <>
                        <div className={'s_title_1_m mt-40px'} style={{lineHeight:'1.2'}}>
                            <div className={'flex align-center justify-between walk-far'}>
                                <div>얼마나 잠들었지?</div>
                                {
                                    props.periodType === 'MONTH'?
                                        <div className={'flex toggle-box'}>
                                            {reportTypeItemList.map((_data, index)=>{
                                                return <div key={index}> <button className={`${clickedReportTypeIndex === index? 'on' : ''}`} onClick={() => selectReportTypeButton(index,_data)}>{_data.name}</button> </div>
                                            })}
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>

                        <div className={'gray-bar-thin mt-8px'}></div>
                        <div className={'c1_r cl-gray04 mt-5px'}>{recommSleepTimeMsg}</div>
                        <div className={'b2_l mt-12px'}>{sleepTimeMsg}</div>
                        <div className={'report mt-40px flex w-100p'} ref={chartDivRef}>
                        {
                            data ?
                                <>
                                    <div style={{position:'relative', zIndex:'2', width:String(reportWidth)+'px'}} >
                                        <Chart type='bar' data={data} options={options} height={String(reportHeight)+'px'} width={String(reportWidth)+'px'}  />
                                    </div>
                                    <div className={'flex-column'} style={{ justifyContent: 'end'}}>
                                        <div className={'flex'} style={{paddingBottom:'3px', justifyContent: 'flex-end', paddingLeft:'6px'}}>
                                            {
                                                pastUnitMsgVisible?
                                                    <>
                                                        <Icon iconClass={'report-legend-pointer-gray w-14px h-8px'} />
                                                        <div className={'c1_r cl-gray04 pl-2px'}>{pastUnitMsg}</div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div style={recommendSleepStyle}></div>
                                    {
                                        averageSleepVisible?
                                            <>
                                                <div style={averageSleepLabelStyle} >
                                                    <div style={{justifyContent: 'flex-end'}} >
                                                        <div className={'flex-column'} >
                                                            <div className={'s_title_2_l cl-primary-cyan01'} style={{textAlign: 'right'}}>평균</div>
                                                            <div className={'s_title_2_l cl-primary-cyan01'} style={{textAlign: 'right'}}>{averageSleepHourMin}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={averageSleepStyle} >
                                                </div>
                                            </>
                                        :
                                            <></>
                                    }
                                </>
                            :
                            <Skeleton variant="rounded" height={reportHeight} width={'100%'}/>
                        }
                    </div>

                </>

    )
}

export default HowLongByTime