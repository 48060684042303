export enum Actions {
    IC_UPDATE_USER_INFO = "updateUserInfo",
    IC_INIT_MGR = "initMgr",
    IC_ADD_DEVICE = 'addDevice',
    IC_REMOVE_DEVICE = 'removeDevice',
    ON_IC_START_SCAN = 'scanDevice',
    ON_IC_STOP_SCAN = 'stopScan',
}

export enum ICSexType {
    /**
     * 알 수 없음/비공개
     */
    ICSexTypeUnknown = 0,

    /**
     * 남
     */
    ICSexTypeMale = 1,

    /**
     * 여
     */
    ICSexTypeFemal
}

export enum ICBFAType {
    /*
     * 수분 함량 근육
     */
    ICBFATypeWLA01 = 0,
    /*
     * 물 없는 근육
     */
    ICBFATypeWLA02 = 1,
    /*
     * 새로운 알고리즘1
     */
    ICBFATypeWLA03 = 2,
    /*
     * 새로운 알고리즘2
     */
    ICBFATypeWLA04 = 3,
    /*
     * 새로운 알고리즘3
     */
    ICBFATypeWLA05 = 4,
    /*
     * 새로운 알고리즘4
     */
    ICBFATypeWLA06 = 5,
    /*
     * 새로운 알고리즘WLA07
     */
    ICBFATypeWLA07 = 6,
    /*
     * 새로운 알고리즘WLA08
     */
    ICBFATypeWLA08 = 7,
    /*
     * 새로운 알고리즘WLA09
     */
    ICBFATypeWLA09 = 8,
    /*
     * 새로운 알고리즘WLA10
     */
    ICBFATypeWLA10 = 9,
    /*
     * 새로운 알고리즘WLA11
     */
    ICBFATypeWLA11 = 10,
    /*
     * 새로운 알고리즘WLA12
     */
    ICBFATypeWLA12 = 11,
    /*
     * 새로운 알고리즘WLA13
     */
    ICBFATypeWLA13 = 12,
    /*
     * 새로운 알고리즘WLA14
     */
    ICBFATypeWLA14 = 13,
    /*
     * 새로운 알고리즘WLA15
     */
    ICBFATypeWLA15 = 14,
    /*
     * 새로운 알고리즘WLA16
     */
    ICBFATypeWLA16 = 15,
    /*
     * 새로운 알고리즘WLA17
     */
    ICBFATypeWLA17 = 16,
    /*
     * 새로운 알고리즘WLA18
     */
    ICBFATypeWLA18 = 17,
    /*
     * 새로운 알고리즘WLA19
     */
    ICBFATypeWLA19 = 18,
    ICBFATypeUnknown = 100,
    ICBFATypeRev = 101
}

export enum ICPeopleType {
    /*
     * 보통 사람들
     */
    ICPeopleTypeNormal,

    /*
     * 운동 선수
     */
    ICPeopleTypeSportman,
}

export enum ICWeightUnit {
    /**
     * 킬로그램
     */
    ICWeightUnitKg,

    /**
     * 파운드
     */
    ICWeightUnitLb,

    /**
     * 잉시
     */
    ICWeightUnitSt,

    /**
     * 캐티
     */
    ICWeightUnitJin
}

export enum ICRulerUnit {
    /**
     * cm
     */
    ICRulerUnitCM = 1,

    /**
     * inch
     */
    ICRulerUnitInch,
}

export enum ICRulerMeasureMode {
    /**
     * 길이 모드
     */
    ICRulerMeasureModeLength = 0,

    /**
     * 둘레 모드
     */
    ICRulerMeasureModeGirth,
}

export enum ICKitchenScaleUnit {
    /**
     * 그램
     */
    ICKitchenScaleUnitG,

    /**
     * ml
     */
    ICKitchenScaleUnitMl,

    /**
     * 파운드
     */
    ICKitchenScaleUnitLb,

    /**
     * 온스
     */
    ICKitchenScaleUnitOz,
}

export enum ICDeviceType {
    /**
     * 알려지지 않은
     **/
    ICDeviceTypeUnKnown = 0,

    /**
     * 저울
     **/
    ICDeviceTypeWeightScale,

    /**
     * 체지방
     **/
    ICDeviceTypeFatScale,

    /**
     * 체지방률(체온 표시 포함)
     **/
    ICDeviceTypeFatScaleWithTemperature,

    /**
     * 주방 저울
     **/
    ICDeviceTypeKitchenScale,

    /**
     * 자
     **/
    ICDeviceTypeRuler,

    /**
     * 저울
     **/
    ICDeviceTypeBalance,

    /**
     * 줄넘기
     **/
    ICDeviceTypeSkip,
}

export enum ICDeviceSubType {
    /**
     * 기본
     **/
    ICDeviceSubTypeDefault = 0,

    /**
     * 8전극 장치
     **/
    ICDeviceSubTypeEightElectrode,

    /**
     * 높이 장비
     **/
    ICDeviceSubTypeHeight,
}

export enum ICDeviceCommunicationType {
    /**
     알려지지 않은
     */
    ICDeviceCommunicationTypeUnknown,

    /**
     연결된
     */
    ICDeviceCommunicationTypeConnect,

    /**
     방송
     */
    ICDeviceCommunicationTypeBroadcast,
}

export class ICUserInfo {
    /**
     사용자 번호,기본값:1
     */
    userIndex = 1;

    /**
     키(cm),기본값:172cm
     */
    height = 172;

    /**
     몸무게(kg),기본값:60.0kg
     */
    weight = 60.0;

    /**
     나이,기본값:24
     */
    age = 24;

    /**
     성별,기본값:ICSexTypeMale
     */
    sex: ICSexType = ICSexType.ICSexTypeMale;

    /**
     체지방 알고리즘 버전 사용,기본값:ICBFATypeWLA01
     */
     bfaType: ICBFAType = ICBFAType.ICBFATypeWLA01;

    /**
     사용자 유형,기본값:ICPeopleTypeNormal
     */
    peopleType: ICPeopleType = ICPeopleType.ICPeopleTypeNormal;

    /**
     사용자 기본 무게 단위,기본값:ICWeightUnitKg
     */
    weightUnit: ICWeightUnit = ICWeightUnit.ICWeightUnitKg;

    /**
     사용자 기본 둘레 단위,기본값:ICRulerUnitCM
     */
    rulerUnit: ICRulerUnit = ICRulerUnit.ICRulerUnitCM;

    /**
     사용자의 기본 둘레 측정 모드,기본값:ICRulerMeasureModeLength
     */
    rulerMode: ICRulerMeasureMode = ICRulerMeasureMode.ICRulerMeasureModeLength;

    /**
     주방 저울 기본 단위,기본값:ICKitchenScaleUnitG
     */
    kitchenUnit = ICKitchenScaleUnit.ICKitchenScaleUnitG;

    /**
     * 측정 임피던스 활성화 여부,기본값:true,지원되는 기기만 유효합니다.
     */
    enableMeasureImpendence = true;
    /**
     * HR 측정 활성화 여부,기본값:true,지원되는 기기만 유효합니다.
     */
    enableMeasureHr = true;
    /**
     * 측정 저울 활성화 여부,기본값:true,지원되는 기기만 유효합니다.
     */
    enableMeasureBalance = true;
    /**
     * 무게 중심 측정 활성화 여부,기본값:true,지원되는 기기만 유효합니다.
     */
    enableMeasureGravity = true;
}

export declare interface ICScanDeviceInfo {
    /**
     방송명
     */
    name: string;

    /**
     장비 유형
     */
    type: ICDeviceType;

    /**
     장치 하위 유형
     */
    subType: ICDeviceSubType;

    /**
     디바이스 통신 방식
     */
    communicationType: ICDeviceCommunicationType;

    /**
     맥 주소
     */
    macAddr: string;

    /**
     서비스 ID 목록
     */
    services: string[];

    /**
     신호 강도(작을수록 커짐, 0: 시스템과 장치가 페어링됨, -128: 신호 값이 잘못됨)
     */
    rssi: number;
}

export declare interface ICWeightExtData {
    /**
     왼손 체지방률(단위: %, 정확도: 0.1, float)
     */
    left_arm: number

    /**
     오른손 체지방률(단위: %, 정확도: 0.1, float)
     */
    right_arm: number

    /**
     왼발 체지방률(단위:%, 정확도: 0.1, float)
     */
    left_leg: number

    /**
     오른발 체지방률(단위: %, 정밀도: 0.1, float)
     */
    right_leg: number

    /**
     몸통의 체지방률(단위:%, 정확도: 0.1, float)
     */
    all_body: number

    /**
     왼손 지방량(단위: kg, 정확도: 0.1, float)
     */
    left_arm_kg: number

    /**
     오른손 지방량(단위: kg, 정밀도: 0.1, float)
     */
    right_arm_kg: number

    /**
     왼발 지방량(단위: kg, 정확도: 0.1, float)
     */
    left_leg_kg: number

    /**
     오른쪽 발 지방량(단위: kg, 정확도: 0.1, float)
     */
    right_leg_kg: number

    /**
     체지방량(단위: kg, 정밀도: 0.1, float)
     */
    all_body_kg: number

    /**
     왼손 근육 비율(단위: %, 정확도: 0.1, float)
     */
    left_arm_muscle: number

    /**
     오른손 근육 비율 (단위:%, 정확도: 0.1, float)
     */
    right_arm_muscle: number

    /**
     왼발 근육 비율 (단위: %, 정확도: 0.1, float)
     */
    left_leg_muscle: number

    /**
     오른발 근육 비율 (단위: %, 정확도: 0.1, float)
     */
    right_leg_muscle: number

    /**
     몸통 근육 비율(단위:%, 정확도: 0.1, float)
     */
    all_body_muscle: number

    /**
     왼손 근육량(단위: kg, 정확도: 0.1, float)
     */
    left_arm_muscle_kg: number

    /**
     오른손 근육량(단위: kg, 정확도: 0.1, float)
     */
    right_arm_muscle_kg: number

    /**
     왼발 근육량(단위: kg, 정밀도: 0.1, float)
     */
    left_leg_muscle_kg: number

    /**
     오른발 근육량(단위: kg, 정확도: 0.1, float)
     */
    right_leg_muscle_kg: number

    /**
     몸통 근육량(단위: kg, 정확도: 0.1, float)
     */
    all_body_muscle_kg: number
}

/**
 体重数据
 */
export declare interface ICWeightData {
    /**
     데이터가 안정적인가
     @notice 데이터가 불안정하면 weight_kg, weight_lb만 유효하고 불안정한 데이터는 표시용이므로 저장하지 마세요.
     */
    isStabilized: boolean

    /**
     체중(g, unsigned int)
     */
    weight_g: number

    /**
     체중(kg, float)
     */
    weight_kg: number

    /**
     무게(lb, float)
     */
    weight_lb: number

    /**
     체중(st:lb, unsigned int), 참고: 이 필드는 weight_st_lb와 함께 사용됩니다.
     */
    weight_st: number

    /**
     체중(st:lb, float)，참고: 이 필드는 weight_st와 함께 사용됩니다.
     */
    weight_st_lb: number

    /**
     kg다음과 같은 가중치 소수점 이하 자릿수:weight_kg=70.12,precision=2，weight_kg=71.5,precision_kg=1, unsigned int
     */
    precision_kg: number

    /**
     lb다음과 같은 가중치 소수점 이하 자릿수:weight_lb=70.12,precision=2，weight_lb=71.5,precision_lb=1, unsigned int
     */
    precision_lb: number

    /**
     st:lb무게 소수 자릿수, unsigned int
     */
    precision_st_lb: number

    /**
     kg구분 값, unsigned int
     */
    kg_scale_division: number

    /**
     lb구분 값, unsigned int
     */
    lb_scale_division: number

    /**
     온도, 단위: 섭씨, float
     */
    temperature: number

    /**
     측정 타임스탬프(초), unsigned int
     */
    time: number

    /**
     심박수 측정 지원
     */
    isSupportHR: boolean

    /**
     심박수 값, unsigned int
     */
    hr: number

    /**
     체질량 지수(정밀도:0.1), float
     */
    bmi: number

    /**
     체지방률(백분율, 정밀도:0.1), float
     */
    bodyFatPercent: number

    /**
     피하 지방 비율(백분율, 정밀도:0.1), float
     */
    subcutaneousFatPercent: number

    /**
     내장지방지수(정밀도:0.1), float
     */
    visceralFat: number

    /**
     근육량(백분율, 정밀도:0.1), float
     */
    musclePercent: number

    /**
     기초 대사율(단위:kcal), unsigned int
     */
    bmr: number

    /**
     뼈 무게(단위: kg, 정확도: 0.1), float
     */
     boneMass: number

    /**
     수분 함량(백분율, 정확도: 0.1), 부유물
     */
    moisturePercent: number

    /**
     신체적 나이, float
     */
    physicalAge: number

    /**
     단백질 비율(백분율, 정확도: 0.1), float
     */
    proteinPercent: number

    /**
     골격근 비율(백분율, 정확도: 0.1), float
     */
    smPercent: number

    /**
     전극 수, 4전극 또는 8전극, unsigned int
     */
    electrode: number

    /**
     전신 임피던스(단위: 옴), 임피던스가 0이면 임피던스를 측정할 수 없음을 의미합니다., float
     */
    imp: number

    /**
     왼손(8 전극)의 임피던스(단위: ohm), 임피던스가 0이면 임피던스를 측정할 수 없음을 의미합니다., float
     */
    imp2: number

    /**
     오른손(8 전극)의 임피던스(단위: ohm), 임피던스가 0이면 임피던스를 측정할 수 없음을 의미합니다., float
     */
    imp3: number

    /**
     왼발(8 전극)의 임피던스(단위: 옴), 임피던스가 0이면 임피던스를 측정할 수 없음을 의미합니다., float
     */
    imp4: number

    /**
     오른발(8전극)의 임피던스(단위: ohm), 임피던스가 0이면 임피던스를 측정할 수 없음을 의미, float
     */
    imp5: number

    /**
     체중 확장 데이터(8 전극의 일부 데이터는 여기)
     */
    extData: ICWeightExtData

    /**
     데이터 계산 방식(0: sdk, 1: 디바이스 계산), unsigned int
     */
    data_calc_type: number

    /**
     이 체지방 데이터 계산을 위한 알고리즘 유형
     */
    bfa_type: ICBFAType

    /**
     * unsigned int
     */
    state: number

    /**
     * unsigned int
     */
    impendenceType: number

    /**
     * unsigned int
     */
    impendenceProperty: number
    impendences: number[]
    height: number
}
