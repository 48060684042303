import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import BottomButton from "@/view/common/component/button/BottomButton";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import DialogHeader from "@/view/layout/DialogHeader";
import {TermsListData} from "@/service/BoardService";

export interface TermsDetailConfig{
    onClose: () => void
    termDetail?: TermsListData
    type: string
    setType: (param:string) => void ,
    themeClass?: string
}

const TermsDetail = (props:TermsDetailConfig) => {
    const[agreeType, setAgreeType] = useState(props.type);
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const setType = useCallback((param:string) => props.setType(param), [props.setType])
    const [theme, setTheme] = useState<{background:string, icon: string, content: string} | undefined>(undefined)

    const clickAgree = () => {
        setAgreeType('Y')
        handleOverlayClick()
    }

    useEffect(() => {
        if(agreeType === 'Y'){
            setType('Y')
        }else{
            setType('N')
        }
    }, [agreeType])

    useEffect(() => {
        if(props.type === 'Y'){
            setAgreeType('Y')
        }else{
            setAgreeType('N')
        }
    }, [props.type])

    useEffect(()=>{
        setTheme({background: props.themeClass? 'bg-cl-white': 'bg-cl-black', icon: props.themeClass? 'prev-b': 'prev-w', content: props.themeClass? 'main-content-w': 'main-content'})
    }, [])

    return (
        <>
            <div id='TermsDetail' className={theme? theme.background: ''}>
                <DialogHeader onClose={handleOverlayClick} leftIcon={theme? theme.icon: ''}/>
                <HeaderSpacer className={theme? theme.background: ''}/>
                <div className={theme? theme.content: 'main-content'}>
                    <div className={'board'}>
                        <div className={theme? theme.background: ''}>
                           <span dangerouslySetInnerHTML={{__html: props.termDetail?props.termDetail.termsContents.replaceAll('NaN%;','')
                                   .replaceAll('color: black;','')
                                   .replaceAll('color: #000000;',''):''}}/>
                        </div>
                    </div>
                </div>
                {
                    agreeType === 'Y' ?
                        <BottomButton title={'동의 해제'} disabled={false} onClick={() => setAgreeType('N')} />
                    :
                        <BottomButton title={'동의'} disabled={false} onClick={() => clickAgree()} />
                }

            </div>
        </>
    )
}

export default TermsDetail