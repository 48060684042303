import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Dialog, Slide, SlideProps} from "@mui/material";
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import BottomButton from "@/view/common/component/button/BottomButton";
import TermsDetail from "@/view/signup/component/TermsDetail";
import BoardService, {TermsList, TermsListData} from "@/service/BoardService";
import {TermsAggreMents} from "@/service/UserService";
import Icon from "@/view/common/component/Icon";
import AgreeDetail from "@/view/signup/component/AgreeDetail";
import Signup from "@/view/signup/component/Signup"

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export interface TermsMainConfig {
    email?: string | null | undefined
    prevType?: string | null | undefined
    uuid?: string | null | undefined
}


export const TermsMain = (props:TermsMainConfig) => {

    const [open1, setOpenTermsAgree] = useState(false);
    const [open2, setOpenPrivateInfoAgree] = useState(false);
    const [open3, setOpenAdInfoAgree] = useState(false);
    const [open4, setOpenSignup] = useState(false);

    const [allAgree, setAllAgree] = useState('N')

    const [termsAgree, setTermsAgree] = useState('N');
    const [privateInfoAgree, setPrivateInfoAgree] = useState('N');
    const [adInfoAgree, setAdInfoAgree] = useState('N');
    const [emailAgree, setEmailAgree] = useState('N');

    const [termsList, setTermsList] = useState<TermsListData[]>([])
    const [termDetail, setTermDetail] = useState<TermsListData | undefined>(undefined)
    const defaultTermsAgreements = new TermsAggreMents('N',
                                                            'N',
                                                            'N',
                                                            'N',
                                                            'N',
                                                        )
    const [termsAgreements, setTermsAgreements] = useState<TermsAggreMents>(defaultTermsAgreements)

    const [_email] = useState(props.email)
    const [_prevType] = useState(props.prevType)
    const [_uuid] = useState(props.uuid)
    const {data:result} = useQuery<TermsList, AxiosError>(
        ['termsList'],
        ()=> BoardService.selectTerms(),
        {
            enabled: true,
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 60,
        }
    )

    useEffect(() => {
        if(result){
            updateTermsList(result.data.objectList)
        }
    }, [result])

    const updateTermsList = (data: TermsListData[]) => {
        const dataList : TermsListData[] =[]
        data.map( d => dataList.push({ termsId: d.termsId, termsNm: d.termsNm, termsContents: d.termsContents }))
        setTermsList(dataList)
    }

    const openTermsAgree = () => {
        setTermDetail(termsList[0])
        setOpenTermsAgree(true)
    }

    const openPrivateInfoAgree = () => {
        setTermDetail(termsList[1])
        setOpenPrivateInfoAgree(true)
    }

    const openAdInfoAgree = () => {
        setTermDetail(termsList[2])
        setOpenAdInfoAgree(true)
    }


    const closeDialog = () => {
        setOpenTermsAgree(false)
        setOpenPrivateInfoAgree(false)
        setOpenAdInfoAgree(false)
        setOpenSignup(false)
    }

    const goSignUp = () => {
        const _termsAgreements = new TermsAggreMents(
            termsAgree,
            privateInfoAgree,
            adInfoAgree,
            'N',
            'N',
        )
        setTermsAgreements(_termsAgreements)
        setOpenSignup(true)
    }


    useEffect(() => {
        if(allAgree === 'Y'){
            setTermsAgree('Y')
            setPrivateInfoAgree('Y')
            setAdInfoAgree('Y')
        }else{
            setTermsAgree('N')
            setPrivateInfoAgree('N')
            setAdInfoAgree('N')
        }
    }, [allAgree])

    return (
        <>
            <div id='TermsMain' className={'bg-cl-black'}>
                <div className={'main-content text-left'}>
                    <div className={'h5_m title mt-39px'}>필수 약관에<br/>동의해주세요</div>
                    <div className={'b2_r title mt-16px ft-cl-hint'}>원활한 서비스 이용을 위해 필요해요</div>
                    <div className={'terms-content'}>
                        <div className={'all-agree'}>
                            {
                                allAgree === 'Y' ?
                                    <div className={'all-agree-item all-agree-checkbox'} onClick={() => (setAllAgree('N'))}>
                                        <Icon iconClass='check-all-on w-20px h-20px' />
                                    </div>
                               :
                                    <div className={'all-agree-item all-agree-checkbox'} onClick={() => (setAllAgree('Y'))}>
                                        <Icon iconClass='check-all-off w-20px h-20px' />
                                    </div>
                            }
                            <div className={'all-agree-item'}>
                                <span className={'b1_m all-agree-items'}>약관 전체 동의</span>
                            </div>
                        </div>
                        <AgreeDetail type={termsAgree} setType={setTermsAgree} title={'[필수] 서비스 이용약관 동의'} openSignupDetail={() => openTermsAgree()}/>
                        <AgreeDetail type={privateInfoAgree} setType={setPrivateInfoAgree} title={'[필수] 개인정보 수집 및 이용동의'} openSignupDetail={() => openPrivateInfoAgree()}/>
                        <div className={'termDescription c1_r'}>
                            <div className={'row mb-4px'}>
                                <div className={'row-header'}>목적</div><div className={'row-body'}>개인식별, 서비스 이용을 위한 정보</div>
                            </div>
                            <div className={'row mb-4px'}>
                                <div className={'row-header'}>항목<br/> </div><div className={'row-body'}>이름, 성별, 생년월일, 개인식별번호,<br/>이메일, 닉네임</div>
                            </div>
                            <div className={'row'}>
                                <div className={'row-header'}>보유기간</div><div className={'row-body'}>회원탈퇴 시 즉시 파기</div>
                            </div>
                        </div>
                        <AgreeDetail type={adInfoAgree} setType={setAdInfoAgree} title={'[선택] 선택정보 수집 및 이용동의'} openSignupDetail={() => openAdInfoAgree()}/>
                        <div className={'termDescription c1_r'}>
                            <div className={'row mb-4px'}>
                                <div className={'row-header'}>목적</div><div className={'row-body'}>맞춤 정보 제공, 마케팅</div>
                            </div>
                            <div className={'row mb-4px'}>
                                <div className={'row-header'}>항목<br/> </div><div className={'row-body'}>성별, 생년월일, 이메일</div>
                            </div>
                            <div className={'row'}>
                                <div className={'row-header'}>보유기간</div><div className={'row-body'}>회원탈퇴 시 즉시 파기</div>
                            </div>
                        </div>
                        <div className={'mt-16px'}>
                            {
                                emailAgree === 'Y' ?
                                    <div className={'all-agree-item all-agree-checkbox'} onClick={() => (setEmailAgree('N'))}>
                                        <Icon iconClass='check-all-on w-20px h-20px' />
                                    </div>
                                    :
                                    <div className={'all-agree-item all-agree-checkbox'} onClick={() => (setEmailAgree('Y'))}>
                                        <Icon iconClass='check-all-off w-20px h-20px' />
                                    </div>
                            }
                            <div className={'all-agree-item'}>
                                <span className={'b1_m all-agree-items'}>[선택] 이메일 마케팅 수신 동의</span>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    termsAgree === 'Y' && privateInfoAgree === 'Y' ?
                        <BottomButton title={'동의하고 계속하기'} disabled={false} onClick={() => goSignUp()} />
                    :
                        <BottomButton title={'동의하고 계속하기'} disabled={true} />
                }
                <Dialog fullScreen open={open1} TransitionComponent={Transition}>
                    <TermsDetail onClose={() => closeDialog()} termDetail={termDetail} type={termsAgree} setType={setTermsAgree}/>
                </Dialog>

                <Dialog fullScreen open={open2} TransitionComponent={Transition}>
                    <TermsDetail onClose={() => closeDialog()} termDetail={termDetail} type={privateInfoAgree} setType={setPrivateInfoAgree}/>
                </Dialog>

                <Dialog fullScreen open={open3} TransitionComponent={Transition}>
                    <TermsDetail onClose={() => closeDialog()} termDetail={termDetail} type={adInfoAgree} setType={setAdInfoAgree}/>
                </Dialog>

                <Dialog fullScreen open={open4} TransitionComponent={Transition}>
                    <Signup onClose={() => closeDialog()} termsAgreements={termsAgreements} email={_email?_email:undefined} emailAgree={emailAgree} prevType={_prevType?_prevType:undefined} uuid={_uuid?_uuid:undefined}/>
                </Dialog>
            </div>
        </>
    )

}

export default TermsMain
