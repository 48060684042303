import React, {useCallback, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import DialogHeader from "@/view/layout/DialogHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import Icon from "@/view/common/component/Icon";
import HealthService, {HealthReportDetail, HealthReportDetailRes} from "@/service/HealthService";
import HealthItemChartView from "@/view/health/HealthItemChartView";
import StringUtil from "@/common/StringUtil";
import {exceptOrderList} from "@/common/Constant";


export interface HealthItemDetailParam{
    titleItem: string,
    onClose: () => void,
    itemList: string[],
    history: boolean
}

const HealthItemDetail = (props: HealthItemDetailParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [dataList, setDataList] = useState<HealthReportDetail[]>([])

    const {refetch:getHealthReportDetail} = useQuery<HealthReportDetailRes, AxiosError>(
        ['getHealthReportDetail'],
        ()=> HealthService.getHealthReportDetail({detailCodeList: props.itemList}),
        {
            onSuccess: (v: HealthReportDetailRes) => {
                setDataList(v.data.detailList)
            },
        }
    )

    useEffect(()=>{
        if(props.itemList.length > 0){
            getHealthReportDetail().then(r=>r).catch(reason=>console.log(reason))
        }
    }, [])

    const getLabel = (label: string, currentValue:string, labelType:string):string => {
        let suffix = ' 에요'
        if ("warning" === label) suffix = ' 주의 상태에요'
        if ("danger" === label) suffix = ' 위험 상태에요'
        if ("good" === label)  suffix = ' 정상 상태에요'
        if(labelType === 'TALL' || labelType === 'WEIGHT'){
            return currentValue + suffix
        }else if(labelType === 'EYE'){
            const leftValue = currentValue.split('/')[0]
            const rightValue = currentValue.split('/')[1]
            return '왼쪽 ' + leftValue + ', 오른쪽 ' + rightValue + ' 에요'
        }else if(labelType === 'EAR'){
            const leftValue = currentValue.split('/')[0]
            const rightValue = currentValue.split('/')[1]
            return '왼쪽 ' + leftValue + ', 오른쪽 ' + rightValue + ' 에요'
        }else if(labelType === 'UP'){
            return currentValue + '으로' + suffix
        }else if(labelType === 'TUB'){
            return currentValue + ' 상태에요'
        }else {
            return currentValue + '로' + suffix
        }
    }

    const getTitle = (labelType:string, itemName:string):string => {
        if(labelType === 'EYE'){
            return '최근 ' + itemName.replaceAll(' (좌/우)','')+'은'
        }else if(labelType === 'EAR'){
            return '최근 ' + itemName.replaceAll(' (좌/우)','')+'은'
        }else if(labelType === 'BP'){
            return '최근 ' + itemName.replaceAll(' (고/저)','')+'은'
        }else if(labelType === 'OST'){
            return '최근 ' + itemName + ' 수치는'
        }

        if(labelType === 'UP' || labelType === 'FBS' || labelType === 'TCH'
            || labelType === 'HDL' || labelType === 'LDL' || labelType === 'TC'
            || labelType === 'SC' || labelType === 'GFR'){
            return '최근 ' + itemName + '은'
        }
        return '최근 ' + itemName + '는'
    }

    return(
        <div id={'healthReportDetail'}>
            <div className={'health-report-header'}>
                <DialogHeader title={props.titleItem} leftIcon={'prev-b'} onClose={handleOverlayClick}/>
            </div>
            <HeaderSpacer/>
            <div className={'flex-column flex-start detail-list'}>
                {dataList && dataList.length > 0
                    ?
                    dataList.reverse().map((data, index)=>{
                        return (
                            <div key={index} className={`w-100p ${index > 0 ? 'mt-40px' : ''}`}>
                                <div className={'mt-12px flex-column flex-start'}>
                                    <p className={'s_title_1_m'}>{getTitle(data.labelType, data.itemName)}</p>
                                    <p className={'s_title_1_m'}>{getLabel(data.label, data.currentValue, data.labelType)}</p>
                                </div>
                                {exceptOrderList.includes(data.labelType)
                                    ?
                                    <>
                                        {StringUtil.isNotEmpty(data.goodValue) &&
                                            <div className={'flex align-center mt-14px'}>
                                                <Icon iconClass={'item-good w-30px h-16px'}/>
                                                <p className={'c1_r ml-4px'}>{data.goodValue}</p>
                                            </div>
                                        }
                                        {StringUtil.isNotEmpty(data.warningValue) &&
                                            <div className={'flex align-center mt-8px'}>
                                                <Icon iconClass={'item-warning w-30px h-16px'}/>
                                                <p className={'c1_r ml-4px'}>{data.warningValue}</p>
                                            </div>
                                        }
                                        {StringUtil.isNotEmpty(data.dangerValue) &&
                                            <div className={'flex align-center mt-8px'}>
                                                <Icon iconClass={'item-danger w-30px h-16px'}/>
                                                <p className={'c1_r ml-4px'}>{data.dangerValue}</p>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {StringUtil.isNotEmpty(data.dangerValue) &&
                                                <div className={'flex align-center mt-14px'}>
                                                    <Icon iconClass={'item-danger w-30px h-16px'}/>
                                                    <p className={'c1_r ml-4px'}>{data.dangerValue}</p>
                                                </div>
                                        }
                                        {StringUtil.isNotEmpty(data.warningValue) &&
                                            <div className={'flex align-center mt-8px'}>
                                            <Icon iconClass={'item-warning w-30px h-16px'}/>
                                            <p className={'c1_r ml-4px'}>{data.warningValue}</p>
                                            </div>
                                        }
                                        {StringUtil.isNotEmpty(data.goodValue) &&
                                            <div className={'flex align-center mt-8px'}>
                                            <Icon iconClass={'item-good w-30px h-16px'}/>
                                            <p className={'c1_r ml-4px'}>{data.goodValue}</p>
                                            </div>
                                        }
                                    </>
                                }
                                { data.graphYn === 'Y' &&
                                    <div className={'mt-23px'}>
                                        <HealthItemChartView {...data}/>
                                    </div>
                                }
                                { StringUtil.isNotEmpty(data.information) &&
                                    <div className={'health-information mt-24px'}>
                                        <p className={'c1_r cl-gray03 white-space-wrap'}>{data.information}</p>
                                    </div>
                                }
                                {props.history
                                    ?
                                    <div className={'mt-30px'}>
                                        {data.resultList.map((value, index)=>{
                                                return (
                                                    <div key={index} className={`flex align-center justify-between ${index > 0? 'mt-19px': ''}`}>
                                                        <div className={'flex-column'}>
                                                            <p className={'b2_r cl-gray03'}>{value.itemName}</p>
                                                            <p className={'b2_l cl-gray04'}>{value.checkupDate}</p>
                                                        </div>
                                                        <p className={'b2_r cl-gray03'}>{value.value}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className={'h-56px'}/>
                                    </div>
                                    : <div className={`${index === dataList.length-1 ? 'mt-56px' :''}`}/>
                                }
                            </div>
                        )
                    })
                : null
                }
            </div>
        </div>
    )
}

export default HealthItemDetail