import React from 'react'
import DocsLottie from "@/assets/lottie/docs.json";
import LottieAnimation from "@/view/common/component/LottieAnimation";

const ResultWait = () => {
    return (
        <div className={'flex-column align-center'}>
            <LottieAnimation animationData={DocsLottie}
                             lottieStyle={{width: '56px', height: '72px'}}/>
            <p className={'b2_r'}>1분 안에 검진 결과를 불러올게요<br/>잠시만 기다려 주세요</p>
        </div>
    )
}

export default ResultWait