import React from 'react'
import Icon from '@/view/common/component/Icon'
import {genderList} from "@/common/ValidateUtil";

export interface genderSelectParam{
    onClick: (value:string) => void,
    selected: string,
    className: string
}

const GenderSelect = (props:genderSelectParam) => {
    return (
            <div className={'custom-select'}>
                <div className={`${props.className}`}>
                    {genderList.map((data, index)=>{
                        return(<div key={index} className={`select-items ${index === genderList.length-1? 'mb-8px':''} ${data.name === props.selected?'item-selected' : ''}`} onClick={() => props.onClick(data.value)}>
                                    <p className={'b2_r'}>{data.name}</p>
                                    <div id ={'select-item-'+ index.toString()} style={{display: data.name === props.selected? '': 'none'}}><Icon iconClass={'select-box-selected'}/></div>
                               </div>)
                    })}
                </div>
            </div>
    )
}

export default GenderSelect