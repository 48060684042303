import React, {forwardRef, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import {Skeleton} from "@mui/material"
import {FocusItem} from "@/service/UserService"
import WeightService, {WeightRecordReq, WeightAmountRes} from "@/service/WeightService"
import StringUtil from "@/common/StringUtil"
import StorageUtil from "@/common/StorageUtil"
import {FOCUS_ITEM} from "@/common/Constant"
import WeightAmountChart, {WeightAmountChartData} from "@/view/weight/component/WeightAmountChart"

interface weightAmountParam{
    focusItemList : FocusItem[]
    reqParam: WeightRecordReq
}

const WeightAmount = forwardRef((props:weightAmountParam, ref) => {
    const focusItem:FocusItem = StringUtil.jsonString2ObjectWithMap<FocusItem>(StorageUtil.getLocalStorage(FOCUS_ITEM) as unknown as '')
    const focusCd = focusItem? focusItem.focusCd : 'BDFAT_RATE'
    const [focusUnit, setFocusUnit] = useState(focusItem? focusItem.focusUnit : '%')
    const [clickedIndex, setClickedIndex] = useState(0)
    const [itemList,setItemList] = useState<FocusItem[]>(props.focusItemList)
    const [amountReq,setAmountReq] = useState<WeightRecordReq>(new WeightRecordReq(0,'','','','','',''))
    const [amountComment,setAmountComment] = useState('')
    const [currentAvgValue,setCurrentAvgValue] = useState(0)
    const [avgStatus,setAvgStatus] = useState('')
    const [chartData,setChartData] = useState<WeightAmountChartData>()
    const [data,setData] = useState<WeightAmountChartData>()
    const [lastYn,setLastYn] = useState('N')
    const [pastUnitMsg, setPastUnitMsg] = useState<string | undefined>(undefined)

    const {refetch: getAmountData} = useQuery<WeightAmountRes, AxiosError>(
        ['getWeightAmount'],
        ()=> WeightService.getWeightAmount(amountReq),
        {
            onSuccess: (v: WeightAmountRes) => success(v)
        }
    )

    const success = (v:WeightAmountRes) => {
        setAmountComment(v.data.comment)
        setCurrentAvgValue(v.data.currentAvgValue)
        setAvgStatus(v.data.avgStatus)

        let _minValue
        let _maxValue
        let _sizeValue
        if(v.data.currentYn === 'N' && v.data.lastYn === 'N'){
            _sizeValue = 1
            if(focusCd === 'WT'){
                _minValue = 60
                _maxValue = 65
            } else {
                _minValue = 20
                _maxValue = 25
            }
        } else {
            _sizeValue = 4
            const chartAvg = v.data.currentAvgValue !== 0 ? v.data.currentAvgValue : v.data.lastAvgValue
            _minValue = Math.floor(chartAvg) -10
            _maxValue = Math.floor(chartAvg) +10
        }

        setChartData({minValue: _minValue, maxValue: _maxValue, sizeValue: _sizeValue, currentList: v.data.currentList, currentYn: v.data.currentYn, lastList: v.data.lastList, lastYn: v.data.lastYn, unit: focusUnit})
        setLastYn(v.data.lastYn)
    }

    useEffect(() => {
        setData(chartData)
        updatePastUnitMsg()
    }, [chartData])

    useEffect(() => {
        setItemList(props.focusItemList !== undefined? props.focusItemList : [])
    }, [props.focusItemList])

    const selectButton = (index:number, data:FocusItem) =>{
        setFocusUnit(data.focusUnit)
        setClickedIndex(index)
        setAmountReq((prevState) => ({
            userId:prevState.userId, periodType:prevState.periodType, fromDate:prevState.fromDate,
            toDate:prevState.toDate, month:prevState.month, year:prevState.year, selectType:data.focusCd
        }))
    }

    useEffect(() => {
        if(props){
            const _weightRecordReq = new WeightRecordReq (
                props.reqParam.userId,
                props.reqParam.periodType,
                props.reqParam.fromDate,
                props.reqParam.toDate,
                props.reqParam.month,
                props.reqParam.year,
                focusCd
            )
            setAmountReq(_weightRecordReq)
        }

        itemList.map((data,index) => {
            if(data.focusCd === focusCd) {
                setClickedIndex(index)
                setFocusUnit(data.focusUnit)
            }
        })
    },[props, itemList])

    useEffect(() => {
        if(amountReq && StringUtil.isNotEmpty(amountReq.periodType)){
            getAmountData()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[amountReq])

    const updatePastUnitMsg = () => {
        if(props.reqParam.periodType === 'WEEK'){
            setPastUnitMsg('지난주')
        }else if(props.reqParam.periodType === 'MONTH'){
            setPastUnitMsg('지난달')
        }else if(props.reqParam.periodType === 'YEAR'){
            setPastUnitMsg('지난해')
        }else{
            setPastUnitMsg(undefined)
        }
    }

    return (
        <div id="WeightAmount">
            <div className={'weight-record-title'}>내 체성분은?</div>
            <div className={'weight-amount-button'}>
                {itemList.map((data, index)=>{
                    return <div key={index}> <button className={`${clickedIndex === index? 'on' : ''}`} onClick={() => selectButton(index,data)}>{data.focusNm}</button> </div>
                })}
            </div>
            <div className={'b2_l pb-40px'}>{amountComment}</div>
            <div>
                <span className={'s_title_2_r'}>평균</span>
                {avgStatus !== '' ?
                    <div className={'flex flex-end'}>
                        <h4>{currentAvgValue}</h4>
                        <p className={'b1_r pl-2px mb-4px'}>{focusUnit}</p>
                        <div className={`weight-amount-avg ${avgStatus ==='적정'||avgStatus ==='GOOD'? 'good' : 'etc'}`}>{avgStatus}</div>
                    </div>
                    :
                    <div className={'flex flex-end'}>
                        <h4>-</h4>
                    </div>
                }

            </div>
            <div className={'report mt-40px'} style={{height: '250px', width: '100%'}}>
                {
                    data
                    ?
                    <WeightAmountChart {...data}/>
                    :
                    <Skeleton height={'300'} animation={'wave'}/>
                }
                { lastYn === 'Y' && pastUnitMsg?
                    <div className={'flex align-center mt-2px'} style={{ justifyContent: 'flex-end', paddingBottom:'3px'}}>
                        <div style={{width: '13px', height: '0px', border: '1px solid #CDCDCD'}}></div>
                        <span className={'c1_r pl-5px cl-gray04'}>{pastUnitMsg}</span>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
})

export default WeightAmount