import DAILY_STEP_JSON from "@/assets/json/daily_step.json"
import DAILY_DISTANCE_JSON from "@/assets/json/daily_distance.json"
import DAILY_SPEED_JSON from "@/assets/json/daily_speed.json"
import DAILY_CALOLIE_JSON from "@/assets/json/daily_calolie.json"
import HISTORY_STEP_JSON from "@/assets/json/history_step.json"
import HISTORY_DISTANCE_JSON from "@/assets/json/histroy_distance.json"
import HISTORY_SPEED_JSON from "@/assets/json/history_speed.json"
import HISTORY_CALOLIE_JSON from "@/assets/json/history_calolie.json"
import HISTORY_SLEEP from "@/assets/json/history_sleep.json"
import OXYGENSATURATION from "@/assets/json/oxygenSaturation.json"
import INFIT_INIT_MGR from "@/assets/json/infit_init_mgr.json"
import INFIT_UPDATE_USER_INFO from "@/assets/json/infit_update_user_info.json"

import * as RNIMessaging from "@/service/rothynative/RNIMessaging"
import * as RNIHealth from "@/service/rothynative/RNIHealth"
import {RHSets} from "@/service/rothynative/RNIHealth"
import * as RNIGoogleSignIn from "@/service/rothynative/RNIGoogleSignIn"
import * as RNIKakaoSignIn from "@/service/rothynative/RNIKakaoSignIn"
import * as RNINaverSignIn from "@/service/rothynative/RNINaverSignIn"
import * as RNIAppleSignIn from "@/service/rothynative/RNIAppleSignIn"
import * as RNISmartScale from "@/service/rothynative/RNISmartScale"
import * as SelfAuth from "@/service/rothynative/SelfAuth"
import * as RabbitMqService from "@/service/rothynative/RabbitMqService"
import InterfaceUtil from "@/common/Interface";

enum Events {
    HARDWARE_BACK_PRESS = "hardwareBackPress",
    APP_STATE_CHANGE = "appStateChange",
    ON_REMOTE_MESSAGE = "onRemoteMessage",
    ON_REMOTE_BACKGROUND_MESSAGE = "onRemoteBackgroundMessage",
    ON_DAILY_UPDATE = 'onDailyUpdate',
    ON_HISTORY_UPDATE = 'onHistoryUpdate',
    ON_IC_SCAN_RESULT = 'onScanResult',
    ON_IC_RECEIVE_WEIGHT_DATA = 'onReceiveWeightData',
}
export const RNIEvents = {
    ...Events
}

enum Actions {
    SHOW_RN_CONSOLE = "SHOW_RN_CONSOLE",
    GET_DEVICE_INFO = "GET_DEVICE_INFO",
    GET_APP_INFO = "GET_APP_INFO",
    GET_PLATFORM = "GET_PLATFORM",
    APP_EXIT = "APP_EXIT",
    OPEN_URL = "OPEN_URL",
    OPEN_APP = "OPEN_APP",
    SET_CLIPBOARD = 'SET_CLIPBOARD',
    GET_CLIPBOARD = 'GET_CLIPBOARD',
}

export const RNIActions = {
    ...Actions,
    ...RNIMessaging.Actions,
    ...RNIHealth.Actions,
    ...RNIGoogleSignIn.Actions,
    ...RNIKakaoSignIn.Actions,
    ...RNINaverSignIn.Actions,
    ...RNIAppleSignIn.Actions,
    ...RNISmartScale.Actions,
    ...SelfAuth.Actions,
    ...RabbitMqService.Actions,
};
export type RNIActions = typeof RNIActions

declare global {
    interface ReactNativeWebView {
        postMessage(message: string): void
    }
    interface Window {
        ReactNativeWebView: ReactNativeWebView;
    }
    interface Event {
        data: string | undefined;
    }
}

export type AppStateStatus = 'active' | 'background' | 'inactive' | 'unknown' | 'extension';

export class RNIError extends Error {
    code?: string

    constructor(message: string, code?: string) {
        super(message)

        this.code = code

        Object.setPrototypeOf(this, RNIError.prototype);
    }
}

export const RNIPlatform = {
    os: {
        android: "android",
        ios: "ios",
    }
}

export declare interface RNIDeviceInfo {
    model: string | null
    os: string | null
}

export declare interface RNIAppInfo {
    packageName: string | null
    shortVersion: string | null
    buildVersion: string | null
}

export declare interface RNIResponse<R>  {
    code?: string
    message?: string
    data?: R
}

export declare interface RNIResponseEvent {
    id: string
    action: string
    event: string | undefined
    eventData: string | undefined
    error: string | undefined
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data: any
}

export class RNIMessageData<T> {
    id?: string
    action?: string
    data?: T
}

export class RNIMessageConfig<T, R> {
    action?: string
    data?: T
    response?: R
}

export class RNMessageMapData<T, R> {
    id?: string
    data?: T
    resolve?: (response: R) => void
    reject?: (error: Error) => void
    config?: RNIMessageConfig<T, R>
}

export type EventCallback = {
    (data?: any):void;
};

export class RNIHeader {
    id = "";
    action = "";
    error?: string;

    constructor(id: string, action: string) {
        this.id = id
        this.action = action
    }

    public header(): RNIHeader {
        return new RNIHeader(this.id, this.action)
    }

    public toJson(): string {
        if (this.error) {
            return "{\"id\": \"" + this.id + "\", \"action\": \"" + this.action + "\", \"error\": " + JSON.stringify(this.error) + "}"
        } else {
            return "{\"id\": \"" + this.id + "\", \"action\": \"" + this.action + "\"}"
        }
    }

    public static parse(jsonStr: string): RNIHeader {
        if (jsonStr) {
            const map = JSON.parse(jsonStr) as RNIHeader

            return new RNIHeader(map.id, map.action)
        }

        return new RNIHeader("", "")
    }
}
export class RNIData<T> extends RNIHeader {
    data?: T;

    constructor(id: string, action: string, data?: T) {
        super(id, action)

        this.data = data
    }

    public static parse<T>(jsonStr: string): RNIData<T> {
        if (jsonStr) {
            const map = JSON.parse(jsonStr) as RNIData<T>

            return new RNIData<T>(map.id, map.action, map.data)
        }

        return new RNIData<T>("", "")
    }

    public static stringify(header: RNIHeader, str: string): string {
        return "{\"id\": \"" + header.id + "\", \"action\": \"" + header.action + "\", \"data\": " + str + "}"
    }
}
export class RothyWebView implements ReactNativeWebView {
    postMessage(message: string): void {
        const event = new Event('message');

        const header = RNIHeader.parse(message)

        const eventData = {id: header.id, action: header.action}

        if (header.action === RNIActions.IS_HEALTH_DATA_AVAILABLE) {
            const response = Object.assign(eventData, {data: {code : "0000", message: "success", data: true}})
            event.data = JSON.stringify(response)
        } else if (header.action === RNIActions.REQUEST_HEALTH_AUTHORIZATION) {
            const response = Object.assign(eventData, {data: {code : "0000", message: "success", data: true}})
            event.data = JSON.stringify(response)
        } else if (header.action === RNIActions.GET_HEALTH_DATA_DAILY) {
            const param = RNIData.parse<RNIHealth.RHParamDaily>(message)

            const quantityType = param.data?.quantityType
            switch (quantityType) {
                case RHSets.stepCount.daily.quantityType: {
                    const response = Object.assign(eventData, DAILY_STEP_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.distanceWalkingRunning.daily.quantityType: {
                    const response = Object.assign(eventData, DAILY_DISTANCE_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.walkingSpeed.daily.quantityType: {
                    const response = Object.assign(eventData, DAILY_SPEED_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.activeEnergyBurned.daily.quantityType: {
                    const response = Object.assign(eventData, DAILY_CALOLIE_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
            }
        } else if (header.action === RNIActions.GET_HEALTH_DATA_HISTORY) {
            const param = RNIData.parse<RNIHealth.RHParamDaily>(message)

            const quantityType = param.data?.quantityType
            switch (quantityType) {
                case RHSets.stepCount.history.quantityType: {
                    const response = Object.assign(eventData, HISTORY_STEP_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.distanceWalkingRunning.history.quantityType: {
                    const response = Object.assign(eventData, HISTORY_DISTANCE_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.walkingSpeed.history.quantityType: {
                    const response = Object.assign(eventData, HISTORY_SPEED_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.activeEnergyBurned.history.quantityType: {
                    const response = Object.assign(eventData, HISTORY_CALOLIE_JSON)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.sleepAnalysis.history.quantityType: {
                    const response = Object.assign(eventData, HISTORY_SLEEP)
                    event.data = JSON.stringify(response)
                    break
                }
                case RHSets.oxygenSaturation.history.quantityType: {
                    const response = Object.assign(eventData, OXYGENSATURATION)
                    event.data = JSON.stringify(response)
                    break
                }
            }
        } else if (header.action === RNIActions.IC_UPDATE_USER_INFO) {
            const response = Object.assign(eventData, INFIT_INIT_MGR)
            event.data = JSON.stringify(response)
        } else if (header.action === RNIActions.IC_INIT_MGR) {
            const response = Object.assign(eventData, INFIT_UPDATE_USER_INFO)
            event.data = JSON.stringify(response)
        }

        window.dispatchEvent(event)
    }
}

export default class RothyNativeService {
    private static instance: RothyNativeService;
    public static getInstance() {
        if (!RothyNativeService.instance) {
            RothyNativeService.instance = new RothyNativeService();

            // android
            document.addEventListener('message', function (event) { RothyNativeService.getInstance().nativeEventAndroid(event) })
            // iOS
            window.addEventListener('message', function (event) { RothyNativeService.getInstance().nativeEventIos(event) })
        }

        return RothyNativeService.instance;
    }

    nativeInterface?: ReactNativeWebView
    postMessageMap = new Map<string, RNMessageMapData<any, any>>()

    private constructor() {
        if (window.navigator.userAgent.indexOf("Rothy/") > -1) {
            this.nativeInterface = window.ReactNativeWebView
        } else {
            this.nativeInterface = new RothyWebView()
        }
    }

    private nativeEventAndroid(event: Event) {
        this.nativeEvent(event.data)
    }
    private nativeEventIos(event: MessageEvent) {
        this.nativeEvent(event.data)
    }
    private nativeEvent(ed: any) {
        if (!ed || typeof ed !== "string") return

        const responseEvent = JSON.parse(ed) as RNIResponseEvent
        const eventName = responseEvent.event ? responseEvent.event : ""
        const eventData = responseEvent.eventData
        if (this.validEvent(eventName)) {
            this.callEvent(eventName, eventData)
            return
        }

        const id = responseEvent.id
        const error = responseEvent.error ? JSON.parse(responseEvent.error) as RNIError : undefined
        const data = responseEvent.data as RNIResponse<any>

        const postMessageData = this.postMessageMap.get(id)
        if (postMessageData) {
            const resole = postMessageData.resolve
            const reject = postMessageData.reject

            postMessageData.resolve = undefined
            postMessageData.reject = undefined
            this.postMessageMap.delete(id)

            if (!error && resole) {
                resole(data?.data)
            } else if (reject) {
                if (error) {
                    reject(new RNIError(error.message, error.code))
                } else {
                    reject(new RNIError("", ""))
                }
            }
        } else {
            console.log("can't find native action id")
        }
    }

    public postMessage<T, R>(config: RNIMessageConfig<T, R>): Promise<R> {
        return new Promise((resolve, reject) => {
            const saveMessage = new RNMessageMapData<T, R>()
            saveMessage.id = `SEQ_${Date.now() + this.random()}`
            saveMessage.resolve = resolve
            saveMessage.reject = reject
            saveMessage.config = config

            this.postMessageMap.set(saveMessage.id, saveMessage)

            const message = new RNIMessageData<T>()
            message.id = saveMessage.id
            message.action = saveMessage.config.action
            if (config.data !== null && config.data !== undefined) {
                message.data = config.data
            }

            if (this.nativeInterface) {
                this.nativeInterface.postMessage(JSON.stringify(message))
            }
        })
    }

    eventMap = new Map<string, Array<EventCallback>>()
    public addEventListener(action: string, callback: EventCallback) {
        if (InterfaceUtil.isAppRunning()) {
            if (!this.eventMap.get(action)) {
                this.eventMap.set(action, [])
            }

            this.eventMap.get(action)?.push(callback)
        }
    }
    public removeEventListener(action: string) {
        if (InterfaceUtil.isAppRunning()) {
            this.eventMap.delete(action)
        }
    }

    private validEvent(responseEvent: string) {
        switch(responseEvent) {
            case RNIEvents.HARDWARE_BACK_PRESS:
            case RNIEvents.APP_STATE_CHANGE:
            case RNIEvents.ON_REMOTE_MESSAGE:
            case RNIEvents.ON_REMOTE_BACKGROUND_MESSAGE:
            case RNIEvents.ON_DAILY_UPDATE:
            case RNIEvents.ON_HISTORY_UPDATE:
            case RNIEvents.ON_IC_SCAN_RESULT:
            case RNIEvents.ON_IC_RECEIVE_WEIGHT_DATA:
                return true;
            default:
                return false;
        }
    }
    private callEvent(responseEvent: string, responseEventData?: any) {
        const callbacks = this.eventMap.get(responseEvent)
        if (callbacks) {
            for (let i = 0; i < callbacks.length; i++) {
                if (responseEventData) {
                    callbacks[i](responseEventData)
                } else {
                    callbacks[i]()
                }
            }
        }
    }

    private random(min = 1000, max = 9000): number {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }
}
