import React, {useCallback, useEffect} from 'react'
import {Dialog, Slide, SlideProps} from "@mui/material";
import Icon from '../common/component/Icon'
import NotificationListView from "@/view/notification/NotificationListView";
import {history} from "@/view/common/component/History"

type DialogHeaderParam = {
    title ?: string,
    leftIcon ?: string,
    rightIcon ?: string,
    rightLink ?: string,
    rightOnClick ?: () => void,
    onClose?: () => void
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DialogHeader = (props:DialogHeaderParam) => {
    const handleOverlayClick = useCallback(() => props.onClose && props.onClose(), [props.onClose])
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === 'POP') {
                handleOverlayClick()
            }
        });

        return unlistenHistoryEvent;

    }, [history]);

    return (
        <>
            <div className='header'>
                <div className='left svg' onClick={handleOverlayClick}>
                    <Icon iconClass={props.leftIcon?props.leftIcon:'prev w-8 w-17'}/>
                </div>
                <span className='b1_r'>{props.title}</span>
                { props.rightIcon &&
                    <div className='right svg' onClick={handleClickOpen}>
                        <Icon iconClass={props.rightIcon}/>
                    </div>}
                { props.rightIcon && props.rightOnClick
                    ?
                    <div className='right svg' onClick={props.rightOnClick && props.rightOnClick}>
                        <Icon iconClass={props.rightIcon}/>
                    </div>
                    : null
                }
            </div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                {props.rightLink === 'notificationList' && <NotificationListView onClose={handleClose}/>}
            </Dialog>
        </>
    )
}

export default DialogHeader