import React, { useState } from 'react';

import RothyNativeService, {
    RNIActions, RNIError, RNIMessageConfig,
} from "@/service/rothynative/RothyNativeService";
import * as RNIKakaoSignIn from "@/service/rothynative/RNIKakaoSignIn";

const KakaoSignInSample: React.FC = (): JSX.Element => {
    const [accessToken, setAccessToken] = useState<string | undefined>()
    const [profile, setProfile] = useState<RNIKakaoSignIn.RNKakaoProfile | undefined>(undefined)

    const onClickKakaoSignIn = () => {
        const config = new RNIMessageConfig<void, RNIKakaoSignIn.RNKakaoToken>()
        config.action = RNIActions.KAKAO_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIKakaoSignIn.RNKakaoToken) => {
                setAccessToken(response?.accessToken)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }
    const onClickKakaoSignOut = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.KAKAO_SIGN_OUT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setAccessToken(undefined)
                setProfile(undefined)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }
    const onClickKakaoProfile = () => {
        const config = new RNIMessageConfig<void, RNIKakaoSignIn.RNKakaoProfile>()
        config.action = RNIActions.KAKAO_PROFILE

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIKakaoSignIn.RNKakaoProfile) => {
                setProfile(response)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    return <>
        <button onClick={onClickKakaoSignIn}>카카오 로그인</button>
        <br />
        <button onClick={onClickKakaoSignOut}>카카오 로그아웃</button>
        <br />
        <button onClick={onClickKakaoProfile}>카카오 계정정보 조회</button>
        <ul>
            <li><b>Access Token</b></li>
            <li>{accessToken ? accessToken : ''}</li>
            <li><b>계정 정보</b></li>
            {profile && (
                <>
                    <li>프로필 이미지: {`${profile?.profileImageUrl}`}</li>
                    <li>email: {profile?.email}</li>
                    <li>닉네임: {`${profile?.nickname}`}</li>
                </>
            )}
        </ul>
    </>
}

export default KakaoSignInSample;