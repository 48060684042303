export enum Actions {
    NAVER_SIGN_IN = "NAVER_SIGN_IN",
    NAVER_SIGN_OUT = "NAVER_SIGN_OUT",
    NAVER_PROFILE = "NAVER_PROFILE",
}

export declare interface RNNaverLoginResponse {
    isSuccess: boolean;
    /** isSuccess가 true일 때 존재합니다. */
    successResponse?: {
        accessToken: string;
        refreshToken: string;
        expiresAtUnixSecondString: string;
        tokenType: string;
    };
    /** isSuccess가 false일 때 존재합니다. */
    failureResponse?: {
        message: string;
        isCancel: boolean;
        /** Android Only */
        lastErrorCodeFromNaverSDK?: string;
        /** Android Only */
        lastErrorDescriptionFromNaverSDK?: string;
    };
}
export declare interface RNNaverProfileResponse {
    resultcode: string;
    message: string;
    response: RNNaverProfile;
}
export declare interface RNNaverProfile {
    id: string;
    profile_image: string | null;
    email: string;
    name: string;
    birthday: string | null;
    age: string | null;
    birthyear: number | null;
    gender: string | null;
    mobile: string | null;
    mobile_e164: string | null;
    nickname: string | null;
}
