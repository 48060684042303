import React, {CSSProperties, useState} from 'react';
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import {SelfAuthItem} from "@/service/rothynative/SelfAuth";

const RothyHealthSample: React.FC = (): JSX.Element => {
    const [selfAuthItem, setSelfAuthItem] = useState<SelfAuthItem>()

    const onClickSelfAuth = () => {
        const config = new RNIMessageConfig<string, SelfAuthItem>()
        config.action = RNIActions.REQUEST_SELF_AUTH
        config.data = "https://test-fb.gi-vita.io/selfcert/checkplus_main.jsp"

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: SelfAuthItem) => {
                setSelfAuthItem(response)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    return <>
        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>본인인증</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickSelfAuth}>조회</div>
            </div>
            <div style={styles.content}>
                {selfAuthItem && (
                    <>
                        <div><span>복호화한시간</span><span>{selfAuthItem.sCipherTime}</span></div>
                        <div><span>요청번호</span><span>{selfAuthItem.sRequestNumber}</span></div>
                        <div><span>NICE응답번호</span><span>{selfAuthItem.sResponseNumber}</span></div>
                        <div><span>인증수단</span><span>{selfAuthItem.sAuthType}</span></div>
                        <div><span>성명</span><span>{selfAuthItem.sName}</span></div>
                        <div><span>중복가입확인값(DI)</span><span>{selfAuthItem.sDupInfo}</span></div>
                        <div><span>연계정보확인값(CI)</span><span>{selfAuthItem.sConnInfo}</span></div>
                        <div><span>생년월일(YYYYMMDD)</span><span>{selfAuthItem.sBirthDate}</span></div>
                        <div><span>성별</span><span>{selfAuthItem.sGender}</span></div>
                        <div><span>내/외국인정보</span><span>{selfAuthItem.sNationalInfo}</span></div>
                        <div><span>휴대폰번호</span><span>{selfAuthItem.sMobileNo}</span></div>
                        <div><span>통신사</span><span>{selfAuthItem.sMobileCo}</span></div>
                    </>
                )}
            </div>
        </div>
    </>
}

const styles = {
    wrap: {
        marginTop: "10px",
    } as CSSProperties,
    header: {
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    title: {
        alignSelf: "center"
    } as CSSProperties,
    between: {
        flex: 1,
    } as CSSProperties,
    button: {
        background: "#FFFF00",
        alignSelf: "center",
        padding: "10px 10px",
    } as CSSProperties,
    content: {
        padding: "0px 10px"
    } as CSSProperties,
    contentList: {
        padding: "0px 10px",
        maxHeight: "200px",
    } as CSSProperties,
}

export default RothyHealthSample;
