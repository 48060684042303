import React, {useCallback, useEffect, useRef, useState} from 'react'
import {animated, useSpring} from '@react-spring/web'
import Modal from "@mui/material/Modal"
import Icon from '@/view/common/component/Icon'

type popupParam = {
    isOpen:boolean,
    title?: string,
    content: JSX.Element,
    onClose?: () => void,
    className ?: string,
    iconClass ?: string
};


const Popup = (props:popupParam) => {
    const [isInDOM, setIsInDOM] = useState(false)
    const bodyOverflowStyleRef = useRef<string>(document.body.style.overflow)
    const topRef = useRef<string>(document.body.style.top)
    const contentRef = useRef<HTMLDivElement>(null)
    const titleRef = useRef<HTMLDivElement>(null)
    const heightPixel = (contentRef.current?.offsetHeight || window.innerHeight / 2) + (titleRef.current?.offsetHeight || 0)
    const [popupStyle, popupStyleApi] = useSpring(() => ({
        opacity: 0,
        zIndex: 100
    }))

    const [popupBodyStyle, popupBodyStyleApi] = useSpring(() => ({
      height: '0px',
      onRest: {
        height: (height) => {
          if (height.value === `${heightPixel}px`) {
            return
          }
          if (height.value === '0px') {
            setIsInDOM(false)
          }
        },
      }
    }))

    const handleOverlayClick = useCallback(() => props.onClose && props.onClose(), [props.onClose])
    const handleContentClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), [])

    useEffect(() => {
        if (props.isOpen) {
            const currY = window.pageYOffset || document.documentElement.scrollTop + 56 + 48 || document.body.scrollTop
            bodyOverflowStyleRef.current = document.body.style.overflow
            topRef.current = document.body.style.top
            document.body.style.overflow = 'hidden'
            document.body.style.position = 'relative'
            // document.body.style.top = `-${currY}px`
            setIsInDOM(true)
        } else {
            popupBodyStyleApi.start({ height: '0px'})
            popupStyleApi.start({opacity: 0, delay: 100})
            setIsInDOM(false)
        }

    }, [props.isOpen, popupBodyStyleApi, popupStyleApi])

    useEffect(() => {
        if (isInDOM) {
            popupStyleApi.start({ opacity:1 })
            popupBodyStyleApi.start({ height: `${heightPixel}px`, delay: 100 })
        } else if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = bodyOverflowStyleRef.current
            // document.body.style.top = topRef.current
        }
    }, [isInDOM, popupBodyStyleApi, popupStyleApi])

    useEffect(() => () => {
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = bodyOverflowStyleRef.current
            // document.body.style.top = topRef.current
        }
    }, [])

    return(
        <>
            {
                props.isOpen ?
                <Modal open={props.isOpen}>
                    <animated.div className={'rothy-popup'} style={popupStyle} onClick={handleOverlayClick}>
                        <animated.div className={`popup-body${props.className ? ' ' + props.className : ''}`} style={popupBodyStyle} onClick={handleContentClick}>
                            <div ref={titleRef} className={'b1_m ml-24px flex'}>
                                {props.title}
                                {props.onClose
                                    ?
                                    <div onClick={props.onClose}>
                                        <Icon iconClass={`${props.iconClass ? props.iconClass : 'close'} w-17px h-17px` }/>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div ref={contentRef}>
                                {props.content}
                            </div>
                        </animated.div>
                    </animated.div>
                </Modal>
                : null
            }
        </>
    )
}

export default Popup;
