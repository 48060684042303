import {DefaultResult, rothyClient} from "@/service/RothyClient";

export interface UserDvicData {
    userDvicNo:number,
    userId?:number,
    dvicType?:string,
    dvicNickNm?:string,
    macAddr?:string,
    status?:string,
    frstRegDtm?:string
}

export class UserDvicUpateReq{
    userDvicNo?: number;
    dvicNickNm?: string;
    dvicType?: string;
    macAddr?: string;
    status?: string;
    constructor(
        userDvicNo: number,
        dvicNickNm: string,
        dvicType: string,
        macAddr: string,
        status: string,
    ){
        this.userDvicNo = userDvicNo;
        this.dvicNickNm = dvicNickNm;
        this.dvicType = dvicType;
        this.macAddr = macAddr;
        this.status = status;
    }
}

export interface UserDvicReq{
    dvicNickNm?:string,
    dvicType?:string,
    macAddr?:string,
    status?:string
}

const selectUserDvic = async (props: UserDvicReq) =>
   rothyClient.get<DefaultResult>('/user-dvic',{params:props}).then(res => res.data)

const checkRegisterPossible = async (props : UserDvicReq) =>
    rothyClient.get<DefaultResult>('/user-dvic/able',{params:props}).then(res=>res.data)

const insertUserDvic = async (prpos:UserDvicReq) =>
    await rothyClient.post<DefaultResult>('/user-dvic',{...prpos}).then(res=>res.data)

const deleteUserDvic = async (userDvicNo: number) =>
    rothyClient.delete<DefaultResult>('/user-dvic/'+String(userDvicNo)).then(res => res.data)

const updateUserDvic = async (_params: UserDvicUpateReq | undefined) =>
    rothyClient.patch<DefaultResult>('/user-dvic/'+String(_params?.userDvicNo), _params).then(res => res.data)

const UserDvicService = {
    selectUserDvic,
    checkRegisterPossible,
    deleteUserDvic,
    updateUserDvic,
    insertUserDvic,
    UserDvicUpateReq
}


export default UserDvicService