import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import BoardService, {TermsList, TermsListData} from "@/service/BoardService";
import Loading from "@/view/common/component/Loading";
import BoardListItem from "@/view/common/component/BoardListItem";
import Icon from "@/view/common/component/Icon";

const Terms = () => {
    const [termsList, setTermsList] = useState<TermsListData[]>([])

    const { status, data:typeResult} = useQuery<TermsList, AxiosError>(
        ['termsList'],
        ()=> BoardService.selectTerms(),
        {
            enabled: true,
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 60,
            onSuccess: (v: TermsList) => updateTermsList(v.data.objectList),
        }
    )

    const updateTermsList = (data: TermsListData[]) => {
        const dataList : TermsListData[] =[]
        data.map( d => dataList.push({ termsId: d.termsId, termsNm: d.termsNm, termsContents: d.termsContents }))
        setTermsList(dataList)
    }

    useEffect(() => {
        if(typeResult){
            updateTermsList(typeResult.data.objectList)
        }
    }, [])

    if(status === 'loading'){
        return (
            <>
                <Loading/>
            </>
        )
    }else if(termsList.length !== 0) {
        return (
            <>
                {termsList.map((n,index) =>{
                    return <div key={n.termsId}>
                                <BoardListItem type='terms'
                                               index={index}
                                               title={n.termsNm}
                                               contents={n.termsContents}
                                               heightClass={'h-61px'}
                                               rightContent={<div className={'flex-row-reverse'}><Icon iconClass='next w-6px h-12px'/></div>} />
                            </div>
                })}
                <BoardListItem type='termsAgree'
                               index={termsList.length}
                               title='선택정보 수집 및 이용동의'
                               contents={termsList[2].termsContents}
                               heightClass={'h-61px'}
                               rightContent={<div className={'flex-row-reverse'}><Icon iconClass='next w-6px h-12px'/></div>} />
            </>
        )
    }else{
        return (
            <>
                <Loading/>
            </>
        )
    }
}

export default Terms;