import React, {useCallback, useEffect} from "react";
import Alert from "@mui/material/Alert";

type popupParam = {
    isOpen:boolean,
    content: JSX.Element,
    content2?: string,
    onClose: () => void,
}

const Toast = (props:popupParam) => {

    const handleCloseClick = useCallback(() => props.onClose(), [props.onClose])

    useEffect(() => {
        if(props.isOpen){
            setTimeout(function() { // Code here
                handleCloseClick()
            }, 4000);
        }
    },[props.isOpen])

    return(
        <>
            {
                props.isOpen ?
                    <Alert icon={false}>
                        <div className={'toast-alert-detail b2_r'}>
                            {props.content}
                        </div>
                    </Alert>
                    : null
            }
        </>
    )
}

export default Toast;