type AppVersionParam = {
    title: string,
    version: string,
    className?: string
}

const AppVersion = (props:AppVersionParam) => {
    return (
        <div className={`mrml-24px ptpb-22px flex justify-between ${props.className ? props.className : ''}`}>
            <span>{props.title}</span>
                <span>{props.version}</span>
        </div>
    )
}

export default AppVersion