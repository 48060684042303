import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import DateUtil, {MonthData, WeekData, YearData} from "@/common/DateUtil";
import {SvcUserData} from "@/service/UserService";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import {USER_INFO} from "@/common/Constant";
import Icon from "@/view/common/component/Icon";
import HistoryMonth from "@/view/vitaindex/component/HistoryMonth";
import HistoryDaily from "@/view/vitaindex/component/HistoryDaily";

const VitaIndexHistory= forwardRef((props, ref) => {
    const [monthList, setMonthList] = useState<MonthData[] | undefined>(undefined)
    const [month, setMonth] = useState<string | undefined>('')
    const [selectedIListIndex, setSelectedIListIndex] = useState<number | undefined>(undefined)
    const [selectedTitle, setSelectedTitle] = useState<string | undefined>('')

    const [leftIconDisable, setLeftIconDisable] = useState(false)
    const [rightIconDisable, setRightIconDisable] = useState(false)

    const sleepStageRef = useRef<{stageFocusOut: () => void}>(null)

    const userInfo: SvcUserData  = StringUtil.jsonString2ObjectWithMap<SvcUserData>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
    const userSignupDt = userInfo?.signupDt
    const userId = userInfo?.userId


    useImperativeHandle(ref, () => ({
        viewFocusOut: () =>{
            focusOut()
        }
    }))

    const focusOut = () => {
        if(sleepStageRef.current !== null){
            sleepStageRef.current.stageFocusOut()
        }
    }

    const handlePrev = () => {
        const prev = Number(selectedIListIndex)+1
        setSelectedIListIndex(prev)

    }

    const handleNext = () => {
        const next = Number(selectedIListIndex)-1
        setSelectedIListIndex(next)
    }

    const initData = () => {
        setLeftIconDisable(false)
        setRightIconDisable(false)

        setMonth('')
    }

    useEffect(()=>{
        if(!monthList){
            const _monthList:MonthData[] = DateUtil.getMonthList(userSignupDt)
            setMonthList(_monthList)
            setSelectedIListIndex(0)
        }

    },[])


    useEffect(()=>{
        initData()
        const index = selectedIListIndex?selectedIListIndex:0
        if(monthList){
            setMonth(monthList[index].month)
            setSelectedTitle(monthList[index].text)
            if(monthList.length === (index+1)){
                setLeftIconDisable(true)
            }
        }

        if(selectedIListIndex === 0){
            setRightIconDisable(true)
        }


    },[selectedIListIndex])

    return (
        <div className='mrml-24px mt-74px mb-100px'>
            <div className={'record-search-wrapper'}>
                {
                    leftIconDisable?
                        <div className='left svg w-20px h-20px' >
                            <Icon iconClass={'prev-small-disable w-5px h-8px'}/>
                        </div>
                        :
                        <div className='left svg w-20px h-20px' onClick={handlePrev}>
                            <Icon iconClass={'prev-small w-5px h-8px'}/>
                        </div>
                }
                <span className='b2_r'>{selectedTitle}</span>
                {
                    rightIconDisable?
                        <div className='right svg w-20px h-20px'>
                            <Icon iconClass={'next-small-disable w-5px h-8px'}/>
                        </div>
                        :
                        <div className='right svg w-20px h-20px' onClick={handleNext}>
                            <Icon iconClass={'next-small w-5px h-8px'}/>
                        </div>
                }
            </div>
            <HistoryMonth month={month} title={selectedTitle}/>

        </div>
    )
})

export default VitaIndexHistory