import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import Header from "@/view/layout/Header";
import Main from "@/view/main/component/Main";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import RothyNativeService, {
    RNIActions,
    RNIError,
    RNIEvents,
    RNIMessageConfig
} from "@/service/rothynative/RothyNativeService";
import {RHParamAuthorization, RHQuantityTypes} from "@/service/rothynative/RNIHealth";
import RothyFireBase from "@/service/FireBaseService";
import PopUpService, {MissionPopup, MissionPopupRes, PointPopup, UserPopup, UserPopupRes} from "@/service/PopUpService";
import WebPopup from "@/view/common/popup/WebPopup";
import * as RothyPopup from "@/service/rothynative/RothyPopup";
import {ACCESS_TOKEN, OS_TYPE, POINT_POPUP, USER_ID, SMART_STORE_URI, HEALTH_AGREE} from "@/common/Constant";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import InterfaceUtil from "@/common/Interface";


type WebPopupData = {
    url:string,
    type:string,
    userMissionDtlNo:number,
}

type ClosingData = {
    index: number
}

const apiUrl:string = process.env.API_URI === undefined? '' : process.env.API_URI
const token:string = StringUtil.convertNullToEmpty(localStorage.getItem(ACCESS_TOKEN))
const targtOrigin:string = process.env.SELF_URI === undefined? '' : process.env.SELF_URI
const sourceOrigin:string = process.env.FEEDBACK_URI === undefined? '' : process.env.FEEDBACK_URI
const devFeedbackUrl = 'https://test-fb.gi-vita.io'

const MainView: React.FC = (): JSX.Element => {
    const userId = Number(StorageUtil.getLocalStorage(USER_ID)) as unknown as number
    const navigate = useNavigate()
    const [response, setResponse] = useState(false)
    const [missionPopupList, setMissionPopupList] = useState<MissionPopup[]>([])
    const [userPopupList, setUserPopupList] = useState<UserPopup[]>([])
    const [isOpen, setIsOpen] = useState(true)
    const [tempIndex, setTempIndex] = useState<number>(0)
    const [closingIndex, setClosingIndex] = useState<ClosingData>({index: 0})
    const [isRead, setIsRead] = useState<boolean>()
    const [webPopupList, setWebPopupList] = useState<WebPopupData[]>([])

    useEffect(()=>{
        document.body.style.overflow = 'auto' //로그인 후, body에 overflow hidden 되어 스크롤 비활성화되어, 스크롤 활성화 적용

        const param = new RHParamAuthorization()
        param.shareDataTypes = []
        param.readDataTypes = [
            // 걸음수
            RHQuantityTypes.stepCount,
            // 걸은 거리
            RHQuantityTypes.distanceWalkingRunning,
            // 걸음 속도
            RHQuantityTypes.walkingSpeed,
            // 소모 칼로리
            RHQuantityTypes.activeEnergyBurned,
            // 수면
            RHQuantityTypes.sleepAnalysis,
            // 산소 포화도
            RHQuantityTypes.oxygenSaturation,
            // 심박수
            RHQuantityTypes.heartRate,
            // 계단오름
            RHQuantityTypes.floorsClimbed,
            // 운동량
            RHQuantityTypes.exercise,
        ]

        if (InterfaceUtil.getOs() === 'android') {
            // 체질량
            param.readDataTypes.push(RHQuantityTypes.weight)
        }

        const config = new RNIMessageConfig<RHParamAuthorization, boolean>()
        config.action = RNIActions.REQUEST_HEALTH_AUTHORIZATION
        config.data = param
        callHealthAuthorization(config)

        RothyFireBase.getInstance()
    }, [])

    const callHealthAuthorization = (config: RNIMessageConfig<unknown, boolean>) => {
        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((_response: boolean) => {
                if(_response){
                    StorageUtil.setLocalStorage(HEALTH_AGREE,'Y')
                    setResponse(_response)
                } else {
                    callHealthAuthorization(config)
                }
            })
            .catch((error: RNIError) => {
                if (error.code === '-9005') {
                    // 삼성헬스앱/애플헬스앱 미설치
                    openStoreApp()
                } else if (error.code === '-9006') {
                    // 삼성헬스앱 미활성화
                    openSamsungHealthApp()
                } else {
                    console.log(`${error.code || ''}::${error.message || ''}`)
                }
            })
    }

    const openStoreApp = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_URL
        if (InterfaceUtil.getOs() == OS_TYPE.ANDROID) {
            config.data = "https://play.google.com/store/apps/details?id=com.sec.android.app.shealth"
        }

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open url success")
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const openSamsungHealthApp = () => {
        const config = new RNIMessageConfig<string, void>()
        if (InterfaceUtil.getOs() == OS_TYPE.ANDROID) {
            config.action = RNIActions.OPEN_APP
            config.data = "com.sec.android.app.shealth"
        }

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open app success")
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const {refetch:getMissionPopup} = useQuery<MissionPopupRes, AxiosError>(
        ['getMissionPopup'],
        ()=> PopUpService.getMissionPopUp(),
        {
            onSuccess: (v:MissionPopupRes) => setMissionPopup(v.data)
        }
    )

    const {refetch:getUserPopup} = useQuery<UserPopupRes, AxiosError>(
        ['getUserPopup'],
        ()=> PopUpService.getUserPopUp(userId),
        {
            onSuccess: (v:UserPopupRes) => setUserPopup(v.data)
        }
    )

    const setMissionPopup = (list: MissionPopup[]) => {
        const tempList:MissionPopup[] = []
        list.forEach(d=> tempList.push(d))
        setMissionPopupList(tempList)
    }

    const setUserPopup = (list: UserPopup[]) => {
        const tempList:UserPopup[] = []
        list.forEach(d=> tempList.push(d))
        setUserPopupList(tempList)
    }

    useEffect(() =>{
        const tempList: WebPopupData[] = []
        if(missionPopupList.length > 0){
            missionPopupList.forEach((d) =>{
                let url = d.popUpUrl
                if(process.env.PROFILE === "local") {
                    url = url.replaceAll(devFeedbackUrl, sourceOrigin)
                }
                const popup = {userMissionDtlNo: d.userMissionDtlNo}
                tempList.push({
                    url: url + `?apiUrl=${apiUrl}&token=${token}&targtOrigin=${targtOrigin}&popup=${encodeURI(JSON.stringify(popup))}`,
                    type: d.popUpTpCd,
                    userMissionDtlNo:d.userMissionDtlNo,
                })
            })
        }
        if(userPopupList.length > 0){
            userPopupList.forEach((d) =>{
                let url = d.popupUrl
                if(process.env.PROFILE === "local") {
                    url = url.replaceAll(devFeedbackUrl, sourceOrigin)
                }
                const popup = {
                    popupId: d.popupId,
                    userId: d.userId
                }
                tempList.push({
                    url: url + `?apiUrl=${apiUrl}&token=${token}&targtOrigin=${targtOrigin}&popup=${encodeURI(JSON.stringify(popup))}`,
                    type: d.popupType,
                    userMissionDtlNo: 0,
                })
            })
        }
        const pointPopupData = StorageUtil.getLocalStorage(POINT_POPUP)
        if(pointPopupData){
            const data:PointPopup = StringUtil.jsonString2ObjectWithMap(pointPopupData)
            const popup = {
                bottomText: data.bottomText,
                lottie: data.lottie,
                pointContent: data.pointContent,
                pointTitle: data.pointTitle,
            }
            let url = data.popupUrl
            if(process.env.PROFILE === "local") {
                url = url.replaceAll(devFeedbackUrl, sourceOrigin)
            }
            tempList.push({
                url: url + `?apiUrl=${apiUrl}&token=${token}&targtOrigin=${targtOrigin}&popup=${encodeURI(JSON.stringify(popup))}`,
                type: 'BOTTOM',
                userMissionDtlNo: 0
            })
        }
        setWebPopupList(tempList)
    }, [missionPopupList, userPopupList, localStorage.pointPopup])

    useEffect(()=>{
        if(webPopupList.length > 0){
            setIsOpen(true)
        }
    }, [webPopupList])


    useEffect(() => {
        RothyNativeService.getInstance().addEventListener(RNIEvents.HARDWARE_BACK_PRESS, () => {
            onClickAppExit()
        })

        getMissionPopup()
            .then(() => {void getUserPopup().then(r => r)})
            .catch(reason=>console.log(reason))
        // Android Only, 백키 터치시
        window.addEventListener('message', (messageEvent) => {
            if (messageEvent.origin === sourceOrigin) {
                const response = messageEvent.data as RothyPopup.WebRothyInterface
                switch (response.action) {
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_MISSION: {
                        // 미션 화면으로 이동
                        navigate('/main')
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.SEND_TO_DONATION_CLAUSE: {
                        // 기부 정보 입력 완료
                        // 해당 액션 수신후 '/api/donation/user' API 통신 필요
                        const sendToDonationClause = response.data as RothyPopup.SendToDonationClause
                        console.log(sendToDonationClause)
                        setIsOpen(false)
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_MAIN_ROVING:
                        // 어슬렁 메인 화면으로 이동
                        setIsOpen(false)
                        break
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_POINTLIST:
                        // 포인트 적립/사용 내역 화면으로 이동
                        setIsOpen(false)
                        break
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_POINT:
                        // 포인트 사용 화면으로 이동
                        setIsOpen(false)
                        break
                    case RothyPopup.WebRothyInterfaceAction.FINISH_VIEW: {
                        // 팝업 닫기
                        // isRead 가 false인 경우 팝업 읽음 여부 처리를 해야한다.(/api/userMission/popup/read/{userMissionDtlNo})
                        const finishView = response.data as RothyPopup.FinishView
                        setIsRead(finishView.isRead)
                        setTempIndex(prevState => prevState +1)
                        localStorage.setItem(POINT_POPUP, '')
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.LINK: {
                        // 외부 브라우저 호출
                        const link = response.data as RothyPopup.Link
                        console.log(`${link.type} :: ${link.url}`)
                        const config = new RNIMessageConfig<string, void>()
                        config.action = RNIActions.OPEN_URL
                        config.data = link.url

                        RothyNativeService
                            .getInstance()
                            .postMessage(config)
                            .then(() => {
                                console.log("open url success")
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.GET_SHARE: {
                        // SNS 공유
                        const share = response.data as RothyPopup.Share
                        console.log(`${share.type} :: ${share.text}`)
                        setIsOpen(false)
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.CLIPBOARD_COPY: {
                        // 클립도트에 텍스트 복사
                        const clipboardCopy = response.data as RothyPopup.ClipboardCopy
                        const config = new RNIMessageConfig<string, void>()
                        config.action = RNIActions.SET_CLIPBOARD
                        config.data = clipboardCopy.text

                        RothyNativeService
                            .getInstance()
                            .postMessage(config)
                            .then(() => {
                                alert('클립보드에 복사하였습니다')
                            })
                            .catch((error: RNIError) => {
                                console.log(`${error.code || ''}::${error.message || ''}`)
                            })
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_DNA_RESULT:
                        // DNA 결과 화면으로 이동
                        setIsOpen(false)
                        break
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_TERMS: {
                        // '/api/termsdtl' API 호출하여 기부 약관 조회
                        const goToTerms = response.data as RothyPopup.GoToTerms
                        console.log(goToTerms.termsId)
                        setIsOpen(false)
                        break
                    }
                }
            }
        })
    }, [])

    const onClickAppExit = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.APP_EXIT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("app_exit")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(()=>{
        if(isRead !== undefined && !isRead && webPopupList[closingIndex.index].userMissionDtlNo > 0){
            void PopUpService.readMissionPopup(webPopupList[closingIndex.index].userMissionDtlNo).then(r => r)
        }
        if(tempIndex > webPopupList.length -1){
            setClosingIndex({index: 0})
            setIsOpen(false)
        }else{
            if(tempIndex !== 0) {
                setClosingIndex(prevState => ({index: prevState.index + 1}))
                setIsOpen(true)
            }
        }
    }, [tempIndex])

    const openSmartStore = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_URL
        config.data = SMART_STORE_URI

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open url success")
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    return (<div className={'bg-cl-gray-7'}>
                <Header leftIcon={'menu-b'} rightIcon={'smart-store-link w-18px h-20px'} rightLink={openSmartStore}/>
                <HeaderSpacer className='bg-cl-gray-7 header-tab-spacer'/>
        {
            response ?
                <Main/>
                :
                <div></div>
        }
        {webPopupList.length > 0 ?
            <WebPopup isOpen={isOpen} type={webPopupList[closingIndex.index].type} url={webPopupList[closingIndex.index].url} className={'bg-cl-white'}/>
            :
            null
        }
    </div>)
}

export default MainView;
