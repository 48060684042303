import {rothyClient} from "@/service/RothyClient";

export interface SleepCardReq{
    fromDate: string,
    toDate: string,
    userId: number
}

export interface SleepCardRes{
    data: SleepCardData[]
}


export interface SleepCardData{
    msreBeginDtm: string,
    msreDt: string,
    msreEndDtm: string,
    scesYn: string,
    sleepMaxTimeByAge: number,
    sleepMinTimeByAge: number,
    sleepOxygen: string,
    sleepOxygenLowDuration: number,
    sleepPatternDiff: string,
    sleepPatternScoreOrMsg: string,
    sleepTime: number,
    sleepTimeMsg: string,
}

export class SleepReportReq{
    fromDate: string;
    month: string;
    periodType: string;
    selectType: string;
    toDate: string;
    userId: number;
    year: string;

    constructor(
        fromDate: string,
        month: string,
        periodType: string,
        selectType: string,
        toDate: string,
        userId: number,
        year: string,
    ) {
        this.fromDate = fromDate;
        this.month = month;
        this.periodType = periodType;
        this.selectType = selectType;
        this.toDate = toDate;
        this.userId = userId;
        this.year = year;
    }

}

export interface SleepTimeRes {
    data : {
        avgSleepTime: number,
        pastSleepList: SleepTimeData[],
        pastSleepTime: number
        recommSleepTimeMsg: string
        sameGroupAvgSleepTime: number
        sleepMaxTimeByAge: number
        sleepMinTimeByAge: number
        sleepTimeMsg: string
        todaySleepList: SleepTimeData[]
        todaySleepTime: number
    }
}

export interface SleepStageRes {
    data : {
        sleepStageMsg: string,
        todayMentalRecoverTime: number,
        todayBodyRecoverTime: number,
        pastMentalRecoverTime: number,
        pastBodyRecoverTime: number,
        sleepStageStandRangeList: SleepStandRangeData[],
        todaySleepStageList: SleepStageData[]
        pastSleepStageList: SleepStageData[]
    }
}

export interface SleepTimeData{
    YOIL: string,
    SLEEP_TIME: number,
    ROW_NUM: number,
}

export interface SleepStageData{
    YOIL: string,
    SLEEP_TIME: number,
    ROW_NUM: number,
    AWAKE_STAGE_RATIO: number,
    DEEP_STAGE_RATIO: number,
    REM_STAGE_RATIO: number,
    LIGHT_STAGE_RATIO: number,
}

export interface SleepStandRangeData{
    sleepStageCd: string,
    sleepStageNm: string,
    sleepStageRatio: number,
    sleepStageTime: number,
    standFromRange: number,
    standToRange: number,
}

export interface SleepPatternRes{
    data : {
        sleepPatternMsg: string,
        todaySleepPatternScore: string,
        todayMsreDt: string,
        todayMsreBeginDtm: string,
        todayMsreEndDtm: string,
        pastSleepPatternScore: string,
        pastMsreDt: string,
        pastMsreBeginDtm: string,
        pastMsreEndDtm: string,
        sameGroupAvgScore: string,
        sameGroupTitle: string,
        sameGroupAvgMsreBeginDtm: string,
        sameGroupAvgMsreEndDtm: string,
        avgSleepPatternScore: number,
        avgMsreBeginDtm: string,
        avgMsreEndDtm: string,
        msreBeginErrorTime: string,
        msreEndErrorTime: string,
        sleepTypeMsg: string,
        minBeginDtm: string,
        maxEndDtm: string,
        sleepMsreDtmList: SleepMsreDtm[]
    }
}

export interface SleepMsreDtm{
    YOIL: string,
    SLEEP_TIME: number,
    MSRE_DTM: string,
    ROW_NUM: number,
    MSRE_BEGIN_DTM: string,
    SLEEP_PATTERN_SCORE: number,
    MSRE_END_DTM: string
}

export interface SleepBodyEnergyRes{
    data : {
        todayBodyEnergy: number,
        pastBodyEnergy: number,
        bodyEnergyMsg: string,
        sameGroupAvgBodyEnergy: number,
        avgBodyEnergy: number,
        bodyEnergyList: BodyEnergyData[]
    }
}

export interface BodyEnergyData{
    YOIL: string,
    SLEEP_TIME: number,
    WAKEUP_BODY_ENERGY: number,
    ENERGY_NONE_TIME: string,
    ROW_NUM: number,
    WAKEUP_DTM: string,
    SLEEP_DTM: string,
    MSRE_DTM: string,
    MSRE_BEGIN_DTM: string,
    SLEEP_BODY_ENERGY: number,
    MSRE_END_DTM: string,
    TOOLTIP_MSG: string,
}

export interface SleepOxygenRes{
    data : {
    sleepOxygenMsg: string,
    msreDt: string,
    sleepOxygenLowDuration: number,
    sleepOxygenLowCnt: number,
    sleepOxygenMin: number,
    sleepOxygenMax: number,
    sleepOxygenList: OxygenData[]

    }
}

export interface OxygenData{
    YOIL: string,
    SLEEP_TIME: number,
    ROW_NUM: number,
    SLEEP_OXYGEN_MIN: number,
    SLEEP_OXYGEN_LOW_DURATION: number,
    OXYGEN_LOW_COUNT: number,
    SLEEP_OXYGEN_MAX: number
}

const getUserSleepData = async (props: SleepCardReq) =>
    rothyClient.get<SleepCardRes>("/sleep/daily/info",{params:props}).then(res => res.data)

const getSleepRecordTime = async (props: SleepReportReq) =>
    rothyClient.get<SleepTimeRes>("/sleep/record/time",{params:props}).then(res => res.data)

const getSleepRecordStage = async (props: SleepReportReq) =>
    rothyClient.get<SleepStageRes>("/sleep/record/stage",{params:props}).then(res => res.data)

const getSleepRecordPattern = async (props: SleepReportReq) =>
    rothyClient.get<SleepPatternRes>("/sleep/record/pattern",{params:props}).then(res => res.data)

const getSleepRecordBodyEnergy = async (props: SleepReportReq) =>
    rothyClient.get<SleepBodyEnergyRes>("/sleep/record/bodyenergy",{params:props}).then(res => res.data)

const getSleepRecordOxygen = async (props: SleepReportReq) =>
    rothyClient.get<SleepOxygenRes>("/sleep/record/oxygen",{params:props}).then(res => res.data)



const SleepService = {
    getUserSleepData,
    getSleepRecordTime,
    getSleepRecordStage,
    getSleepRecordPattern,
    getSleepRecordBodyEnergy,
    getSleepRecordOxygen,
    SleepReportReq,
 }

export default SleepService;