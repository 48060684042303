import {rothyClient} from "@/service/RothyClient";
import {FocusItem} from "@/service/UserService";
import {ResponseApi, SingleDataResponse} from "@/service/BoardService";

export interface WeightCardReq{
    userId: number,
    selectType: string
}

export interface WeightInfoRes{
    data: WeightInfoData[]
}

export interface WeightInfoData{
    bdfatQty: number,
    bdfatRate: number,
    bdwtQty: number,
    bmi: number,
    dvicTp: string,
    focusItem: string,
    msreCnt: number,
    msreDt: string,
    skltnWt: number,
    tall: number,
    wt: number,
    countList?: countData[]
    weightStandard?: SectionData
}

export interface countData{
    msreTm: string
}

export interface SectionData{
    section1: number
    section2: number
    section3: number
    section4: number
}

export interface UpdateFocusItemReq{
    userId: number,
    selectType: string
}

export class WeightRecordReq{
    userId: number
    periodType: string
    fromDate: string
    toDate: string
    month: string
    year: string
    selectType: string

    constructor(
        userId: number,
        periodType: string,
        fromDate: string,
        toDate: string,
        month: string,
        year: string,
        selectType: string,
    ) {
        this.userId = userId
        this.periodType = periodType
        this.fromDate = fromDate
        this.toDate = toDate
        this.month = month
        this.year = year
        this.selectType = selectType
    }
}

export interface WeightAmountRes {
    data : {
        comment: string
        currentAvgValue: number
        avgStatus: string
        currentList: AmountData[]
        currentYn: string
        lastAvgValue: number
        lastList: AmountData[]
        lastYn: string
    }
}

export interface AmountData {
    x: string
    y: number | undefined
    dvicTp: string
}

export interface WeightHealthyTypeRes {
    data : FocusItem[]
}

export interface WeightHealthyRes {
    data : {
        comment: string
        dailyList: HealthyData[]
        currentList: HealthyYearData[]
        lastList: HealthyYearData[]
        lastYn: string
    }
}

export interface HealthyData {
    x: string
    y: number
    diffValue: number
    dvicTp: string
    highlightYn: string
}

export interface HealthyYearData {
    x: string
    y: number | undefined
}

export interface WeightCountRes {
    data : {
        comment: string
        type: string
        count: number
        weightCountDto: WeightCountData[]
    }
}

export interface WeightCountData {
    x: string
    count: number
    msreDtmList: string[]
    msreDtmListOfYear: CountYearData[]
}

export interface CountYearData {
    msreDt: string
    count: number
}

const getWeightInfo = async (props: WeightCardReq) =>
    rothyClient.get<WeightInfoRes>("/weight/info",{params:props}).then(res => res.data)

const getFocusItem = async (userId:number) =>
    rothyClient.get<SingleDataResponse<FocusItem[]>>("/weight/focus",{params:{userId: userId.toString()}}).then(res => res.data)

const updateFocusItem = async (data:UpdateFocusItemReq) =>
    await rothyClient.patch<ResponseApi>('/weight/focus',{...data}).then(res => res.data)

const getWeightAmount = async (data:WeightRecordReq) =>
    rothyClient.get<WeightAmountRes>("/weight/record/amount",{params:data}).then(res => res.data)

const getWeightHealthyType = async (linkDvic:string) =>
    rothyClient.get<WeightHealthyTypeRes>("/weight/record/item/"+linkDvic).then(res => res.data)

const getWeightHealthy = async (data:WeightRecordReq) =>
    rothyClient.get<WeightHealthyRes>("/weight/record/healthy",{params:data}).then(res => res.data)

const getWeightCount = async (data:WeightRecordReq) =>
    rothyClient.get<WeightCountRes>("/weight/record/count",{params:data}).then(res => res.data)

const WeightService = {
    getWeightInfo,
    getFocusItem,
    updateFocusItem,
    getWeightAmount,
    getWeightHealthyType,
    getWeightHealthy,
    getWeightCount
}

export default WeightService;