import React, {useCallback, useEffect, useState} from 'react'
import {AxiosError} from "axios";
import {Dialog, Slide, SlideProps} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import DialogHeader from "@/view/layout/DialogHeader";
import HealthReport from "@/view/health/component/HealthReport";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import HealthService, {
    HealthReportData,
    HealthReportPossibleRes,
    TilkoHealthReportRes
} from "@/service/HealthService";
import HealthCertification from "@/view/health/component/HealthCertification";
import StringUtil from "@/common/StringUtil";
import Toast from "@/view/common/popup/Toast";
import Icon from "@/view/common/component/Icon";
import StorageUtil from "@/common/StorageUtil";
import {HEALTH_REPORT_PLUS_INFO} from "@/common/Constant";
import BottomButton from "@/view/common/component/button/BottomButton";

export interface HealthReportViewParam{
    onClose: () => void,
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const HealthReportView = (props: HealthReportViewParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [reportList, setReportList] = useState<HealthReportData[]>([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [healthReportPossible, setHealthReportPossible] = useState(false)
    const [toastOpen, setToastOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [plusInfo, setPlusInfo] = useState(localStorage.getItem(HEALTH_REPORT_PLUS_INFO))

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleReturn = () => {
        setDialogOpen(false)
        setToastMessage('검진 기록을 불러왔어요\n지금바로 확인해 보세요')
        setToastOpen(true)
        initData()
    }

    const {refetch:getHealthReportResult} = useQuery<TilkoHealthReportRes, AxiosError>(
        ['getHealthReportResult'],
        ()=> HealthService.getHealthReportResult(),
        {
            onSuccess: (v: TilkoHealthReportRes) => setReportList(v.data),
        }
    )

    const {refetch:getHealthReportPossible} = useQuery<HealthReportPossibleRes, AxiosError>(
        ['getHealthReportPossible'],
        () => HealthService.getHealthReportPossible(),
        {
            onSuccess: (v: HealthReportPossibleRes) => setResult(v)
        }
    )

    const setResult = (v: HealthReportPossibleRes) => {
        setHealthReportPossible(v.data.isPossible)
        setToastMessage(v.data.message)
    }

    useEffect(()=>{
        initData()
    }, [])

    const initData = () => {
        getHealthReportPossible().then(r=>r).catch(reason => console.log(reason))
        getHealthReportResult().then(r=>r).catch(reason => console.log(reason))
    }

    const getHealthRecord = (readYn: boolean) => {
        if(readYn) readPlusInfo()
        if(healthReportPossible){
            setDialogOpen(true)
        }else{
            if(StringUtil.isNotEmpty(toastMessage)){
                setToastOpen(true)
            }
        }
    }

    const readPlusInfo = () => {
        StorageUtil.setLocalStorage(HEALTH_REPORT_PLUS_INFO, 'Y')
        setPlusInfo(localStorage.getItem(HEALTH_REPORT_PLUS_INFO))
    }

    return (
        <div id={'healthReportView'}>
            <div className={'health-report-header'}>
                {reportList.length == 0
                    ?
                    <>
                        <DialogHeader title='건강검진 기록' onClose={handleOverlayClick}/>
                    </>
                    :
                    <>
                        <DialogHeader title='건강검진 기록' onClose={handleOverlayClick} rightIcon={'add h-18px w-18px'} rightOnClick={() => getHealthRecord(true)}/>
                        <div className={`report-add ${StringUtil.isEmpty(plusInfo)? '':'ds-none'}`} onClick={readPlusInfo}>
                        <Icon iconClass={'report-plus-info w-130px h-56px'}/>
                        </div>
                    </>
                }
            </div>
            <div className={'board'}>
                <HeaderSpacer/>
                <HealthReport reportList={reportList}/>
            </div>
            {reportList.length == 0
                ?
                <BottomButton title={'검진기록 불러오기'} disabled={false} onClick={() => getHealthRecord(false)}/>
                : null
            }

            <Dialog fullScreen open={dialogOpen} onClose={handleClose} TransitionComponent={Transition}>
                {<HealthCertification onClose={handleClose} onReturn={handleReturn}/>}
            </Dialog>
            <Toast content={<div className={'white-space-wrap'}>{toastMessage}</div>} isOpen={toastOpen} onClose={ () => setToastOpen(false) }></Toast>
        </div>
    )
}

export default HealthReportView