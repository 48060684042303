import React, {useEffect, useState} from 'react'
import { Bar } from 'react-chartjs-2'
import {ScoreFeatureDetail} from "@/service/VitaIndexService";

export type ChartDataSet = {
    type: any,
    barThickness: number,
    borderRadius: number,
    backgroundColor: string[] | CanvasGradient[],
    data: number[] | object[] | undefined[] | undefined,
}

interface PropsConfig {
    scoreFeatureDetail?:ScoreFeatureDetail
}

const CharacterAvgChart = (props:PropsConfig) => {
    const [data,setData] = useState<{labels:string[], datasets:ChartDataSet[]}>({labels:[],datasets:[]})
    const [options,setOptions] = useState({})

    useEffect(()=>{
        if(props.scoreFeatureDetail){
            createChart(props.scoreFeatureDetail)
        }
    }, [props.scoreFeatureDetail])

    const createChart = (scoreFeatureDetail:ScoreFeatureDetail) => {
        updateData(String(scoreFeatureDetail.thirdCard.ageGroup) +'대 평균', scoreFeatureDetail.thirdCard.ageMeanScore, scoreFeatureDetail.secondCard.lastWeekScoreList[6],)
        updateOptions()
    }

    const updateData = (ageGroup: string, ageMeanScore: number, todayScore: number) => {
        setData({
            labels : [ageGroup, '내 점수'],
            datasets: [
                {
                    type: 'bar' as const,
                    barThickness: 42,
                    borderRadius: 2,
                    backgroundColor: setBackgroundColor(),
                    data: [ageMeanScore, todayScore],
                },
            ],
        })
    }

    const updateOptions = () => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                tooltip: false,
                legend: false,
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    color: '#1A1A1A',
                    font: {
                        size: '12',
                        family: 'SpoqaHanSansNeo',
                        lineHeight: '18px',
                        weight: '400'
                    },
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: '12',
                            style: 'normal',
                            family: 'SpoqaHanSansNeo',
                            lineHeight: '18px',
                            weight: '500'
                        },
                    },
                },
                y: {
                    max: 1000,
                    min: 0,
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            layout: {
                padding: {
                    top: 15
                }
            },
        })
    }

    const setBackgroundColor = () => {
        const canvas = document.getElementById('character-avg-chart') as HTMLCanvasElement
        const avg_ctx = canvas.getContext('2d')

        if (avg_ctx) {
            const black_gradient = avg_ctx.createLinearGradient(0, 0, 0, 100)
            black_gradient.addColorStop(0, '#585866')
            black_gradient.addColorStop(1, '#8F8F9F')

            const mint_gradient = avg_ctx.createLinearGradient(0, 0, 0, 100)
            mint_gradient.addColorStop(0,'#24CBAA')
            mint_gradient.addColorStop(1, '#A1E0D4')

            return [black_gradient, mint_gradient]
        } else {
            return ['#585866','#A1E0D4','#585866','#A1E0D4']
        }
    }

    return <>
                <div style={{height: '230px', margin: '0px 31px', paddingTop: '38px'}}>
                    <Bar id="character-avg-chart" data={data} options={options} height= '220px'/>
                </div>
                <div className={'characteristics-card-content'}>
                    <span className={'h6_m cl-gray01'}>{props.scoreFeatureDetail?.thirdCard.title}</span>
                    <span className={'b2_r cl-gray03 mt-11px'}>{props.scoreFeatureDetail?.thirdCard.comment}</span>
                </div>
            </>
}

export default CharacterAvgChart
