import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import BoardService, {Response} from "@/service/BoardService";
import Icon from "@/view/common/component/Icon";

export interface itemParam{
    type:string,
    pushTrxId:number,
    index:number,
    leftIcon:string,
    title:string,
    sendDt:string,
    recvYn:string,
}

const NotificationListItem = (props: itemParam) => {
    const[pushId,setPushId] = useState<number>()
    const[isRead,setIsRead] = useState({id: props.pushTrxId, recvYn: props.recvYn})

    const {refetch: updateNotification} = useQuery<Response, AxiosError>(
        ['updateNotification'],
        ()=> BoardService.updateNotification(props.pushTrxId),
        {
            onSuccess: () => {
                if(pushId === isRead.id) setIsRead(prevState=> ({id: prevState.id, recvYn: 'Y'}))
            }
        }
    )

    const handleClick = (pushTrxId: number) => {
        setPushId(pushTrxId)
    }

    useEffect(() => {
        if(pushId !== undefined){
            updateNotification()
                .then(r => r.data)
                .catch(reason => {
                    console.log(reason)
                })
        }
    }, [pushId])

    return  <div key={props.index} className={`flex h-fit-content ${props.index == 0 ? '' : 'border-top-gray'}`}>
                <div><Icon iconClass={`${isRead.recvYn === 'Y'? props.leftIcon : 'new-'+props.leftIcon}`}/></div>
                <div className={`notification-item`} onClick={() => handleClick(props.pushTrxId)}>
                    <div className={`notification-item-title`}>
                        <div>
                            <span className={`b2_r ${isRead.recvYn === 'Y'? 'cl-gray04' : ''}`}>{props.title}</span>
                            <p className={'c1_r cl-gray04'}>{props.sendDt}</p>
                        </div>
                    </div>
                </div>
            </div>
}

export default NotificationListItem;