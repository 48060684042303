import React, {useEffect, useState} from 'react';

import * as RothyPopup from "@/service/rothynative/RothyPopup";

import Popup from "@/view/common/popup/Popup";

const apiUrl = 'https://test-api.gi-vita.io'
const token = 'eyJ0eXBlIjoiSldUIiwicmVnRGF0ZSI6MTY3MjAxODE3NjM3MywiYWxnIjoiSFMyNTYifQ.eyJleHAiOjE2NzI2MjI5NzYsInBqdE5vIjoxLCJ1c2VySWQiOjg4NjgsInVzZXJObSI6IuyViOuvvOq4sCIsImF1dGhvcml0eU5tIjoiVVNFUiIsImNvbXBhbnlDb2RlIjoiR0lWSVRBIn0.dV08wZr70rdo2ToGocQT07if80wPHKsMdRpKU5FnTyI'
const targtOrigin = 'http://localhost:9080'
const sourceOrigin = 'http://localhost:8088'

const RothyNativeiSample: React.FC = (): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState('')

    useEffect(() => {
        // Android Only, 백키 터치시
        window.addEventListener('message', (messageEvent) => {
            if (messageEvent.origin === sourceOrigin) {
                const response = messageEvent.data as RothyPopup.WebRothyInterface
                switch (response.action) {
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_MISSION: {
                        // 미션 화면으로 이동
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.SEND_TO_DONATION_CLAUSE: {
                        // 기부 정보 입력 완료
                        // 해당 액션 수신후 '/api/donation/user' API 통신 필요
                        const sendToDonationClause = response.data as RothyPopup.SendToDonationClause
                        console.log(sendToDonationClause)

                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_MAIN_ROVING:
                        // 어슬렁 메인 화면으로 이동
                        break
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_POINTLIST:
                        // 포인트 적립/사용 내역 화면으로 이동
                        break
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_POINT:
                        // 포인트 사용 화면으로 이동
                        break
                    case RothyPopup.WebRothyInterfaceAction.FINISH_VIEW: {
                        // 팝업 닫기
                        // isRead 가 false인 경우 팝업 읽음 여부 처리를 해야한다.(/api/userMission/popup/read/{userMissionDtlNo})
                        const finishView = response.data as RothyPopup.FinishView
                        console.log(finishView.isRead)
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.LINK: {
                        // 외부 브라우저 호출
                        const link = response.data as RothyPopup.Link
                        console.log(`${link.type} :: ${link.url}`)
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.GET_SHARE: {
                        // SNS 공유
                        const share = response.data as RothyPopup.Share
                        console.log(`${share.type} :: ${share.text}`)
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.CLIPBOARD_COPY: {
                        // 클립도트에 텍스트 복사
                        const clipboardCopy = response.data as RothyPopup.ClipboardCopy
                        console.log(clipboardCopy.text)
                        break
                    }
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_DNA_RESULT:
                        // DNA 결과 화면으로 이동
                        break
                    case RothyPopup.WebRothyInterfaceAction.GO_TO_TERMS: {
                        // '/api/termsdtl' API 호출하여 기부 약관 조회
                        const goToTerms = response.data as RothyPopup.GoToTerms
                        console.log(goToTerms.termsId)
                        break
                    }
                }
            }
        })

        /**
         * getPopup() 사용하는 팝업인 경우 해당 데이터 추가
         * 1. 미션 팝업, 이베트 팝업
         * File: mi_220501_after_mission_14.html, mi_220501_after_mission_30.html, mi_220501_after_mission_60.html, mi_rothy_mall_220901_suc_acc_day_20.html, mi_sdf_220829_suc_acc_day_20.html
         * API: /api/popup/user
         * Example: {userMissionDtlNo: }
         *
         * 2. 포입트 획득 팝업
         * File: po_220926_get_point_pop_up.html
         * API: /api/userMission/v2/check/{userId}
         * Example: {pointTitle:, pointContent: , bottomText:}
         */
        const popup = {userMissionDtlNo: 10}
        // const popup = {pointTitle: '제목', pointContent: '내용', bottomText: '하단 텍스트'}

        setUrl(`http://localhost:8088/resources/rothy/html/mi_end_suc_0_220501_after.html?apiUrl=${apiUrl}&token=${token}&targtOrigin=${targtOrigin}&popup=${encodeURI(JSON.stringify(popup))}`)
        setIsOpen(true)
    }, [])

    return <>
        <Popup className='bg-cl-white' title='' content={<iframe sandbox={'allow-same-origin allow-scripts'} width={'100%'} height={'600px'} src={url}></iframe>} isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}/>
    </>
}

export default RothyNativeiSample;
