import React from 'react'
import {AnswerData, QuestionData} from "@/service/BoardService";
import AnswerContent from "@/view/question/component/AnswerContent";
import QuestionContent from "@/view/question/component/QuestionContent";

export interface QuestionDetailParam{
    question: QuestionData | undefined,
    answer: AnswerData | undefined
}

const QuestionDetail = (props:QuestionDetailParam) => {
    const questionData = props.question
    const answerData = props.answer

    return <>
        <div className={'mt-24px'}>
            <QuestionContent questionTitle={questionData?.questionTitle} questionCn={questionData?.questionCn} questionDate={questionData?.questionDate} />
            <div className={'border-top-gray mt-36px'}/>
            <AnswerContent answerStatus={answerData?.answerStatus} answerCn={answerData?.answerCn} answerDate={answerData?.frstRegDtm}/>
        </div>
    </>
}

export default QuestionDetail