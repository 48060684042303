import React, {useEffect, useState} from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js'
import {Chart} from 'react-chartjs-2'
import {Skeleton} from "@mui/material"
import {HealthyYearData} from '@/service/WeightService'
import Icon from "@/view/common/component/Icon"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
)

export interface WeightHealthyYearData{
    currentList: HealthyYearData[]
    lastList: HealthyYearData[]
    lastYn: string
    periodType: string
}

type HealthyYearChartDataSet = {
    type: any,
    backgroundColor: string,
    borderColor: string,
    data: number[] | object[] | undefined[] | undefined,
}

const WeightHealthyYearChart = (props:WeightHealthyYearData) => {
    const [data,setData] = useState<{labels: string[], datasets: HealthyYearChartDataSet[]}>()
    const [pastUnitMsg, setPastUnitMsg] = useState<string | undefined>(undefined)
    const [maxYValue, setMaxYValue] = useState(10)

    useEffect(() => {
        initChart()
        updatePastUnitMsg()
    },[props])

    const initChart = () => {
        const array :HealthyYearChartDataSet[] = []

        let maxValue: number | undefined = 10
        props.currentList.map((data)=>{
            if(maxValue && data.y && maxValue < data.y) maxValue = data.y
            if(data.y === 0 ) data.y = undefined
        })

        const currentData = { type: 'bar' as const, data: props.currentList, borderColor: '#24CBAA', backgroundColor: '#24CBAA'}
        array.push(currentData)

        if(props.lastYn==='Y'){
            props.lastList.map((data)=>{
                if(maxValue && data.y && maxValue < data.y) maxValue = data.y
                if(data.y === 0 ) data.y = undefined
            })
            const lastData = { type: 'line' as const, data: props.lastList, backgroundColor: '#FFFFFF'
                                , borderColor: '#CDCDCD', borderWidth: 2, fill:false}
            array.push(lastData)
        }
        setMaxYValue(Math.ceil(maxValue / 10) * 10)

        setData({
            labels : ['1','2','3','4','5','6','7','8','9','10','11','12'],
            datasets: array,
        })
    }

    const updatePastUnitMsg = () => {
        if(props.periodType === 'WEEK'){
            setPastUnitMsg('지난주')
        }else if(props.periodType === 'MONTH'){
            setPastUnitMsg('지난달')
        }else if(props.periodType === 'YEAR'){
            setPastUnitMsg('지난해')
        }else{
            setPastUnitMsg(undefined)
        }
    }

    const options = {
        responsive: true,
        animation: false,
        plugins: {
            datalabels: false,
            legend: false,
            title: false
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    maxRotation: 0,
                    font: {
                        size: 12
                    }
                },
            },
            y: {
                beginAtZero: true,
                max: maxYValue,
                ticks: {
                    stepSize: maxYValue == 10? 5 : 10,
                    callback: function (value: number) {
                        return (Math.floor(value * 10) / 10).toString() + '일'
                    }
                },
            }
        },
    }

    return <>{ data ?
                <>
                    <Chart type='bar' height={'200px'} data={data} options={options}/>
                        {props.lastYn === 'Y' && pastUnitMsg ?
                            <div className={'flex align-center mt-2px'} style={{justifyContent: 'flex-end', paddingBottom: '3px'}}>
                                <Icon iconClass={'report-legend-pointer-gray w-14px h-8px'} />
                                <span className={'c1_r pl-5px cl-gray04'}>{pastUnitMsg}</span>
                            </div>
                            :
                            <></>}
                </>
                :
                <Skeleton height={'300'} animation={'wave'}/>
            }

            </>
}

export default WeightHealthyYearChart