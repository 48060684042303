import {useEffect, useState} from "react";
import Icon from "@/view/common/component/Icon";
import LottieAnimation from "@/view/common/component/LottieAnimation";
import ScaleAnalysis from "@/assets/lottie/lottie_scale_analysis_spiral loader-dark.json";
import {ICWeightData} from "@/service/rothynative/RNISmartScale";
import StringUtil from "@/common/StringUtil";


type MeasureProps ={
    measureInfo:ICWeightData|undefined,
    isOpen:boolean
}

const MeasureWeightView = (props : MeasureProps) => {

    const [checkId,setCheckId] = useState<NodeJS.Timeout|undefined|number>()


    const checkAnalysis = () => {
        props.isOpen = false
    }


    const setTimer = () => {
        if(checkId == undefined) {
            console.debug("타이머 설정")
            setCheckId(setTimeout(checkAnalysis, 15000, 1))
        }
    }

    useEffect(()=> {
        setTimer()
        console.debug(StringUtil.stringify(props.measureInfo))
    },[])

    return(
        <>
            {props.isOpen &&
                (<>
                    <div className={' h-fit-content  justify-center align-center w-100p h-60vh'} >
                        <div className={'align-center'}>
                            <div className={'justify-center align-center w-100p mt-32px'}>
                                <div className={'h6_b  flex-column align-center cl-basic-white'}>체중 측정 중이에요!</div>
                            </div>
                            <div className={'justify-center align-center w-100p mt-16px'}>
                                <div className={'b2_r  flex-column align-center cl-basic-white'}>정확한 측정을 위해 팝업이 사라질 때까지</div>
                                <div className={'b2_r  flex-column align-center cl-basic-white'}>체중계 위에서 기다려주세요</div>
                            </div>
                            <div className={'justify-center align-center flex mt-43px'} style={{width:'96.82',height:'146.27'}} >
                                <LottieAnimation animationData={ScaleAnalysis}
                                                 lottieStyle={{width: '232px', height: '104px'}}/>
                            </div>
                            <div className={'justify-center align-center w-100p mt-16px'}>
                                {props.measureInfo !== undefined &&
                                    (<div className={'b2_r  flex-column align-center cl-basic-white'}>{(Math.floor(props.measureInfo.weight_kg*10)/10)}kg</div>)
                                }
                            </div>

                        </div>
                    </div>
                </>)
            }
            {!props.isOpen &&
                (<>
                    <div className={' h-fit-content  justify-center align-center w-100p h-60vh'} >
                        <div className={'align-center'}>
                            <div className={'justify-center align-center w-100p mt-32px'}>
                                <div className={'h6_b  flex-column align-center cl-basic-white'}>체질량 분석이 안됐어요.</div>
                                <div className={'h6_b  flex-column align-center cl-basic-white mt-12px'}>다시 측정해주세요!</div>
                            </div>
                            <div className={'justify-center align-center w-100p mt-16px'}>
                                <div className={'b2_r flex-column align-center cl-basic-white'}>맨발이 아니거나 발이 너무 건조하거나 축축하면</div>
                                <div className={'b2_r flex-column align-center cl-basic-white'}>체질량 측정이 되지 않을 수 있어요</div>
                            </div>
                            <div className={'justify-center align-center flex mt-43px'} style={{width:'96.82',height:'146.27'}} >
                                <Icon iconClass={'scale-not-analysis w-150px h-150px'} />
                            </div>
                        </div>
                    </div>
                </>)
            }
        </>
    )
}

export default MeasureWeightView