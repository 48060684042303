import React, { useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Skeleton} from "@mui/material";
import LinkHeader from "@/view/layout/LinkHeader";
import VitaIndexScore from "@/view/vitaindex/component/VitaIndexScore";
import VitaIndexHistory from "@/view/vitaindex/component/VitaIndexHistory";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import VitaIndexService, {
    ScoreFeatureData, ScoreTipListData,
    VitaIndexContents,
    VitaIndexDescContentsData, VitaIndexDetailData,
    VitaIndexReq, WarningHabitData
} from "@/service/VitaIndexService";
import StringUtil from "@/common/StringUtil";
import ScoreCharacter from "@/view/vitaindex/component/ScoreCharacter";
import Caution from "@/view/vitaindex/component/Caution";
import RaiseScore from "@/view/vitaindex/component/RaiseScore";
import CollectNotYet from "@/view/vitaindex/component/CollectNotYet";
import StorageUtil from "@/common/StorageUtil";
import {USER_ID} from "@/common/Constant";

interface PropsCofig {
    ref?: string,
}

const VitaIndexDetail = (props: PropsCofig) => {
    const [vitaIndexReq, setVitaIndexReq] = useState<VitaIndexReq | undefined>( undefined)

    const [vitaIndexDetail, setVitaIndexDetail] = useState< VitaIndexDetailData | undefined>(undefined)
    const [scoreFeature, setScoreFeature] = useState< ScoreFeatureData | undefined>(undefined)
    const [warningHabit, setWarningHabit] = useState< WarningHabitData | undefined>(undefined)
    const [scoreTipList, setScoreTipList] = useState< ScoreTipListData[] | undefined>(undefined)
    const [collectError, setCollectError] = useState<boolean | undefined>(undefined)
    const [cautionCheck, setCautionCheck] = useState<boolean>(true)


    const {refetch: getVitaIndex} = useQuery<VitaIndexContents, AxiosError>(
        ['getVitaIndex'],
        () => VitaIndexService.getVitaIndexData(vitaIndexReq),
        {
            onSuccess: (v: VitaIndexContents) => success(v)
        }
    )

    const success = (v:VitaIndexContents) => {
        if(v.result){
            const data:VitaIndexDescContentsData = StringUtil.jsonString2ObjectWithMap(v.data)
            if(data && data.result){
                setCollectError(false)
                setVitaIndexDetail(data.vitaIndexDetail)
                setScoreFeature(data.scoreFeature)
                setScoreTipList(data.scoreTipList)
                if(data.warningHabit.comment == null && data.warningHabit.lifelogType == null && data.warningHabit.title == null){
                    setCautionCheck(false)
                } else {
                    setCautionCheck(true)
                    setWarningHabit(data.warningHabit)
                }

            }else{
                setCollectError(true)
            }
        }
    }

    useEffect(() => {
        if(props){
            const _vitaIndexReq = new VitaIndexReq (
                '',
                '',
                Number(StorageUtil.getLocalStorage(USER_ID))
            )
            setVitaIndexReq(_vitaIndexReq)
        }
    },[props])

    useEffect(() => {
        if(vitaIndexReq){
            getVitaIndex()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[vitaIndexReq])



    return (
        <div>
            <div style={{backgroundColor: "#e8e8e8"}}>
                <LinkHeader leftIcon={'prev'} leftLink={'/main'} />
                <HeaderSpacer className='bg-cl-gray-7'/>
                {
                    collectError === undefined ?
                        <>
                            <div className={'mrml-24px'}>
                                <Skeleton variant="rounded" height={'720px'} width={'100%'}></Skeleton>
                            </div>
                        </>
                        :
                        collectError?
                            <>
                                <CollectNotYet/>
                            </>
                            :
                            <>
                                <VitaIndexScore vitaIndexDetail={vitaIndexDetail}/>
                                <div className={'gray-bar'}></div>
                                <ScoreCharacter scoreFeature={scoreFeature} />
                                { cautionCheck ?
                                    <>
                                        <div className={'gray-bar'}></div>
                                        <Caution warningHabit={warningHabit}/>
                                    </>
                                    : <></>
                                }
                                <div className={'gray-bar'}></div>
                                <RaiseScore scoreTipList={scoreTipList} />
                                <div className={'gray-bar'}></div>
                                <VitaIndexHistory />
                            </>
                }
           </div>
        </div>
    )
}

export default VitaIndexDetail