import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react'
import {Input, InputAdornment} from "@mui/material";
import NumberUtil from "@/common/NumberUtil";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import StepService from "@/service/StepService";

export interface ChangeGoalParam{
    onClose: () => void,
    refresh: () => void,
    limitDay: number,
    stepGoal: number,
    recommendGoal: number,
    userId: number
}

const ChangeGoal = (props: ChangeGoalParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [stepGoal, setStepGoal] = useState('0')
    const inputRef = useRef(null)
    const [goalInfoStyle, setGoalInfoStyle] = useState({})
    const [goalInfoMessage, setGoalInfoMessage] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(true)

    const onChangeHandler = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value  = e.target.value
        const replaceValue = Number(value.replace(/[^0-9]/g, ""))
        setStepGoal(NumberUtil.numberWithCommas(replaceValue))
        if(replaceValue >= 4000 && replaceValue <= 16000) {
            if(props.recommendGoal + 2000 <= replaceValue){
                setGoalInfoMessage('<p>목표를 너무 많이 높이면 오히려 건강에 안 좋을 수 있어요<br/>반드시 수행할 수 있을만한 목표로 설정해주세요!</p>')
                setGoalInfoStyle({visibility: 'visible'})
            }else{
                setGoalInfoStyle({visibility: 'hidden'})
            }
            setButtonDisabled(false)
        }else{
            setGoalInfoMessage('<p><span>4,000보 이상 16,000보 이하의<br/>목표값</span>으로 입력해 주세요!</p>')
            setGoalInfoStyle({visibility: 'visible'})
            setButtonDisabled(true)
        }
    }

    useEffect(()=>{
        setStepGoal(NumberUtil.numberWithCommas(props.stepGoal))
    }, [props.stepGoal])

    const changeStepGoal = () => {
        const commaStepGoal = stepGoal
        StepService.patchStepGoal({userId: props.userId, stepGoal: Number(commaStepGoal.replaceAll(',',''))})
            .then(() => {props.refresh(); handleOverlayClick()})
            .catch(reason=>{console.log(reason)})
    }

    const closePopup = () => {
        handleOverlayClick()
    }

    return (
        <div className={'flex-column flex-start change-goal-popup mrml-24px'}>
            <div className={'mt-43px mb-42px'}>
                <p className={'h5_m'}>하루 걷기 목표를 세워<br/>도전해 보세요</p>
                <p className={'c1_r cl-gray04'}>목표는 {props.limitDay}일에 한 번 바꾸실 수 있어요</p>
                <p className={'mt-30px b2_r'}>매일</p>
                <div className={'w-100p goal-input'}>
                    <Input
                        id="step-goal-input"
                        value={stepGoal}
                        onChange={event => {onChangeHandler(event)}}
                        ref={inputRef}
                        endAdornment={<InputAdornment position="end">걸음</InputAdornment>}
                    />
                </div>
                <div className={'mt-43px mb-42px goal-info'} style={goalInfoStyle}>
                    <div dangerouslySetInnerHTML={{__html: goalInfoMessage? goalInfoMessage:''}}/>
                </div>
                <div className={'w-100p h-100px'}/>
            </div>
            <div className={'w-100p flex popup-buttons'}>
                <button className={'back-button'} onClick={closePopup}>이전</button>
                <button className={'change-button'} disabled={buttonDisabled} onClick={changeStepGoal}>이 목표로 도전하기!</button>
            </div>
        </div>
    )
}

export default ChangeGoal