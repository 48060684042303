import React, {useEffect, useState} from 'react'
import {Skeleton} from "@mui/material";
import DailyMissionContent from '@/view/mission/component/DailyMissionContent'
import DailyMissionComment from '@/view/mission/component/DailyMissionComment'
import StringUtil from "@/common/StringUtil";
import {FocusItem} from "@/service/UserService";
import StorageUtil from "@/common/StorageUtil";
import {FOCUS_ITEM} from "@/common/Constant";

export interface TodayMissionParam{
    missionStatCd:string,
    missionType:string,
    successCnt:number,
    missionDay:number,
    totalMissionDay:number,
    monthMissionDay:number,
    dDay:number,
    stepGoal:string,
    todayStep:string,
    todaySleep:string,
    todayWeight:string,
    comment:string,
    missionList:string
}

const TodayMission = (props: TodayMissionParam) => {
    const focusItem:FocusItem = StringUtil.jsonString2ObjectWithMap<FocusItem>(StorageUtil.getLocalStorage(FOCUS_ITEM) as unknown as '')
    const focusNm = focusItem? focusItem.focusNm : '체지방률'
    const [step, setStep] = useState({content:props.todayStep, class:'cl-gray07', icon:'mission-fail'})
    const [sleep, setSleep] = useState({content:'워치를 차고 주무세요', class:'cl-gray07', icon:'mission-fail'})
    const [weight, setWeight] = useState({content: focusNm +'을 측정하세요', class:'cl-gray07', icon:'mission-fail'})

    useEffect(()=> {
        const stepGoalNumber = Number(props.stepGoal.replaceAll(',',''))
        const todayStepNumber = Number(props.todayStep.replaceAll(',', ''))
        const stepContentClass = stepGoalNumber > 0 && todayStepNumber >= stepGoalNumber ? 'cl-primary-cyan01' : todayStepNumber === 0? 'cl-gray06' :''
        const stepIcon = stepGoalNumber > 0 && todayStepNumber >= stepGoalNumber ? 'mission-success' : 'mission-fail'
        setStep({content: props.todayStep, class: stepContentClass, icon: stepIcon})
        if(StringUtil.isNotEmpty(props.todaySleep)) {
            setSleep({content: props.todaySleep, class: 'cl-primary-cyan01', icon: 'mission-success'})
        }
        if(StringUtil.isNotEmpty(props.todayWeight)) {
            setWeight({content: props.todayWeight, class: 'cl-primary-cyan01', icon: 'mission-success'})
        }
    }, [props])

    return (
        <div className={'card-content mb-12px'}>
            <div className={'mrml-24px'}>
                <p className={'s_title_2_m mt-24px'}>나의 오늘 미션</p>
                {props.missionStatCd === ''
                ?
                    <>
                        <div className={'mt-16px w-100p'}>
                            <Skeleton variant="rounded" animation="wave" height={70} />
                        </div>
                    </>
                :
                    props.missionStatCd === '진행중'
                            ?
                            <div className={'flex justify-between align-center mt-16px w-100p'}>
                                <div className={'flex-column'}>
                                    <div className={'flex align-baseline'}>
                                        <span className={'h4_m ft-cl-black'}>{props.missionDay}일 차&nbsp;</span><span className={'h6_r cl-gray06'}>/ {props.missionType ==='PROMOTION'? props.totalMissionDay: props.monthMissionDay}일</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={'flex justify-between align-center mt-16px w-100p'}>
                                <div className={'flex-column'}>
                                    <div className={'flex align-baseline'}>
                                        <span className={'h4_m ft-cl-black'}>{props.missionDay}일 차&nbsp;</span><span className={'h6_r cl-gray06'}>/ {props.monthMissionDay}일</span>
                                    </div>
                                </div>
                            </div>
                }
                {props.missionStatCd === ''
                    ?
                    <>
                        <div className={'border-top-gray mt-24px'}></div>
                        <div className={'mt-10px mb-24px w-100p'}>
                            <Skeleton variant="rounded" animation="wave" height={300}/>
                        </div>
                    </>
                    :
                    <>
                        <div className={'border-top-gray mt-24px'}></div>
                        <DailyMissionContent iconClass={'walk-icon w-20px h-20px'} missionName={`${props.stepGoal == ''? 0 : props.stepGoal}보 걷기`}
                                             content={`${step.content == ''? 0 : step.content}`}
                                             contentClass={step.class}
                                             subContent={`/ ${props.stepGoal == ''? 0 : props.stepGoal}`}
                                             rightIcon={`${step.icon} w-20px h-20px`}/>
                        {StringUtil.isNotEmpty(props.missionList) && props.missionList.includes('SLEEP') &&
                            <DailyMissionContent iconClass={'sleep-icon w-16px h-20px'} missionName={'워치 차고 자기'}
                                                 content={sleep.content}
                                                 contentClass={sleep.class} rightIcon={`${sleep.icon} w-20px h-20px`}/>
                        }
                        {StringUtil.isNotEmpty(props.missionList) && props.missionList.includes('WEIGHT') &&
                            <DailyMissionContent iconClass={'weight-icon w-14px h-20px'} missionName={focusNm + ' 측정 하기'} content={weight.content}
                                                 contentClass={weight.class} rightIcon={`${weight.icon} w-20px h-20px`}/>
                        }
                        <div className={'mt-24px mb-24px'}>
                            <DailyMissionComment comment={props.comment}/>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default TodayMission