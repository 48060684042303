import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartType} from "chart.js";
import { Doughnut } from 'react-chartjs-2';


const SampleGraph: React.FC = (): JSX.Element => {
    ChartJS.register(ArcElement, Tooltip, Legend);

    const options: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                title: {
                    display: true,
                    text:'this is legend title',
                    font: {
                        size: 12,
                    },
                },
                position: 'right',
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 12,

                    },
                    padding: 50,

                },
            },
        },
        layout: {
            padding:10
        },
        onClick: (e) => {
           console.log(e)
           alert('click')
        },

    }
const data = {
        labels: ['Walk', 'SLEEP', 'WEIGHT'],
        datasets: [
            {
                label: '# of Votes',
                data: [33, 33, 5],
                backgroundColor: [
                    '#24CBAA',
                    'rgba(173, 230, 223, 0.3)',
                    '#A5A5A5',
                ],
                borderWidth: 3,
                borderColor: [
                    'white',
                    'white',
                    'white',
                ],
            },
        ],
    }
    return (
        <>
            <div id='doughnut1'>
                <Doughnut  data={data} options={options}/>
            </div>

        </>
    )
}
export default SampleGraph;