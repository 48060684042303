import moment from "moment-timezone";
import StringUtil from "@/common/StringUtil";

export interface WeekData {
    fromDate: string,
    toDate: string,
    text: string,
}

export interface MonthData {
    month: string,
    text: string,
}

export interface YearData {
    year: string
    text: string,
}

function isPastDay(str:string) : boolean{
    if(StringUtil.isEmpty(str)) return true

    const nowDate = moment(new Date()).tz("Asia/Seoul").format('YYYY-MM-DD 00:00:00')

    if(str.localeCompare(nowDate) == 0)
        return false
    else
        return true
}

function convertTimeTypeFrom24To12(hour: number, min: number):string{
    let type = ''
    if(hour === 0) {
        type = 'AM'
        hour = hour + 12
    }else if(0 < hour && hour < 12){
        type = 'AM'
    }else if(hour === 12){
        type = 'PM'
    }else if(12 < hour && hour < 24) {
        type = 'PM'
        hour = hour - 12
    }else if(hour === 24) {
        type = 'AM'
        hour = hour - 12
    }

    let _hour = String(hour)
    if(hour && hour < 10){
        _hour = '0' + String(hour)
    }

    let _min = String(min)
    if(min < 10){
        _min = '0' + String(min)
    }
    return String(_hour) + ':' + String(_min) + type
}

function getWeekList(userSignupDt: string){
    let weekBoolean = true
    const weekArray = [];
    const nowDate = moment()
    const thisWeekStart = moment(nowDate.startOf('week'))
    const thisWeekEnd = moment(nowDate.endOf('week'))

    const thisWeekData: WeekData = {
        fromDate: thisWeekStart.format('YYYY-MM-DD'),
        toDate: thisWeekEnd.format('YYYY-MM-DD'),
        text: '이번 주'
    }

    weekArray.push(thisWeekData)

    while(weekBoolean){
        const pastWeekStart = thisWeekStart.subtract(1, 'week')
        const pastWeekEnd = thisWeekEnd.subtract(1, 'week')

        const _userSignupDt = moment(userSignupDt)

        const pastWeekData: WeekData = {
            fromDate: pastWeekStart.format('YYYY-MM-DD'),
            toDate: pastWeekEnd.format('YYYY-MM-DD'),
            text: pastWeekStart.format('YY.MM.DD')+' ~ '+pastWeekEnd.format('YY.MM.DD')
        }
        weekArray.push(pastWeekData)
        if(_userSignupDt.isAfter(pastWeekStart) || moment(_userSignupDt).format('YYYY-MM-DD').toString() === pastWeekStart.format('YYYY-MM-DD').toString()) {
            weekBoolean = false
        }
    }
    return weekArray
}

function getMonthList(userSignupDt: string){
    let monthBoolean = true
    const monthArray = [];
    const thisMonth = moment()

    const thisMonthData: MonthData = {
       month: thisMonth.format('YYYY-MM').toString(),
       text: '이번 달'
    }

    monthArray.push(thisMonthData)

    while (monthBoolean){
        const pastMonth = thisMonth.subtract(1, 'month')
        const _userSignupDt = moment(userSignupDt)

        if(_userSignupDt.isBefore(pastMonth) || moment(_userSignupDt).format('YYYY-MM').toString() === pastMonth.format('YYYY-MM').toString()){
            const pastMonthData: MonthData = {
                month: pastMonth.format('YYYY-MM').toString(),
                text: pastMonth.format('YY').toString()+'년 '+(pastMonth.month()+1).toString()+'월'
            }
            monthArray.push(pastMonthData)
        }else{
            monthBoolean = false
        }
    }
    return monthArray
}

function getYearList(userSignupDt: string){
    const _userSignupDt = moment(userSignupDt)
    let yearBoolean = true
    const yearArray = [];
    const thisYear = moment()

    const thisYearData: YearData = {
        year: thisYear.format('YYYY').toString(),
        text: thisYear.year().toString()+'년'
    }

    yearArray.push(thisYearData)

    while (yearBoolean){
        const pastYear = thisYear.subtract(1, 'year')
        if(_userSignupDt.isBefore(pastYear) || moment(_userSignupDt).format('YYYY').toString() === pastYear.format('YYYY').toString()){
            const pastYearData: YearData = {
                year: pastYear.format('YYYY').toString(),
                text: pastYear.year().toString()+'년'
            }
            yearArray.push(pastYearData)
        }else{
            yearBoolean = false
        }
    }
    return yearArray
}

function convertTimeFromSecToHour(sec: number){
    return sec/60/60
}

function convertTimeFromSecToHourMin(_sec: number) {
    const hour = parseInt((_sec/3600).toString())
    const min = parseInt(((_sec%3600)/60).toString())
    let _min = ''
    if(min > 0 && min < 10){
        _min = '0' + String(min)
    }else{
        _min = String(min)
    }
    return String(hour) +'H ' + _min +'M'
}

function checkCurrentWeekYn(_fromDate:string,_toDate:string){
    const nowDate = moment().format('YYYY-MM-DD')
    return moment(nowDate).isBetween(_fromDate,_toDate)
}

function checkCurrentMonthYn(_month:string){
    const nowDate = moment().format('YYYY-MM')
    return _month === nowDate
}
function getAge(birthday:string) {
    let age = 0
    const nowDate = moment(new Date()).tz("Asia/Seoul").format('YYYYMMDD').toString()

    age = Number(nowDate.substring(0,4)) - Number(birthday.substring(0,4))

    if(Number(nowDate.substring(4)) > Number(birthday.substring(4)) )
        age++

    return age
}

function getCalendarDataForMonth(month:string) {
    const result = []
    if(month && month !== '') {
        const firstDay = moment(month).startOf('month').day()
        let startDt = moment(month).startOf('month').format('YYYYMMDD')
        const endDt = moment(month).endOf('month').format('YYYYMMDD')

        //저번달에 해당하는데 1주차에 나오는 일자는 빈칸
        for(let i = 0 ; i < firstDay; i ++){
            result.push({date:'',status:'past'})
        }

        while (startDt <= endDt){
            if(moment(startDt).format('YYYYMMDD') === moment().format('YYYYMMDD')){
                result.push({date:moment(startDt).format('YYYY-MM-DD') ,status:'today'})
            }else if(moment().isBefore(moment(startDt))){
                result.push({date:moment(startDt).format('YYYY-MM-DD') ,status:'deactive-day'})
            }else{
                result.push({date:moment(startDt).format('YYYY-MM-DD') ,status:'active-day'})
            }
            startDt = moment(startDt).add(1,'days').format('YYYYMMDD')
        }
    }

    return result
}

export default {
    isPastDay,
    convertTimeTypeFrom24To12,
    getWeekList,
    getMonthList,
    getYearList,
    convertTimeFromSecToHour,
    convertTimeFromSecToHourMin,
    checkCurrentWeekYn,
    checkCurrentMonthYn,
    getAge,
    getCalendarDataForMonth,
}