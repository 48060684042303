import React, {useEffect, useState} from 'react'
import {withStyles , createStyles} from '@mui/styles'
import LinearProgress from '@mui/material/LinearProgress'

const CustomizedLinearProgress = withStyles(() =>
    createStyles({
        root: {
            height: '6px',
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#F1F1F1',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#24CBAA',
        },
    }),
)(LinearProgress);

export interface AccuracyChartProps{
    dayPercent: number,
    dayCount: number
}

const AccuracyChart = (props: AccuracyChartProps) => {
    const [_dayCount, setDayCount] = useState(0)
    useEffect(()=>{
        if(props.dayCount < 30){
            const minusDay = 30 - props.dayCount
            setDayCount(minusDay)
        }
    },[props.dayCount])


    return <div className={'mt-26px'}>
                <div className={'flex justify-between align-center'} style={{marginBottom: '7px'}}>
                    <span className={'c1_m cl-gray03'}>정확도 높이는 중...</span>
                    <span className={'s_title_2_b cl-primary-cyan01'}>{props.dayPercent}%</span>
                </div>
                <CustomizedLinearProgress
                    variant="determinate"
                    value={props.dayPercent}
                />
                <div className={'mt-7px'}>
                    <span className={'b2_r cl-gray03'}>TIP: 정교한 분석을 위해 {_dayCount}일 더 기록해보세요.</span>
                </div>
            </div>
}

export default AccuracyChart
