import React, {ChangeEvent, ForwardedRef, forwardRef, KeyboardEvent, useEffect, useRef, useState} from 'react'
import Icon from "@/view/common/component/Icon"
import StringUtil from "@/common/StringUtil";

export type InputValue = string | number | ReadonlyArray<string>
export type InputChangeEvent = ChangeEvent<HTMLInputElement>

export interface CustomInputProps{
    label?: string,
    placeholder?: string,
    value?: InputValue,
    onChange?: (ev: InputChangeEvent) => void
    name: string,
    theme: string,
    rightContent?:JSX.Element,
    children?: string,
    error?: boolean,
    errorMsg?: string,
    required?: boolean,
    readOnly?: boolean,
    onClick?: () => void,
    onKeyUp?: () => void | Promise<void>,
    onBlur?: () => void | Promise<void>,
    maxLength?: number,
    type?:string,
    goNext?: (name:string) => void | Promise<void>
}

const Input = forwardRef((props:CustomInputProps, forwardRef:ForwardedRef<HTMLInputElement>) => {
    const [type, setType] = useState(props.type?props.type:'text')
    const [inputValue, setInputValue] = useState<InputValue>(props.value?props.value:'')
    const [themeClass, setThemeClass] = useState(props.theme)
    const [errorDisplay, setErrorDisplay] = useState('none')
    const divRef = useRef<HTMLDivElement>(null)

    const changeHandler = (ev: InputChangeEvent) => {
        props.onChange && props.onChange(ev) // optional로 인한 코드
        setInputValue(ev.target.value)
    }

    const focusHandler = () => {
        setThemeClass(props.errorMsg? props.theme+'_error' : props.theme+'_selected')
    }

    const blurHandler = () => {
        if(props.onBlur){
            void props.onBlur()
            if(StringUtil.isEmpty(props.errorMsg)){
                setThemeClass(props.theme)
            }
        }else {
            if (props.required && StringUtil.isEmpty(inputValue.toString())) {
                setThemeClass(props.theme + '_error')
            } else {
                setThemeClass(props.errorMsg? props.theme+'_error' : props.theme)
            }
        }
    }

    const keyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        props.onKeyUp && props.onKeyUp()
        if (e.key === 'Enter') {
            props.goNext && props.goNext(props.name)
        }
    }

    const inputFocus = () => {
        if (divRef.current != null) {
            const childNode = divRef.current.firstChild as HTMLInputElement
            if(childNode) childNode.focus()
            props.onClick && props.onClick()
        }
    }

    useEffect(()=>{
        if(themeClass.includes('_error')){
            setErrorDisplay('')
        }else{
            setErrorDisplay('none')
        }
    },[themeClass])

    useEffect(()=>{
        if(!StringUtil.isEmpty(props.errorMsg)){
            setThemeClass(props.theme + '_error')
        }else{
            setThemeClass(prevState => prevState.includes('_error')? props.theme + '_selected' : prevState.includes('_selected')? props.theme + '_selected' : props.theme)
        }
    }, [props.errorMsg])

    useEffect(() => {
        setInputValue(props.value || '')
    }, [props.value])

    useEffect(()=>{
        if(props.type !== undefined)
            setType(props.type)
    },[props.type])

    return (
        <div className={'custom-input'}>
            <div className={themeClass}>
                <div className={'custom-input-box'}
                     onFocus={focusHandler}>
                    <p className={'c2_r pt-14px ml-16px'}>{props.label}</p>
                    <div className={'pl-16px w-100p flex justify-between'} ref={divRef}>
                        <input className={'b1_r'} placeholder={props.placeholder} value={inputValue}
                               onChange={changeHandler}
                               onBlur={blurHandler}
                               readOnly={props.readOnly}
                               onClick={props.onClick}
                               onKeyUp={keyUpHandler}
                               maxLength={props.maxLength}
                               type={type}
                               ref={forwardRef}
                        />
                        <div className={'icon-wrapper'}>
                            {props.rightContent && inputValue? props.rightContent: ''}
                        </div>
                    </div>
                </div>
                <div style={{display: `${errorDisplay}`}} onClick={inputFocus}>
                    {props.errorMsg
                        ?<div className={'error mt-8px flex'}>
                            <Icon iconClass={'error-info'}/>
                            <p className={'c1_r cl-point03 ml-3px'}>{props.errorMsg}</p>
                        </div>
                        :''
                    }
                </div>
            </div>
        </div>
    )


})

export default Input