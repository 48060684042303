import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from './App';
import '@/assets/scss/index.scss';

const theme = createTheme({
    palette: {
        primary: {
            light: '#8FE2D2',
            main: '#24CBAA',
            dark: '#11B998',
        },
        secondary: {
            light: '#FFD976',
            main: '#FFC223',
        },
    },
});
const root = ReactDOM.createRoot(
  document.getElementById('contentBody') as HTMLElement
);

root.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
);
