import React, {useEffect, useState} from 'react'
import {Chart} from "chart.js"
import { Bar } from 'react-chartjs-2'
import moment from "moment-timezone";
import annotationPlugin from 'chartjs-plugin-annotation'
import datalabelsPlugin from 'chartjs-plugin-datalabels'
import {ScoreFeatureDetailSecondCard} from "@/service/VitaIndexService";
Chart.register(annotationPlugin, datalabelsPlugin)

export type ChartDataSet = {
    type: any,
    barThickness: number,
    borderRadius: number,
    backgroundColor: string[],
    data: number[] | object[] | undefined[] | undefined,
}

interface PropsConfig {
    scoreFeatureDetailSecondCard?:ScoreFeatureDetailSecondCard
}


const CharacterChart = (props:PropsConfig) => {
    const [data,setData] = useState<{labels:string[], datasets:ChartDataSet[]}>({labels:[],datasets:[]})
    const [options,setOptions] = useState({})

    useEffect(()=>{
        if(props.scoreFeatureDetailSecondCard){
            createChart(props.scoreFeatureDetailSecondCard)
        }
    }, [props.scoreFeatureDetailSecondCard])

    const createChart = (scoreFeatureDetail:ScoreFeatureDetailSecondCard) => {
        updateData(scoreFeatureDetail.lastWeekScoreList)
        updateOptions(scoreFeatureDetail.meanScore)
    }

    const updateData = (lastWeekScoreList: number[]) => {
        const _labels: string[] = []
        _labels.push('오늘')
        for(let i = 1 ; i < 7; i ++){
            const label = moment().subtract(i, 'days').format('MM.DD')
            _labels.push(label.toString())
        }
        const reverseLables = _labels.reverse()
        setData({
            labels : reverseLables,
            datasets: [
                {
                    type: 'bar' as const,
                    barThickness: 16,
                    borderRadius: 2,
                    backgroundColor: ['#8FE2D2','#8FE2D2','#8FE2D2','#8FE2D2','#8FE2D2','#8FE2D2','#24CBAA'],
                    //data: [501, 584, 194, 584, 598, 612, 624], //scoreFeatureDetail[0]: 일주일전 데이터, scoreFeatureDetail[6]: 오늘 데이터
                    data: lastWeekScoreList, //scoreFeatureDetail[0]: 일주일전 데이터, scoreFeatureDetail[6]: 오늘 데이터
                },
            ],
        })
    }

    const updateOptions = (ageMeanScore:number) => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                tooltip: false,
                legend: false,
                annotation: {
                    annotations: {
                        line1: {
                            drawTime: 'beforeDraw',
                            type: 'line',
                            yMin: ageMeanScore,
                            yMax: ageMeanScore,
                            borderColor: '#8FE2D2',
                            borderDash: [2, 2],
                            borderWidth: 2,
                        },
                    }
                },
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    color: function() {
                        return ['#AAAAAA','#AAAAAA','#AAAAAA','#AAAAAA','#AAAAAA','#AAAAAA','#1A1A1A']
                    },
                    font: {
                        size: '12',
                        family: 'SpoqaHanSansNeo',
                        lineHeight: '18px',
                        weight: '400'
                    },
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: '12',
                            style: 'normal',
                            family: 'SpoqaHanSansNeo',
                            lineHeight: '18px',
                            weight: '500'
                        },
                        getLabelForValue(value: number): string {
                            return value as unknown as string
                        },
                        callback: function(_value: number, index: number) {
                            let result
                            if(index == 0 || index == 6){
                                result = this.getLabelForValue(index)
                            } else {
                                result = ''
                            }
                            return result
                        }
                    },
                },
                y: {
                    max: 1000,
                    min: 0,
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            layout: {
                padding: {
                    top: 15
                }
            },
        })
    }

    return <>
                <div style={{height: '230px', margin: '0px 31px', paddingTop: '38px'}}>
                    <Bar data={data} options={options} height= '220px'/>
                </div>
                <div className={'characteristics-card-content'}>
                        <span className={'h6_m cl-gray01'}>{props.scoreFeatureDetailSecondCard?.title}</span>
                        <span className={'b2_r cl-gray03 mt-13px'}>{props.scoreFeatureDetailSecondCard?.comment}</span>
                </div>
            </>
}

export default CharacterChart
