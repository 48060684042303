
export declare interface WebRothyInterface {
    action: string
    data?: any
}

export enum WebRothyInterfaceAction {
    GO_TO_MISSION = "goToMission",
    SEND_TO_DONATION_CLAUSE = "sendToDonationClause",
    GO_TO_MAIN_ROVING = "goToMainRoving",
    GO_TO_POINTLIST = "goToPointList",
    GO_TO_POINT = "goToPoint",
    FINISH_VIEW = "finishView",
    LINK = "link",
    GET_SHARE = "getShare",
    CLIPBOARD_COPY = "clipboardCopy",
    GO_TO_DNA_RESULT = "goToDnaResult",
    GO_TO_TERMS = "goToTerms"
}

export declare interface SendToDonationClause {
    address: string,
    addressDetail: string,
    isIndiInfoAgree: boolean,
    isServiceAgree: boolean,
    residentNo: string,
    userNm: string,
    zipNo: string,
}

export declare interface FinishView {
    isRead: boolean,
}

export declare interface Link {
    type: 'ext-browser',
    url: string,
}

export declare interface Share {
    type: 'kakao' | 'message' | 'more',
    text: string,
}

export declare interface ClipboardCopy {
    text: string,
}

export declare interface GoToTerms {
    termsId: string,
}
