import React from 'react'
import Icon from "@/view/common/component/Icon";

type NoContentProps = {
    className: string
    notification?: string,
};

const NoContent = (props: NoContentProps) => {
    return (
        <>
            <div className='no-content-wrapper'>
                <Icon iconClass={props.className} />
                <p className='b1_r cl-gray05'>{props.notification}</p>
            </div>
        </>
    )
}

export default NoContent;