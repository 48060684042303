export enum Actions {
    IS_HEALTH_DATA_AVAILABLE = "IS_HEALTH_DATA_AVAILABLE",
    REQUEST_HEALTH_AUTHORIZATION = "REQUEST_HEALTH_AUTHORIZATION",
    GET_HEALTH_DATA_DAILY = "GET_HEALTH_DATA_DAILY",
    GET_HEALTH_DATA_HISTORY = "GET_HEALTH_DATA_HISTORY",
    START_OBSERVER_DAILY = 'START_OBSERVER_DAILY',
    STOP_OBSERVER_DAILY = 'STOP_OBSERVER_DAILY',
    START_OBSERVER_HISTORY = 'START_OBSERVER_HISTORY',
    STOP_OBSERVER_HISTORY = 'STOP_OBSERVER_HISTORY',
}

export enum RHQuantityTypes {
    stepCount = "HKQuantityTypeIdentifierStepCount",
    distanceWalkingRunning = "HKQuantityTypeIdentifierDistanceWalkingRunning",
    walkingSpeed = "HKQuantityTypeIdentifierWalkingSpeed",
    activeEnergyBurned = "HKQuantityTypeIdentifierActiveEnergyBurned",
    sleepAnalysis = "HKCategoryTypeIdentifierSleepAnalysis",
    oxygenSaturation = "HKQuantityTypeIdentifierOxygenSaturation",
    heartRate ="HKQuantityTypeIdentifierHeartRate",
    floorsClimbed ="HKQuantityTypeIdentifierFlightsClimbed",
    exercise ="HKWorkout",

    // Android Only
    weight ="Weight",
}

export enum RHOptions {
    discreteAverage = "2",
    discreteMin = "4",
    discreteMax = "8",
    cumulativeSum = "16",
}

export enum RHSleep {
    inBed = 0,// 사용자는 침대에 있습니다.
    aSleepUnspecified,// 사용자는 잠들어 있지만 특정 단계를 알 수 없습니다.
    // asleep,// 미사용
    awake,// 사용자가 깨어 있습니다.
    asleepCore,// 사용자가 얕은 수면 또는 중간 수면 상태입니다.
    asleepDeep,// 사용자가 깊은 잠에 빠져 있습니다.
    asleepREM,// 사용자가 REM 수면 상태입니다.
}
export const RHSets = {
    stepCount: {
        daily: {
            quantityType: RHQuantityTypes.stepCount,
            option: RHOptions.cumulativeSum,
        },
        history: {
            quantityType: RHQuantityTypes.stepCount,
        }
    },

    distanceWalkingRunning: {
        daily: {
            quantityType: RHQuantityTypes.distanceWalkingRunning,
            option: RHOptions.cumulativeSum,
        },
        history: {
            quantityType: RHQuantityTypes.distanceWalkingRunning,
        }
    },

    walkingSpeed: {
        daily: {
            quantityType: RHQuantityTypes.walkingSpeed,
            option: RHOptions.discreteAverage,
        },
        history: {
            quantityType: RHQuantityTypes.walkingSpeed,
        }
    },

    activeEnergyBurned: {
        daily: {
            quantityType: RHQuantityTypes.activeEnergyBurned,
            option: RHOptions.cumulativeSum,
        },
        history: {
            quantityType: RHQuantityTypes.activeEnergyBurned,
        }
    },

    sleepAnalysis: {
        history: {
            quantityType: RHQuantityTypes.sleepAnalysis,
        }
    },

    oxygenSaturation: {
        daily: {
            quantityType: RHQuantityTypes.oxygenSaturation,
            option: {
                min: RHOptions.discreteMin,
                max: RHOptions.discreteMax,
            }
        },
        history: {
            quantityType: RHQuantityTypes.oxygenSaturation,
        }
    },

    heartRate: {
        history: {
            quantityType: RHQuantityTypes.heartRate,
        }
    },

    floorsClimbed: {
        history: {
            quantityType: RHQuantityTypes.floorsClimbed,
        }
    },

    exercise: {
        history: {
            quantityType: RHQuantityTypes.exercise,
        }
    },

    weight: {
        history: {
            quantityType: RHQuantityTypes.weight,
        }
    },
}

export class RHParamAuthorization {
    // 필수
    shareDataTypes?: string[]
    readDataTypes?: string[]
}

export class RHParamDaily {
    // 필수
    quantityType?: string
    option?: string
    startDate?: string

    // 옵션
    endDate?: string
}

export class RHParamHistory {
    // 필수
    quantityType?: string
    startDate?: string
    endDate?: string
}

export declare interface RHQuantity {
    startDate: string
    endDate: string
    quantityType: string
    quantity: number
    quantityMin: number
    quantityMax: number
    categoryType: string
    categoryValue: number
    deviceModel?: string
}

// SamsungHealth
export declare interface SHExercise {
    deviceuuid: string
    deviceGroup: number
    dvicTp: number

    // 사용자ID
    userId: number
    // 데이터 고유키
    datauuid: string
    // 데이터 생성 TimeStamp
    createTime: number
    // 데이터 변경 TimeStamp
    updateTime: number
    // 패키지 이름
    pkgName: string
    // 측정 시작 시 UTC 밀리초
    startTime: number
    // 측정 종료 후 UTC 밀리초
    endTime: number
    // 사전 정의된 운동 유형
    exerciseType: number
    // 시간 오프셋(밀리초)
    timeOffset: number
    // 소모된 칼로리
    calorie: number
    // 운동 시간
    duration: number
    // 맞춤 운동 유형
    exerciseCustomType?: string
    // 거리
    distance?: number
    // 활동 중 고도 증가
    altitudeGain?: number
    // 활동 중 고도 감소
    altitudeLoss?: number
    // 반복 동작 횟수
    count?: number
    // 반복 동작 유형 30001-스트라이드 또는 스텝 케이던스 유형, 30002-스트로크 케이던스 유형, 30003-스윙 케이던스 유형, 30004-일반 반복 유형
    countType?: number
    // 최대 속도
    maxSpeed?: number
    // 평균 속도
    meanSpeed?: number
    //	최대 소모 칼로리 비율
    maxCaloricburnRate?: number
    //	평균 소모 칼로리 비율
    meanCaloricburnRate?: number
    //	최대 케이던스 비율
    maxCadence?: number
    //	평균 케이던스 비율
    meanCadence?: number
    //	최대 심박수
    maxHeartRate?: number
    //	평균 심박수
    meanHeartRate?: number
    //	최소 심박수
    minHeartRate?: number
    //	최대 고도
    maxAltitude?: number
    //	최소 고도
    minAltitude?: number
    //	오르막 거리
    inclineDistance?: number
    //	내리막 거리
    declineDistance?: number
    //	최대 전력
    maxPower?: number
    //	평균 전력
    meanPower?: number
    //	평균 rpm(분당 회전 수)
    meanRpm?: number
    //	최대 rpm(분당 회전 수)
    maxRpm?: number
    //	운동 중 실시간 데이터(예: 심박수, 속도, 전력 등)
    liveData?: ExcerciseLive[]
    //	운동 중 위치(궤적) 데이터
    locationData?: ExcerciseLocation[]
    //	운동 중 최대 산소 소비량.
    vo2Max?: number
    //	논평
    comment?: string
    //	운동의 세부 사항을 표현하기 위한 추가 정보
    additional?: string
    // JSON 및 압축 데이터로 형식이 지정된 사용자 지정 정보
    custom?: string
}

export declare interface ExcerciseLive {
    startTime?: number
    heartRate?: number
    cadence?: number
    count?: number
    power?: number
    speed?: number
    distance?: number
}

export declare interface ExcerciseLocation {
    startTime?: number
    latitude?: number
    longitude?: number
    altitude?: number
    accuracy?: number
}

export declare interface SHWeightStageData {
    deviceuuid: string
    deviceGroup: number
    dvicTp: number

    create_time: number
    update_time: number
    start_time: number
    time_offset: number
    pkg_name: string
    weight: number
    height: number
    body_fat: number
    skeletal_muscle: number
    muscle_mass: number
    basal_metabolic_rate: number
    body_fat_mass: number
    fat_free_mass: number
    fat_free: number
    skeletal_muscle_mass: number
    total_body_water: number
    comment: string
}

export declare interface SHWeightStage {
    msreDtm: string
    tall: number
    wt: number
    basalMtblsQty: number
    bdfatRate: number
    bdfatQty: number
    muslWt: number
    skltnWtRate: number
    skltnWt: number
    bdwtQty: number
    bmi: number
    dvicTp: number
}
