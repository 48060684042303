import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {WalkRecordParam} from "@/view/walk/component/WalkHowFar";
import StepService, {CalorieBurnedData, CalorieBurnedRes} from "@/service/StepService";
import StringUtil from "@/common/StringUtil";
import numberUtil from "@/common/NumberUtil";
import WalkBarChart, {WalkBarChartParam} from "@/view/walk/component/WalkBarChart";

type FoodData = {
    bubbleComment:string,
    bubbleImageUrl:string,
    burnedCalorie:number,
    foodImageUrl:string
}

const WalkCalorieBurned = (props: WalkRecordParam) => {
    const itemList = [{name:'음식', value:'FOOD'}, {name:'수치', value:'AMOUNT'}]
    const [clickedIndex, setClickedIndex] = useState(0)
    const [selectType, setSelectType] = useState('FOOD')
    const title = '칼로리 소비를\n얼마나 했을까?'
    const [comment, setComment] = useState('')
    const [chartParam, setChartParam] = useState<WalkBarChartParam>()
    const [foodData, setFoodData] = useState<FoodData>()
    const [amountData, setAmountData] = useState<CalorieBurnedData>()

    const selectButton = (index:number, data:string) =>{
        setClickedIndex(index)
        setSelectType(data)
    }

    const {refetch:getBuredCalorie} = useQuery<CalorieBurnedRes, AxiosError>(
        ['getBuredCalorie'],
        ()=> StepService.getWalkRecordCalorie({fromDate:props.fromDate, toDate:props.toDate
            , month: props.month, year:props.year, userId:props.userId.toString()
            , periodType:props.periodType, selectType:selectType}),
        {
            onSuccess: (v:CalorieBurnedRes) => updateGraphData(v.data)
        }
    )

    const updateGraphData = (data:CalorieBurnedData) => {
        setComment(data.recordComment)
        if(selectType === 'FOOD'){
            setFoodData({bubbleComment: data.bubbleComment, bubbleImageUrl: data.bubbleImageUrl, burnedCalorie: data.burnedCalorie, foodImageUrl: data.foodImageUrl})
        }else{
            setAmountData(data)
        }
    }

    useEffect(()=>{
        if(props.periodType !== undefined && StringUtil.isNotEmpty(props.periodType)){
            getBuredCalorie()
                .then(r=>r)
                .catch(reason => console.log(reason))
        }
    },[props, selectType])

    useEffect(() => {
        let data1:number[] = []
        let data2:object[] | number[] | undefined[] = []
        const data3:number[] = []
        let labels:string[] = []
        let maxValue = 5
        let recommendValue = 0
        if(amountData){
            data1 = amountData.presentData.map(d=>d.verticalValue)
            data2 = amountData.pastData.map(d=>d.verticalValue)
            maxValue = Math.ceil(Math.max.apply(null, data1.concat(data2)))
            console.log(Math.ceil(Math.max.apply(null, data1.concat(data2))))
            data2.forEach((d, index)=> {if(Number(d) === 0) data2[index] = undefined})
            labels = amountData.presentData.map(d=> getLabelData(d.horizontalValue))
            amountData.presentData.forEach(d=> data3.push(amountData.recommendValue))
            recommendValue = amountData.recommendValue
        }
        setChartParam({
            labelData1: data1,
            labelData2: data2,
            labelData3: data3,
            maxValue: maxValue,
            labels:labels,
            periodType: props.periodType,
            selectType: selectType,
            recommendValue: recommendValue
        })
    }, [amountData])

    const getLabelData = (label:string) => {
        let result = ''
        if(props.periodType === 'MONTH'){
            if(['7','14','21','28'].includes(label)){
                result = label +'일'
            }
        }else{
            result = label
        }
        return result
    }

    return (
        <div className={'mt-63px calorie-burned'}>
            <div className={'flex justify-between flex-end'}>
                <div className={'title'}>
                    <p className={'s_title_1_m'}>{title}</p>
                </div>
                <div className={'flex toggle-box mb-4px'}>
                    {itemList.map((data, index)=>{
                        return <div key={index}> <button className={`${clickedIndex === index? 'on' : ''}`} onClick={() => selectButton(index,data.value)}>{data.name}</button> </div>
                    })}
                </div>
            </div>
            <div className={'gray-bar-thin mt-9px'}></div>
            <p className={'b2_l mt-12px'}>{comment}</p>
            {
                selectType === 'FOOD'
                    ?
                        <div className={'mt-38px flex-column'}>
                            <p className={'c1_r cl-primary-cyan01'}>소모칼로리</p>
                            <div className={'flex align-baseline'}>
                                <p className={'h4_m cl-primary-cyan01'}>{numberUtil.numberWithCommas(foodData? foodData?.burnedCalorie : 0)}</p>
                                <p className={'b1_r cl-primary-cyan01'}>kcal</p>
                            </div>
                            <div className={'mt-22px'}>
                                <img width={'100%'} height={'fit-content'} src={foodData?.foodImageUrl}/>
                            </div>
                            {foodData?.bubbleComment !== ''
                                ?
                                <div className={'mt-32px'}>
                                    <p className={'b2_l cl-primary-cyan01 bubble prpl-24px text-center'}>{foodData?.bubbleComment}</p>
                                </div>
                                :
                                null
                            }
                        </div>
                    :
                        <div className={'mt-38px'}>
                            <WalkBarChart {...chartParam}/>
                        </div>
            }
        </div>
    )
}

export default WalkCalorieBurned