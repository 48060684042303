import React, {CSSProperties, useEffect, useState} from 'react';
import moment from 'moment-timezone';
import InterfaceUtil from "@/common/Interface";
import {OS_TYPE} from "@/common/Constant";

import RothyNativeService, {
    RNIActions,
    RNIError,
    RNIEvents,
    RNIMessageConfig,
} from "@/service/rothynative/RothyNativeService";
import {
    RHParamAuthorization,
    RHParamDaily,
    RHParamHistory,
    RHQuantity,
    RHQuantityTypes,
    RHSets,
    RHSleep,
    SHExercise,
    SHWeightStageData
} from "@/service/rothynative/RNIHealth";

const RothyHealthSample: React.FC = (): JSX.Element => {
    const [isHealthDataAvailable, setHealthDataAvailable] = useState<boolean | undefined>()
    const [isHealthPermission, setHealthPermission] = useState<boolean | undefined>()

    // 일자별
    const [stepCountDailys, setStepCountDailys] = useState<Array<RHQuantity>>([])
    const [distanceDailys, setDistanceDailys] = useState<Array<RHQuantity>>([])
    const [avgSpeedDailys, setAvgSpeedDailys] = useState<Array<RHQuantity>>([])
    const [calorieDailys, setCalorieDailys] = useState<Array<RHQuantity>>([])

    // 기록
    const [stepCountHistorys, setStepCountHistorys] = useState<Array<RHQuantity>>([])
    const [distanceHistorys, setDistanceHistorys] = useState<Array<RHQuantity>>([])
    const [avgSpeedHistorys, setAvgSpeedHistorys] = useState<Array<RHQuantity>>([])
    const [calorieHistorys, setCalorieHistorys] = useState<Array<RHQuantity>>([])

    // 걸을 일자 실시간
    const [stepCountDailyRealTime, setStepCountDailyRealTime] = useState<RHQuantity>()
    const [distanceDailyRealTime, setDistanceDailyRealTime] = useState<RHQuantity>()
    const [avgSpeedDailyRealTime, setAvgSpeedDailyRealTime] = useState<RHQuantity>()
    const [calorieDailyRealTime, setCalorieDailyRealTime] = useState<RHQuantity>()

    // 걸을 기록 실시간
    const [stepCountHistoryRealTime, setStepCountHistoryRealTime] = useState<RHQuantity>()
    const [distanceHistoryRealTime, setDistanceHistoryRealTime] = useState<RHQuantity>()
    const [avgSpeedHistoryRealTime, setAvgSpeedHistoryRealTime] = useState<RHQuantity>()
    const [calorieHistoryRealTime, setCalorieHistoryRealTime] = useState<RHQuantity>()

    const [isDailyRealTime, setDailyRealTime] = useState(false)
    const [isHistoryRealTime, setHistoryRealTime] = useState(false)

    // 수면
    const [sleepHistorys, setSleepHistorys] = useState<Array<RHQuantity>>([])

    // 산소포화도 일자별
    const [oxygenDailys, setOxygenDailys] = useState<Array<OxygenSaturationNetData>>([])

    const [oxygenDailyRealTime, setOxygenDailyRealTime] = useState<RHQuantity>()
    const [isOxygenDailyRealTime, setIsOxygenDailyRealTime] = useState(false)

    // 산소포화도 기록별
    const [oxygenHistorys, setOxygenHistorys] = useState<Array<RHQuantity>>([])
    const [isOxygenHistoryRealTime, setIsOxygenHistoryRealTime] = useState(false)

    const startDate = moment("2023-01-01T00:00:00").utc().format('YYYY-MM-DDTHH:mm:ss')
    const endDate = moment("2023-01-30T00:00:00").utc().format('YYYY-MM-DDTHH:mm:ss')

    useEffect(() => {
        onClickStepStopDaily()
        onClickStepStopHistory()

        // Apple Health Daily 실시간
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_DAILY_UPDATE, (response: Array<RHQuantity>) => {
            for (let i = 0; i < response.length; i++) {
                const data = response[i]
                switch (response[i].quantityType) {
                    case RHQuantityTypes.stepCount:
                        // 걸음수
                        setStepCountDailyRealTime(data)
                        break;
                    case RHQuantityTypes.distanceWalkingRunning:
                        // 거리
                        setDistanceDailyRealTime(data)
                        break;
                    case RHQuantityTypes.walkingSpeed:
                        // 평균 속도(m/s)
                        setAvgSpeedDailyRealTime(data)
                        break;
                    case RHQuantityTypes.activeEnergyBurned:
                        // 칼로리
                        setCalorieDailyRealTime(data)
                        break;
                    case RHQuantityTypes.oxygenSaturation:
                        // 산소포화도
                        setOxygenDailyRealTime(data)
                        break;
                }
            }
        })

        // Apple Health History 실시간
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_HISTORY_UPDATE, (response: Array<RHQuantity>) => {
            for (let i = 0; i < response.length; i++) {
                const data = response[i]
                switch (response[i].quantityType) {
                    case RHQuantityTypes.stepCount:
                        // 걸음수
                        setStepCountHistoryRealTime(data)
                        break;
                    case RHQuantityTypes.distanceWalkingRunning:
                        // 거리
                        setDistanceHistoryRealTime(data)
                        break;
                    case RHQuantityTypes.walkingSpeed:
                        // 평균 속도(m/s)
                        setAvgSpeedHistoryRealTime(data)
                        break;
                    case RHQuantityTypes.activeEnergyBurned:
                        // 칼로리
                        setCalorieHistoryRealTime(data)
                        break;
                }
            }
        })

        return () => {
            RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_DAILY_UPDATE)
            RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_HISTORY_UPDATE)
        }
    }, [])

    const onClickIsHealthDataAvailable = () => {
        const config = new RNIMessageConfig<void, boolean>()
        config.action = RNIActions.IS_HEALTH_DATA_AVAILABLE

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: boolean) => {
                setHealthDataAvailable(response)
            })
            .catch(healthError)
    }

    const onClickRequestHealthAuthorization = () => {
        const param = new RHParamAuthorization()
        param.shareDataTypes = []
        param.readDataTypes = [
            // 걸음 데이터 셋
            RHQuantityTypes.stepCount,
            RHQuantityTypes.distanceWalkingRunning,
            RHQuantityTypes.walkingSpeed,
            RHQuantityTypes.activeEnergyBurned,

            // 수면
            RHQuantityTypes.sleepAnalysis,

            // 산소 포화도
            RHQuantityTypes.oxygenSaturation,

            // 심박수
            RHQuantityTypes.heartRate,

            // 계단오름
            RHQuantityTypes.floorsClimbed,

            // 운동량
            RHQuantityTypes.exercise,
        ]

        if (InterfaceUtil.getOs() === 'android') {
            // 체질량
            param.readDataTypes.push(RHQuantityTypes.weight)
        }

        const config = new RNIMessageConfig<RHParamAuthorization, boolean>()
        config.action = RNIActions.REQUEST_HEALTH_AUTHORIZATION
        config.data = param

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: boolean) => {
                setHealthPermission(response)
            })
            .catch(healthError)
    }

    const healthError = (error: RNIError) => {
        if (error.code === '-9005') {
            // 삼성헬스앱/애플헬스앱 미설치
            openStoreApp()
        } else if (error.code === '-9006') {
            // 삼성헬스앱 미활성화
            openSamsungHealthApp()
        } else {
            alert(`${error.code || ''}::${error.message || ''}`)
        }
    }

    const openStoreApp = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_URL
        if (InterfaceUtil.getOs() == OS_TYPE.ANDROID) {
            config.data = "https://play.google.com/store/apps/details?id=com.sec.android.app.shealth"
        } else if (InterfaceUtil.getOs() == OS_TYPE.IOS) {
            config.data = "https://apps.apple.com/us/app/apple-health/id1242545199"
        }

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open url success")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const openSamsungHealthApp = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_APP
        config.data = "com.sec.android.app.shealth"

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open app success")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onClickStepCountDaily = () => {
        // 걸음수
        const stepParam = new RHParamDaily()
        stepParam.quantityType = RHSets.stepCount.daily.quantityType
        stepParam.option = RHSets.stepCount.daily.option
        stepParam.startDate = startDate
        stepParam.endDate = endDate

        const stepConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
        stepConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
        stepConfig.data = stepParam

        // 거리
        const disParam = new RHParamDaily()
        disParam.quantityType = RHSets.distanceWalkingRunning.daily.quantityType
        disParam.option = RHSets.distanceWalkingRunning.daily.option
        disParam.startDate = stepParam.startDate
        disParam.endDate = stepParam.endDate

        const disConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
        disConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
        disConfig.data = disParam

        // 평균속도(m/s)
        const speParam = new RHParamDaily()
        speParam.quantityType = RHSets.walkingSpeed.daily.quantityType
        speParam.option = RHSets.walkingSpeed.daily.option
        speParam.startDate = stepParam.startDate
        speParam.endDate = stepParam.endDate

        const speConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
        speConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
        speConfig.data = speParam

        // 칼로리
        const calParam = new RHParamDaily()
        calParam.quantityType = RHSets.activeEnergyBurned.daily.quantityType
        calParam.option = RHSets.activeEnergyBurned.daily.option
        calParam.startDate = stepParam.startDate
        calParam.endDate = stepParam.endDate

        const calConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
        calConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
        calConfig.data = calParam

        const stePromise = RothyNativeService.getInstance().postMessage(stepConfig)
        const disPromise = RothyNativeService.getInstance().postMessage(disConfig)
        const spePromise = RothyNativeService.getInstance().postMessage(speConfig)
        const calPromise = RothyNativeService.getInstance().postMessage(calConfig)

        const promiseAll = Promise.all([stePromise, disPromise, spePromise, calPromise])
        promiseAll
            .then((values) => {
                // 걸음수
                const steResponse = values[0]// as Array<RHDaily>
                // 거리
                const disResponse = values[1]// as Array<RHDaily>
                // 평균속도(m/s)
                const speResponse = values[2]// as Array<RHDaily>
                // 칼로리
                const calResponse = values[3]// as Array<RHDaily>

                setStepCountDailys(steResponse)
                setDistanceDailys(disResponse)
                setAvgSpeedDailys(speResponse)
                setCalorieDailys(calResponse)
            })
            .catch(healthError)
    }

    const onClickStepCountHistory = () => {
        // 걸음수
        const stepParam = new RHParamHistory()
        stepParam.quantityType = RHSets.stepCount.history.quantityType
        stepParam.startDate = startDate
        stepParam.endDate = endDate

        const stepConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        stepConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        stepConfig.data = stepParam

        // 거리
        const disParam = new RHParamHistory()
        disParam.quantityType = RHSets.distanceWalkingRunning.history.quantityType
        disParam.startDate = stepParam.startDate
        disParam.endDate = stepParam.endDate

        const disConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        disConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        disConfig.data = disParam

        // 평균속도(m/s)
        const speParam = new RHParamHistory()
        speParam.quantityType = RHSets.walkingSpeed.history.quantityType
        speParam.startDate = stepParam.startDate
        speParam.endDate = stepParam.endDate

        const speConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        speConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        speConfig.data = speParam

        // 칼로리
        const calParam = new RHParamHistory()
        calParam.quantityType = RHSets.activeEnergyBurned.history.quantityType
        calParam.startDate = stepParam.startDate
        calParam.endDate = stepParam.endDate

        const calConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        calConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        calConfig.data = calParam

        const stePromise = RothyNativeService.getInstance().postMessage(stepConfig)
        const disPromise = RothyNativeService.getInstance().postMessage(disConfig)
        const spePromise = RothyNativeService.getInstance().postMessage(speConfig)
        const calPromise = RothyNativeService.getInstance().postMessage(calConfig)

        const promiseAll = Promise.all([stePromise, disPromise, spePromise, calPromise])
        promiseAll
            .then((values) => {
                // 걸음수
                const steResponse = values[0]// as Array<RHHistory>
                // 거리
                const disResponse = values[1]// as Array<RHHistory>
                // 평균속도(m/s)
                const speResponse = values[2]// as Array<RHHistory>
                // 칼로리
                const calResponse = values[3]// as Array<RHHistory>

                setStepCountHistorys(steResponse)
                setDistanceHistorys(disResponse)
                setAvgSpeedHistorys(speResponse)
                setCalorieHistorys(calResponse)
            })
            .catch(healthError)
    }

    const onClickStepStartDaily = () => {
        // 걸음수
        const steParam = new RHParamDaily()
        steParam.quantityType = RHSets.stepCount.daily.quantityType
        steParam.option = RHSets.stepCount.daily.option
        steParam.startDate = startDate
        steParam.endDate = endDate

        const steConfig = new RNIMessageConfig<RHParamDaily, void>()
        steConfig.action = RNIActions.START_OBSERVER_DAILY
        steConfig.data = steParam

        // 거리
        const disParam = new RHParamDaily()
        disParam.quantityType = RHSets.distanceWalkingRunning.daily.quantityType
        disParam.option = RHSets.distanceWalkingRunning.daily.option
        disParam.startDate = steParam.startDate
        disParam.endDate = steParam.endDate

        const disConfig = new RNIMessageConfig<RHParamDaily, void>()
        disConfig.action = RNIActions.START_OBSERVER_DAILY
        disConfig.data = disParam

        // 평균속도(m/s)
        const speParam = new RHParamDaily()
        speParam.quantityType = RHSets.walkingSpeed.daily.quantityType
        speParam.option = RHSets.walkingSpeed.daily.option
        speParam.startDate = steParam.startDate
        speParam.endDate = steParam.endDate

        const speConfig = new RNIMessageConfig<RHParamDaily, void>()
        speConfig.action = RNIActions.START_OBSERVER_DAILY
        speConfig.data = speParam

        // 칼로리
        const calParam = new RHParamDaily()
        calParam.quantityType = RHSets.activeEnergyBurned.daily.quantityType
        calParam.option = RHSets.activeEnergyBurned.daily.option
        calParam.startDate = steParam.startDate
        calParam.endDate = steParam.endDate

        const calConfig = new RNIMessageConfig<RHParamDaily, void>()
        calConfig.action = RNIActions.START_OBSERVER_DAILY
        calConfig.data = calParam

        RothyNativeService.getInstance().postMessage(steConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(disConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(speConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(calConfig).then(() => {console.log("success")}).catch(healthError)

        setDailyRealTime(true)
    }

    const onClickStepStopDaily = () => {
        // 걸음수
        const steParam = new RHParamDaily()
        steParam.quantityType = RHSets.stepCount.daily.quantityType

        const steConfig = new RNIMessageConfig<RHParamDaily, void>()
        steConfig.action = RNIActions.STOP_OBSERVER_DAILY
        steConfig.data = steParam

        // 거리
        const disParam = new RHParamDaily()
        disParam.quantityType = RHSets.distanceWalkingRunning.daily.quantityType

        const disConfig = new RNIMessageConfig<RHParamDaily, void>()
        disConfig.action = RNIActions.STOP_OBSERVER_DAILY
        disConfig.data = disParam

        // 평균속도(m/s)
        const speParam = new RHParamDaily()
        speParam.quantityType = RHSets.walkingSpeed.daily.quantityType

        const speConfig = new RNIMessageConfig<RHParamDaily, void>()
        speConfig.action = RNIActions.STOP_OBSERVER_DAILY
        speConfig.data = speParam

        // 칼로리
        const calParam = new RHParamDaily()
        calParam.quantityType = RHSets.activeEnergyBurned.daily.quantityType

        const calConfig = new RNIMessageConfig<RHParamDaily, void>()
        calConfig.action = RNIActions.STOP_OBSERVER_DAILY
        calConfig.data = calParam

        RothyNativeService.getInstance().postMessage(steConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(disConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(speConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(calConfig).then(() => {console.log("success")}).catch(healthError)

        setDailyRealTime(false)
        setStepCountDailyRealTime(undefined)
        setDistanceDailyRealTime(undefined)
        setAvgSpeedDailyRealTime(undefined)
        setCalorieDailyRealTime(undefined)
    }

    const onClickStepStartHistory = () => {
        // 걸음수
        const steParam = new RHParamHistory()
        steParam.quantityType = RHSets.stepCount.history.quantityType
        steParam.startDate = stepCountHistorys[stepCountHistorys.length - 1].endDate
        steParam.startDate = moment(`${steParam.startDate}`).add(1, 'second').format('YY-MM-DDTHH:mm:ss')

        const steConfig = new RNIMessageConfig<RHParamHistory, void>()
        steConfig.action = RNIActions.START_OBSERVER_HISTORY
        steConfig.data = steParam

        // 거리
        const disParam = new RHParamHistory()
        disParam.quantityType = RHSets.distanceWalkingRunning.history.quantityType
        disParam.startDate = distanceHistorys[distanceHistorys.length - 1].endDate
        disParam.startDate = moment(`${disParam.startDate}`).add(1, 'second').format('YY-MM-DDTHH:mm:ss')

        const disConfig = new RNIMessageConfig<RHParamHistory, void>()
        disConfig.action = RNIActions.START_OBSERVER_HISTORY
        disConfig.data = disParam

        // 평균속도(m/s)
        const speParam = new RHParamHistory()
        speParam.quantityType = RHSets.walkingSpeed.history.quantityType
        speParam.startDate = avgSpeedHistorys[avgSpeedHistorys.length - 1].endDate
        speParam.startDate = moment(`${speParam.startDate}`).add(1, 'second').format('YY-MM-DDTHH:mm:ss')

        const speConfig = new RNIMessageConfig<RHParamHistory, void>()
        speConfig.action = RNIActions.START_OBSERVER_HISTORY
        speConfig.data = speParam

        // 칼로리
        const calParam = new RHParamHistory()
        calParam.quantityType = RHSets.activeEnergyBurned.history.quantityType
        calParam.startDate = calorieHistorys[calorieHistorys.length - 1].endDate
        calParam.startDate = moment(`${calParam.startDate}`).add(1, 'second').format('YY-MM-DDTHH:mm:ss')

        const calConfig = new RNIMessageConfig<RHParamHistory, void>()
        calConfig.action = RNIActions.START_OBSERVER_HISTORY
        calConfig.data = calParam

        RothyNativeService.getInstance().postMessage(steConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(disConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(speConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(calConfig).then(() => {console.log("success")}).catch(healthError)

        setHistoryRealTime(true)
    }

    const onClickStepStopHistory = () => {
        // 걸음수
        const steParam = new RHParamHistory()
        steParam.quantityType = RHSets.stepCount.history.quantityType

        const steConfig = new RNIMessageConfig<RHParamHistory, void>()
        steConfig.action = RNIActions.STOP_OBSERVER_HISTORY
        steConfig.data = steParam

        // 거리
        const disParam = new RHParamHistory()
        disParam.quantityType = RHSets.distanceWalkingRunning.history.quantityType

        const disConfig = new RNIMessageConfig<RHParamHistory, void>()
        disConfig.action = RNIActions.STOP_OBSERVER_HISTORY
        disConfig.data = disParam

        // 평균속도(m/s)
        const speParam = new RHParamHistory()
        speParam.quantityType = RHSets.walkingSpeed.history.quantityType

        const speConfig = new RNIMessageConfig<RHParamHistory, void>()
        speConfig.action = RNIActions.STOP_OBSERVER_HISTORY
        speConfig.data = speParam

        // 칼로리
        const calParam = new RHParamHistory()
        calParam.quantityType = RHSets.activeEnergyBurned.history.quantityType

        const calConfig = new RNIMessageConfig<RHParamHistory, void>()
        calConfig.action = RNIActions.STOP_OBSERVER_HISTORY
        calConfig.data = calParam

        RothyNativeService.getInstance().postMessage(steConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(disConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(speConfig).then(() => {console.log("success")}).catch(healthError)
        RothyNativeService.getInstance().postMessage(calConfig).then(() => {console.log("success")}).catch(healthError)

        setHistoryRealTime(false)
        setStepCountHistoryRealTime(undefined)
        setDistanceHistoryRealTime(undefined)
        setAvgSpeedHistoryRealTime(undefined)
        setCalorieHistoryRealTime(undefined)
    }

    const onClickSleepHistory = () => {
        const sleepStartDate = startDate
        const sleepEndDate = endDate

        const sleepParam = new RHParamHistory()
        sleepParam.quantityType = RHSets.sleepAnalysis.history.quantityType
        sleepParam.startDate = sleepStartDate
        sleepParam.endDate = sleepEndDate

        const sleepConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        sleepConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        sleepConfig.data = sleepParam

        const slePromise = RothyNativeService.getInstance().postMessage(sleepConfig)

        const promiseAll = Promise.all([slePromise])
        promiseAll
            .then((values) => {
                const sleResponse = values[0]// as Array<RHHistory>

                setSleepHistorys(sleResponse)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    /**
     * 산소포화도 일자별
    const onClickOxygenDaily = () => {
        // 산소포화도 최소
        const oxygenMinParam = new RHParamDaily()
        oxygenMinParam.quantityType = RHSets.oxygenSaturation.daily.quantityType
        oxygenMinParam.option = RHSets.oxygenSaturation.daily.option.min
        oxygenMinParam.startDate = startDate
        oxygenMinParam.endDate = endDate

        const oxygenMinConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
        oxygenMinConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
        oxygenMinConfig.data = oxygenMinParam

        // 산소포화도 최대
        const oxygenMaxParam = new RHParamDaily()
        oxygenMaxParam.quantityType = RHSets.oxygenSaturation.daily.quantityType
        oxygenMaxParam.option = RHSets.oxygenSaturation.daily.option.max
        oxygenMaxParam.startDate = startDate
        oxygenMaxParam.endDate = endDate

        const oxygenMaxConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
        oxygenMaxConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
        oxygenMaxConfig.data = oxygenMaxParam

        const oxygenMinPromise = RothyNativeService.getInstance().postMessage(oxygenMinConfig)
        const oxygenMaxPromise = RothyNativeService.getInstance().postMessage(oxygenMaxConfig)

        const promiseAll = Promise.all([oxygenMinPromise, oxygenMaxPromise])
        promiseAll
            .then((values) => {
                const temp = new Array<OxygenSaturationNetData>()
                for (let i = 0; i < values[0].length; i++) {
                    const min = values[0][i]
                    const max = values[1][i]

                    const data = new OxygenSaturationNetData()
                    data.msreBeginDtm = min.startDate
                    data.msreEndDtm = min.endDate
                    data.oxygenMin = min.quantity * 100
                    data.oxygenMax = max.quantity * 100

                    temp.push(data)
                }

                setOxygenDailys(temp)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }
     */
    class OxygenSaturationNetData {
        msreBeginDtm?: string
        msreEndDtm?: string
        oxygenMin = 0
        oxygenMax = 0
        oxygenLowDuration = 0
    }

    /**
     * 산소포화도 기록별
     */
    const onClickOxygenHistory = () => {
        const oxygenParam = new RHParamHistory()
        oxygenParam.quantityType = RHSets.oxygenSaturation.history.quantityType
        oxygenParam.startDate = startDate
        oxygenParam.endDate = endDate

        const oxygenConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        oxygenConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        oxygenConfig.data = oxygenParam

        RothyNativeService
            .getInstance()
            .postMessage(oxygenConfig)
            .then((values) => {
                const convertValue = values.map((v): RHQuantity => {
                    // iOS Only
                    v.quantity = v.quantity * 100

                    // Android Only
                    v.quantityMin = v.quantityMin * 100
                    v.quantityMax = v.quantityMax * 100

                    return v
                })

                setOxygenHistorys(convertValue)
            })
            .catch(healthError)
    }

    /**
     * 심박수
     */
    const [heartRateHistorys, setheartRateHistorys] = useState<Array<RHQuantity>>([])
    const onClickHeartRateHistory = () => {
        const heartRateParam = new RHParamHistory()
        heartRateParam.quantityType = RHSets.heartRate.history.quantityType
        heartRateParam.startDate = startDate
        heartRateParam.endDate = endDate

        const heartRateConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        heartRateConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        heartRateConfig.data = heartRateParam

        RothyNativeService
            .getInstance()
            .postMessage(heartRateConfig)
            .then((values) => {
                setheartRateHistorys(values)
            })
            .catch(healthError)
    }

    /**
     * 계단오름
     */
    const [floorsClimbedHistorys, setFloorsClimbedHistorys] = useState<Array<RHQuantity>>([])
    const onClickFloorsClimbedHistory = () => {
        const floorsClimbedParam = new RHParamHistory()
        floorsClimbedParam.quantityType = RHSets.floorsClimbed.history.quantityType
        floorsClimbedParam.startDate = startDate
        floorsClimbedParam.endDate = endDate

        const floorsClimbedConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        floorsClimbedConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        floorsClimbedConfig.data = floorsClimbedParam

        RothyNativeService
            .getInstance()
            .postMessage(floorsClimbedConfig)
            .then((values) => {
                setFloorsClimbedHistorys(values)
            })
            .catch(healthError)
    }

    /**
     * 운동량(Android Only)
     */
    const [exerciseHistorys, setExerciseHistorys] = useState<Array<SHExercise>>([])
    const onClickExerciseHistory = () => {
        const exerciseParam = new RHParamHistory()
        exerciseParam.quantityType = RHSets.exercise.history.quantityType
        exerciseParam.startDate = startDate
        exerciseParam.endDate = endDate

        const exerciseConfig = new RNIMessageConfig<RHParamHistory, Array<SHExercise>>()
        exerciseConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        exerciseConfig.data = exerciseParam

        RothyNativeService
            .getInstance()
            .postMessage(exerciseConfig)
            .then((values) => {
                setExerciseHistorys(values)
            })
            .catch(healthError)
    }

    /**
     * 체질량(Android Only)
     */
    const [weightHistorys, setWeightHistorys] = useState<Array<SHWeightStageData>>([])
    const onClickWeightHistory = () => {
        const weightParam = new RHParamHistory()
        weightParam.quantityType = RHSets.weight.history.quantityType
        weightParam.startDate = startDate
        weightParam.endDate = endDate

        const weightConfig = new RNIMessageConfig<RHParamHistory, Array<SHWeightStageData>>()
        weightConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        weightConfig.data = weightParam

        RothyNativeService
            .getInstance()
            .postMessage(weightConfig)
            .then((values) => {
                setWeightHistorys(values)
            })
            .catch(healthError)
    }

    const createLiStep = (label: string, data: RHQuantity, key?: string) => {
        const s = strUtcToKst(data.startDate)
        const e = strUtcToKst(data.endDate)

        if (key) {
            return <li key={key}>{`${label} :: ${s} - ${e} ${data.quantity}`}</li>
        } else {
            return <li >{`${label} :: ${s} - ${e} ${data.quantity}`}</li>
        }
    }

    const createLiOxygenDaily = (data: OxygenSaturationNetData, key?: string) => {
        if (undefined != data.msreBeginDtm) {
            const s = strUtcToKst(data.msreBeginDtm, 'YY-MM-DD')

            return <li key={key}>{`${s} ${data.oxygenMin}-${data.oxygenMax}`}</li>
        } else {
            return <li key={key}>{`${data.oxygenMin}-${data.oxygenMax}`}</li>
        }
    }

    const createLiDaily = (data: RHQuantity, key?: string) => {
        const s = strUtcToKst(data.startDate, 'YY-MM-DD')
        // const e = moment(`${data.endDate}Z`).tz("Asia/Seoul").format('YY-MM-DD')

        if (key) {
            return <li key={key}>{`${s} ${data.quantity}`}</li>
        } else {
            return <li >{`${s} ${data.quantity}`}</li>
        }
    }

    const createLiHistory = (data: RHQuantity, key?: string) => {
        const s = strUtcToKst(data.startDate)
        // const e = moment(`${data.endDate}Z`).tz("Asia/Seoul").format('YY-MM-DD')

        if (key) {
            return <li key={key}>{`${s} ${data.quantity}`}</li>
        } else {
            return <li >{`${s} ${data.quantity}`}</li>
        }
    }

    const createLiOxygenHistory = (data: RHQuantity, key?: string) => {
        const s = strUtcToKst(data.startDate)
        // const e = moment(`${data.endDate}Z`).tz("Asia/Seoul").format('YY-MM-DD')

        if (key) {
            return <li key={key}>{`${s} ${data.quantityMin} - ${data.quantityMax}`}</li>
        } else {
            return <li >{`${s} ${data.quantity}`}</li>
        }
    }

    const createLiExerciseHistory = (data: SHExercise, key?: string) => {
        const s = data.startTime//strUtcToKst(data.startTime)
        // const e = moment(`${data.endDate}Z`).tz("Asia/Seoul").format('YY-MM-DD')

        if (key) {
            return <li key={key}>{`${s} ${data.count ? data.count : 0.0}`}</li>
        } else {
            return <li >{`${s} ${data.count ? data.count : 0.0}`}</li>
        }
    }

    const createLiWeightHistory = (data: SHWeightStageData, key?: string) => {
        const s = data.start_time//strUtcToKst(data.startTime)
        // const e = moment(`${data.endDate}Z`).tz("Asia/Seoul").format('YY-MM-DD')

        if (key) {
            return <li key={key}>{`${s} ${data.weight ? data.weight : 0.0}`}</li>
        } else {
            return <li >{`${s} ${data.weight ? data.weight : 0.0}`}</li>
        }
    }

    const createLiSleepHistory = (data: RHQuantity, key?: string) => {
        const s = strUtcToKst(data.startDate)
        const e = strUtcToKst(data.endDate)

        if (key) {
            let typeMessage = ""
            switch (data.categoryValue) {
                case RHSleep.inBed:
                    typeMessage = "사용자는 침대에 있습니다."
                    break;
                case RHSleep.aSleepUnspecified:
                    typeMessage = "사용자는 잠들어 있지만 특정 단계를 알 수 없습니다."
                    break;
                case RHSleep.awake:
                    typeMessage = "사용자가 깨어 있습니다."
                    break;
                case RHSleep.asleepCore:
                    typeMessage = "사용자가 얕은 수면 또는 중간 수면 상태입니다."
                    break;
                case RHSleep.asleepDeep:
                    typeMessage = "사용자가 깊은 잠에 빠져 있습니다."
                    break;
                case RHSleep.asleepREM:
                    typeMessage = "사용자가 REM 수면 상태입니다."
                    break;
            }
            return <li key={key}>{`${s} ~ ${e} ${typeMessage}`}</li>
        }
    }

    const strUtcToKst = (strDate: string, outFormat = "YY-MM-DD HH:mm:ss") => {
        if (strDate.indexOf("Z") > -1) {
            return moment(`${strDate}`).tz("Asia/Seoul").format(outFormat)
        } else {
            return moment(`${strDate}Z`).tz("Asia/Seoul").format(outFormat)
        }
    }

    return <>
        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>애플 헬스 지원 여부</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickIsHealthDataAvailable}>조회</div>
            </div>
            <div style={styles.content}>
                {isHealthDataAvailable === undefined ?
                    <div>디바이스가 애플 헬스를 지원하는지 확인합니다.</div>
                    : isHealthDataAvailable ?
                        <div>애플 헬스를 지원하는 기기입니다.</div>
                        : <div>애플 헬스를 지원하지 않는 기기입니다.</div>}
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>애플 헬스 권한</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickRequestHealthAuthorization}>조회</div>
            </div>
            <div style={styles.contentList}>
                {isHealthPermission === undefined ?
                    <div>애플 헬스 데이터 접근을 위한 권한을 요청합니다.</div>
                    : isHealthPermission ?
                        <div>애플 헬스 권항 요청을 하였습니다.</div>
                        : <div>애플 헬스 권항 요청에 실패하였습니다.</div>}
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`걸음 일자별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickStepCountDaily}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {stepCountDailys.length > 0 && (<li style={{background: '#CCCCCC'}}>걸음수</li>)}
                    {stepCountDailys.length > 0 && (
                        stepCountDailys.map((value, key) => {
                            return createLiDaily(value, key.toString())
                        })
                    )}
                    {distanceDailys.length > 0 && (<li style={{background: '#CCCCCC'}}>거리</li>)}
                    {distanceDailys.length > 0 && (
                        distanceDailys.map((value, key) => {
                            return createLiDaily(value, key.toString())
                        })
                    )}
                    {avgSpeedDailys.length > 0 && (<li style={{background: '#CCCCCC'}}>평균 속도(m/s)</li>)}
                    {avgSpeedDailys.length > 0 && (
                        avgSpeedDailys.map((value, key) => {
                            return createLiDaily(value, key.toString())
                        })
                    )}
                    {calorieDailys.length > 0 && (<li style={{background: '#CCCCCC'}}>칼로리</li>)}
                    {calorieDailys.length > 0 && (
                        calorieDailys.map((value, key) => {
                            return createLiDaily(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`걸음 기록별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickStepCountHistory}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {stepCountHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>걸음수</li>)}
                    {stepCountHistorys.length > 0 && (
                        stepCountHistorys.map((value, key) => {
                            return createLiHistory(value, key.toString())
                        })
                    )}
                    {distanceHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>거리</li>)}
                    {distanceHistorys.length > 0 && (
                        distanceHistorys.map((value, key) => {
                            return createLiHistory(value, key.toString())
                        })
                    )}
                    {avgSpeedHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>평균 속도(m/s)</li>)}
                    {avgSpeedHistorys.length > 0 && (
                        avgSpeedHistorys.map((value, key) => {
                            return createLiHistory(value, key.toString())
                        })
                    )}
                    {calorieHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>칼로리</li>)}
                    {calorieHistorys.length > 0 && (
                        calorieHistorys.map((value, key) => {
                            return createLiHistory(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`수면 기록별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickSleepHistory}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {sleepHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>수면</li>)}
                    {sleepHistorys.length > 0 && (
                        sleepHistorys.map((value, key) => {
                            return createLiSleepHistory(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>걸음수 일자별 실시간</h6></div>
                <div style={styles.between} />
                {!isDailyRealTime && (<div style={styles.button} onClick={onClickStepStartDaily}>시작</div>)}
                {isDailyRealTime && (<div style={styles.button} onClick={onClickStepStopDaily}>중지</div>)}
            </div>
            <div style={styles.content}>
                <ul>
                    {stepCountDailyRealTime && (createLiStep('걸음', stepCountDailyRealTime))}
                    {distanceDailyRealTime  && (createLiStep('거리', distanceDailyRealTime))}
                    {avgSpeedDailyRealTime  && (createLiStep('평균속도', avgSpeedDailyRealTime))}
                    {calorieDailyRealTime   && (createLiStep('칼로리', calorieDailyRealTime))}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>걸음수 기록별 실시간</h6></div>
                <div style={styles.between} />
                {!isHistoryRealTime && (<div style={styles.button} onClick={onClickStepStartHistory}>시작</div>)}
                {isHistoryRealTime && (<div style={styles.button} onClick={onClickStepStopHistory}>중지</div>)}
            </div>
            <div style={styles.content}>
                <ul>
                    {stepCountHistoryRealTime && (createLiStep('걸음', stepCountHistoryRealTime))}
                    {distanceHistoryRealTime  && (createLiStep('거리', distanceHistoryRealTime))}
                    {avgSpeedHistoryRealTime  && (createLiStep('평균속도', avgSpeedHistoryRealTime))}
                    {calorieHistoryRealTime   && (createLiStep('칼로리', calorieHistoryRealTime))}
                </ul>
            </div>
        </div>

        {/*<div style={styles.wrap}>*/}
        {/*    <div style={styles.header}>*/}
        {/*        <div style={styles.title}><h6>{`산소포화도 일자별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>*/}
        {/*        <div style={styles.between} />*/}
        {/*        <div style={styles.button} onClick={onClickOxygenDaily}>조회</div>*/}
        {/*    </div>*/}
        {/*    <div style={styles.content}>*/}
        {/*        <ul>*/}
        {/*            {oxygenDailys.length > 0 && (<li style={{background: '#CCCCCC'}}>산소포화도</li>)}*/}
        {/*            {oxygenDailys.length > 0 && (*/}
        {/*                oxygenDailys.map((value, key) => {*/}
        {/*                    return createLiOxygenDaily(value, key.toString())*/}
        {/*                })*/}
        {/*            )}*/}
        {/*        </ul>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`산소포화도 기록별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickOxygenHistory}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {oxygenHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>산소포화도</li>)}
                    {oxygenHistorys.length > 0 && (
                        oxygenHistorys.map((value, key) => {
                            return createLiOxygenHistory(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`심박수 기록별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickHeartRateHistory}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {heartRateHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>심박수</li>)}
                    {heartRateHistorys.length > 0 && (
                        heartRateHistorys.map((value, key) => {
                            return createLiHistory(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`계단오름 기록별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickFloorsClimbedHistory}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {floorsClimbedHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>계단오름</li>)}
                    {floorsClimbedHistorys.length > 0 && (
                        floorsClimbedHistorys.map((value, key) => {
                            return createLiHistory(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`운동량(Android Only) 기록별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickExerciseHistory}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {exerciseHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>운동량</li>)}
                    {exerciseHistorys.length > 0 && (
                        exerciseHistorys.map((value, key) => {
                            return createLiExerciseHistory(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>

        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>{`체질량(Android Only) 기록별 조회(${strUtcToKst(startDate, 'YY-MM-DD')})`}</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onClickWeightHistory}>조회</div>
            </div>
            <div style={styles.content}>
                <ul>
                    {weightHistorys.length > 0 && (<li style={{background: '#CCCCCC'}}>체질량</li>)}
                    {weightHistorys.length > 0 && (
                        weightHistorys.map((value, key) => {
                            return createLiWeightHistory(value, key.toString())
                        })
                    )}
                </ul>
            </div>
        </div>
    </>
}

const styles = {
    wrap: {
        marginTop: "10px",
    } as CSSProperties,
    header: {
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    title: {
        alignSelf: "center"
    } as CSSProperties,
    between: {
        flex: 1,
    } as CSSProperties,
    button: {
        background: "#FFFF00",
        alignSelf: "center",
        padding: "10px 10px",
    } as CSSProperties,
    content: {
        padding: "0px 10px"
    } as CSSProperties,
    contentList: {
        padding: "0px 10px",
        maxHeight: "200px",
    } as CSSProperties,
}

export default RothyHealthSample;
