import React from 'react'
import moment from "moment"
import {CountYearData, WeightCountData} from '@/service/WeightService'
import {SvcUserData} from "@/service/UserService";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import {USER_INFO} from "@/common/Constant";

const WeightCountYearChart = (props:WeightCountData[]) => {

    const userInfo: SvcUserData  = StringUtil.jsonString2ObjectWithMap<SvcUserData>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
    const userSignupDt = userInfo?.signupDt

    const setYearCalendar = () => {
        const result = []
        for(const [key,value] of Object.entries(props)){
            result.push(<div key={key}>
                            <div className={'month c1_r cl-gray04'}>
                                <p>{value.x}</p>
                            </div>
                            {
                                setDiv(value.msreDtmListOfYear)
                            }
                        </div>)
        }

        return result
    }

    const setDiv = (msreDtmListOfYear:CountYearData[]) => {
        const result = []
        for(const list of msreDtmListOfYear){
            const index = list.msreDt
            const signUpBeforeChk = moment(moment(userSignupDt).format('YYYY-MM-DD')).isSameOrBefore(list.msreDt)
            const afterChk = moment(moment().format('YYYY-MM-DD')).isAfter(list.msreDt)
            let listColor = ''
            if(signUpBeforeChk){
                if (afterChk){
                    switch (list.count){
                        case 0:
                            listColor = ''
                            break
                        case 1:
                            listColor = 'one-time'
                            break
                        case 2:
                            listColor = 'two-time'
                            break
                        default:
                            listColor = 'three-time'
                            break
                    }
                } else {
                    const todayChk = moment(moment().format('YYYY-MM-DD')).isSame(list.msreDt)
                    if(todayChk){
                        listColor = 'today'
                    } else {
                        listColor = 'future'
                    }
                }
            } else {
                listColor = 'before'
            }

            result.push(<div key={index} className={'count-div '+listColor}></div>)
        }
        return result
    }

    return <>
        <div className={'position-relative'}>
            <div>
                <p className={'c2_r cl-gray04 position-absolute'} style={{top: '90px'}}>6일</p>
                <p className={'c2_r cl-gray04 position-absolute'} style={{top: '176px'}}>13일</p>
                <p className={'c2_r cl-gray04 position-absolute'} style={{top: '260px'}}>20일</p>
                <p className={'c2_r cl-gray04 position-absolute'} style={{top: '344px'}}>27일</p>
            </div>
            <div className={'count-year'}>
                {setYearCalendar()}
            </div>
            <div className={'flex-column position-absolute'} style={{top:'430px', right:'0px', minWidth: '70px'}}>
                <div className={'flex mb-8px'}><div className={'count-div one-time'}></div><p className={'c1_r count-described'}>1회 측정</p></div>
                <div className={'flex mb-8px'}><div className={'count-div two-time'}></div><p className={'c1_r count-described'}>2회 측정</p></div>
                <div className={'flex mb-8px'}><div className={'count-div three-time'}></div><p className={'c1_r count-described'}>3회 이상 측정</p></div>
                <div className={'flex mb-8px'}><div className={'count-div'}></div><p className={'c1_r count-described'}>미측정</p></div>
            </div>
        </div>
    </>
}

export default WeightCountYearChart
