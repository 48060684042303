import React, {useCallback, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import DialogHeader from "@/view/layout/DialogHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import Icon from "@/view/common/component/Icon";
import LottieAnimation from "@/view/common/component/LottieAnimation";
import DvicConnecting from "@/assets/lottie/lottie_device-connect_connecting_step.json"
import DvicConnected from "@/assets/lottie/lottie_device-connect_complete_100x100.json"
import {ICScanDeviceInfo, ICUserInfo} from "@/service/rothynative/RNISmartScale";
import RothyNativeService, {
    RNIActions,
    RNIError,
    RNIEvents,
    RNIMessageConfig
} from "@/service/rothynative/RothyNativeService";
import * as RNISmartScale from "@/service/rothynative/RNISmartScale";
import BottomButton from "@/view/common/component/button/BottomButton";
import UserDvicService, {UserDvicReq} from "@/service/UserDvicService";
import {DefaultResult} from "@/service/RothyClient";
import StringUtil from "@/common/StringUtil";
import {DEFAULT_DVIC_NM, DVIC_TYPE} from "@/common/Constant";
import Popup from "@/view/common/popup/Popup";
import UserDvicAddUsedView from "@/view/userDvic/UserDvicAddUsedView";
import UserDvicAddUsedMineView from "@/view/userDvic/UserDvicAddUsedMineView";





interface DviceAddProps {
    onClose: () => void
}

const UserDvicAddView: React.FC<DviceAddProps> = (props:DviceAddProps) => {
    const closeView = useCallback(() => props.onClose(), [props.onClose])
    let userDvicReq:UserDvicReq ={}

    const [isInitMgr, setInitMgr] = useState(false)
    const [isRetry,setIsRetry] = useState(false)
    const [isScaning, setScaning] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState<ICScanDeviceInfo>()
    const [checkId,setCheckId] = useState<NodeJS.Timeout|undefined>()
    const [isConnected, setIsConnected] = useState(false)
    const [isUsed, setIsUsed] = useState(false)
    const [isMine,setIsMine] = useState(false)
    const [mineDvicList,setMineDvicList] = useState<string[]|undefined>()

    const {refetch: checkRegisterPossible} = useQuery<DefaultResult, AxiosError>(
        ['checkRegisterPossible'],
        ()=> UserDvicService.checkRegisterPossible(userDvicReq),
        {
            onSuccess: (v: DefaultResult) => checkOwner(v)
        }
    )

    const {refetch: checkMine} = useQuery<DefaultResult, AxiosError>(
        ['checkMine'],
        ()=> UserDvicService.selectUserDvic(userDvicReq),
        {
            onSuccess: (v: DefaultResult) => alertMsg(v)
        }
    )

    const {refetch:insertUserDvic} = useQuery<DefaultResult,AxiosError>(
        ['insertUserDvic'],
        ()=> UserDvicService.insertUserDvic(userDvicReq),
        {
            onSuccess : (v:DefaultResult) => connectComplete(v)
        }

    )

    const insertUsedUserDvic = () => {
        userDvicReq = {dvicNickNm:DEFAULT_DVIC_NM.SCALE,macAddr:deviceInfo?.macAddr,dvicType:DVIC_TYPE.SCALE}
        void insertUserDvic()
        closePopup()
    }

    const handleOverlayClick = () => {
        console.debug("handleOverlayClick001")
        setMineDvicList(undefined)
        closeView()
        console.debug("handleOverlayClick002")
    }
    const cancleExit = () => {
        console.debug("기기 등록 없이 취소001")
        handleOverlayClick()
        console.debug("기기 등록 없이 취소002")
    }
    const connectComplete = (data:DefaultResult) => {
        if(data){
            setIsConnected(true)
        }else{
            console.debug("기기등록 오류")
        }

    }
    const retryConnect = () => {
        console.debug("retryConnect 0001")
        setIsRetry(true)
        onScanDevice()
        console.debug("retryConnect 0002")
        setCheckId(undefined)
        console.debug("retryConnect 0003")
        setCheckId(setTimeout(checkConnect, 15000, 1))
        console.debug("retryConnect 0001")
    }

    const alertMsg = (data:DefaultResult) => {
        console.debug("alertMsg 001 ")
        console.debug(StringUtil.stringify(data))

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if(data !== undefined && data.data.length> 0){
            console.debug("alertMsg 002 ".concat("기 등록 기기"))
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            console.debug(data.data.length)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if(data !== undefined && data.data[0] !== undefined && data.data[0].macAddr !== undefined && mineDvicList !== undefined)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
                mineDvicList.push(data.data[0].macAddr)
            else
                console.debug("push 못함")
            setIsMine(true)
        }else{
            console.debug("alertMsg 002 ".concat("다른사람 기기"))
            if(deviceInfo !== undefined && deviceInfo.macAddr !== undefined && mineDvicList !== undefined)
                mineDvicList.push(deviceInfo.macAddr)
            else
                console.debug("push 못함")
            setIsUsed(true)
        }
    }


    const checkOwner = (data:DefaultResult) => {
        console.debug("checkOwner 001 ")
        console.debug(StringUtil.stringify(data))
        if(data.data){
            console.debug("checkOwner 002 ".concat("등록 가능"))
            userDvicReq = {dvicNickNm:DEFAULT_DVIC_NM.SCALE,macAddr:deviceInfo?.macAddr,dvicType:DVIC_TYPE.SCALE}
            void insertUserDvic()

        }else{
            console.debug("checkOwner 002 ".concat("등록 불가능"))
            userDvicReq.macAddr = deviceInfo?.macAddr
            void checkMine()
        }


    }

    const registerScale = () => {
        console.debug("registerScale 001 ")
        userDvicReq = {macAddr:deviceInfo?.macAddr}
        void checkRegisterPossible()
    }


    const checkScaleHistory = () => {
        if(deviceInfo) {
            console.debug("macAddr : ".concat(deviceInfo.macAddr))
            console.debug("clearTimeout : ")
            console.debug(checkId)
            clearTimeout(checkId)
            RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_IC_SCAN_RESULT)
            registerScale()
            console.debug("checkScaleHistory End")
        }else
            console.debug("체중계 정보 없음")
    }






    const checkConnect = (flag:number) => {  // 0 최초 진입 초기화, 1 재연결 시도 , 2 2차 재연결 시도
        console.debug("checkConnect ######001")

        onStopScan()
        switch(flag) {
            case 0 :setInitMgr(true)
                setIsRetry(false)
                break
            case 1 :setInitMgr(false)
                setIsRetry(false)
                break
            case 2 :setInitMgr(false)
                setIsRetry(true)
                break
        }
        setInitMgr(false)
        console.debug("checkConnect ######002")
    }

    const onStopScan = () => {

        console.debug("scan 정지 ####### 001")
        if(!isScaning) {
            console.debug("scan중 아님")
            return
        }
        setScaning(false)

        RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_IC_SCAN_RESULT)

        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.ON_IC_STOP_SCAN
        console.debug("scan 정지 ####### 002")
        RothyNativeService
            .getInstance()
            .postMessage(config)
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
        console.debug("scan 정지 ####### 003")
    }

    const addListener = () => {
        console.debug("addListener 001")
        setScaning(true)

        // 체중계 기기 검색
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_IC_SCAN_RESULT, (response: ICScanDeviceInfo) => {
            console.debug("Listener ####### 001")
            setTimer()
            if(mineDvicList){
                if(response !== undefined && mineDvicList.includes(response.macAddr)) {
                    //console.debug("이미 등록된 기기...무시....")

                    return
                }else{
                    console.debug("이미 등록된 기기...무시.... 정보 부족??????")
                    console.debug(StringUtil.stringify(response))
                    console.debug(StringUtil.stringify(mineDvicList))
                }
            }else{
                console.debug("listener$$$$$$")
                console.debug(StringUtil.stringify(mineDvicList))
            }

            setDeviceInfo(response)
            onStopScan()

            console.debug(response)
            console.debug("Listener ######002")

        })
        console.debug("addListener 002")
    }

    const onScanDevice = () => {

        addListener()
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.ON_IC_START_SCAN

        console.debug("scan 001")
        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setInitMgr(true)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
        console.debug("scan 002")
    }

    const onInitMgr = () => {
        console.debug("onInitMgr ######001")
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.IC_INIT_MGR

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setInitMgr(true)
                console.debug("onInitMgr ######002")
                onScanDevice()
                console.debug("onInitMgr ######003")
                setTimer()
                console.debug(checkId)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onUpdateUserInfo = () => {
        // 사용자 프로필 변경 이후 SDK 초기화를 해주어야한다.

        console.debug("onUpdateUserInfo ######001")
        const param = new ICUserInfo()
        param.userIndex = 1;


        const config = new RNIMessageConfig<RNISmartScale.ICUserInfo, void>()
        config.action = RNIActions.IC_UPDATE_USER_INFO
        config.data = param

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.debug("onUpdateUserInfo ######002")
                onInitMgr()
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const closePopup = () => {
        setIsMine(false)
        setIsUsed(false)
    }
    const connectOtherDvic = () => {
        closePopup()
        checkConnect(2)
        onScanDevice()
    }

    const setTimer = () => {
        if(checkId == undefined) {
            console.debug("타이머 설정")
            setCheckId(setTimeout(checkConnect, 15000, 1))
        }
    }

    useEffect(()=> {
        onUpdateUserInfo()
        setMineDvicList([])
        return  () =>{
            RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_IC_SCAN_RESULT)
        }
    },[])

    useEffect( () => {
        checkScaleHistory()
    },[deviceInfo])


    console.debug(StringUtil.stringify(props))
    return (
        <><DialogHeader  onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
            </div>
            {isInitMgr && !isRetry && !isConnected &&
                (<> <div>
                    <p className={'h5_m  pt-22px ml-24px'}>연결 준비 중입니다.</p>
                    <p className={'h5_m  ml-24px'}>체중계에 올라가 주세요</p>
                </div>

                    <div className={'h-80vh flex'} >
                        <div id={'scale-bg-l'} className={'flex'}>
                            <div className={'h-fit-content position-absolute'} >
                                <LottieAnimation animationData={DvicConnecting}
                                                 lottieStyle={{width: '196px', height: '196px'}}/>
                            </div>
                            <Icon iconClass={'scale-bg-l w-200px h-200px'} />
                        </div>
                    </div></>)
            }

            {!isInitMgr && !isRetry && !isConnected &&
                (<> <div>
                    <p className={'h5_m  pt-22px ml-24px'}>연결이 잘 되지 않네요</p>
                    <p className={'h5_m  ml-24px'}>다시 해주시겠어요?</p>
                </div>

                    <div className={'h-80vh flex'} >
                        <div id={'scale-bg-l'} className={'flex'}>
                            <Icon iconClass={'scale-not-connect-bg w-200px h-200px'} />
                        </div>
                    </div>
                    <BottomButton  wrapperClassName={'button-wrapper'} title='다시 연결하기' disabled={false}   onClick={retryConnect} />
                </>)
            }

            {isRetry && !isConnected &&
                (<> <div>
                    <p className={'h5_m  pt-22px ml-24px'}>체중계에서 내려갔다가</p>
                    <p className={'h5_m  ml-24px'}>다시 올라가 주세요</p>
                </div>

                    <div className={'h-80vh flex'} >
                        <div id={'scale-bg-l'} className={'flex'}>
                            <Icon iconClass={'scale-foot-bg w-200px h-200px'} />
                        </div>
                    </div>

                </>)
            }

            {isConnected &&
                (<>
                    <div className={'flex h-fit-content  justify-center align-center w-100p h-60vh'} >
                        <div className={'align-center'}>
                            <div className={'justify-center align-center flex'} >
                                <LottieAnimation animationData={DvicConnected} loop={false}
                                                 lottieStyle={{width: '100px', height: '100px'}}/>
                            </div>
                            <div className={'justify-center align-center w-100p mt-32px'}>
                                <div className={'h5_m  flex-column align-center'}>연결이 완료되었습니다.</div>
                                <div className={'h5_m  flex-column align-center'}>더 편하게 체성분을 기록해 보세요</div>
                            </div>
                            <div className={'justify-center align-center w-100p mt-16px'}>
                                <div className={'b2_r  flex-column align-center'}>체성분을 측정하고 관리하기 위해</div>
                                <div className={'b2_r  flex-column align-center'}>ROTHY 앱을 켜고 체중계에 올라가주세요.</div>
                            </div>

                        </div>

                    </div>
                    <BottomButton  title='완료' disabled={false}   onClick={handleOverlayClick} />
                </>)
            }
            <Popup className='bg-cl-white'  content={<UserDvicAddUsedView onClickCancle={connectOtherDvic} onClickOk={insertUsedUserDvic} />} isOpen={isUsed}  />
            <Popup className='bg-cl-white'  content={<UserDvicAddUsedMineView  onClickCancle={cancleExit}  onClickOk={connectOtherDvic} />} isOpen={isMine}   />
        </>
    )
}

export default UserDvicAddView