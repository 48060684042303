import React, {useEffect, useRef, useState} from 'react'
import moment from "moment";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Chart} from "react-chartjs-2";
import {Skeleton} from "@mui/material";
import SleepService, {
    BodyEnergyData,
    SleepBodyEnergyRes,
    SleepReportReq,
} from "@/service/SleepService"
import StringUtil from "@/common/StringUtil";

import {ChartDataSet, getLabelData, ReportPropsConfig} from "@/common/ReportUtil";
import NumberUtil from "@/common/NumberUtil";
import Icon from "@/view/common/component/Icon";

type BodyEnergyYearInform = {
    month: string,
    tooltipMsg: string,
}
const BodyEnergy =(props:ReportPropsConfig) => {
    const [bodyEnergyMsg, setBodyEnergyMsg] = useState<string | undefined>(undefined)
    const [avgBodyEnergy, setAvgBodyEnergy] = useState<string | undefined>(undefined)
    const [weekSleepAverage, setWeekSleepAverage] = useState<string[] | undefined>([])
    const [weekWakeUpAverage, setWeekWakeUpAverage] = useState<string[] | undefined>([])
    const [sleepReportReq, setSleepReportReq] = useState<SleepReportReq>( new SleepReportReq ('','','','','',0,''))
    const [data, setData] = useState<{labels:string[], datasets:ChartDataSet[]}>({labels:[], datasets:[]})
    const [options, setOptions] = useState({})
    const [dataForYear, setDataForYear] = useState<{labels:string[], datasets:ChartDataSet[]}>({labels:[], datasets:[]})
    const [optionsForYear, setOptionsForYear] = useState({})
    const [resourceData, setResourceData] = useState<SleepBodyEnergyRes | undefined>(undefined)

    const [bodyEnergyYearInform, setBodyEnergyYearInform] = useState<BodyEnergyYearInform[] | undefined>(undefined)
    const [selectedBodyEnergyYearInform, setSelectedBodyEnergyYearInform] = useState<number>(0)
    const [prevIconDisable, setPrevIconDisable] = useState(false)
    const [nextIconDisable, setNextIconDisable] = useState(false)

    let maxValue = 60*60*5*2
    let minValue:number|undefined = undefined

    const chartDivRef = useRef<HTMLDivElement>(null)

    const reportHeight = props.periodType==='YEAR'?170:270
    const divWidth = chartDivRef.current ? chartDivRef.current.clientWidth : window.innerWidth - 48
    const reportWidth = divWidth

 const {refetch: getSleepRecordBodyEnergy} = useQuery<SleepBodyEnergyRes, AxiosError>(
        ['getSleepRecordBodyEnergy'],
        () => SleepService.getSleepRecordBodyEnergy(sleepReportReq),
        {
            onSuccess: (v: SleepBodyEnergyRes) => success(v)
        }
    )

    const success = (v:SleepBodyEnergyRes) => {
        console.log(v)
        setResourceData(v)
    }

    let pointRadius = 9
    let barResizePercentage = 0.4
    const thisMonth = Number(moment().format('MM').toString())
    const thisYear = Number(moment().format('YYYY').toString())
    const createChart = (v:SleepBodyEnergyRes) => {
        const labels:string[] = []
        const labelData1:Array<[number | undefined, number | undefined]> = []
        const labelData2:Array<[number | undefined, number | undefined]> = []
        const labelData3:any[] = []
        const labelData4:any[] = []
        const labelData5:any[] = []
        const labelData6:number[] = []
        updateChartDetail(props.periodType)
        updateAverage(v)
        v.data.bodyEnergyList.forEach(d => {
            labels.push(getLabelData(d, props.periodType))
            labelData1.push(getLabelData1(d))
            labelData2.push(getLabelData2(d))
            labelData3.push(getLabelData3(d))
            labelData4.push(getLabelData4(d))
            labelData5.push(getLabelData5(d))
            labelData6.push(getLabelData6(d))
        })
        setBodyEnergyYearInform(getLabelData7(v))

        updateData(labels, labelData1, labelData2, labelData3, labelData4, labelData5)
        updateOptions()

        updateDataForYear(labels, labelData6)
        updateOptionsForYear()
    }

    const updateChartDetail = (_periodType:string) => {
        const type = _periodType
        switch (type) {
            case 'WEEK':
                pointRadius = 6
                barResizePercentage = 0.3
                break
            case 'MONTH':
                pointRadius = 3
                barResizePercentage = 0.6
                break
            case 'YEAR':
                pointRadius = 3
                barResizePercentage = 0.3
                break
        }
    }

    const getLabelData1 = (d:BodyEnergyData): [number| undefined, number| undefined] => {
        let startValue: number| undefined  = undefined
        let endValue: number| undefined = undefined
        let addMaxValue = 0
        let diffMinValue = 0

        if(d.SLEEP_DTM && d.SLEEP_DTM !== ''){
            startValue = moment(d.WAKEUP_DTM).diff(d.MSRE_DTM, 'seconds')
            endValue = d.SLEEP_DTM>d.ENERGY_NONE_TIME?moment(d.ENERGY_NONE_TIME).diff(d.MSRE_DTM, 'seconds'):moment(d.SLEEP_DTM).diff(d.MSRE_DTM, 'seconds')
        }
        addMaxValue = 60*60-moment(d.SLEEP_DTM).diff(d.MSRE_DTM, 'seconds')%(60*60)
        maxValue = NumberUtil.replaceBiggerNumber(maxValue, moment(d.SLEEP_DTM).diff(d.MSRE_DTM, 'seconds')+addMaxValue)
        diffMinValue = moment(d.WAKEUP_DTM).diff(d.MSRE_DTM, 'seconds')%(60*60)
        if(d.WAKEUP_DTM && d.WAKEUP_DTM !== ''){
            if(minValue === undefined){
                minValue = moment(d.WAKEUP_DTM).diff(d.MSRE_DTM, 'seconds')-diffMinValue
            }else{
                minValue = NumberUtil.replaceSmallerNumber(minValue, moment(d.WAKEUP_DTM).diff(d.MSRE_DTM, 'seconds')-diffMinValue)
            }
        }
        return [startValue,  endValue]
    }

    const getLabelData2 = (d:BodyEnergyData): [number| undefined, number| undefined] => {
        let startValue: number| undefined  = undefined
        let endValue: number| undefined = undefined
        let _value: number| undefined = undefined
        if(d.SLEEP_DTM>d.ENERGY_NONE_TIME){
            startValue = moment(d.ENERGY_NONE_TIME).diff(d.MSRE_DTM, 'seconds')
            endValue = moment(d.SLEEP_DTM).diff(d.MSRE_DTM, 'seconds')
            _value = endValue-startValue
        }
        return [0,_value]
    }

    const getLabelData3 = (d:BodyEnergyData): number| undefined  => {
        let _value: number| undefined  = undefined
        if(d.SLEEP_DTM>d.ENERGY_NONE_TIME){
            _value = moment(d.ENERGY_NONE_TIME).diff(d.MSRE_DTM, 'seconds')
        }
        return _value
    }

    const getLabelData4 = (d:BodyEnergyData) => {
        let _value: number| undefined  = undefined
        if(d.SLEEP_DTM<=d.ENERGY_NONE_TIME){
            _value = moment(d.SLEEP_DTM).diff(d.MSRE_DTM, 'seconds')
        }
        return _value
    }

    const getLabelData5 = (d:BodyEnergyData) => {
        let _value: number| undefined  = undefined
        if((!d.SLEEP_DTM || d.SLEEP_DTM === '') && d.WAKEUP_DTM){
            _value = moment(d.WAKEUP_DTM).diff(d.MSRE_DTM, 'seconds')
        }
        return _value
    }

    const getLabelData6 = (d:BodyEnergyData) => {
        let _value: number| undefined  = undefined
        _value = d.WAKEUP_BODY_ENERGY
        return _value
    }

    const getLabelData7 = (v: SleepBodyEnergyRes) => {
        const _value:BodyEnergyYearInform[] = []
        if(props.periodType !== 'YEAR')
            return _value

        v.data.bodyEnergyList.forEach(d => {
            if(thisYear > Number(props.year) || (thisYear === Number(props.year) && thisMonth >= d.ROW_NUM)){
                const _msg: string = d.TOOLTIP_MSG.indexOf('|')>0?d.TOOLTIP_MSG.split('|')[1]:d.TOOLTIP_MSG
                _value.push({
                    month: String(d.ROW_NUM)+'월',
                    tooltipMsg: _msg,
                })
            }
        })

        return _value.reverse()
    }

    const updateAverage = (v: SleepBodyEnergyRes) => {
        setBodyEnergyMsg(v.data.bodyEnergyMsg)
        setAvgBodyEnergy(String(v.data.avgBodyEnergy))

        const _weekSleepAverage:string[] = [];
        const _weekWakeUpAverage:string[] = [];

        if(props.periodType === 'WEEK'){
            v.data.bodyEnergyList.forEach(d => {
                _weekSleepAverage.push(d.SLEEP_BODY_ENERGY>-1?String(d.SLEEP_BODY_ENERGY)+'%':'-')
                _weekWakeUpAverage.push(d.WAKEUP_BODY_ENERGY>-1?String(d.WAKEUP_BODY_ENERGY)+'%':'-')
            })
            setWeekSleepAverage(_weekSleepAverage)
            setWeekWakeUpAverage(_weekWakeUpAverage)
        }

    }

    const updateData = (_labels:string[], labelData1:Array<[number | undefined, number | undefined]>, labelData2:Array<[number | undefined, number | undefined]>
                        , labelData3:any[], labelData4:any[], labelData5:any[]) => {
        setData({
            labels: _labels,
            datasets: [
                {
                    type: 'bar' as const,
                    label: '활동시간',
                    backgroundColor: '#24CBAA',
                    data: labelData1,
                    barPercentage: barResizePercentage,
                    borderSkipped: false,
                    order:2,
                },
                {
                    type: 'bar' as const,
                    label: '에너지없이활동한시간',
                    data: labelData2,
                    backgroundColor: '#AAAAAA',
                    barPercentage: barResizePercentage,
                    borderSkipped: false,
                    order:2,
                },
                {
                    type: 'line' as const,
                    label: '에너지0%되는시간(취침할때 에너지 < 0)',
                    backgroundColor: 'red',
                    //borderColor: '#CDCDCD',
                    borderWidth: 0,
                    fill: false,
                    data: labelData3,
                    order:1,
                    pointStyle:'rect',
                    radius: pointRadius,
                    hidden: true,
                },
                {
                    type: 'line' as const,
                    label: '취침시간(취침할때 에너지 > 0)',
                    backgroundColor: 'blue',
                    //borderColor: '#CDCDCD',
                    borderWidth: 0,
                    fill: false,
                    data: labelData4,
                    order:1,
                    pointStyle:'rect',
                    radius: pointRadius,
                    hidden: true,
                },
                {
                    type: 'line' as const,
                    label: '일어난시간(해당일에 취침시간 데이터 없을때)',
                    backgroundColor: '#24CBAA',
                    //borderColor: '#CDCDCD',
                    borderWidth: 0,
                    fill: false,
                    data: labelData5,
                    order:1,
                    pointStyle:'rect',
                    radius: pointRadius,
                },
            ],
        })
    }

    const updateOptions = () => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                datalabels: false,
                legend: {
                    display: false,
                    position: 'right',

                },
                tooltip: false,
            },
            scales: {
                x: {
                    stacked: true,
                    position: 'top',
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxRotation: 0,
                        autoSkip: false,
                        font: {
                            size: 12
                        }
                    },
                },
                y: {
                    stacked: true,
                    reverse: true,
                    grid: {
                        drawBorder: false,
                        display: false,
                    },
                    max: maxValue,
                    min: minValue?minValue:0,
                    ticks: {
                        stepSize: maxValue/2, //5시간
                        //stepSize: 60*60*5, //5시간
                        callback: function (value: number) {
                            let result:string[] = []
                            const defaultDt = moment('2023-01-02')
                            const _value = moment(defaultDt).add(value, 'second')
                            if(value === 0){
                                result = [moment(defaultDt).format('hh')+':'+moment(defaultDt).format('mm'), moment(defaultDt).format('A')]
                            }else  {
                                result = [moment(_value).format('hh')+':'+moment(_value).format('mm'), moment(_value).format('A')]
                            }
                            return result
                        }
                    },
                },

            },
        })
    }

    const updateDataForYear = (_labels:string[], labelData6:number[]) => {
        setDataForYear({
            labels: _labels,
            datasets: [
                {
                    type: 'bar' as const,
                    label: '이번주',
                    backgroundColor: '#24CBAA',
                    data: labelData6,
                    barPercentage: barResizePercentage,
                    borderSkipped: false,
                    order:2,
                }
            ],
        })
    }

    const updateOptionsForYear = () => {
        setOptionsForYear({
            animation: false,
            responsive: false,
            plugins: {
                datalabels: false,
                legend: {
                    display: false,
                    position: 'right',

                },
                tooltip: false,
            },
            scales: {
                x: {
                    stacked: true,
                    position: 'bottom',
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxRotation: 0,
                        autoSkip: false,
                        font: {
                            size: 12
                        }
                    },
                },
                y: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    max: 100,
                    ticks: {
                        stepSize: 50, //5시간
                        callback: function (value: number) {
                            return String(value)+'%'
                        }
                    },
                },
            },
        })
    }

    useEffect(()=>{
        if(resourceData){
            createChart(resourceData)
        }
    },[resourceData])

    useEffect(()=>{
        if(props){
            const _sleepReportReq = new SleepReportReq (
                props.fromDate,
                props.month,
                props.periodType,
                '',
                props.toDate,
                props.userId,
                props.year,
            )
            setSleepReportReq(_sleepReportReq)

        }

        setSelectedBodyEnergyYearInform(0)
    },[props])

    useEffect(() => {
        if(sleepReportReq && StringUtil.isNotEmpty(sleepReportReq.periodType)){
            getSleepRecordBodyEnergy()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[sleepReportReq])

    const handlePrev = () => {
        const prev = Number(selectedBodyEnergyYearInform)+1
        setSelectedBodyEnergyYearInform(prev)

    }

    const handleNext = () => {
        const next = Number(selectedBodyEnergyYearInform)-1
        setSelectedBodyEnergyYearInform(next)
    }

    useEffect(() => {
        if(bodyEnergyYearInform){
            if(selectedBodyEnergyYearInform === 0){
                setNextIconDisable(true)
            }else{
                setNextIconDisable(false)
            }

            if(bodyEnergyYearInform?.length === selectedBodyEnergyYearInform+1){
                setPrevIconDisable(true)
            }else{
                setPrevIconDisable(false)
            }
        }
    }, [selectedBodyEnergyYearInform, bodyEnergyYearInform])
  return (
                <>
                    <div className={'s_title_1_m mt-56px'} style={{lineHeight:'1.2'}}>
                        <div className={'flex align-center justify-between walk-far'}>
                            <div>자면서 채운<br/>BODY ENERGY는?</div>
                        </div>
                        <div className={'gray-bar-thin mt-8px'}></div>
                        <div className={'b2_l mt-12px'}>{bodyEnergyMsg}</div>
                    </div>
                    {
                        props.periodType === 'MONTH' || props.periodType === 'YEAR'?
                            <>
                                <div className={'flex mt-39px cl-gray02'}>
                                    <div className={'flex-column mr-39px'}>
                                        <div className={'b2_l '}>평균</div>
                                        {
                                            avgBodyEnergy && avgBodyEnergy !== '0' ?
                                                <div className={'flex pt-3px'} style={{alignItems: 'baseline'}}>
                                                    <div className={'h4_m pr-2px'}>{avgBodyEnergy}</div>
                                                    <div className={'b1_l'}>%</div>
                                                </div>
                                                :
                                                <div className={'flex pt-3px'} style={{alignItems: 'baseline'}}>
                                                    <div className={'h4_m pr-2px'}>계산중!</div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </>
                        :
                            <></>
                    }
                    <div className={'w-100p'} ref={chartDivRef}>
                    {
                        data ?
                            <>
                                {
                                    props.periodType === 'YEAR' ?
                                        <div className={'body-energy-year-inform mt-10px'}>
                                            <div className={'body-energy-year-inform-header flex justify-between align-center'}>
                                                <div className={'b2_r pl-14px cl-gray01'}>{bodyEnergyYearInform?.map((_data, index) => {
                                                    if(index === selectedBodyEnergyYearInform)
                                                        return <div className={'flex'} key={index}>
                                                                    <div className={'mr-8px'}>{_data.month}</div>
                                                                    <div className={'mr-8px'}>|</div>
                                                                    <div>{_data.tooltipMsg}</div>
                                                                </div>
                                                })}</div>
                                                <div className={'flex'}>
                                                {
                                                    prevIconDisable?
                                                        <div className={'b2_r pr-14px'}>
                                                            <Icon iconClass={'deactive-round-prev w-24px h-24px'} />
                                                        </div>
                                                     :
                                                        <div className={'b2_r pr-14px'} onClick={handlePrev}>
                                                            <Icon iconClass={'active-round-prev w-24px h-24px'} />
                                                        </div>

                                                }

                                                {
                                                    nextIconDisable ?
                                                        <div className={'b2_r pr-10px'}>
                                                            <Icon iconClass={'deactive-round-next w-24px h-24px'}/>
                                                        </div>
                                                        :
                                                        <div className={'b2_r pr-10px'} onClick={handleNext}>
                                                            <Icon iconClass={'active-round-next w-24px h-24px'}/>
                                                        </div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                <div className={'mt-26px'} style={{position: 'relative', zIndex: '2', width:String(reportWidth)+'px'}} >
                                    {
                                        props.periodType === 'WEEK' || props.periodType === 'MONTH' ?
                                            <Chart type='bar' data={data} options={options}
                                                   height={String(reportHeight) + 'px'}
                                                   width={String(reportWidth) + 'px'}/>
                                            :
                                            <Chart type='bar' data={dataForYear} options={optionsForYear}
                                                   height={String(reportHeight) + 'px'}
                                                   width={String(reportWidth) + 'px'}/>
                                    }
                                </div>
                                {
                                    props.periodType === 'WEEK' || props.periodType === 'MONTH' ?
                                        <div className={'mt-18px flex'} style={{justifyContent: 'flex-end'}}>
                                            <div className={'flex align-center'}>
                                                <div className={'w-10px h-10px mr-4px'}
                                                     style={{backgroundColor: '#24CBAA'}}></div>
                                                <div className={'c1_r cl-gray04 mr-12px'}>활동 시간</div>
                                                <div className={'w-10px h-10px mr-4px'}
                                                     style={{backgroundColor: '#AAAAAA'}}></div>
                                                <div className={'c1_r cl-gray04'}>무리해서 활동한 시간</div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    props.periodType === 'WEEK'?
                                        <>
                                            {
                                                weekWakeUpAverage && weekSleepAverage?
                                                    <>
                                                        <div className={'grid-data mt-32px'}>
                                                            <div className={'row'} >
                                                                <div className={'row-item c1_r'} style={{width:'50px'}}></div>
                                                                <div className={'row-item c1_r'} style={{width:'13%'}}>일</div>
                                                                <div className={'row-item c1_r'} style={{width:'13%'}}>월</div>
                                                                <div className={'row-item c1_r'} style={{width:'13%'}}>화</div>
                                                                <div className={'row-item c1_r'} style={{width:'13%'}}>수</div>
                                                                <div className={'row-item c1_r'} style={{width:'13%'}}>목</div>
                                                                <div className={'row-item c1_r'} style={{width:'13%'}}>금</div>
                                                                <div className={'row-item c1_r'} style={{width:'13%'}}>토</div>
                                                            </div>
                                                            <div className={'row'} >
                                                                <div className={'row-item c1_r'} style={{width:'50px'}}>기상</div>
                                                                {weekWakeUpAverage.map((d, index) => {
                                                                        return (<div className={'row-item b2_l'} key={index} style={{width:'13%'}}>{d}</div>)
                                                                    }
                                                                )}
                                                            </div>
                                                            <div className={'row'} >
                                                                <div className={'row-item c1_r'} style={{width:'50px'}}>취침</div>
                                                                {weekSleepAverage.map((d, index) => {
                                                                        return (<div className={'row-item b2_l'} key={index} style={{width:'13%'}}>{d}</div>)
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }

                            </>
                        :
                            <Skeleton variant="rounded" height={reportHeight} width={'100%'}/>
                    }
                    </div>
                </>

    )
}

export default BodyEnergy