import React, {useCallback} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

type popupParam = {
    isOpen: boolean,
    content: JSX.Element,
    title: JSX.Element | undefined,
    btnContent: string | undefined,
    onClose?: () => void,
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 270,
};

const Alert = (props:popupParam) => {
    const handleClose = useCallback(() => {props.onClose && props.onClose()}, [props.onClose])
    return(
        <>
            <div className={'modal-dialog'}>
                <Modal
                    open={props.isOpen}
                    onClose={handleClose}
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div id="modal-modal-description" className={'modal-body'}>
                            { props.title !== undefined ?
                                <>{props.title}</>
                                :
                                <></>
                            }
                            {props.content}
                        </div>
                        {props.btnContent
                            ?
                               <div className="modal-footer">
                                    <div className={'modal-btn alert-btn'} onClick={handleClose}>{props.btnContent}</div>
                                </div>
                            :
                                null
                        }
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default Alert;