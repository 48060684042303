import moment from "moment-timezone";
import {DefaultResult, rothyClient} from "@/service/RothyClient";
import StorageUtil from "@/common/StorageUtil";
import {USER_ID} from "@/common/Constant";
import dateUtil from "@/common/DateUtil";
import {PointPopupRes} from "@/service/PopUpService";
import StringUtil from "@/common/StringUtil";

export interface MsreDate{
    data : ItemList
}

export interface ItemList{
    itemList : Item[]
}
export interface Item {
    msreDtm :string,
    mgtItemCd:string,
    msreVal:number
}

export interface LstMsreDateForMQ{
    data : MeasureList
}

export interface MeasureList{
    measureList: MeasureListItem[],
}

export interface MeasureListItem{
    measureType: string,
    measureDatetime: number
}

export interface UserMissionReq{
    fromDateTime:string,
    toDateTime:string,
    userId:number
}

export interface UserMissionPoint{
    step:number,
    sleep:number,
    weight:number
}

export interface UserDailyMission{
    missionStatCd:string,
    point:UserMissionPoint,
    succTime:string,
    userId:number,
    frstRegDtm:string,
    userDailyMissionNo:number,
    userMissionNo:number,
    missionDate:string,
    stepCondCd:string,
    stepCondVl:number,
    stepCnt:number,
    sleepTime:string,
    bdfatRate:number

}

export interface MissionEndPopup{
    userMissionDtlNo:number,
    popUpUrl:string,
    verNo:number
}

export interface UserMission{
    healthSyncAgree:string,
    missionStatCd:string,
    missionStartDate:string,
    missionEndDate:string,
    messageBubble:string,
    message7days1:string,
    message30days:string,
    succCnt:number,
    frstRegDtm:string,
    userId:number,
    feedbackConfirmYn:string,
    compCd:string,
    rewardTypeCd:string,
    pcsrDt:string,
    missionDays:number,
    missionEndPopup:MissionEndPopup,
    userTotalPoint:UserMissionPoint,
    stepCondVl:number,
    stepCondCd:string,
    userDailyMissionList:UserDailyMission[],
    dday:number
}

export interface LastMission{
    lastCheckDate:string,
    checkCallYn:string,
    beginDateYn:string,
    expireYn:string
}

export interface TodayMissionData{
    data : {
        missionStatusCd: string,
        missionType: string,
        successCnt: number,
        missionDay: number,
        totalMissionDay: number,
        monthMissionDay: number,
        dday: number,
        stepGoal: string,
        todayStep: string,
        todaySleep: string,
        todayWeight: string,
        stepComparison: string,
        sleepComparison: string,
        weightComparison: string,
        comment: string,
        missionList: string
    }
}


const getLastMserDate = async () =>
    rothyClient.get<MsreDate>("/userMission/getLstMsreDate").then(res => res.data)

const getLstMsreDateForMQ = async (userId: number) =>
    rothyClient.get<LstMsreDateForMQ>(`/measure/datetime/last/${userId}`).then(res => res.data)

const getTodayMission =  async () :Promise<UserDailyMission>=> {
    return new Promise((resolve,reject)=>{
        const fromDateTime = moment(new Date()).utc().format('YYYY-MM-01 00:00:00')
        const toDateTime = moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')
        console.debug("check mission3####-1")
        const props : UserMissionReq = {fromDateTime:fromDateTime,toDateTime:toDateTime,userId:Number(StorageUtil.getLocalStorage(USER_ID))}
        getUserMissionData(props).then(res=>{
            console.debug("check mission3####-2")
            if(res != undefined && res.userDailyMissionList.length>0) {
                console.debug("check mission3####-3")
                const missionList = res.userDailyMissionList
                const lastIndex = missionList.length -1
                const toDayMission  = missionList.at(lastIndex)
                if(toDayMission != undefined)
                    resolve(toDayMission)
                else
                    reject()
            }else{
                reject()
                console.debug(res)
            }
        }).catch((e)=>{
            reject(e)})
    })
}

const getUserMissionData = async (props:UserMissionReq) : Promise<UserMission> => {
    return new Promise(
        (resolve,reject ) => {
            rothyClient.get<DefaultResult>("/userMission/monthly", {params: props}).then(res => {
                let userMission
                if(res.data.data != undefined) {
                    userMission = res.data.data as UserMission
                    resolve(userMission)
                }else{
                    reject()
                }

            }).catch((e) => {
                reject(e)
            })
        })
}

const getLastMissionData = async () :Promise<LastMission> => {
    return new Promise(
        (resolve,reject ) => {
            const  userId = Number(StorageUtil.getLocalStorage(USER_ID))
            const props = {userId}
            rothyClient.get<DefaultResult>("/userMission/check/date", {params: props}).then(res => {
                const lastMission = res.data.data as LastMission
                resolve(lastMission)

            }).catch((e) => {
                reject(e)
            })

        })
}


const shuldRequestCheckMission = async () : Promise<boolean> =>{
    return new Promise((resolve) => {
        let shouldCheckMission = false
        let todayMission:UserDailyMission
        let lastMission:LastMission

        console.debug("check mission3")
        console.debug("check mission4")
        getLastMissionData().then(r=>{
            lastMission = r
            console.debug("check mission5" + JSON.stringify(lastMission))

            if(lastMission == undefined ){
                shouldCheckMission = false
                resolve(shouldCheckMission)
                return
            }
            console.debug("check mission6")
            console.debug(lastMission)
            if(dateUtil.isPastDay(lastMission.lastCheckDate)){
                console.debug("최종 미션체크일이 과거 ")
                shouldCheckMission = true
                resolve(shouldCheckMission)
                return
            }
            console.debug("check mission7")
            if(lastMission.checkCallYn === "N"){
                shouldCheckMission = false
                resolve(shouldCheckMission)
                return
            }
            console.debug("check mission8")
            if(lastMission.expireYn === "Y"){
                shouldCheckMission = true
                resolve(shouldCheckMission)
                return
            }
            console.debug("check mission9")
            if(lastMission.beginDateYn === "Y"){
                shouldCheckMission = true
                resolve(shouldCheckMission)
                return
            }
            console.debug("check mission10")
            if(!dateUtil.isPastDay(lastMission.lastCheckDate)){
                console.debug("check mission11")
                getTodayMission().then(r=>{
                    todayMission = r
                    console.debug("check mission12")
                    console.debug(JSON.stringify(todayMission))
                    if(checkTodaySuccess(todayMission)) {
                        shouldCheckMission = true
                        resolve(shouldCheckMission)
                        return
                    } else {
                        resolve(shouldCheckMission)
                    }
                }).catch(err=>{console.log(err)})
            } else {
                resolve(shouldCheckMission)
            }
        }).catch(err=>{console.log(err)})
    })
}

const checkTodaySuccess = (todayMission:UserDailyMission) : boolean =>{
    let isSuccess= false
    /*
    각 성공 여부 판단은 todayMission admin에서 설정한 조건 코드에 따라 판단 해야 하나 현재 android에 걸음은 '이상', 체중 및 수면은 측정 여부로 고정 되어 있음
    향후 다른 조건의 미션을 만들 경우 android, iOS 수정 필요
     */
    if(todayMission.stepCnt>=todayMission.stepCondVl || todayMission.sleepTime != '' || todayMission.bdfatRate != 0)
        isSuccess = true;

    return isSuccess
}

const checkMission =  (event:Event) => {

    const userId = StorageUtil.getLocalStorage(USER_ID) ?? '0'

    shuldRequestCheckMission()
        .then(resolve=>{
            if(resolve){
                rothyClient.get<PointPopupRes>("/userMission/v2/check/".concat(userId)).then(res =>{
                    console.debug(res.data)
                    if(res.data.data.popupYn === 'Y'){
                        StorageUtil.setLocalStorage('pointPopup', StringUtil.stringify(res.data.data))
                    }
                    window.dispatchEvent(event)
                }).catch(err => console.debug(err))
            }
            window.dispatchEvent(event)
        })
        .catch(err => {console.debug("checkMission error : "); console.debug(err)})
}

const asyncCheckMission = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        const userId = StorageUtil.getLocalStorage(USER_ID) ?? '0'

        shuldRequestCheckMission()
            .then(response => {
                if(response){
                    rothyClient.get<PointPopupRes>("/userMission/v2/check/".concat(userId)).then(res =>{
                        if(res.data.data.popupYn === 'Y'){
                            StorageUtil.setLocalStorage('pointPopup', StringUtil.stringify(res.data.data))
                        }

                        resolve()
                    }).catch(err => reject(err))
                } else {
                    resolve()
                }
            })
            .catch(err => reject(err))
    })
}


const getLastHistoryMserDate = async () =>
    rothyClient.get<MsreDate>("/userMission/lastHistorySaveDt").then(res => res.data)

const getTodayMissionData = async (userId:number) =>
    rothyClient.get<TodayMissionData>("/userMission/today", {params:{userId: userId}}).then(res => res.data)


const UserMissionService = {
    getLastMserDate,
    getLstMsreDateForMQ,
    getTodayMission,
    shuldRequestCheckMission,
    checkMission,
    asyncCheckMission,
    getLastHistoryMserDate,
    getTodayMissionData

}
export default UserMissionService;
