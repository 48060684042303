import moment from "moment-timezone";
import UserMissionService, {MsreDate} from "@/service/UserMissionService";
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import {RHParamDaily, RHQuantity, RHQuantityTypes, RHSets} from "@/service/rothynative/RNIHealth";
import StorageUtil from "@/common/StorageUtil";
import {DATE_FORMAT, ITEM_CD, OS_TYPE, USER_ID} from "@/common/Constant";
import RothyFireBase from '@/service/FireBaseService'
import {rothyClient} from "@/service/RothyClient";
import InterfaceUtil from "@/common/Interface";

const rotyFireBase : RothyFireBase = RothyFireBase.getInstance()

const putStep = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!InterfaceUtil.isAppRunning()) {
            resolve()
            return
        }

        const userId = Number(StorageUtil.getLocalStorage(USER_ID))

        rotyFireBase
            .isCheckMissionStep(userId)
            .then((isCheckMission: boolean) => {
                if (isCheckMission) {
                    resolve()
                } else {
                    UserMissionService
                        .getLastMserDate()
                        .then(r  => {
                            return getData(r)
                        })
                        .then(r2 => {
                            return putData(r2)
                        })
                        .then(() => {
                            resolve()
                        })
                        .catch(err => {console.log('getLastMserDate error'); console.log(err); reject(err)})
                }
            })
            .catch(e => reject(e))
    })
}

const putData = (stepData : DailyStepData) : Promise<void> => {
    return new Promise((resolve, reject) => {
        rothyClient
            .post<any>("/measure/step", stepData)
            .then(() => { resolve() })
            .catch((err) => { reject(err) })
    })
}


const getData = (msreDate : MsreDate) : Promise<DailyStepData> => {
    return new Promise((resolve) => {
            const startDate = moment(msreDate.data.itemList[msreDate.data.itemList.findIndex(element => ITEM_CD.STEP.match(element.mgtItemCd))].msreDtm).utc().format(DATE_FORMAT.DATE_TIME_UTC)
            const endDate = moment(new Date()).utc().format(DATE_FORMAT.DATE_TIME_UTC)
            // 걸음수
            const stepParam = new RHParamDaily()
            stepParam.quantityType = RHSets.stepCount.daily.quantityType
            stepParam.option = RHSets.stepCount.daily.option
            stepParam.startDate = startDate
            stepParam.endDate = endDate

            const stepConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
            stepConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
            stepConfig.data = stepParam

            // 거리
            const disParam = new RHParamDaily()
            disParam.quantityType = RHSets.distanceWalkingRunning.daily.quantityType
            disParam.option = RHSets.distanceWalkingRunning.daily.option
            disParam.startDate = stepParam.startDate
            disParam.endDate = stepParam.endDate

            const disConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
            disConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
            disConfig.data = disParam

            // 평균속도(km/h)
            const speParam = new RHParamDaily()
            speParam.quantityType = RHSets.walkingSpeed.daily.quantityType
            speParam.option = RHSets.walkingSpeed.daily.option
            speParam.startDate = stepParam.startDate
            speParam.endDate = stepParam.endDate

            const speConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
            speConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
            speConfig.data = speParam

            // 칼로리
            const calParam = new RHParamDaily()
            calParam.quantityType = RHSets.activeEnergyBurned.daily.quantityType
            calParam.option = RHSets.activeEnergyBurned.daily.option
            calParam.startDate = stepParam.startDate
            calParam.endDate = stepParam.endDate

            const calConfig = new RNIMessageConfig<RHParamDaily, Array<RHQuantity>>()
            calConfig.action = RNIActions.GET_HEALTH_DATA_DAILY
            calConfig.data = calParam

            const stePromise = RothyNativeService.getInstance().postMessage(stepConfig)
            const disPromise = RothyNativeService.getInstance().postMessage(disConfig)
            const spePromise = RothyNativeService.getInstance().postMessage(speConfig)
            const calPromise = RothyNativeService.getInstance().postMessage(calConfig)

            const promiseAll = Promise.all([stePromise, disPromise, spePromise, calPromise])
            promiseAll
                .then((values) => {
                    const dailyStep = meargeDailyStepData(values[0],values[1],values[2],values[3])
                    resolve(dailyStep)
                })
                .catch((error: RNIError) => {
                    alert(`${error.code || ''}::${error.message || ''}`)
                })
        }
    )
}

const meargeDailyStepData = (stepResponse : RHQuantity[],distResponse  : RHQuantity[],speedResponse : RHQuantity[],calResponse : RHQuantity[]) : DailyStepData => {
    let t1 : Array<StepData> = []
    const dailyStep : DailyStepData = {dailyStep : t1,healthSyncAgree:"Y",userId:Number(StorageUtil.getLocalStorage(USER_ID))}

    t1 = setDailyData(stepResponse,t1,RHQuantityTypes.stepCount)
    t1 = setDailyData(speedResponse,t1,RHQuantityTypes.walkingSpeed)
    t1 = setDailyData(distResponse,t1,RHQuantityTypes.distanceWalkingRunning)
    t1 = setDailyData(calResponse,t1,RHQuantityTypes.activeEnergyBurned)
    t1 = stepDailyDataValidation(t1)

    dailyStep.dailyStep = t1

    return dailyStep
}

const setDailyData = (setData : RHQuantity[],result : Array<StepData>,quantityType : string) : Array<StepData> => {
    setData.length > 0 && (
        setData.map((value) => {
            const findIndex = result.findIndex(element => element.msreDtm?.match(moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DAY)))
            if(findIndex != -1){
                if( quantityType.localeCompare(RHQuantityTypes.stepCount) == 0)
                    result[findIndex].stepCnt = value.quantity
                else if(quantityType.localeCompare(RHQuantityTypes.walkingSpeed) == 0)
                    result[findIndex].moveSpeed = value.quantity
                else if(quantityType.localeCompare(RHQuantityTypes.distanceWalkingRunning) == 0)
                    result[findIndex].moveDist = value.quantity
                else if(quantityType.localeCompare(RHQuantityTypes.activeEnergyBurned) == 0)
                    result[findIndex].cnptCalr = value.quantity
            }else{
                let rowStep: StepData = {}

                let msreDtm = moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME)
                if (InterfaceUtil.getOs() === OS_TYPE.ANDROID) {
                    // 일자별 걸음수의 경우 안드로이드에서는 시분초 데이터가 없어 KST로 전환시 +09:00이되어
                    // 오전 9시 이전에는 풋매저가 실패하는 현상으로 인해 Android에서는 KST 전환 없이 처리한다.
                    msreDtm = moment(`${value.startDate}`).format(DATE_FORMAT.DATE_TIME)
                }

                if( quantityType.localeCompare(RHQuantityTypes.stepCount) == 0) {
                    rowStep  = {
                        stepCnt: value.quantity,
                        msreDtm: msreDtm
                    }
                }else if(quantityType.localeCompare(RHQuantityTypes.walkingSpeed) == 0) {
                    rowStep = {
                        moveSpeed: value.quantity,
                        msreDtm: msreDtm
                    }
                }else if(quantityType.localeCompare(RHQuantityTypes.distanceWalkingRunning) == 0){
                    rowStep  = {
                        moveDist: value.quantity,
                        msreDtm: msreDtm
                    }
                }else if(quantityType.localeCompare(RHQuantityTypes.activeEnergyBurned) == 0){
                    rowStep  = {
                        cnptCalr: value.quantity,
                        msreDtm: msreDtm
                    }
                }


                result.push(rowStep)
            }
        })
    )

    return result
}

const stepDailyDataValidation = (result:Array<StepData>) : Array<StepData> => {
    result.sort(function (a,b){
        if (a.msreDtm != undefined && b.msreDtm != undefined && (a.msreDtm > b.msreDtm))
            return 1

        if (a.msreDtm != undefined && b.msreDtm != undefined && (a.msreDtm < b.msreDtm))
            return -1


        return 0
    })

    for(const value of result){
        if(value.cnptCalr == undefined) {
            result[result.findIndex(element => element.msreDtm == value.msreDtm)].cnptCalr = 0
        }
        if(value.stepCnt == undefined) {
            result[result.findIndex(element => element.msreDtm == value.msreDtm)].stepCnt = 0
        }
        if(value.moveSpeed == undefined) {
            result[result.findIndex(element => element.msreDtm == value.msreDtm)].moveSpeed =0
        }
        if(value.moveDist == undefined) {
            result[result.findIndex(element => element.msreDtm == value.msreDtm)].moveDist = 0
        }

        result[result.findIndex(element => element.msreDtm == value.msreDtm)].msreDtm = value.msreDtm?.replaceAll('T',' ')
    }

    return result
}

export interface DailyStepData {
    dailyStep : StepData[]
    healthSyncAgree : string
    userId : number
}

export class StepData {
    cnptCalr? : number
    moveDist? : number
    moveSpeed? : number
    msreDtm? : string
    stepCnt? : number
}

const PutStepService = {
    putStep
}

export default PutStepService;
