import React, {CSSProperties, useState} from 'react';

import RothyNativeService, {
    RNIActions,
    RNIError,
    RNIEvents,
    RNIMessageConfig,
} from "@/service/rothynative/RothyNativeService";
import * as RNISmartScale from "@/service/rothynative/RNISmartScale";
import {
    ICKitchenScaleUnit,
    ICPeopleType,
    ICRulerUnit, ICScanDeviceInfo,
    ICSexType,
    ICUserInfo, ICWeightData,
    ICWeightUnit
} from "@/service/rothynative/RNISmartScale";

/**
 * Infit 체중계 연동 순서
 * 1. 사용자 프로필 등록
 * 2. Infit 체중계 SDK 초기화
 * 3. Device 검색
 * 4. 조회된 Device 등록
 * 샘플 순서대로 인터페이스를 호출해야 체중 데이터를 받을수 있습니다.
 * @constructor
 */
const SmartScaleSample: React.FC = (): JSX.Element => {
    const [isInitMgr, setInitMgr] = useState(false)
    const [isScaning, setScaning] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState<ICScanDeviceInfo>()
    const [isAddDevice, setAddDevice] = useState(false)
    const [weightData, setWeightData] = useState<ICWeightData>()

    const onUpdateUserInfo = () => {
        // 사용자 프로필 변경 이후 SDK 초기화를 해주어야한다.
        const param = new ICUserInfo()
        param.userIndex = 1;
        param.height = 172;
        param.age = 39;
        param.sex = ICSexType.ICSexTypeMale

        const config = new RNIMessageConfig<RNISmartScale.ICUserInfo, void>()
        config.action = RNIActions.IC_UPDATE_USER_INFO
        config.data = param

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                onInitMgr()
            })
            .catch((error: RNIError) => {
                console.log(`code: ${error.code ? error.code : ""} message: ${error.message}`)
            })
    }

    const onInitMgr = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.IC_INIT_MGR

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setInitMgr(true)
            })
            .catch((error: RNIError) => {
                console.log(`code: ${error.code ? error.code : ""} message: ${error.message}`)
            })
    }

    const onScanDevice = () => {
        setScaning(true)

        // 체중계 기기 검색
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_IC_SCAN_RESULT, (response: ICScanDeviceInfo) => {
            setDeviceInfo(response)
            onStopScan()
        })

        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.ON_IC_START_SCAN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setInitMgr(true)
            })
            .catch((error: RNIError) => {
                if (error.code === '-9001') {
                    // 블루투스 권한 미허용 or 블루투스 disable
                } else if (error.code === '-1001') {
                    // SDK 초기화 되지 않음
                }
                console.log(JSON.stringify(error))
            })
    }

    const onStopScan = () => {
        setScaning(false)

        RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_IC_SCAN_RESULT)

        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.ON_IC_STOP_SCAN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .catch((error: RNIError) => {
                if (error.code === '-9001') {
                    // 블루투스 권한 미허용 or 블루투스 disable
                } else if (error.code === '-1001') {
                    // SDK 초기화 되지 않음
                }

                console.log(JSON.stringify(error))
            })
    }

    const addDevice = () => {
        // 체중계 체중 데이터
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_IC_RECEIVE_WEIGHT_DATA, (response: ICWeightData) => {
            setWeightData(response)
        })

        if (deviceInfo) {
            const config = new RNIMessageConfig<string, void>()
            config.action = RNIActions.IC_ADD_DEVICE
            config.data = deviceInfo.macAddr

            RothyNativeService
                .getInstance()
                .postMessage(config)
                .then(() => {
                    setAddDevice(true)
                })
                .catch((error: RNIError) => {
                    if (error.code === '-9001') {
                        // 블루투스 권한 미허용 or 블루투스 disable
                    } else if (error.code === '-1001') {
                        // SDK 초기화 되지 않음
                    }

                    setAddDevice(false)
                    console.log(JSON.stringify(error))
                })
        }
    }

    const removeDevice = () => {
        RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_IC_RECEIVE_WEIGHT_DATA)

        if (deviceInfo) {
            const config = new RNIMessageConfig<string, void>()
            config.action = RNIActions.IC_REMOVE_DEVICE
            config.data = deviceInfo.macAddr

            RothyNativeService
                .getInstance()
                .postMessage(config)
                .then(() => {
                    setAddDevice(false)
                })
                .catch((error: RNIError) => {
                    if (error.code === '-9001') {
                        // 블루투스 권한 미허용 or 블루투스 disable
                        console.log()
                    } else if (error.code === '-1001') {
                        // SDK 초기화 되지 않음
                    } else {
                        // 그 외 에러
                    }

                    console.log(JSON.stringify(error))
                })
        }
    }

    return <>
        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>사용자 프로필 업데이트 & SDK 초기화</h6></div>
                <div style={styles.between} />
                <div style={styles.button} onClick={onUpdateUserInfo}>업데이트</div>
            </div>
            <div style={styles.content}>
                {isInitMgr && (
                    <div>사용자 프로필 업데이트 & SDK 초기화 완료.</div>
                )}
            </div>
        </div>
        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>Infit 체중계 Scan</h6></div>
                <div style={styles.between} />
                {isScaning && (
                    <div style={styles.button} onClick={onStopScan}>중지</div>
                )}
                {!isScaning && (
                    <div style={styles.button} onClick={onScanDevice}>시작</div>
                )}
            </div>
            <div style={styles.content}>
                {deviceInfo != undefined && !isAddDevice && (
                    <>
                        <div>{deviceInfo.macAddr}(신호세기: {deviceInfo.rssi})</div>
                        <div style={styles.button} onClick={addDevice}>등록</div>
                    </>
                )}
                {deviceInfo != undefined && isAddDevice && (
                    <>
                        <div>{deviceInfo.macAddr}(신호세기: {deviceInfo.rssi})</div>
                        <div style={styles.button} onClick={removeDevice}>삭제</div>
                    </>
                )}
                {deviceInfo != undefined && isAddDevice && (
                    <div>{weightData?.weight_kg}</div>
                )}
            </div>
        </div>
    </>
}

const styles = {
    wrap: {
        marginTop: "10px",
    } as CSSProperties,
    header: {
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    title: {
        alignSelf: "center"
    } as CSSProperties,
    between: {
        flex: 1,
    } as CSSProperties,
    button: {
        background: "#FFFF00",
        alignSelf: "center",
        padding: "10px 10px",
    } as CSSProperties,
    content: {
        padding: "0px 10px"
    } as CSSProperties,
    contentList: {
        padding: "0px 10px",
        maxHeight: "200px",
    } as CSSProperties,
}

export default SmartScaleSample;
