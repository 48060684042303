import React, {useEffect, useState} from 'react'
import {WarningHabitData} from "@/service/VitaIndexService";
import {VitaIndexImage} from "@/common/Constant";

interface PropsCofig {
    warningHabit?: WarningHabitData,
}

const Caution = (props:PropsCofig) => {
    const [selectedImage, setSelectedImage] = useState('')

    useEffect(()=> {
        if(props.warningHabit?.lifelogType){
            switch (props.warningHabit.lifelogType){
                case '걸음':
                    setSelectedImage(VitaIndexImage.warningHabit.walk)
                    break
                case '수면':
                    setSelectedImage(VitaIndexImage.warningHabit.sleep)
                    break
                case '체성분':
                    setSelectedImage(VitaIndexImage.warningHabit.body)
                    break
            }
        }
    }, [props.warningHabit?.lifelogType])

    return <div className={'mt-60px mrml-24px mb-60px'}>
                <div className={'mb-24px'}>
                    <span className={'s_title_1_m cl-gray01'}> 주의하실 습관이 있어요! </span>
                </div>
                <div className={'flex justify-center mb-24px'}>
                    <div style={{height: '220px', width: '220px'}}>
                        <img width={'100%'} height={'100%'} src={selectedImage}/>
                    </div>
                </div>
                <div style={{height: 'fit-content', width: '100%' , padding: '24px 16px', backgroundColor: '#F1F1F1', borderRadius: '8px'}}>
                    <span className={'flex justify-center s_title_1_m mb-12px'}>{props.warningHabit?.title}</span>
                    <p className={'b2_r cl-gray03'} style={{lineHeight:'22px'}}>{props.warningHabit?.comment}</p>
                </div>
            </div>
}

export default Caution
