import React, {useEffect, useRef, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Chart} from "react-chartjs-2";
import {Skeleton} from "@mui/material";
import SleepService, {
    OxygenData, SleepOxygenRes,
    SleepReportReq,
} from "@/service/SleepService"
import StringUtil from "@/common/StringUtil";
import Icon from "@/view/common/component/Icon";
import {ChartDataSet, getLabelData, ReportPropsConfig} from "@/common/ReportUtil";
import Popup from "@/view/common/popup/Popup";
import OxygenYearDetail from "@/view/sleep/component/OxygenYearDetail";

export type YearItem = {
    date: string,
    totalOxygenLowCount: number,
}

const Oxygen =(props:ReportPropsConfig) => {
    const [sleepOxygenMsg, setSleepOxygenMsg] = useState<string | undefined>(undefined)
    const [sleepOxygenLowCnt, setSleepOxygenLowCnt] = useState<number | undefined>(undefined)
    const [sleepReportReq, setSleepReportReq] = useState<SleepReportReq>( new SleepReportReq ('','','','','',0,''))
    const [data, setData] = useState<{labels:string[], datasets:ChartDataSet[]}>()
    const [options, setOptions] = useState({})
    const [resourceData, setResourceData] = useState<SleepOxygenRes | undefined>(undefined)
    const [oxygenInfoVisible, setOxygenInfoVisible] = useState<boolean>(false)
    const [standRangeStyle, setStandRangeStyle] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [yearItemList, setYearItemList] = useState<YearItem[] |undefined>(undefined)

    const [totalOxygenLowCount, setTotalOxygenLowCount] = useState(0)

    const chartDivRef = useRef<HTMLDivElement>(null)

    const reportHeight = 160
    const divWidth = chartDivRef.current ? chartDivRef.current.clientWidth : window.innerWidth - 48
    const reportWidth = divWidth

    let barResizePercentage = 0.3

    const clickStageInfo = () => {
        if(oxygenInfoVisible){
            setOxygenInfoVisible(false)
        }else{
            setOxygenInfoVisible(true)
        }
    }

    const {refetch: getSleepRecordOxygen} = useQuery<SleepOxygenRes, AxiosError>(
        ['getSleepRecordOxygen'],
        () => SleepService.getSleepRecordOxygen(sleepReportReq),
        {
            onSuccess: (v: SleepOxygenRes) => success(v)
        }
    )

    const success = (v:SleepOxygenRes) => {
        console.log(v)
        setResourceData(v)
    }

    let _totalOxygenLowCount = 0
    const createChart = (v:SleepOxygenRes) => {
        const labels:string[] = []
        const labelData1:Array<[number, number]> = []
        const labelData2:Array<[number, number]> = []
        const _setYearItemList:YearItem[] = []


        setSleepOxygenMsg(v.data.sleepOxygenMsg)
        setSleepOxygenLowCnt(v.data.sleepOxygenLowCnt)
        updateChartDetail(props.periodType)

        v.data.sleepOxygenList.forEach(d => {
            labels.push(getLabelData(d, props.periodType))
            labelData1.push(getLabelData1(d))
            labelData2.push(getLabelData2(d))
            _setYearItemList.push(getYearItemList(d))
            _totalOxygenLowCount += d.OXYGEN_LOW_COUNT>0?d.OXYGEN_LOW_COUNT:0
        })

        setYearItemList(_setYearItemList)
        setTotalOxygenLowCount(_totalOxygenLowCount)
        updateStandRangeStyle(String((reportHeight-35)/2)+'px', '10px')
        updateData(labels, labelData1, labelData2)
        updateOptions(100)
    }

    const updateStandRangeStyle = (averageHeight:string, averageMarginTop:string) => {
        setStandRangeStyle({
            backgroundColor: 'rgba(173, 230, 223, 0.3)',
            position:'absolute',
            width: String(reportWidth-45)+'px',
            borderTop:'0.5px solid',
            borderBottom:'0.5px solid',
            borderColor:'#24CBAA',
            marginLeft: '47px',
            height: averageHeight,
            marginTop: averageMarginTop,
        })
    }

    const updateChartDetail = (_periodType:string) => {
        const type = _periodType
        switch (type) {
            case 'WEEK':
                barResizePercentage = 0.3
                break
            case 'MONTH' :
                barResizePercentage = 0.6
                break
            case 'YEAR':
                barResizePercentage = 0.3
                break
        }
    }

    const getLabelData1 = (d:OxygenData): [number, number] => {
        let startValue = 0
        let endValue = 0

        if(d.SLEEP_OXYGEN_MIN> 0 && d.SLEEP_OXYGEN_MAX>0){
            if(d.SLEEP_OXYGEN_MIN<90){
                startValue = d.SLEEP_OXYGEN_MIN
                endValue = 90
            }
        }

        return [startValue, endValue]
    }

    const getLabelData2 = (d:OxygenData): [number, number] => {
        let startValue = 0
        let endValue = 0

        if(d.SLEEP_OXYGEN_MIN> 0 && d.SLEEP_OXYGEN_MAX>0){
            if(d.SLEEP_OXYGEN_MIN<90){
                endValue = d.SLEEP_OXYGEN_MAX-90
            }else{
                startValue = d.SLEEP_OXYGEN_MIN
                endValue = d.SLEEP_OXYGEN_MAX
            }
        }

        return [startValue, endValue]
    }

    const getYearItemList = (d:OxygenData): YearItem => {
        return {
            date: String(props.year)+'년 '+String(d.ROW_NUM)+'월',
            totalOxygenLowCount : d.OXYGEN_LOW_COUNT

        }
    }

    const updateData = (_labels:string[], labelData1:Array<[number, number]>, labelData2:Array<[number, number]> ) => {
        setData({
            labels: _labels,
            datasets: [
                {
                    type: 'bar' as const,
                    label: '이번주',
                    backgroundColor: '#AAAAAA',
                    data: labelData1,
                    //borderColor: 'white',
                    barPercentage: barResizePercentage,
                    borderSkipped: false,
                    order:2,
                },
                {
                    type: 'bar' as const,
                    label: '이번주',
                    backgroundColor: '#24CBAA',
                    data: labelData2,
                    //borderColor: 'white',
                    barPercentage: barResizePercentage,
                    borderSkipped: false,
                    order:2,
                },
            ],
        })
    }

    const updateOptions = (_reportEndDtValue:number) => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                datalabels: false,
                legend: {
                    display: false,
                    position: 'right',

                },
                tooltip: false,
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxRotation: 0,
                        autoSkip: false,
                        font: {
                            size: 12
                        }
                    },
                },
                y: {
                    stacked: true,
                    grid: {
                        drawBorder: false,
                        display: false
                    },
                    max: _reportEndDtValue,
                    min: 80,
                    ticks: {
                        stepSize: 10,
                        callback: function (value: number) {
                            return String(value)+'%'
                        }
                    },

                },

            },
        })
    }

    const test = () => {
        setIsOpen(true)
    }

    useEffect(()=>{
        if(resourceData){
            createChart(resourceData)
        }
    },[resourceData])

    useEffect(()=>{
        if(props){
            const _sleepReportReq = new SleepReportReq (
                props.fromDate,
                props.month,
                props.periodType,
                '',
                props.toDate,
                props.userId,
                props.year,
            )
            setSleepReportReq(_sleepReportReq)

        }
    },[props])

    useEffect(() => {
        if(sleepReportReq && StringUtil.isNotEmpty(sleepReportReq.periodType)){
            getSleepRecordOxygen()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[sleepReportReq])

    return (
                <>
                    <div className={'s_title_1_m mt-56px'} style={{lineHeight:'1.2'}}>
                        <div className={'flex align-center justify-between walk-far'}>
                            <div>자는 동안 몸속의<br/>산소는 충분했을까?</div>
                        </div>
                        <div className={'gray-bar-thin mt-8px'}></div>
                        <div className={'b2_l mt-12px'}>{sleepOxygenMsg}</div>
                    </div>

                    <div className={'w-100p mt-39px'} ref={chartDivRef}>
                    {
                        data ?
                            <>
                                {
                                    props.periodType === 'WEEK' || props.periodType === 'MONTH' ?
                                        <div className={'mt-10px mb-18px flex'} style={{justifyContent: 'space-between'}}>
                                            <div className={'flex align-center'}>
                                                <div className={'mr-4px'}><Icon iconClass={'point-red w-6px h-6px'}/></div>
                                                <div className={'c1_r cl-gray04'}>90% 아래로 내려간 날</div>
                                            </div>
                                            <div className={'c1_r cl-gray04'}>{sleepOxygenLowCnt}일</div>
                                        </div>
                                    :
                                        <div className={'mt-10px mb-18px flex-column'}>
                                            <div className={'b2_r cl-gray03'}>90% 아래로 내려간 날</div>
                                            <div className={'flex'}>
                                                <div className={'h4_m mr-8px'}>총</div>
                                                <div className={'h4_m mr-3px'}>{totalOxygenLowCount}</div>
                                                <div className={'h4_m mr-12px'}>일</div>
                                                {
                                                    totalOxygenLowCount>0?
                                                        <div className={'w-16px'} style={{alignSelf: "center"}} onClick={test}>
                                                            <Icon iconClass={'next-small w-5px h-8px'}/>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                }
                                <div style={standRangeStyle}></div>
                                <div style={{position: 'relative', zIndex: '2', width:String(reportWidth)+'px'}} >
                                    <Chart type='bar' data={data} options={options} height={String(reportHeight)+'px'} width={String(reportWidth)+'px'}/>
                                </div>
                                <div className={'mt-10px flex'} style={{justifyContent:'flex-end'}}>
                                    <div className={'flex align-center'}>
                                        <div className={'w-10px h-10px mr-4px'} style={{backgroundColor:'#AAAAAA'}}></div>
                                        <div className={'c1_r cl-gray04'}>90% 미만</div>
                                    </div>
                                </div>
                            </>
                        :
                            <Skeleton variant="rounded" height={reportHeight} width={'100%'}/>
                    }
                    </div>
                    <div className={'stage-inform mt-32px mb-60px'} onClick={clickStageInfo} >
                        <div className={'stage-inform-header flex justify-between align-center'}>
                            <div className={'b2_r pl-18px'}>수면 중 혈중 산소란?</div>
                            <div className={'b2_r pr-22px'}>
                                {
                                    oxygenInfoVisible?
                                        <Icon iconClass={'arrow-top w-12px h-6px'} />
                                        :
                                        <Icon iconClass={'arrow-bottom w-12px h-6px'} />
                                }
                            </div>
                        </div>
                        {
                            oxygenInfoVisible ?
                                <>
                                    <div className={'flex'}>
                                        <div className={'w-18px'} style={{height:'0.5px'}}></div>
                                        <div className={'gray-bar-thin'}></div>
                                        <div className={'w-18px'} style={{height:'0.5px'}}></div>
                                    </div>
                                    <div className={'stage-inform-detail mt-20px prpl-18px pb-24px'}>
                                        <div className={'b2_l'}>
                                            수면 중 혈중 산소는 단어 그대로 잠들어 있는 동안 측정한 혈중 산소 농도의 수치입니다. 잠이 들면 우리 몸은 깨어있을 때보다 활동량이 줄어듭니다. 따라서 폐의 활동량도 줄어들며 휴식과 회복하는 시간을 가집니다. 그러나 코골이, 수면 무호흡, 상기도 저항 증후군 등의 수면 중 호흡장애가 있으면 잠들어 있는 중에 충분한 산소를 확보하기 어려울 수 있습니다. 이를 해결하기 위해 잠에서 자주 깨게 되며 숙면을 방해받기 쉽습니다. 만일 깨어나지 않더라도 우리의 뇌는 부족한 산소를 채우기 위해 조치를 취합니다. 심장 박동을 증가시켜 혈액 순환을 빠르게 해 산소 운반을 늘리고 폐의 활동량을 늘려 부족한 산소를 확보하려 합니다. 그에 따라 심장 박동이 또 증가하게 됩니다. 증가한 심장 박동은 심장질환을 유발하거나 지속하게 하는 원인이 됩니다. 또한, 수면을 통해 쉬어야 하는 뇌와 심장, 폐는 활동을 지속하기에 수면 후에도 피로가 해소되지 않을 수 있습니다.
                                        </div>
                                        <br/>
                                        <div className={'c1_r cl-gray04'}>
                                            본 수치는 의료용 기기로 측정한 것이 아니므로 진단을 대신하지 않습니다. 또한, 워치 스트랩을 느슨하게 착용하거나 정확한 위치에 작용하지 않는 등 센서 데이터를 수집하는 데에 방해 요소가 있을 때도 혈중 산소 농도가 낮게 나올 수 있습니다.
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </div>
                    <Popup className='bg-cl-white' content={<OxygenYearDetail onClose={() => setIsOpen(!isOpen)} itemList={yearItemList}/>} isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}/>
                </>
    )
}

export default Oxygen