import moment from "moment-timezone";
import UserMissionService, {Item, MsreDate} from "@/service/UserMissionService";
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import {DATE_FORMAT, ITEM_CD, USER_ID} from "@/common/Constant";
import {RHParamHistory, RHQuantity, RHQuantityTypes, RHSets} from "@/service/rothynative/RNIHealth";
import StorageUtil from "@/common/StorageUtil";
import RothyFireBase from '@/service/FireBaseService'
import {rothyClient} from "@/service/RothyClient";
import InterfaceUtil from "@/common/Interface";

const rotyFireBase : RothyFireBase = RothyFireBase.getInstance()

const putStepHistory = () : Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!InterfaceUtil.isAppRunning()) {
            resolve()
            return
        }

        const userId = Number(StorageUtil.getLocalStorage(USER_ID))

        rotyFireBase
            .isCheckMissionStepHistory(userId)
            .then((isCheckMission: boolean) => {
                if (isCheckMission) {
                    resolve()
                } else {
                    UserMissionService
                        .getLastHistoryMserDate()
                        .then(r => {
                            return getStepHitoryData(r)
                        })
                        .then(r2 => {
                            return meargeStepHistoryData(r2)
                        })
                        .then(r3 => {
                            return putStepHistoryData(r3)
                        })
                        .then(() => resolve())
                        .catch(err => reject(err))
                }
            })
            .catch(e => reject(e))
    })
}

const getStepHitoryData =  async (msreData : MsreDate) : Promise<Array<RHQuantity[]>> => {
    return new Promise((resolve) => {
        const itemList: Item[] = msreData.data.itemList
        const findIndex = itemList.findIndex(element => ITEM_CD.STEP.match(element.mgtItemCd))
        const startDate = moment(msreData.data.itemList[findIndex].msreDtm).utc().format(DATE_FORMAT.DATE_TIME_UTC)
        const endDate = moment(new Date()).utc().format(DATE_FORMAT.DATE_TIME_UTC)

        // 걸음수
        const stepParam = new RHParamHistory()
        stepParam.quantityType = RHSets.stepCount.history.quantityType
        stepParam.startDate = startDate
        stepParam.endDate = endDate

        const stepConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        stepConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        stepConfig.data = stepParam

        // 거리
        const disParam = new RHParamHistory()
        disParam.quantityType = RHSets.distanceWalkingRunning.history.quantityType
        disParam.startDate = stepParam.startDate
        disParam.endDate = stepParam.endDate

        const disConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        disConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        disConfig.data = disParam

        // 평균속도(km/h)
        const speParam = new RHParamHistory()
        speParam.quantityType = RHSets.walkingSpeed.history.quantityType
        speParam.startDate = stepParam.startDate
        speParam.endDate = stepParam.endDate

        const speConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        speConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        speConfig.data = speParam

        // 칼로리
        const calParam = new RHParamHistory()
        calParam.quantityType = RHSets.activeEnergyBurned.history.quantityType
        calParam.startDate = stepParam.startDate
        calParam.endDate = stepParam.endDate

        const calConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        calConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        calConfig.data = calParam

        const stePromise = RothyNativeService.getInstance().postMessage(stepConfig)
        const disPromise = RothyNativeService.getInstance().postMessage(disConfig)
        const spePromise = RothyNativeService.getInstance().postMessage(speConfig)
        const calPromise = RothyNativeService.getInstance().postMessage(calConfig)

        const promiseAll = Promise.all([stePromise, disPromise, spePromise, calPromise])
        promiseAll
            .then((values) => {
                resolve(values)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })

    })

}

const putStepHistoryData = async  (meargeData:StepHistoryData) : Promise<void> => {
    return new Promise((resolve, reject) => {
        rothyClient.post<any>("/measure/history/step", meargeData)
            .then(() => { resolve() })
            .catch((err) => { reject(err) })
    })


}


const meargeStepHistoryData  = async (values : Array<RHQuantity[]>) : Promise<StepHistoryData> => {
    return new Promise((resolve) => {
        const result : Map<string,StepDataByTime> = new Map()
        const stepHistoryData : StepHistoryData = {healthSyncAgree:"Y",userId:Number(StorageUtil.getLocalStorage(USER_ID))}

        const steResponse : RHQuantity[] = values[0]
        const distResponse : RHQuantity[]= values[1]
        const speedReponse : RHQuantity[]= values[2]
        const calResponse : RHQuantity[]= values[3]

        setStepHistoryDataHah(steResponse,result,RHQuantityTypes.stepCount)
            .then(r1 => {return setStepHistoryDataHah(distResponse,r1,RHQuantityTypes.distanceWalkingRunning)} )
            .then(r2 => {return setStepHistoryDataHah(speedReponse,r2,RHQuantityTypes.walkingSpeed)} )
            .then(r3 => {return setStepHistoryDataHah(calResponse,r3,RHQuantityTypes.activeEnergyBurned)} )
            .then(r4 =>{return stepHistoryDataValidationHash(r4)})
            .then(r5 => { stepHistoryData.step = r5
                resolve(stepHistoryData) })
            .catch(err => console.debug(err))
    })
}
const setStepHistoryDataHah = async (setData:RHQuantity[],result : Map<string,StepDataByTime>,quantityType:string) : Promise<Map<string,StepDataByTime>> => {
    return new Promise(resolve => {
        setData.length > 0 && (
            setData.map((value) => {
                const key = moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME).concat(moment(`${value.endDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME))
                if(result.get(key) != undefined) {
                    const getData = result.get(key)
                    if (quantityType.localeCompare(RHQuantityTypes.stepCount) == 0) {
                        if(getData != undefined)
                            getData.stepCnt = value.quantity
                    }else if(quantityType.localeCompare(RHQuantityTypes.walkingSpeed) == 0) {
                        if(getData != undefined)
                            getData.moveSpeed = value.quantity
                    }else if(quantityType.localeCompare(RHQuantityTypes.distanceWalkingRunning) == 0) {
                        if(getData != undefined)
                            getData.moveDist = value.quantity
                    }else if(quantityType.localeCompare(RHQuantityTypes.activeEnergyBurned) == 0) {
                        //console.log("##########" + stringUtil.stringify(value.quantity))
                        if(getData != undefined)
                            getData.cnptCalr = value.quantity
                    }
                }else{
                    let rowStep: StepDataByTime = {}


                    if( quantityType.localeCompare(RHQuantityTypes.stepCount) == 0) {
                        rowStep  = {
                            stepCnt: value.quantity,
                            msreBeginDtm: moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            msreEndDtm: moment(`${value.endDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            dvicTp: value.deviceModel /* 데이터 수집 기기 정보는 현재 확인 하지 못하여 우선 모바일로 하드 코딩. */
                        }
                    }else if(quantityType.localeCompare(RHQuantityTypes.walkingSpeed) == 0) {
                        rowStep = {
                            moveSpeed: value.quantity,
                            msreBeginDtm: moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            msreEndDtm: moment(`${value.endDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            dvicTp: value.deviceModel /* 데이터 수집 기기 정보는 현재 확인 하지 못하여 우선 모바일로 하드 코딩. */
                        }
                    }else if(quantityType.localeCompare(RHQuantityTypes.distanceWalkingRunning) == 0){
                        rowStep  = {
                            moveDist: value.quantity,
                            msreBeginDtm: moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            msreEndDtm: moment(`${value.endDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            dvicTp: value.deviceModel /* 데이터 수집 기기 정보는 현재 확인 하지 못하여 우선 모바일로 하드 코딩. */
                        }
                    }else if(quantityType.localeCompare(RHQuantityTypes.activeEnergyBurned) == 0){
                        // console.log("@@@@@@@@@" + stringUtil.stringify(value.startDate) +' : ' +stringUtil.stringify(value.endDate) )
                        rowStep  = {
                            cnptCalr: value.quantity,
                            msreBeginDtm: moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            msreEndDtm: moment(`${value.endDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME),
                            dvicTp: value.deviceModel /* 데이터 수집 기기 정보는 현재 확인 하지 못하여 우선 모바일로 하드 코딩. */
                        }
                    }

                    result.set(key,rowStep)
                }

            })
        )
        resolve(result)
    })
}

const stepHistoryDataValidationHash = async (result:Map<string,StepDataByTime>) : Promise<Array<StepDataByTime>> => {

    return new Promise(resolve => {
        const returnData : Array<StepDataByTime> = []
        result.forEach(function (value:StepDataByTime,key:string){
            const getData  : StepDataByTime|undefined = result.get(key)
            if(getData != undefined && value.cnptCalr == undefined) {
                getData.cnptCalr = 0
            }
            if(getData != undefined &&  value.stepCnt == undefined) {
                getData.stepCnt = 0
            }
            if(getData != undefined && value.moveSpeed == undefined) {
                getData.moveSpeed =0
            }
            if(getData != undefined && value.moveDist == undefined) {
                getData.moveDist = 0
            }

            if(getData != undefined) {
                getData.msreBeginDtm = value.msreBeginDtm?.replaceAll('T', ' ')
                getData.msreEndDtm = value.msreEndDtm?.replaceAll('T', ' ')
            }
            if(getData != undefined)
                returnData.push(getData)
        })
        resolve(returnData)
    })
}

const PutStepHistoryService = {
    putStepHistory
}

export class StepDataByTime {
    cnptCalr? : number
    dvicTp?:string
    endTime?:string
    frstRegDtm?:string
    moveDist? : number
    moveSpeed? : number
    msreBeginDtm?:string
    msreEndDtm?:string
    startTime?:string
    stepCnt? : number
}

export class StepHistoryData {
    healthSyncAgree?:string
    step?:StepDataByTime[]
    userId?:number
}


export default  PutStepHistoryService
