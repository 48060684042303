import moment from "moment-timezone";
import {DATE_FORMAT, ITEM_CD, USER_ID} from "@/common/Constant";
import StorageUtil from "@/common/StorageUtil";
import InterfaceUtil from "@/common/Interface";
import RothyFireBase from '@/service/FireBaseService'
import {rothyClient} from "@/service/RothyClient";
import {ICWeightData} from "@/service/rothynative/RNISmartScale";
import UserMissionService, {Item, MsreDate} from "@/service/UserMissionService";
import {RHParamHistory, RHQuantity, RHSets, SHWeightStage, SHWeightStageData} from "@/service/rothynative/RNIHealth";
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";

const rotyFireBase : RothyFireBase = RothyFireBase.getInstance()

const putWeightHistory = (data?: ICWeightData) : Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!data && InterfaceUtil.getOs() === 'ios') {
            resolve()
            return
        }

        if (!InterfaceUtil.isAppRunning()) {
            resolve()
            return
        }

        const userId = Number(StorageUtil.getLocalStorage(USER_ID))

        rotyFireBase
            .isCheckMissionWeight(userId)
            .then((isCheckMission: boolean) => {
                if (isCheckMission) {
                    resolve()
                } else {
                    if (!data && InterfaceUtil.getOs() === 'android') {
                        // 삼성헬스에서 넘어온 데이터 PUT MESAURE
                        UserMissionService
                            .getLastMserDate()
                            .then(r => {
                                return getWeightHitoryData(r)
                            })
                            .then(r => {
                                const params = new WeightHistoryData()
                                params.healthSyncAgree = 'Y'
                                params.weightStage = r
                                params.userId = Number(StorageUtil.getLocalStorage(USER_ID))

                                putWeightHistoryData(params)
                                    .then(() => resolve())
                                    .catch(err => reject(err))
                            })
                            .catch(err => reject(err))
                    } else if (data) {
                        // Infit 체중계에서 넘어온 데이터 PUT MESAURE
                        const requestData = icWeightDataToWeightDataByTime(data)

                        const params = new WeightHistoryData()
                        params.healthSyncAgree = 'Y'
                        params.weightStage = [requestData]
                        params.userId = Number(StorageUtil.getLocalStorage(USER_ID))

                        putWeightHistoryData(params)
                            .then(() => resolve())
                            .catch(err => reject(err))
                    } else {
                        reject([])
                    }
                }
            })
            .catch(e => reject(e))
    })
}

const getWeightHitoryData =  async (msreData : MsreDate) : Promise<Array<WeightDataByTime>> => {
    return new Promise((resolve) => {
        const itemList: Item[] = msreData.data.itemList
        const findIndex = itemList.findIndex(element => ITEM_CD.WEIGHT.match(element.mgtItemCd))
        const startDate = moment(msreData.data.itemList[findIndex].msreDtm).utc().format(DATE_FORMAT.DATE_TIME_UTC)
        const endDate = moment(new Date()).utc().format(DATE_FORMAT.DATE_TIME_UTC)

        const weightParam = new RHParamHistory()
        weightParam.quantityType = RHSets.weight.history.quantityType
        weightParam.startDate = startDate
        weightParam.endDate = endDate

        const weightConfig = new RNIMessageConfig<RHParamHistory, Array<SHWeightStageData>>()
        weightConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        weightConfig.data = weightParam

        RothyNativeService.getInstance()
            .postMessage(weightConfig)
            .then((values) => {
                const result = new Array<WeightDataByTime>()

                for (let i = 0; i < values.length; i++) {
                    const row = values[i]
                    if (row.dvicTp != 360001) {
                        result.push(shWeightStageDataToWeightDataByTime(row))
                    }
                }

                resolve(result)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    })
}

const shWeightStageDataToWeightDataByTime = (data: SHWeightStageData): WeightDataByTime => {
    const h = data.height * 0.01
    let bmi = 0.0
    if (h != 0.0) {
        bmi = data.weight / (h * h)
    }

    const returnValue = new WeightDataByTime()
    returnValue.msreDtm = moment(data.start_time).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME)
    returnValue.tall = data.height
    returnValue.wt = data.weight
    returnValue.basalMtblsQty = data.basal_metabolic_rate
    returnValue.bdfatRate = data.body_fat
    returnValue.bdfatQty = data.body_fat_mass
    returnValue.muslWt = data.muscle_mass
    returnValue.skltnWtRate = data.skeletal_muscle
    returnValue.skltnWt = data.skeletal_muscle_mass
    returnValue.bdwtQty = data.total_body_water
    returnValue.bmi = bmi
    returnValue.dvicTp = data.dvicTp

    return returnValue
}

const icWeightDataToWeightDataByTime = (data: ICWeightData): WeightDataByTime => {
    const returnValue = new WeightDataByTime()

    const muscle_mass = (data.moisturePercent + data.proteinPercent) / 100 * data.weight_kg
    const skeletal_muscle_mass = muscle_mass * 0.577
    const skeletal_muscle = skeletal_muscle_mass / data.weight_kg * 100
    const total_body_water = data.moisturePercent * data.weight_kg / 100
    const h = data.height * 0.01
    let bmi = 0.0
    if (h === 0.0) {
        bmi = 0.0
    } else {
        bmi = data.weight_kg / (h * h)
    }

    returnValue.msreDtm = moment().tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME)
    returnValue.tall = data.height
    returnValue.wt = data.weight_kg
    returnValue.basalMtblsQty = data.bmr
    returnValue.bdfatRate = data.bodyFatPercent
    returnValue.bdfatQty = 0.0
    returnValue.muslWt = muscle_mass
    returnValue.skltnWtRate = skeletal_muscle
    returnValue.skltnWt = skeletal_muscle_mass
    returnValue.bdwtQty = total_body_water
    returnValue.bmi = bmi
    returnValue.dvicTp = 990002

    return returnValue
}

const putWeightHistoryData = async  (meargeData: WeightDataByTime[] | WeightHistoryData) : Promise<void> => {
    return new Promise((resolve, reject) => {
        rothyClient.post<any>("/measure/weight", meargeData)
            .then(() => { resolve() })
            .catch((err) => { reject(err) })
    })
}

const PutWeightHistoryService = {
    putWeightHistory
}

export class WeightDataByTime {
    msreDtm?: string
    tall?: number
    wt?: number
    basalMtblsQty?: number
    bdfatRate?: number
    bdfatQty?: number
    muslWt?: number
    skltnWtRate?: number
    skltnWt?: number
    bdwtQty?: number
    bmi?: number
    dvicTp?: number
}

export class WeightHistoryData {
    healthSyncAgree?:string
    weightStage?:WeightDataByTime[]
    userId?:number
}


export default PutWeightHistoryService
