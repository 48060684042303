import SplashRothyLogo from '@/assets/lottie/splash_beta_220716.json'
import SplashBottomLogos from '@/assets/image/splash-bottom-logos.png'
import LottieAnimation from "@/view/common/component/LottieAnimation";


const Splash = () => {

    return (
        <div className ={'bg-cl-white w-100p h-100vh'}>
            <div className="h-screen relative">
                <div style={{
                        position:'absolute',
                        top:'50%',
                        left: '0',
                        right: '0',
                        transform: 'translateY(-50%)'
                    }}
                >
                <LottieAnimation animationData={SplashRothyLogo} lottieStyle={{width: '300px', height:'200px', margin: '0 auto'}}/>
                </div>
                <div style={{
                        position:'absolute',
                        bottom:'42px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width:'142px',
                        height:'39px'
                    }}
                >
                    <img src={SplashBottomLogos} style={{ display:'block', margin:'0 auto', width: '142px', height:'39px' }}/>
                </div>
            </div>
        </div>
    )
}

export default Splash