import React, {useEffect, useState} from 'react'
import { Bar } from 'react-chartjs-2'

import {ChartDataSet} from "@/view/vitaindex/component/HistoryDaily";



interface PropsConfig {
    result?:number[]
}


const HistoryDailyChart= (props:PropsConfig ) => {
    const [data,setData] = useState<{labels:string[], datasets:ChartDataSet[]}>({labels:[],datasets:[]})
    const [options,setOptions] = useState({})

    const createChart = (result: number[]) => {
        updateData(result)
        updateOptions()
    }

    const updateData = (result: number[]) => {
        setData({
            labels : ['종합','걸음','수면','체성분'],
            datasets: [
                {
                    type: 'bar' as const,
                    borderRadius: 2,
                    backgroundColor: setBackgroundColor(),
                    data: result,
                    barPercentage: 0.6,
                },
            ],
        })
    }

    const updateOptions = () => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                legend: false,
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    color: function() {
                        return ['##616161']
                    },
                    font: {
                        size: '12',
                        family: 'SpoqaHanSansNeo',
                        lineHeight: '18px',
                        weight: '400'
                    },
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: '14',
                            style: 'normal',
                            family: 'SpoqaHanSansNeo',
                            lineHeight: '18px',
                            weight: '500'
                        },
                    },
                },
                y: {
                    max: 1000,
                    min: 0,
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            layout: {
                padding: {
                    top: 15
                }
            },
        })
    }

    const setBackgroundColor = () => {
        const canvas = document.getElementById('bar-chart') as HTMLCanvasElement
        const avg_ctx = canvas.getContext('2d')

        const mint_gradient = avg_ctx?.createLinearGradient(0, 0, 0, 100)
        mint_gradient?.addColorStop(0,'#24CBAA')
        mint_gradient?.addColorStop(1, '#A1E0D4')

        return mint_gradient
    }

    useEffect(() => {
        if(props.result && props.result.length > 0){
            createChart(props.result)
        }
    },[props.result])


    return (
        <div style={{height: '200px', margin: '0px 31px', paddingTop: '38px'}}>
            <Bar id="bar-chart"  data={data} options={options} height= '190px'/>
        </div>
    )
}

export default HistoryDailyChart