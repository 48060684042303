import React, {useEffect, useState} from 'react'
import MissionRecord from "@/view/mission/component/MissionRecord";
import StringUtil from "@/common/StringUtil";
import {FocusItem} from "@/service/UserService";
import StorageUtil from "@/common/StorageUtil";
import {FOCUS_ITEM} from "@/common/Constant";

export interface TodayRecordParam{
    step:string,
    stepComparison:string,
    sleep:string,
    sleepComparison:string,
    weight:string,
    weightComparison:string
}

const TodayRecord = (props: TodayRecordParam) => {
    const focusItem:FocusItem = StringUtil.jsonString2ObjectWithMap<FocusItem>(StorageUtil.getLocalStorage(FOCUS_ITEM) as unknown as '')
    const focusNm = focusItem? focusItem.focusNm : '체지방률'

    return (
        <div className={'card-content mb-12px'}>
            <div className={'mrml-24px mb-24px'}>
                <div className={'flex mt-24px mb-16px'}>
                    <p className={'s_title_2_m'}>나의 오늘 기록</p>
                    <p className={'s_title_2_m cl-primary-cyan01'}>&nbsp;3</p>
                </div>
                {Number(props.step.replaceAll(',','')) > 0
                    ?
                     <MissionRecord name={'걸음'} record={props.step} comparison={props.stepComparison}/>
                    :
                     <MissionRecord name={'걸음'} record={'휴식'} comparison={''} recordClass={'cl-gray06'}/>
                }
                {StringUtil.isNotEmpty(props.sleep)
                    ?
                    <MissionRecord name={'수면'} record={props.sleep} comparison={props.sleepComparison}/>
                    :
                    <MissionRecord name={'수면'} record={'휴식'} comparison={''} recordClass={'cl-gray06'}/>
                }
                {StringUtil.isNotEmpty(props.weight)
                    ?
                    <MissionRecord name={focusNm} record={props.weight} comparison={props.weightComparison}/>
                    :
                    <MissionRecord name={focusNm} record={focusNm + '측정하기'} comparison={''} recordClass={'cl-gray06'}/>
                }
            </div>
        </div>
    )
}

export default TodayRecord