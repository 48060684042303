import React, {useEffect, useState} from 'react'
import {useSearchParams, useNavigate, createSearchParams} from "react-router-dom";
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import BottomButton from "@/view/common/component/button/BottomButton";
import LinkHeader from "@/view/layout/LinkHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import StringUtil from "@/common/StringUtil";
import {LOGIN} from "@/common/Constant"


export const NoSignUpEmail = () => {
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()

    const [_email] = useState(searchParam.get("email"))
    const [_type] = useState(searchParam.get("type"))
    const [_prevType] = useState(searchParam.get("prevType"))
    const [msg,setMsg] = useState('')
    const goSignUp = () => {
        navigate({pathname: '/login', search: createSearchParams(new URLSearchParams({email: _email === null? '': _email, prevType: _type === null?'':_type})).toString()})
    }

    useEffect(() => {
        if(StringUtil.isNotEmpty(_type)){
            if(_type !== null)
                setMsg(LOGIN.HNAME[_type as keyof typeof LOGIN.HNAME])
        }
    },[])

    return (
        <>
            {
                _prevType === 'emailLogin' ?
                    <LinkHeader leftIcon={'prev-w'} leftLink={'/emailLogin'}/>
                :
                    <LinkHeader leftIcon={'prev-w'} leftLink={'/login'}/>
            }
            <HeaderSpacer className='bg-cl-black'/>
            <div id='SnsSignUpEmail' className={'bg-cl-black'}>
                <div className={'main-content text-left'}>
                    <div className={'h5_m title mt-39px'}>{msg}로<br/>로그인 해주세요</div>
                    <div className={'b2_r title mt-16px ft-cl-hint'}>{msg} 계정으로 가입한 이메일이에요.
                        <br/>
                        로그인 화면에서 {msg} 아이콘을 눌러주세요.</div>
                </div>
                <BottomButton title={'로그인 화면으로 돌아가기'} disabled={false} onClick={() => goSignUp()} />
            </div>
        </>
    )

}

export default NoSignUpEmail