import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Skeleton} from "@mui/material";
import Flicking from "@egjs/react-flicking";
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import moment from 'moment';
import WalkCard from "@/view/walk/component/WalkCard";
import StepService, {WalkCardData, WalkCardRes} from "@/service/StepService";
import {USER_ID} from "@/common/Constant";
import StorageUtil from "@/common/StorageUtil";
import Popup from "@/view/common/popup/Popup";
import PopUpService, {StepPopUpData, StepPopUpRes} from "@/service/PopUpService";
import ChangeGoal from "@/view/walk/component/ChangeGoal";
import RecommendGoal from "@/view/walk/component/RecommendGoal";

let flag = false;

const WalkDetail = forwardRef((props, ref) => {
    const userId = Number(StorageUtil.getLocalStorage(USER_ID)) as unknown as number
    const [walkCardDataList, setWalkCardDataList] = useState<WalkCardData[]>([])
    const ref2 = useRef<Flicking>(null)
    const [goalInfo, setGoalInfo] = useState({changeGoalPossibleYn: '', limitExceedYn: '', limitDay: 0, remainLimitDay: 0})
    const [buttonLabel, setButtonLabel] = useState('목표 바꾸기')
    const [goalInfoMessage, setGoalInfoMessage] = useState('')
    const [goalInfoMessageStyle, setGoalInfoMessageStyle] = useState({})
    const [changePopUpOpen, setChangePopUpOpen] = useState(false)
    const [goalRecommendOpen, setGoalRecommendOpen] = useState(false)
    const [popupInfo, setPopUpInfo] = useState<StepPopUpData>({
        goalDiff: 0,
        nickNm: '',
        popupType: '',
        popupYn: 'N',
        recommendGoal: 0,
        stepDataYn: '',
        stepGoal: 0
    })

    const {refetch: getCardData, data: cardDataRes} = useQuery<WalkCardRes, AxiosError>(
        ['getWalkCardData'],
        ()=> StepService.getWalkCardData({fromDate:moment().format('YYYY-MM-DD HH:mm:ss'), limit:7, start:0, userId: userId}),
        {
            onSuccess: (v: WalkCardRes) => onSuccess(v)
        }
    )

    const {refetch: getStepPopUp} = useQuery<StepPopUpRes, AxiosError>(
        ['getStepPopUp'],
        ()=> PopUpService.getPersonalStepPopUp(userId),
        {
            onSuccess: (v: StepPopUpRes) => checkStepPopUp(v.data)
        }
    )

    const onSuccess = (v: WalkCardRes) => {
        setWalkCardDataList(v.data)
        ref2.current?.forceUpdate()
    }

    const checkStepPopUp = (data: StepPopUpData) => {
        setPopUpInfo({...data})
        if(data.popupYn === 'Y'){
            setGoalRecommendOpen(true)
        }
    }

    useEffect(() => {
        if(cardDataRes){
            onSuccess(cardDataRes)
        }else{
            initData()
        }
    }, [])

    const initData = () => {
        getCardData()
            .then(r => updateGoalInfo(r.data))
            .catch(reason => {console.log(reason)})
        getStepPopUp()
            .then(r=> r)
            .catch(reason => console.log(reason))
    }

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            initData()
        }
    }))

    const updateGoalInfo = (data: WalkCardRes | undefined) => {
        if(data !== undefined) {
            const list:WalkCardData[] = data.data
            if(list.length > 0) {
                setGoalInfo({changeGoalPossibleYn: list[0].changeGoalPossibleYn
                                    , limitExceedYn: list[0].limitExceedYn
                                    , limitDay: list[0].limitDay
                                    , remainLimitDay: list[0].remainLimitDay})
                if(list[0].limitExceedYn === 'N') {
                    setButtonLabel(list[0].remainLimitDay.toString() + '일 뒤 목표를 변경하실 수 있어요')
                    setGoalInfoMessage('목표는 ' + list[0].limitDay.toString() + '일에 한 번 바꾸실 수 있어요')
                }
                if(list[0].changeGoalPossibleYn === 'N'){
                    setGoalInfoMessage('미션 기간이 끝나면 목표 바꾸기를 시작할 수 있어요!')
                }
            }
        }
    }

    const changeGoalPopUpOpen = async () => {
        if (flag) {
            return
        } else {
            flag = true
            await checkChangePossible().then(()=> flag = false).finally(() => flag = false)
        }
    }

    const checkChangePossible = () => {
        return new Promise((resolve)=> {
            if (goalInfo.changeGoalPossibleYn === 'Y' && goalInfo.limitExceedYn === 'Y') {
                setChangePopUpOpen(true)
                resolve('')
            } else {
                setGoalInfoMessageStyle({
                    display: 'block'
                })
                setTimeout(function () { // Code here
                    setGoalInfoMessageStyle({
                        display: 'none'
                    })
                    resolve('')
                }, 2000)
            }
        })
    }

    const selfChange = () => {
        setGoalRecommendOpen(false)
        setChangePopUpOpen(true)
    }

    return (
        <>
            <div className='walk-content mrml-24px pt-55px pb-60px'>
                <div className='w-100p'>
                    <h4 className='h4_l'>WALK CARD</h4>
                    <div className='walk-card w-100p'>
                        <Flicking bounce={1}
                                  circular={false}
                                  cameraClass={'main-content'}
                                  hanger={'0'}
                                  align={'prev'}
                                  moveType={['strict', { count: 1 }]}
                                  changeOnHold={false}
                                  bound={false}
                                  ref={ref2}
                        >
                            {
                                walkCardDataList.length > 0 ?
                                    walkCardDataList.map((data, index) => {
                                        return <div key={index}>
                                            <WalkCard msreDtm={data.msreDtm}
                                                      moveDist={data.moveDist}
                                                      distMsg={data.distMsg}
                                                      stepCnt={data.stepCnt}
                                                      stepMsg={data.stepMsg}
                                                      moveSpeed={data.moveSpeed}
                                                      speedMsg={data.speedMsg}
                                                      burnedCalorie={data.burnedCalorie}
                                                      calorieMsg={data.calorieMsg}
                                                      goalStepCnt={data.goalStepCnt}
                                                      achievementRate={data.achievementRate}
                                                      changeGoalPossibleYn={data.changeGoalPossibleYn}
                                                      limitExceedYn={data.limitExceedYn}
                                                      limitDay={data.limitDay}
                                                      remainLimitDay={data.remainLimitDay}/>
                                        </div>
                                    })

                                :
                                    <>
                                        <div key={1000}>
                                            <div className={'mt-14px mr-10px'}>
                                                <Skeleton variant="rounded" width={284} height={404}/>
                                            </div>
                                        </div>

                                        <div key={1001}>
                                            <div className={'mt-14px'}>
                                                <Skeleton variant="rounded" width={284} height={404}/>
                                            </div>
                                        </div>
                                    </>
                            }

                        </Flicking>
                    </div>
                    <div className={'mt-34px round-button-wrapper'}>
                        <div className={'button-info'} style={goalInfoMessageStyle}>
                            <p>{goalInfoMessage}</p>
                        </div>
                        <button className={goalInfo.changeGoalPossibleYn === 'Y' && goalInfo.limitExceedYn === 'Y'? '' :'off'} disabled={false} onClick={() => void changeGoalPopUpOpen()}>{buttonLabel}</button>
                    </div>
                </div>
            </div>
            <Popup className={'bg-cl-white'} isOpen={changePopUpOpen}
                   content={<ChangeGoal limitDay = {goalInfo.limitDay}
                                        stepGoal = {popupInfo? popupInfo.stepGoal :0}
                                        recommendGoal = {popupInfo? popupInfo.recommendGoal :0}
                                        refresh={() => initData()}
                                        userId = {userId}
                                        onClose={() => setChangePopUpOpen(!changePopUpOpen)}/>}/>
            <Popup className={'bg-cl-white'} isOpen={goalRecommendOpen}
                   content={<RecommendGoal data = {popupInfo}
                                           onClose = {() => setGoalRecommendOpen(!goalRecommendOpen)}
                                           refresh = {() => initData()}
                                           selfChange={() => selfChange()}
                                           userId = {userId}/>}/>
        </>
    )
})

export default WalkDetail