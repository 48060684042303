import React, {useEffect, useRef, useState} from 'react'
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import BottomButton from "@/view/common/component/button/BottomButton";
import Input from "@/view/common/component/Input";
import Icon from "@/view/common/component/Icon";
import UserService, {SvcUser, SvcUserDataRes} from "@/service/UserService";
import StorageUtil from "@/common/StorageUtil";
import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    USER_ID,
    USER_INFO,
    LOGIN,
    MNBR_NTRY_SE_CD,
    FOCUS_ITEM,
    LINK_DVIC
} from "@/common/Constant";
import StringUtil from "@/common/StringUtil";
import Toast from "@/view/common/popup/Toast";
import ValidateUtil from "@/common/ValidateUtil";
import {DefaultResult} from "@/service/RothyClient";

const EmailLogin = () => {
    const navigate = useNavigate()
    const defaultTheme = 'dark'
    const messageList = {
        'email': {'label':'이메일', 'name':'email', 'title': '메일 주소를 입력해주세요', 'subTitle': '가입할 때 사용한 이메일을 입력해 주세요', 'index':0},
        'pwd': {'label':'비밀번호', 'name':'pwd', 'title': '비밀번호를 입력해주세요', 'subTitle': '영문, 숫자 포함 10자 이상이에요', 'index':1},
    }
    const [isOpen2, setIsOpen2] = useState(false)
    const [message,setMessage] = useState(messageList.email)

    const [searchParam] = useSearchParams()
    const searchParamEmail = searchParam.get("email")
    const [email, setEmail] = useState({value: searchParamEmail !== null ? searchParamEmail :'', errMsg: ''})
    const [pwd, setPwd] = useState({value:'', errMsg: ''})
    const [disable, setDisable] = useState(true)
    const [typePassword, setTypePassword] = useState('password')
    const inputRefs = useRef<HTMLInputElement[]>([])

    const {refetch: emailSignIn} = useQuery<DefaultResult, AxiosError>(
        ['emailSignIn'],
        ()=> UserService.emailSignIn(email.value, pwd.value),
        {
            onSuccess: (data: SvcUser) => {
                signInSuccess(data).then(() => {
                    return Promise<void>
                }).catch(_reason => {
                    console.log(_reason)
                })
            }
        }
    )

    const {refetch: userSignUpCheck} = useQuery<any, AxiosError>(
        ['userSignUpCheck'],
        ()=> UserService.userSignUpCheck(email.value),
        {
            onSuccess: (data: DefaultResult) => {
                checkSuccess(data)
            }
        }
    )

    const clickLogin = async() => {
        await emailSignIn()
    }

    const checkSuccess = (data: SvcUserDataRes) => {
        if(data.data.mnbrNtrySeCd === undefined){
            const _email = email.value
            navigate({pathname: '/noSignupEmail', search: createSearchParams({email: _email}).toString()})
        }else if(data.data.mnbrNtrySeCd === LOGIN.TYPE.EMAIL){
            setIsOpen2(true)
        }else {
            const _email = data.data.userEmail
            const _type = data.data.mnbrNtrySeCd
            navigate({pathname: '/snsSignUpEmail', search: createSearchParams({email: _email, type:_type, prevType:'emailLogin'}).toString()})
        }
    }

    const signInSuccess = async (data: SvcUser) => {
        if(data.data === undefined){
            await userSignUpCheck()
        }else{
            StorageUtil.setLocalStorage(MNBR_NTRY_SE_CD, LOGIN.TYPE.EMAIL)
            StorageUtil.setLocalStorage(ACCESS_TOKEN, data.data.token)
            StorageUtil.setLocalStorage(REFRESH_TOKEN, data.data.refreshToken)
            StorageUtil.setLocalStorage(USER_ID, data.data.userId.toString())
            StorageUtil.setLocalStorage(FOCUS_ITEM, StringUtil.stringify(data.data.focusItem))
            StorageUtil.setLocalStorage(LINK_DVIC, StringUtil.stringify(data.data.linkDvic))
            StorageUtil.setLocalStorage(USER_INFO,StringUtil.stringify(data.data))
            navigate('/main')
        }

    }

    const validateEmail = (value:string) =>{
        const result = ValidateUtil.checkValidEmail(value)
        setEmail({value: result.value, errMsg: result.errMsg})
    }

    const validatePwd = (value:string) =>{
        const result = ValidateUtil.checkValidPwd(value)
        setPwd({value: result.value, errMsg: result.errMsg})
    }

    const clickLook = () => {
        if(typePassword === 'text'){
            setTypePassword('password')
        }else{
            setTypePassword('text')
        }
    }

    useEffect(()=> {
        if(StringUtil.isEmpty(email.value) || StringUtil.isEmpty(pwd.value)){
            setDisable(true)
        }else if(StringUtil.isNotEmpty(email.errMsg) || StringUtil.isNotEmpty(pwd.errMsg)){
            setDisable(true)
        }else{
            setDisable(false)
        }
    }, [email, pwd])

    const goNextInput = async (name:string) => {
        const index = messageList[name as keyof typeof messageList].index
        const nextIndex = index + 1;
        if(inputRefs.current.length !== nextIndex) {
            inputRefs.current[Number(nextIndex)].focus();
        } else{
            if(!disable){
                await clickLogin()
            }
        }
    }

    return (
        <>
            <div id='NoSignUpEmail' className={'bg-cl-black'}>
                <div className={'main-content'}>
                    <div className={'h5_m title mt-39px'}>{message.title}</div>
                    <div className={'b2_r title mt-16px ft-cl-hint'}>{message.title}</div>
                    <div className={'input-wrapper'}>
                        <div className={'b1_r input-item'}>
                            <Input theme={defaultTheme}
                                   label={messageList.email.label}
                                   name={messageList.email.name}
                                   value={email.value}
                                   rightContent={<div className={'click-area'} onClick={() => setEmail({value: '', errMsg: ''})}><Icon iconClass={'input-delete-d h-17px'}/></div>}
                                   onChange={(ev) => setEmail(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                                   required={true}
                                   onBlur={() => validateEmail(email.value)}
                                   onKeyUp={() => validateEmail(email.value)}
                                   onClick={() => setMessage(messageList.email)}
                                   goNext = {(name:string) => goNextInput(name)}
                                   errorMsg={email.errMsg}
                                   ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.email.index]= ref)}
                            >
                                {email.value}
                            </Input>
                        </div>
                        <div className={'b1_r input-item'}>
                            <Input theme={defaultTheme}
                                   label={messageList.pwd.label}
                                   name={messageList.pwd.name}
                                   value={pwd.value}
                                   rightContent={<div className={'click-area'} onClick={() => clickLook()}><Icon iconClass={'ic-look h-17px'}/></div>}
                                   onChange={(ev) => setPwd(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                                   required={true}
                                   onBlur={() => validatePwd(pwd.value)}
                                   onKeyUp={() => validatePwd(pwd.value)}
                                   onClick={() => setMessage(messageList.pwd)}
                                   goNext = {(name:string) => goNextInput(name)}
                                   errorMsg={pwd.errMsg}
                                   type={typePassword}
                                   ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.pwd.index]= ref)}
                            >
                                {pwd.value}
                            </Input>
                        </div>
                    </div>
                </div>
                {
                    !disable ?
                        <BottomButton title={'다음'} disabled={false} onClick={() => clickLogin()} />
                        :
                        <BottomButton title={'다음'} disabled={true} />
                }

            </div>
            <Toast content={<div style={{lineHeight:'1.5'}}>회원정보와 일치하지 않아요.<br/>이메일과 비밀번호를 확인해주세요.</div>}  isOpen={isOpen2} onClose={ () => setIsOpen2(false) }></Toast>
        </>
    )
}

export default EmailLogin