import React, {useEffect, useRef, useState} from 'react'
import moment from "moment";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import {Skeleton} from "@mui/material";
import {VitaIndexDetailData} from "@/service/VitaIndexService";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import {USER_INFO} from "@/common/Constant";
import {UserInfo} from "@/view/menu/MenuView";
import Icon from "@/view/common/component/Icon";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export interface PropsConfig{
    ref?:string,
    vitaIndexDetail?:VitaIndexDetailData

}

export type ChartDataSet = {
    backgroundColor?: string,
    borderColor?: string,
    data: number[] | object[] | undefined[] | undefined,
    borderRadius?:number,
    borderWidth?:number,
    pointRadius?:number,
}

const VitaIndexScore = (props: PropsConfig) => {
    const [data, setData] = useState<{labels:string[], datasets:ChartDataSet[]}>()
    const [options, setOptions] = useState({})
    const reportHeight = 240
    const chartDivRef = useRef<HTMLDivElement>(null)
    const divWidth = chartDivRef.current ? chartDivRef.current.clientWidth : window.innerWidth - 48
    const reportWidth = divWidth-48;

    const [todayContent] = useState<string | undefined>(moment().format('YY년 MM월 DD일'));
    const [nickNameContent, setNickNameContent] = useState<string | undefined>(undefined);
    const [totalScore, setTotalScore] = useState(0)
    const [totalScoreDiff, setTotalScoreDiff] = useState(0)
    const [walkScore, setWalkScore] = useState(0)
    const [walkScoreDiff, setWalkScoreDiff] = useState(0)
    const [sleepScore, setSleepScore] = useState(0)
    const [sleepScoreDiff, setSleepScoreDiff] = useState(0)
    const [bodyScore, setBodyScore] = useState(0)
    const [bodyScoreDiff, setBodyScoreDiff] = useState(0)

    const [vitaIndexDetail, setVitaIndexDetail] = useState(props.vitaIndexDetail)

    const [dailyWalkingPercent, setDailyWalkingPercent] = useState(0)
    const [improvedWalkingPercent, setImprovedWalkingPercent] = useState(0)
    const [dailySleepPercent, setDailySleepPercent] = useState(0)
    const [improvedSleepPercent, setImprovedSleepPercent] = useState(0)
    const [dailyBodyPercent, setDailyBodyPercent] = useState(0)
    const [improvedBodyPercent, setImprovedBodyPercent] = useState(0)

    const [notiSleep, setNotiSleep] = useState(false)
    const [notiBody, setNotiBody] = useState(false)


    const createChart = (v:VitaIndexDetailData) => {
        const labelData1:number[] = []
        labelData1.push(v.dailyWalkingPercent)
        labelData1.push(v.improvedWalkingPercent)
        labelData1.push(v.dailySleepPercent)
        labelData1.push(v.improvedSleepPercent)
        labelData1.push(v.dailyBodyPercent)
        labelData1.push(v.improvedBodyPercent)
        setDetail(v)
        updateData(labelData1)
        updateOptions()
    }

    const setDetail = (v:VitaIndexDetailData) => {
        setDailyWalkingPercent(v.dailyWalkingPercent)
        setImprovedWalkingPercent(v.improvedWalkingPercent)
        setDailySleepPercent(v.dailySleepPercent)
        setImprovedSleepPercent(v.improvedSleepPercent)
        setDailyBodyPercent(v.dailyBodyPercent)
        setImprovedBodyPercent(v.improvedBodyPercent)

        setTotalScore(v.todayTotalScore)
        setTotalScoreDiff(v.totalScoreVariance)
        setWalkScore(v.todayWalkingScore)
        setWalkScoreDiff(v.walkingScoreVariance)
        setSleepScore(v.todaySleepScore)
        setSleepScoreDiff(v.sleepScoreVariance)
        setBodyScore(v.todayBodyScore)
        setBodyScoreDiff(v.bodyScoreVariance)

        if(v.todaySleepScore === 0){
            setNotiSleep(true)
        }else if(v.todayBodyScore === 0){
            setNotiBody(true)
        }else{
            setNotiSleep(false)
            setNotiBody(false)
        }
    }
    const updateData = (labelData1:number[]) => {
        setData({
            labels: ['', '','','','',''], // 걸음일상, 걸음개선, 수면일상, 수면개선, 체성분일상. 체성분개선
            datasets: [
                {
                   data: labelData1,
                    backgroundColor: 'rgba(36,203,170,0.5)',
                    borderColor: '#24CBAA',
                    borderWidth: 1,
                    pointRadius: 0,
                },
            ],
        })
    }

    const updateOptions = () => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: false,
                datalabels: false,
            },
            scales: {
                r: {
                    max: 100,
                    min: 0,
                    ticks: {
                        stepSize: 25,
                        display: false
                    },
                    angleLines: {
                        display: false
                    },
                },

            }
        })
    }

    useEffect(() => {
        if(props.vitaIndexDetail){
            setVitaIndexDetail(props.vitaIndexDetail)
        }
    },[props.vitaIndexDetail])

    useEffect(()=>{
        if(vitaIndexDetail){
            createChart(vitaIndexDetail)
        }
    },[vitaIndexDetail])

    useEffect(()=>{
        if(!nickNameContent){
            const userInfo: UserInfo  = StringUtil.jsonString2ObjectWithMap<UserInfo>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
            const userNickNm = userInfo?.nickNm
            setNickNameContent(userNickNm?.length>6?userNickNm.substring(0,6)+'...':userNickNm)
        }

    },[])

    return (
        <div className={'mrml-24px mb-60px mt-16px'}>
            <div className={'b1_m'}>{todayContent}</div>
            <div className={'h6_b mt-4px'}>{nickNameContent}님의 건강 습관 점수</div>
            <div className={'flex-column w-100p-48px'} style={{position: 'absolute'}}>
                <div className={'c1_m cl-gray03 mt-50px text-center'}>걸음 일상</div>
                <div className={'s_title_2_m cl-primary-cyan01 pt-3px text-center'}>{dailyWalkingPercent}%</div>
                <div className={'flex justify-between mt-36px'}>
                    <div className={'flex-column'}>
                        <div className={'c1_m cl-gray03 text-right'}>체성분 개선</div>
                        <div className={'s_title_2_m cl-primary-cyan01 pt-3px text-right'}>{improvedBodyPercent}%</div>
                    </div>
                    <div className={'flex-column'}>
                        <div className={'c1_m cl-gray03 text-left'}>걸음 개선</div>
                        <div className={'s_title_2_m cl-primary-cyan01 pt-3px text-left'}>{improvedWalkingPercent}%</div>
                    </div>
                </div>
                <div className={'flex justify-between mt-74px'}>
                    <div className={'flex-column'}>
                        <div className={'c1_m cl-gray03 text-right'}>체성분 일상</div>
                        <div className={'s_title_2_m cl-primary-cyan01 pt-3px text-right'}>{dailyBodyPercent}%</div>
                    </div>
                    <div className={'flex-column'}>
                        <div className={'c1_m cl-gray03 text-left'}>수면 일상</div>
                        <div className={'s_title_2_m cl-primary-cyan01 pt-3px text-left'}>{dailySleepPercent}%</div>
                    </div>
                </div>
                <div className={'c1_m cl-gray03 text-center mt-24px'}>수면 개선</div>
                <div className={'s_title_2_m cl-primary-cyan01 pt-3px text-center'}>{improvedSleepPercent}%</div>
            </div>
            <div className={'mt-84px flex justify-center w-100p'} ref={chartDivRef}>
                {
                    data ?
                        <div style={{position: 'relative', zIndex: '2', width:String(reportWidth)+'px'}} >
                            <Radar data={data} options={options}  height={String(reportHeight)+'px'} width={String(reportWidth)+'px'}/>
                        </div>
                        :
                        <Skeleton variant="rounded" height={reportHeight} width={'100%'}/>
                }
            </div>
            <div className={'flex justify-center'}>
                <div className={'h4_r mt-100px'}>종합 {totalScore}점</div>
            </div>
            <div className={'flex justify-center mt-4px'}>
                <div className={'flex'}>
                    {totalScoreDiff > 0 && <><div className={'b2_r mr-4px'}>어제보다</div><div className={'b2_m'} style={{color:'#F05F5F'}}>+{totalScoreDiff}점</div></>}
                    {totalScoreDiff === 0 && <div className={'b2_r mr-4px'}>어제와 같음</div>}
                    {totalScoreDiff < 0 && <><div className={'b2_r mr-4px'}>어제보다</div><div className={'b2_m'} style={{color:'#4586F4'}}>{totalScoreDiff}점</div></>}
                </div>
            </div>
            <div className={'flex justify-between mt-40px'}>
                <div className={'bg-cl-gray-8'} style={{height:'112px', width:'32%'}}>
                    <div className={'mt-16px ml-16px'}>
                        <div className={'b2_m'} style={{color:'#616161'}}>걸음</div>
                        <div className={'h6_m mt-8px'}>{walkScore}</div>
                        {walkScoreDiff > 0 && <div className={'b2_m'} style={{color:'#F05F5F'}}>+{walkScoreDiff}</div>}
                        {walkScoreDiff === 0 && <div className={'b2_m'}>-</div>}
                        {walkScoreDiff < 0 && <div className={'b2_m'} style={{color:'#4586F4'}}>{walkScoreDiff}</div>}
                    </div>
                </div>
                <div className={'bg-cl-gray-8'} style={{height:'112px', width:'32%'}}>
                    <div className={'mt-16px ml-16px'}>
                        <div className={'b2_m'} style={{color:'#616161'}}>수면</div>
                        <div className={'h6_m mt-8px'}>{sleepScore}</div>
                        {sleepScoreDiff > 0 && <div className={'b2_m'} style={{color:'#F05F5F'}}>+{sleepScoreDiff}</div>}
                        {sleepScoreDiff === 0 && <div className={'b2_m'}>-</div>}
                        {sleepScoreDiff < 0 && <div className={'b2_m'} style={{color:'#4586F4'}}>{sleepScoreDiff}</div>}
                    </div>
                </div>
                <div className={'bg-cl-gray-8'} style={{height:'112px', width:'32%'}}>
                    <div className={'mt-16px ml-16px'}>
                        <div className={'b2_m'} style={{color:'#616161'}}>체성분</div>
                        <div className={'h6_m mt-8px'}>{bodyScore}</div>
                        {bodyScoreDiff > 0 && <div className={'b2_m'} style={{color:'#F05F5F'}}>+{bodyScoreDiff}</div>}
                        {bodyScoreDiff === 0 && <div className={'b2_m'}>-</div>}
                        {bodyScoreDiff < 0 && <div className={'b2_m'} style={{color:'#4586F4'}}>{bodyScoreDiff}</div>}
                    </div>
                </div>
            </div>
            <div className={'vitaindex-noti'}>
            {notiSleep &&
                <div className={'flex justify-center mt-16px'}>
                    <Icon iconClass={'vitaindex-noti-sleep w-256px h-53px'}></Icon>
                </div>
            }
            {notiBody &&
                <div className={'flex justify-flex-end mt-16px'}>
                    <Icon iconClass={'vitaindex-noti-body w-282px h-53px'}></Icon>
                </div>
            }
            </div>
        </div>
    )
}

export default VitaIndexScore