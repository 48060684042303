type iconParam = {
    iconClass: string,
};

const Icon = (props: iconParam) => {
    return(
        <div className='icon'>
            <span className={props.iconClass}></span>
        </div>
    )
}

export default Icon;
