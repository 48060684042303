import React, { useState } from 'react';

import RothyNativeService, {
    RNIActions, RNIError, RNIMessageConfig,
} from "@/service/rothynative/RothyNativeService";
import * as RNIAppleSignIn from "@/service/rothynative/RNIAppleSignIn";

const AppleSignInSample: React.FC = (): JSX.Element => {
    const [profile, setProfile] = useState<RNIAppleSignIn.AppleRequestResponse | undefined>(undefined)

    const onClickAppleSignIn = () => {
        const config = new RNIMessageConfig<void, RNIAppleSignIn.AppleRequestResponse>()
        config.action = RNIActions.APPLE_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIAppleSignIn.AppleRequestResponse) => {
                setProfile(response)
            })
            .catch((error: RNIError) => {
                if (error.code != RNIAppleSignIn.AppleError.CANCELED) {
                    console.log('cancel')
                } else {
                    alert(`${error.code || ''}::${error.message || ''}`)
                }
            })
    }

    return <>
        <button onClick={onClickAppleSignIn}>애플 로그인</button>
        <ul>
            <li><b>Access Token</b></li>
            <li>{profile?.identityToken ? profile?.identityToken : ''}</li>
            <li><b>계정 정보</b></li>
            {profile && (
                <>
                    <li>email: {profile?.email || ''}</li>
                    <li>성명: {`${profile?.fullName?.familyName || ''} ${profile?.fullName?.givenName || ''}`}</li>
                    <li>닉네임: {`${profile?.fullName?.nickname || ''}`}</li>
                </>
            )}
        </ul>
    </>
}

export default AppleSignInSample;