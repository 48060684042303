import React, {useEffect, useRef, useState} from 'react'
import Flicking from '@egjs/react-flicking'
import CharacterChart from "@/view/vitaindex/component/CharacterChart";
import CharacterAvgChart from "@/view/vitaindex/component/CharacterAvgChart";
import {ScoreFeatureData, ScoreFeatureDetail} from "@/service/VitaIndexService";
import {VitaIndexImage} from "@/common/Constant"

export interface PropsConfig {
    scoreFeature?:ScoreFeatureData
}

const ScoreCharacter = (props: PropsConfig) => {
    const ref = useRef<Flicking>(null)
    const [clickedIndex, setClickedIndex] = useState(0)
    const [scoreFeature, setScoreFeature] = useState(props.scoreFeature)
    const [selectedData, setSelectedData] = useState<ScoreFeatureDetail | undefined>(undefined)
    const [selectedImage, setSelectedImage] = useState<string | undefined>('')

    const selectButton = (index:number) =>{
        setClickedIndex(index)
    }

    useEffect(()=> {
        if(props.scoreFeature){
            setScoreFeature(props.scoreFeature)
            selectButton(0)
        }
    }, [props.scoreFeature])

    useEffect(()=>{
        if(ref.current !== null) {
            void ref.current.moveTo(0, 0)
        }

        if(clickedIndex !== undefined){
            switch(clickedIndex){
                case 0:
                    setSelectedData(scoreFeature?.total)
                    setSelectedImage(VitaIndexImage.scoreCharacter[scoreFeature?.total.firstCard.cardId] as string)
                    break
                case 1:
                    setSelectedData(scoreFeature?.walking)
                    setSelectedImage(VitaIndexImage.scoreCharacter[scoreFeature?.walking.firstCard.cardId] as string)
                    break
                case 2:
                    setSelectedData(scoreFeature?.sleep)
                    setSelectedImage(VitaIndexImage.scoreCharacter[scoreFeature?.sleep.firstCard.cardId] as string)
                    break
                case 3:
                    setSelectedData(scoreFeature?.body)
                    setSelectedImage(VitaIndexImage.scoreCharacter[scoreFeature?.body.firstCard.cardId] as string)
                    break
                default:
                    setClickedIndex(0)
                    setSelectedData(scoreFeature?.total)
            }

        }

    },[clickedIndex])

    return <div className={'mt-60px mrml-24px mb-60px'}>
                <div className={'mb-16px'}>
                    <span className={'s_title_1_m cl-gray01'}> 점수별 특징은?</span>
                </div>
                <div className={'mb-24px vitaIndex-button'}>
                    <button className={`${clickedIndex === 0? 'on' : ''}`} onClick={() => selectButton(0)}>종합</button>
                    <button className={`${clickedIndex === 1? 'on' : ''}`} onClick={() => selectButton(1)}>걸음</button>
                    <button className={`${clickedIndex === 2? 'on' : ''}`} onClick={() => selectButton(2)}>수면</button>
                    <button className={`${clickedIndex === 3? 'on' : ''}`} onClick={() => selectButton(3)}>체성분</button>
                </div>
                <div>
                    <Flicking bounce={1}
                              circular={false}
                              cameraClass={'main-content'}
                              hanger={'0'}
                              align={'prev'}
                              moveType={['strict', { count: 1 }]}
                              changeOnHold={false}
                              bound={false}
                              ref={ref}
                    >
                        <div key={0} className='characteristics-card'>
                            <div className={'w-100p'} style={{height: '229px'}}>
                                <img width={'100%'} height={'100%'} src={selectedImage}/>
                            </div>
                            <div className={'characteristics-card-content'}>
                                <span className={'h6_m cl-gray01'}>{selectedData?.firstCard.title}</span>
                                <span className={'b2_r cl-gray03 mt-13px'}>{selectedData?.firstCard.comment}</span>
                            </div>
                        </div>
                        <div key={1} className='characteristics-card'><CharacterChart scoreFeatureDetailSecondCard={selectedData?.secondCard}/></div>
                        <div key={2} className='characteristics-card'><CharacterAvgChart scoreFeatureDetail={selectedData}/></div>
                    </Flicking>
                </div>
            </div>
}

export default ScoreCharacter
