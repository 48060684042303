import moment from "moment-timezone";
import UserMissionService, {Item, MsreDate} from "@/service/UserMissionService";
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import {DATE_FORMAT, ITEM_CD, OS_TYPE, USER_ID} from "@/common/Constant";
import {RHParamHistory, RHQuantity, RHSets, RHSleep} from "@/service/rothynative/RNIHealth";
import StorageUtil from "@/common/StorageUtil";
import RothyFireBase from '@/service/FireBaseService'
import {rothyClient} from "@/service/RothyClient";
import InterfaceUtil from "@/common/Interface";

const rotyFireBase : RothyFireBase = RothyFireBase.getInstance()

const putSleepHistory = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!InterfaceUtil.isAppRunning()) {
            resolve()
            return
        }

        const userId = Number(StorageUtil.getLocalStorage(USER_ID))

        rotyFireBase
            .isCheckMissionStepHistory(userId)
            .then((isCheckMission: boolean) => {
                if (isCheckMission) {
                    resolve()
                } else {
                    UserMissionService
                        .getLastHistoryMserDate()
                        .then((msreData: MsreDate) => {
                            const itemList: Item[] = msreData.data.itemList
                            const findIndex = itemList.findIndex(element => ITEM_CD.SLEEP.match(element.mgtItemCd))
                            const startDate = moment(msreData.data.itemList[findIndex].msreDtm).utc().format(DATE_FORMAT.DATE_TIME_UTC)
                            const endDate = moment(new Date()).utc().format(DATE_FORMAT.DATE_TIME_UTC)

                            return getSleepHitoryData(startDate, endDate)
                        })
                        .then(r2 => {
                            return meargeSleepHistoryData(r2)
                        })
                        .then(r3 => {
                            return putSleepHistoryData(r3)
                        })
                        .then(() => {
                            resolve()
                        })
                        .catch(err => {
                            reject(err)
                        })
                }
            })
            .catch(e => reject(e))
    })
}

/**
 * RN에 수면 및 산소포화도 데이터 요청
 * @param startDate
 * @param endDate
 */
const getSleepHitoryData = (startDate: string, endDate: string): Promise<(RHQuantity[])[]> => {
    return new Promise((resolve, reject) => {
        // 수면
        const sleepParam = new RHParamHistory()
        sleepParam.quantityType = RHSets.sleepAnalysis.history.quantityType
        sleepParam.startDate = startDate
        sleepParam.endDate = endDate

        const sleepConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        sleepConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        sleepConfig.data = sleepParam

        // 산소포화도
        const oxygenParam = new RHParamHistory()
        oxygenParam.quantityType = RHSets.oxygenSaturation.history.quantityType
        oxygenParam.startDate = startDate
        oxygenParam.endDate = endDate

        const oxygenConfig = new RNIMessageConfig<RHParamHistory, Array<RHQuantity>>()
        oxygenConfig.action = RNIActions.GET_HEALTH_DATA_HISTORY
        oxygenConfig.data = oxygenParam

        const sleepPromise = RothyNativeService.getInstance().postMessage(sleepConfig)
        const oxygenPromise = RothyNativeService.getInstance().postMessage(oxygenConfig)

        Promise.all([sleepPromise, oxygenPromise])
            .then((values) => {
                resolve(values)
            })
            .catch((e: RNIError) => reject(e))
    })
}

/**
 * RN으로 응답받은 수면, 산소포화도 데이터를 병합
 * @param values
 */
const meargeSleepHistoryData  = (values : (RHQuantity[])[]) : Promise<SleepHistoryData> => {
    return new Promise((resolve) => {
        const sleepResponse = values[0]
            // RHSleep.inBed의 값은 전체 취침시간이기에 categoryValue 값이 RHSleep.inBed인 경우는 데이터에서 제거한다.
            .filter((value) => value.categoryValue !== RHSleep.inBed)
            .map((value, index) => {
                const returnValue = new SleepDataByTime()
                returnValue.msreBeginDtm = moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME)
                returnValue.msreEndDtm = moment(`${value.endDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME)
                switch (value.categoryValue) {
                    // case RHSleep.inBed:
                    //     returnValue.sleepStatCd = 40002
                    //     break;
                    // case RHSleep.aSleepUnspecified:
                    //     returnValue.sleepStatCd = 40002
                    //     break;
                    case RHSleep.awake:
                        returnValue.sleepStatCd = 40001
                        break;
                    case RHSleep.asleepCore:
                        returnValue.sleepStatCd = 40002
                        break;
                    case RHSleep.asleepDeep:
                        returnValue.sleepStatCd = 40003
                        break;
                    case RHSleep.asleepREM:
                        returnValue.sleepStatCd = 40004
                        break;
                    default:
                        returnValue.sleepStatCd = 40002
                        break;
                }

                return returnValue
            })
        const oxygenResponse = values[1].map((value, index) => {
            // 애플헬스의 산소포화도는 일자별이 아닌 개별적인 데이터에는 min, max 값이 없기에 min, max값을 동일하게 설정한다.
            const returnValue = new OxygenDataByTime()
            returnValue.msreBeginDtm = moment(`${value.startDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME)
            returnValue.msreEndDtm = moment(`${value.endDate}Z`).tz("Asia/Seoul").format(DATE_FORMAT.DATE_TIME)

            if (InterfaceUtil.getOs() === OS_TYPE.IOS) {
                returnValue.oxygenMax = value.quantity * 100
                returnValue.oxygenMin = value.quantity * 100
            } else if (InterfaceUtil.getOs() === OS_TYPE.ANDROID) {
                returnValue.oxygenMax = value.quantityMax * 100
                returnValue.oxygenMin = value.quantityMin * 100
            }

            return returnValue
        })

        const sleepHistoryData = new SleepHistoryData()
        sleepHistoryData.healthSyncAgree = "Y"
        sleepHistoryData.userId = Number(StorageUtil.getLocalStorage(USER_ID))
        sleepHistoryData.sleepStage = sleepResponse
        sleepHistoryData.oxygenData = oxygenResponse

        resolve(sleepHistoryData)
    })
}

/**
 * SLEEP 풋매저
 * @param meargeData
 */
const putSleepHistoryData = (meargeData: SleepHistoryData) : Promise<void> => {
    return new Promise((resolve, reject) => {
        rothyClient
            .post<any>("/measure/sleep", meargeData)
            .then(() => { resolve() })
            .catch((err) => { reject(err) })
    })
}

const PutSleepHistoryService = {
    putSleepHistory
}

export class SleepDataByTime {
    msreBeginDtm?: string
    msreEndDtm?: string
    sleepStatCd?: number
}

export class OxygenDataByTime {
    msreBeginDtm?: string
    msreEndDtm?: string
    oxygenMin?: number
    oxygenMax?: number
    oxygenLowDuration?: number
}

export class SleepHistoryData {
    healthSyncAgree?:string
    sleepStage?: SleepDataByTime[]
    oxygenData?: OxygenDataByTime[]
    userId?:number
}

export default  PutSleepHistoryService
