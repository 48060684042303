import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import Flicking from "@egjs/react-flicking"
import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import {Skeleton} from "@mui/material"
import WeightService, {WeightInfoData, WeightInfoRes} from "@/service/WeightService"
import WeightCard from "@/view/weight/component/WeightCard"
import StorageUtil from "@/common/StorageUtil"
import {FOCUS_ITEM, USER_ID} from "@/common/Constant"
import StringUtil from "@/common/StringUtil"
import {FocusItem} from "@/service/UserService"

interface DetailProps {
    onOpen: () => void
}

const WeightDetail = forwardRef((props:DetailProps, ref) => {
    const userId = Number(StorageUtil.getLocalStorage(USER_ID)) as unknown as number
    const focusItem:FocusItem = StringUtil.jsonString2ObjectWithMap<FocusItem>(StorageUtil.getLocalStorage(FOCUS_ITEM) as unknown as '')
    const focusCd = focusItem? focusItem.focusCd : 'BDFAT_RATE'
    const focusNm = focusItem? focusItem.focusNm : '체지방률'
    const focusUnit = focusItem? focusItem.focusUnit : '%'
    const [weightCardInfoList, setWeightCardInfoList] = useState<WeightInfoData[]>([])
    const ref2 = useRef<Flicking>(null)
    const handleOverlayClick = useCallback(() => props.onOpen(), [props.onOpen])

    const {refetch: getCardData} = useQuery<WeightInfoRes, AxiosError>(
        ['getWeightInfo'],
        ()=> WeightService.getWeightInfo({userId: userId, selectType: focusCd}),
        {
            onSuccess: (v: WeightInfoRes) => onSuccess(v)
        }
    )

    const onSuccess = (v: WeightInfoRes) => {
        setWeightCardInfoList(v.data)
        ref2.current?.forceUpdate()
    }

    useEffect(() => {
        initData()
    }, [])

    useEffect(() => {
        initData()
    }, [focusItem])

    const initData = () => {
        getCardData()
            .then(r => r.data)
            .catch(reason => {console.log(reason)})
    }

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            initData()
        }
    }))

    return (
            <div id="WeightDetail">
                <div className={'weight-detail-title'}>
                    <h4 className='h4_l'>WEIGHT CARD</h4>
                    <h4 className='b2_r mb-4px cl-gray01' onClick={handleOverlayClick}>편집</h4>
                </div>
                <div className='weight-card'>
                    <Flicking bounce={1}
                              circular={false}
                              cameraClass={'main-content'}
                              hanger={'0'}
                              align={'prev'}
                              moveType={['strict', { count: 1 }]}
                              changeOnHold={false}
                              bound={false}
                              ref={ref2}
                    >
                        {weightCardInfoList.length > 0 ?
                            weightCardInfoList.map((data, index) => {
                                return <div key={index}>
                                    <WeightCard cardData={data}
                                                focusCd={focusCd}
                                                focusNm={focusNm}
                                                focusUnit={focusUnit === undefined ? '' : focusUnit}/>
                                </div>;
                            })
                            :
                            <>
                                <div key={1000}>
                                    <div className={'mt-14px mr-10px'}>
                                        <Skeleton variant="rounded" width={284} height={404}/>
                                    </div>
                                </div>

                                <div key={1001}>
                                    <div className={'mt-14px'}>
                                        <Skeleton variant="rounded" width={284} height={404}/>
                                    </div>
                                </div>
                            </>}
                    </Flicking>
                </div>
            </div>
    )
})

export default WeightDetail