import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import jwt_decode from "jwt-decode";
import DialogHeader from "@/view/layout/DialogHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import BottomButton from "@/view/common/component/button/BottomButton";
import Input from "@/view/common/component/Input";
import Icon from "@/view/common/component/Icon";
import StorageUtil from "@/common/StorageUtil";
import {
    ACCESS_TOKEN,
    APP_VERSION_NO,
    DEVICE_MODEL,
    MNBR_NTRY_SE_CD,
    REFRESH_TOKEN,
    USER_ID,
    USER_INFO
} from "@/common/Constant";
import Popup from "@/view/common/popup/Popup";
import UserService, {SvcUserSignUp, SvcUser, TermsAggreMents} from "@/service/UserService"
import StringUtil from "@/common/StringUtil";
import ValidateUtil from "@/common/ValidateUtil";
import GenderSelect from '@/view/common/component/GenderSelect'
import {DefaultResult} from "@/service/RothyClient";


export interface SignupDetailConfig {
    onClose: () => void
    termsAgreements: TermsAggreMents
    emailAgree: string
    email?: string
    prevType?: string
    uuid?: string
}

type DecodedToken = {
    email: string
}

type MessageType = {
    label: string
    name: string
    title: string
    subTitle: string
    index: number | undefined
}

const Signup = (props:SignupDetailConfig) => {
    const navigate = useNavigate()
    const messageList = {
        'email': {'label':'이메일', 'name':'email', 'title': '메일 주소를 입력해주세요', 'subTitle': '아이디로 쓰일 주소예요', 'index':0},
        'pwd': {'label':'비밀번호', 'name':'pwd', 'title': '비밀번호를 입력해주세요', 'subTitle': '영문, 숫자 포함 10자 이상이에요', 'index':1},
        'pwdCheck': {'label':'비밀번호', 'name':'pwdCheck', 'title': '비밀번호를 확인해주세요', 'subTitle': '영문, 숫자 포함 10자 이상이에요', 'index':2},
        'nickName': {'label':'닉네임', 'name':'nickNm', 'title': '닉네임을 입력해주세요', 'subTitle': '20자 이내로 입력해주세요', 'index':3},
        'tall': {'label':'키', 'name':'tall', 'title': '키를 알려주세요', 'subTitle': '소수점 첫 번째 자리까지 입력할 수 있어요', 'index':4},
        'gender': {'label':'성별', 'name':'gender', 'title': '성별을 선택해 주세요', 'subTitle': '', 'index':5},
        'birthday': {'label':'생년월일', 'name':'birthday', 'title': '생년월일을 입력해 주세요', 'subTitle': '8글자로 입력해주세요', 'index':6},
        'sns': {'label':'', 'name':'', 'title': 'SNS 회원가입', 'subTitle': '비밀번호 없이 사용하는 SNS 회원가입이에요', 'index':undefined},
        'complete': {'label':'', 'name':'', 'title': '정보를 확인 해 주세요', 'subTitle': '입력하신 정보가 맞나요?', 'index':undefined},
    }
    const [termsAgreements] = useState<TermsAggreMents>(props.termsAgreements)
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [message, setMessage] = useState<MessageType>(messageList.email)
    const defaultTheme = 'dark'


    const [_mnbrNtrySeCd] = useState(props.prevType?props.prevType:'')
    const [_uuid] = useState(props.uuid)
    const [email, setEmail] = useState({value:props.email?props.email:'', errMsg: ''})
    const [pwd, setPwd] = useState({value:'', errMsg: ''})
    const [pwdCheck, setPwdCheck] = useState({value:'', errMsg: ''})
    const [nickName, setNickName] = useState({value:'', errMsg: ''})
    const [tall, setTall] = useState({value:'', errMsg: ''})
    const [gender, setGender] = useState({value: '', errMsg:''})
    const [birthday, setBirthday] = useState({value:'', errMsg:''})

    const [typePassword, setTypePassword] = useState('password')
    const [typePassword2, setTypePassword2] = useState('password')
    const [disable, setDisable] = useState(true)

    const inputRefs = useRef<HTMLInputElement[]>([])

    useEffect(()=> {
        if(_uuid){
            let decodedToken:DecodedToken = {email:''}
            if(_mnbrNtrySeCd === 'A'){
                    decodedToken = jwt_decode(_uuid);
                    if(decodedToken.email){
                        setEmail({value:decodedToken.email, errMsg: ''})
                    }
            }
        }
    }, [_uuid])

    useEffect(()=> {
        if(_mnbrNtrySeCd === 'G' || _mnbrNtrySeCd === 'N' || _mnbrNtrySeCd === 'K' || _mnbrNtrySeCd === 'A'){
            pwd.value= _mnbrNtrySeCd
            pwdCheck.value= _mnbrNtrySeCd
            setMessage(messageList.sns as MessageType)
        }
    }, [_mnbrNtrySeCd])

    useEffect(()=> {
        if(StringUtil.isEmpty(email.value)
            || StringUtil.isEmpty(pwd.value)
            || StringUtil.isEmpty(pwdCheck.value)
            || StringUtil.isEmpty(nickName.value)
            || StringUtil.isEmpty(tall.value)
            || StringUtil.isEmpty(gender.value)
            || StringUtil.isEmpty(birthday.value)
        ){
            setDisable(true)
        }else if(StringUtil.isNotEmpty(email.errMsg)
            || StringUtil.isNotEmpty(pwd.errMsg)
            || StringUtil.isNotEmpty(pwdCheck.errMsg)
            || StringUtil.isNotEmpty(nickName.errMsg)
            || StringUtil.isNotEmpty(tall.errMsg)
            || StringUtil.isNotEmpty(gender.errMsg)
            || StringUtil.isNotEmpty(birthday.errMsg)
        ){
            setDisable(true)
        }else{
            setDisable(false)
            setMessage(messageList.complete as MessageType)
        }
    }, [email, pwd, pwdCheck, nickName, tall, gender, birthday])

    const deviceModel = StorageUtil.getLocalStorage(DEVICE_MODEL)
    const appVerNo = StorageUtil.getLocalStorage(APP_VERSION_NO)
    const svcUser = new SvcUserSignUp(
        1,
        '',
        '',
        '',
        0,
        '',
        '',
        '',
        '',
        '',
        0,
        60,
        deviceModel != null? deviceModel.indexOf('hone')>0?'iPhone':'Android' : '',
        'userDvic',
        appVerNo !== null? appVerNo: '',
        '',
        0,
        termsAgreements,
        '',
        '',
        '',
        props.emailAgree
    )

    const {refetch: signUp} = useQuery<DefaultResult, AxiosError>(
        ['signUp'],
        ()=> UserService.signUp(svcUser),
        {
            onSuccess: (data: SvcUser) => {
                signUpSuccess(data).then(() => {
                    return Promise<void>
                }).catch(_reason => {
                    console.log(_reason)
                })
            }
        }
    )

    const {refetch: getUser} = useQuery<SvcUser, AxiosError>(
        ['getUser'],
        ()=> UserService.getUser(),
        {
            onSuccess: (v: SvcUser) => {
                StorageUtil.setLocalStorage(USER_ID, v.data.userId.toString())
                StorageUtil.setLocalStorage(USER_INFO,StringUtil.stringify(v.data))
                void checkMission().then(r => r)
                }
            }
    )

    const {refetch: checkMission} = useQuery<SvcUser, AxiosError>(
        ['checkMission'],
        ()=> UserService.checkMission(Number(StorageUtil.getLocalStorage(USER_ID))),
        {
            onSuccess: () => goHealthConnectGuide()
        }
    )

    const signUpSuccess = async (data: SvcUser) => {
        if(data.result){
            StorageUtil.setLocalStorage(MNBR_NTRY_SE_CD, _mnbrNtrySeCd?_mnbrNtrySeCd:'E')
            StorageUtil.setLocalStorage(ACCESS_TOKEN, data.data.token)
            StorageUtil.setLocalStorage(REFRESH_TOKEN, data.data.refreshToken)
        }

        await getUser()
    }

    const goHealthConnectGuide = () => {
        navigate('/healthConnectGuide')
    }

    const [isOpen, setIsOpen] = useState(false)

    let flag = false
    const clickSignUp = async() => {
        if(flag) return
        flag = true
        svcUser.nickNm = nickName.value
        svcUser.userEmail = email.value
        svcUser.uuid = email.value // sns 로그인 이라면 전달받은 값 세팅하는 로직 필요함
        svcUser.pwd = pwd.value
        svcUser.tall = Number(tall.value)
        svcUser.gender = ValidateUtil.convertGenderValue(gender.value)
        svcUser.birthday = birthday.value.replaceAll('.','')

        svcUser.mnbrNtrySeCd = StringUtil.isNotEmpty(_mnbrNtrySeCd)?_mnbrNtrySeCd: 'E'
        const defaultUuid = _uuid? _uuid:''
        svcUser.uuid = StringUtil.isEmpty(_mnbrNtrySeCd) || _mnbrNtrySeCd === 'E' ? email.value: defaultUuid

        await signUp()
        flag = false
    }

    const clearBirthday = () => {
        setBirthday({value:'', errMsg:''})
    }

    const validateNickName = async (value: string) => {
        const result = ValidateUtil.checkValidNickName(value)
        if(StringUtil.isNotEmpty(result.value) && StringUtil.isEmpty(result.errMsg)){
            await ValidateUtil.checkDuplicate(result.value).then(res=> {
                if(!res) {
                    setNickName({value: result.value, errMsg: '사용하실 수 없는 닉네임이에요.'})
                }else{
                    setNickName(prevState => ({value: prevState.value, errMsg: ''}))
                }
            }).catch(() => {console.log('시스템 에러')})
        } else {
            setNickName({value:result.value, errMsg: result.errMsg})
        }

    }

    const clearNickName = () => {
        setNickName({value: '', errMsg: ''})
    }

    const validateTall = (value:string) => {
        const result = ValidateUtil.checkValidTall(value)
        setTall({value: result.value, errMsg: result.errMsg})
    }

    const validateBirthday = (value:string) =>{
        const result = ValidateUtil.checkValidBirthday(value)
        setBirthday({value: result.value, errMsg: result.errMsg})
    }

    const validateEmail = async(value:string) =>{
        const result = ValidateUtil.checkValidEmail(value)
        if(StringUtil.isNotEmpty(result.value) && StringUtil.isEmpty(result.errMsg)){
            await ValidateUtil.checkEmailDuplicate(result.value).then(res=> {
                if(!res) {
                    setEmail({value: result.value, errMsg: '다른 사람이 사용 중인 이메일이에요.'})
                }else{
                    setEmail(prevState => ({value: prevState.value, errMsg: ''}))
                }
            }).catch(() => {console.log('시스템 에러')})
        } else {
            setEmail({value:result.value, errMsg: result.errMsg})
        }
    }

    const validatePwd = (value:string) =>{
        const result = ValidateUtil.checkValidPwd(value)
        setPwd({value: result.value, errMsg: result.errMsg})
    }

    const validatePwdOnBlur = (value:string) =>{
        const result = ValidateUtil.checkValidPwd(value)
        setPwd({value: result.value, errMsg: result.errMsg})
        if(StringUtil.isEmpty(result.errMsg) && StringUtil.isNotEmpty(pwd.value) && StringUtil.isNotEmpty(pwdCheck.value)){
            if(pwd.value !== pwdCheck.value) {
                setPwd({value: result.value, errMsg: '비밀번호가 일치하지 않아요.'})
            }else{
                setPwdCheck({value: pwdCheck.value, errMsg: ''})
            }
        }

    }

    const validatePwdCheck = (value:string) =>{
        const result = ValidateUtil.checkValidPwd(value)
        setPwdCheck({value: result.value, errMsg: result.errMsg})
    }

    const validatePwdCheckOnBlur = (value:string) =>{
        const result = ValidateUtil.checkValidPwd(value)
        setPwdCheck({value: result.value, errMsg: result.errMsg})
        if(StringUtil.isEmpty(result.errMsg) && StringUtil.isNotEmpty(pwd.value) && StringUtil.isNotEmpty(pwdCheck.value)){
            if(pwd.value !== pwdCheck.value) {
                setPwdCheck({value: result.value, errMsg: '비밀번호가 일치하지 않아요.'})
            }else{
                setPwd({value: pwd.value, errMsg: ''})
            }
        }
    }

    const clickLook = () => {
        if(typePassword === 'text'){
            setTypePassword('password')
        }else{
            setTypePassword('text')
        }
    }

    const clickLook2 = () => {
        if(typePassword2 === 'text'){
            setTypePassword2('password')
        }else{
            setTypePassword2('text')
        }
    }

    const genderClicked = () => {
        setMessage(messageList.gender)
        setIsOpen(true)
    }

    const genderSelected = (value:string) => {
        setGender(prevState => ({value: ValidateUtil.convertGenderName(value), errMsg: prevState.errMsg}))
        setIsOpen(false)
        inputRefs.current[Number(messageList.birthday.index)].focus()
    }

    useEffect(()=>{
        if(StringUtil.isNotEmpty(nickName.value) || StringUtil.isNotEmpty(nickName.errMsg)){
            if(StringUtil.isEmpty(nickName.value)){
                setNickName(prevState => ({value: prevState.value, errMsg: '닉네임을 입력해주세요'}))
            }else{
                setNickName(prevState => ({value: prevState.value, errMsg: prevState.errMsg}))
            }
        }
    }, [nickName.value, nickName.errMsg])

    const goNextInput = async (name:string) => {
        const index = messageList[name as keyof typeof messageList].index
        const nextIndex = index !== undefined? index+1 : 0
        if(name === 'birthday'){
            if(!disable){
                await clickSignUp()
            }
        } else if((name === 'pwd' || name === 'pwdCheck') && (_mnbrNtrySeCd === 'G' || _mnbrNtrySeCd === 'N' || _mnbrNtrySeCd === 'K' || _mnbrNtrySeCd === 'A')){
            inputRefs.current[3].focus()
        }else if (name === 'tall'){
            setIsOpen(true)
        } else {
            inputRefs.current[Number(nextIndex)].focus()
        }


    }


    return (
        <>
            <div id='Signup' className={'bg-cl-black'}>
                <DialogHeader onClose={handleOverlayClick} leftIcon={'prev-w'}/>
                <HeaderSpacer className='bg-cl-black'/>
                <div className={'main-content'}>
                    <div className={'h5_m title mt-39px'}>{message.title}</div>
                    <div className={'b2_r title mt-16px ft-cl-hint'}>{message.subTitle}</div>
                    <div className={'input-wrapper'}>
                        <div className={'b1_r input-item'}>
                    {
                        _mnbrNtrySeCd === 'G' || _mnbrNtrySeCd === 'N'||  _mnbrNtrySeCd === 'K'||  _mnbrNtrySeCd === 'A'?
                            <Input theme={defaultTheme}
                                   label={messageList.email.label}
                                   name={messageList.email.name}
                                   value={email.value}
                                   required={true}
                                   errorMsg={email.errMsg}
                                   readOnly={true}
                            >
                                {email.value}
                            </Input>
                        :
                            <Input theme={defaultTheme}
                                   label={messageList.email.label}
                                   name={messageList.email.name}
                                   value={email.value}
                                   rightContent={<div className={'click-area'} onClick={() => setEmail({value: '', errMsg: ''})}><Icon iconClass={'input-delete-d h-17px'}/></div>}
                                   onChange={(ev) => setEmail(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                                   required={true}
                                   onBlur={() => validateEmail(email.value)}
                                   onKeyUp={() => validateEmail(email.value)}
                                   onClick={() => setMessage(messageList.email)}
                                   errorMsg={email.errMsg}
                                   ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.email.index]= ref)}
                                   goNext = {(name:string) => goNextInput(name)}
                            >
                                {email.value}
                            </Input>
                    }
                        </div>
                        {
                            _mnbrNtrySeCd === 'G' || _mnbrNtrySeCd === 'N' || _mnbrNtrySeCd === 'K' || _mnbrNtrySeCd === 'A' ?
                                <></>
                            :
                                <div>
                                    <div className={'b1_r input-item'}>
                                        <Input theme={defaultTheme}
                                               label={messageList.pwd.label}
                                               name={messageList.pwd.name}
                                               value={pwd.value}
                                               rightContent={<div className={'click-area'} onClick={() => clickLook()}><Icon
                                                   iconClass={'ic-look h-17px'}/></div>}
                                               onChange={(ev) => setPwd(prevState => ({
                                                   value: ev.target.value,
                                                   errMsg: prevState.errMsg
                                               }))}
                                               required={true}
                                               onBlur={() => validatePwdOnBlur(pwd.value)}
                                               onKeyUp={() => validatePwd(pwd.value)}
                                               onClick={() => setMessage(messageList.pwd)}
                                               errorMsg={pwd.errMsg}
                                               type={typePassword}
                                               ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.pwd.index]= ref)}
                                               goNext = {(name:string) => goNextInput(name)}
                                        >
                                            {pwd.value}
                                        </Input>
                                    </div>
                                    <div className={'b1_r input-item'}>
                                        <Input theme={defaultTheme}
                                               label={messageList.pwdCheck.label}
                                               name={messageList.pwdCheck.name}
                                               value={pwdCheck.value}
                                               rightContent={<div className={'click-area'} onClick={() => clickLook2()}>
                                                   <Icon iconClass={'ic-look h-17px'}/></div>}
                                               onChange={(ev) => setPwdCheck(prevState => ({
                                                   value: ev.target.value,
                                                   errMsg: prevState.errMsg
                                               }))}
                                               required={true}
                                               onBlur={() => validatePwdCheckOnBlur(pwdCheck.value)}
                                               onKeyUp={() => validatePwdCheck(pwdCheck.value)}
                                               onClick={() => setMessage(messageList.pwdCheck)}
                                               errorMsg={pwdCheck.errMsg}
                                               type={typePassword2}
                                               ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.pwdCheck.index]= ref)}
                                               goNext = {(name:string) => goNextInput(name)}
                                        >
                                            {pwdCheck.value}
                                        </Input>
                                    </div>
                                </div>
                        }
                        <div className={'b1_r input-item'}>
                            <Input theme={defaultTheme}
                                   label={'닉네임'}
                                   name={'nickName'}
                                   value={nickName.value}
                                   rightContent={<div className={'click-area'} onClick={() => clearNickName()}><Icon iconClass={'input-delete-d h-17px'}/></div>}
                                   onChange={(ev) => setNickName(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                                   //onChange={(ev) => validateNickName(ev.target.value)}
                                   onKeyUp={() => validateNickName(nickName.value)}
                                   required={true}
                                   errorMsg={nickName.errMsg}
                                   onClick={() => setMessage(messageList.nickName)}
                                   ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.nickName.index]= ref)}
                                   goNext = {(name:string) => goNextInput(name)}
                            />
                        </div>
                        <div className={'b1_r input-item'}>
                            <Input theme={defaultTheme}
                                   label={messageList.tall.label}
                                   name={messageList.tall.name}
                                   value={tall.value}
                                   rightContent={<div className={'click-area'} onClick={() => setTall({value: '', errMsg: ''})}><Icon iconClass={'input-delete-d h-17px'}/></div>}
                                   required={true}
                                   onChange={(ev) => setTall(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                                   maxLength={5}
                                   errorMsg={tall.errMsg}
                                   onBlur={() => validateTall(tall.value)}
                                   onKeyUp={() => validateTall(tall.value)}
                                   onClick={() => setMessage(messageList.tall)}
                                   ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.tall.index]= ref)}
                                   goNext = {(name:string) => goNextInput(name)}
                            >
                                {tall.value}
                            </Input>
                        </div>
                        <div className={'b1_r input-item'}>
                            <Input theme={defaultTheme}
                                   label={messageList.gender.label}
                                   name={messageList.gender.name}
                                   value={gender.value}
                                   rightContent={<div onClick={()=> genderClicked()}><Icon iconClass={'expend-off'}/></div>}
                                   required={true}
                                   readOnly={true}
                                   onClick={() =>genderClicked()}
                                   onBlur={() =>setGender(prevState => ({value:prevState.value, errMsg:prevState.errMsg}))}
                                   errorMsg={gender.errMsg}
                                   ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.gender.index]= ref)}
                                   goNext = {(name:string) => goNextInput(name)}
                            />
                        </div>
                        <div className={'b1_r input-item'}>
                            <Input theme={defaultTheme}
                                   label={messageList.birthday.label}
                                   name={messageList.birthday.name}
                                   value={birthday.value}
                                   rightContent={<div className={'click-area'} onClick={() => clearBirthday()}><Icon iconClass={'input-delete-d h-17px'}/></div>}
                                   required={true}
                                   onChange={(ev) => setBirthday(prevState => ({value: ev.target.value, errMsg: prevState.errMsg}))}
                                   onBlur={() => validateBirthday(birthday.value)}
                                   onKeyUp={() => validateBirthday(birthday.value)}
                                   onClick={() => setMessage(messageList.birthday)}
                                   errorMsg={birthday.errMsg}
                                   maxLength={8}
                                   ref = {(ref:HTMLInputElement) => (inputRefs.current[messageList.birthday.index]= ref)}
                                   goNext = {(name:string) => goNextInput(name)}

                            />
                        </div>
                        <div className={'h-48px'}></div>
                    </div>
                </div>

                {

                    !disable ?
                        <BottomButton title={'완료'} disabled={false} onClick={() => clickSignUp()} />
                        :
                        <BottomButton title={'완료'} disabled={true}  />
                }

            </div>
            <Popup className='bg-cl-gray-2 ft-cl-white' title='성별 선택' iconClass={'close-w'} content={<GenderSelect onClick={genderSelected} className={defaultTheme} selected={gender.value}/>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
        </>
    )
}

export default Signup
