import React from 'react'
import {withStyles} from "@mui/styles";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Icon from "@/view/common/component/Icon";

export interface AccordionParam{
    title:string,
    contents:string,
    index:number
}

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        borderBottom: '1px solid #E8E8E8',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        padding: '0 24px',
        marginBottom: -1,
        marginRight: 7,
        minHeight: 66,
        '&$expanded': {
            minHeight: 66,
        },
    },
    content: {
        marginRight: 34,
        '&$expanded': {
            margin: '12px 34px 12px 0px',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        background: '#F4F4F4',
        padding: 24
    },
})(MuiAccordionDetails);

const CustomizedAccordion = (props:AccordionParam) => {
    return (
        <>
            <Accordion square key={props.index}>
                <AccordionSummary aria-controls={`'panel${props.index+1}d-content'`}
                                  id={`'panel${props.index+1}d-header'`}
                                  expandIcon={<Icon iconClass='expend-off w-16px h-16px'/>}>
                    <Typography>{props.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={'faq-list-content'}
                                dangerouslySetInnerHTML={{__html: props.contents}} />
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default CustomizedAccordion;