import React from 'react'

export interface NoticeDetailParam{
    title:string,
    contents?:string,
    subTitle?:string,
}

const NoticeDetail = (props: NoticeDetailParam) => {
    return <>
        <div className='board-item'>
            <span className='s_title_1_m'>{props.title}</span>
            <p className='c1_r cl-gray04 border-bottom-gray'>{props.subTitle}</p>
            <div className='board-detail'>
                <span dangerouslySetInnerHTML={{__html: props.contents? props.contents:''}}/>
            </div>
        </div>
    </>
}

export default NoticeDetail