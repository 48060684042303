import {FirebaseApp, initializeApp} from 'firebase/app';
import {Database, DatabaseReference, getDatabase, onValue, ref, get} from 'firebase/database'
import {FIREBASE, OS_TYPE} from "@/common/Constant";

const firebaseConfig = {
    apiKey: process.env.FIRE_API_KEY,
    authDomain: process.env.FIRE_AUTH_DOMAIN,
    databaseURL: process.env.FIRE_DATABASE_URL,
    projectId: process.env.FIRE_PROJECT_ID,
    storageBucket: process.env.FIRE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIRE_MESSAGING_SENDER_ID,
    appId: process.env.FIRE_APP_ID,
    measurementId:process.env.FIRE_MEASUREMENT_ID
}

const firebasePath = {
    rootPath : process.env.FIRE_ROOT,
    putMeasureStep : String().concat(FIREBASE.ROOT_PATH,FIREBASE.PUT_MEASURE,FIREBASE.STEP,FIREBASE.USER_ID),
    putMeasureHistoryStep : String().concat(FIREBASE.ROOT_PATH,FIREBASE.PUT_MEASURE_HISTORY,FIREBASE.STEP,FIREBASE.USER_ID),
    putMeasureHistorySleep : String().concat(FIREBASE.ROOT_PATH,FIREBASE.PUT_MEASURE_HISTORY,FIREBASE.SLEEP,FIREBASE.USER_ID),
    putMeasureWeight : String().concat(FIREBASE.ROOT_PATH,FIREBASE.PUT_MEASURE,FIREBASE.WEIGHT,FIREBASE.USER_ID),
    iosVersionCheckPath : process.env.FIRE_LATEST_IOS_VERSION,
    androidVersionCheckPath : process.env.FIRE_LATEST_ANDROID_VERSION,

}

export default class RothyFireBase {
    private static instance:RothyFireBase
    private myApp? : FirebaseApp
    private db? : Database
    private myRef? : DatabaseReference
    private myData? : object


    public static getInstance(){
        if(!RothyFireBase.instance){
            RothyFireBase.instance = new RothyFireBase()

        }

        return RothyFireBase.instance;
    }


    private init () {
        this.myApp = initializeApp(firebaseConfig)
        this.db = getDatabase(this.myApp)
        this.myRef = ref(this.db, firebasePath.rootPath)
        this.myData = undefined
        this.getRoot()
    }

    constructor() {
        this.init()
    }

    public getRoot() :object | undefined {

        if(this.myRef != undefined)
            onValue(this.myRef, (snapshot) => {
                if(snapshot.exists()) {
                    this.myData = snapshot.exportVal() as object

                }else
                    console.log("data 가져오기 실패");
            })
        return this.myData

    }

    // public checkPutStep(userId:number) :boolean {
    //     let isMyData = false
    //     if (this.db instanceof Database) {
    //         const stepRef: DatabaseReference = ref(this.db, firebasePath.putMeasureStep.concat(userId.toString()))
    //
    //         onValue(stepRef, (stepSnapshot) => {
    //             if(stepSnapshot.exists()) {
    //                 isMyData = true
    //             }else
    //                 console.log("non exist " + firebasePath.putMeasureStep.concat(userId.toString()));
    //         })
    //     }
    //     return isMyData
    // }
    //
    // public checkPutStepHistory(userId:number) :boolean {
    //     let isMyData = false
    //     if (this.db instanceof Database) {
    //         const stepRef = ref(this.db, firebasePath.putMeasureHistoryStep.concat(userId.toString()))
    //
    //         onValue(stepRef, (stepSnapshot) => {
    //             if(stepSnapshot.exists()) {
    //                 isMyData = true
    //             }else
    //                 console.log("non exist " + firebasePath.putMeasureHistoryStep.concat(userId.toString()));
    //         })
    //     }
    //     return isMyData
    // }
    //
    // public checkPutSleepHistory(userId:number) :boolean {
    //     let isMyData = false
    //
    //     if (this.db instanceof Database) {
    //         const sleepRef = ref(this.db, firebasePath.putMeasureHistorySleep.concat(userId.toString()))
    //
    //         onValue(sleepRef, (stepSnapshot) => {
    //             if(stepSnapshot.exists()) {
    //                 isMyData = true
    //             } else {
    //                 console.log("non exist " + firebasePath.putMeasureHistorySleep.concat(userId.toString()));
    //             }
    //         })
    //     }
    //
    //     return isMyData
    // }

    public async isCheckMissionStep(userId:number): Promise<boolean> {
        let isMyData = false

        try {
            if (this.db instanceof Database) {
                const stepRef: DatabaseReference = ref(this.db, firebasePath.putMeasureStep.concat(userId.toString()))
                const snapshot = await get(stepRef)
                if (snapshot.exists()) {
                    console.log("■ \tcheck mission is already in progress " + firebasePath.putMeasureStep.concat(userId.toString()));

                    isMyData = true
                }
            }
        } catch (e) {
            console.log(e)
        }

        return isMyData
    }

    public async isCheckMissionStepHistory(userId:number): Promise<boolean> {
        let isMyData = false
        if (this.db instanceof Database) {
            const stepRef = ref(this.db, firebasePath.putMeasureHistoryStep.concat(userId.toString()))
            const snapshot = await get(stepRef)
            if (snapshot.exists()) {
                console.log("■ check \tmission is already in progress " + firebasePath.putMeasureHistoryStep.concat(userId.toString()));

                isMyData = true
            }
        }

        return isMyData
    }

    public async isCheckMissionSleep(userId:number): Promise<boolean> {
        let isMyData = false

        if (this.db instanceof Database) {
            const sleepRef = ref(this.db, firebasePath.putMeasureHistorySleep.concat(userId.toString()))
            const snapshot = await get(sleepRef)
            if (snapshot.exists()) {
                console.log("■ \tcheck mission is already in progress " + firebasePath.putMeasureHistorySleep.concat(userId.toString()));

                isMyData = true
            }
        }

        return isMyData
    }

    public async isCheckMissionWeight(userId:number): Promise<boolean> {
        let isMyData = false

        try {
            if (this.db instanceof Database) {
                const stepRef: DatabaseReference = ref(this.db, firebasePath.putMeasureWeight.concat(userId.toString()))
                const snapshot = await get(stepRef)
                if (snapshot.exists()) {
                    console.log("■ \tcheck mission is already in progress " + firebasePath.putMeasureWeight.concat(userId.toString()));

                    isMyData = true
                }
            }
        } catch (e) {
            console.log(e)
        }

        return isMyData
    }

    public async getRequireVersion(osType:string) : Promise<string> {
        let returnVal  = ""
        const pathVal = osType.indexOf(OS_TYPE.IOS) > -1 ? firebasePath.iosVersionCheckPath : firebasePath.androidVersionCheckPath
        try {
            if (this.db instanceof Database) {
                const stepRef: DatabaseReference = ref(this.db, pathVal)
                const snapshot = await get(stepRef)
                if (snapshot.exists()) {
                    returnVal = snapshot.exportVal().toString()
                    console.log("■ \trequire version ".concat(returnVal));
                }
            }
        } catch (e) {
            console.log(e)
        }

        return returnVal
    }

}



