import React, {useEffect, useState} from 'react'
import {Dialog, Slide, SlideProps} from "@mui/material";
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import Icon from "@/view/common/component/Icon";
import HealthCertification from "@/view/health/component/HealthCertification";
import HealthService, {
    HealthReportData,
    HealthReportPossibleRes,
    TilkoHealthReportRes,
    ValueData
} from "@/service/HealthService";
import Popup from "@/view/common/popup/Popup";
import HealthRecordInfo from "@/view/health/component/HealthRecordInfo";
import HealthReportView from "@/view/health/HealthReportView";
import StringUtil from "@/common/StringUtil";


const leftTransition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
})

const upTransition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
})

export interface HealthRecordParam{
    onToast: (message:string) => void
}

const HealthRecord = (props: HealthRecordParam) => {
    const [healthData, setHealthData] = useState<HealthReportData | undefined>()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [reportListOpen, setReportListOpen] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    const [healthReportPossible, setHealthReportPossible] = useState({isPossible: false, message: ""})
    const [dangerItem, setDangerItem] = useState('')
    const [warningItem, setWarningItem] = useState('')
    const [goodItem, setGoodItem] = useState('')

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleReturn = () => {
        setDialogOpen(false)
        getHealthReportPossible().then(r=>r).catch(reason => console.log(reason))
        getHealthReportResult().then(r=>{
            if(r.data && r.data.data.length > 0){
                props.onToast("검진 기록을 불러왔어요\n지금바로 확인해 보세요")
            }else{
                props.onToast("건강보험공단에 검진 기록이 없네요\nROTHY는 공단에 등록된 기록만 볼 수 있어요")
            }
        }).catch(reason => console.log(reason))
    }

    const getHealthRecord = () => {
        if(healthReportPossible.isPossible){
            setDialogOpen(true)
        }else{
            if(StringUtil.isNotEmpty(healthReportPossible.message)){
                props.onToast(healthReportPossible.message)
            }
        }
    }
    const {refetch:getHealthReportResult} = useQuery<TilkoHealthReportRes, AxiosError>(
        ['getHealthReportResult'],
        ()=> HealthService.getHealthReportResult(),
        {
            onSuccess: (v: TilkoHealthReportRes) => setHealthDataList(v),
        }
    )

    const {refetch:getHealthReportPossible} = useQuery<HealthReportPossibleRes, AxiosError>(
        ['getHealthReportPossible'],
        () => HealthService.getHealthReportPossible(),
        {
            onSuccess: (v: HealthReportPossibleRes) => setHealthReportPossible({isPossible: v.data.isPossible, message: v.data.message})
        }
    )

    const setHealthDataList = (data: TilkoHealthReportRes) =>{
        if(data.data.length > 0){
            setHealthData(data.data[0])
        }
    }

    useEffect(()=>{
        if(healthData) {
            const warningArray:ValueData[] = []
            const dangerArray:ValueData[]= []
            const goodArray:ValueData[] = []
            healthData.resultDetailList.map((data)=>{
            data.valueList.forEach((data)=>{
                if(data.label === 'warning'){
                    warningArray.push(data)
                }
                if(data.label === 'danger'){
                    dangerArray.push(data)
                }
                if(data.label === 'good'){
                    goodArray.push(data)
                }
                })
            })

            if(goodArray.length ===0 ){
                setGoodItem('정상 항목이 없어요')
            }else if(goodArray.length >3) {
                setGoodItem(goodArray[0].itemName + ' 포함 ' + goodArray.length.toString() + '개 항목')
            }else{
                const goodItem = ''
                goodArray.forEach((data, index)=>{
                    goodItem.concat(data.itemName)
                    if(index !== goodArray.length -1){
                        goodItem.concat(',')
                    }
                })
                setGoodItem(goodItem)
            }

            if(warningArray.length ===0 ){
                setWarningItem('주의 항목이 없어요')
            }else if(warningArray.length >3) {
                setWarningItem(warningArray[0].itemName + ' 포함 ' + warningArray.length.toString() + '개 항목')
            }else{
                let warningItem = ''
                warningArray.forEach((data, index)=>{
                    warningItem = warningItem.concat(data.itemName)
                    if(index !== warningArray.length -1){
                        warningItem = warningItem.concat(', ')
                    }
                })
                setWarningItem(warningItem)
            }

            if(dangerArray.length ===0 ){
                setDangerItem('위험 항목이 없어요')
            }else if(dangerArray.length >3) {
                setDangerItem(dangerArray[0].itemName + ' 포함 ' + dangerArray.length.toString() + '개 항목')
            }else{
                let dangerItem = ''
                dangerArray.forEach((data, index)=>{
                    dangerItem = dangerItem.concat(data.itemName)
                    if(index !== dangerArray.length -1){
                        dangerItem = dangerItem.concat(', ')
                    }
                })
                setDangerItem(dangerItem)
            }
        }
    }, [healthData])

    useEffect(()=>{
        initData()
    }, [])

    const initData = () => {
        getHealthReportPossible().then(r=>r).catch(reason => console.log(reason))
        getHealthReportResult().then(r=>r).catch(reason => console.log(reason))
    }

    return(
        <>
            {healthData
                ?   <div className={'card-content mb-12px'}>
                        <div className={'mrml-24px'}>
                            <div className={'mt-24px'}>
                                <div className={'flex align-center'}>
                                    <p className={'b2_m mt-2px'}>건강검진 기록</p>
                                    <div className={'ml-4px'} onClick={() => setPopupOpen(true)}>
                                        <Icon iconClass={'info-bold w-16px h-16px'}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'mt-16px info-box'}>
                                <p className={'c2_r date'}>{healthData.checkupDate}</p>
                                <p className={'c1_m description'}>{healthData.codeInfo}</p>
                            </div>
                            <div className={'mt-25px flex-column flex-start'}>
                                <div className={'flex'}>
                                    <Icon iconClass={'item-good w-30px h-16px'}/>
                                    <p className={`c1_r ml-7px ${goodItem.includes('없어요')? 'cl-gray05': 'cl-gray02'}`}>{goodItem}</p>
                                </div>
                                <div className={'flex mt-14px'}>
                                    <Icon iconClass={'item-warning w-30px h-16px'}/>
                                    <p className={`c1_r ml-7px ${warningItem.includes('없어요')? 'cl-gray05': 'cl-gray02'}`}>{warningItem}</p>
                                </div>
                                <div className={'flex mt-14px'}>
                                    <Icon iconClass={'item-danger w-30px h-16px'}/>
                                    <p className={`c1_r ml-7px ${dangerItem.includes('없어요')? 'cl-gray05': 'cl-gray02'}`}>{dangerItem}</p>
                                </div>
                            </div>
                            <div className={'mt-24px mb-24px'}>
                                <div className={'health-record-btn'} onClick={() => setReportListOpen(true)}>
                                    <p className={'c1_r'}>전체 기록 확인하기</p>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className={'card-content mb-12px'}>
                        <div className={'mrml-24px'}>
                            <div className={'mt-24px'}>
                                <div className={'flex align-center'}>
                                    <p className={'b2_m  mt-2px'}>건강검진 기록</p>
                                    <div className={'ml-4px'} onClick={() => setPopupOpen(true)}>
                                        <Icon iconClass={'info-bold w-16px h-16px'}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'mt-18px text-center'}>
                                <p className={'c1_r cl-gray03'}>검진 기록을 불러오시면<br/>ROTHY만의 노하우로 관리해드릴게요</p>
                            </div>
                            <div className={'mt-24px mb-24px'}>
                                <div className={'health-record-btn'} onClick={getHealthRecord}>
                                    <p className={'c1_r'}>검진 기록 불러오기</p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <Dialog fullScreen open={dialogOpen} onClose={handleClose} TransitionComponent={upTransition}>
                <HealthCertification onClose={handleClose} onReturn={handleReturn}/>
            </Dialog>
            <Dialog fullScreen open={reportListOpen} onClose={() => setReportListOpen(false)} TransitionComponent={leftTransition}>
                <HealthReportView onClose={()=> setReportListOpen(false)}/>
            </Dialog>
            <Popup className='bg-cl-white' content={<HealthRecordInfo/>} isOpen={popupOpen} onClose={() => setPopupOpen(!popupOpen)}/>
        </>
    )
}

export default HealthRecord