import React from 'react'
import NoContent from "@/view/common/component/NoContent";
import BoardListItem from "@/view/common/component/BoardListItem";
import AnswerStatus from "@/view/question/component/AnswerStatus";
import {QuestionData} from "@/service/BoardService";

export interface QuestionParam{
    questionList: QuestionData[]
}

const Question = (props: QuestionParam) => {
    if(props.questionList.length === 0){
        return (
            <>
                <NoContent className='no-board-list mb-18px' notification='문의하신 내역이 없습니다' />
            </>
        )
    }else{
        return (
            <>
                <div className={'question-list'}>
                {props.questionList.map((data,index) =>{
                    return <div key={data.questionNo}>
                                <BoardListItem index={index}
                                               type={'questionDetail'}
                                               title={data.questionTitle}
                                               contents={data.questionCn}
                                               detailProp={data}
                                               subTitle={data.questionDate}
                                               heightClass={'h-88px'}
                                               rightContent={<AnswerStatus answerStatus={data.answerStatus} answerStatusNm={data.answerStatusNm}/>} />
                            </div>
                })}
                </div>
            </>
        )
    }
}

export default Question;