import React, {useCallback, useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import moment from "moment-timezone";
import HeaderSpacer from '@/view/layout/HeaderSpacer'

import MenuItem from '@/view/menu/component/MenuItem'
import AppVersion from '@/view/menu/component/AppVersion'
import Logout from '@/view/menu/component/Logout'
import DeleteUserView from '@/view/user/DeleteUserView'
import StringUtil from "@/common/StringUtil"
import StorageUtil from "@/common/StorageUtil"
import {APP_VERSION_NO, DATE_FORMAT, EVENT_TYPE, USER_INFO} from "@/common/Constant"
import DialogHeader from "@/view/layout/DialogHeader";
import BoardService, {SingleDataResponse} from "@/service/BoardService";
import Toast from "@/view/common/popup/Toast";
import RothyNativeService, {RNIActions, RNIEvents, RNIMessageConfig} from "@/service/rothynative/RothyNativeService";
import HealthConnect from "@/view/menu/component/HealthConnect";


interface MenuProps {
    onClose: () => void
}

export interface UserInfo {
    nickNm: string,
    signupDt: string
}

const MenuView: React.FC<MenuProps> = (props:MenuProps) => {
    const navigate = useNavigate()
    const [nickNm, setNickNm] = useState('')
    const [signupDt, setSignupDt] = useState('')
    const [appVersion, setAppVersion] = useState(StorageUtil.getLocalStorage(APP_VERSION_NO)as unknown as "")
    const [noticeIcon, setNoticeIcon] = useState('notice-off')
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [isOpen, setIsOpen] = useState(false)
    const [_content, setContent] = useState('')

    useQuery<SingleDataResponse<number>, AxiosError>(
        ['getNotificationUnreadCount'],
        ()=> BoardService.getNotificationUnreadCount(),
        {
            enabled: true,
            onSuccess: (v: SingleDataResponse<number>) => v.data === 0 ? setNoticeIcon('notice-off') : setNoticeIcon('notice-on'),
        }
    )

    useEffect(() => {
        if(StringUtil.isEmpty(StorageUtil.getLocalStorage(USER_INFO))){
            navigate('/login')
        }else{
            const userInfo: UserInfo  = StringUtil.jsonString2ObjectWithMap<UserInfo>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
            const userNickNm = userInfo?.nickNm
            setNickNm(userNickNm)

            const userSignupDt = userInfo?.signupDt
            setSignupDt(moment(userSignupDt).format(DATE_FORMAT.DAY_DOT))
        }

    }, [nickNm])

    useEffect(()=> {
        const userInfo: UserInfo  = StringUtil.jsonString2ObjectWithMap<UserInfo>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
        const userNickNm = userInfo?.nickNm
        setNickNm(userNickNm)
    }, [isOpen])

    useEffect(() => {
        console.debug("!!!!!!!!!!!!!!!!!!!!!!!!!!!! 리스너 해제1")
        RothyNativeService.getInstance().removeEventListener(RNIEvents.HARDWARE_BACK_PRESS)
        RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_IC_RECEIVE_WEIGHT_DATA)
        console.debug("!!!!!!!!!!!!!!!!!!!!!!!!!!!! 리스너 해제2")
        return () => {
            console.debug("!!!!!!!!!!!!!!!!!!!!!!!!!!!! 리스너 등록1")
            const setWalkEvent = new Event(EVENT_TYPE.WALK_SET_LISTENER)
            window.dispatchEvent(setWalkEvent)
            console.debug("!!!!!!!!!!!!!!!!!!!!!!!!!!!! 리스너 등록2")


            RothyNativeService.getInstance().addEventListener(RNIEvents.HARDWARE_BACK_PRESS, () => {
                onClickAppExit()
            })
        }

    },[])

    const onClickAppExit = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.APP_EXIT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("app_exit")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className='menu b2_r'>
            <DialogHeader title='메뉴' onClose={handleOverlayClick} rightIcon = {`${noticeIcon.toString()} w-26px h-26px`} rightLink='notificationList'/>
            <HeaderSpacer/>

            <MenuItem title={nickNm} link='profile' className='pb-13px border-bottom-gray cl-primary-cyan01 s_title_1_m' iconClass='next w-6px h-12px' onReturn={() => {setIsOpen(true), setContent('기본 정보가 변경되었습니다')}}/>
            <HealthConnect signupDt={signupDt} onReturn={() => {setIsOpen(true), setContent('ROTHY를 사용하려면 삼성헬스 동기화가 꼭 필요해요.\n' +'동기화 화면에서 모든 권한을 선택해주세요.')}}/>
            <MenuItem title='건강검진 기록' link='healthReport' className='border-top-gray' iconClass='next w-6px h-12px'/>
            <MenuItem title='내 기기 관리' link='userDvic' className='border-top-gray' iconClass='next w-6px h-12px'/>
            <MenuItem title='공지사항' link='notice' className='border-top-gray' iconClass='next w-6px h-12px'/>
            <MenuItem title='알림 설정' link='notificationSetting' iconClass='next w-6px h-12px'/>

            <MenuItem title='FAQ' link='faq' className='border-top-gray' iconClass='next w-6px h-12px'/>
            <MenuItem title='문의하기' link='question' iconClass='next w-6px h-12px'/>

            <MenuItem title='약관 및 개인정보 처리동의' link='terms' className='border-top-gray' iconClass='next w-6px h-12px'/>
            <AppVersion title='앱정보' version={appVersion}/>

            <Logout/>
            <DeleteUserView/>

            <Toast content={<div>{_content}</div>} isOpen={isOpen} onClose={ () => setIsOpen(false) }></Toast>
        </div>
    );
}

export default MenuView;
