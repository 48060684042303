import React, {useCallback} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

type popupParam = {
    isOpen: boolean,
    content: JSX.Element,
    title?: JSX.Element | undefined,
    leftBtnContent: string,
    rightBtnContent: string,
    onClick: () => void,
    onClose: () => void,
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 270,
};

const Confirm = (props:popupParam) => {
    const handleClick = useCallback(() => {props.onClick()}, [props.onClick])
    const handleClose = useCallback(() => {props.onClose()}, [props.onClose])

    return(
        <>
            <div className={'modal-dialog'}>
                <Modal
                    open={props.isOpen}
                    onClose={handleClose}
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div id="modal-modal-description" className={'modal-body'}>
                            { props.title !== undefined ?
                                <>{props.title}</>
                                :
                                <></>
                            }
                            {props.content}
                        </div>
                        <div className="modal-footer" >
                            <div className={'modal-btn confirm-btn confirm-lbtn'} onClick={handleClick}>{props.leftBtnContent}</div>
                            <div className={'modal-btn confirm-btn'} onClick={handleClose}>{props.rightBtnContent}</div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default Confirm;