import React, {useCallback, useEffect, useState} from 'react'
import {Dialog, Slide, SlideProps} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import moment from "moment";
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import Question from "@/view/question/component/Question";
import BottomButton from "@/view/common/component/button/BottomButton";
import QuestionAddView from "@/view/question/QuestionAddView";
import BoardService, {QuestionData, QuestionList} from "@/service/BoardService";

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />
})

export interface QuestionViewParam{
    onClose : () => void
}

const QuestionView = (props:QuestionViewParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const handleReturnClick = useCallback(() => {initData()}, [props.onClose])

    const [open, setOpen] = React.useState(false)

    const [questionList, setQuestionList] = useState<QuestionData[]>([])

    const {refetch:selectQuestion} = useQuery<QuestionList, AxiosError>(
        ['questionlist'],
        ()=> BoardService.selectQuestion(),
        {
            onSuccess: (v: QuestionList) => updateQuestionList(v.data.objectList),
        }
    )

    const updateQuestionList = (data:QuestionData[]) => {
        const dataList:QuestionData[] = []
        data.map(d => {
            const question = {
                questionNo: d.questionNo,
                questionTitle: d.questionTitle,
                questionCn: d.questionCn,
                answerStatus: d.answerStatus,
                answerStatusNm: d.answerStatus !== 'END'? '답변 준비중': '답변 완료',
                questionType: d.questionType,
                questionTypeNm: d.questionTypeNm,
                questionDate: moment(d.questionDate).format('YYYY.MM.DD')
            }
            dataList.push(question)
        })
        setQuestionList(dataList)
    }

    const initData = () => {
        selectQuestion()
            .then(r => {return Promise<void>})
            .catch(reason => {console.log(reason)})
    }

    useEffect(  () => {
        initData()
    },[])


    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <DialogHeader title='문의하기' onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <Question questionList={questionList}/>
            </div>
            <BottomButton title='문의 등록하기' disabled={false} onClick={handleClickOpen}/>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                {<QuestionAddView onClose={handleClose} onReturn={handleReturnClick}/>}
            </Dialog>
        </>
    )
}

export default QuestionView;