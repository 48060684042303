import React from 'react'
import {ValueData} from "@/service/HealthService";


type arrayData = {
    illness:string,
    value:ValueData[]
}

export interface HealthItemSummaryParam{
    list: arrayData[]
}

const HealthItemSummary = (props: HealthItemSummaryParam) => {
    return (
        <>
            {props.list.map((data, index) =>{
                    return(
                        <div key={index} className={'mt-9px w-100p ml-2px'}>
                            <p className={'b2_m'}>{data.illness}</p>
                            {data.value.map((data, index) => {
                                    return (
                                        <div key={index}
                                             className={'flex w-100p align-center justify-between'}>
                                            <div className={'flex align-center'}>
                                                <p className={'c1_r'}>·</p>
                                                <p className={'c1_r ml-8px'}>{data.itemName} </p>
                                                <p className={'c1_r ml-4px'}>|</p>
                                                <p className={'c1_r ml-4px'}>{data.lawValue}</p>
                                            </div>
                                            <p className={'c2_r cl-gray04 mr-8px'}>{data.normalValue}</p>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    )
                })}
        </>
    )
}

export default HealthItemSummary