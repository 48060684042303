import React, {useCallback} from 'react'
import Icon from "@/view/common/component/Icon";
import BottomButton from "@/view/common/component/button/BottomButton";
import HeaderSpacer from "@/view/layout/HeaderSpacer";


export interface QuestionCompleteParam{
    onClose: () => void
}

const QuestionComplete = (props: QuestionCompleteParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    return <>
            <div className={'no-content-wrapper'}>
                <HeaderSpacer/>
                <Icon iconClass={'question-complete'}/>
                <p className={'text-center h5_l mt-35px'}>문의가 정상적으로<br/>전달되었어요</p>
                <p className={'text-center b2_l mt-15px'}>내용 확인 후 최대한 빠르게 답변드릴게요<br/>궁금한 점이 생기면 언제든 말씀해 주세요</p>
            </div>
            <BottomButton title={'문의내역으로 돌아가기'}
                          disabled={false}
                          notification={<p className={'c1_r cl-gray05'}>문의 답변 시간은 공휴일 제외 평일 10:00 ~ 17:00 입니다.</p>}
                          onClick={handleOverlayClick}/>
        </>
}

export default QuestionComplete