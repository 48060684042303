import React, {useEffect, useState} from 'react'
import moment from "moment"
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import {WeightCountData} from '@/service/WeightService'

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
)

type countChartDataSet = {
    data: {x: string; y: string | undefined}[],
    backgroundColor: string,
    borderColor: string,
}

const WeightCountChart = (props:WeightCountData[]) => {
    const [data,setData] = useState<{ labels:string[], datasets: countChartDataSet[]}>({labels:[''],datasets:[]})
    const [options, setOptions] = useState({})
    const countList: string[] = []
    const maxValue = moment(moment().endOf('day')).diff(moment(moment().startOf('day')),'seconds') + 1000
    const monthYn = Object.keys(props).length > 7 ? 'Y' : 'N'

    useEffect(() => {
        initChart()
        updateOptions()
    },[props])

    const updateOptions = () => {
        setOptions({
            responsive: true,
            animation: false,
            plugins: {
                datalabels: false,
                legend: false,
                title: false,
                tooltip: false
            },
            scales: {
                x: {
                    position: 'top',
                    grid: {
                        drawBorder: false,
                    },
                    ticks: {
                        autoSkip: false,
                        stepSize: 1,
                        maxRotation: 0,
                        font: {
                            size: 12
                        },
                        callback: function (value: number) {
                            let result
                            if(monthYn === 'N'){
                                if(value === 1) {
                                    result = ['월',countList[value]]
                                } else if (value === 2) {
                                    result = ['화',countList[value]]
                                } else if (value === 3) {
                                    result = ['수',countList[value]]
                                } else if (value === 4) {
                                    result = ['목',countList[value]]
                                } else if (value === 5) {
                                    result = ['금',countList[value]]
                                } else if (value === 6) {
                                    result = ['토',countList[value]]
                                } else {
                                    result = ['일',countList[value]]
                                }
                            } else {
                                if(['6','13','20','27'].includes(value.toString())){
                                    result =  (value+1).toString() +'일'
                                } else {
                                    result = ''
                                }
                            }
                            return result
                        }
                    },
                },
                y: {
                    min: -1000,
                    max: maxValue,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false
                    },
                }
            },
        })
    }

    const initChart = () => {
        const dataArray: { x: string; y: string | undefined | any}[] = []
        const labelsArray = []

        for(const [key,value] of Object.entries(props)){
            if(value.msreDtmList){
                dataArray.push({x:key, y: undefined})
                labelsArray.push(key)
                value.msreDtmList.forEach( d => {
                    dataArray.push({x:key, y: moment(moment(d).endOf('day')).diff(moment(d),'seconds')})
                })
                if(monthYn === 'N') countList.push(value.count === 0? '-' : value.count.toString())
            }
        }

        setData({
            labels: labelsArray,
            datasets: [{ data: dataArray, backgroundColor: '#24CBAA' , borderColor: '#24CBAA'}],
        })
    }

    const setSemiCircle = () => {
        const result = []
        result.push(<div key={'semi_border'} style={{position:'absolute', width: '89%', borderBottom: '1px solid #0000001a'}}></div>)
        if(props !== undefined){
            for(const [key,value] of Object.entries(props)){
                const classResult = getCountClass(key,value.count)
                result.push(<div key={'semi_'+key} className={'semicircle '+classResult[0]} style={{left:classResult[1]}}></div>)
            }
        }

        return result
    }

    const getCountClass = (index: string, count: number) =>{
        let leftPercent = ''
        if(index === '0'){
            leftPercent = '14%'
        } else if (index === '1') {
            leftPercent = '26.5%'
        } else if (index === '2') {
            leftPercent = '39%'
        } else if (index === '3') {
            leftPercent = '51.5%'
        } else if (index === '4') {
            leftPercent = '64%'
        } else if (index === '5') {
            leftPercent = '76.5%'
        } else {
            leftPercent = '89%'
        }

        if(count === 0){
            return ['zero','calc('+leftPercent+' - 14px)']
        } else if (count === 1) {
            return ['one','calc('+leftPercent+' - 24px)']
        } else if (count === 2) {
            return ['two','calc('+leftPercent+' - 34px)']
        } else {
            return ['three','calc('+leftPercent+' - 42px)']
        }
    }

    return <>
        {monthYn === 'Y' ?
                <div className={'position-relative w-90p'}>
                    <div className={'count-month'}>
                        <div className={'y-ticks c2_r cl-gray04'}>
                            <p>12AM</p><p>3AM</p><p>6AM</p><p>9AM</p>
                            <p>12PM</p><p>3PM</p><p>6PM</p><p>9PM</p><p>12AM</p>
                        </div>
                        <div className={'line'}></div>
                    </div>
                    <Scatter className={'position-absolute'} style={{left: '23px'}} height={'326px'} options={options} data={data}/>
                </div>
            :
            <>
            <div className={'h-42px flex flex-end'}>
                {setSemiCircle()}
            </div>
            <div className={'position-relative w-90p mt-7px'}>
                <div className={'count-week'}>
                    <div className={'y-ticks c2_r cl-gray04'}>
                        <p>AM</p><p>PM</p></div>
                    <div className={'line'}></div>
                </div>
                <Scatter className={'position-absolute'} style={{left:'23px'}} height={'326px'} options={options} data={data}/>
            </div>
            </>
        }
    </>
}

export default WeightCountChart
