import React, { useState } from 'react';

import RothyNativeService, {
    RNIActions, RNIError, RNIMessageConfig,
} from "@/service/rothynative/RothyNativeService";
import * as RNINaverSignIn from "@/service/rothynative/RNINaverSignIn";

const NaverSignInSample: React.FC = (): JSX.Element => {
    const [accessToken, setAccessToken] = useState<string | undefined>()
    const [profile, setProfile] = useState<RNINaverSignIn.RNNaverProfile | undefined>(undefined)

    const onClickNaverSignIn = () => {
        const config = new RNIMessageConfig<void, RNINaverSignIn.RNNaverLoginResponse>()
        config.action = RNIActions.NAVER_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNINaverSignIn.RNNaverLoginResponse) => {
                if (response?.successResponse?.accessToken) {
                    setAccessToken(response?.successResponse?.accessToken)
                } else {
                    setAccessToken(undefined)
                }
                
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickNaverSignOut = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.NAVER_SIGN_OUT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setAccessToken(undefined)
                setProfile(undefined)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickNaverProfile = () => {
        if (!accessToken) {
            alert("네이버 로그인이 필요합니다.")
            return
        }

        const config = new RNIMessageConfig<string, RNINaverSignIn.RNNaverProfileResponse>()
        config.action = RNIActions.NAVER_PROFILE
        config.data = accessToken

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNINaverSignIn.RNNaverProfileResponse) => {
                setProfile(response?.response)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    return <>
        <button onClick={onClickNaverSignIn}>네이버 로그인</button>
        <br />
        <button onClick={onClickNaverSignOut}>네이버 로그아웃</button>
        <br />
        <button onClick={onClickNaverProfile}>네이버 계정정보 조회</button>
        <ul>
            <li><b>Access Token</b></li>
            <li>{accessToken ? accessToken : ''}</li>
            <li><b>계정 정보</b></li>
            {profile && (
                <>
                    <li>프로필 이미지: {profile?.profile_image}</li>
                    <li>email: {profile?.email}</li>
                    <li>닉네임: {`${profile?.name}`}</li>
                </>
            )}
        </ul>
    </>
}

export default NaverSignInSample;