import React, {CSSProperties, useEffect, useState} from 'react';
import axios from "axios";
import cryptoJs from 'crypto-js';

import {
    NHIIncomeItem,
    NHIRefereceItem,
    NHISimpleMedicalCheckup,
    NHISimpleMedicalCheckupStep1Response,
    NHISimpleMedicalCheckupStep2Response
} from "@/service/rothynative/NHISService";

// 개발
const nhisDomain = 'https://datahub-dev.scraping.co.kr'
// 운영
// const nhisDomain = 'https://api.mydatahub.co.kr'

const SECRET_KEY = 'UNPoRD0voPDC6okPjuoBZj0hjllD0CVo' // 32자리 비밀키
const IV = 'DezRvPqDUKDjhBB0' // 16자리 iv
const ACCESS_TOKEN = 'f49f7fc4b730484cbc314064a3d57ee8642fcffe'

const NHISSample: React.FC = (): JSX.Element => {
    const [loginOption, setLoginOption] = useState("5")
    const [telecomType, setTelecomType] = useState("1")
    const [name, setName] = useState("이민섭")
    const [birthday, setBirthday] = useState("19840505")
    const [hpNumber, setHpNumber] = useState("01048006591")

    const [medicalCheckupStep1Response, setMedicalCheckupStep1Response] = useState<NHISimpleMedicalCheckupStep1Response | undefined>(undefined)
    const [medicalCheckupStep2Response, setMedicalCheckupStep2Response] = useState<NHISimpleMedicalCheckupStep2Response | undefined>(undefined)

    const [callbackId, setCallbackId] = useState<string | undefined>(undefined)
    const [callbackType, setCallbackType] = useState<string | undefined>(undefined)

    const [medicalCheckup, setMedicalCheckup] = useState<NHISimpleMedicalCheckup | undefined>(undefined)
    const [incomeList, setIncomeList] = useState<NHIIncomeItem[] | undefined>(undefined)
    const [unitMap, setUnitMap] = useState<NHIRefereceItem | undefined>(undefined)

    // const [medicalCheckupStep1Response, setMedicalCheckupStep1Response] = useState<NHISimpleMedicalCheckupStep1Response | undefined>(STEP1_RESULT)
    // const [medicalCheckupStep2Response, setMedicalCheckupStep2Response] = useState<NHISimpleMedicalCheckupStep2Response | undefined>(STEP2_RESULT)
    //
    // const [callbackId, setCallbackId] = useState<string | undefined>(STEP1_RESULT.data.callbackId)
    // const [callbackType, setCallbackType] = useState<string | undefined>(STEP1_RESULT.data.callbackType)
    //
    // const [medicalCheckup, setMedicalCheckup] = useState<NHISimpleMedicalCheckup | undefined>(STEP2_RESULT.data)
    // const [incomeList, setIncomeList] = useState<NHIIncomeItem[] | undefined>(STEP2_RESULT.data.INCOMELIST.reverse())
    // const [unitMap, setUnitMap] = useState<NHIRefereceItem | undefined>(STEP2_RESULT.data.REFERECELIST[0])

    const onHealthScreeningsSearchStep1 = () => {
        const encBirthday = cryptoJs.AES.encrypt(birthday, cryptoJs.enc.Utf8.parse(SECRET_KEY), {
            iv: cryptoJs.enc.Utf8.parse(IV),
            padding: cryptoJs.pad.Pkcs7,
            mode: cryptoJs.mode.CBC,
        });

        axios
            .post(nhisDomain + '/scrap/common/nhis/MedicalCheckupGlanceSimple', {
                "LOGINOPTION": loginOption,
                "JUMIN": encBirthday.toString(),
                "USERNAME": name,
                "HPNUMBER": hpNumber,
                "TELECOMGUBUN": telecomType,
            }, {
                headers: {
                    'Authorization': 'Token ' + ACCESS_TOKEN,
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
            .then((response) => {
                const step1Response = response.data as NHISimpleMedicalCheckupStep1Response

                if (step1Response && step1Response.data.callbackId && step1Response.data.callbackType) {
                    setCallbackId(step1Response.data.callbackId)
                    setCallbackType(step1Response.data.callbackType)
                }

                setMedicalCheckupStep1Response(step1Response)

            })
            .catch((error) => {
                console.error(error)

                setCallbackId(undefined)
                setCallbackType(undefined)
                setMedicalCheckupStep1Response(undefined)
            })
    }

    const onHealthScreeningsSearchStep2 = () => {
        if (!callbackId || !callbackType) {
            alert('간편인증 정보가 존재하지 않습니다')
            return
        }

        axios
            .post(nhisDomain + '/scrap/captcha', {
                "callbackId": callbackId,
                "callbackType": callbackType,
            }, {
                headers: {
                    'Authorization': 'Token ' + ACCESS_TOKEN,
                    'Content-Type': 'application/json;charset=UTF-8',
                }
            })
            .then((response) => {
                const step2Response = response.data as NHISimpleMedicalCheckupStep2Response

                setMedicalCheckup(step2Response.data)
                setIncomeList(step2Response.data.INCOMELIST.reverse())
                setUnitMap(step2Response.data.REFERECELIST[0])

                setMedicalCheckupStep2Response(step2Response)
            })
            .catch((error) => {
                console.error(error)

                setCallbackId(undefined)
                setCallbackType(undefined)
            })
    }

    return <>
        <div style={styles.wrap}>
            <div style={styles.header}>
                <div style={styles.title}><h6>건강검진 결과(간편인증)</h6></div>
                <div style={styles.between} />
                {!callbackId && !callbackType && (<div style={styles.button} onClick={onHealthScreeningsSearchStep1}>조회</div>)}
            </div>
            <div style={styles.content}>
                {!callbackId && !callbackType && (
                    // const loginOption = '5'
                    // const birthDay = '19840505'
                    // const encBirthDay = 'maBkZ0qP90n1uNb9EXoBIg=='
                    // const name = '이민섭'
                    // const hpNumber = '01048006591'
                    // const telecomType = '1'
                    <div>
                        <select value={loginOption} onChange={(e) => {
                            setLoginOption(e.target.value)
                        }}>
                            <option value={""}>간편인증서 선택</option>
                            <option value={"0"}>카카오톡</option>
                            <option value={"1"}>삼성패스</option>
                            <option value={"2"}>페이코</option>
                            <option value={"3"}>통신사</option>
                            <option value={"4"}>KB모바일인증서</option>
                            <option value={"5"}>네이버</option>
                            <option value={"6"}>신한인증서</option>
                            <option value={"7"}>토스</option>
                        </select>
                        <input style={{width: '100%'}} placeholder={'이름'} value={name} onChange={(e) => {
                            setName(e.target.value)
                        }} />
                        <input style={{width: '100%'}} placeholder={'생년월일(20000101'} value={birthday} onChange={(e) => {
                            setBirthday(e.target.value)
                        }} /><br />
                        <div>
                            <select value={telecomType} onChange={(e) => {
                                setTelecomType(e.target.value)
                            }}>
                                <option value={""}>통신사 선택</option>
                                <option value={"1"}>SKT</option>
                                <option value={"2"}>KT</option>
                                <option value={"3"}>LGT</option>
                            </select>
                            <input placeholder={'전화번호(01011112222)'} value={hpNumber} onChange={(e) => {
                                setHpNumber(e.target.value)
                            }} />
                        </div>
                    </div>
                )}
                {callbackId && callbackType && !medicalCheckup && (
                    <>
                        <div style={styles.button} onClick={onHealthScreeningsSearchStep2}>인증완료</div>
                        <div>
                            입력하신 휴대폰으로 인증 요청 메시지를 보냈습니다.<br />
                            앱에서 인증을 진행해주세요.
                        </div>
                    </>
                )}
                {callbackId && callbackType && incomeList && unitMap && (
                    <>
                        {incomeList.map((value, key) => {
                            return (<div key={key}>
                                <div style={{background: '#CCCCCC', border: 1, fontSize: 16, padding: 10, marginTop: 10 * key}}>{value.GUNYEAR}({value.GUNDATE})</div>
                                <table style={{width: '100%'}}>
                                    <thead>
                                        <tr>
                                            <th />
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={styles.thTd}>검사항목</td>
                                            <td style={styles.thTd}>수치(상태)</td>
                                            <td style={styles.thTd}>단위</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>신장</td>
                                            <td style={styles.tbTd}>{value.HEIGHT}</td>
                                            <td style={styles.tbTd}>{unitMap.WAISTSIZE}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>체중</td>
                                            <td style={styles.tbTd}>{value.WEIGHT}</td>
                                            <td style={styles.tbTd}>{unitMap.WEIGHT}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>체질량지수</td>
                                            <td style={styles.tbTd}>{value.BODYMASS}</td>
                                            <td style={styles.tbTd}>{unitMap.BODYMASS}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>시력(좌/우)</td>
                                            <td style={styles.tbTd}>{value.SIGHT}</td>
                                            <td style={styles.tbTd}></td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>청각(좌/우)</td>
                                            <td style={styles.tbTd}>{value.HEARING}</td>
                                            <td style={styles.tbTd}></td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>혈압(최고/최저)</td>
                                            <td style={styles.tbTd}>{value.BLOODPRESS}</td>
                                            <td style={styles.tbTd}>{unitMap.BLOODPRESS}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>요단백</td>
                                            <td style={styles.tbTd}>{value.YODANBAK}</td>
                                            <td style={styles.tbTd}>{unitMap.YODANBAK}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>혈색소</td>
                                            <td style={styles.tbTd}>{value.HEMOGLOBIN}</td>
                                            <td style={styles.tbTd}>{unitMap.HEMOGLOBIN}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>공복혈당</td>
                                            <td style={styles.tbTd}>{value.BLOODSUGAR}</td>
                                            <td style={styles.tbTd}>{unitMap.BLOODSUGAR}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>총콜레스테롤</td>
                                            <td style={styles.tbTd}>{value.TOTCHOLESTEROL}</td>
                                            <td style={styles.tbTd}>{unitMap.TOTCHOLESTEROL}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>HDL콜레스테롤</td>
                                            <td style={styles.tbTd}>{value.HDLCHOLESTEROL}</td>
                                            <td style={styles.tbTd}>{unitMap.HDLCHOLESTEROL}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>중성지방</td>
                                            <td style={styles.tbTd}>{value.TRIGLYCERIDE}</td>
                                            <td style={styles.tbTd}>{unitMap.TRIGLYCERIDE}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>LDL콜레스테롤</td>
                                            <td style={styles.tbTd}>{value.LDLCHOLESTEROL}</td>
                                            <td style={styles.tbTd}>{unitMap.LDLCHOLESTEROL}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>혈청크레아티닌</td>
                                            <td style={styles.tbTd}>{value.SERUMCREATININE}</td>
                                            <td style={styles.tbTd}>{unitMap.SERUMCREATININE}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>신사구체여과율(GFR)</td>
                                            <td style={styles.tbTd}>{value.GFR}</td>
                                            <td style={styles.tbTd}>{unitMap.GFR}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>AST (SGOT)</td>
                                            <td style={styles.tbTd}>{value.SGOT}</td>
                                            <td style={styles.tbTd}>{unitMap.SGOT}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>ALT(SGPT)</td>
                                            <td style={styles.tbTd}>{value.SGPT}</td>
                                            <td style={styles.tbTd}>{unitMap.SGPT}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>감마지티피(y-GTP)</td>
                                            <td style={styles.tbTd}>{value.YGPT}</td>
                                            <td style={styles.tbTd}>{unitMap.YGPT}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>폐결핵 흉부질환	</td>
                                            <td style={styles.tbTd}>{value.CHESTTROUBLE}</td>
                                            <td style={styles.tbTd}>{unitMap.CHESTTROUBLE}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>골다공증</td>
                                            <td style={styles.tbTd}>{value.OSTEOPOROSIS}</td>
                                            <td style={styles.tbTd}>{unitMap.OSTEOPOROSIS}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.tbTd}>판정</td>
                                            <td style={styles.tbTd}>{value.JUDGMENT}</td>
                                            <td style={styles.tbTd}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>)
                        })}
                    </>
                )}
            </div>
        </div>
    </>
}

const styles = {
    wrap: {
        marginTop: "10px",
    } as CSSProperties,
    header: {
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    title: {
        alignSelf: "center"
    } as CSSProperties,
    between: {
        flex: 1,
    } as CSSProperties,
    button: {
        background: "#FFFF00",
        alignSelf: "center",
        padding: "10px 10px",
    } as CSSProperties,
    content: {
        padding: "0px 10px"
    } as CSSProperties,
    contentList: {
        padding: "0px 10px",
        maxHeight: "200px",
    } as CSSProperties,
    thTd: {
        // width: '20%',
        height: '20px',
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'center',
        padding: 5,
        borderColor: '#C6C6C6'
    } as CSSProperties,
    tbTd: {
        // width: '20%',
        height: '18px',
        fontSize: 14,
        lineHeight: '18px',
        textAlign: 'center',
        padding: 5,
        borderColor: '#C6C6C6'
    } as CSSProperties,
}

export default NHISSample;
