import React from 'react'

export interface MissionRecordParam{
    name:string,
    record:string,
    comparison:string,
    recordClass?:string
}

const MissionRecord = (props: MissionRecordParam) => {
    return (
        <div className={'record-box mt-8px prpl-24px'}>
            <div className={'flex justify-between align-center'}>
                <p className={'c1_l'}>{props.name}</p>
                <div className={'flex-column flex-end'}>
                    <p className={`s_title_1_r ${props.recordClass?props.recordClass:''}`}>{props.record}</p>
                    <p className={'c2_m cl-primary-cyan01'}>{props.comparison}</p>
                </div>
            </div>
        </div>
    )
}

export default MissionRecord