import React, {useCallback, useEffect, useState} from 'react'
import Icon from "@/view/common/component/Icon";

export interface AgreeDetailConfig {
    type: string
    setType: (param:string) => void
    title: string
    openSignupDetail: () => void
}


const AgreeDetail = (props:AgreeDetailConfig) => {
    const[agreeType, setAgreeType] = useState(props.type);

    const setType = useCallback((param:string) => props.setType(param), [props.setType])
    const openSignupDetail = useCallback(() => props.openSignupDetail(), [props.openSignupDetail])

    useEffect(() => {
        if(agreeType === 'Y'){
            setType('Y')
        }else{
            setType('N')
        }
    }, [agreeType])

    useEffect(() => {
        if(props.type === 'Y'){
            setAgreeType('Y')
        }else{
            setAgreeType('N')
        }
    }, [props.type])

    return (
        <>
            <div className={'mt-14px agree-detail flex justify-between'}>
                <div>
                    {
                        agreeType === 'Y' ?
                            <div className={'detail-agree-item detail-agree-checkbox'} onClick={() => setAgreeType('N')}>
                                <Icon iconClass='check-box-on w-14px h-10px'/>
                            </div>
                        :
                            <div className={'detail-agree-item detail-agree-checkbox'} onClick={() => setAgreeType('Y')}>
                                <Icon iconClass='check-box-off w-14px h-10px'/>
                            </div>
                    }
                    <div className={'b2_r detail-agree-item '}>{props.title}</div>
                </div>
                <div className={'c1_r read-btn'} onClick={() => openSignupDetail()}>보기</div>
            </div>
        </>
    )
}

export default AgreeDetail