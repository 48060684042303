import React from "react";
import Icon from "@/view/common/component/Icon";
import TwoBottomButton from "@/view/common/component/button/TwoBottomButton";


type UserDvicAddUsedMineProps ={
    onClickCancle:() => Promise<void> | void,
    onClickOk:() => Promise<void> | void
}

const UserDvicAddUsedMineView = (props : UserDvicAddUsedMineProps) => {
    console.debug("UserDvicAddUsedMineView")


    const handleCancle= () => {
        console.debug("handleCancle")
        handleCancleParent()
        console.debug("handleCancleParent")

    }

    const handleCancleParent = async () => {
       await props.onClickCancle()
    }

    return(
        <>
            <div className={' h-fit-content  justify-center align-center w-100p h-60vh'} >
                <div className={'align-center'}>
                    <div className={'justify-center align-center w-100p mt-32px'}>
                        <div className={'h6_b  flex-column align-center'}>이미 연결된 체중계에요!</div>
                    </div>
                    <div className={'justify-center align-center w-100p mt-16px'}>
                        <div className={'b2_r  flex-column align-center'}>기기를 추가하시려면 다른 체중계에 올라가주세요</div>
                    </div>
                    <div className={'justify-center align-center flex mt-43px'} style={{width:'96.82',height:'146.27'}} >
                        <Icon iconClass={'scale-used-mine wi-9682px hi-14627px'}  />
                    </div>

            </div>
            <div className={'flex'}>
                <div><TwoBottomButton  titleOk='다른 체중계 연결하기' disabledOk={false}
                                       titleCancle={'나중에'}  classNameCancle={'gray03'} onClickCancle={handleCancle} onClickOk={props.onClickOk}/></div>
            </div>
            </div>
        </>
    )
}

export default UserDvicAddUsedMineView