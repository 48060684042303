import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import BoardService, {FaqList, FaqListData} from "@/service/BoardService";
import Loading from "@/view/common/component/Loading";
import CustomizedAccordion from "@/view/common/component/CustomizedAccordion";

const Faq = () => {
    const [faqList, setFaqList] = useState<FaqListData[]>([])

    const { status, data:typeResult} = useQuery<FaqList, AxiosError>(
        ['faqlist'],
        ()=> BoardService.selectFaq(),
        {
            enabled: true,
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 60,
            onSuccess: (v: FaqList) => updateFaqList(v.data.objectList),
        }
    )

    const updateFaqList = (data: FaqListData[]) => {
        const dataList : FaqListData[] =[]
        data.map( d => dataList.push({ faqId: d.faqId, title: d.title, contents: d.contents }))
        setFaqList(dataList)
    }

    useEffect(() => {
        if(typeResult){
            updateFaqList(typeResult.data.objectList)
        }
    }, [])

    if(status === 'loading'){
        return (
            <>
                <Loading/>
            </>
        )
    }else if(faqList.length !== 0) {
        return (
            <>
                <div style={{paddingBottom: '32px'}}>
                    {faqList.map((n, index) => {
                        return <CustomizedAccordion key={n.faqId} title={n.title} contents={n.contents} index={index}/>
                    })}
                </div>
            </>
        )
    }else{
        return (
            <>
            </>
        )
    }
}

export default Faq;