import React, {useEffect, useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export interface CircularProps{
    circularValue: number,
    chartFlag: boolean,
    countFlag: boolean
}

const ScoreChart = (props: CircularProps) => {
    const [_variant, setVariant] = useState<"indeterminate" | "determinate" | undefined>('indeterminate')
    const [_circularValue, setCircularValue] = useState(0)
    const [_color, setColor] = useState('#616161')

    useEffect(()=>{
        if(props.chartFlag){
            setVariant('determinate')
            setCircularValue(props.circularValue)
        }
    },[props.chartFlag])

    useEffect(()=>{
        if(props.countFlag){
            setColor('#24CBAA')
        }else{
            setColor('#616161')
        }
    },[props.countFlag])

    return <div className={'position-relative mt-24px'} style={{height: '108px'}}>
            <CircularProgress
                variant="determinate"
                style={{color: '#F1F1F1',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%)'
                    }}
                size={92}
                thickness={9}
                value={100}
            />
            <div className={'flex justify-center'}>
                <CircularProgress
                    variant={_variant}
                    style={{color: _color,
                        transform: 'rotate(-90deg)',
                        strokeLinecap: 'round'}}
                    size={92}
                    thickness={9}
                    value={_circularValue}
                />
            </div>
        </div>
}

export default ScoreChart
