import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = () => {
    return (
        <div id='loading-page'>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress color="primary"/>
            </Box>
        </div>
    );
};

export default Loading;