import React from 'react'
import {UserInfo} from "@/view/menu/MenuView";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import {USER_INFO} from "@/common/Constant";

interface WalkScoreDescriptionParam{
    imageUrl:string,
    abilityType:string,
}

const WalkScoreDescription = (props: WalkScoreDescriptionParam) => {
    const userInfo: UserInfo  = StringUtil.jsonString2ObjectWithMap<UserInfo>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
    const userNickNm = userInfo?.nickNm
    const information = '걷기능력점수는 ' + userNickNm + '님이\n얼마나 효율적으로 걷고 있는지 보여줄 수\n있는 지표입니다. 해당 점수는 걸음수, 걸은 거리, 운동 시간, 걸음 속도, 운동강도(심박수)를\n기반으로 계산됩니다.'
    const notice = '*운동 강도는 심박수 측정이 가능한 웨어러블 \n디바이스를 통해 기록할 수 있습니다.'
    const comment = userNickNm + '님의 걸음 능력 수준을 다양한 타입에\n비유해서 보여주고 있어요!'
    return (
        <div className={'mt-24px flex-column align-center mrml-24px walk-score-description text-center'}>
            <p className={'h5_l'}>걷기능력점수란?</p>
            <p className={'b2_l mt-16px'}>{information}</p>
            <p className={'cl-gray cl-basic-black c1_l mt-10px'}>{notice}</p>
            <div className={'mt-39px w-100p border-top-gray'}/>
            <img width={'100px'} height={'100px'} src={props.imageUrl}/>
            <p className={'mt-24px h5_l'}>나의 걸음 타입</p>
            <p className={'mt-16px b2_l mb-52px'}>{comment}</p>
        </div>
    )
}

export default WalkScoreDescription