import React, {forwardRef} from 'react';

import VitaIndexDetail from "@/view/vitaindex/component/VitaIndexDetail";

const VitaIndexDetailView = forwardRef((props, ref) => {
    return (
        <>
            <div id="VitaIndexDetailView" >
                <VitaIndexDetail/>
            </div>
        </>
    )
})
export default VitaIndexDetailView;