import React, {useEffect, useState} from 'react'
import Flicking from '@egjs/react-flicking'
import {ScoreTipListData} from "@/service/VitaIndexService";
import {VitaIndexImage} from "@/common/Constant";

export interface PropsConfig {
    scoreTipList?:ScoreTipListData[]
}

const RaiseScore = (props: PropsConfig) => {
    const [scoreTipList, setScoreTipList] = useState(props.scoreTipList);

    const getImage = (lifelogType: string) => {
        let image = ''
        switch (lifelogType){
            case '걸음':
                image = VitaIndexImage.scoreTipList.walk
                break
            case '수면':
                image = VitaIndexImage.scoreTipList.sleep
                break
            case '체성분':
                image = VitaIndexImage.scoreTipList.body
                break
        }
        return <div style={{width: '54px', height: '54px', marginRight: '8px'}}>
                    <img width={'100%'} height={'100%'} src={image}/>
                </div>
    }
    useEffect(()=> {
        if(props.scoreTipList){
            setScoreTipList(props.scoreTipList)
        }
    }, [props.scoreTipList])

    return <div className={'mt-60px mrml-24px mb-60px'}>
                <div className={'mb-8px'}>
                    <span className={'s_title_1_m cl-gray01'}> 점수 더 올려볼까요?</span>
                </div>
                <div className={'mb-16px'}>
                    <span className={'b2_r cl-gray01'}>가장 많은 점수를 획득할 수 있는 방법을 분석했어요.</span>
                </div>
                <div>
                    <Flicking bounce={1}
                              circular={false}
                              cameraClass={'main-content'}
                              hanger={'0'}
                              align={'prev'}
                              moveType={['strict', { count: 1 }]}
                              changeOnHold={false}
                              bound={false}
                    >
                        {
                            scoreTipList &&
                                <>
                                {scoreTipList.map((d, index) => {
                                    return <div key={index} className='raise-score-card'>
                                                <div className={'flex justify-space-between'}>
                                                    <div className={'flex'}>
                                                        {getImage(d.lifelogType)}
                                                        <div className={'flex-column justify-center'}>
                                                            {
                                                                d.score && d.score >0 &&
                                                                    <div>
                                                                        <p className={'b2_m cl-primary-cyan01 flex align-center'}>최대</p>
                                                                        <p className={'b2_m cl-primary-cyan01 flex align-center'}>{'+'+String(d.score) + '점'}</p>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={'raise-score-chip'}>
                                                        <span className={'b2_r'}>{d.lifelogType}</span>
                                                    </div>
                                                </div>
                                                <div className={'mt-16px'}>
                                                    <p className={'b1_m cl-gray01'}>{d.title}</p>
                                                    <p className={'b2_r cl-gray03 mt-13px'} style={{lineHeight:'22px'}}>{d.comment}</p>
                                                </div>
                                            </div>

                                })}
                                </>
                        }

                    </Flicking>
                </div>
            </div>
}

export default RaiseScore
