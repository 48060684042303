import React, {useEffect, useRef, useState} from 'react'
import moment from "moment";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {Chart} from "react-chartjs-2";
import {Skeleton} from "@mui/material";
import SleepService, {
    SleepMsreDtm,
    SleepPatternRes,
    SleepReportReq,
} from "@/service/SleepService"
import StringUtil from "@/common/StringUtil";

import Icon from "@/view/common/component/Icon";
import NumberUtil from "@/common/NumberUtil";
import {ChartDataSet, getLabelDataByReportType, ReportPropsConfig, ReportTypeItem} from "@/common/ReportUtil";

const ScoreByPattern =(props:ReportPropsConfig) => {
    const reportTypeItemList = [{name:'전체', value:'DATE'}, {name:'요일별', value:'YOIL'}]
    const [sleepPatternMsg, setSleepPatternMsg] = useState<string | undefined>(undefined)
    const [sleepReportReq, setSleepReportReq] = useState<SleepReportReq>( new SleepReportReq ('','','','','',0,''))
    const [data, setData] = useState<{labels:string[], datasets:ChartDataSet[]}>()
    const [options, setOptions] = useState({})
    const [resourceData, setResourceData] = useState<SleepPatternRes | undefined>(undefined)
    const [clickedReportTypeIndex, setClickedReportTypeIndex] = useState(0)
    const [selectedReportTypeItem, setSelectedReportTypeItem] = useState<ReportTypeItem| undefined>(reportTypeItemList[0])

    const [scoreVisible, setScoreVisible] = useState(true)
    const [monthYoilVisible, setMonthYoilVisible] = useState(true)
    const [averageLabelMsg, setAverageLabelMsg] = useState('')
    const [sleepTypeMsg, setSleepTypeMsg] = useState('')
    const [avgSleepPatternScore, setAvgSleepPatternScore] = useState(0)

    const [scoreInfoVisible, setScoreInfoVisible] = useState<boolean>(false)
    const [standRangeStyle, setStandRangeStyle] = useState({})

    const [monthYoilAverage, setMonthYoilAverage] = useState<string[]>([]);

    const chartDivRef = useRef<HTMLDivElement>(null)

    const reportHeight = 160
    const divWidth = chartDivRef.current ? chartDivRef.current.clientWidth : window.innerWidth - 48
    const reportWidth = divWidth


    const selectReportTypeButton = (index:number, _data:ReportTypeItem) =>{
        if(clickedReportTypeIndex !== index){
            setClickedReportTypeIndex(index)
            setSelectedReportTypeItem(_data)
        }
    }

    const {refetch: getSleepRecordPattern} = useQuery<SleepPatternRes, AxiosError>(
        ['getSleepRecordPattern'],
        () => SleepService.getSleepRecordPattern(sleepReportReq),
        {
            onSuccess: (v: SleepPatternRes) => success(v)
        }
    )

    const clickStageInfo = () => {
        if(scoreInfoVisible){
            setScoreInfoVisible(false)
        }else{
            setScoreInfoVisible(true)
        }
    }

    const success = (v:SleepPatternRes) => {
        setResourceData(v)
    }

    const [avgMsreBeginDtm, setAvgMsreBeginDtm] = useState('')
    const [avgMsreBeginDtmAmPm, setAvgMsreBeginDtmAm] = useState('')
    const [avgMsreEndDtm, setAvgMsreEndDtm] = useState('')
    const [avgMsreEndDtmAmPm, setAvgMsreEndDtmAmPm] = useState('')

    const [msreBeginError, setMsreBeginError] = useState('')
    const [msreEndError, setMsreEndError] = useState('')

    const updateScore = (v:SleepPatternRes) => {
        setAvgSleepPatternScore(v.data.avgSleepPatternScore)
        if(StringUtil.isNotEmpty(v.data.avgMsreBeginDtm)){
            setAvgMsreBeginDtm(moment(v.data.avgMsreBeginDtm).format('hh')+':'+moment(v.data.avgMsreBeginDtm).format('mm'))
            setAvgMsreBeginDtmAm(moment(v.data.avgMsreBeginDtm).format('A'))
        }else{
            setScoreVisible(false)
        }

        if(StringUtil.isNotEmpty(v.data.avgMsreEndDtm)){
            setAvgMsreEndDtm(moment(v.data.avgMsreEndDtm).format('hh')+':'+moment(v.data.avgMsreEndDtm).format('mm'))
            setAvgMsreEndDtmAmPm(moment(v.data.avgMsreEndDtm).format('A'))
        }

        if(StringUtil.isNotEmpty(v.data.msreBeginErrorTime)) {
            const msreBeginErrorTimeHH = v.data.msreBeginErrorTime.substring(0,2)
            const msreBeginErrorTimeMM = v.data.msreBeginErrorTime.substring(3,5)
            setMsreBeginError('±' + msreBeginErrorTimeHH + 'H ' + msreBeginErrorTimeMM + 'M')
        }else{
            setMsreBeginError('')
        }

        if(StringUtil.isNotEmpty(v.data.msreEndErrorTime)) {
            const msreEndErrorTimeHH = v.data.msreEndErrorTime.substring(0,2)
            const msreEndErrorTimeMM = v.data.msreEndErrorTime.substring(3,5)
            setMsreEndError('±' + msreEndErrorTimeHH + 'H ' + msreEndErrorTimeMM + 'M')
        }else{
            setMsreEndError('')
        }
    }

    let minBeginDtm = ''
    let reportEndDtValue = 0
    let pointRadius = 4
    let pointResizeRate = 0.025
    let barResizePercentage = 0.3
    const createChart = (v:SleepPatternRes) => {
        const labels:string[] = []
        const labelData1:Array<[number, number]> = []
        const labelData2:any[] = []
        const labelData3:Array<[number, number]> = []
        minBeginDtm = v.data.minBeginDtm
        reportEndDtValue = moment(v.data.maxEndDtm).diff(v.data.minBeginDtm,'seconds')

        setSleepPatternMsg(v.data.sleepPatternMsg)
        setAverageLabelMsg(v.data.sameGroupTitle)
        setSleepTypeMsg(v.data.sleepTypeMsg)
        updateScore(v)
        updateChartDetail(props.periodType)
        v.data.sleepMsreDtmList.forEach(d => {
            labels.push(getLabelDataByReportType(d, props.periodType, selectedReportTypeItem))
            if(d.MSRE_END_DTM){
                reportEndDtValue =  NumberUtil.replaceBiggerNumber(reportEndDtValue, moment(d.MSRE_END_DTM).diff(minBeginDtm, 'seconds'))
            }
            labelData1.push(getLabelData1(d))
            labelData2.push(getLabelData2(d))

            labelData3.push([moment(v.data.avgMsreBeginDtm).diff(minBeginDtm, 'seconds'),  moment(v.data.avgMsreEndDtm).diff(minBeginDtm, 'seconds')])

        })
        const realReportHeight = reportHeight-43 // 위쪽 28, 아래쪽 15
        const _averageToRange = moment(v.data.sameGroupAvgMsreBeginDtm !== ''?v.data.sameGroupAvgMsreBeginDtm:v.data.avgMsreBeginDtm).diff(minBeginDtm, 'seconds')
        const _averageFromRange = moment(v.data.sameGroupAvgMsreEndDtm !== ''?v.data.sameGroupAvgMsreEndDtm:v.data.avgMsreEndDtm).diff(minBeginDtm, 'seconds')

        const averageMarginTop = String((_averageToRange*realReportHeight/reportEndDtValue)+28)+'px'
        const averageHeight = String((_averageFromRange-_averageToRange)*realReportHeight/reportEndDtValue)+'px'

        updateStandRangeStyle(averageHeight, averageMarginTop)

        updateData(labels, labelData1, labelData2, labelData3)
        updateOptions(reportEndDtValue)
        updateMonthYoilAverage(v)
    }

    const updateMonthYoilAverage = (v:SleepPatternRes) => {
        const _monthYoilAverage:string[] = []
        if (monthYoilVisible) {
            v.data.sleepMsreDtmList.forEach(d => {
               _monthYoilAverage.push(d.SLEEP_PATTERN_SCORE>0?String(d.SLEEP_PATTERN_SCORE):'-')
                setMonthYoilAverage(_monthYoilAverage)
            })
        }
    }


    const updateStandRangeStyle = (averageHeight:string, averageMarginTop:string) => {
        setStandRangeStyle({
            backgroundColor: 'rgba(173, 230, 223, 0.3)',
            position:'absolute',
            width: String(reportWidth-48)+'px',
            marginLeft: '44px',
            height: averageHeight,
            marginTop: averageMarginTop,  //=(1-8/8)*(240-30)
            //=((y축ticks높이-standToRange)*(차트높이-x축ticks높이)+기본마진10)/y축ticks높이
        })
    }

    const updateChartDetail = (_periodType:string) => {
        let type = _periodType
        if(_periodType === 'MONTH' && selectedReportTypeItem?.value === 'YOIL'){
            type = 'WEEK'
        }
        switch (type) {
            case 'WEEK':
                pointRadius = 4
                pointResizeRate = 0.045
                barResizePercentage = 0.3
                break
            case 'MONTH':
                pointRadius = 2
                pointResizeRate = 0.023
                barResizePercentage = 0.6
                break
            case 'YEAR':
                pointRadius = 2
                pointResizeRate = 0.025
                barResizePercentage = 0.3
                break
        }
    }

    const getLabelData1 = (d:SleepMsreDtm): [number, number] => {
        return [moment(d.MSRE_BEGIN_DTM).diff(minBeginDtm, 'seconds'),  moment(d.MSRE_END_DTM).diff(minBeginDtm, 'seconds')]
    }

    const getLabelData2 = (d:SleepMsreDtm) => {
        return moment(d.MSRE_END_DTM).diff(minBeginDtm, 'seconds')- Number(reportEndDtValue)*pointResizeRate
    }

    const updateData = (_labels:string[], labelData1:Array<[number, number]> , labelData2:any[], labelData3:Array<[number, number]>) => {
        setData({
            labels: _labels,
            datasets: [
                {
                    type: 'bar' as const,
                    label: '이번주',
                    backgroundColor: '#24CBAA',
                    data: labelData1,
                    //borderColor: 'white',
                    barPercentage: barResizePercentage,
                    borderRadius: 10,
                    borderSkipped: false,
                    order:2,
                },
                {
                    type: 'line' as const,
                    label: '지난주',
                    backgroundColor: '#FFFFFF',
                    //borderColor: '#CDCDCD',
                    borderWidth: 0,
                    fill: false,
                    data: labelData2,
                    order:1,
                    radius: pointRadius,

                },
                {
                    type: 'bar' as const,
                    label: '이번주',
                    data: labelData3,
                    backgroundColor: 'blue',
                    barPercentage: barResizePercentage,
                    borderRadius: 10,
                    borderSkipped: false,
                    order:2,
                    hidden: true
                },
            ],
        })
    }

    const updateOptions = (_reportEndDtValue:number) => {
        setOptions({
            animation: false,
            responsive: false,
            plugins: {
                datalabels: false,
                legend: {
                    display: false,
                    position: 'right',

                },
                tooltip: false,
            },
            scales: {
                x: {
                    position: 'top',
                    grid: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxRotation: 0,
                        autoSkip: false,
                        font: {
                            size: 12
                        }
                    },
                },
                y: {
                    reverse: true,
                    grid: {
                        drawBorder: false,
                        display: false
                    },
                    max: _reportEndDtValue,
                    ticks: {
                        stepSize: Math.ceil(_reportEndDtValue/2),
                        callback: function (value: number) {
                            let result:string[] = []
                            const _value = moment(minBeginDtm).add(value, 'second')
                            if(value === 0){
                                result = [moment(minBeginDtm).format('hh')+':'+moment(minBeginDtm).format('mm'), moment(minBeginDtm).format('A')]
                            }else  {
                                result = [moment(_value).format('hh')+':'+moment(_value).format('mm'), moment(_value).format('A')]
                            }
                            return result
                        }
                    },

                },

            },
        })
    }

    const updateScoreVisible = (selectType: string | undefined, periodType: string) => {
        if(periodType === 'MONTH' && selectType === 'YOIL'){
            setScoreVisible(false)
            setMonthYoilVisible(true)
        }else{
            setScoreVisible(true)
            setMonthYoilVisible(false)
        }
    }

    useEffect(()=>{
        if(resourceData){
            createChart(resourceData)
        }
    },[resourceData])


    useEffect(() => {
        let _selectType = 'DATE'
        if(selectedReportTypeItem){
            _selectType = selectedReportTypeItem.value
        }
        if(props){
            const _sleepReportReq = new SleepReportReq (
                props.fromDate,
                props.month,
                props.periodType,
                _selectType,
                props.toDate,
                props.userId,
                props.year,
            )
            setSleepReportReq(_sleepReportReq)
            updateScoreVisible(_selectType, props.periodType)
            //updatePastUnitMsg(props.periodType)
        }
    },[props, selectedReportTypeItem])


    useEffect(() => {
        if(sleepReportReq && StringUtil.isNotEmpty(sleepReportReq.periodType)){
            getSleepRecordPattern()
                .then(() =>{return Promise<void>})
                .catch(err => console.log(err))
        }
    },[sleepReportReq])

    return (
                <>
                    <div className={'s_title_1_m mt-56px'} style={{lineHeight:'1.2'}}>
                        <div className={'flex align-center justify-between walk-far'}>
                            <div>내 수면 규칙 점수는?</div>
                            <div className={'flex toggle-box'}>
                                {
                                    props.periodType === 'MONTH'?
                                        <div className={'flex toggle-box'}>
                                            {reportTypeItemList.map((_data, index)=>{
                                                return <div key={index}> <button className={`${clickedReportTypeIndex === index? 'on' : ''}`} onClick={() => selectReportTypeButton(index,_data)}>{_data.name}</button> </div>
                                            })}
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                        <div className={'gray-bar-thin mt-8px'}></div>
                        <div className={'b2_l mt-12px'}>{sleepPatternMsg}</div>
                    </div>
                    {
                        scoreVisible?
                        <div className={'flex mt-39px cl-gray02 justify-space-between'}>
                            <div className={'flex-column'}>
                                <div className={'b2_l '}>평균</div>
                                {
                                    avgSleepPatternScore>0?
                                        <div className={'flex pt-3px'} style={{alignItems:'baseline'}}>
                                            <div className={'h4_m pr-2px'}>{avgSleepPatternScore}</div>
                                            <div className={'b1_l'}>점</div>
                                        </div>
                                    :
                                        <div className={'flex pt-3px'} style={{alignItems:'baseline'}}>
                                            <div className={'h4_m pr-2px'}>계산중!</div>
                                        </div>
                                }
                            </div>
                            <div className={'flex'}>
                                <div className={'flex-column mr-24px'}>
                                    <div className={'b2_l'}>평균 취침 시간</div>
                                    <div className={'flex align-center'}>
                                        <div className={'s_title_1_m pr-2px'}>{avgMsreBeginDtm}</div>
                                        <div className={'b1_l'}>{avgMsreBeginDtmAmPm}</div>
                                    </div>
                                    <div className={'b2_l'}>{msreBeginError}</div>
                                </div>
                                <div className={'flex-column'}>
                                    <div className={'b2_l'}>평균 기상 시간</div>
                                    <div className={'flex align-center'}>
                                        <div className={'s_title_1_m pr-2px'}>{avgMsreEndDtm}</div>
                                        <div className={'b1_l'}>{avgMsreEndDtmAmPm}</div>
                                    </div>
                                    <div className={'b2_l'}>{msreEndError}</div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div className={'w-100p'} ref={chartDivRef}>
                    {
                        data ?
                            <>
                                {
                                    monthYoilVisible?
                                        <></>
                                        :
                                        <div style={standRangeStyle} ></div>
                                }

                                    <div className={'mt-36px'} style={{position: 'relative', zIndex: '2', width:String(reportWidth)+'px'}} >
                                        <Chart type='bar' data={data} options={options} height={String(reportHeight)+'px'} width={String(reportWidth)+'px'}/>
                                    </div>
                                {
                                    monthYoilVisible?
                                        <>
                                            <div className={'flex mt-18px align-center '}  style={{width: String(reportWidth-17) + 'px'}}>
                                                <div className={'c1_r cl-gray04 ml ml-12px'}>평균<br/>점수</div>
                                                <div className={'flex justify-between ml-24px'} style={{width: String(reportWidth-75) + 'px'}}>
                                                {monthYoilAverage.map((d, index) => {
                                                    return (<div className={'b2_l'} key={index}>{d}</div>)
                                                    }
                                                )}
                                                </div>
                                            </div>
                                            <div className={'mt-10px flex'} style={{justifyContent:'flex-end'}}>
                                                <div className={'flex align-center'}>
                                                    <div className={'mr-4px'}><Icon iconClass={'sleep-score-info-sleeptime w-10px h-10px'} /></div>
                                                    <div className={'c1_r cl-gray04 mr-12px'}>취침 시간</div>
                                                </div>
                                                <div className={'flex align-center'}>
                                                    <div className={'mr-4px'}><Icon iconClass={'sleep-score-info-endtime w-10px h-10px'} /></div>
                                                    <div className={'c1_r cl-gray04'}>기상 시간</div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className={'mt-10px flex'} style={{justifyContent:'flex-end'}}>
                                            <div className={'flex align-center'} >
                                                <div className={'mr-4px'}><Icon iconClass={'sleep-score-info-average w-10px h-10px'} /></div>
                                                <div className={'c1_r cl-gray04 mr-12px'}>{averageLabelMsg} 평균</div>
                                            </div>
                                            <div className={'flex align-center'}>
                                                <div className={'mr-4px'}><Icon iconClass={'sleep-score-info-sleeptime w-10px h-10px'} /></div>
                                                <div className={'c1_r cl-gray04 mr-12px'}>취침 시간</div>
                                            </div>
                                            <div className={'flex align-center'}>
                                                <div className={'mr-4px'}><Icon iconClass={'sleep-score-info-endtime w-10px h-10px'} /></div>
                                                <div className={'c1_r cl-gray04'}>기상 시간</div>
                                            </div>
                                        </div>
                                }



                            </>
                        :
                            <Skeleton variant="rounded" height={reportHeight} width={'100%'}/>
                    }
                    </div>
                    {
                        sleepTypeMsg?
                            <div className={'mt-26px'}>
                                <div className={'c1_r cl-primary-cyan01 bubble text-center h-34px flex justify-center align-center'}>
                                    <div>{sleepTypeMsg}</div>
                                </div>
                            </div>
                        :
                        <></>
                    }

                    <div className={'stage-inform mt-32px mb-60px'} onClick={clickStageInfo} >
                        <div className={'stage-inform-header flex justify-between align-center'}>
                            <div className={'b2_r pl-18px'}>수면 규칙 점수란?</div>
                            <div className={'b2_r pr-22px'}>
                                {
                                    scoreInfoVisible?
                                        <Icon iconClass={'arrow-top w-12px h-6px'} />
                                        :
                                        <Icon iconClass={'arrow-bottom w-12px h-6px'} />
                                }
                            </div>
                        </div>
                        {
                            scoreInfoVisible ?
                                <>
                                    <div className={'flex'}>
                                        <div className={'w-18px'} style={{height:'0.5px'}}></div>
                                        <div className={'gray-bar-thin'}></div>
                                        <div className={'w-18px'} style={{height:'0.5px'}}></div>
                                    </div>
                                    <div className={'stage-inform-detail mt-20px prpl-18px pb-24px'}>
                                        <div className={'b2_l'}>
                                            수면 규칙 점수는 수면 시간, 수면 질과 함께 수면 건강을 결정하는 요소 중 하나로, 얼마나 일관적으로 잠들고 일어났는지를 보여주는 지표입니다.<br/><br/>
                                            개인마다 잠들고 일어나는 시간은 다르지만, 규칙적인 수면 습관을 만들고 싶다면 잠드는 시간에 영향을 미치는 기상 시간을 맞추는 것이 도움 됩니다. 일어나는 시간의 편차는 2시간 이내가 좋습니다.<br/><br/>
                                            잠드는 시간은 되도록 밤 12시 이전이 좋습니다. 우리 몸은 잠든 후 2시간이 지나면 성장 호르몬과 렙틴 호르몬을 분비하는데, 밤 10시에서 새벽 2시 사이에 가장 활발히 분비되기 때문입니다. 참고로 성장 호르몬은 근육량을 늘려 기초 대사량을 늘려주고 체지방과 콜레스테롤을 감소시켜줍니다. 렙틴 호르몬은 포만감을 느끼게 해 과식을 막아줍니다.<br/>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </div>
                </>

    )
}

export default ScoreByPattern