import React, {useCallback, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import NotificationListItem from './NotificationListItem';
import Loading from "@/view/common/component/Loading";
import BoardService, {Notification, NotificationListData} from "@/service/BoardService";
import NoContent from "@/view/common/component/NoContent";

export interface postData{
    pushTrxId: number,
    type: string,
    pushTitle: string,
    sendDt: string,
    recvYn: string,
    icon: string,
}

const NotificationList = () => {
    const [notificationList, setNotificationList] = useState<postData[]>([])

    const { status, data:typeResult} = useQuery<Notification, AxiosError>(
        ['notificationlist'],
        ()=> BoardService.selectNotification(),
        {
            enabled: true,
            onSuccess: (v: Notification) => updateNotificationList(v.data.objectList),
        }
    )

    const updateNotificationList = (data: NotificationListData[]) => {
        const dataList : postData[] =[]
        data.map( d => {
            let icon 
            if(d.type === 'MEASURE' || d.type === 'BONUS'){
                icon = 'noti-point'
            } else if (d.type === 'ONE2ONE_ANS') {
                icon = 'noti-one2one'
            } else if (d.type === 'MISSION_END_NOTIFY') {
                icon = 'noti-mission'
            } else {
                icon = 'noti-etc'
            }
            dataList.push({ pushTrxId: d.pushTrxId, type:d.type, icon:icon, recvYn:d.recvYn,
                            pushTitle: d.pushTitle, sendDt: d.sendDt.substring(0,10) })
        })
        setNotificationList(dataList)
    }

    useEffect(() => {
        if(typeResult){
            updateNotificationList(typeResult.data.objectList)
        }
    }, [])

    if(status === 'loading'){
        return (
            <>
                <Loading/>
            </>
        )
    }else if(notificationList.length !== 0) {
        return (
            <>
                {notificationList.map((n,index) =>{
                    return <div key={n.pushTrxId}>
                        <NotificationListItem type={n.type}
                                              pushTrxId = {n.pushTrxId}
                                              index={index}
                                              leftIcon={n.icon}
                                              title={n.pushTitle}
                                              recvYn={n.recvYn}
                                              sendDt={n.sendDt}/>
                    </div>
                })}
            </>
        )
    }else {
        return (
            <>
                <NoContent className='no-notification-list mb-18px' notification='알림이 없습니다' />
            </>
        )
    }
}

export default NotificationList;