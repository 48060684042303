import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import SleepDetail from "@/view/sleep/component/SleepDetail";
import SleepRecord from "@/view/sleep/component/SleepRecord";

const SleepView = forwardRef((props, ref) => {
    const sleepDetailRef = useRef<{refresh: () => void}>(null)
    const sleepRecordRef = useRef<{viewFocusOut: () => void}>(null)

    const refreshSleep = () => {
        if(sleepDetailRef.current !== null){
           return  sleepDetailRef.current.refresh()
        }

    }

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            return refreshSleep()
        }
    }))

    const viewFocusOut = () => {
        if(sleepRecordRef.current !== null) {
            sleepRecordRef.current.viewFocusOut()
        }
    }

    return (
        <>
            <div id="SleepView" onClick={viewFocusOut}>
                <SleepDetail ref={sleepDetailRef}/>
                <div className={'gray-bar'}></div>
                <SleepRecord ref={sleepRecordRef}/>
                <div className={'mt-100px'}></div>
            </div>
        </>
    )
})
export default SleepView;