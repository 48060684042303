function isEmpty(str?: string | null): boolean {
    return str === undefined || str === null || str === "";
}

function isNotEmpty(str?: string | null): boolean {
    return !isEmpty(str);
}

function stringify<T>(object: T){
    return JSON.stringify(object, null, 4);
}

function jsonString2ObjectWithMap<T>(jsonString: string): T {
    return JSON.parse(jsonString) as T
}

function addComma (num: number) {
    if (!num) {
        num = 0
    }
    const parts = num.toString().split(".")
    const regexp = /\B(?=(\d{3})+(?!\d))/g
    return parts[0].replace(regexp, ',') + (parts[1] ? "." + parts[1] : "")
}

function convertNullToEmpty(value: string | null | undefined){
    if(value === null || value === undefined){
        return ''
    }
    return value
}


export default {
    isEmpty,
    isNotEmpty,
    stringify,
    jsonString2ObjectWithMap,
    addComma,
    convertNullToEmpty
}