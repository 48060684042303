import React from 'react'
import Icon from "@/view/common/component/Icon";

export interface DailyMissionContentParam{
    iconClass:string,
    missionName:string,
    content:string,
    contentClass:string,
    subContent?:string,
    rightIcon:string
}

const DailyMissionContent = (props: DailyMissionContentParam) => {
    return (
        <div className={'mt-20px'}>
            <div className={'flex justify-between align-center'}>
                <div className={'flex flex-start'}>
                    <Icon iconClass={`${props.iconClass} mr-8px`}/>
                    <div className={'flex-column'}>
                        <p className={'c1_l'}>{props.missionName}</p>
                        <div className={'flex'}>
                            <p className={`${props.contentClass} b1_m`}>{props.content}</p>
                            <p className={'b1_m cl-gray06'}>&nbsp;{props.subContent}</p>
                        </div>
                    </div>
                </div>
                <Icon iconClass={props.rightIcon}/>
            </div>
        </div>
    )
}

export default DailyMissionContent
