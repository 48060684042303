import React from "react";
import {Dialog, Slide, SlideProps} from "@mui/material";
import QuestionView from "@/view/question/QuestionView";
import NoticeView from '@/view/notice/NoticeView';
import FaqView from '@/view/faq/FaqView';
import ProfileView from '@/view/user/ProfileView';
import TermsView from '@/view/terms/TermsView';
import NotificationSettingView from '@/view/notification/NotificationSettingView';
import Icon from '@/view/common/component/Icon'
import UserDvicView from "@/view/userDvic/UserDvicView";
import RothyNativeiSample from "@/view/sample/rothynative/RothyNativeiSample";
import LoginView from "@/view/login/LoginView";
import HealthReportView from "@/view/health/HealthReportView";


type MenuItemParam = {
    title: string,
    link?: string,
    iconClass?: string,
    className?: string,
    onReturn?: () => void
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const MenuItem = (props:MenuItemParam) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <div className={`mrml-24px ptpb-22px flex justify-between ${props.className ? props.className : ''}`} onClick={handleClickOpen}>
                <span>{props.title}</span>
                {props.iconClass &&
                        <Icon iconClass={props.iconClass}/>
                }
            </div>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                {props.link === 'profile' && <ProfileView onClose={handleClose} onReturn={() => {props.onReturn && props.onReturn(); handleClose()}}/>}
                {props.link === 'healthReport' && <HealthReportView onClose={handleClose}/>}
                {props.link === 'userDvic' && <UserDvicView onClose={handleClose}/>}
                {props.link === 'notice' && <NoticeView onClose={handleClose}/>}
                {props.link === 'notificationSetting' && <NotificationSettingView onClose={handleClose}/>}
                {props.link === 'faq' && <FaqView onClose={handleClose}/>}
                {props.link === 'question' && <QuestionView onClose={handleClose}/>}
                {props.link === 'terms' && <TermsView onClose={handleClose}/>}

                {props.link === 'login' && <LoginView />}
                {props.link === 'rothynative' && <RothyNativeiSample/>}
            </Dialog>
        </>
    )
}

export default MenuItem