import React from 'react'
import {Link} from 'react-router-dom'

import Icon from '../common/component/Icon'

type HeaderParam = {
    title ?: string,
    leftIcon ?: string,
    leftLink ?: string,
    rightIcon ?: string,
    rightLink ?: string,
}


const LinkHeader = (props:HeaderParam) => {

    return (
        <>
            <div className='header'>
                {props.leftLink && props.leftIcon &&
                    <>
                        <Link to={props.leftLink}>
                            <div className='left svg'>
                                <Icon iconClass={props.leftIcon}/>
                            </div>
                        </Link>
                        <span className='b1_r'>{props.title}</span>
                    </>}
                {props.rightLink && props.rightIcon &&
                    <Link to={props.rightLink}>
                        <div className='right svg'>
                            <Icon iconClass={props.rightIcon}/>
                        </div>
                    </Link>}
            </div>
        </>
    )
}

export default LinkHeader