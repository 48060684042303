import React from 'react'

export interface QuestionContentData{
    questionTitle: string | undefined,
    questionCn: string | undefined,
    questionDate: string | undefined
}

const QuestionContent = (props: QuestionContentData) =>  {

    return <div className={'w-100p h-min-50p'}>
        <div className={'s_title_1_m'}><p>{props.questionTitle}</p></div>
        <div className={'c1_r cl-gray04'}><p>{props.questionDate}</p></div>
        <div className={'b2_r mt-32px'}><p>{props.questionCn}</p></div>
    </div>
}

export default QuestionContent