import React, {useState} from 'react'
import {useSearchParams} from "react-router-dom";
import LinkHeader from "@/view/layout/LinkHeader"
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import TermsMain from "@/view/signup/component/TermsMain"
import StringUtil from "@/common/StringUtil";


const SignupView: React.FC = (): JSX.Element => {
    const [searchParam] = useSearchParams()
    const [_email] = useState(StringUtil.isNotEmpty(searchParam.get("email"))?searchParam.get("email"):undefined)
    const [_prevType] = useState(StringUtil.isNotEmpty(searchParam.get("prevType"))?searchParam.get("prevType"):undefined)
    const [_uuid] = useState(StringUtil.isNotEmpty(searchParam.get("uuid"))?searchParam.get("uuid"):undefined)

    return(
        <>
            <LinkHeader leftIcon={'prev-w'} leftLink={'/login'}/>
            <HeaderSpacer className='bg-cl-black'/>
            <TermsMain email={_email} prevType={_prevType} uuid={_uuid}/>
        </>
    )

}

export default SignupView;