import React, {forwardRef, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import {Skeleton} from "@mui/material"
import WeightCountChart from './WeightCountChart'
import WeightCountYearChart from './WeightCountYearChart'
import WeightService, {
    WeightRecordReq, WeightCountRes, WeightCountData
} from "@/service/WeightService"
import StringUtil from "@/common/StringUtil"

interface weightCountParam{
    reqParam: WeightRecordReq
}

const WeightCount = forwardRef((props:weightCountParam, ref) => {
    const [countReq,setCountReq] = useState<WeightRecordReq>(new WeightRecordReq(0,'','','','','',''))
    const [countComment,setCountComment] = useState('')
    const [type,setType] = useState('')
    const [calendarHeight, setCalendarHeight] = useState('250px')
    const [data,setData] = useState<WeightCountData[]>()

    const {refetch: getCountData} = useQuery<WeightCountRes, AxiosError>(
        ['getCountData'],
        ()=> WeightService.getWeightCount(countReq),
        {
            onSuccess: (v: WeightCountRes) =>  dataSuccess(v)
        }
    )

    const dataSuccess = (v:WeightCountRes) => {
        setCountComment(v.data.comment)
        setType(v.data.type)
        setData(v.data.weightCountDto)
        if(props.reqParam.periodType === 'YEAR'){
            setCalendarHeight('490px')
        }else if(props.reqParam.periodType === 'MONTH'){
            setCalendarHeight('335px')
        }else {
            setCalendarHeight('400px')
        }
    }

    useEffect(() => {
        if(props){
            const _weightRecordReq = new WeightRecordReq (
                props.reqParam.userId,
                props.reqParam.periodType,
                props.reqParam.fromDate,
                props.reqParam.toDate,
                props.reqParam.month,
                props.reqParam.year,
                ''
            )
            setCountReq(_weightRecordReq)
        }

    },[props])

    useEffect(() => {
        if(countReq && StringUtil.isNotEmpty(countReq.periodType)) {
            getCountData()
                .then(() => {
                    return Promise<void>
                })
                .catch(err => console.log(err))
        }
    },[countReq])

    return (<>
                <div id="WeightCount">
                    <div className={'weight-record-title pt-19px'}>체성분은 <br/>몇 번 확인했지?</div>
                    <div className={'b2_l mt-12px'}>{countComment}</div>
                    <div className={'report mt-40px'} style={{height: calendarHeight, width: '100%'}}>
                        {
                            data ?
                                props.reqParam.periodType === 'YEAR' ?
                                    <WeightCountYearChart {...data}/>
                                    :
                                    <WeightCountChart {...data}/>
                                :
                                <Skeleton height={calendarHeight} animation={'wave'}/>
                        }
                        {
                            props.reqParam.periodType === 'YEAR'  || type === undefined || type === '' ?
                                <></>
                                :
                                <div className={'position-relative w-90p'}>
                                    <div className={'c1_r cl-primary-cyan01 bubble'}>
                                        <div>{type}</div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
    )
})

export default WeightCount