import React, {useCallback, useEffect, useRef, useState} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import ProfileEdit from "@/view/user/component/ProfileEdit";
import {history} from "@/view/common/component/History";
import Confirm from "@/view/common/popup/Confirm";

interface profileProps {
    onClose: () => void,
    onReturn: () => void
}

const ProfileView: React.FC<profileProps> = (props:profileProps) => {
    const handleOverlayClick = useCallback(() => checkIsChanged(), [props.onClose])
    const submitHandler = useCallback(() => {props.onReturn()}, [props.onReturn])
    const [isOpen, setIsOpen] = useState(false)
    const profileEditRef = useRef<{isChanged: () => void}>(null)

    useEffect(() => {
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === 'POP') {
                checkIsChanged()
            }
        });

        return unlistenHistoryEvent;

    }, [history]);

    const checkIsChanged = () => {
        if(profileEditRef.current?.isChanged()){
            setIsOpen(true)
        }else{
            props.onClose()
        }
    }

    return (
        <>
            <DialogHeader title='프로필 편집' onClose={handleOverlayClick}/>
            <HeaderSpacer/>
            <ProfileEdit onSubmit={submitHandler} ref={profileEditRef}/>
            <Confirm content={<span>작성 중인 내용이 사라집니다<br/>그래도 나가시겠습니까?</span>} title={undefined} leftBtnContent={'아니오'} rightBtnContent={'네'} isOpen={isOpen} onClick={ () => setIsOpen(false)} onClose={ () => props.onClose()}/>
        </>
    )
}

export default ProfileView;