import {SleepTimeData} from "@/service/SleepService";

export interface ReportTypeItem {
    name: string,
    value: string,
}

export interface ReportPropsConfig {
    fromDate: string;
    month: string;
    periodType: string;
    toDate: string;
    userId: number;
    year: string;
    title?: string;
}

export interface ChartDataSet {
    type: any,
    label: string,
    backgroundColor?: string,
    borderWidth?: number,
    fill?: boolean,
    data: number[] | object[] | undefined[] | undefined,
    order: number,
    hidden?: boolean,
    barPercentage?:number,
    borderRadius?:number,
    radius?:number,
    borderSkipped?:boolean,
    pointStyle?:string
}

interface LabelDataSet {
    YOIL: string,
    ROW_NUM: number,
}

export const getLabelData = (labelDataSet:LabelDataSet, periodType:string) => {
    let result = ''
    if(periodType === 'WEEK'){
        result = labelDataSet.YOIL
    }else if(periodType === 'MONTH'){
        if([7,14,21,28].includes(labelDataSet.ROW_NUM)){
            result = String(labelDataSet.ROW_NUM)+'일'
        }
    }else if(periodType === 'YEAR'){
        result = String(labelDataSet.ROW_NUM)
    }
    return result
}

export const getLabelDataByReportType = (sleepTimeData:SleepTimeData, periodType:string, selectedReportTypeItem:ReportTypeItem | undefined) => {
    let result = ''
    if(periodType === 'WEEK'){
        result = sleepTimeData.YOIL
    }else if(periodType === 'MONTH'){
        if(selectedReportTypeItem?.value === 'YOIL'){
            result = sleepTimeData.YOIL
        }else{
            if([7,14,21,28].includes(sleepTimeData.ROW_NUM)){
                result = String(sleepTimeData.ROW_NUM)+'일'
            }
        }
    }else if(periodType === 'YEAR'){
        result = String(sleepTimeData.ROW_NUM)
    }

    return result
}
