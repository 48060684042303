import React, {useCallback} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";

export interface TermsDetailParam {
    contents?:string,
    onClose: () => void
}

const TermsDetail: React.FC<TermsDetailParam> = (props:TermsDetailParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    return (
        <>
            <DialogHeader onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <div className='board-detail'>
                    <span dangerouslySetInnerHTML={{__html: props.contents?props.contents:''}}/>
                </div>
            </div>
        </>
    )
}

export default TermsDetail;