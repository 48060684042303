import {OS_TYPE} from "@/common/Constant";

function callInterfaceGetCustomerId(): number {
    return 8868
}

function isAppRunning(): boolean {
    if (window.navigator.userAgent.indexOf("Rothy/") > -1) {
        return true
    } else {
        return false
    }
}

function getOs(): string {
    let osStr = OS_TYPE.WEB
    if (window.navigator.userAgent.indexOf(OS_TYPE.ANDROID) > -1) {
        osStr = OS_TYPE.ANDROID
    } else if(window.navigator.userAgent.indexOf(OS_TYPE.IOS) > -1) {
        osStr = OS_TYPE.IOS
    }

    return osStr
}


export default {
    callInterfaceGetCustomerId,
    isAppRunning,
    getOs
}
