import React, {useCallback} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import Notice from '@/view/notice/component/Notice';

interface noticeProps {
    onClose: () => void
}

const NoticeView: React.FC<noticeProps> = (props:noticeProps) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    return (
        <>
            <DialogHeader title='공지사항' onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <Notice/>
            </div>
        </>
    )
}

export default NoticeView;