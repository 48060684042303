import React, {useEffect, useState} from 'react'
import {createSearchParams, Link, useNavigate} from 'react-router-dom'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Icon from "@/view/common/component/Icon"
import RothyNativeService, {RNIActions, RNIError, RNIMessageConfig} from "@/service/rothynative/RothyNativeService"
import * as GoogleSignIn from "@/service/rothynative/RNIGoogleSignIn"
import {ACCESS_TOKEN, LOGIN, MESSAGE, MNBR_NTRY_SE_CD, REFRESH_TOKEN, FOCUS_ITEM, USER_ID, USER_INFO, LINK_DVIC} from "@/common/Constant"
import UserService, {SvcUser, SvcUserDataRes} from "@/service/UserService"
import StorageUtil from "@/common/StorageUtil"
import * as RNIKakaoSignIn from "@/service/rothynative/RNIKakaoSignIn"
import * as RNINaverSignIn from "@/service/rothynative/RNINaverSignIn"
import * as RNIAppleSignIn from "@/service/rothynative/RNIAppleSignIn"
import Alert from "@/view/common/popup/Alert"
import StringUtil from "@/common/StringUtil";
import {DefaultResult} from "@/service/RothyClient";
import InterfaceUtil from "@/common/Interface";

const SnsLogin = () => {
    const navigate = useNavigate()
    const [init, setInit] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [googleIdToken, setGoogleIdToken] = useState<string | undefined>(undefined)

    const [uId, setUid] = useState<string | undefined>(undefined)
    const [type, setType] = useState<string | undefined>(undefined)
    const [email, setEmail] = useState<string | undefined>(undefined)
    const [token, setToken] = useState<string | undefined>(undefined) // 회원가입에 사용하는 token

    const [initType] = useState(StorageUtil.getLocalStorage(MNBR_NTRY_SE_CD))

    const onclick = () => {
        navigate('/emailLogin')
    }

    useEffect(() => {
        if (googleIdToken) {
            googleSignInWithCredential(googleIdToken)

        }
    }, [googleIdToken])

    useEffect(() => {
        logoutAll()
    }, [setInit])

    useEffect(() => {
        if (uId && type) {
            signIn(uId, type).then(_r => {
                return Promise<void>
            }).catch(_reason => {
                alertError()
             })
        }
    }, [uId, type])

    const signIn = async (uuId:string, mnbrNtrySeCd:string) => {

        const data:DefaultResult = await UserService.signIn(uuId, mnbrNtrySeCd)

        if(data.data === undefined){ //로그인 실패
            if(data.code === 'L-10210'){ // 해당 사용자 정보가 없습니다.
                loginFail().then(_r => {
                    return Promise<void>
                }).catch(_reason => {
                    alertError()
                })
            }else if(data.code === 'L-10240'){ //삭제된 계정
                setIsOpen2(true)
            }else{
                alertError()
            }
        }else{
            loginSuccess(data)
        }
    }

    const loginSuccess = (data: SvcUser) => {
        StorageUtil.setLocalStorage(MNBR_NTRY_SE_CD, type?type:'')
        StorageUtil.setLocalStorage(ACCESS_TOKEN, data.data.token)
        StorageUtil.setLocalStorage(REFRESH_TOKEN, data.data.refreshToken)
        StorageUtil.setLocalStorage(USER_ID, data.data.userId.toString())
        StorageUtil.setLocalStorage(FOCUS_ITEM, StringUtil.stringify(data.data.focusItem))
        StorageUtil.setLocalStorage(LINK_DVIC, StringUtil.stringify(data.data.linkDvic))
        StorageUtil.setLocalStorage(USER_INFO,StringUtil.stringify(data.data))
        navigate('/main')
    }

    const loginFail = async() => {
        if(StringUtil.isNotEmpty(email)){
            await userSignUpCheck()
        }else{
            const _type = type?type:''
            const _token = token?token:''
            const _data = {uuid: _token, prevType:_type}
            navigate({pathname: '/signup', search: createSearchParams(_data).toString()})
        }
    }

    const {refetch: userSignUpCheck} = useQuery<any, AxiosError>(
        ['userSignUpCheck'],
        ()=> UserService.userSignUpCheck(email?email:''),
        {
            onSuccess: (data: SvcUserDataRes) => {
                checkSuccess(data)
            }
        }
    )

    const checkSuccess = (data: SvcUserDataRes) => {
        if(data.data.mnbrNtrySeCd === undefined){
            const _email = email?email:''
            const _token = token?token:''
            const _type = type?type:''
            const _data = {email: _email, uuid: _token, prevType:_type}
            navigate({pathname: '/signup', search: createSearchParams(_data).toString()})
        }else if(data.data.mnbrNtrySeCd === LOGIN.TYPE.EMAIL){
            navigate({pathname: '/emailSignUpEmail', search: createSearchParams({email: data.data.userEmail}).toString()})
        }else {
            navigate({pathname: '/snsSignUpEmail', search: createSearchParams({email: data.data.userEmail, type: data.data.mnbrNtrySeCd, prevType:'snsLogin'}).toString()})
        }
    }

    const alertError = () => {
        setIsOpen(true)
        initLoginData()
    }

    const initLoginData = () => {
        setUid(undefined)
        setGoogleIdToken(undefined)
        setType(undefined)
        setEmail(undefined)
        setToken(undefined)
    }

    const onClickGoogleSignIn = () => {
        const config = new RNIMessageConfig<void, GoogleSignIn.User>()
        config.action = RNIActions.GOOGLE_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: GoogleSignIn.User) => {
                if (response?.idToken) {
                    setEmail(response.user.email)
                    setType(LOGIN.TYPE.GOOGLE)
                    setGoogleIdToken(response?.idToken)
                } else {
                    initLoginData()
                }
            })
            .catch((error: RNIError) => {
                if (error.code == GoogleSignIn.RNI_GSI_STATUS_CODES.SIGN_IN_CANCELLED) {
                    initLoginData()
                } else {
                    initLoginData()
                    alertError()
                }
            })
    }

    const googleSignInWithCredential = (googleIdTokenP: string) => {
        if (!googleIdTokenP) {
            alert("idToken is null")
            return
        }

        const config = new RNIMessageConfig<string, GoogleSignIn.UserCredential>()
        config.action = RNIActions.GOOGLE_SIGNIN_WITH_CREDENTIAL
        config.data = googleIdToken

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response) => {
                const uid = response?.user.uid
                if (uid) {
                    setUid(uid)
                    setToken(uid)
                }else{
                    initLoginData()
                }
            })
            .catch((_error: RNIError) => {
                initLoginData()
                alertError()
            })
    }

    const onClickKakaoSignIn = () => {
        const config = new RNIMessageConfig<void, RNIKakaoSignIn.RNKakaoToken>()
        config.action = RNIActions.KAKAO_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIKakaoSignIn.RNKakaoToken) => {
                const uid = response?.accessToken
                if (uid) {
                    setType(LOGIN.TYPE.KAKAO)
                    setToken(uid)
                    onClickKakaoProfile()
                }else{
                    initLoginData()
                }
            })
            .catch((_error: RNIError) => {
                initLoginData()
                console.log(_error)
            })
    }
    const onClickKakaoProfile = () => {
        const config = new RNIMessageConfig<void, RNIKakaoSignIn.RNKakaoProfile>()
        config.action = RNIActions.KAKAO_PROFILE

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIKakaoSignIn.RNKakaoProfile) => {
                setEmail(response.email)
                setUid(response.id)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickNaverSignIn = () => {
        const config = new RNIMessageConfig<void, RNINaverSignIn.RNNaverLoginResponse>()
        config.action = RNIActions.NAVER_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNINaverSignIn.RNNaverLoginResponse) => {
                if (response?.successResponse?.accessToken) {
                    setType(LOGIN.TYPE.NAVER)
                    setToken(response?.successResponse?.accessToken)
                    getNaverProfile(response?.successResponse?.accessToken)
                } else {
                    initLoginData()
                }

            })
            .catch((_error: RNIError) => {
                initLoginData()
                alertError()
            })
    }
    const getNaverProfile = (accessToken: string) => {
        const config = new RNIMessageConfig<string, RNINaverSignIn.RNNaverProfileResponse>()
        config.action = RNIActions.NAVER_PROFILE
        config.data = accessToken

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((profileResponse: RNINaverSignIn.RNNaverProfileResponse) => {
                setEmail(profileResponse.response.email)
                setUid(profileResponse.response.id)
            })
            .catch((error: RNIError) => {
                initLoginData()
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickAppleSignIn = () => {
        const config = new RNIMessageConfig<void, RNIAppleSignIn.AppleRequestResponse>()
        config.action = RNIActions.APPLE_SIGN_IN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIAppleSignIn.AppleRequestResponse) => {
                if (response?.identityToken) {
                    setType(LOGIN.TYPE.APPLE)
                    setUid(response?.identityToken)
                    setToken(response?.identityToken)
                } else {
                    initLoginData()
                }
            })
            .catch((error: RNIError) => {
                initLoginData()
                console.log(error)
            })
    }

    const logoutAll = () => {
        if(init){
            setInit(false)
            onClickGoogleSignOut()
            onClickNaverSignOut()
            onClickKakaoSignOut()
        }
    }

    const onClickGoogleSignOut = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.GOOGLE_SIGN_OUT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                initLoginData()
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickNaverSignOut = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.NAVER_SIGN_OUT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                initLoginData()
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickKakaoSignOut = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.KAKAO_SIGN_OUT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                initLoginData()
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    return (
        <>
            <div className={'login-wrapper prpl-24px pb-24px h-312px'}>
                <div className={'sns-wrapper'}>
                    <div className={'b2_m'}>{LOGIN.TITLE.SNS}</div>
                    <div className={'sns-icons flex'}>
                        <div className={'sns-icon flex-column sns-icon-left h-48px w-48px'} onClick={() => (onClickKakaoSignIn())}>
                            <Icon iconClass={'login-kakao h-48px w-48px'} />
                            {initType === 'K' &&
                                <>
                                    <div className={'pt-7px'}></div>
                                    <Icon iconClass={'login-selected-sns-left w-158px h-42px'} />
                                </>
                            }
                        </div>
                        <div className={'sns-icon flex-column sns-icon-center h-48px w-48px'} onClick={() => (onClickNaverSignIn())}>
                            <Icon iconClass={'login-naver h-48px w-48px'}/>
                            {initType === 'N' &&
                                <>
                                    <div className={'pt-7px'}></div>
                                    <Icon iconClass={'login-selected-sns-center w-158px h-42px'} />
                                </>
                            }
                        </div>
                        <div className={'sns-icon flex-column sns-icon-center h-48px w-48px'} onClick={() => (onClickGoogleSignIn())}>
                            <Icon iconClass={'login-google h-48px w-48px'}/>
                            {initType === 'G' &&
                                <>
                                    <div className={'pt-7px'}></div>
                                    <Icon iconClass={'login-selected-sns-center w-158px h-42px'}/>
                                </>
                            }
                        </div>
                        {
                            InterfaceUtil.getOs() === 'ios' &&
                            <div className={'sns-icon flex-column sns-icon-right h-48px w-48px'} onClick={() => (onClickAppleSignIn())}>
                                <Icon iconClass={'login-apple h-48px w-48px'}/>
                                {initType === 'A' &&
                                    <>
                                        <div className={'pt-7px'}></div>
                                        <Icon iconClass={'login-selected-sns-right w-158px h-42px'}/>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className={'email-wrapper'}>
                    <div className={'b2_m'}>{LOGIN.TITLE.EMAIL}</div>
                    {initType === 'E' &&
                        <>
                            <Icon iconClass={'login-selected-email w-158px h-42px'} />
                        </>
                    }
                    <button id='nextBtn' className={'b2_m outlined w-100p nextBtn mt-16px'} onClick={() => onclick()}>{LOGIN.EMAIL_LOGIN}</button>
                </div>
                <div className={'signup-wrapper text-center'}>
                    <span className={'c1_r signup-item ft-cl-caption'}>{LOGIN.SIGN_UP.MESSAGE1}</span>
                    <span className={'c1_r signup-item text-underline'}><Link to={"/signup"}>{LOGIN.SIGN_UP.EMAIL}</Link></span>
                </div>
            </div>
            <Alert content={<div className={'text-center b2_r'}><div>{MESSAGE.ERROR1}</div><div>{MESSAGE.ERROR2}</div><div>{MESSAGE.ERROR3}</div></div>} title={undefined} btnContent={'확인'} isOpen={isOpen} onClose={ () => setIsOpen(false)}/>
            <Alert content={<div className={'text-center b2_r'}><div>사용할 수 없는 계정입니다.</div><div>{MESSAGE.ERROR2}</div><div>{MESSAGE.ERROR3}</div></div>} title={undefined} btnContent={'확인'} isOpen={isOpen2} onClose={ () => setIsOpen2(false)}/>
        </>
    )
}

export default SnsLogin
