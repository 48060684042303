import React, {useCallback} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import NotificationList from "@/view/notification/component/NotificationList";

interface notificationListProps {
    onClose: () => void
}

const NotificationListView: React.FC<notificationListProps> = (props:notificationListProps) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    return (
        <>
            <DialogHeader title='알림함' onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <NotificationList/>
            </div>
        </>
    )
}

export default NotificationListView;