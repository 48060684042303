import React, {useCallback} from 'react'
import {Dialog, Slide, SlideProps} from "@mui/material";
import NoticeDetailView from '@/view/notice/NoticeDetailView';
import Icon from "@/view/common/component/Icon";
import QuestionAdd from "@/view/question/component/QuestionAdd";
import QuestionDetailView from "@/view/question/QuestionDetailView";
import {QuestionData} from "@/service/BoardService";
import TermsDetail from '@/view/terms/component/TermsDetail';
import TermsAgree from '@/view/terms/component/TermsAgree';

export interface BoardItemParam{
    type:string,
    index:number,
    title:string,
    contents?:string,
    subTitle?:string,
    heightClass:string,
    leftIcon?:string,
    rightContent?:JSX.Element,
    onReturn?:() => void,
    detailProp?: QuestionData | undefined //상세페이지에서 필요한 타입이 있을경우 추가
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props}/>;
});

const BoardListItem = (props: BoardItemParam) => {
    const [open, setOpen] = React.useState(false)
    const handleReturnClick = useCallback(() => props.onReturn !== undefined? props.onReturn(): {},  [props.onReturn])

    const handleClose = () => {
        setOpen(false);
    };

    const onClickEvent = () => {
        props.contents && setOpen(true)
    }

    return <div className={`flex ${props.index == 0 ? '' : 'border-top-gray'} ${props.heightClass}`}>
        {props.leftIcon
            ?<div className={'mr-8px pt-22px'}><Icon iconClass={props.leftIcon}/></div>
            : null
        }
        <div className={`board-item w-100p`}
             onClick={onClickEvent}>
            <div className={'board-item-title'}>
                <span className='b2_r'>{props.title}</span>
                <div className={`${props.rightContent === undefined ? '' : 'right-contents'}`}>
                    {props.rightContent}
                </div>
            </div>
            {props.subTitle ? <p className={'c1_r cl-gray04'}>{props.subTitle}</p> : ''}
        </div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            {props.type === 'notice' &&
                <NoticeDetailView title={props.title} subTitle={props.subTitle} contents={props.contents} onClose={handleClose}/>}
            {props.type === 'questionAdd' &&
                <QuestionAdd onClose={handleClose} onReturn={handleReturnClick} questionType={props.contents? props.contents: ''}/>}
            {props.type === 'questionDetail' &&
                <QuestionDetailView onClose={handleClose} question={props.detailProp}/>}
            {props.type === 'terms' &&
                <TermsDetail contents={props.contents} onClose={handleClose}/>}
            {props.type === 'termsAgree' &&
                <TermsAgree title={props.title} contents={props.contents} onClose={handleClose}/>}
        </Dialog>
    </div>
}

export default BoardListItem;