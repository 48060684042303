import React, {useEffect, useState} from 'react'
import moment from "moment";
import Icon from "@/view/common/component/Icon";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import {USER_INFO, DELETE_USER} from "@/common/Constant";

interface UserInfo {
    signupDt: string
}

const DeleteUserContent = () => {
    const [message, setMessage] = useState('');
    const nowDate = moment()
    const userInfo: UserInfo  = StringUtil.jsonString2ObjectWithMap<UserInfo>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
    const userSignupDt = userInfo?.signupDt

    const signupDate = moment(userSignupDt, "YYYY-MM-DD HH:mm:ss");

    const getMessage = () => {
        const diffDays = nowDate.diff(signupDate, 'days')
        if(Number(diffDays)>0) {
            setMessage(diffDays.toString()+DELETE_USER.CONTS_LINE2_1)
        }else{
            setMessage(DELETE_USER.CONTS_LINE2_2)
        }
    }

    useEffect(() => {
        getMessage()
    }, [message])

    return (
        <>
            <div className='content-main'>
                <div className='b1_l'>{DELETE_USER.CONTS_LINE1}</div>
                <div className='h4_r'>{message}</div>
                <div className='h4_r'>{DELETE_USER.CONTS_LINE3}</div>
                <Icon iconClass='delete-user w-32px h-26px mt-50px' />
            </div>
        </>
    )
}

export default DeleteUserContent