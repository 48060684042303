import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Skeleton} from "@mui/material";
import Flicking from "@egjs/react-flicking";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import moment from "moment/moment";
import StorageUtil from "@/common/StorageUtil";
import {USER_ID, USER_INFO} from "@/common/Constant";
import SleepService, {SleepCardData, SleepCardRes} from "@/service/SleepService";
import SleepCard from "@/view/sleep/component/SleepCard";
import {SvcUserData} from "@/service/UserService";
import StringUtil from "@/common/StringUtil";


const SleepDetail = forwardRef((props, ref) => {
    const userId = Number(StorageUtil.getLocalStorage(USER_ID)) as unknown as number
    const userInfo: SvcUserData  = StringUtil.jsonString2ObjectWithMap<SvcUserData>(StorageUtil.getLocalStorage(USER_INFO) as unknown as "")
    const userSignupDt = userInfo?.signupDt
    const signupDate = moment(userSignupDt).format("YY.MM.DD");

    const [sleepCardDataList, setSleepCardDataList] = useState<SleepCardData[]>([])

    const ref2 = useRef<Flicking>(null)

    const _fromDate = moment().subtract(6, "days").format('YYYY-MM-DD')
    const _toDate = moment().format('YYYY-MM-DD')

    const {refetch: getCardData} = useQuery<SleepCardRes, AxiosError>(
        ['getSleepCardData'],
        ()=> SleepService.getUserSleepData({fromDate:_fromDate, toDate:_toDate , userId: userId}),
        {
            onSuccess: (v: SleepCardRes) => onSuccess(v)
        }
    )

    const onSuccess = (v: SleepCardRes) => {
        setSleepCardDataList(v.data)
    }

    useEffect(() => {
        ref2.current?.forceUpdate()
    }, [sleepCardDataList])

    useEffect(() => {
        initData().then(r=>{console.log(r)}).catch(e=>{console.log(e)})
    }, [])

    const initData = async () => {
        return getCardData()
            .then(r => r.data)
            .catch(reason => {console.log(reason)})
    }

    useImperativeHandle(ref, () => ({
        refresh: () => {
            return initData()
        }
    }))

    return (

               <div className='sleep-content mrml-24px pt-55px pb-60px'>
                   <h4 className='h4_l'>SLEEP CARD</h4>
                    <div className='walk-card w-100p' >
                        <Flicking bounce={1}
                        circular={false}
                        cameraClass={'sleep-main-content'}
                        hanger={'0'}
                        align={'prev'}
                        moveType={['strict', { count: 1 }]}
                        changeOnHold={false}
                        bound={false}
                        ref={ref2}
                        >
                        {
                        sleepCardDataList.length > 0 ?
                        sleepCardDataList.map((data, index) => {
                                return <div key={index} >
                                    <SleepCard msreBeginDtm={data.msreBeginDtm}
                                               msreDt={data.msreDt}
                                               msreEndDtm={data.msreEndDtm}
                                               scesYn= {data.scesYn}
                                               sleepMaxTimeByAge={data.sleepMaxTimeByAge}
                                               sleepMinTimeByAge={data.sleepMinTimeByAge}
                                               sleepOxygen={data.sleepOxygen}
                                               sleepOxygenLowDuration={data.sleepOxygenLowDuration}
                                               sleepPatternDiff={data.sleepPatternDiff}
                                               sleepPatternScoreOrMsg={data.sleepPatternScoreOrMsg}
                                               sleepTime={data.sleepTime}
                                               sleepTimeMsg={data.sleepTimeMsg}
                                               signupDate={signupDate}
                         />
                    </div>
                        })
                    :
                    <>
                        <div key={1000} >
                            <div className={'mt-14px mr-10px'}>
                                <Skeleton variant="rounded" width={284} height={404} />
                            </div>
                        </div>

                        <div key={1001} >
                            <div className={'mt-14px'}>
                                <Skeleton variant="rounded" width={284} height={404} />
                            </div>
                        </div>
                    </>
                    }
                    </Flicking>
                </div>
               </div>

    )
})

export default SleepDetail