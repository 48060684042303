import React, {useEffect, useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import UserService, {SvcUser, SvcUserData} from "@/service/UserService"
import StringUtil from "@/common/StringUtil"
import StorageUtil from "@/common/StorageUtil"
import {EVENT_TYPE, USER_INFO} from '@/common/Constant'
import Popup from "@/view/common/popup/Popup"
import Bubble from "@/view/common/component/Bubble"
import Confirm from "@/view/common/popup/Confirm"
import Toast from "@/view/common/popup/Toast";
import Alert from "@/view/common/popup/Alert"
import PutStepService from "@/service/PutStepService";
import UserMissionService from "@/service/UserMissionService";
import PutStepHistoryService from "@/service/PutStepHistoryService";

const Sample: React.FC = (): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [isOpen3, setIsOpen3] = useState(false)
    const [isOpen4, setIsOpen4] = useState(false)
    const [isOpen5, setIsOpen5] = useState(false)
    const [userInfo, setUserInfo] = useState<SvcUserData>()


    const {refetch: useQueryTest} = useQuery<SvcUser, AxiosError>(
            ['getUser1'],
            ()=> UserService.getUser(),
        {
            onSuccess: (v: SvcUser) => updateUser(v.data)
        }
    )

    let flag = false
    const getUserInfo = async () => {
        if(flag) return
        flag = true
        const user = await useQueryTest()
        console.log(user)
        flag = false
    }

    const getUserInfo2 = () => {
        const userInfo = StorageUtil.getLocalStorage(USER_INFO)
        const user:SvcUserData = StringUtil.jsonString2ObjectWithMap<SvcUserData>(userInfo? userInfo : '')
        console.log(user)
        console.log('=================')
        console.log(typeof userInfo)
    }

    const onClick = () => {
        alert('click')
    }

    const noAsyncTest = () => {
        const user = UserService.getUser()
        console.log(user)
    }

    const asyncTest = async () => {
        const user = await UserService.getUser()
        console.log(user)
    }

    const thenTest = async () => {
        await UserService.getUser().then((resolve) => {
            console.log(resolve)
        }).catch(()=>{
            alert(1)
        })
    }

    const asyncTest2 = async () => {
        return PutStepService.putStep()
                        .then((resolve) => resolve)
                        .catch(reason => {console.log(reason)})

    }

    const getStepHistory = async () => {
        return PutStepHistoryService.putStepHistory()
                                .then((resolve) => resolve)
                                .catch(reason => {console.log(reason)})

    }


    const {refetch: getUserMissionData} = useQuery<boolean, AxiosError>(
        ['shuldRequestCheckMission'],
        ()=> UserMissionService.shuldRequestCheckMission(),
        {
            onSuccess: (v: boolean) => {console.log("######");console.log(v)}
        }
    )

    const kimTest2 = () => {
        console.log("check mission1")
        const event : Event = new Event(EVENT_TYPE.STEP_DAY)
        UserMissionService.checkMission(event)
        console.log("check mission2")
    }

    const updateUser = (user : SvcUserData) => {
        if(user === undefined) return
        setUserInfo({
            userNm: user.userNm,
            nickNm: user.nickNm,
            birthday: "",
            displayList: "",
            gender: "",
            mnbrNtrySeCd: "",
            refreshToken: "",
            tall: "",
            token: "",
            userEmail: "",
            userId: 0,
            userMobile: "",
            weight: "",
            signupDt: "",
            focusItem: {
                focusCd: "",
                focusNm: "",
                focusUnit: ""
            },
            linkDvic:""
        })
    }

    const putComplete = () => {
        console.debug("put putComplete")
    }

    const popupUser = () => {
        const options = 'top=10, left=10, width=400, height=800, status=no, menubar=no, toolbar=no, resizable=no';
        const data1 = '{\n' +
            '    "userId": 317,\n' +
            '    "nickNm": "hell2",\n' +
            '    "signupDt": "2021-12-20 16:24:37",\n' +
            '    "refreshToken": "eyJ0eXBlIjoiSldUIiwicmVnRGF0ZSI6MTY3NDYzNDI0NzY4NCwiYWxnIjoiSFMyNTYifQ.eyJleHAiOjE2NzcyNjQwNDcsInVzZXJJZCI6MzE3LCJwanRObyI6MSwiYXV0aG9yaXR5Tm0iOiJVU0VSIn0.cKdum5YmtpBaj0VF2Rd2orQnlv8MS804WHBNSjYEJrc",\n' +
            '    "token": "eyJ0eXBlIjoiSldUIiwicmVnRGF0ZSI6MTY3NDYwNjQyOTIwMiwiYWxnIjoiSFMyNTYifQ.eyJleHAiOjE2NzUyMTEyMjksInBqdE5vIjoxLCJ1c2VySWQiOjMxNywiYXV0aG9yaXR5Tm0iOiJVU0VSIiwiY29tcGFueUNvZGUiOiJHSVZJVEEifQ.PK-LrfNVstsIvL3on9xQZeHiy017-hN1SvXLwpWxil4"\n' +
            '}'
        //window.open('https://test-m.gi-vita.io?type=popupUser&userInfoData='+data1, 'popupUser', options);
        window.open('http://localhost:9080?type=popupUser&userInfoData='+data1, 'popupUser', options);
    }

    useEffect(() => {
        console.log(userInfo)
        window.addEventListener(EVENT_TYPE.STEP_DAY,putComplete)
        return  () =>{
            window.removeEventListener(EVENT_TYPE.STEP_DAY,putComplete)
        }
    }, [userInfo])

    return (
        <>
            <p className={'bg-orange-200'} onClick={() => popupUser()}>사용자_팝업</p>
            <br/>
            <h5 className='font-bold'>- loacalstorage 사용자 조회 </h5>
            <p className={'bg-orange-200'} onClick={() => getUserInfo2()}>사용자조회(loacalstorage)</p>
            <br/>
            <h5 className='font-bold'>- db 요청 중복 방지(등록, 수정, 삭제) </h5>
            <p className={'bg-stone-400'} onClick={() => void getUserInfo()}>사용자조회(db-중복방지)</p>
            <br/>
            <h5 className='font-bold'>- toast popup </h5>
            <p className={"bg-blue-100"} onClick={() => setIsOpen2(true)}>toast-alert-test</p>
            <br/>
            <h5 className='font-bold'>- confirm popup </h5>
            <p className={"bg-blue-300"} onClick={() => setIsOpen3(true)}>mui-confirm</p>
            <br/>
            <h5 className='font-bold'>- confirm popup2- notitle </h5>
            <p className={"bg-blue-300"} onClick={() => setIsOpen4(true)}>mui-confirm(notitle)</p>
            <br/>
            <h5 className='font-bold'>- alert popup2 </h5>
            <p className={"bg-blue-300"} onClick={() => setIsOpen5(true)}>mui-alert</p>
            <br/>

            {/*popupSample*/}
            <div style={{marginTop:'30px'}}>
                <h5 className='font-bold'>- popupSample</h5>
                <button className='bg-primary-btn' onClick={ () => setIsOpen(true) }>Popup test</button>
            </div>
            <Popup className='bg-cl-white' content={<div style={{padding:'100px 20px'}}>Test중입니당</div>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
            <br/>
            {/*bubbleSample*/}
            <div style={{marginTop:'20px'}}>
                <h5 className='font-bold'>- BubbleSample</h5>
                <Bubble message='hihihihihihihihi'/>
            </div>

            {/*buttonSample*/}
            <div style={{marginTop:'30px'}}>
                <h3>7.ButtonSample</h3>
                <div className='theme-light'>
                    <h4>For theme-light</h4>
                    <button className='primary'>primary</button>
                    <button className='primary' disabled>primary-disabled</button>
                    <button className='black'>black</button>
                    <button className='black' disabled>black-disableda</button>
                    <button className='outlined'>outlined</button>
                    <button className='outlined' disabled>outlined-disabled</button>
                </div>
                <div className='theme-dark'>
                    <h4>For theme-dark</h4>
                    <button className='primary'>primary</button>
                    <button className='primary' disabled>primary-disabled</button>
                    <button className='black'>black</button>
                    <button className='black' disabled>black-disableda</button>
                    <button className='outlined'>outlined</button>
                    <button className='outlined' disabled>outlined-disabled</button>
                </div>
            </div>

            <Toast content={<div>로그아웃 되었습니다.</div>} isOpen={isOpen2} onClose={ () => setIsOpen2(false) }></Toast>
            <Confirm content={<span>로그아웃 하시겠습니까?</span>} title={<h5>title</h5>} leftBtnContent={'로그아웃'} rightBtnContent={'취소'} isOpen={isOpen3} onClick={ () => onClick()} onClose={ () => setIsOpen3(false)}/>
            <Confirm content={<span>로그아웃 하시겠습니까?</span>} title={undefined} leftBtnContent={'로그아웃'} rightBtnContent={'취소'} isOpen={isOpen4} onClick={ () => onClick()} onClose={ () => setIsOpen4(false)}/>
            <Alert content={<span>개인정보에 동의 하셨습니다.</span>} title={undefined} btnContent={'확인'} isOpen={isOpen5} onClose={ () => setIsOpen5(false)}/>
            <div style={{marginTop:'30px'}}>
                <h5 className='font-bold'>- 비동기 패턴 처리</h5>
                <button className='bg-primary-btn' onClick={ () => noAsyncTest() }>noAsync</button>
                <br/>
                <button className='bg-primary-btn' onClick={ () => void asyncTest() }>async</button>
                <br/>
                <button className='bg-primary-btn' onClick={ () => void thenTest() }>then</button>
                <br/>
                <button className='bg-primary-btn' onClick={ () => void useQueryTest() }>useQuery</button>
            </div>

            <br/>
            <h5 className='font-bold'> 비동기 테스트 </h5><br/><br/>
            <p className={'bg-orange-200'} onClick={() => void getStepHistory() }>put daily history step</p><br/><br/>
            <p className={'bg-orange-200'} onClick={() => void asyncTest2() }>put daily step</p><br/><br/>
            <p className={'bg-orange-200'} onClick={() => void kimTest2() }>check mission</p><br/><br/>
            <br/><br/>
            <br/>
        </>
    )
}
export default Sample;