import React, {useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {createSearchParams, useNavigate} from 'react-router-dom'
import {LOGIN_TYPE, CONFIRM} from "@/common/Constant"
import Confirm from "@/view/common/popup/Confirm"
import UserService, {SvcUser} from "@/service/UserService";

type LogoutMsg = {
    type : string
}

const Logout = () => {
    const navigate = useNavigate()
    const data: LogoutMsg = { type: LOGIN_TYPE.LOGOUT}
    const [isOpen, setIsOpen] = useState(false)

    const {refetch: executeLogout} = useQuery<SvcUser, AxiosError>(
        ['logout'],
        ()=> UserService.logout(),
        {
            onSuccess: () => logout(),
            onError: () => logout()
        }
    )

    const handleClick = async () => {
        await executeLogout();
    }

    const logout = () => {
        navigate({pathname: '/login', search: createSearchParams(data).toString()})
    }

    return (
        <>
            <div className='mrml-24px ptpb-22px border-top-gray text-center b2_l' onClick={() => setIsOpen(true)}>
                로그아웃
            </div>
            <Confirm content={<span>{CONFIRM.LOGOUT.CONTENT}</span>} title={undefined} leftBtnContent={CONFIRM.LOGOUT.LBTN} rightBtnContent={CONFIRM.LOGOUT.RBTN} isOpen={isOpen} onClick={ () => handleClick()} onClose={ () => setIsOpen(false)}/>
        </>
    )
};

export default Logout;