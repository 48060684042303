import {DefaultResult, loginClient, rothyClient} from "@/service/RothyClient";
import {Response} from "@/service/BoardService";

export interface SvcUser {
    result: boolean,
    data: SvcUserData,
    message: string
}

export type SvcUserDataRes = {
    code: string,
    data: SvcUserData,
    errors: string[],
    message: string,
    result: boolean
}

export interface SvcUserData {
    userId: number,
    userNm: string,
    nickNm: string,
    displayList: string,
    token: string,
    refreshToken: string,
    userMobile: string,
    userEmail: string,
    gender: string,
    birthday: string,
    mnbrNtrySeCd: string,
    tall: string,
    weight: string,
    signupDt: string,
    focusItem: FocusItem
    linkDvic: string,
}

export interface FocusItem {
    focusCd: string,
    focusNm: string,
    focusUnit: string
}

export class SvcUserSignUp {
    pjtNo: number;
    nickNm: string;
    userEmail: string;
    userNm: string;
    athnNo: number;
    mnbrNtrySeCd: string;
    uuid: string;
    userMobile: string;
    birthday: string;
    gender: string;
    tall: number;
    weight: number;
    os: string;
    registrationId: string;
    appVerNo: string;
    pushId: string;
    profileFile: number;
    termsAgreements: TermsAggreMents | undefined;
    pwd: string;
    ci: string;
    di: string;
    emailAgree: string;

    constructor(
        pjtNo: number,
        nickNm: string,
        userEmail: string,
        userNm: string,
        athnNo: number,
        mnbrNtrySeCd: string,
        uuid: string,
        userMobile: string,
        birthday: string,
        gender: string,
        tall: number,
        weight: number,
        os: string,
        registrationId: string,
        appVerNo: string,
        pushId: string,
        profileFile: number,
        termsAgreements: TermsAggreMents,
        pwd: string,
        ci: string,
        di: string,
        emailAgree: string
    ) {
        this.pjtNo = pjtNo;
        this.nickNm = nickNm;
        this.userEmail = userEmail;
        this.userNm = userNm;
        this.athnNo = athnNo;
        this.mnbrNtrySeCd = mnbrNtrySeCd;
        this.uuid = uuid;
        this.userMobile = userMobile;
        this.birthday = birthday;
        this.gender = gender;
        this.tall = tall;
        this.weight = weight;
        this.os = os;
        this.registrationId = registrationId;
        this.appVerNo = appVerNo;
        this.pushId = pushId;
        this.profileFile = profileFile;
        this.termsAgreements = termsAgreements;
        this.pwd = pwd;
        this.ci = ci;
        this.di = di;
        this.emailAgree = emailAgree;
    }
}

export class TermsAggreMents {
    termsAgree: string;             // 서비스 이용
    privateInfoAgree: string;       // 개인정보
    adInfoAgree: string;            // 마케팅
    gpsAgree: string;
    healthSyncAgree: string;

    constructor(termsAgree: string,
                privateInfoAgree: string,
                adInfoAgree: string,
                gpsAgree: string,
                healthSyncAgree: string) {
        this.termsAgree = termsAgree;
        this.privateInfoAgree = privateInfoAgree;
        this.adInfoAgree = adInfoAgree;
        this.gpsAgree = gpsAgree;
        this.healthSyncAgree = healthSyncAgree;
    }
}

export interface ProfileEditReq{
    nickNm: string,
    gender: string,
    birthday: string,
    tall: string,
    pjtNo: number,
    weight: number,
    userEmail: string,
    userMobile: string
}

const getUser = async () =>
    rothyClient.get<SvcUser>("/svcUsers/svcUser").then(res => res.data)

const logout = async () =>
    rothyClient.post<SvcUser>("/svcUsers/svcUser/logout").then(res => res.data)

const deleteUser = async () =>
    rothyClient.patch<SvcUser>("/svcUsers/svcUserStat").then(res => res.data)

const signIn = async (uuid:string, mnbrNtrySeCd:string) =>
    loginClient.post<DefaultResult>("/svcUsers/svcUser/signIn", {'uuid':uuid, 'mnbrNtrySeCd': mnbrNtrySeCd}).then(res => res.data)

const emailSignIn = async (email:string, pwd:string) =>
    loginClient.post<DefaultResult>("/svcUsers/svcUser/signInEmailPwd", {'email':email, 'pwd': pwd}).then(res => res.data)

const getTotalDist = async () =>
    rothyClient.get<number>("/step/dist/total").then(res => res.data)

const signUp = async (svcUserSignUp:SvcUserSignUp) =>
    loginClient.post<DefaultResult>("/svcUsers/svcUser/signUp", svcUserSignUp).then(res => res.data)

const userSignUpCheck = async (email:string) =>
    loginClient.get<DefaultResult>("/svcUsers/userSignUpCheck?email="+email).then(res => res.data)

const checkMission = async (userId:number) =>
    rothyClient.get<DefaultResult>("/userMission/v2/check/"+userId.toString()).then(res => res.data)

const checkNickNmDuplicate = async (nickNm:string) =>
    rothyClient.post<DefaultResult>("/svcUsers/nickNmCheck", {'nickNm':nickNm}).then(res => res.data)

const checkEmailDuplicate = async (email:string) =>
    rothyClient.post<DefaultResult>("/svcUsers/emailCheck", {'pjtNo':1, 'userEmail':email}).then(res => res.data)

const updateProfile = async (param?: ProfileEditReq) =>
    rothyClient.patch<Response>("/svcUsers/svcUser", {...param}).then(res => res.data)

const selectUserEmailAgree = async () =>
    await rothyClient.get<DefaultResult>("/svcUsers/emailAgree").then(res => res.data)

const updateUserEmailAgree = async (userId: number, emailAgree: string) =>
    await rothyClient.patch<Response>("/svcUsers/emailAgree", {
        userId: userId,
        emailAgree: emailAgree
    }).then(res => res.data)

const UserService = {
    getUser
    , logout
    , deleteUser
    , signIn
    , getTotalDist
    , SvcUserSignUp
    , emailSignIn
    , signUp
    , userSignUpCheck
    , checkMission
    , checkNickNmDuplicate
    , updateProfile
    , checkEmailDuplicate
    , selectUserEmailAgree
    , updateUserEmailAgree
}

export default UserService;
