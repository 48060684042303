import React, { useEffect } from 'react';
import { useNavigate, Routes, Route } from "react-router-dom";

import RothyNativeService, {
    RNIActions, RNIAppInfo, RNIDeviceInfo, RNIMessageConfig, RNIPlatform, RNIError, RNIEvents, AppStateStatus
} from "@/service/rothynative/RothyNativeService";

import GoogleSignInSample from "@/view/sample/rothynative/GoogleSignInSample";
import KakaoSignInSample from "@/view/sample/rothynative/KakaoSignInSample";
import NaverSignInSample from "@/view/sample/rothynative/NaverSignInSample";
import AppleSignInSample from "@/view/sample/rothynative/AppleSignInSample";
import RothyHealthSample from "@/view/sample/rothynative/RothyHealthSample";
import SmartScaleSample from "@/view/sample/rothynative/SmartScaleSample";
import NHISSample from "@/view/sample/rothynative/NHISSample";
import RothyPopupSample from "@/view/sample/rothynative/RothyPopupSample";
import SelfAuthSample from "@/view/sample/rothynative/SelfAuthSample";

const RothyNativeiSample: React.FC = (): JSX.Element => {
    useEffect(() => {
        // Android Only, 백키 터치시
        RothyNativeService.getInstance().addEventListener(RNIEvents.HARDWARE_BACK_PRESS, () => {
            onClickAppExit()
        })
        // 앱 활성상태에서 Notification 수신시
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_REMOTE_MESSAGE, (remoteMessage) => {
            console.log(`${RNIEvents.ON_REMOTE_MESSAGE} ${JSON.stringify(remoteMessage)}`)
        })
        // 앱 백그라운드 상태에서 Notification 수신시
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_REMOTE_BACKGROUND_MESSAGE, (remoteMessage) => {
            console.log(`${RNIEvents.ON_REMOTE_BACKGROUND_MESSAGE} ${JSON.stringify(remoteMessage)}`)
        })
        // 앱 상태변경 이벤트
        RothyNativeService.getInstance().addEventListener(RNIEvents.APP_STATE_CHANGE, (state: AppStateStatus) => {
            console.log(`${RNIEvents.APP_STATE_CHANGE} ${state}`)
        })

    }, [])

    const onClickDeviceInfo = () => {
        const config = new RNIMessageConfig<void, RNIDeviceInfo>()
        config.action = RNIActions.GET_DEVICE_INFO

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIDeviceInfo) => {
                const os = response.os
                const model = response.model
                alert(`디바이스 정보 >> ${os || ''}::${model || ''}`)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickAppInfo = () => {
        const config = new RNIMessageConfig<void, RNIAppInfo>()
        config.action = RNIActions.GET_APP_INFO

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: RNIAppInfo) => {
                alert(`앱 정보 >> ${response?.packageName || ''}::${response?.shortVersion || ''}::${response?.buildVersion || ''}`)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickPlatform = () => {
        const config = new RNIMessageConfig<void, string>()
        config.action = RNIActions.GET_PLATFORM

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: string) => {
                if (response == RNIPlatform.os.ios) {
                    alert("아이폰")
                } else if (response == RNIPlatform.os.android) {
                    alert("안드로이드")
                }
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickDeviceToken = () => {
        const config = new RNIMessageConfig<void, string>()
        config.action = RNIActions.GET_DEVICE_TOKEN

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response: string) => {
                alert("디바이스 토큰 >> " + response)
            })
            .catch((error: RNIError) => {
                alert(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onClickAppExit = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.APP_EXIT

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("app_exit")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const openUrl = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_URL
        config.data = "https://m.naver.com"

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open url success")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const openApp = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.OPEN_APP
        config.data = "com.sec.android.app.shealth"

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log("open url success")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onShowRnConsole = () => {
        const config = new RNIMessageConfig<boolean, void>()
        config.action = RNIActions.SHOW_RN_CONSOLE
        config.data = false

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.log(``)
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onSetClipboard = () => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.SET_CLIPBOARD
        config.data = "클립보드 복사합니다"

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                alert('클립보드에 복하하였습니다')
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }

    const onGetClipboard = () => {
        const config = new RNIMessageConfig<void, string>()
        config.action = RNIActions.GET_CLIPBOARD

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then((response) => {
                console.log(response)
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })
    }


    const navigate = useNavigate();

    const indexRender = () => {
        return <>
            <p>[SNS 로그인]</p>
            <button onClick={() => {
                navigate("googleSignInSample")
            }}> 구글 로그인</button>
            <br />
            <button onClick={() => {
                navigate("kakaoSignInSample")
            }}> 카카오 로그인</button>
            <br />
            <button onClick={() => {
                navigate("naverSignInSample")
            }}> 네이버 로그인</button>
            <br />
            <button onClick={() => {
                navigate("appleSignInSample")
            }}> 애플 로그인</button>

            <br />
            <p>[애플헬스]</p>
            <button onClick={() => {
                navigate("rothyHealthSample")
            }}> 애플헬스</button>

            <br />
            <p>[Infit]</p>
            <button onClick={() => {
                navigate("smartScaleSample")
            }}>Infit 체중계</button>

            <br />
            <p>[ROTHY 팝업]</p>
            <button onClick={() => {
                navigate("rothyPopupSample")
            }}>ROTHY 팝업</button>

            <br />
            <p>[건강검진]</p>
            <button onClick={() => {
                navigate("nhisSample")
            }}>건강검진</button>

            <br />
            <p>[디바이스 토큰]</p>
            <div onClick={onClickDeviceToken}>디바이스 토큰 조회</div>
            <br />
            <p>[그외]</p>
            <div onClick={onClickDeviceInfo}>디바이 정보 조회(모델, OS)</div>
            <br />
            <div onClick={onClickAppInfo}>앱 정보 조회(앱 버전)</div>
            <br />
            <div onClick={onClickPlatform}>플램폼 조회</div>
            <br />
            <div onClick={onClickAppExit}>앱 종료</div>
            <br />
            <div onClick={openUrl}>외부 앱(웹) 실행</div>
            <br />
            <div onClick={openApp}>외부 앱 실행(Android Only)</div>
            <br />
            <div onClick={onShowRnConsole}>RN 로그 미출력</div>
            <br />
            <div onClick={onSetClipboard}>클립보드 복사</div>
            <br />
            <div onClick={onGetClipboard}>클립보드 붙여넣기</div>
            <br />
            <button onClick={() => {
                navigate("selfAuthSample")
            }}>본인인증</button>
            <br />
        </>
    }

    return <>
        <Routes>
            <Route path="/" element={indexRender()} />
            <Route path="googleSignInSample" element={<GoogleSignInSample/>} />
            <Route path="kakaoSignInSample" element={<KakaoSignInSample/>} />
            <Route path="naverSignInSample" element={<NaverSignInSample/>} />
            <Route path="appleSignInSample" element={<AppleSignInSample/>} />
            <Route path="rothyHealthSample" element={<RothyHealthSample/>} />
            <Route path="smartScaleSample" element={<SmartScaleSample/>} />
            <Route path="nhisSample" element={<NHISSample/>} />
            <Route path="rothyPopupSample" element={<RothyPopupSample/>} />
            <Route path="selfAuthSample" element={<SelfAuthSample/>} />
        </Routes>
    </>
}

export default RothyNativeiSample;
