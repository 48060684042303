import React, {useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import moment from "moment/moment";
import NoContent from "@/view/common/component/NoContent";
import BoardService, {NoticeList, NoticeListData} from "@/service/BoardService";
import Loading from "@/view/common/component/Loading";
import BoardListItem from "@/view/common/component/BoardListItem";

const Notice = () => {
    const [noticeList, setNoticeList] = useState<NoticeListData[]>([])

    const { status, data:typeResult} = useQuery<NoticeList, AxiosError>(
        ['noticelist'],
        ()=> BoardService.selectNotice(),
        {
            enabled: true,
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 60,
            onSuccess: (v: NoticeList) => updateNoticeList(v.data.objectList),
        }
    )

    const updateNoticeList = (data: NoticeListData[]) => {
        const dataList : NoticeListData[] =[]
        data.map( d => dataList.push({ noticeId: d.noticeId, title: d.title, contents: d.contents, regDt: d.regDt.substring(0,10) }))
        setNoticeList(dataList)
    }

    useEffect(() => {
        if(typeResult){
            updateNoticeList(typeResult.data.objectList)
        }
    }, [])

    if(status === 'loading'){
        return (
            <>
                <Loading/>
            </>
        )
    }else if(noticeList.length !== 0) {
        return (
            <>
                {noticeList.map((n,index) =>{
                    return <div key={n.noticeId}>
                                <BoardListItem type='notice' index={index} title={n.title} subTitle={moment(n.regDt).format('YYYY.MM.DD')} contents={n.contents} heightClass={'h-fit-content'}/>
                            </div>
                })}
            </>
        )
    }else {
        return (
            <>
                <NoContent className='no-board-list mb-18px' notification='공지사항이 없습니다'/>
            </>
        )
    }
}

export default Notice;