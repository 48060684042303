import React, {forwardRef, useEffect, useState} from 'react'
import {styled} from '@mui/material/styles';
import {TabContext, TabPanel} from '@mui/lab';
import {Tabs} from '@mui/material';
import Tab from '@mui/material/Tab';
import {useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import StringUtil from '@/common/StringUtil';
import StorageUtil from '@/common/StorageUtil';
import {USER_INFO} from '@/common/Constant';
import {SvcUserData} from '@/service/UserService';
import WalkDistance, {WalkDistanceParam} from '@/view/walk/component/WalkDistance';
import WalkScore, {WalkScoreParam} from '@/view/walk/component/WalkScore';
import StepService, {WalkDistanceData, WalkScoreData} from '@/service/StepService';

interface StyledTabProps {
    label: string;
    value: string;
}

interface StyledTabsProps {
    children?: React.ReactNode;
    value: string;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const WalkRecord = forwardRef((props, ref) => {
    const [summaryTabValue, setSummaryTabValue] = useState('1')
    const [walkDistance, setWalkDistance] = useState<WalkDistanceParam>({
        showYn: 'LOADING',
        step: 0,
        calorie: 0,
        distance: 0,
        pointCount: 0,
        startPoint: '',
        endPoint: '',
        recordComment: '',
        recordImageUrl: ''})
    const [walkScore, setWalkScore] = useState<WalkScoreParam>({
        comment: 'LOADING',
        score: 0,
        abilityType: '',
        abilityInfo: '',
        imageUrl: '',
        showYn: '',
        stepTypeNo: 0
    })
    const [periodType, setPeriodType] = useState('TOTAL')
    const userInfo: SvcUserData  = StringUtil.jsonString2ObjectWithMap<SvcUserData>(StorageUtil.getLocalStorage(USER_INFO) as unknown as '')
    const userId = userInfo?.userId


    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        switch(newValue){
            case '1':
                setPeriodType('TOTAL')
                break;
            case '2':
                setPeriodType('MONTH')
                break;
            case '3':
                setPeriodType('WEEK')
                break;
        }
        initData()
        setSummaryTabValue(newValue)
    }

    const StyledTab = styled((props2: StyledTabProps) => (
        <Tab disableRipple {...props2} />
    ))(({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: '#1A1A1A',
        opacity: '0.3',
        '&.Mui-selected': {
            color: '#1A1A1A',
            opacity: '1',
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#1A1A1A',
        },
    }));

    const StyledTabs = styled((props3: StyledTabsProps) => (
        <Tabs
            {...props3}
            TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
        />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            width: '100%'
        },
        '& .MuiTabs-indicatorSpan': {
            maxWidth: 15,
            width: '100%',
            backgroundColor: '#1A1A1A',
        },
    });

    const {refetch:getWalkDistance} = useQuery<WalkDistanceData, AxiosError>(
        ['getWalkDistanceData'],
        ()=> StepService.getWalkDistance({periodType: periodType, userId: userId}),
        {
            onSuccess: (v:WalkDistanceData) => updateDistanceData(v)
        }
    )

    const {refetch:getWalkScore} = useQuery<WalkScoreData, AxiosError>(
        ['getWalkScoreData'],
        ()=> StepService.getWalkScore({periodType: periodType, userId: userId}),
        {
            onSuccess: (v:WalkScoreData) => updateScoreData(v)
        }
    )

    const initData = () => {
        getWalkDistance()
            .then(r => r.data)
            .catch(reason => {console.log(reason)})

        getWalkScore()
            .then(r => r.data)
            .catch(reason => {console.log(reason)})
    }

    const updateDistanceData = (v:WalkDistanceData) => {
        const data = v.data
        setWalkDistance({...data})
    }

    const updateScoreData = (v:WalkScoreData) => {
        const data = v.data
        setWalkScore({...data})
    }

    useEffect(()=>{
        initData()
    },[periodType])


    return (
               <div className='mrml-24px pt-60px pb-60px'>
                   <h4 className='h4_l'>YOU MADE IT!</h4>
                   <TabContext value={summaryTabValue}>
                       <div className={'record-tab-g'}>
                           <StyledTabs
                               value={summaryTabValue}
                               onChange={handleTabChange}
                           >
                               <StyledTab label='TOTAL' value='1' />
                               <StyledTab label='MONTH' value='2' />
                               <StyledTab label='WEEK'  value='3'/>
                            </StyledTabs>
                       </div>
                       <TabPanel value='1' className={'padding-0'}>
                           <WalkDistance {...walkDistance}/>
                           <WalkScore {...walkScore}/>
                       </TabPanel>
                       <TabPanel value='2' className={'padding-0'}>
                           <WalkDistance {...walkDistance}/>
                           <WalkScore {...walkScore}/>
                       </TabPanel>
                       <TabPanel value='3' className={'padding-0'}>
                           <WalkDistance {...walkDistance}/>
                           <WalkScore {...walkScore}/>
                       </TabPanel>
                   </TabContext>
               </div>
    )
})

export default WalkRecord