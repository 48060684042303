import {rothyClient} from "@/service/RothyClient";

export interface TilkoAuthReq{
    birthDate: string,
    privateAuthType : string,
    userCellphoneNumber: string,
    userName: string
}

export interface TilkoAuthRes{
    code: string,
    data: TilkoAuthResData,
    errors: string,
    message: string,
    result: boolean
}

export interface TilkoAuthResData{
    Status: string,
    Message: string,
    ResultData: TilkoAuthData
}

export interface TilkoAuthData{
    Token: string,
    TxId: string,
    CxId: string,
    ReqTxId: string,
    PrivateAuthType: string,
    UserName: string,
    BirthDate: string,
    UserCellphoneNumber: string
}

export interface TilkoHealthReportReq{
    birthDate: string,
    cxId: string,
    privateAuthType: string,
    reqTxId: string,
    token: string,
    txId: string,
    userCellphoneNumber: string,
    userName: string
}

export interface TilkoHealthReportRes{
    result: boolean,
    message: string,
    data: HealthReportData[]
}

export interface HealthReportData{
    checkupDate: string,
    code: string,
    codeInfo: string,
    location: string,
    description: string,
    resultDetailList: ResultDetail[]
}

export interface ResultDetail{
    illnessName: string,
    valueList: ValueData[]
}

export interface ValueData{
    itemName: string,
    value: string,
    label: string,
    normalValue: string,
    labelType: string,
    lawValue: string
}

export interface HealthReportPossibleRes{
    result: boolean,
    code: string,
    message: string,
    data: HealthReportPossibleData
}

export interface HealthReportPossibleData{
    isPossible: boolean,
    message: string
}

export interface HealthReportDetailReq{
    detailCodeList: string[]
}

export interface HealthReportDetailRes{
    data: HealthReportDetailData,
}

export interface HealthReportDetailData{
    detailList: HealthReportDetail[],
}

export interface HealthReportDetail{
    itemName:string,
    currentValue: string,
    dangerValue: string,
    warningValue: string,
    goodValue: string,
    information: string,
    graphYn: string,
    label: string,
    labelType: string,
    annotationList: GraphAnnotation[],
    subAnnotationList: GraphAnnotation[],
    resultList: HealthReportDetailResult[]
}

export interface GraphAnnotation{
  value: string,
  color: string
}

export interface HealthReportDetailResult{
    checkupDate: string,
    itemName: string,
    value: string | undefined,
    lawValue: string | undefined,
    labelType: string,
}

const getTilkoAuth = async (props: TilkoAuthReq) =>
    rothyClient.get<TilkoAuthRes>("/health/simple-auth", {params:props}).then(res => res.data)

const getHealthReport = async (props: TilkoHealthReportReq) =>
    rothyClient.get<TilkoHealthReportRes>("/health/report", {params: props}).then(res => res.data)

const getEmptyHealthReport = async (props: TilkoHealthReportReq) =>
    rothyClient.get<TilkoHealthReportRes>("/health/report/test", {params: props}).then(res => res.data)

const getHealthReportResult = async () =>
    rothyClient.get<TilkoHealthReportRes>("/health/report/history").then(res => res.data)

const getHealthReportPossible = async () =>
    rothyClient.get<HealthReportPossibleRes>("/health/report/possible").then(res => res.data)

const getHealthReportDetail = async (props: HealthReportDetailReq) =>
    rothyClient.get<HealthReportDetailRes>("/health/report/detail", {params: {detailCodeList: props.detailCodeList.join(',')}}).then(res => res.data)

const HealthService = {
    getTilkoAuth,
    getHealthReport,
    getEmptyHealthReport,
    getHealthReportResult,
    getHealthReportPossible,
    getHealthReportDetail
}

export default HealthService