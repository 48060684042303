import React, {useCallback} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import NotificationSetting from '@/view/notification/component/NotificationSetting';

interface notificationSettingProps {
    onClose: () => void
}

const NotificationSettingView: React.FC<notificationSettingProps> = (props:notificationSettingProps) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    return (
        <>
            <DialogHeader title='알림 설정' onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <NotificationSetting/>
            </div>
        </>
    )
}

export default NotificationSettingView;