import React, {useCallback, useEffect, useRef, useState} from 'react'
import {animated, useSpring} from "@react-spring/web";


type webPopupParam = {
    type: string,
    isOpen:boolean,
    className ?: string,
    url: string
}

const WebPopup = (props:webPopupParam) => {
    const [isInDOM, setIsInDOM] = useState(false)
    const bodyOverflowStyleRef = useRef<string>(document.body.style.overflow)
    const topRef = useRef<string>(document.body.style.top)
    const contentRef = useRef<HTMLDivElement>(null)
    const iframeHeight = props.type === 'BOTTOM_FULL' || props.type === 'DEFAULT' ? window.innerHeight : window.innerHeight * 0.6
    const [popupStyle, popupStyleApi] = useSpring(() => ({
        opacity: 0
    }))

    const handleContentClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), [])

    useEffect(() => {
        if (props.isOpen) {
            const currY = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
            bodyOverflowStyleRef.current = document.body.style.overflow
            topRef.current = document.body.style.top
            document.body.style.overflow = 'hidden'
            document.body.style.top = `-${currY}px`
            setIsInDOM(true)
        } else {
            popupStyleApi.start({opacity: 0, delay: 100})
            setIsInDOM(false)
        }
    }, [props.isOpen, popupStyleApi])

    useEffect(() => {
        if (isInDOM) {
            popupStyleApi.start({ opacity:1 })
        } else if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = bodyOverflowStyleRef.current
            document.body.style.top = topRef.current
        }
    }, [isInDOM, popupStyleApi])

    useEffect(() => () => {
        if (document.body.style.overflow === 'hidden') {
            document.body.style.overflow = bodyOverflowStyleRef.current
            document.body.style.top = topRef.current
        }
    }, [])

    return(
        <>
            {
                props.isOpen ?
                    <animated.div className={'rothy-popup'} style={popupStyle}>
                        <animated.div className={`web-popup-body${props.className ? ' ' + props.className : ''}`} onClick={handleContentClick}>
                            <div ref={contentRef}>
                                <iframe sandbox={'allow-same-origin allow-scripts'} width={'100%'} height={iframeHeight} max-height={window.outerHeight} src={props.url}></iframe>
                            </div>
                        </animated.div>
                    </animated.div>
                    : null
            }
        </>
    )
}

export default WebPopup