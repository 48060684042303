import React, {useEffect, useRef, useState} from 'react'
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import PullToRefresh from 'react-simple-pull-to-refresh'
import {Tab} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import SleepView from '@/view/sleep/SleepView'
import WeightView from '@/view/weight/WeightView'
import MissionView from '@/view/mission/MissionView'
import WalkView from '@/view/walk/WalkView'
import Popup from "@/view/common/popup/Popup"
import StorageUtil from "@/common/StorageUtil"
import {EVENT_TYPE, GENDER, PutMeasureType, USER_INFO} from "@/common/Constant"
import StringUtil from "@/common/StringUtil"
import RothyNativeService, {
    RNIActions,
    RNIError,
    RNIEvents,
    RNIMessageConfig
} from "@/service/rothynative/RothyNativeService"
import * as RNISmartScale from "@/service/rothynative/RNISmartScale"
import {ICSexType, ICUserInfo, ICWeightData} from "@/service/rothynative/RNISmartScale"
import {SvcUserData} from "@/service/UserService"
import DateUtil from "@/common/DateUtil"
import {putMeasure, putMeasureMq} from "@/service/PutMeasureService"
import {DefaultResult} from "@/service/RothyClient";
import UserDvicService, {UserDvicData, UserDvicReq} from "@/service/UserDvicService";
import MeasureWeightView from "@/view/userDvic/MeasureWeightView";

const Main = () => {
    const [value, setValue] = React.useState('1')
    const [refreshStyle] = useState({width:'30px', height:'30px', marginTop:'15px'})
    const [refreshFlag, setRefreshFlag] = useState<boolean>(false)
    const [vitaIndexFlag, setVitaIndexFlag] = useState<boolean>(false)
    const missionViewRef = useRef<{refresh: () => void}>(null)
    const walkViewRef = useRef<{refresh: () => void}>(null)
    const sleepViewRef = useRef<{refresh: () => void}>(null)
    const weightViewRef = useRef<{refresh: () => void}>(null)
    const [isConnectScale,setIsConnectScale] = useState(false)
    const [loginUserInfo,setLoginUserInfo] = useState<SvcUserData>()
    const [measureInfo,setMeasureInfo] = useState<ICWeightData>()
    const [popupIsOpen,setPopupIsOpen] = useState(true)
    const userDvicReq:UserDvicReq = {dvicType:'SCALE'}
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if(!refreshFlag) {
            setValue(newValue.toString())
            window.scrollTo({top: 0, left: 0, behavior: 'auto'})
        }
    }

    const refreshWalkView = () => {
        if(walkViewRef.current !== null){
            walkViewRef.current.refresh()
        }
    }

    const refreshWalkDetail = () => {
        if(!refreshFlag) {
            setRefreshFlag(true)

            return putMeasure(PutMeasureType.STEP)
                .catch(e => console.log(e))
                .finally(() => {
                    refreshWalkView()
                    setRefreshFlag(false)
                })
        }else{
            return new Promise(()=> {
                console.log('skip')
            })
        }
    }

    const refreshSleepDetail = async () => {
        if(!refreshFlag){
            return putMeasure(PutMeasureType.SLEEP)
                .catch(e => console.log(e))
                .finally(() => {
                    if(sleepViewRef.current !== null){
                        return sleepViewRef.current.refresh()
                    }
                })
        }else{
            return new Promise(()=> {
                console.log('skip')
            })
        }
    }

    const refreshMissionView = () => {
        if(missionViewRef.current !== null){
            missionViewRef.current.refresh()
        }
    }

    const refreshAllData = () => {
        if(!refreshFlag) {
            setRefreshFlag(true)

            return putMeasure(PutMeasureType.ALL)
                .then(() => {
                    console.log("초기 풋매저 성공")
                })
                .catch(e => console.log(e))
                .finally(() => {
                    console.log("초기 풋매저 완료")
                    setVitaIndexFlag(true)
                    setRefreshFlag(false)

                    // 미션 갱신
                    refreshMissionView()

                    // 걸음판 갱신
                    refreshWalkView()

                    // 수면판 갱신
                    if(sleepViewRef.current !== null){
                        return sleepViewRef.current.refresh()
                    }

                    // 체질량판 갱신
                    if (weightViewRef.current !== null) {
                        return weightViewRef.current.refresh()
                    }
                })
        }else{
            return new Promise(() => {
                console.log('skip')
            })
        }
    }

    const refreshWeightView = async () => {
        if(!refreshFlag) {
            return putMeasure(PutMeasureType.WEIGHT)
                .catch(e => console.log(e))
                .finally(() => {
                    if (weightViewRef.current !== null) {
                        return weightViewRef.current.refresh()
                    }
                })
        }else{
            return new Promise(()=> {
                console.log('skip')
            })
        }
    }

    const setListener = () => {
        // 체중계 체중 데이터
        console.debug("setListener######## 001")
        RothyNativeService.getInstance().addEventListener(RNIEvents.ON_IC_RECEIVE_WEIGHT_DATA, (response: ICWeightData) => {
            console.debug("데이터 수집 리스너########")

            setPopupIsOpen(true)
            setIsConnectScale(true)
            setMeasureInfo(response)
            if (response.isStabilized) {

                if(!checkMeasureDataValidation(response)){
                    console.debug("수집 데이터 유효성 오류")
                    setPopupIsOpen(false)
                    return
                }else{
                    setPopupIsOpen(true)
                }


                if (loginUserInfo) {
                    response.height = Number(loginUserInfo.tall)
                } else {
                    response.height = 179
                }

                putMeasure(PutMeasureType.WEIGHT, response)
                    .then(() => {
                        closeMeasurePopup()
                        if (weightViewRef.current !== null) {
                            return weightViewRef.current.refresh()
                        }
                    })
                    .catch(e => console.log(e))
            }

            console.debug(StringUtil.stringify(response))
        })
        console.debug("setListener######## 002")


    }

    const addDvic = (data :UserDvicData[]) => {
        console.debug("내 체중계 목록 ##########")
        data.forEach( d => {
            if (d.macAddr != null) {
                setDvic(d.macAddr)
            }
            console.debug(d.macAddr)
        })
        console.debug("내 체중계 목록 @@@@@@@@@")

    }
    const setDvic = (macAddr:string) => {
        const config = new RNIMessageConfig<string, void>()
        config.action = RNIActions.IC_ADD_DEVICE
        config.data = macAddr

        console.debug("기기등록 시작 #####")
        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                console.debug("기기등록 성공 #####")
            })
            .catch((error: RNIError) => {
                if (error.code === '-9001') {
                    // 블루투스 권한 미허용 or 블루투스 disable
                    console.log('블루투스 off')
                } else if (error.code === '-1001') {
                    // SDK 초기화 되지 않음
                    console.log('기기등록 오류')
                }

                console.debug("기기등록 실패 #####")
                console.log(JSON.stringify(error))
            })
    }
    const addScaleListener = ()  => {
        const param = new ICUserInfo()
        param.userIndex = 1;
        param.height = 172
        param.age = 39;
        param.sex = ICSexType.ICSexTypeMale

        console.debug("체중계 데이터 수집 리스너 등록 시작 001")
        if(loginUserInfo !== undefined) {
            if(StringUtil.isNotEmpty(loginUserInfo.tall) && Number(loginUserInfo.tall) > 0) {
                param.height = Number(loginUserInfo.tall)
            }

            if(StringUtil.isNotEmpty(loginUserInfo.birthday) && loginUserInfo.birthday.length == 8){
                param.age = DateUtil.getAge(loginUserInfo.birthday)
            }

            if(StringUtil.isNotEmpty(loginUserInfo?.gender) && loginUserInfo?.gender.match(GENDER.FEMALE))
                param.sex = ICSexType.ICSexTypeFemal
        }
        console.debug("초기화 파라메터#########")
        console.debug(StringUtil.stringify(param))

        const config = new RNIMessageConfig<RNISmartScale.ICUserInfo, void>()
        config.action = RNIActions.IC_UPDATE_USER_INFO
        config.data = param

        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                onInitMgr()
            })
            .catch((error: RNIError) => {
                console.log(`${error.code || ''}::${error.message || ''}`)
            })

    }

    const onInitMgr = () => {
        const config = new RNIMessageConfig<void, void>()
        config.action = RNIActions.IC_INIT_MGR

        console.debug("onInitMgr######### 0001")
        RothyNativeService
            .getInstance()
            .postMessage(config)
            .then(() => {
                setListener()
                console.debug("체중계 데이터 수집 리스너 등록 완료#######")
            })
            .catch((error: RNIError) => {
                console.log(JSON.stringify(error))
            })
    }

    const closeMeasurePopup = () => {
        setIsConnectScale(false)
    }

    const checkMeasureDataValidation = (checkData:ICWeightData) : boolean => {
        let checkDataResult = false
        console.debug("@@@@@@@@@@@@@@@ 유효성 검사")
        console.debug(StringUtil.stringify(checkData))
        if(checkData !== undefined){
            if(checkData.bodyFatPercent !== undefined &&  checkData.bodyFatPercent > 0)
                checkDataResult = true
        }
        return checkDataResult
    }

    const { status, data:typeResult, refetch: userDviclist} = useQuery<DefaultResult, AxiosError>(
        ['userDviclist'],
        ()=> UserDvicService.selectUserDvic(userDvicReq),
        {
            enabled: true,
            onSuccess: (v: DefaultResult) => addDvic(v.data as UserDvicData[]),
        }
    )

    useEffect(()=>{
        refreshMissionView()

        void refreshAllData()
        void putMeasureMq()

        userDviclist().catch(e => console.log(e))
        setLoginUserInfo(StringUtil.jsonString2ObjectWithMap<SvcUserData>(StorageUtil.getLocalStorage(USER_INFO) as unknown as ""))
        window.addEventListener(EVENT_TYPE.WALK_SET_LISTENER,addScaleListener)
        console.debug("!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        return  () =>{
            RothyNativeService.getInstance().removeEventListener(RNIEvents.ON_IC_RECEIVE_WEIGHT_DATA)
        }

    },[])

    useEffect(()=>{
        if(loginUserInfo==undefined) {
            return
        }else{
            console.debug("$$$$$$$$$$loginUserInfo")
            console.debug(StringUtil.stringify(loginUserInfo))
        }
        addScaleListener()
    },[loginUserInfo])

    return (
        <>
            <TabContext value={value}>
                <TabList onChange={handleTabChange} className={'menu-tab-g w-100p'} variant='scrollable'>
                    <Tab label='HOME' value='1' />
                    <Tab label='WALK' value='2' />
                    <Tab label='SLEEP' value='3' />
                    <Tab label='WEIGHT' value='4' />
                </TabList>
                <div className={'main-content'}>
                    <TabPanel value='1' className={'padding-0'}>
                        <PullToRefresh onRefresh={refreshAllData}
                                       pullingContent={<></>}
                                       refreshingContent={<CircularProgress color={'primary'} style={refreshStyle}/>}
                                       pullDownThreshold={48}
                                       resistance={5}>
                            <MissionView ref={missionViewRef} vitaIndexFlag={vitaIndexFlag}/>
                        </PullToRefresh>
                    </TabPanel>
                    <TabPanel value='2' className={'padding-0'}>
                        <PullToRefresh onRefresh={refreshWalkDetail}
                                       pullingContent={<></>}
                                       refreshingContent={<CircularProgress color={'primary'} style={refreshStyle}/>}
                                       pullDownThreshold={48}
                                       resistance={5}>
                            <WalkView ref={walkViewRef}/>
                        </PullToRefresh>
                    </TabPanel>
                    <TabPanel value='3' className={'padding-0'}>
                        <PullToRefresh onRefresh={refreshSleepDetail}
                                       pullingContent={<></>}
                                       refreshingContent={<CircularProgress color={'primary'} style={refreshStyle}/>}
                                       pullDownThreshold={48}
                                       resistance={5}>
                            <SleepView ref={sleepViewRef}/>
                        </PullToRefresh>
                    </TabPanel>
                    <TabPanel value='4' className={'padding-0'}>
                        <PullToRefresh onRefresh={refreshWeightView}
                                       pullingContent={<></>}
                                       refreshingContent={<CircularProgress color={'primary'} style={refreshStyle}/>}
                                       pullDownThreshold={48}
                                       resistance={5}>
                            <WeightView ref={weightViewRef}/>
                        </PullToRefresh>
                    </TabPanel>
                </div>
            </TabContext>
            <Popup className='bg-cl-black'  content={<MeasureWeightView  measureInfo={measureInfo} isOpen={popupIsOpen}/>} isOpen={isConnectScale}    onClose={closeMeasurePopup}  iconClass={'close-w'}/></>
    )
}

export default Main

