import React, {useCallback} from 'react'
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import {createSearchParams, useNavigate} from "react-router-dom";
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DeleteUserContent from "@/view/user/component/DeleteUserContent"
import BottomButton from "@/view/common/component/button/BottomButton"
import Notification from "@/view/user/component/Notification"
import {DELETE_USER, LOGIN_TYPE, MNBR_NTRY_SE_CD} from '@/common/Constant'
import UserService, {SvcUser} from "@/service/UserService";
import DialogHeader from "@/view/layout/DialogHeader";
import StorageUtil from "@/common/StorageUtil";

interface DeleteProps {
    onClose: () => void
}

type DeleteUserMsg = {
    type : string
}

const DeleteUser: React.FC<DeleteProps> = (props:DeleteProps) => {
    const navigate = useNavigate()
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const data: DeleteUserMsg = { type: LOGIN_TYPE.DELETE_USER};

    const {refetch: deleteUser} = useQuery<SvcUser, AxiosError>(
        ['deleteUser'],
        ()=> UserService.deleteUser(),
        {
            onSuccess: () => onSuccess(),
        }
    )

    let flag = false
    const onClick = async() => {
        if(flag) return
        flag = true
        await deleteUser()
        flag = false
    }

    const onSuccess = () => {
        StorageUtil.setLocalStorage(MNBR_NTRY_SE_CD,'')
        goLogin()
    }

    const goLogin = () => {
        navigate({pathname: '/login', search: createSearchParams(data).toString()})
    }

    return (
        <>
            <DialogHeader onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <DeleteUserContent/>
            </div>

            <BottomButton title={DELETE_USER.BTN} disabled={false} notification={<Notification/>} onClick={() => onClick()}/>
        </>
    )
}

export default DeleteUser;