import React, {useCallback, useEffect, useState} from 'react'
import {Dialog, Input, InputAdornment, OutlinedInput, Slide, SlideProps} from "@mui/material";
import {AxiosError} from "axios";
import {useQuery} from "@tanstack/react-query";
import DialogHeader from "@/view/layout/DialogHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import BottomButton from "@/view/common/component/button/BottomButton";
import BoardService, {RegistQuestionReq, Response} from "@/service/BoardService";
import {COMP_CD, USER_INFO} from "@/common/Constant";
import StringUtil from "@/common/StringUtil";
import StorageUtil from "@/common/StorageUtil";
import {SvcUserData} from "@/service/UserService";
import QuestionComplete from "@/view/question/component/QuestionComplete";
import {history} from "@/view/common/component/History";
import Confirm from "@/view/common/popup/Confirm";

export interface QuestionAddParam{
    onClose: () => void,
    onReturn: () => void,
    questionType: string
}

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const QuestionAdd = (props: QuestionAddParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const handleReturnClick = useCallback(() => {props.onReturn()}, [props.onReturn])
    const [open, setOpen] = React.useState(false)
    const titleMaxLength = 20
    const contentMaxLength = 1000
    const [titleLength, setTitleLength] = useState(0)
    const [contentLength, setContentLengh] = useState(0)
    const [questionItem, setQuestionItem] = useState({ title: "", content: ""})
    const [registItem, setRegistItem] = useState<RegistQuestionReq>()
    let registFlag = false
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const unlistenHistoryEvent = history.listen(({ action }) => {
            if (action === 'POP') {
                checkIsChanged()
            }
        });

        return unlistenHistoryEvent;

    }, [history]);

    const checkIsChanged = () => {
        if(StringUtil.isNotEmpty(questionItem.title) || StringUtil.isNotEmpty(questionItem.content)){
            setIsOpen(true)
        }else{
            handleOverlayClick()
        }
    }

    const inputChanged = (event: { target: { id: string; value: string; }; }) => {
        const { id, value } = event.target
        setQuestionItem({ ...questionItem, [id]: value })
        if(id === 'title' && value !== undefined){
            setTitleLength(value.length)

        }else if(id === 'content' && value !== undefined){
            setContentLengh(value.length)
        }
    }

    const {refetch:postQuestion} = useQuery<Response, AxiosError>(
        ['postQuestion'],
        ()=> BoardService.postQuestion(registItem),
        {
            onSuccess: (v: Response) => {setOpen(true)}
        }
    )

    const registQuestion = () => {
        const compCd = localStorage.getItem(COMP_CD)
        const userInfo = StorageUtil.getLocalStorage(USER_INFO)
        const user:SvcUserData = StringUtil.jsonString2ObjectWithMap<SvcUserData>(userInfo? userInfo : '')
        setRegistItem({
            wrtrUserNo: user.userId,
            wrtrNickNm: user.nickNm,
            wrtrWpno: user.userMobile,
            compCd: compCd? compCd : '',
            questionTitle: questionItem.title,
            questionCn: questionItem.content,
            questionType: props.questionType
        })
    }

    useEffect(()=> {
        if(registItem !== undefined) {
            if(registFlag){
                return
            }
            registFlag = true
            postQuestion()
                .then(r => {
                    registFlag = false
                    return Promise<void>
                })
                .catch(reason => {
                    console.log(reason)
                })
        }
    }, [registItem])

    return <>
        <DialogHeader onClose={checkIsChanged} title={'문의등록'}/>
        <div className={'board'}>
            <HeaderSpacer/>
            <div className={'question-list'}>
                <Input className={'b2_r w-100p h-22px mt-39px mb-24px'}
                       id={'title'}
                       required={true}
                       placeholder={'제목을 입력해주세요'}
                       inputProps={{ maxLength: titleMaxLength}}
                       endAdornment={<InputAdornment className={'c1_r'} position="end">{titleLength}/{titleMaxLength}</InputAdornment>}
                       onChange={inputChanged}
                />
                <OutlinedInput className={'b2_r w-100p'}
                               style={{flexDirection: 'column', alignItems : 'flex-end'}}
                               id={'content'}
                               required={true}
                               placeholder={'내용을 입력해주세요'}
                               rows={10}
                               multiline={true}
                               inputProps={{ maxLength: contentMaxLength}}
                               endAdornment={<InputAdornment className={'c1_r'} position="end">{contentLength}/{contentMaxLength}</InputAdornment>}
                               onChange={inputChanged}
                />
            </div>
        </div>
        {questionItem.title === '' || questionItem.content === ''
            ? <BottomButton className={'primary'} disabled={true} title={'문의 등록하기'}/>
            : <BottomButton className={'primary'} disabled={false} title={'문의 등록하기'} onClick={registQuestion}/>
        }
        <Dialog fullScreen open={open} onClose={handleReturnClick} TransitionComponent={Transition}>
            {<QuestionComplete onClose={handleReturnClick}/>}
        </Dialog>
        <Confirm content={<span>작성 중인 내용이 사라집니다<br/>그래도 나가시겠습니까?</span>} title={undefined} leftBtnContent={'아니오'} rightBtnContent={'네'} isOpen={isOpen} onClick={ () => setIsOpen(false)} onClose={ () => props.onClose()}/>
    </>
}

export default QuestionAdd
