import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import StorageUtil from "@/common/StorageUtil"
import {ACCESS_TOKEN, MESSAGE, LOGIN_TYPE} from "@/common/Constant"
import Toast from "@/view/common/popup/Toast";
import Onboard from "@/view/login/component/Onboard";

import StringUtil from "@/common/StringUtil";

const LoginView: React.FC = (): JSX.Element => {
    const [searchParam] = useSearchParams()
    const [isOpen, setIsOpen] = useState(false)
    const [alertContent, setAlertContent] = useState('')
    const [gotoLogin, setGotoLogin] = useState(false)

    const initLogin = () => {
        const type = searchParam.get("type")

        if(StringUtil.isNotEmpty(StorageUtil.getLocalStorage(ACCESS_TOKEN))) {
            if(type !== undefined && type === LOGIN_TYPE.LOGOUT){
                setAlertContent(MESSAGE.LOGOUT)
                setIsOpen(true)
                setGotoLogin(true)
            }else if(type !== undefined && type === LOGIN_TYPE.EXPIRED){
                setAlertContent(MESSAGE.LOGIN_EXPIRED)
                setIsOpen(true)
                setGotoLogin(true)
            }else if(type !== undefined && type === LOGIN_TYPE.DELETE_USER){
                setAlertContent(MESSAGE.DELETE_USER)
                setIsOpen(true)
            }

        }else if(StorageUtil.getLocalStorage(ACCESS_TOKEN) !== null){
            setGotoLogin(true)
        }
        StorageUtil.initStorage()

    }

    useEffect(()=> {
        initLogin()
    },[])

    return(
        <>
            <div id="rootBg-black"></div>
            <Onboard gotoLogin={gotoLogin}/>
            <Toast content={<div>{alertContent}</div>} isOpen={isOpen} onClose={ () => setIsOpen(false) }></Toast>
        </>
    )

}

export default LoginView;