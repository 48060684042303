import React, {useCallback} from 'react'
import HeaderSpacer from "@/view/layout/HeaderSpacer"
import DialogHeader from "@/view/layout/DialogHeader";
import Faq from "@/view/faq/component/Faq";

interface faqProps {
    onClose: () => void
}

const FaqView: React.FC<faqProps> = (props:faqProps) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])

    return (
        <><DialogHeader title='FAQ' onClose={handleOverlayClick}/>
            <div className={'board'}>
                <HeaderSpacer/>
                <div className='content-main pb-62px'>
                    <p className={'b1_l cl-gray02 mb-8px'}>ROTHY가 도와드릴게요</p>
                    <span className={'h4_r cl-gray01'}>어떤 도움이 <br/> 필요하신가요?</span>
                </div>
            </div>
            <Faq/>
        </>
    )
}

export default FaqView;