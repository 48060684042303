import StringUtil from "@/common/StringUtil";
import {forbiddenWords, slangWords} from "@/common/ForbiddenWord";
import UserService from "@/service/UserService";

export const genderList = [{name:'남', value: 'M'},{name:'여', value:'F'}]

function convertGenderName (value:string):string {
    let result = ''
    genderList.map((data)=>{
        if(data.value === value){
            result = data.name
        }
    })
    return result
}

function convertGenderValue (name:string):string {
    let result = ''
    genderList.map((data)=>{
        if(data.name === name){
            result = data.value
        }
    })
    return result
}

function checkValidBirthday(value:string){
    let replaceValue = value.replace(/[^0-9]/g,'')
    let errMsg = ''
    if(StringUtil.isEmpty(replaceValue)){
        errMsg = '생년월일 8글자를 입력해주세요.'
    }else if(replaceValue.length >= 4){
        if(calcAge(replaceValue) <14){
            errMsg = '만 14세 이상부터 사용하실 수 있어요.'
        }else if(Number(replaceValue.substring(0,4)) <= 1921){
            errMsg = '1921년 이전 출생자는 사용할 수 없습니다.'
        }else if(replaceValue.length === 6 && !checkMonth(replaceValue)){
            errMsg = '올바른 생년월일을 입력해주세요.'
        }else if(replaceValue.length === 8 && (!checkMonth(replaceValue) || !checkDay(replaceValue))){
            errMsg = '올바른 생년월일을 입력해주세요.'
        }else if(replaceValue.length < 8){
           errMsg = '생년월일 8글자를 입력해주세요.'
        }else{
            replaceValue = replaceValue.replace(/(\d{4})(\d{2})(\d{2})/g, '$1.$2.$3')
            errMsg = ''
        }
    }else if(replaceValue.length <8){
        errMsg = '생년월일 8글자를 입력해주세요.'
    }else{
        replaceValue = replaceValue.replace(/(\d{4})(\d{2})(\d{2})/g, '$1.$2.$3')
        errMsg = ''
    }
    return {value:replaceValue, errMsg: errMsg}
}

const checkMonth = (value:string):boolean => {
    const month =  Number(value.substring(4,6))
    return !(month < 1 || month > 12);
}

const checkDay = (value:string):boolean => {
    const year = Number(value.substring(0,4))
    const isLeapYear = year % 4 == 0 && year % 100 === 0 && year % 400 === 0 //윤년체크
    const month =  Number(value.substring(4,6))
    const day = Number(value.substring(6,8))
    const days31:number[] = [1,3,5,7,8,10,12]
    const days30:number[] = [4,6,9,11]
    if(month === 2){
        if(isLeapYear && (day < 1 || day > 29)){
            return false
        }
        if(!isLeapYear && (day < 1 || day > 28)){
            return false
        }
    }else if(days31.includes(month) && (day < 1 || day >31)){
        return false
    }else if(days30.includes(month) && (day < 1 || day > 30)){
        return false
    }
    return true
}

function calcAge(value:string){
    const year = Number(value.substring(0,4))
    const month =  Number(value.substring(4,6))
    const day = Number(value.substring(6,8))
    const today = new Date()
    const birthDate = new Date(year, month, day)

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age
}

function checkValidTall(value:string){
    let errMsg
    if(/^[\d]*\.?[\d]{0,1}$/.test(value.replace(' cm',''))){
        const tall = Number(value)
        if(tall <100 || tall > 250){
            errMsg = '실제 키를 입력해주세요.'
        }else {
            errMsg = ''
        }
    }else{
        errMsg = '실제 키를 입력해주세요.'
    }
    return {value:value, errMsg:errMsg}
}

function checkValidNickName(value: string) {
    let errMsg = ''
        if (StringUtil.isEmpty(value)) {
            errMsg = '닉네임을 입력해주세요.'
        } else if (value.length > 20) {
            errMsg = '최대 20자까지 입력하실 수 있어요.'
        } else if (includeSlangWord(value)) {
            errMsg = '비속어는 사용하실 수 없어요.'
        } else if (includeForbiddenWord(value)) {
            errMsg = '사용할 수 없는 단어가 포함되어 있습니다.'
        } else if (StringUtil.isEmpty(value.replaceAll(' ', ''))) {
            errMsg = '닉네임을 입력해주세요.'
        } else {
            errMsg = ''
        }
    return {value: value, errMsg: errMsg}
}

function includeSlangWord(value:string){
    for(const word of slangWords){
        if(value.toUpperCase().includes(word.toUpperCase())){
            return true
        }
    }
    return false
}

function includeForbiddenWord(value:string){
    for(const word of forbiddenWords){
        if(value.toUpperCase().includes(word.toUpperCase())){
            return true
        }
    }
    return false
}

function checkDuplicate(value: string):Promise<boolean>{
    return UserService.checkNickNmDuplicate(value)
                                .then(res => res.data as boolean)
                                .catch(() => false)
}

function checkEmailDuplicate(value: string):Promise<boolean>{
    return UserService.checkEmailDuplicate(value)
        .then(res => res.data as boolean)
        .catch(() => false)
}

function checkValidEmail(value:string){
    const resultValue = value.replaceAll(' ', '')
    let errMsg = '올바른 이메일 주소를 입력해 주세요.'

    try{
        const result1 = value.indexOf('@')
        const result2 = value.indexOf('.')

        const result3 = value.split('@')
        const result4 = result3[1].split('.')
        const result6 = result3[1].indexOf('.')


        const result5 = value.split('@').length -1

        if (StringUtil.isEmpty(resultValue)) {
            errMsg = '이메일을 입력해주세요.'
        }else if(result1 < 0 || result2 < 0 ) {
            console.log('@ . 없음')
        }else if(result5 > 1 ){
            console.log('@ 갯수가 많음')
        }else if(result6 < 0 ){
            console.log('@ 뒤에 . 없음')
        }else if(result3[0].length < 2 || result4[0].length < 2 || result4[result4.length-1].length < 2){
            console.log('값의 길이가 2보다 작음')
        } else {
            errMsg = ''
        }
    }catch (e){
        return {value:resultValue, errMsg: errMsg}
    }
    return {value:resultValue, errMsg: errMsg}
}

function checkValidPwd(value:string){
    const resultValue = value.replaceAll(' ', '')
    const regType1 = /^[A-Za-z0-9]+$/
    const regType2 = /^[A-Za-z]+$/
    const regType3 = /^[0-9]+$/
    let errMsg = ''

    try{
        if (StringUtil.isEmpty(resultValue)) {
            errMsg = '비밀번호를 입력해주세요.'
        }else if(value.length < 10){
            errMsg = '영문, 숫자 포함 10자 이상 입력해 주세요.'
        }else if(!regType1.test(resultValue)){
            errMsg = '영문과 숫자만 입력해 주세요.'
        }else if(regType2.test(resultValue) || regType3.test(resultValue)){
            errMsg = '영문, 숫자를 모두 포함해서 입력해 주세요.'
        }

    }catch (e){
        errMsg = '올바른 비밀번호를 입력해 주세요.'
        return {value:resultValue, errMsg: errMsg}
    }
    return {value:resultValue, errMsg: errMsg}
}

export default{
    convertGenderName,
    checkValidBirthday,
    checkValidTall,
    checkValidNickName,
    convertGenderValue,
    checkValidEmail,
    checkValidPwd,
    checkDuplicate,
    checkEmailDuplicate
}
