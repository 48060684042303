import React, {useCallback, useEffect, useState} from 'react'
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {authType, defaultAuthType} from "@/common/Constant";
import DialogHeader from "@/view/layout/DialogHeader";
import HealthService, {TilkoAuthData, TilkoHealthReportRes} from "@/service/HealthService";
import Alert from "@/view/common/popup/Alert";
import ResultWait from "@/view/mission/component/ResultWait";
import BottomButton from "@/view/common/component/button/BottomButton";
import Icon from "@/view/common/component/Icon";

export interface HealthAuthConfirmParam{
    onClose: () => void,
    onReturn?: () => void,
    authType: number | undefined,
    name: string,
    phoneNumber: string,
    birthday: string,
    authResult: TilkoAuthData | undefined
}

const HealthAuthConfirm = (props: HealthAuthConfirmParam) => {
    const handleOverlayClick = useCallback(() => props.onClose(), [props.onClose])
    const [selectedAuthType, setSelectedAuthType] = useState<authType>()
    const [authErrOpen, setAuthErrOpen] = useState(false)
    const [resultErrOpen, setResultErrOpen] = useState(false)
    const [resultWaitOpen, setResultWaitOpen] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [authResult, setAuthResult] = useState<TilkoAuthData | undefined>(props.authResult)

    const {refetch:getHealthReport} = useQuery<TilkoHealthReportRes, AxiosError>(
        ['getHealthReport'],
         ()=> HealthService.getHealthReport({
            birthDate: authResult? authResult.BirthDate:'',
            cxId: authResult? authResult.CxId: '',
            privateAuthType: authResult? authResult.PrivateAuthType: '',
            reqTxId: authResult? authResult.ReqTxId: '',
            token: authResult? authResult.Token: '',
            txId: authResult? authResult.TxId: '',
            userCellphoneNumber: authResult? authResult.UserCellphoneNumber: '',
            userName: authResult? authResult.UserName: ''
        }),
        {
            onSuccess: (v: TilkoHealthReportRes) => checkReport(v),
        }
    )

    const checkToken = () => {
        if(authResult === undefined){
            setErrMsg(`${selectedAuthType? selectedAuthType.title: ''} 앱에서 인증을 하셔야\n검진 결과를 가져올 수 있어요`)
            setAuthErrOpen(true)
        }else{
            setResultWaitOpen(true)
        }
    }

    const checkReport = (report: TilkoHealthReportRes) => {
        setResultWaitOpen(false)
        if(report.result){
            props.onClose()
            props.onReturn && props.onReturn()
        }else{
            if("AUTH_ERROR"=== report.message){
                setErrMsg(`${selectedAuthType? selectedAuthType.title: ''} 앱에서 인증을 하셔야\n검진 결과를 가져올 수 있어요`)
                setAuthErrOpen(true)
            }else {
                setErrMsg('검진 결과를 보내주는 곳에서\n오류가 발생했어요\n잠시 후 다시 시도해 주세요')
                setResultErrOpen(true)
            }
        }
    }

    useEffect(()=>{
        if(props.authType !== undefined){
            setSelectedAuthType(defaultAuthType[props.authType])
        }
    }, [props.authType])

    useEffect(()=>{
        if(resultWaitOpen === true){
            getHealthReport().then(r => r).catch(reason => console.log(reason)).finally(()=>setResultWaitOpen(false))
        }
    }, [resultWaitOpen])

    useEffect(()=>{
        setAuthResult(props.authResult)
    }, [props.authResult])

    return (
        <>
            <div id={'HealthAuthConfirm'}>
                <DialogHeader title='' leftIcon={'prev-b'} onClose={handleOverlayClick}/>
                <div className={'contents'}>
                <div className={'mt-43px flex-column align-center mrml-24px'}>
                    <img src={selectedAuthType?.bigLogo} width={'78px'} height={'78px'}/>
                    <p className={'mt-32px h5_m text-center'}>{selectedAuthType?.title} 앱에서 인증한 뒤<br/>확인을 눌러주세요</p>
                    <div className={'mt-96px'}>
                        <div className={'auth-info-box'}>
                            <p className={'s_title_2_m'}>알림이 오지 않거나 문제가 생겼나요?</p>
                            {selectedAuthType?.info.map((data, index) =>{
                                return <div className={'flex mt-8px'} key={selectedAuthType?.title + String(index)}>
                                        <div className={'mt-9px'}><Icon iconClass={'dot w-2px h-2px'}/></div>
                                        <p className={'ml-5px b2_r cl-gray03'}>{data}</p>
                                       </div>
                            })}
                        </div>
                    </div>
                </div>
                <Alert content={<div className={'text-center b2_r'}><p className={'white-space-wrap'}>{errMsg}</p></div>} title={undefined} btnContent={'확인'} isOpen={authErrOpen} onClose={ () => setAuthErrOpen(false)}/>
                <Alert content={<div className={'text-center b2_r'}><p className={'white-space-wrap'}>{errMsg}</p></div>} title={undefined} btnContent={'확인'} isOpen={resultErrOpen} onClose={ () => setResultErrOpen(false)}/>
                <Alert content={<ResultWait/>} title={undefined} btnContent={undefined} isOpen={resultWaitOpen}/>
                </div>
                <BottomButton title={'확인'} disabled={false} onClick={() => checkToken()}/>
            </div>
        </>
    )
}

export default HealthAuthConfirm