export enum Actions {
    GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN",
    GOOGLE_SIGN_OUT = "GOOGLE_SIGN_OUT",
    GOOGLE_PROFILE = "GOOGLE_PROFILE",
    GOOGLE_SIGNIN_WITH_CREDENTIAL = "GOOGLE_SIGNIN_WITH_CREDENTIAL",
}

export enum RNI_GSI_STATUS_CODES {
    SIGN_IN_CANCELLED =  "-5"
}

export declare interface User {
    user: {
        id: string;
        name: string | null;
        email: string;
        photo: string | null;
        familyName: string | null;
        givenName: string | null;
    };
    scopes?: string[];
    idToken: string | null;
    serverAuthCode: string | null;
}

export declare interface AdditionalUserInfo {
    isNewUser: boolean;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    profile?: Record<string, any>;
    providerId: string;
    username?: string;
}

export interface UserMetadata {
    creationTime?: string;
    lastSignInTime?: string;
}

export enum FactorId {
    PHONE = 'phone',
}
export interface MultiFactorInfo {
    displayName?: string;
    enrollmentTime: string;
    factorId: FactorId;
    uid: string;
}
export interface MultiFactor {
    enrolledFactors: MultiFactorInfo[];
}

export interface UserInfo {
    displayName?: string;
    email?: string;
    phoneNumber?: string;
    photoURL?: string;
    providerId: string;
    tenantId?: string;
    uid: string;
}

export declare interface UserCredential {
    additionalUserInfo?: AdditionalUserInfo;
    user: {
        displayName: string | null;
        email: string | null;
        emailVerified: boolean;
        isAnonymous: boolean;
        metadata: UserMetadata;
        multiFactor: MultiFactor | null;
        phoneNumber: string | null;
        photoURL: string | null;
        providerData: UserInfo[];
        providerId: string;
        uid: string;
    }
}
