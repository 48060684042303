import React, {useEffect, useState} from 'react'
import {DailyData} from "@/view/vitaindex/component/HistoryMonth";
import Icon from "@/view/common/component/Icon";
import {CommentData, ScoreHistoryData} from "@/service/VitaIndexService";
import HistoryDailyChart from "@/view/vitaindex/component/HistoryDailyChart";
import Nodata from "@/assets/image/vitaIndex/noData.png"

export type ChartDataSet = {
    type: any,
    borderRadius: number,
    barPercentage: number,
    backgroundColor: CanvasGradient | undefined,
    data: number[] | object[] | undefined[] | undefined,
}


interface PropsConfig {
    dailyData?:DailyData
    scoreHistory?:ScoreHistoryData[]
    month?:string
}


const HistoryDaily= (props: PropsConfig) => {
    const [nodata, setNodata] = useState<boolean>(false)
    const [result, setResult] = useState<number[]>([])
    const [commentList, setCommentList] = useState<CommentData[] | undefined>(undefined)

    const createChart = (data:ScoreHistoryData) => {
        const _result: number[] = []
        _result.push(data.totalScore)
        _result.push(data.walkingScore)
        _result.push(data.sleepScore)
        _result.push(data.bodyScore)
        setResult(_result)
        setCommentList(data.commentList)
    }

    useEffect(() => {
        if(props.dailyData && props.scoreHistory){
            if(props.dailyData.date?.substring(0,7) === props.month){ // month 바뀔때 화면 변하지 않도록 로직 추가
                let contain = false // 선택한 날짜에 데이터 없을때 화면 처리하기 위한 구분자
                props.scoreHistory?.forEach((d) => {
                    if(d.date === props.dailyData?.date){
                        createChart(d)
                        contain = true
                    }
                })

                if(!contain){
                    setNodata(true)
                }else{
                    setNodata(false)
                }
            }
        }
    },[props.dailyData, props.scoreHistory])


    return (
        <div className='mt-26px mb-100px daily-history'>
            <div className={'b2_m cl-gray03 pt-14px prpl-24px'}>{props?.dailyData?.date}의 건강 습관 점수</div>
            <div className={'gray-bar-thin mt-12px'}></div>
            {
                nodata ?
                    <>
                        <div style={{height: '200px', margin: '0px 31px', paddingTop: '45px', textAlign: 'center'}}>
                            <img width={'110px'} height={'113px'} src={Nodata as string}/>
                        </div>
                    </>
                :
                    <>
                        <div>
                            <HistoryDailyChart result={result} />
                        </div>
                        {
                            commentList && commentList.length > 0 &&
                            <>
                                <div className={'daily-history-comment mr-16px ml-16px mt-26px mb-26px '}>
                                    <div className={'prpl-24px pt-16px pb-16px'}>
                                    {
                                        commentList?.map((data, index) => {
                                            return <div key={index} className={'flex'}>
                                                        <div className={'flex mt-8px'}><span className={'point-gray w-4px h-4px'}></span></div>
                                                        <div className={'b2_r cl-gray03 ml-10px'}>{data.value}</div>
                                                    </div>
                                        })
                                    }
                                    </div>
                                </div>
                            </>
                        }
                    </>
            }
            <br/>
            <br/>
        </div>
    )
}

export default HistoryDaily