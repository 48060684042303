import React from 'react'

export interface DailyMissionCommentParam{
    comment:string
}

const DailyMissionComment = (props: DailyMissionCommentParam) => {
    return (
        <>
        <div className={'comment-box pt-12px pb-12px'}>
            <div className={'flex align-center ml-16px mr-16px'}>
                <div dangerouslySetInnerHTML={{__html: props.comment? props.comment:''}}/>
            </div>
        </div>
        </>
    )
}

export default DailyMissionComment