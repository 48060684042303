import React from "react";
import Icon from "@/view/common/component/Icon";
import TwoBottomButton from "@/view/common/component/button/TwoBottomButton";

type UserDvicAddUsedProps ={
    onClickCancle:() => Promise<void> | void,
    onClickOk:() => Promise<void> | void
}

const UserDvicAddUsedView = (props : UserDvicAddUsedProps) => {

    return(
        <>
            <div className={' h-fit-content  justify-center align-center w-100p h-60vh'} >
                <div className={'align-center'}>
                    <div className={'justify-center align-center w-100p mt-32px'}>
                        <div className={'h6_b  flex-column align-center '}>다른 사람이 사용중인</div>
                        <div className={'h6_b  flex-column align-center '}>체중계를 가져오셨네요.</div>
                    </div>
                    <div className={'justify-center align-center w-100p mt-16px'}>
                        <div className={'b2_r  flex-column align-center'}>이 체중계를 함께 쓸 수 있어요</div>
                        <div className={'b2_r  flex-column align-center'}>편하게 쓰고 싶다면 개인 체중계를 연결해 주세요</div>
                    </div>
                    <div className={'justify-center align-center flex mt-43px'} style={{width:'96.82',height:'146.27'}} >
                        <Icon iconClass={'scale-used-other wi-9682px hi-14627px'}  />
                    </div>

                </div>
                <div className={'flex'}>
                    <div><TwoBottomButton  titleOk='체중계 연결하기' disabledOk={false}   titleCancle={'나중에'}  classNameCancle={'gray03'}
                                           onClickCancle={props.onClickCancle} onClickOk={props.onClickOk}
                    /></div>
                </div>
            </div>
        </>
    )
}

export default UserDvicAddUsedView