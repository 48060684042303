import React, {useEffect, useRef, useState} from 'react'
import moment from "moment/moment";
import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import Flicking, {ViewportSlot} from '@egjs/react-flicking'
import {Pagination} from '@egjs/flicking-plugins'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import Lotte1 from '@/assets/image/login/login-intro-lottie1.png'
import Lotte2 from '@/assets/image/login/login-intro-lottie2.png'
import Lotte3 from '@/assets/image/login/login-intro-lottie3.png'
import Lotte4 from '@/assets/image/login/login-intro-lottie4.png'
import Popup from "@/view/common/popup/Popup"
import SnsLogin from "@/view/login/component/SnsLogin"
import {LOGIN} from "@/common/Constant"
import UserService from "@/service/UserService"
import StringUtil from "@/common/StringUtil";

export interface LoginOnboardParam {
    gotoLogin: boolean
}

const Onboard = (props: LoginOnboardParam) => {
    const [_plugins] = useState([new Pagination({ type: 'bullet' })]);
    const ref = useRef<Flicking>(null)
    const [isLast, setIsLast] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const nowDate = moment()
    const [nowDt] = useState(nowDate.format("YYYY.MM.DD"))
    const [moonCoount, setmoonCoount] = useState('')
    const [totalDist, setTotalDist] = useState('')
    const [isMoving, setMoving] = useState(false);

    useQuery<number, AxiosError>(
        ['getTotalDist'],
        ()=> UserService.getTotalDist(),
        {
            cacheTime: 1000 * 60 * 60,
            enabled: true,
            onSuccess: (v : number) => onSuccess(v)
        }
    )

    const onSuccess = (v: number) => {
        if(v){
            const moonCount = Math.round(v/780000)
            setmoonCoount(getMoonCount(moonCount))
        }
        setTotalDist(StringUtil.addComma(v))
    }

    const getMoonCount = (moonCount: number): string => {
        let result
        switch (moonCount){
            case 4: result = '네'
                break;
            case 5: result = '다섯'
                break;
            case 6: result = '여섯'
                break;
            case 7: result = '일곱'
                break;
            case 8: result = '여덜'
                break;
            case 9: result = '아홉'
                break;
            case 10: result = '열'
                break;
            case 11: result = '열한'
                break;
            case 12: result = '열두'
                break;
            case 13: result = '열세'
                break;
            case 14: result = '열네'
                break;
            case 15: result = '열다섯'
                break;
            case 16: result = '열여섯'
                break;
            case 17: result = '열일곱'
                break;
            case 18: result = '열여덜'
                break;
            case 19: result = '열아홉'
                break;
            case 20: result = '스무'
                break;
            default:
                result = moonCount.toString()

        }
        return result
    }

    const moveToNext = ()=>{
        if(ref.current !== null && !isMoving) {
            const index: number = ref.current.index
            if (index < 4) {
                setMoving(true)

                if (index === 1) {
                    setIsLast(true)
                } else if (index === 3) {
                    //바로 시작하기
                    setMoving(false)
                    return
                }

                ref.current.next(300)
                    .then(() => {
                        setMoving(false)
                    })
                    .catch(() => {
                            setMoving(false)
                        },
                    )
            }
        }
    }

    const divChanged = () => {
        if(ref.current !== null) {
            const index: number = ref.current.index
            if (index < 4) {
                if (index === 3) {
                    setIsLast(true)
                } else {
                    setIsLast(false)
                }
            }
        }
    }

    const moveToLast = () => {
        setIsLast(true)
        if(ref.current !== null) {
            void ref.current.moveTo(3, 0)
        }
        setIsOpen(true)
    }

    useEffect(()=> {
        if(props.gotoLogin) {
            moveToLast()
            setIsOpen(true)
        }
    },[props.gotoLogin])

    return (
        <>
            <div id='Onboard' className={'bg-cl-black'}>
                <div className={'text-right'}>
                    {
                        isOpen?
                            <div className={'h-56px w-100p'}></div>
                        :
                            <button className='skip-btn w-48px' onClick={moveToLast}>{LOGIN.SKIP}</button>
                    }
                </div>
                <div>
                    <Flicking bounce={1}
                              plugins={_plugins}
                              circular={false}
                              hanger={'0'}
                              align={'prev'}
                              panelsPerView={1}
                              moveType={['strict', { count: 1 }]}
                              changeOnHold={false}
                              ref={ref}
                              onChanged={divChanged}
                    >

                        <div key={0}>
                            <img className='lottie-image' src={Lotte1 as string}/>
                            <div className='h5_m pt-24 cl-basic-white'>
                                누구나 쉽게 만드는 건강습관!<br/>
                                ROTHY만 따라오세요
                            </div>
                            <p className='c1_r pt-16px pb-30p cl-gray06'>
                                ROTHY가 제안하는 하루치 목표량을 달성하다 보면<br/>
                                어느새 건강해진 자신을 발견할 수 있을거예요
                            </p>
                        </div>
                        <div key={1}>
                        <img className='lottie-image' src={Lotte2 as string}/>
                            <h5 className='h5_m pt-24 cl-basic-white'>
                                ROTHY와 함께<br/>
                                지루하지 않게 걸어보세요
                            </h5>
                            <p className='c1_r pt-16px pb-30p cl-gray06'>
                                이동 거리, 걸어서 소모한 칼로리 등<br/>
                                다양한 방식으로 얼마나 걸었는지 알려드려요
                            </p>
                        </div>
                        <div key={2}>
                        <img className='lottie-image' src={Lotte3 as string}/>
                            <h5 className='h5_m pt-24 cl-basic-white'>
                                스마트 기기를 연동해서도<br/>
                                관리 할 수 있어요
                            </h5>
                            <p className='c1_r pt-16px pb-30p cl-gray06'>
                                갤럭시 워치나 스마트 체중계가 있다면<br/>
                                수면이나 체중도 함께 관리하실 수 있게 도와드려요
                            </p>
                        </div>
                        <div key={3}>
                        <img className='lottie-image' src={Lotte4 as string}/>
                            <h5 className='h5_m pt-24 cl-basic-white'>
                                ROTHY와 함께한<br/>
                                {totalDist}KM
                            </h5>
                            <p className='c1_r pt-16px pb-30p cl-gray06'>
                                ROTHY 회원들이 <span className='cl-primary-cyan01'>{nowDt}</span>까지 걸은 거리는<br/>
                                달까지 {moonCoount}번 왕복한 거리예요!
                            </p>
                        </div>

                        <ViewportSlot>
                            <div className='flicking-pagination'></div>
                        </ViewportSlot>
                    </Flicking>
                </div>
                <div className='prpl-24px mb-52px'>
                    {isLast?
                        <button id='nextBtn' className={'primary w-100p'} onClick={ () => setIsOpen(true) }>{LOGIN.START_NOW}</button>
                        : <button id='nextBtn' className={'b2_m outlined w-100p nextBtn'} onClick={moveToNext}>{LOGIN.NEXT}</button>
                    }
                </div>
            </div>
            <Popup className='bg-cl-white' content={<SnsLogin/>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
        </>
    )
}

export default Onboard;