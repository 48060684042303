import React from 'react'
import {useNavigate} from "react-router-dom";
import LinkHeader from "@/view/layout/LinkHeader";
import HeaderSpacer from "@/view/layout/HeaderSpacer";
import BottomButton from "@/view/common/component/button/BottomButton";
import InterfaceUtil from "@/common/Interface";
import {OS_TYPE} from "@/common/Constant";

const HealthConnectGuide = () => {
    const navigate = useNavigate()

    const goMain = () => {
        navigate('/main')
    }
    return (
        <>
            <LinkHeader/>
            <HeaderSpacer className='bg-cl-black'/>
            {
                InterfaceUtil.getOs() == OS_TYPE.ANDROID ?
                    <div id='HealthConnectGuide' className={'bg-cl-black'}>
                        <div className={'main-content text-left'}>
                            <div className={'h5_m title mt-39px'}>삼성 헬스와 ROTHY를<br/>연결해주세요</div>
                            <div className={'b2_r title mt-16px ft-cl-hint'}>
                                삼성 헬스를 통해 걸음기록, 수면기록,<br/>체성분 기록을 측정할 수 있어요.
                            </div>
                        </div>
                        <BottomButton title={'연결하기'} disabled={false} onClick={() => goMain()} />
                    </div>
                    :
                    <div id='HealthConnectGuide' className={'bg-cl-black'}>
                        <div className={'main-content text-left'}>
                            <div className={'h5_m title mt-39px'}>Apple Health와 ROTHY를<br/>연결해주세요</div>
                            <div className={'b2_r title mt-16px ft-cl-hint'}>
                                통계 및 서비스 제공을 위해<br/>
                                Apple Health에서 건강 데이터 접근을<br/>
                                ‘모두 켜기' 해주세요.
                            </div>
                        </div>
                        <BottomButton title={'연결하기'} disabled={false} onClick={() => goMain()} />
                    </div>
            }
        </>
    )
}

export default HealthConnectGuide