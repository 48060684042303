import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {AxiosError} from "axios";
import AuthenticationView from "@/view/auth/AuthenticationView";
import Sample from "@/view/sample/Sample";
import LoginView from "@/view/login/LoginView";
import MainView from "@/view/main/MainView";
import RothyNativeiSample from "@/view/sample/rothynative/RothyNativeiSample";
import Loading from "@/view/common/component/Loading";
import Typo from "@/view/sample/Typo";
import RothyFireBase from "@/service/FireBaseService";
import Alert from "@/view/common/popup/Alert"
import {MESSAGE} from "@/common/Constant"
import SignupView from "@/view/signup/SignupView";
import HealthConnectGuide from "@/view/signup/component/HealthConnectGuide"
import EmailLoginView from "@/view/login/EmailLoginView"
import NoSignUpEmail from "@/view/login/component/NoSignUpEmail"
import SnsSignUpEmail from "@/view/login/component/SnsSignUpEmail"
import EmailSignUpEmail from "@/view/login/component/EmailSignUpEmail";
import SampleGraph from "@/view/sample/SampleGraph";
import MainViewForAdmin from "@/view/main/MainViewForAdmin";
import VitaIndexDetailView from "@/view/vitaindex/VitaIndexDetailView";

const App: React.FC = () => {
    useEffect(()=>{
        RothyFireBase.getInstance()
    })

    const [isOpen, setIsOpen] = useState(false)
    const queryClient = new QueryClient(
        {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    cacheTime: 0, // 시간안에 같은 key로 요청오면 캐싱된 데이터 리턴 1000 -> 1초, default: 0초, 공지사항, FAQ: 1시간(1000 * 60 * 60) 으로 오버라이딩 해서 사용
                    retry: false, // false: 에러시 재시도 없음. 1: 재시도 1회
                    enabled: false, // true: mount시 함수 호출, false mount시 함수 호출 되지 않음
                    onError: (err:unknown) => {
                        const error = err as AxiosError
                        if(error.response !== undefined && (error.response.status === undefined || (error.response.status !== 401 && error.response.status !== 403))){
                            setIsOpen(true)
                        }
                    }
                },
            }
        }
    )

    return (
        <>
            <div id="rootBg"></div>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<AuthenticationView/>}/>
                        <Route path="/sample" element={<Sample/>}/>
                        <Route path="/sampleGraph" element={<SampleGraph/>}/>
                        <Route path="/typo" element={<Typo/>}/>
                        <Route path="/login" element={<LoginView/>}/>
                        <Route path="/emailLogin" element={<EmailLoginView/>}/>
                        <Route path="/noSignupEmail" element={<NoSignUpEmail/>}/>
                        <Route path="/snsSignUpEmail" element={<SnsSignUpEmail/>}/>
                        <Route path="/emailSignUpEmail" element={<EmailSignUpEmail/>}/>
                        <Route path="/signup" element={<SignupView/>}/>
                        <Route path="/main" element={<MainView/>}/>
                        <Route path="/mainForAdmin" element={<MainViewForAdmin/>}/>
                        <Route path="/vitaIndexDetail" element={<VitaIndexDetailView/>}/>
                        <Route path="/loading" element={<Loading/>}/>
                        <Route path="/healthConnectGuide" element={<HealthConnectGuide/>}/>
                        <Route path="/sample/rothynative/*" element={<RothyNativeiSample/>} />
                    </Routes>
                </BrowserRouter>
               {/* <ReactQueryDevtools initialIsOpen={false} />*/}
            </QueryClientProvider>
            <Alert content={<div className={'text-center b2_r'}><div>{MESSAGE.ERROR1}</div><div>{MESSAGE.ERROR2}</div><div>{MESSAGE.ERROR3}</div></div>} title={undefined} btnContent={'확인'} isOpen={isOpen} onClose={ () => setIsOpen(false)}/>
        </>
    )
}
export default App;
