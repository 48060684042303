import React, {useEffect, useState} from 'react'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import Loading from "@/view/common/component/Loading";
import UserDvicService, {UserDvicData,  UserDvicReq, UserDvicUpateReq} from "@/service/UserDvicService";
import Icon from "@/view/common/component/Icon";
import {DefaultResult} from "@/service/RothyClient";
import Popup from "@/view/common/popup/Popup";
import UpdateTypeSelect from "@/view/userDvic/component/UpdateTypeSelect";
import Toast from "@/view/common/popup/Toast";
import {InputChangeEvent} from "@/view/common/component/Input";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 275,
}

export interface UserDvicProps {
    updateList?:boolean
}
const UserDvic = (props:UserDvicProps) => {
    const [userDvicList, setUserDvicList] = useState<UserDvicData[]>([])
    const userDvicReq:UserDvicReq = {dvicType:'SCALE'}
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDisconnect, setIsOpenDisconnect] = useState(false)
    const [title, setTitle] = useState<string | undefined>('')
    const [updatedTitle, setUpdatedTitle] = useState<string | undefined>('')
    const [updatedUserDvicData, setUpdatedUserDvicData] = useState<UserDvicUpateReq | undefined>(undefined)

    const [disconnectMsg, setDisconnectMsg] = useState<string | undefined>('')
    const [selectedUserDvicData, setSelectedUserDvicData] = useState<UserDvicData | undefined>(undefined)


    const userDvicUpateReq = new UserDvicUpateReq(
        0,
        '',
        '',
        '',
        '정상'
    )

    const {refetch: updateUserDvic} = useQuery<DefaultResult, AxiosError>(
        ['updateUserDvic'],
        ()=> UserDvicService.updateUserDvic(updatedUserDvicData),
        {
            onSuccess: () => {
                onSuccessUpdate().then(r => r).catch((e) => console.log(e))
            },
        }
    )

    const {refetch: deleteUserDvic} = useQuery<DefaultResult, AxiosError>(
        ['deleteUserDvic'],
        ()=> UserDvicService.deleteUserDvic(selectedUserDvicData?selectedUserDvicData.userDvicNo:0),
        {
            onSuccess: () => {
                onSuccessDelete()
            },
        }
    )

    const onSuccessUpdate = async () => {
        setIsOpenUpdate(false)
        await userDviclist()
    }

    const onSuccessDelete =  () => {
        setDisconnectMsg(String(title) + ' 연결이 해제됐습니다.')
        setIsOpenDisconnect(true)
    }

    const typeClicked = (n:UserDvicData) => {
        if(n){
            setTitle(n.dvicNickNm)
            setSelectedUserDvicData(n)
            setIsOpen(true)
        }
    }

    const typeSelected = (value:string) => {
        setIsOpen(false)
        if(value === 'update'){
            setIsOpenUpdate(true)
            setUpdatedTitle(title)
        }else{
            deleteUserDvic().then(() => {
                return Promise<void>
            }).catch((e) => console.log(e))
        }
    }

    const { status, data:typeResult, refetch: userDviclist} = useQuery<DefaultResult, AxiosError>(
        ['userDviclist'],
        ()=> UserDvicService.selectUserDvic(userDvicReq),
        {
            enabled: true,
            onSuccess: (v: DefaultResult) => updateUserDvicList(v.data as UserDvicData[]),
        }
    )

    const updateUserDvicList = (data: UserDvicData[]) => {
        const dataList : UserDvicData[] =[]
        data.forEach( d => {
                        let frstRegDay:string|undefined
                        if(d.frstRegDtm != undefined)
                            frstRegDay = d.frstRegDtm.split(' ').at(0)
                        dataList.push({ userDvicNo: d.userDvicNo, dvicType: d.dvicType, dvicNickNm: d.dvicNickNm, macAddr: d.macAddr ,frstRegDtm:frstRegDay})
                        })
        setUserDvicList(dataList)
    }

    const handleClick = () => {
        userDvicUpateReq.userDvicNo = selectedUserDvicData?.userDvicNo
        userDvicUpateReq.dvicNickNm = updatedTitle
        userDvicUpateReq.dvicType = selectedUserDvicData?.dvicType
        userDvicUpateReq.macAddr = selectedUserDvicData?.macAddr
        setUpdatedUserDvicData(userDvicUpateReq)
    }

    const handleClose = () => {
        setIsOpenUpdate(false)
    }

    const changeHandler = (ev: InputChangeEvent) => {
        setUpdatedTitle(ev.target.value)
    }

    const reloadHandler = async  () => {
        await userDviclist()
    }

    useEffect(() => {
        if(updatedUserDvicData){
            updateUserDvic().then(r => r).catch(e => console.log(e))
        }
    }, [updatedUserDvicData])

    useEffect(() => {
        if(typeResult !== undefined){
            updateUserDvicList(typeResult.data as UserDvicData[])
        }
    }, [])

    useEffect( () => {
        console.debug("UserDvic reload")
        reloadHandler().catch(e=>console.log(e))

    },[props.updateList])

    const toastClose = async () =>{
        setIsOpenDisconnect(false)
        await userDviclist()
    }

    if(status === 'loading'){
        return (
            <>
                <Loading/>
            </>
        )
    }else if(userDvicList.length !== 0) {
        return (
            <>
                <div >
                    {userDvicList.map((n, index) => {
                        return (
                            <div key={index} className='header-spacer border-bottom-gray h-84px' onClick={() =>typeClicked(n)}>
                                <div className='flex h-84px ml-20px align-center'>
                                    <div className={'h-84px  w-20px'}></div>
                                    <Icon iconClass={`scale-icon w-40px h-40px`}/>
                                    <div >
                                        <div className=' b2_r h-22px ml-12px'>{n.dvicNickNm}</div>
                                        <div className=' c1_m h-18px ml-12px'><span className='ft-cl-hint c1_r'>등록일 | {n.frstRegDtm}</span></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Popup className='bg-cl-white' title={title} content={<UpdateTypeSelect onClick={typeSelected}/>} isOpen={isOpen} onClose={ () => setIsOpen(!isOpen) }/>
                <Toast content={<div>{disconnectMsg}</div>} isOpen={isOpenDisconnect} onClose={toastClose}></Toast>
                {

                        <Modal
                            open={isOpenUpdate}
                            onClose={handleClose}
                            aria-describedby="modal-modal-description"
                        >

                                <Box sx={style}>
                                    <div className={'prpl-24px pt-24px'}>
                                        <div className={'b1_m'}>체중계 이름 변경</div>
                                        <div className={'mt-16px mb-32px'}>
                                            <input className={'custom-input-one b2_r'} placeholder={'체중계 이름을 입력해 주세요.'} value={updatedTitle}  readOnly={false}
                                                   onChange={changeHandler}
                                            />
                                            <div className={'black-bar'}></div>
                                        </div>
                                    </div>
                                    <div className="modal-footer" >
                                        <div className={'modal-btn confirm-btn confirm-lbtn'} onClick={handleClose}>취소</div>
                                        <div className={'modal-btn confirm-btn'} onClick={handleClick}>변경</div>
                                    </div>
                                </Box>
                        </Modal>

                }
            </>
        )
    }else{
        return (
            <>
                <div className={' h-80vh flex  justify-center align-center' }>
                    <Icon iconClass={`no-scale-bg w-193px h-210px`}/>
                </div>
            </>
        )
    }
}

export default UserDvic;