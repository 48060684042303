import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import Mission from "@/view/mission/component/Mission";

export interface MissionViewProps{
    vitaIndexFlag: boolean
}

const MissionView = forwardRef((props: MissionViewProps, ref) => {
    const missionRef = useRef<{refresh: () => void}>(null)

    useEffect(()  => {
        refreshMission()
    }, [])

    const refreshMission = () => {
        if(missionRef.current !== null){
            missionRef.current.refresh()
        }
    }

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            refreshMission()
        }
    }))

    return (
        <>
            <Mission ref={missionRef} vitaIndexFlag={props.vitaIndexFlag}/>
        </>
    )
})

export default MissionView
