import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import WalkDetail from "@/view/walk/component/WalkDetail";
import WalkRecord from "@/view/walk/component/WalkRecord";
import WalkSummary from "@/view/walk/component/WalkSummary";

const WalkView = forwardRef((props, ref) => {
    const walkDetailRef = useRef<{refresh: () => void}>(null)

    const refreshWalkDetail = () => {
        if(walkDetailRef.current !== null){
            walkDetailRef.current.refresh()
        }
    }

    useImperativeHandle(ref, () => ({
        refresh: () =>{
            refreshWalkDetail()
        }
    }))

    return (
        <>
            <WalkDetail ref={walkDetailRef}/>
            <div className={'gray-bar'}></div>
            <WalkSummary/>
            <div className={'gray-bar'}></div>
            <WalkRecord/>
            <div className={'mt-100px'}></div>
        </>
    )

})

export default WalkView